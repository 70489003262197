import _async_to_generator from "@swc/helpers/src/_async_to_generator.mjs";
import _object_spread from "@swc/helpers/src/_object_spread.mjs";
import regeneratorRuntime from "/vercel/path0/node_modules/next/dist/compiled/regenerator-runtime/runtime.js";
import { getAuthorizedSdk } from "@striver/sdk";
import { checkRole } from "@striver/ui";
import striverAuth0 from "./striverAuth";
import vantiamAuth0 from "./vantiamAuth";
export function roleBasedAccess(pathname, req, res, query, pageProps, asPath) {
    return _roleBasedAccess.apply(this, arguments);
}
function _roleBasedAccess() {
    _roleBasedAccess = _async_to_generator(regeneratorRuntime.mark(function _callee(pathname, req, res, query, pageProps, asPath) {
        var sdk, auth, session, ref, users, userTenant;
        return regeneratorRuntime.wrap(function _callee$(_ctx) {
            while(1)switch(_ctx.prev = _ctx.next){
                case 0:
                    if (!(pathname.startsWith("/[tenant]/profile") || pathname === "/" || pathname === "/[tenant]" && query.tenant === "striver" || pathname === "/[tenant]/scheduling/schedule-confirmation" || pathname === "/[tenant]/smart-onboarding" || pathname === "/s/[...short_url]" || pathname === "/[tenant]/people" || pathname === "/privacy")) {
                        _ctx.next = 2;
                        break;
                    }
                    return _ctx.abrupt("return", _object_spread({}, pageProps.pageProps));
                case 2:
                    if (!(pathname === "/[tenant]" && query.tenant !== "striver" && !res.headersSent)) {
                        _ctx.next = 4;
                        break;
                    }
                    return _ctx.abrupt("return", {});
                case 4:
                    if (!("object" === "undefined" && !res.headersSent)) {
                        _ctx.next = 30;
                        break;
                    }
                    sdk = getAuthorizedSdk();
                    auth = query.tenant === "striver" ? striverAuth0 : vantiamAuth0;
                    session = auth.getSession(req, res);
                    if (!(session === null && res)) {
                        _ctx.next = 12;
                        break;
                    }
                    res.writeHead(302, {
                        Location: "/api/auth/".concat(query.tenant || "striver", "/login?returnTo=").concat(asPath)
                    });
                    res.end();
                    return _ctx.abrupt("return", {});
                case 12:
                    if (!((session === null || session === void 0 ? void 0 : session.user) && checkRole([
                        "client"
                    ], session.user["https://hasura.io/jwt/claims"]["x-hasura-default-role"]))) {
                        _ctx.next = 22;
                        break;
                    }
                    ;
                    _ctx.next = 16;
                    return sdk.user_by_auth0_uid({
                        auth0_uid: session.user["https://hasura.io/jwt/claims"]["x-hasura-user-id"]
                    });
                case 16:
                    users = _ctx.sent.users;
                    userTenant = (ref = users[0].tenant_users[0]) === null || ref === void 0 ? void 0 : ref.tenant.shortname;
                    if (!(query.tenant && userTenant && userTenant !== query.tenant)) {
                        _ctx.next = 22;
                        break;
                    }
                    res.writeHead(302, {
                        Location: "/".concat(userTenant, "/").concat(asPath.split("/").slice(2).join("/"))
                    });
                    res.end();
                    return _ctx.abrupt("return", _object_spread({}, pageProps.pageProps));
                case 22:
                    if (!(!(pathname.startsWith("/[tenant]/people") || pathname.startsWith("/[tenant]/profile") || pathname.startsWith("/[tenant]/config") || pathname.startsWith("/[tenant]/resource-centre") || pathname.startsWith("/[tenant]/smart-onboarding")) && query.tenant && query.tenant !== "striver")) {
                        _ctx.next = 26;
                        break;
                    }
                    res.writeHead(302, {
                        Location: "/".concat(query.tenant, "/people")
                    });
                    res.end();
                    return _ctx.abrupt("return", _object_spread({}, pageProps.pageProps));
                case 26:
                    if (!(pathname.startsWith("/[tenant]/manage") && !checkRole([
                        "admin"
                    ], session.user["https://hasura.io/jwt/claims"]["x-hasura-default-role"]) || pathname.startsWith("/[tenant]/people") && !checkRole([
                        "client",
                        "admin"
                    ], session.user["https://hasura.io/jwt/claims"]["x-hasura-default-role"]) || pathname.startsWith("/[tenant]/dashboard") && !checkRole([
                        "candidate",
                        "admin"
                    ], session.user["https://hasura.io/jwt/claims"]["x-hasura-default-role"]))) {
                        _ctx.next = 30;
                        break;
                    }
                    res.writeHead(302, {
                        Location: "/".concat(query.tenant)
                    });
                    res.end();
                    return _ctx.abrupt("return", {});
                case 30:
                case "end":
                    return _ctx.stop();
            }
        }, _callee);
    }));
    return _roleBasedAccess.apply(this, arguments);
}
