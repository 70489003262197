import React from 'react';
import { Box, Flex, Spinner, Text } from '@chakra-ui/react';

export const PageIsLoading: React.FC<unknown> = () => {
  return (
    <Flex justifyContent="center" py={10} direction="column" align="center">
      <Box>
        <Spinner mb={3} />
      </Box>
      <Text fontWeight="bold">Loading</Text>
    </Flex>
  );
};
