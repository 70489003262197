import { useRouter } from "next/router";

// Client side
export function useUtms() {
  const router = useRouter();
  const hasUtmParams =
    router.query?.utm_campaign &&
    router.query?.utm_source &&
    router.query?.utm_medium
      ? true
      : false;
  const name = router.query.utm_campaign as string;
  const source = router.query.utm_source as string;
  const medium = router.query.utm_medium as string;
  const path = router.pathname as string;

  return {
    hasUtmParams,
    name,
    source,
    medium,
    path,
  };
}
