import { useUser } from '@auth0/nextjs-auth0';
import React, { useEffect, useState } from 'react';

export type Roles = 'admin' | 'tenant' | 'client' | 'candidate' | 'public';

export function checkRole(allowedRoles: Roles[], userRoles: Roles): boolean {
  if (allowedRoles.includes(userRoles)) {
    return true;
  }
  return false;
}

export const IsRole: React.FC<{
  allowedRoles: Roles[];
  userRole: Roles;
  children: React.ReactNode;
}> = ({ allowedRoles, userRole, children }) => {
  if (allowedRoles.includes(userRole)) {
    return <>{children}</>;
  }
  return null;
};

export const HasRole: React.FC<{
  roles: Roles[];
  children: React.ReactNode;
}> = ({ roles, children }) => {
  const [userRole] = useRole();
  if (roles.includes(userRole)) {
    return <>{children}</>;
  }
  return null;
};

export function useRole(): [Roles, boolean] {
  const [role, setRole] = useState<Roles>('public');
  const { user, isLoading } = useUser();
  useEffect(() => {
    if (user) {
      const role = (user['https://hasura.io/jwt/claims'] as any)[
        'x-hasura-default-role'
      ];
      setRole(role);
    }
  }, [user]);
  return [role, isLoading];
}
