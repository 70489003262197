import { GraphQLClient } from 'graphql-request';
import { getSdk, getSdkWithHooks, SdkFunctionWrapper } from './graphql/types';

// Example of a GraphQL client "middleware"
const clientTimingWrapper: SdkFunctionWrapper = async <T>(
  action: () => Promise<T>
): Promise<T> => {
  const result = await action();
  return result;
};

// For use in a React FE app
export const getAuthorizedSdkWithHooks = (
  endpoint: string | undefined = process.env
    .NEXT_PUBLIC_HASURA_PROXY_ENDPOINT || ''
): ReturnType<typeof getSdkWithHooks> => {
  const headers: Record<string, string> = {
    'Content-Type': 'application/json',
  };
  const client = new GraphQLClient(endpoint, {
    headers,
  });

  return getSdkWithHooks(client, clientTimingWrapper);
};

// For use in a Node app or function
export const getAuthorizedSdk = (
  endpoint: string | undefined = process.env.NEXT_PUBLIC_HASURA_ENDPOINT || '',
  secret: string | undefined = process.env.HASURA_GRAPHQL_ADMIN_SECRET || ''
): ReturnType<typeof getSdk> => {
  const headers: Record<string, string> = {
    'Content-Type': 'application/json',
    'x-hasura-admin-secret': secret,
  };

  const client = new GraphQLClient(
    endpoint,
    // No need to proxy via api/graphql for backend requests e.g. just use NEXT_PUBLIC_HASURA_ENDPOINT
    {
      headers,
    }
  );

  return getSdk(client);
};

// For jest tests (e.g. in apps/app/__tests__/sdk/permissions.spec.ts)
export const getAuthorizedTestSdk = (
  endpoint: string | undefined = process.env.NEXT_PUBLIC_HASURA_ENDPOINT || '',
  secret: string | undefined = process.env.HASURA_GRAPHQL_ADMIN_SECRET || '',
  userId: string,
  role: string
): ReturnType<typeof getSdk> | void => {
  const headers: Record<string, string> = {
    'Content-Type': 'application/json',
    'x-hasura-admin-secret': secret,
    'x-hasura-user-id': userId,
    'x-hasura-role': role,
  };

  if (process.env.DEBUG_ENABLED) {
    const client = new GraphQLClient(
      endpoint,
      // No need to proxy via api/graphql for backend requests e.g. just use NEXT_PUBLIC_HASURA_ENDPOINT
      {
        headers,
      }
    );

    return getSdk(client);
  }
};
