import React from 'react';
import {
  Box,
  GridItem,
  HStack,
  SimpleGrid,
  Table,
  Tbody,
  Td,
  Th,
  Thead,
  Tr,
} from '@chakra-ui/react';
import {
  CanBookScreeningInterview,
  Candidate,
  ClientSchema,
  Contact,
  CustomSafeParsedSchema,
  Interview,
  Job,
} from '@striver/schema';

type SupportedSchemas =
  | typeof Candidate
  | typeof ClientSchema
  | typeof Contact
  | typeof Job
  | typeof Interview
  | typeof CanBookScreeningInterview;

export const DebugSchema: React.FC<{
  description?: string;
  data?: unknown;
  schema: SupportedSchemas;
}> = ({ description, data, schema }) => {
  const parsedData = schema.safeParse(data) as CustomSafeParsedSchema;
  return (
    <>
      <SimpleGrid
        bg={!parsedData?.error?.issues.length ? 'green.400' : 'red.400'}
        color={'white'}
        p={2}
        columns={2}
      >
        <GridItem>
          <HStack fontSize="md" fontWeight="bold">
            <Box>{description ? description : 'Unknown'}</Box>
          </HStack>
        </GridItem>
      </SimpleGrid>

      <SimpleGrid columns={12}>
        <GridItem colSpan={4} p={2}>
          <Box fontSize="xs">
            <pre>{JSON.stringify(parsedData, null, 2)}</pre>
          </Box>
        </GridItem>

        <GridItem colSpan={8} p={2}>
          <Table variant="simple" size="sm">
            <Thead>
              <Tr>
                <Th>Object</Th>
                <Th>Path</Th>
                <Th>Message</Th>
                <Th>Code</Th>
                <Th>Expected</Th>
                <Th>Received</Th>
              </Tr>
            </Thead>
            <Tbody>
              {Object.keys(parsedData).length > 0 &&
                parsedData &&
                parsedData?.error?.issues.map(
                  ({ path, message, code, expected, received }, i) => {
                    return (
                      <Tr key={i}>
                        <Td>data</Td>
                        <Td>{path}</Td>
                        <Td>{message}</Td>
                        <Td>{code}</Td>
                        <Td>{expected}</Td>
                        <Td>{received}</Td>
                      </Tr>
                    );
                  }
                )}
            </Tbody>
          </Table>
        </GridItem>
      </SimpleGrid>
    </>
  );
};
