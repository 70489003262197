import * as z from 'zod';

export const OnboardingClient = z.object({
  industries: z
    .array(
      z
        .object({
          industry: z.object({
            id: z.string().uuid(),
            title: z.string(),
          }),
        })
        .nullish()
    )
    .refine((array) => new Set([...array.filter((v) => v)]).size >= 1, {
      message: 'Please select at least one option',
    }),
});

export const OnboardingCandidate = z.object({
  industries: z
    .array(
      z
        .object({
          industry: z.object({
            id: z.string().uuid(),
            title: z.string(),
          }),
        })
        .nullish()
    )
    .refine((array) => new Set([...array.filter((v) => v)]).size >= 1, {
      message: 'Please select at least one option',
    }),
});

export const ClientHasOnboarded = z.object({
  auth0_uid: z.string(),
  clients: z
    .array(
      z.object({
        id: z.string().uuid(),
      })
    )
    .min(1),
  contacts: z
    .array(
      z.object({
        id: z.string().uuid(),
      })
    )
    .min(1),
});

export const CandidateHasOnboarded = z.object({
  auth0_uid: z.string(),
  candidates: z
    .array(
      z.object({
        id: z.string().uuid(),
      })
    )
    .min(1),
});
