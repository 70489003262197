import { findRelativeElement } from "./utils";
export var defaultMobileLocation = {
    position: "fixed",
    top: 0,
    left: 0,
    transform: "translate(calc(50vw - 50%), calc(50vh - 50%))"
};
export function generateLocationCSS(location, cssSelector, setError) {
    try {
        if (location.startsWith("relative-") || location.startsWith("fixed-")) {
            var positionType = location.startsWith("relative-") ? "absolute" : "fixed";
            var positioning = location.split("-")[1];
            var referredElement = findRelativeElement(cssSelector);
            var referredElementRect = referredElement.getBoundingClientRect();
            if (positioning === "top") {
                return {
                    position: positionType,
                    top: referredElementRect.top - 10 + window.scrollY,
                    left: referredElementRect.left + referredElementRect.width / 2 + window.scrollX,
                    transform: "translate(-50%, -100%)"
                };
            } else if (positioning === "bottom") {
                return {
                    position: positionType,
                    top: referredElementRect.bottom + 10 + window.scrollY,
                    left: referredElementRect.left + referredElementRect.width / 2 + window.scrollX,
                    transform: "translateX(-50%)"
                };
            } else if (positioning === "left") {
                return {
                    position: positionType,
                    top: referredElementRect.top + referredElementRect.height / 2 + window.scrollY,
                    left: referredElementRect.left - 10 + window.scrollX,
                    transform: "translate(-100%, -50%)"
                };
            } else if (positioning === "right") {
                return {
                    position: positionType,
                    top: referredElementRect.top + referredElementRect.height / 2 + window.scrollY,
                    left: referredElementRect.right + 10 + window.scrollX,
                    transform: "translateY(-50%)"
                };
            } else {
                throw new Error("Unknown relative/fixed positioning: ".concat(positioning));
            }
        } else if (location === "fixed-center") {
            return {
                position: "fixed",
                top: "50%",
                left: "50%",
                transform: "translate(-50%, -50%)"
            };
        } else if (location === "fixed-bottom-right") {
            return {
                position: "fixed",
                bottom: "10px",
                right: "10px"
            };
        } else if (location === "fixed-bottom-left") {
            return {
                position: "fixed",
                bottom: "10px",
                left: "10px"
            };
        } else if (location === "fixed-top-right") {
            return {
                position: "fixed",
                top: "10px",
                right: "10px"
            };
        } else if (location === "fixed-top-left") {
            return {
                position: "fixed",
                top: "10px",
                left: "10px"
            };
        } else {
            throw new Error("Unknown location: ".concat(location));
        }
    } catch (e) {
        setError(true);
        console.error(e);
        return;
    }
}
export function generateTooltipPointerStyle(location, cssSelector, isMobile) {
    try {
        if (location.startsWith("relative-") || location.startsWith("fixed-")) {
            var positionType = location.startsWith("relative-") ? "absolute" : "fixed";
            var positioning = location.split("-")[1];
            if (isMobile && cssSelector) {
                var referredElement = findRelativeElement(cssSelector);
                var referredElementRect = referredElement.getBoundingClientRect();
                return {
                    position: positionType,
                    top: referredElementRect.bottom + 10,
                    left: referredElementRect.left + referredElementRect.width / 2,
                    transform: "translateX(-50%)"
                };
            }
            if (positioning === "top") {
                return {
                    position: positionType,
                    top: "100%",
                    left: "50%",
                    transform: "translateX(-50%)",
                    borderLeft: "10px solid transparent",
                    borderRight: "10px solid transparent",
                    borderTop: "10px solid white"
                };
            } else if (positioning === "bottom") {
                return {
                    position: positionType,
                    bottom: "100%",
                    left: "50%",
                    transform: "translateX(-50%)",
                    borderLeft: "10px solid transparent",
                    borderRight: "10px solid transparent",
                    borderBottom: "10px solid white"
                };
            } else if (positioning === "left") {
                return {
                    position: positionType,
                    top: "50%",
                    left: "100%",
                    transform: "translateY(-50%)",
                    borderTop: "10px solid transparent",
                    borderBottom: "10px solid transparent",
                    borderLeft: "10px solid white"
                };
            } else if (positioning === "right") {
                return {
                    position: positionType,
                    top: "50%",
                    right: "100%",
                    transform: "translateY(-50%)",
                    borderTop: "10px solid transparent",
                    borderBottom: "10px solid transparent",
                    borderRight: "10px solid white"
                };
            } else {
                throw new Error("Unknown relative/fixed location: ".concat(positioning));
            }
        }
    } catch (e) {
        console.error(e);
        return;
    }
}
