import { SegmentIdentify, SegmentPage, SegmentTrack } from './types';

// Fetch methods (IMPORTANT: these would be actions behind Hasura for now just invoke directly)
export const Page = ({ anonymousId, userId, context, path }: SegmentPage) => {
  if (anonymousId || userId) {
    try {
      fetch('/api/webhooks/analytics/segment/page', {
        method: 'POST',
        body: JSON.stringify({
          anonymousId,
          userId,
          path,
          context,
        }),
      });
    } catch (e) {
      console.error(e);
    }
  }
};

export const Identify = ({ anonymousId, userId, traits }: SegmentIdentify) => {
  try {
    fetch('/api/webhooks/analytics/segment/identify', {
      method: 'POST',
      body: JSON.stringify({
        anonymousId,
        userId,
        traits,
      }),
    });
  } catch (e) {
    console.error(e);
  }
};

export const Track = ({
  anonymousId,
  userId,
  context,
  event,
  properties,
}: SegmentTrack) => {
  try {
    fetch('/api/webhooks/analytics/segment/track', {
      method: 'POST',
      body: JSON.stringify({
        anonymousId,
        userId,
        context,
        event,
        properties,
      }),
    });
  } catch (e) {
    console.error(e);
  }
};
