import React from 'react';
import { useUser } from '@auth0/nextjs-auth0';
import {
  Box,
  Button,
  Heading,
  HStack,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Text,
  useColorModeValue,
  useDisclosure,
  VStack,
} from '@chakra-ui/react';
import { IconProp } from '@fortawesome/fontawesome-svg-core';
import { faCheck } from '@fortawesome/pro-light-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useRouter } from 'next/router';

const EmailSub: React.FC = () => {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const router = useRouter();
  const { user } = useUser();
  const color = useColorModeValue('gray.900', 'gray.200');
  return (
    <>
      <VStack spacing={5}>
        <Heading fontWeight="bold" size="md">
          Want to keep in the loop?
        </Heading>
        <Text textAlign="center" fontSize="sm">
          Join the Striver community and stay up to date with news and
          happenings in the Striver world.
        </Text>
        <Button
          variant="solid"
          colorScheme={'whiteAlpha'}
          color={color}
          onClick={() => onOpen()}
        >
          Join the Striver Community
        </Button>
      </VStack>

      <Modal isOpen={isOpen} onClose={onClose} isCentered={true}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>
            {user ? "You're all set" : 'Subscribe to our newsletter'}
          </ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            {user ? (
              <HStack>
                <Box color="green.400">
                  <FontAwesomeIcon icon={faCheck as IconProp} />
                </Box>
                <Text>
                  You&apos;re subscription to our newsletter is active
                </Text>
              </HStack>
            ) : (
              <Box>
                <Text mb={4}>
                  To subscribe to our newsletter sign up for a free Striver
                  account
                </Text>
                <Button
                  variant={'primary'}
                  onClick={() =>
                    window.location.assign(
                      `/api/auth/striver/signup?returnTo=/${router.query.tenant}`
                    )
                  }
                >
                  Sign up
                </Button>
              </Box>
            )}
          </ModalBody>

          <ModalFooter></ModalFooter>
        </ModalContent>
      </Modal>
    </>
  );
};

export { EmailSub };
