import { ComponentStyleConfig, extendTheme } from '@chakra-ui/react';
import { cssVar, mode, StyleFunctionProps } from '@chakra-ui/theme-tools';

const Card: ComponentStyleConfig = {
  baseStyle: (props) => ({
    boxShadow: 'sm',
    padding: 6,
    border: '1px solid',
    borderRadius: 'md',
    borderColor: mode('gray.300', 'gray.800')(props),
    bg: mode('white', 'transparent')(props),
  }),
};

const $arrowBg = cssVar('popper-arrow-bg');
const $arrowShadowColor = cssVar('popper-arrow-shadow-color');

const Popover: ComponentStyleConfig = {
  variants: {
    responsive: {
      popper: {
        maxWidth: 'unset',
        width: 'unset',
      },
    },
  },
  baseStyle: (props) => ({
    arrowBg: 'red.500',
    popper: {
      boxShadow: 'lg',
    },
    body: {},
    content: {
      bg: mode('white', 'black')(props),
      [$arrowBg.variable]: mode('white', 'black')(props),
      [$arrowShadowColor.variable]: mode('#CBD5E0', '#2D3748')(props),
    },
    arrow: {},
    footer: {},
  }),
};

const Tabs: ComponentStyleConfig = {
  baseStyle: (props) => ({
    tabpanel: {
      bg: mode('white', 'black')(props),
      border: '1px solid',
      borderColor: mode('gray.300', 'gray.900')(props),
      p: 4,
    },
    tablist: {
      mb: '-1px',
      border: 'none',
      button: {
        mr: 1,
        borderTopRadius: 'md',
        bg: mode('gray.100', 'none')(props),
        borderBottom: '1px solid',
        borderBottomColor: mode('gray.300', 'gray.900')(props),
        _selected: {
          bg: mode('white', 'black')(props),
          color: mode('black', 'white')(props),
          borderBottom: 'none',
          borderLeft: '1px solid',
          borderLeftColor: mode('gray.300', 'gray.900')(props),
          borderRight: '1px solid',
          borderRightColor: mode('gray.300', 'gray.900')(props),
          borderTop: '1px solid',
          borderTopColor: mode('gray.300', 'gray.900')(props),
        },
      },
    },
  }),
};

const Table: ComponentStyleConfig = {
  baseStyle: (props) => ({
    tbody: {
      tr: {
        '&:nth-of-type(even)': {
          'th, td': {
            borderBottomWidth: '1px',
            borderColor: mode('gray.200', 'whiteAlpha.50'),
          },
          td: {
            borderColor: mode('gray.200', 'whiteAlpha.50'),
          },
        },
        '&:nth-of-type(odd)': {
          'th, td': {
            borderBottomWidth: '1px',
            borderColor: mode('gray.200', 'whiteAlpha.50'),
          },
          td: {
            borderColor: mode('gray.200', 'whiteAlpha.50'),
          },
        },
      },
    },
  }),
};

const Menu: ComponentStyleConfig = {
  baseStyle: (props) => ({
    list: {
      boxShadow: 'lg',
      borderRadius: 0,
      bg: mode('white', 'black')(props),
    },
    menu: {
      borderRadius: 0,
      bg: mode('white', 'black')(props),
    },
    item: {
      bg: mode('white', 'black')(props),
    },
  }),
};

const Button: ComponentStyleConfig = {
  variants: {
    primary: (props) => ({
      rounded: 'full',
      color: 'white',
      backgroundColor: mode('brand.400', 'brand.400')(props),
      _focus: {
        ring: 3,
        ringColor: mode('brand.500', 'brand.100')(props),
      },
      _active: {
        backgroundColor: mode('brand.300', 'brand.500')(props),
      },
      _hover: {
        _disabled: {
          backgroundColor: mode('brand.300', 'brand.500')(props),
        },
        backgroundColor: mode('brand.300', 'brand.500')(props),
      },
    }),
    unsubmittable: (props) => ({
      rounded: 'full',
      color: 'gray.400',
      border: '1px solid',
      borderColor: mode('gray.200', 'gray.900')(props),
      _focus: {
        ring: 3,
        ringColor: mode('gray.300', 'gray.500')(props),
      },
      _hover: {
        cursor: 'not-allowed',
      },
    }),
  },
};

const theme = extendTheme({
  fonts: {
    heading: 'Heebo',
    body: 'Lato',
  },
  components: {
    Popover,
    Tabs,
    Table,
    Menu,
    Button,
    Card,
  },
  styles: {
    global: (props: StyleFunctionProps) => ({
      'html, body': {
        fontFamily: 'body',
        color: mode('gray.800', 'gray.100')(props),
        bg: mode('gray.200', 'black')(props),
        lineHeight: 'base',
        fontSize: 'sm',
      },
      '@supports (-webkit-overflow-scrolling: touch)': {
        'html, body': {
          fontSize: 'md',
        },
      },
      a: {
        color: mode('blue.600', 'brand.500')(props),
        _hover: {
          textDecoration: 'underline',
        },
      },
    }),
  },
  colors: {
    brand: {
      50: '#ffede5',
      100: '#fbcbb7',
      200: '#faa986',
      300: '#fa8654',
      400: '#fa6525',
      500: '#e14d10',
      600: '#af3c0b',
      700: '#7d2b07',
      800: '#4b1a05',
      900: '#1a0800',
    },
    'brand-secondary': {
      50: '#e7eafd',
      100: '#bac0f5',
      200: '#8c97ef',
      300: '#5f6dea',
      400: '#3644e6',
      500: '#1e05e5',
      600: '#1b229f',
      700: '#131870',
      800: '#090f43',
      900: '#020518',
    },
  },
});

export { theme };
