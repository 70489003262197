import { useUser } from '@auth0/nextjs-auth0';
import { getAuthorizedSdkWithHooks } from '@striver/sdk';
import { useCallback, useEffect, useState } from 'react';

type Id = {
  tenantId: string | undefined;
};

export function useTenancy(shortname: string): {
  isLoading: boolean;
  id: Id;
} {
  const [id, setId] = useState<Id>({
    tenantId: undefined,
  });
  const [isLoading, setLoading] = useState<boolean>(true);
  const { user: session } = useUser();
  const sdk = getAuthorizedSdkWithHooks();

  const getTenancyDetails = useCallback(async (): Promise<void> => {
    if (session) {
      const { tenants } = await sdk.unique_tenant_by_shortname({ shortname });
      const tenant = tenants[0];
      setId({ tenantId: tenant.id });
    }
  }, [session]);

  useEffect((): void => {
    if (id.tenantId) return;
    if (session && session.sub) {
      setLoading(true);
      getTenancyDetails()
        .finally(() => setLoading(false))
        .catch((e) => {
          console.error(e);
        });
    } else {
      setLoading(false);
    }
  }, [session]);

  return { isLoading, id };
}
