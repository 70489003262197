import parsePhoneNumberFromString from 'libphonenumber-js';
import * as z from 'zod';

export const CandidateSchema = z.object({
  first_name: z.string().min(1, { message: 'Please enter your first name' }),
  last_name: z.string().min(1, { message: 'Please enter your last name' }),
  email: z.string().email({ message: 'Valid email required' }),
  phone: z.string().refine(
    (value) => {
      const mobile = parsePhoneNumberFromString(value, 'AU');
      if (!mobile || !mobile.isValid() || !/^[0-9+]*$/.test(value)) {
        return false;
      }
      return true;
    },
    {
      message: 'Valid AU mobile required',
    }
  ),
});

export const Candidate = z.object({
  id: z.string().uuid(),
  owner_id: z.string(),
  created_at: z
    .preprocess((arg) => {
      if (typeof arg == 'string' || arg instanceof Date) return new Date(arg);
    }, z.date())
    .optional()
    .nullish(),
  updated_at: z
    .preprocess((arg) => {
      if (typeof arg == 'string' || arg instanceof Date) return new Date(arg);
    }, z.date())
    .optional()
    .nullish(),
  deleted_at: z
    .preprocess((arg) => {
      if (typeof arg == 'string' || arg instanceof Date) return new Date(arg);
    }, z.date())
    .optional()
    .nullish(),
  first_name: z.string().min(1, { message: 'Please enter your first name' }),
  last_name: z.string().min(1, { message: 'Please enter your last name' }),
  email: z.string().email({ message: 'Valid email required' }),
  phone: z.string().refine(
    (value) => {
      const mobile = parsePhoneNumberFromString(value, 'AU');
      if (!mobile || !mobile.isValid() || !/^[0-9+]*$/.test(value)) {
        return false;
      }
      return true;
    },
    {
      message: 'Valid AU mobile required',
    }
  ),
  photo_url: z.string().url().optional().nullish(),
  cv_url: z.string().url().optional().nullish(),
  transcripts_url: z.string().url().optional().nullish(),
  psych_url: z.string().url().optional().nullish(),
  bio: z
    .string()
    .min(300, { message: 'Please enter 300 or more characters' })
    .nullish(),
  notes: z.string().nullish(),
  has_work_experience: z.boolean().nullish(),
  work_arrangement: z.enum(['Remote', 'Office', 'Hybrid']),
  open_to_part_time_prior_to_graduation: z.boolean(),
  graduates_on: z.preprocess((arg) => {
    if (typeof arg == 'string' || arg instanceof Date) return new Date(arg);
  }, z.date()),
  graduates_on_month: z.string(),
  graduates_on_year: z.string(),
  available_part_time_days_from: z.number(),
  available_part_time_days_to: z.number(),
  education_level: z
    .enum(['Certifiate / Diploma', 'Undergraduate', 'Masters', 'PHD'])
    .nullish(),
  source: z.enum(['onboarding', 'enterprise']).nullish(),
  status: z.string(),
  university_id: z.string().uuid(),
  residency_status: z.enum([
    'Australian Citizen',
    'Australian PR',
    'New Zealand Citizen',
    'New Zealand PR',
    'Other',
  ]),
  candidate_locations: z.union([
    z.object({
      search: z.string(),
      locality: z.string(),
      postcode: z.string(),
      state: z.string(),
      coordinate: z.object({
        type: z.string(),
        coordinates: z.tuple([z.number(), z.number()]),
      }),
    }),
    z.array(z.any()).refine(
      () => {
        return false;
      },
      {
        message: 'Please select a location',
      }
    ),
  ]),
  candidate_industries: z.array(z.string().nullish()).refine(
    (array) => {
      const length = array.filter(
        (v, i, a) => a.findIndex((id) => (id && v ? id === v : undefined)) === i
      ).length;
      return length >= 1;
    },
    {
      message: 'Please select at least 1 option',
    }
  ),
  candidate_skills: z
    .array(z.string().nullish())
    .refine(
      (array) => {
        const length = array.filter(
          (v, i, a) =>
            a.findIndex((id) => (id && v ? id === v : undefined)) === i
        ).length;
        return length >= 3;
      },
      {
        message: 'Please select 3 skills',
      }
    )
    .refine(
      (array) => {
        const length = array.filter(
          (v, i, a) =>
            a.findIndex((id) => (id && v ? id === v : undefined)) === i
        ).length;
        return length <= 3;
      },
      {
        message: 'Please select 3 skills only',
      }
    ),
  candidate_degrees: z
    .array(
      z.object({
        id: z.string().uuid(),
        title: z.string(),
      })
    )
    .refine(
      (array) => {
        const length = array.filter(
          (v, i, a) =>
            a.findIndex((degree) =>
              degree && v ? degree.id === v.id : undefined
            ) === i
        ).length;
        return length >= 1;
      },
      {
        message: 'Please select a degree',
      }
    ),
  candidate_majors: z.array(
    z.object({
      id: z.string().uuid(),
      title: z.string(),
    })
  ),
});
