import { useUser } from '@auth0/nextjs-auth0';
import { getAuthorizedSdkWithHooks } from '@striver/sdk';
import { ClientHasOnboarded, CustomSafeParsedSchema } from '@striver/schema';
import { useCallback, useEffect, useState } from 'react';

type Ids = {
  clientId: string | undefined;
  contactId: string | undefined;
};

export function useClientOnboardingStatus(): {
  isLoading: boolean;
  isOnboarded: boolean | undefined;
  ids: Ids;
  isSessionLoading: boolean;
} {
  const [ids, setIds] = useState<Ids>({
    clientId: undefined,
    contactId: undefined,
  });
  const [isLoading, setLoading] = useState<boolean>(true);
  const [isOnboarded, setIsOnboarded] = useState<boolean>();
  const { user: session, isLoading: isSessionLoading } = useUser();
  const sdk = getAuthorizedSdkWithHooks();

  const checkOnboardingStatus = useCallback(async (): Promise<void> => {
    if (session) {
      const { users } = await sdk.user_by_auth0_uid({
        auth0_uid: session?.sub as string,
      });
      const user = users[0];
      const isClientHasOnboarded = ClientHasOnboarded.safeParse(
        user
      ) as CustomSafeParsedSchema;
      if (isClientHasOnboarded.success) {
        setIds({
          clientId: user.clients[0].id,
          contactId: user.contacts[0].id,
        });
        setIsOnboarded(true);
      }
    } else {
      setIsOnboarded(false);
    }
  }, [session]);

  useEffect(() => {
    if (isOnboarded) return;
    if (session && session.sub) {
      setLoading(true);
      checkOnboardingStatus()
        .finally(() => setLoading(false))
        .catch((e) => {
          console.error(e);
        });
    } else {
      setLoading(false);
    }
  }, [session]);

  return { isLoading: isLoading, isOnboarded, ids, isSessionLoading };
}
