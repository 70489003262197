export type OptionsKVPair = {
  id: string;
  title: string;
};

export type NameValPairs = {
  [name: string]: OptionsKVPair[];
};

export function checkboxArrToHasuraVarArr(
  arr: { id: string; title: string }[]
): string[] {
  return arr.map((obj) => obj.id);
}

export function hasuraVarArrToCheckboxArr(
  arr: {
    id: string;
    title: string;
  }[]
): { id: string; title: string }[] {
  if (!arr.length) {
    throw Error(
      `Argument passed to hasuraVarArrToCheckboxArr must be array of { id: string; title: string } pairs`
    );
  }
  return arr.map(({ id, title }) => ({ id, title }));
}

export const TIME_ZONES = [
  // Intl.DateTimeFormat().resolvedOptions().timeZone values
  'Australia/Sydney',
  'Australia/Melbourne',
  'Australia/Brisbane',
  'Australia/Adelaide',
  'Australia/Perth',
  'Australia/Hobart',
  'Australia/Darwin',
  'Pacific/Auckland',
  'Pacific/Chatham',
  'Pacific/Fakaofo',
  'Pacific/Tongatapu',
];

export const CALENDAR_MONTHS = [
  'January',
  'February',
  'March',
  'April',
  'May',
  'June',
  'July',
  'August',
  'September',
  'October',
  'November',
  'December',
];

export function convertToDoubleDigits(n: string | number): string {
  n = String(n);
  if (n.length == 1) n = '0' + n;
  return n;
}

export function sleep(ms: number): Promise<void> {
  return new Promise((resolve) => setTimeout(resolve, ms));
}

export function truncateString(str: string, num: number): string {
  if (str.length <= 0) return '';
  if (str.length <= num) {
    return str;
  }
  return `${str.slice(0, num)}...`;
}

export function isWhitelisted(
  input: string,
  whitelist: Array<string>
): boolean {
  const isAllowed = whitelist
    .map((elem) => input.includes(elem))
    .filter((elem) => elem === true).length;
  if (isAllowed === 0) {
    return false;
  }
  return true;
}
