import { differenceInDays } from 'date-fns';
import * as z from 'zod';

const EARLY_TOLERANCE_IN_DAYS = 900;

export const Interview = z.object({});

export const EligibleForScreening = z.object({
  graduates_on: z
    .string()
    .refine(
      (val) =>
        differenceInDays(new Date(val), new Date()) < EARLY_TOLERANCE_IN_DAYS,
      { message: 'Too early' }
    )
    .nullish(),
  // NOTE: removed at request of Ali on 11 April
  // has_work_experience: z.boolean().refine((val) => val === true, {
  //   message: 'Does not have work experience',
  // }),
  residency_status: z
    .string()
    .refine(
      (val) =>
        val === 'Australian Citizen' ||
        val === 'Australian PR' ||
        val === 'New Zealand Citizen' ||
        val === 'New Zealand PR',
      { message: 'Does not meet residency requirements' }
    )
    .nullish(),
});

export const CanBookScreeningInterview = EligibleForScreening.extend({
  status: z.string().refine((val) => val === 'Eligible', {
    message: 'Does not meet status requirements',
  }),
});

export const CanBookCoachingInterview = EligibleForScreening.extend({
  status: z.string().refine((val) => val === 'Passed Screening', {
    message: 'Does not meet status requirements',
  }),
});

export const CanBePublished = CanBookCoachingInterview.extend({
  status: z.string().refine((val) => val === 'Passed Coaching', {
    message: 'Does not meet status requirements',
  }),
});
