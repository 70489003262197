import { useUser } from '@auth0/nextjs-auth0';
import {
  Box,
  Button,
  Center,
  chakra,
  FormControl,
  FormLabel,
  Heading,
  HStack,
  Popover,
  PopoverArrow,
  PopoverBody,
  PopoverCloseButton,
  PopoverContent,
  PopoverHeader,
  PopoverTrigger,
  Text,
  Textarea,
  useColorModeValue,
  useRadio,
  useRadioGroup,
  useToast,
} from '@chakra-ui/react';
import { IconProp } from '@fortawesome/fontawesome-svg-core';
import { faPaperPlaneTop } from '@fortawesome/pro-light-svg-icons';
import { faMegaphone } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { WebClient } from '@slack/web-api';
import { useRouter } from 'next/router';
import React, { useState } from 'react';
import { useForm } from 'react-hook-form';

const token = process.env.NEXT_PUBLIC_SLACK_TOKEN;
const slack = new WebClient(token);
const conversationId = process.env.NEXT_PUBLIC_SLACK_CHANNEL_ID;

interface FormType {
  rating: 'Positive' | 'Neutral' | 'Negative';
  feedback: string;
}

const feedbackLevels = [
  {
    name: 'Positive',
    icon: `😃`,
  },
  {
    name: 'Neutral',
    icon: `🙂`,
  },
  {
    name: 'Negative',
    icon: `😐`,
  },
];

function CustomRadio(props: any) {
  const { icon, ...radioProps } = props;
  const { state, getInputProps, getCheckboxProps, htmlProps, getLabelProps } =
    useRadio(radioProps);
  const bg = useColorModeValue('gray.50', 'gray.900');
  const bgChecked = useColorModeValue('brand.100', 'brand.900');

  return (
    <chakra.label {...htmlProps} cursor="pointer">
      <input {...getInputProps({})} hidden />
      <Box
        {...getCheckboxProps()}
        bg={state.isChecked ? bgChecked : bg}
        rounded="full"
        px={2}
        fontSize="4xl"
      >
        <span {...getLabelProps()} role="img" aria-label="smile">
          {icon}
        </span>
      </Box>
    </chakra.label>
  );
}

export const FeatureFeedback: React.FC<{
  children?: React.ReactNode;
  featureName?: string;
}> = ({ children, featureName }) => {
  const router = useRouter();
  const [success, setSuccess] = useState(false);
  const { register, handleSubmit, setValue } = useForm<FormType>();

  const handleChange = (value: FormType['rating']) => {
    setValue('rating', value);
  };

  const { getRadioProps } = useRadioGroup({
    onChange: handleChange,
  });

  const { user } = useUser();
  const toast = useToast();

  const bg = useColorModeValue('brand-secondary.400', 'brand.900');
  const color = useColorModeValue('whiteAlpha.500', 'brand.200');

  async function handleSubmitFeedback(data: FormType) {
    if (!data.rating) {
      return alert('You must select a rating');
    }

    if (!user) {
      toast({
        status: 'error',
        title: "You can't leave feedback right now",
      });
      return;
    }

    await slack.chat.postMessage({
      blocks: [
        {
          type: 'section',
          text: {
            type: 'plain_text',
            text: featureName
              ? `📢 Feedback for feature "${featureName}" was left by user ${
                  user.email || user.sub
                } (${
                  (user['https://hasura.io/jwt/claims'] as any)[
                    'x-hasura-default-role'
                  ]
                })`
              : `General feedback was left by user ${user.email || user.sub} (${
                  (user['https://hasura.io/jwt/claims'] as any)[
                    'x-hasura-default-role'
                  ]
                })`,
          },
        },
        {
          type: 'section',
          text: {
            type: 'plain_text',
            text: `${router.asPath}`,
          },
        },
        { type: 'divider' },
        {
          type: 'section',
          text: {
            type: 'plain_text',
            text: `Rating: ${data.rating} ${
              feedbackLevels.find((fl) => fl.name === data.rating)?.icon
            }`,
          },
        },
        { type: 'divider' },
        {
          type: 'section',
          text: {
            type: 'plain_text',
            text: data.feedback || 'No feedback was provided',
          },
        },
      ],
      channel: conversationId || '',
    });

    setSuccess(true);
  }

  return (
    <Popover placement={'auto-end'}>
      <PopoverTrigger>
        {children ? (
          children
        ) : (
          <Button
            variant="unstyled"
            color={color}
            borderRadius={'full'}
            opacity={0.75}
            _hover={{ opacity: 1, bg }}
          >
            <FontAwesomeIcon icon={faMegaphone} />
          </Button>
        )}
      </PopoverTrigger>
      <PopoverContent>
        <PopoverArrow />
        <PopoverCloseButton />
        <PopoverHeader fontWeight={'bold'}>Feedback</PopoverHeader>
        <PopoverBody>
          {success ? (
            <Box py={4}>
              <Heading size="sm">
                Thank you for your feedback{' '}
                <span role="img" aria-label="smile">
                  👍🏻
                </span>
              </Heading>
            </Box>
          ) : (
            <form onSubmit={handleSubmit(handleSubmitFeedback)}>
              <FormControl mb={8} py={2}>
                <FormLabel>Rate your experience!</FormLabel>
                <Text mb={5}>
                  Sharing the good and bad parts of your experience with Striver
                  helps us to improve it!
                </Text>
                <Center>
                  <HStack>
                    {feedbackLevels.map((fl, i) => {
                      return (
                        <CustomRadio
                          key={fl.name}
                          icon={fl.icon}
                          {...getRadioProps({ value: fl.name })}
                        />
                      );
                    })}
                  </HStack>
                </Center>
              </FormControl>

              <FormControl mb={3}>
                <Textarea
                  {...register('feedback')}
                  placeholder="Share your feedback"
                />
              </FormControl>

              <FormControl mb={3}>
                <Button
                  type="submit"
                  rightIcon={
                    <FontAwesomeIcon icon={faPaperPlaneTop as IconProp} />
                  }
                  colorScheme="orange"
                  variant="outline"
                >
                  Submit feedback
                </Button>
              </FormControl>
            </form>
          )}
        </PopoverBody>
      </PopoverContent>
    </Popover>
  );
};
