import _instanceof from "@swc/helpers/src/_instanceof.mjs";
import { useState } from "react";
var useLocalStorage = function(key, defaultValue) {
    var ref = useState(function() {
        // Initialize the state
        try {
            if (key && defaultValue && window) {
                var value = window.localStorage.getItem(key);
                // Check if the local storage already has any values,
                // otherwise initialize it with the passed defaultValue
                if (!value) window.localStorage.setItem(key, JSON.stringify(defaultValue));
                return value ? JSON.parse(value) : defaultValue;
            }
        } catch (error) {
            console.error(error);
        }
    }), state = ref[0], setState = ref[1];
    var setValue = function(value) {
        var changeKey = arguments.length > 1 && arguments[1] !== void 0 ? arguments[1] : key;
        try {
            if (window) {
                // If the passed value is a callback function,
                //  then call it with the existing state.
                var valueToStore = _instanceof(value, Function) ? value(state) : value;
                window.localStorage.setItem(changeKey, JSON.stringify(valueToStore));
                setState(value);
            }
        } catch (error) {
            console.error(error);
        }
    };
    return [
        state,
        setValue
    ];
};
export default useLocalStorage;
