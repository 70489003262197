export const CANDIDATE_STATUS_ENUMS = {
  New: 'New',
  // Review: 'Review',
  Declined: 'Declined',
  Eligible: 'Eligible',
  'Failed Screening': 'Failed Screening',
  'Passed Screening': 'Passed Screening',
  'Passed Coaching': 'Passed Coaching',
  'Failed Coaching': 'Failed Coaching',
  // Interviewing: 'Interviewing',
  // Offer: 'Offer',
  Hired: 'Hired',
  Inactive: 'Inactive',
};

export const EVENT_ENUMS = {
  SignUp: 'Signed up',
  Identified: 'Identified',
  CreatedCandidate: 'Created candidate',
  CreatedClient: 'Created client',
  CreatedContact: 'Created contact',
  SignedTerms: 'Signed terms',
  CreatedJob: 'Created job',
  CreatedShareableURL: 'Created shareable URL',
  ShortlistedCandidate: 'Shortlisted candidate',
  ViewedCandidate: 'Viewed candidate',
  DownloadCV: 'Downloaded CV',
  ViewedEC: 'Viewed EC',
  ExpressedInterestInEC: 'Expressed interest in EC',
  UnfollowedEC: 'Candidate unfollowed EC',
  CandidatePlaced: 'Candidate placed',
  ViewedFollowPage: 'Viewed follows page',
  CompletedProfile: 'Completed Profile',
  CandidateViewedBrimstone: 'Candidate clicked Brimstone',
  CandidatePublished: 'Candidate published',
  CandidateUnpublished: 'Candidate unpublished',
  ClickScheduleInterview: 'Clicked schedule interview',
  ProposedInterview: 'Proposed interview',
  ClosedFeaturePopup: 'Closed feature popup',
  DeleteProfile: 'Deleted profile',
  UploadedVideo: 'Uploaded video',
  PlayedVideo: 'Played video',
  NoShow: 'Candidate no show',
  ClickedButton: 'Clicked Button',
  DownloadedResource: 'Downloaded resource',
};
