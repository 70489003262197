// This file configures the initialization of Sentry on the browser.
// The config you add here will be used whenever a page is visited.
// https://docs.sentry.io/platforms/javascript/guides/nextjs/
import * as Sentry from "@sentry/nextjs";
var SENTRY_DSN = process.env.SENTRY_DSN || process.env.NEXT_PUBLIC_SENTRY_DSN;
Sentry.init({
    dsn: SENTRY_DSN || "https://ac5ee9f5063f46eca503304e5827d881@o439687.ingest.sentry.io/5657109",
    // Adjust this value in production, or use tracesSampler for greater control
    tracesSampleRate: 1.0
});
