import _object_spread from "@swc/helpers/src/_object_spread.mjs";
import _object_spread_props from "@swc/helpers/src/_object_spread_props.mjs";
function getFeatureSessionStorageKey(featPopupId) {
    return "feat-popup-".concat(featPopupId);
}
export function shouldShowPopup(featPopupId) {
    // The feature flag should have be disabled for the user once the popup has been shown
    // This is a second check for shorter-term preventing of the popup from showing
    var flagNotShownBefore = !JSON.parse(localStorage.getItem(getFeatureSessionStorageKey(featPopupId)));
    return flagNotShownBefore;
}
export function closePopUp(param) {
    var featPopupId = param.featPopupId, payload = param.payload, setFeatPopups = param.setFeatPopups, buttonType = param.buttonType;
    localStorage.setItem(getFeatureSessionStorageKey(featPopupId), JSON.stringify(true));
    setFeatPopups(function(prevValue) {
        return prevValue.map(function(popup) {
            if (popup.id === payload.id) {
                return _object_spread_props(_object_spread({}, popup), {
                    isShown: true
                });
            }
            return popup;
        });
    });
    if (payload.primary_button_url && buttonType === "primary") {
        window.open(payload.primary_button_url, "_blank");
    }
}
export function findRelativeElement(cssSelector) {
    var el = document.querySelector(cssSelector);
    if (!el) {
        throw new Error("Could not find element with CSS selector: ".concat(cssSelector));
    }
    return el;
}
export function isReadyToShow(popupData, allPopups) {
    if (!popupData.goes_after) return true;
    // checking if the popup was already closed by user
    var prevInSequencePopup = allPopups.filter(function(popup) {
        return popup.id === popupData.goes_after;
    })[0];
    // if the popup was already shown - we shoul show next one in sequence
    return prevInSequencePopup.isShown;
}
export function normalisePopups(popupsData) {
    if (!popupsData) return [];
    var popups = popupsData.map(function(popup) {
        return _object_spread_props(_object_spread({}, popup), {
            isShown: !shouldShowPopup(popup.id)
        });
    });
    return popups;
}
