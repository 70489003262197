import { initAuth0 } from "@auth0/nextjs-auth0";
var clientID = process.env.AUTH0_VANTIAM_CLIENT_ID;
var clientSecret = process.env.AUTH0_VANTIAM_CLIENT_SECRET;
var issuerBaseURL = process.env.AUTH0_ISSUER_BASE_URL;
var secret = process.env.AUTH0_SECRET;
var scope = process.env.AUTH0_SCOPE;
var clockTolerance = parseInt(process.env.AUTH0_CLOCK_TOLERANCE, 10);
var vantiamAuth0 = initAuth0({
    clientID: clientID,
    clientSecret: clientSecret,
    issuerBaseURL: issuerBaseURL,
    secret: secret,
    clockTolerance: clockTolerance,
    authorizationParams: {
        scope: scope
    }
});
export default vantiamAuth0;
