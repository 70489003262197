import React, { useState } from 'react';
import { useUser } from '@auth0/nextjs-auth0';
import {
  Box,
  Button,
  Circle,
  Drawer,
  DrawerBody,
  DrawerCloseButton,
  DrawerContent,
  DrawerFooter,
  DrawerHeader,
  DrawerOverlay,
  Flex,
  Heading,
  HStack,
  Link,
  Menu,
  MenuButton,
  MenuDivider,
  MenuGroup,
  MenuItem,
  MenuList,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Stack,
  Text,
  useColorMode,
  useColorModeValue,
  useDisclosure,
} from '@chakra-ui/react';
import { IconProp } from '@fortawesome/fontawesome-svg-core';
import {
  faCar,
  faSignIn,
  faSignOut,
  faSunrise,
  faSunset,
} from '@fortawesome/pro-light-svg-icons';
import { faBars, faUserAlt } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { HasRole, Logo } from '@striver/ui';
import { useRouter } from 'next/router';
import { FeatureFeedback } from './feedback';
import { EVENT_ENUMS, getAuthorizedSdkWithHooks } from '@striver/sdk';

const StriverAvatar: React.FC<unknown> = () => {
  const imageBg = useColorModeValue('brand-secondary.400', 'brand.900');
  const color = useColorModeValue('whiteAlpha.500', 'brand.200');
  const { user } = useUser();
  if (user) {
    return (
      <HStack>
        <Circle
          size="35px"
          bg={imageBg}
          color={color}
          borderRadius={'full'}
          opacity={0.75}
          _hover={{ opacity: 1 }}
        >
          <FontAwesomeIcon icon={faUserAlt as IconProp} />
        </Circle>
      </HStack>
    );
  }
  return null;
};

export const Header: React.FC<{
  children?: React.ReactNode;
}> = ({ children }) => {
  const router = useRouter();
  const { user } = useUser();
  const sdk = getAuthorizedSdkWithHooks();
  const { data } = sdk.useUniqueTenantByShortname({
    shortname: router.query.tenant as string,
  });
  const tenantColor = data?.tenants[0]?.theme_bg_color || 'brand-secondary.500';
  const { isOpen, onOpen, onClose } = useDisclosure();
  const { colorMode, toggleColorMode } = useColorMode();
  const {
    isOpen: isOpenDeleteAccount,
    onOpen: onOpenDeleteAccount,
    onClose: onCloseDeleteAccount,
  } = useDisclosure();
  const [isLoading, setIsLoading] = useState(false);
  const tenantId = data?.tenants[0]?.id;

  const darkModeColor = useColorModeValue('gray.700', 'white');
  return (
    <>
      <Flex
        data-test-id="main-header"
        bg={useColorModeValue(tenantColor, 'black')}
        w="full"
        px={[2, 3, 4]}
        py={[2, 3, 4]}
        justifyContent="space-between"
        border="none"
      >
        <HStack mr={[4, 4, 4, 4, 4, 0]}>
          <Button
            variant="unstyled"
            borderRadius="none"
            onClick={onOpen}
            color="whiteAlpha.500"
            _hover={{ cursor: 'pointer' }}
          >
            <FontAwesomeIcon icon={faBars as IconProp} />
          </Button>
          <Link href="/">
            <Logo showText={user ? false : true} />
          </Link>
        </HStack>

        <Flex w="100%" justifyContent={'center'} alignItems={'center'}>
          {children}
        </Flex>

        <HStack align="center" spacing={5}>
          <FeatureFeedback featureName="Global" />

          {user ? (
            <Menu>
              <MenuButton
                as={Button}
                variant="unstyled"
                borderRadius="none"
                color="white"
                w="100%"
              >
                <Flex
                  justifyContent={['center', 'center', 'left']}
                  pl={['initial', 'initial', 0]}
                >
                  <StriverAvatar />
                </Flex>
              </MenuButton>
              <MenuList zIndex={1000}>
                <MenuGroup title="Account">
                  {user?.email ? (
                    <Box ml={4} mb={4} fontSize="xs">
                      {user.email}
                    </Box>
                  ) : (
                    <Box ml={4} mb={4} fontSize="xs">
                      {user.sub}
                    </Box>
                  )}

                  <HasRole roles={['tenant']}>
                    <MenuItem
                      onClick={() =>
                        router.push(
                          `/${
                            router.query.tenant
                              ? router.query.tenant
                              : 'striver'
                          }/manage`
                        )
                      }
                    >
                      <Box mr={1}>
                        <FontAwesomeIcon icon={faCar as IconProp} />
                      </Box>
                      Administration
                    </MenuItem>
                  </HasRole>

                  {user ? (
                    <MenuItem
                      onClick={() =>
                        window.location.assign(
                          `/api/auth/${router.query.tenant}/logout?returnTo=/${
                            router.query.tenant
                              ? router.query.tenant
                              : 'striver'
                          }`
                        )
                      }
                    >
                      <Box mr={1}>
                        <FontAwesomeIcon icon={faSignOut as IconProp} />
                      </Box>
                      Logout
                    </MenuItem>
                  ) : (
                    <MenuItem
                      onClick={() =>
                        window.location.assign(
                          `/api/auth/${router.query.tenant}/login?returnTo=/${
                            router.query.tenant
                              ? router.query.tenant
                              : 'striver'
                          }/begin`
                        )
                      }
                    >
                      <Box mr={1}>
                        <FontAwesomeIcon icon={faSignIn as IconProp} />
                      </Box>
                      Login
                    </MenuItem>
                  )}
                  <MenuDivider />
                  <MenuItem onClick={toggleColorMode}>
                    <Box
                      onClick={toggleColorMode}
                      fontSize="sm"
                      color={darkModeColor}
                    >
                      {colorMode === 'light' ? (
                        <HStack>
                          <FontAwesomeIcon icon={faSunset as IconProp} />
                          <Text>Enable Dark mode</Text>
                        </HStack>
                      ) : (
                        <HStack>
                          <FontAwesomeIcon icon={faSunrise as IconProp} />
                          <Text>Enable Light mode</Text>
                        </HStack>
                      )}
                    </Box>
                  </MenuItem>
                </MenuGroup>
              </MenuList>
            </Menu>
          ) : (
            <Button
              variant="unstyled"
              fontSize="sm"
              borderRadius="none"
              onClick={() =>
                window.location.assign(
                  `/api/auth/${router.query.tenant}/login?returnTo=/${
                    router.query.tenant ? router.query.tenant : 'striver'
                  }`
                )
              }
              color="white"
            >
              <Box mr={2} display="inline">
                <FontAwesomeIcon icon={faSignIn as IconProp} />
              </Box>
              Login
            </Button>
          )}
        </HStack>
      </Flex>

      <Drawer isOpen={isOpen} placement="left" onClose={onClose}>
        <DrawerOverlay>
          <DrawerContent bg={useColorModeValue('white', 'black')}>
            <DrawerCloseButton variant="unstyled" borderRadius="none" />
            <DrawerHeader></DrawerHeader>

            <DrawerBody>
              <Heading size="xs" mb={4}>
                Navigation
              </Heading>

              <Stack
                mb={8}
                spacing={3}
                fontSize="lg"
                height={['6vh', '6vh', '83vh']}
              >
                <Link href={`https://striver.careers`}>Home</Link>

                <HasRole roles={['candidate', 'public']}>
                  <Link href={`/enterprise/partners`}>Partners</Link>
                </HasRole>

                <HasRole roles={['client']}>
                  <Link
                    href={`/${
                      router.query.tenant ? router.query.tenant : 'striver'
                    }/people`}
                  >
                    People
                  </Link>
                </HasRole>

                <HasRole roles={['client']}>
                  <Link
                    href={`/${
                      router.query.tenant ? router.query.tenant : 'striver'
                    }/jobs`}
                  >
                    Jobs
                  </Link>
                </HasRole>

                <HasRole roles={['admin']}>
                  <Link
                    href={`/${
                      router.query.tenant ? router.query.tenant : 'striver'
                    }/manage/candidates`}
                  >
                    Administration
                  </Link>
                </HasRole>

                <HasRole roles={['candidate', 'admin']}>
                  <Link
                    href={
                      process.env.NEXT_PUBLIC_STRIVER_CANDIDATE_ONBOARDING_TERMS
                    }
                  >
                    Terms and conditions
                  </Link>
                </HasRole>

                <HasRole roles={['candidate']}>
                  <Text
                    onClick={onOpenDeleteAccount}
                    _hover={{ textDecor: 'underline', cursor: 'pointer' }}
                  >
                    Delete account
                  </Text>
                </HasRole>
              </Stack>

              <Box
                onClick={toggleColorMode}
                fontSize="sm"
                as="button"
                color={useColorModeValue('gray.700', 'white')}
              >
                {colorMode === 'light' ? (
                  <HStack>
                    <FontAwesomeIcon icon={faSunset as IconProp} />
                    <Text>Enable Dark mode</Text>
                  </HStack>
                ) : (
                  <HStack>
                    <FontAwesomeIcon icon={faSunrise as IconProp} />
                    <Text>Enable Light mode</Text>
                  </HStack>
                )}
              </Box>
            </DrawerBody>

            <DrawerFooter></DrawerFooter>
          </DrawerContent>
        </DrawerOverlay>
      </Drawer>

      <Modal isOpen={isOpenDeleteAccount} onClose={onCloseDeleteAccount}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>Delete Account</ModalHeader>
          <ModalCloseButton />
          <ModalBody>Are you sure you want to delete your account?</ModalBody>

          <ModalFooter>
            <Button
              variant="ghost"
              colorScheme="red"
              mr={3}
              isLoading={isLoading}
              onClick={async () => {
                setIsLoading(true);
                await sdk.create_event({
                  object: {
                    event: EVENT_ENUMS.DeleteProfile,
                    owner_id: user?.sub,
                    tenant_id: tenantId,
                  },
                });
                await sdk.delete_candidate_user({ auth0_uid: user?.sub });
                try {
                  fetch('/api/auth/delete', {
                    method: 'POST',
                    body: JSON.stringify({
                      ownerId: user?.sub,
                      email: user?.email,
                    }),
                  })
                    .then(() =>
                      window.location.replace('/api/auth/striver/logout')
                    )
                    .catch(() => {
                      setIsLoading(true);
                    });
                } catch (e) {
                  console.error(e);
                }
              }}
            >
              Confirm
            </Button>
            <Button onClick={onCloseDeleteAccount}>Close</Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </>
  );
};
