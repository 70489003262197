import * as z from 'zod';

export const Job = z.object({
  // client: z.object({
  //   id: z.string(),
  //   business_name: z.string(),
  // }),
  title: z
    .string()
    .min(1, {
      message: 'Please enter a value',
    })
    .nullish(),
  description: z.string().optional(),
  // job_industries: z
  //   .array(
  //     z
  //       .object({
  //         industry: z.object({
  //           id: z.string().uuid(),
  //           title: z.string(),
  //         }),
  //       })
  //       .nullish()
  //   )
  //   .refine(
  //     (array) => array.filter((v) => v),
  //     // .filter(
  //     //   ({ industry: v }, i, a) =>
  //     //     a.findIndex(({ industry }) => industry.id === v.id) === i
  //     // ).length >= 1,
  //     {
  //       message: 'Please select at least 1 option',
  //     }
  //   ),
  work_arrangement: z
    .string()
    .min(1, {
      message: 'Please choose an option',
    })
    .nullish(),
  // job_locations: z
  //   .array(
  //     z.object({
  //       location: z
  //         .object({
  //           locality: z.string().nullish(),
  //           state: z.string().nullish(),
  //           postcode: z.string().nullish(),
  //           coordinate: z.object({
  //             type: z.string(),
  //             coordinates: z.tuple([z.number(), z.number()]),
  //           }),
  //         })
  //         .nullish(),
  //     })
  //   )
  //   .min(1, {
  //     message: 'Job can only have a single location',
  //   }),
  // job_skills: z
  //   .array(
  //     z
  //       .object({
  //         skill: z.object({
  //           id: z.string().uuid(),
  //           title: z.string(),
  //         }),
  //       })
  //       .nullish() // can be undefined as a result of react-hook-form checkbox array behaviour
  //   )
  //   .refine(
  //     (array) => array.filter((v) => v),
  //     // .filter(
  //     //   ({ skill: v }, i, a) =>
  //     //     a.findIndex(({ skill }) => skill.id === v.id) === i
  //     // ).length >= 3,
  //     {
  //       message: 'Please select at least 3 options',
  //     }
  //   ),
  // job_degrees: z
  //   .array(
  //     z.object({
  //       degree: z.object({
  //         id: z.string().uuid(),
  //         title: z.string(),
  //       }),
  //     })
  //   )
  //   .optional(),
  // job_majors: z
  //   .array(
  //     z.object({
  //       major: z.object({
  //         id: z.string().uuid(),
  //         title: z.string(),
  //       }),
  //     })
  //   )
  //   .optional(),
  contract_type: z.string(),
  part_time_availability: z.number().array().optional(),
  open_to_part_time_prior_to_graduation: z.boolean().optional(),
  graduation_timeframe: z.string().optional().nullish(),
});
