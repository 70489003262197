import React from 'react';
import { Flex, Link, Text, VStack } from '@chakra-ui/react';
import { Logo } from '@striver/ui';
import { useRouter } from 'next/router';

const Footer: React.FC<unknown> = () => {
  const router = useRouter();
  return (
    <Flex justifyContent="center" py={10}>
      <VStack align="center">
        <Logo showText={false} fillPrimary={true} />
        <Text color="gray.500" mr={[1, 2, 4]} fontSize="sm">
          Striver Pty Ltd
        </Text>
        <Text color="gray.500" mr={[1, 2, 4]} fontSize="sm">
          Terms and Conditions:{' '}
          <a
            rel="noreferrer"
            target="_blank"
            href={process.env.BUSINESS_TERMS_URL}
            style={{ textDecoration: 'underline', color: 'inherit' }}
          >
            Business
          </a>{' '}
          <a
            rel="noreferrer"
            target="_blank"
            href={process.env.STUDENT_TERMS_URL}
            style={{ textDecoration: 'underline', color: 'inherit' }}
          >
            Student
          </a>{' '}
          |{' '}
          <Link
            style={{ textDecoration: 'underline' }}
            onClick={() => router.push('/privacy')}
          >
            Privacy Policy
          </Link>
        </Text>
        <Text color="gray.500" mr={[1, 2, 4]} fontSize="xs">
          @ Copyright {new Date().getFullYear()}
        </Text>
      </VStack>
    </Flex>
  );
};

export { Footer };
