import { useUser } from '@auth0/nextjs-auth0';
import { getAuthorizedSdkWithHooks } from '@striver/sdk';
import { CandidateHasOnboarded, CustomSafeParsedSchema } from '@striver/schema';
import { useCallback, useEffect, useState } from 'react';

type Ids = {
  candidateId: string | undefined;
};

export function useCandidateOnboardingStatus(): {
  isLoading: boolean;
  isOnboarded: boolean;
  ids: Ids;
} {
  const [ids, setIds] = useState<Ids>({ candidateId: undefined });
  const [isLoading, setLoading] = useState<boolean>(true);
  const [isOnboarded, setIsOnboarded] = useState<boolean>(false);
  const { user: session } = useUser();
  const sdk = getAuthorizedSdkWithHooks();

  const checkOnboardingStatus = useCallback(async (): Promise<void> => {
    if (session) {
      const { users } = await sdk.user_by_auth0_uid({
        auth0_uid: session?.sub as string,
      });
      const user = users[0];
      const isCandidateHasOnboarded = CandidateHasOnboarded.safeParse(
        user
      ) as CustomSafeParsedSchema;
      if (isCandidateHasOnboarded.success) {
        setIds({
          candidateId: user.candidates[0].id,
        });
        setIsOnboarded(true);
      }
    }
  }, [session]);

  useEffect((): void => {
    if (isOnboarded) return;
    if (session && session.sub) {
      setLoading(true);
      checkOnboardingStatus()
        .finally(() => setLoading(false))
        .catch((e) => {
          console.error(e);
        });
    } else {
      setLoading(false);
    }
  }, [session]);

  return { isLoading, isOnboarded, ids };
}
