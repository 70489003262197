import { useEffect } from 'react';
import Script from 'next/script';
import { useRouter } from 'next/router';
import { Spinner } from '@chakra-ui/react';
import { useSegment } from './analytics/react/segment';

export const pageview = (url: string) => {
  // @ts-ignore
  window.dataLayer.push({
    event: 'pageview',
    page: url,
  });
};

export const CustomHead: React.FC<{
  enable3rdPartyScripts: boolean;
  GTM_ID: string;
}> = ({ enable3rdPartyScripts, GTM_ID }) => {
  const router = useRouter();

  const {
    error,
    isLoading,
    anonymousId,
    userId,
    path,
    hasUtmParams,
    name,
    source,
    medium,
  } = useSegment();

  const a = anonymousId ? `"${anonymousId}"` : null;
  const b = userId ? `"${userId}"` : null;
  const c = path ? `${path}` : ``;
  const e = hasUtmParams ? `"${name}"` : null;
  const f = hasUtmParams ? `"${source}"` : null;
  const g = hasUtmParams ? `"${medium}"` : null;

  const hasAnonymousId = anonymousId ? true : false;
  const hasUserId = userId ? true : false;

  const dangerousHTML = `(async () => {
    fetch("/api/webhooks/analytics/segment/page", {
      method: "POST",
      body: JSON.stringify({
        ${hasAnonymousId ? `anonymousId: ${a},` : ``}
        ${hasUserId ? `userId: ${b},` : ``}
        path: "${c}",
        context: {
          campaign: {
            name: ${e},
            source: ${f},
            medium: ${g},
          }
        },
      }),
    });
  })()`;

  useEffect(() => {
    if (enable3rdPartyScripts) {
      router.events.on('routeChangeComplete', pageview);
      return () => {
        router.events.off('routeChangeComplete', pageview);
      };
    }
  }, [router.events, enable3rdPartyScripts]);

  if (isLoading) return <Spinner />;

  if (error) return <div>{error.message}</div>;

  return (
    <>
      <link rel="icon" href={'/favicon.ico'} />
      <Script
        id={`segment-analytics`}
        dangerouslySetInnerHTML={{
          __html: dangerousHTML,
        }}
      />
      <Script
        id="segment"
        dangerouslySetInnerHTML={
          enable3rdPartyScripts
            ? {
                __html: `!function(){var analytics=window.analytics=window.analytics||[];if(!analytics.initialize)if(analytics.invoked)window.console&&console.error&&console.error("Segment snippet included twice.");else{analytics.invoked=!0;analytics.methods=["trackSubmit","trackClick","trackLink","trackForm","pageview","identify","reset","group","track","ready","alias","debug","page","once","off","on","addSourceMiddleware","addIntegrationMiddleware","setAnonymousId","addDestinationMiddleware"];analytics.factory=function(e){return function(){var t=Array.prototype.slice.call(arguments);t.unshift(e);analytics.push(t);return analytics}};for(var e=0;e<analytics.methods.length;e++){var key=analytics.methods[e];analytics[key]=analytics.factory(key)}analytics.load=function(key,e){var t=document.createElement("script");t.type="text/javascript";t.async=!0;t.src="https://cdn.segment.com/analytics.js/v1/" + key + "/analytics.min.js";var n=document.getElementsByTagName("script")[0];n.parentNode.insertBefore(t,n);analytics._loadOptions=e};analytics._writeKey="Jo9rtyMhFvZlu8PzphWC0skY9QiSQJSS";;analytics.SNIPPET_VERSION="4.15.3";
    analytics.load("Jo9rtyMhFvZlu8PzphWC0skY9QiSQJSS");
    analytics.page();
    }}();`,
              }
            : undefined
        }
      />
      <Script
        id="gtag-base"
        strategy="afterInteractive"
        dangerouslySetInnerHTML={
          enable3rdPartyScripts
            ? {
                __html: `(function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':
                new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
                j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=
                'https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);
                })(window,document,'script','dataLayer','${GTM_ID}');`,
              }
            : undefined
        }
      />
      <Script
        id="hotjar"
        dangerouslySetInnerHTML={
          enable3rdPartyScripts
            ? {
                __html: `(function(h,o,t,j,a,r){
        h.hj=h.hj||function(){(h.hj.q=h.hj.q||[]).push(arguments)};
        h._hjSettings={hjid:2973095,hjsv:6};
        a=o.getElementsByTagName('head')[0];
        r=o.createElement('script');r.async=1;
        r.src=t+h._hjSettings.hjid+j+h._hjSettings.hjsv;
        a.appendChild(r);
    })(window,document,'https://static.hotjar.com/c/hotjar-','.js?sv=');`,
              }
            : undefined
        }
      />
    </>
  );
};
