import { motion } from 'framer-motion';
import React from 'react';

export const PageTransition: React.FC<{
  initial?: any;
  animate?: any;
  transition?: any;
  exit?: any;
  children: React.ReactNode;
}> = ({ initial, animate, transition, exit, children }) => {
  return (
    <motion.div
      initial={initial ? initial : { opacity: 0, x: '-50px' }}
      animate={animate ? animate : { opacity: 1, x: 0 }}
      transition={transition ? transition : { delay: 1, duration: 0.2 }}
      exit={
        exit
          ? exit
          : {
              opacity: 0,
              x: '-25px',
            }
      }
    >
      {children}
    </motion.div>
  );
};
