import React from 'react';
import { Box, Heading, SimpleGrid, Text } from '@chakra-ui/react';
import { IconProp } from '@fortawesome/fontawesome-svg-core';
import { faDoorOpen } from '@fortawesome/pro-thin-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { PageSpacer } from './page-spacer';

export const Halt: React.FC<{ message?: string }> = () => {
  return (
    <PageSpacer isCentered>
      <Box textAlign={'center'} mt={[40, 40, 150]}>
        <Box fontSize="xx-large">
          <FontAwesomeIcon icon={faDoorOpen as IconProp} />
        </Box>
        <Heading size="md" mb={2}>
          Access forbidden
        </Heading>
        <Text fontSize="sm">Please contact support for assistance</Text>
      </Box>
    </PageSpacer>
  );
};
