import parsePhoneNumberFromString from 'libphonenumber-js';
import * as z from 'zod';

export const ClientSchema = z.object({
  first_name: z.string().min(1, { message: 'Please enter your first name' }),
  last_name: z.string().min(1, { message: 'Please enter your last name' }),
  email: z.string().email({ message: 'Valid email required' }),
  phone: z.string().refine(
    (value) => {
      const mobile = parsePhoneNumberFromString(value, 'AU');
      if (!mobile || !mobile.isValid() || !/^[0-9+]*$/.test(value)) {
        return false;
      }
      return true;
    },
    {
      message: 'Valid AU mobile required',
    }
  ),
  domain: z
    .string()
    .optional()
    .refine(
      (value) => {
        if (!value) return true;

        return /^(https?:\/\/)(?:[a-z0-9](?:[a-z0-9-]{0,61}[a-z0-9])?\.)+[a-z0-9][a-z0-9-]{0,61}[a-z0-9]$/.test(
          value
        );
      },
      {
        message: 'Valid website URL required (begins with https:// or http://)',
      }
    ),
});

export const ClientProfileSchema = z.object({
  contacts: z.array(
    z.object({
      first_name: z
        .string()
        .min(1, { message: 'Please enter your first name' }),
      last_name: z.string().min(1, { message: 'Please enter your last name' }),
      email: z.string().email({ message: 'Valid email required' }),
      phone: z.string().refine(
        (value) => {
          const mobile = parsePhoneNumberFromString(value, 'AU');
          if (!mobile || !mobile.isValid() || !/^[0-9+]*$/.test(value)) {
            return false;
          }
          return true;
        },
        {
          message: 'Valid AU mobile required',
        }
      ),
    })
  ),
  notes: z.string().optional(),
  business_name: z
    .string()
    .min(1, { message: 'Please enter your company name' }),
  domain: z.string().optional(),
  client_locations: z.object({
    location: z
      .object({
        locality: z.string().nullish(),
        state: z.string().nullish(),
        postcode: z.string().nullish(),
        coordinate: z
          .object({
            type: z.string(),
            coordinates: z.tuple([z.number(), z.number()]),
          })
          .optional(),
      })
      .nullish(),
  }),
  client_industries: z
    .array(
      z
        .object({ id: z.string().nullish(), title: z.string().nullish() })
        .nullish()
    )
    .refine((array) => array.filter((i) => i).length >= 1, {
      message: 'Please select at least 1 option',
    }),
});

export const AdminProposeInterviewSchema = z
  .object({
    activeInterviewer: z.object({
      id: z.string().uuid(),
      email: z.string(),
      first_name: z.string(),
      last_name: z.string(),
    }),
    activeCandidate: z.object({
      id: z.string().uuid(),
      email: z.string(),
      first_name: z.string(),
      last_name: z.string(),
    }),
    job_title: z.string().min(1, {
      message: 'Position title required',
    }),
    proposedTime: z.number().min(1, 'Please select a time').optional(),
    mode: z.string().min(1, {
      message: 'Interview mode required',
    }),
    timeZone: z.string().min(1, {
      message: 'Please select a time zone',
    }),
    job_description: z.string().optional(),
    phone: z
      .string()
      .optional()
      .refine(
        (value) => {
          if (value) {
            const mobile = parsePhoneNumberFromString(value, 'AU');
            return mobile && mobile.isValid() && /^[0-9+]*$/.test(value);
          }
          return true;
        },
        {
          message: 'Valid AU mobile required',
        }
      ),
    meeting_link: z
      .string()
      .optional()
      .refine(
        (value) => {
          if (value) {
            return value.startsWith('http://') || value.startsWith('https://');
          }
          return true;
        },
        {
          message:
            'Valid meeting link required (begins with https:// or http://)',
        }
      ),
    location: z
      .string()
      .min(1, {
        message: 'Location requireds',
      })
      .optional(),
  })
  .refine(
    (data) => {
      if (data.mode === 'Online') {
        return Boolean(data.meeting_link);
      }
      return true;
    },
    {
      message: 'Meeting link required',
      path: ['meeting_link'],
    }
  )
  .refine(
    (data) => {
      if (data.mode === 'Phone') {
        return Boolean(data.phone);
      }
      return true;
    },
    {
      message: 'Phone number requied',
      path: ['phone'],
    }
  )
  .refine(
    (data) => {
      if (data.mode === 'Face-to-face') {
        return Boolean(data.location);
      }
      return true;
    },
    {
      message: 'Location required',
      path: ['location'],
    }
  );

export const ClientProposeInterviewSchema = z
  .object({
    job_title: z.string().min(1, {
      message: 'Position title required',
    }),
    proposedTime: z.number().min(1, 'Please select a time').optional(),
    mode: z.string().min(1, {
      message: 'Interview mode required',
    }),
    timeZone: z.string().min(1, {
      message: 'Please select a time zone',
    }),
    job_description: z.string().optional(),
    phone: z
      .string()
      .optional()
      .refine(
        (value) => {
          if (value) {
            const mobile = parsePhoneNumberFromString(value, 'AU');
            return mobile && mobile.isValid() && /^[0-9+]*$/.test(value);
          }
          return true;
        },
        {
          message: 'Valid AU mobile required',
        }
      ),
    meeting_link: z
      .string()
      .url({
        message:
          'Valid meeting link required (begins with https:// or http://)',
      }).optional()
      .or(z.literal('')),
    location: z
      .string()
      .min(1, {
        message: 'Location requireds',
      })
      .optional(),
  })
  .refine(
    (data) => {
      if (data.mode === 'Online') {
        return Boolean(data.meeting_link);
      }
      return true;
    },
    {
      message: 'Meeting link required',
      path: ['meeting_link'],
    }
  )
  .refine(
    (data) => {
      if (data.mode === 'Phone') {
        return Boolean(data.phone);
      }
      return true;
    },
    {
      message: 'Phone number requied',
      path: ['phone'],
    }
  )
  .refine(
    (data) => {
      if (data.mode === 'Face-to-face') {
        return Boolean(data.location);
      }
      return true;
    },
    {
      message: 'Location required',
      path: ['location'],
    }
  );
