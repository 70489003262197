import { GraphQLClient } from 'graphql-request';
import * as Dom from 'graphql-request/dist/types.dom';
import gql from 'graphql-tag';
import { ClientError } from 'graphql-request/dist/types';
import useSWR, { SWRConfiguration as SWRConfigInterface, Key as SWRKeyInterface } from 'swr';
export type Maybe<T> = T | null;
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] };
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> };
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> };
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: string;
  String: string;
  Boolean: boolean;
  Int: number;
  Float: number;
  candidate_status_enums: any;
  course_result_enums: any;
  event_type_enum: any;
  geography: any;
  geometry: any;
  graduation_timeframe_enums: any;
  highest_level_edu_enums: any;
  interview_mode_enums: any;
  interview_outcome_enums: any;
  interview_status_enums: any;
  interview_type_enums: any;
  job_status_enums: any;
  jobs_contract_type_enums: any;
  json: any;
  jsonb: any;
  location_name_enums: any;
  name: any;
  offer_status_enums: any;
  residency_status_enums: any;
  short_url_action_enums: any;
  skill_score_enums: any;
  timestamp: any;
  timestamptz: any;
  user_role_enums: any;
  uuid: any;
  work_arrangement_enums: any;
};


/** Boolean expression to compare columns of type "Boolean". All fields are combined with logical 'AND'. */
export type Boolean_Comparison_Exp = {
  _eq?: Maybe<Scalars['Boolean']>;
  _gt?: Maybe<Scalars['Boolean']>;
  _gte?: Maybe<Scalars['Boolean']>;
  _in?: Maybe<Array<Scalars['Boolean']>>;
  _is_null?: Maybe<Scalars['Boolean']>;
  _lt?: Maybe<Scalars['Boolean']>;
  _lte?: Maybe<Scalars['Boolean']>;
  _neq?: Maybe<Scalars['Boolean']>;
  _nin?: Maybe<Array<Scalars['Boolean']>>;
};

export type CandidateLocation = {
  __typename?: 'CandidateLocation';
  location?: Maybe<Location>;
};

export type FbServerEventInput = {
  candidateId: Scalars['String'];
  emails?: Maybe<Array<Maybe<Scalars['String']>>>;
  event: Scalars['String'];
  ownerId: Scalars['String'];
  phones?: Maybe<Array<Maybe<Scalars['String']>>>;
  sourceUrl: Scalars['String'];
  tenantId: Scalars['String'];
};

export type FormOption = {
  __typename?: 'FormOption';
  id: Scalars['uuid'];
  title: Scalars['String'];
};

export type FormOptionsOutput = {
  __typename?: 'FormOptionsOutput';
  candidate_status?: Maybe<Array<Maybe<Kv>>>;
  contract_type?: Maybe<Array<Maybe<Kv>>>;
  degrees?: Maybe<Array<Maybe<Kv>>>;
  industries?: Maybe<Array<Maybe<Kv>>>;
  job_status?: Maybe<Array<Maybe<Kv>>>;
  majors?: Maybe<Array<Maybe<Kv>>>;
  skills?: Maybe<Array<Maybe<Kv>>>;
  universities?: Maybe<Array<Maybe<Kv>>>;
  work_arrangement?: Maybe<Array<Maybe<Kv>>>;
};

/** Boolean expression to compare columns of type "Int". All fields are combined with logical 'AND'. */
export type Int_Comparison_Exp = {
  _eq?: Maybe<Scalars['Int']>;
  _gt?: Maybe<Scalars['Int']>;
  _gte?: Maybe<Scalars['Int']>;
  _in?: Maybe<Array<Scalars['Int']>>;
  _is_null?: Maybe<Scalars['Boolean']>;
  _lt?: Maybe<Scalars['Int']>;
  _lte?: Maybe<Scalars['Int']>;
  _neq?: Maybe<Scalars['Int']>;
  _nin?: Maybe<Array<Scalars['Int']>>;
};

export type Kv = {
  __typename?: 'KV';
  id: Scalars['uuid'];
  title: Scalars['String'];
};

export type Location = {
  __typename?: 'Location';
  locality: Scalars['String'];
  postcode: Scalars['String'];
  state: Scalars['String'];
};

export type Nylas_GetCalendarsOutput = {
  __typename?: 'Nylas_GetCalendarsOutput';
  account_id?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  is_primary?: Maybe<Scalars['Boolean']>;
  name?: Maybe<Scalars['String']>;
  read_only?: Maybe<Scalars['Boolean']>;
  timezone?: Maybe<Scalars['String']>;
};

export type Nylas_GetEventByIdInput = {
  event_id?: Maybe<Scalars['String']>;
};

export type Nylas_GetEventByIdOutput = {
  __typename?: 'Nylas_GetEventByIdOutput';
  id?: Maybe<Scalars['String']>;
  status?: Maybe<Scalars['String']>;
};

export type Nylas_PostCalendarAvailabilityInput = {
  access_token?: Maybe<Scalars['String']>;
  duration_minutes?: Maybe<Scalars['Int']>;
  emails?: Maybe<Array<Maybe<Scalars['String']>>>;
  end_time?: Maybe<Scalars['Int']>;
  interval_minutes?: Maybe<Scalars['Int']>;
  start_time?: Maybe<Scalars['Int']>;
  timezone?: Maybe<Scalars['String']>;
};

export type Nylas_PostCalendarAvailabilityOutput = {
  __typename?: 'Nylas_PostCalendarAvailabilityOutput';
  time_slots?: Maybe<Array<Maybe<Nylas_TimeSlot>>>;
};

export type Nylas_PostEventInput = {
  access_token?: Maybe<Scalars['String']>;
  calendar_id?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  end_time?: Maybe<Scalars['Int']>;
  interview_id?: Maybe<Scalars['uuid']>;
  location?: Maybe<Scalars['String']>;
  participants: Scalars['json'];
  start_time?: Maybe<Scalars['Int']>;
  time_zone?: Maybe<Scalars['String']>;
  title?: Maybe<Scalars['String']>;
};

export type Nylas_PostEventOutput = {
  __typename?: 'Nylas_PostEventOutput';
  id?: Maybe<Scalars['Int']>;
  status?: Maybe<Scalars['String']>;
};

export type Nylas_TimeSlot = {
  __typename?: 'Nylas_TimeSlot';
  end_time?: Maybe<Scalars['Int']>;
  start_time?: Maybe<Scalars['Int']>;
  status?: Maybe<Scalars['String']>;
};

export type PartTimeAvailability = {
  __typename?: 'PartTimeAvailability';
  available_part_time_days_from?: Maybe<Scalars['Int']>;
  available_part_time_days_to?: Maybe<Scalars['Int']>;
};

export type SendCandidateFirstTimeViewEmail = {
  candidate_id?: Maybe<Scalars['uuid']>;
};

export type SendCandidateNoShowEmail = {
  candidate_id?: Maybe<Scalars['uuid']>;
};

/** Boolean expression to compare columns of type "String". All fields are combined with logical 'AND'. */
export type String_Comparison_Exp = {
  _eq?: Maybe<Scalars['String']>;
  _gt?: Maybe<Scalars['String']>;
  _gte?: Maybe<Scalars['String']>;
  /** does the column match the given case-insensitive pattern */
  _ilike?: Maybe<Scalars['String']>;
  _in?: Maybe<Array<Scalars['String']>>;
  /** does the column match the given POSIX regular expression, case insensitive */
  _iregex?: Maybe<Scalars['String']>;
  _is_null?: Maybe<Scalars['Boolean']>;
  /** does the column match the given pattern */
  _like?: Maybe<Scalars['String']>;
  _lt?: Maybe<Scalars['String']>;
  _lte?: Maybe<Scalars['String']>;
  _neq?: Maybe<Scalars['String']>;
  /** does the column NOT match the given case-insensitive pattern */
  _nilike?: Maybe<Scalars['String']>;
  _nin?: Maybe<Array<Scalars['String']>>;
  /** does the column NOT match the given POSIX regular expression, case insensitive */
  _niregex?: Maybe<Scalars['String']>;
  /** does the column NOT match the given pattern */
  _nlike?: Maybe<Scalars['String']>;
  /** does the column NOT match the given POSIX regular expression, case sensitive */
  _nregex?: Maybe<Scalars['String']>;
  /** does the column NOT match the given SQL regular expression */
  _nsimilar?: Maybe<Scalars['String']>;
  /** does the column match the given POSIX regular expression, case sensitive */
  _regex?: Maybe<Scalars['String']>;
  /** does the column match the given SQL regular expression */
  _similar?: Maybe<Scalars['String']>;
};

export type TrackInput = {
  candidateId?: Maybe<Scalars['String']>;
  clientId?: Maybe<Scalars['String']>;
  contactId?: Maybe<Scalars['String']>;
  context?: Maybe<Scalars['String']>;
  event: Scalars['String'];
  interviewId?: Maybe<Scalars['String']>;
  jobId?: Maybe<Scalars['String']>;
  ownerId: Scalars['String'];
  source?: Maybe<Scalars['String']>;
  tenantId?: Maybe<Scalars['String']>;
};

export type UniqueCandidateOutputAlt = {
  __typename?: 'UniqueCandidateOutputAlt';
  bio?: Maybe<Scalars['String']>;
  candidate_degrees?: Maybe<Array<Maybe<FormOption>>>;
  candidate_industries?: Maybe<Array<Maybe<Scalars['String']>>>;
  candidate_locations?: Maybe<Array<Maybe<CandidateLocation>>>;
  candidate_majors?: Maybe<Array<Maybe<FormOption>>>;
  candidate_skills?: Maybe<Array<Maybe<Scalars['String']>>>;
  created_at: Scalars['timestamptz'];
  cv_url?: Maybe<Scalars['String']>;
  email?: Maybe<Scalars['String']>;
  first_name?: Maybe<Scalars['String']>;
  graduates_on?: Maybe<Scalars['timestamptz']>;
  graduates_on_month?: Maybe<Scalars['String']>;
  graduates_on_year?: Maybe<Scalars['String']>;
  has_work_experience?: Maybe<Scalars['Boolean']>;
  id: Scalars['uuid'];
  last_name?: Maybe<Scalars['String']>;
  open_to_part_time_prior_to_graduation?: Maybe<Scalars['Boolean']>;
  owner_id?: Maybe<Scalars['String']>;
  part_time_availability?: Maybe<PartTimeAvailability>;
  phone?: Maybe<Scalars['String']>;
  photo_url?: Maybe<Scalars['String']>;
  profile_progress?: Maybe<Scalars['Int']>;
  psych_url?: Maybe<Scalars['String']>;
  published?: Maybe<Scalars['Boolean']>;
  published_on?: Maybe<Scalars['timestamptz']>;
  relocate?: Maybe<Scalars['Boolean']>;
  residency_status?: Maybe<Scalars['String']>;
  status?: Maybe<Scalars['String']>;
  transcripts_url?: Maybe<Scalars['String']>;
  university_id?: Maybe<Scalars['uuid']>;
  updated_at: Scalars['timestamptz'];
  work_arrangement?: Maybe<Scalars['String']>;
};

/** columns and relationships of "brimstone_event_feedback" */
export type Brimstone_Event_Feedback = {
  __typename?: 'brimstone_event_feedback';
  additional_feedback?: Maybe<Scalars['String']>;
  /** An object relationship */
  brimstone_event?: Maybe<Brimstone_Events>;
  brimstone_event_id?: Maybe<Scalars['uuid']>;
  created_at: Scalars['timestamptz'];
  deleted_at?: Maybe<Scalars['timestamptz']>;
  id: Scalars['uuid'];
  interested?: Maybe<Scalars['Boolean']>;
  owner_id?: Maybe<Scalars['String']>;
  scale_rating: Scalars['Int'];
  updated_at: Scalars['timestamptz'];
  /** An object relationship */
  user?: Maybe<Users>;
};

/** aggregated selection of "brimstone_event_feedback" */
export type Brimstone_Event_Feedback_Aggregate = {
  __typename?: 'brimstone_event_feedback_aggregate';
  aggregate?: Maybe<Brimstone_Event_Feedback_Aggregate_Fields>;
  nodes: Array<Brimstone_Event_Feedback>;
};

export type Brimstone_Event_Feedback_Aggregate_Bool_Exp = {
  bool_and?: Maybe<Brimstone_Event_Feedback_Aggregate_Bool_Exp_Bool_And>;
  bool_or?: Maybe<Brimstone_Event_Feedback_Aggregate_Bool_Exp_Bool_Or>;
  count?: Maybe<Brimstone_Event_Feedback_Aggregate_Bool_Exp_Count>;
};

export type Brimstone_Event_Feedback_Aggregate_Bool_Exp_Bool_And = {
  arguments: Brimstone_Event_Feedback_Select_Column_Brimstone_Event_Feedback_Aggregate_Bool_Exp_Bool_And_Arguments_Columns;
  distinct?: Maybe<Scalars['Boolean']>;
  filter?: Maybe<Brimstone_Event_Feedback_Bool_Exp>;
  predicate: Boolean_Comparison_Exp;
};

export type Brimstone_Event_Feedback_Aggregate_Bool_Exp_Bool_Or = {
  arguments: Brimstone_Event_Feedback_Select_Column_Brimstone_Event_Feedback_Aggregate_Bool_Exp_Bool_Or_Arguments_Columns;
  distinct?: Maybe<Scalars['Boolean']>;
  filter?: Maybe<Brimstone_Event_Feedback_Bool_Exp>;
  predicate: Boolean_Comparison_Exp;
};

export type Brimstone_Event_Feedback_Aggregate_Bool_Exp_Count = {
  arguments?: Maybe<Array<Brimstone_Event_Feedback_Select_Column>>;
  distinct?: Maybe<Scalars['Boolean']>;
  filter?: Maybe<Brimstone_Event_Feedback_Bool_Exp>;
  predicate: Int_Comparison_Exp;
};

/** aggregate fields of "brimstone_event_feedback" */
export type Brimstone_Event_Feedback_Aggregate_Fields = {
  __typename?: 'brimstone_event_feedback_aggregate_fields';
  avg?: Maybe<Brimstone_Event_Feedback_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<Brimstone_Event_Feedback_Max_Fields>;
  min?: Maybe<Brimstone_Event_Feedback_Min_Fields>;
  stddev?: Maybe<Brimstone_Event_Feedback_Stddev_Fields>;
  stddev_pop?: Maybe<Brimstone_Event_Feedback_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Brimstone_Event_Feedback_Stddev_Samp_Fields>;
  sum?: Maybe<Brimstone_Event_Feedback_Sum_Fields>;
  var_pop?: Maybe<Brimstone_Event_Feedback_Var_Pop_Fields>;
  var_samp?: Maybe<Brimstone_Event_Feedback_Var_Samp_Fields>;
  variance?: Maybe<Brimstone_Event_Feedback_Variance_Fields>;
};


/** aggregate fields of "brimstone_event_feedback" */
export type Brimstone_Event_Feedback_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<Brimstone_Event_Feedback_Select_Column>>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "brimstone_event_feedback" */
export type Brimstone_Event_Feedback_Aggregate_Order_By = {
  avg?: Maybe<Brimstone_Event_Feedback_Avg_Order_By>;
  count?: Maybe<Order_By>;
  max?: Maybe<Brimstone_Event_Feedback_Max_Order_By>;
  min?: Maybe<Brimstone_Event_Feedback_Min_Order_By>;
  stddev?: Maybe<Brimstone_Event_Feedback_Stddev_Order_By>;
  stddev_pop?: Maybe<Brimstone_Event_Feedback_Stddev_Pop_Order_By>;
  stddev_samp?: Maybe<Brimstone_Event_Feedback_Stddev_Samp_Order_By>;
  sum?: Maybe<Brimstone_Event_Feedback_Sum_Order_By>;
  var_pop?: Maybe<Brimstone_Event_Feedback_Var_Pop_Order_By>;
  var_samp?: Maybe<Brimstone_Event_Feedback_Var_Samp_Order_By>;
  variance?: Maybe<Brimstone_Event_Feedback_Variance_Order_By>;
};

/** input type for inserting array relation for remote table "brimstone_event_feedback" */
export type Brimstone_Event_Feedback_Arr_Rel_Insert_Input = {
  data: Array<Brimstone_Event_Feedback_Insert_Input>;
  /** upsert condition */
  on_conflict?: Maybe<Brimstone_Event_Feedback_On_Conflict>;
};

/** aggregate avg on columns */
export type Brimstone_Event_Feedback_Avg_Fields = {
  __typename?: 'brimstone_event_feedback_avg_fields';
  scale_rating?: Maybe<Scalars['Float']>;
};

/** order by avg() on columns of table "brimstone_event_feedback" */
export type Brimstone_Event_Feedback_Avg_Order_By = {
  scale_rating?: Maybe<Order_By>;
};

/** Boolean expression to filter rows from the table "brimstone_event_feedback". All fields are combined with a logical 'AND'. */
export type Brimstone_Event_Feedback_Bool_Exp = {
  _and?: Maybe<Array<Brimstone_Event_Feedback_Bool_Exp>>;
  _not?: Maybe<Brimstone_Event_Feedback_Bool_Exp>;
  _or?: Maybe<Array<Brimstone_Event_Feedback_Bool_Exp>>;
  additional_feedback?: Maybe<String_Comparison_Exp>;
  brimstone_event?: Maybe<Brimstone_Events_Bool_Exp>;
  brimstone_event_id?: Maybe<Uuid_Comparison_Exp>;
  created_at?: Maybe<Timestamptz_Comparison_Exp>;
  deleted_at?: Maybe<Timestamptz_Comparison_Exp>;
  id?: Maybe<Uuid_Comparison_Exp>;
  interested?: Maybe<Boolean_Comparison_Exp>;
  owner_id?: Maybe<String_Comparison_Exp>;
  scale_rating?: Maybe<Int_Comparison_Exp>;
  updated_at?: Maybe<Timestamptz_Comparison_Exp>;
  user?: Maybe<Users_Bool_Exp>;
};

/** unique or primary key constraints on table "brimstone_event_feedback" */
export enum Brimstone_Event_Feedback_Constraint {
  /** unique or primary key constraint on columns "id" */
  BrimstoneEventFeedbackPkey = 'brimstone_event_feedback_pkey'
}

/** input type for incrementing numeric columns in table "brimstone_event_feedback" */
export type Brimstone_Event_Feedback_Inc_Input = {
  scale_rating?: Maybe<Scalars['Int']>;
};

/** input type for inserting data into table "brimstone_event_feedback" */
export type Brimstone_Event_Feedback_Insert_Input = {
  additional_feedback?: Maybe<Scalars['String']>;
  brimstone_event?: Maybe<Brimstone_Events_Obj_Rel_Insert_Input>;
  brimstone_event_id?: Maybe<Scalars['uuid']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  deleted_at?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['uuid']>;
  interested?: Maybe<Scalars['Boolean']>;
  owner_id?: Maybe<Scalars['String']>;
  scale_rating?: Maybe<Scalars['Int']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
  user?: Maybe<Users_Obj_Rel_Insert_Input>;
};

/** aggregate max on columns */
export type Brimstone_Event_Feedback_Max_Fields = {
  __typename?: 'brimstone_event_feedback_max_fields';
  additional_feedback?: Maybe<Scalars['String']>;
  brimstone_event_id?: Maybe<Scalars['uuid']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  deleted_at?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['uuid']>;
  owner_id?: Maybe<Scalars['String']>;
  scale_rating?: Maybe<Scalars['Int']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** order by max() on columns of table "brimstone_event_feedback" */
export type Brimstone_Event_Feedback_Max_Order_By = {
  additional_feedback?: Maybe<Order_By>;
  brimstone_event_id?: Maybe<Order_By>;
  created_at?: Maybe<Order_By>;
  deleted_at?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  owner_id?: Maybe<Order_By>;
  scale_rating?: Maybe<Order_By>;
  updated_at?: Maybe<Order_By>;
};

/** aggregate min on columns */
export type Brimstone_Event_Feedback_Min_Fields = {
  __typename?: 'brimstone_event_feedback_min_fields';
  additional_feedback?: Maybe<Scalars['String']>;
  brimstone_event_id?: Maybe<Scalars['uuid']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  deleted_at?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['uuid']>;
  owner_id?: Maybe<Scalars['String']>;
  scale_rating?: Maybe<Scalars['Int']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** order by min() on columns of table "brimstone_event_feedback" */
export type Brimstone_Event_Feedback_Min_Order_By = {
  additional_feedback?: Maybe<Order_By>;
  brimstone_event_id?: Maybe<Order_By>;
  created_at?: Maybe<Order_By>;
  deleted_at?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  owner_id?: Maybe<Order_By>;
  scale_rating?: Maybe<Order_By>;
  updated_at?: Maybe<Order_By>;
};

/** response of any mutation on the table "brimstone_event_feedback" */
export type Brimstone_Event_Feedback_Mutation_Response = {
  __typename?: 'brimstone_event_feedback_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Brimstone_Event_Feedback>;
};

/** on_conflict condition type for table "brimstone_event_feedback" */
export type Brimstone_Event_Feedback_On_Conflict = {
  constraint: Brimstone_Event_Feedback_Constraint;
  update_columns?: Array<Brimstone_Event_Feedback_Update_Column>;
  where?: Maybe<Brimstone_Event_Feedback_Bool_Exp>;
};

/** Ordering options when selecting data from "brimstone_event_feedback". */
export type Brimstone_Event_Feedback_Order_By = {
  additional_feedback?: Maybe<Order_By>;
  brimstone_event?: Maybe<Brimstone_Events_Order_By>;
  brimstone_event_id?: Maybe<Order_By>;
  created_at?: Maybe<Order_By>;
  deleted_at?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  interested?: Maybe<Order_By>;
  owner_id?: Maybe<Order_By>;
  scale_rating?: Maybe<Order_By>;
  updated_at?: Maybe<Order_By>;
  user?: Maybe<Users_Order_By>;
};

/** primary key columns input for table: brimstone_event_feedback */
export type Brimstone_Event_Feedback_Pk_Columns_Input = {
  id: Scalars['uuid'];
};

/** select columns of table "brimstone_event_feedback" */
export enum Brimstone_Event_Feedback_Select_Column {
  /** column name */
  AdditionalFeedback = 'additional_feedback',
  /** column name */
  BrimstoneEventId = 'brimstone_event_id',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  DeletedAt = 'deleted_at',
  /** column name */
  Id = 'id',
  /** column name */
  Interested = 'interested',
  /** column name */
  OwnerId = 'owner_id',
  /** column name */
  ScaleRating = 'scale_rating',
  /** column name */
  UpdatedAt = 'updated_at'
}

/** select "brimstone_event_feedback_aggregate_bool_exp_bool_and_arguments_columns" columns of table "brimstone_event_feedback" */
export enum Brimstone_Event_Feedback_Select_Column_Brimstone_Event_Feedback_Aggregate_Bool_Exp_Bool_And_Arguments_Columns {
  /** column name */
  Interested = 'interested'
}

/** select "brimstone_event_feedback_aggregate_bool_exp_bool_or_arguments_columns" columns of table "brimstone_event_feedback" */
export enum Brimstone_Event_Feedback_Select_Column_Brimstone_Event_Feedback_Aggregate_Bool_Exp_Bool_Or_Arguments_Columns {
  /** column name */
  Interested = 'interested'
}

/** input type for updating data in table "brimstone_event_feedback" */
export type Brimstone_Event_Feedback_Set_Input = {
  additional_feedback?: Maybe<Scalars['String']>;
  brimstone_event_id?: Maybe<Scalars['uuid']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  deleted_at?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['uuid']>;
  interested?: Maybe<Scalars['Boolean']>;
  owner_id?: Maybe<Scalars['String']>;
  scale_rating?: Maybe<Scalars['Int']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** aggregate stddev on columns */
export type Brimstone_Event_Feedback_Stddev_Fields = {
  __typename?: 'brimstone_event_feedback_stddev_fields';
  scale_rating?: Maybe<Scalars['Float']>;
};

/** order by stddev() on columns of table "brimstone_event_feedback" */
export type Brimstone_Event_Feedback_Stddev_Order_By = {
  scale_rating?: Maybe<Order_By>;
};

/** aggregate stddev_pop on columns */
export type Brimstone_Event_Feedback_Stddev_Pop_Fields = {
  __typename?: 'brimstone_event_feedback_stddev_pop_fields';
  scale_rating?: Maybe<Scalars['Float']>;
};

/** order by stddev_pop() on columns of table "brimstone_event_feedback" */
export type Brimstone_Event_Feedback_Stddev_Pop_Order_By = {
  scale_rating?: Maybe<Order_By>;
};

/** aggregate stddev_samp on columns */
export type Brimstone_Event_Feedback_Stddev_Samp_Fields = {
  __typename?: 'brimstone_event_feedback_stddev_samp_fields';
  scale_rating?: Maybe<Scalars['Float']>;
};

/** order by stddev_samp() on columns of table "brimstone_event_feedback" */
export type Brimstone_Event_Feedback_Stddev_Samp_Order_By = {
  scale_rating?: Maybe<Order_By>;
};

/** Streaming cursor of the table "brimstone_event_feedback" */
export type Brimstone_Event_Feedback_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Brimstone_Event_Feedback_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: Maybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Brimstone_Event_Feedback_Stream_Cursor_Value_Input = {
  additional_feedback?: Maybe<Scalars['String']>;
  brimstone_event_id?: Maybe<Scalars['uuid']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  deleted_at?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['uuid']>;
  interested?: Maybe<Scalars['Boolean']>;
  owner_id?: Maybe<Scalars['String']>;
  scale_rating?: Maybe<Scalars['Int']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** aggregate sum on columns */
export type Brimstone_Event_Feedback_Sum_Fields = {
  __typename?: 'brimstone_event_feedback_sum_fields';
  scale_rating?: Maybe<Scalars['Int']>;
};

/** order by sum() on columns of table "brimstone_event_feedback" */
export type Brimstone_Event_Feedback_Sum_Order_By = {
  scale_rating?: Maybe<Order_By>;
};

/** update columns of table "brimstone_event_feedback" */
export enum Brimstone_Event_Feedback_Update_Column {
  /** column name */
  AdditionalFeedback = 'additional_feedback',
  /** column name */
  BrimstoneEventId = 'brimstone_event_id',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  DeletedAt = 'deleted_at',
  /** column name */
  Id = 'id',
  /** column name */
  Interested = 'interested',
  /** column name */
  OwnerId = 'owner_id',
  /** column name */
  ScaleRating = 'scale_rating',
  /** column name */
  UpdatedAt = 'updated_at'
}

export type Brimstone_Event_Feedback_Updates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: Maybe<Brimstone_Event_Feedback_Inc_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: Maybe<Brimstone_Event_Feedback_Set_Input>;
  /** filter the rows which have to be updated */
  where: Brimstone_Event_Feedback_Bool_Exp;
};

/** aggregate var_pop on columns */
export type Brimstone_Event_Feedback_Var_Pop_Fields = {
  __typename?: 'brimstone_event_feedback_var_pop_fields';
  scale_rating?: Maybe<Scalars['Float']>;
};

/** order by var_pop() on columns of table "brimstone_event_feedback" */
export type Brimstone_Event_Feedback_Var_Pop_Order_By = {
  scale_rating?: Maybe<Order_By>;
};

/** aggregate var_samp on columns */
export type Brimstone_Event_Feedback_Var_Samp_Fields = {
  __typename?: 'brimstone_event_feedback_var_samp_fields';
  scale_rating?: Maybe<Scalars['Float']>;
};

/** order by var_samp() on columns of table "brimstone_event_feedback" */
export type Brimstone_Event_Feedback_Var_Samp_Order_By = {
  scale_rating?: Maybe<Order_By>;
};

/** aggregate variance on columns */
export type Brimstone_Event_Feedback_Variance_Fields = {
  __typename?: 'brimstone_event_feedback_variance_fields';
  scale_rating?: Maybe<Scalars['Float']>;
};

/** order by variance() on columns of table "brimstone_event_feedback" */
export type Brimstone_Event_Feedback_Variance_Order_By = {
  scale_rating?: Maybe<Order_By>;
};

/** columns and relationships of "brimstone_event_speakers" */
export type Brimstone_Event_Speakers = {
  __typename?: 'brimstone_event_speakers';
  /** An object relationship */
  brimstone_event?: Maybe<Brimstone_Events>;
  brimstone_event_id?: Maybe<Scalars['uuid']>;
  /** An object relationship */
  brimstone_speaker?: Maybe<Brimstone_Speakers>;
  brimstone_speaker_id?: Maybe<Scalars['uuid']>;
  created_at: Scalars['timestamptz'];
  deleted_at?: Maybe<Scalars['timestamptz']>;
  id: Scalars['uuid'];
  updated_at: Scalars['timestamptz'];
};

/** aggregated selection of "brimstone_event_speakers" */
export type Brimstone_Event_Speakers_Aggregate = {
  __typename?: 'brimstone_event_speakers_aggregate';
  aggregate?: Maybe<Brimstone_Event_Speakers_Aggregate_Fields>;
  nodes: Array<Brimstone_Event_Speakers>;
};

export type Brimstone_Event_Speakers_Aggregate_Bool_Exp = {
  count?: Maybe<Brimstone_Event_Speakers_Aggregate_Bool_Exp_Count>;
};

export type Brimstone_Event_Speakers_Aggregate_Bool_Exp_Count = {
  arguments?: Maybe<Array<Brimstone_Event_Speakers_Select_Column>>;
  distinct?: Maybe<Scalars['Boolean']>;
  filter?: Maybe<Brimstone_Event_Speakers_Bool_Exp>;
  predicate: Int_Comparison_Exp;
};

/** aggregate fields of "brimstone_event_speakers" */
export type Brimstone_Event_Speakers_Aggregate_Fields = {
  __typename?: 'brimstone_event_speakers_aggregate_fields';
  count: Scalars['Int'];
  max?: Maybe<Brimstone_Event_Speakers_Max_Fields>;
  min?: Maybe<Brimstone_Event_Speakers_Min_Fields>;
};


/** aggregate fields of "brimstone_event_speakers" */
export type Brimstone_Event_Speakers_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<Brimstone_Event_Speakers_Select_Column>>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "brimstone_event_speakers" */
export type Brimstone_Event_Speakers_Aggregate_Order_By = {
  count?: Maybe<Order_By>;
  max?: Maybe<Brimstone_Event_Speakers_Max_Order_By>;
  min?: Maybe<Brimstone_Event_Speakers_Min_Order_By>;
};

/** input type for inserting array relation for remote table "brimstone_event_speakers" */
export type Brimstone_Event_Speakers_Arr_Rel_Insert_Input = {
  data: Array<Brimstone_Event_Speakers_Insert_Input>;
  /** upsert condition */
  on_conflict?: Maybe<Brimstone_Event_Speakers_On_Conflict>;
};

/** Boolean expression to filter rows from the table "brimstone_event_speakers". All fields are combined with a logical 'AND'. */
export type Brimstone_Event_Speakers_Bool_Exp = {
  _and?: Maybe<Array<Brimstone_Event_Speakers_Bool_Exp>>;
  _not?: Maybe<Brimstone_Event_Speakers_Bool_Exp>;
  _or?: Maybe<Array<Brimstone_Event_Speakers_Bool_Exp>>;
  brimstone_event?: Maybe<Brimstone_Events_Bool_Exp>;
  brimstone_event_id?: Maybe<Uuid_Comparison_Exp>;
  brimstone_speaker?: Maybe<Brimstone_Speakers_Bool_Exp>;
  brimstone_speaker_id?: Maybe<Uuid_Comparison_Exp>;
  created_at?: Maybe<Timestamptz_Comparison_Exp>;
  deleted_at?: Maybe<Timestamptz_Comparison_Exp>;
  id?: Maybe<Uuid_Comparison_Exp>;
  updated_at?: Maybe<Timestamptz_Comparison_Exp>;
};

/** unique or primary key constraints on table "brimstone_event_speakers" */
export enum Brimstone_Event_Speakers_Constraint {
  /** unique or primary key constraint on columns "id" */
  BrimstoneEventSpeakersPkey = 'brimstone_event_speakers_pkey'
}

/** input type for inserting data into table "brimstone_event_speakers" */
export type Brimstone_Event_Speakers_Insert_Input = {
  brimstone_event?: Maybe<Brimstone_Events_Obj_Rel_Insert_Input>;
  brimstone_event_id?: Maybe<Scalars['uuid']>;
  brimstone_speaker?: Maybe<Brimstone_Speakers_Obj_Rel_Insert_Input>;
  brimstone_speaker_id?: Maybe<Scalars['uuid']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  deleted_at?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['uuid']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** aggregate max on columns */
export type Brimstone_Event_Speakers_Max_Fields = {
  __typename?: 'brimstone_event_speakers_max_fields';
  brimstone_event_id?: Maybe<Scalars['uuid']>;
  brimstone_speaker_id?: Maybe<Scalars['uuid']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  deleted_at?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['uuid']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** order by max() on columns of table "brimstone_event_speakers" */
export type Brimstone_Event_Speakers_Max_Order_By = {
  brimstone_event_id?: Maybe<Order_By>;
  brimstone_speaker_id?: Maybe<Order_By>;
  created_at?: Maybe<Order_By>;
  deleted_at?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  updated_at?: Maybe<Order_By>;
};

/** aggregate min on columns */
export type Brimstone_Event_Speakers_Min_Fields = {
  __typename?: 'brimstone_event_speakers_min_fields';
  brimstone_event_id?: Maybe<Scalars['uuid']>;
  brimstone_speaker_id?: Maybe<Scalars['uuid']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  deleted_at?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['uuid']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** order by min() on columns of table "brimstone_event_speakers" */
export type Brimstone_Event_Speakers_Min_Order_By = {
  brimstone_event_id?: Maybe<Order_By>;
  brimstone_speaker_id?: Maybe<Order_By>;
  created_at?: Maybe<Order_By>;
  deleted_at?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  updated_at?: Maybe<Order_By>;
};

/** response of any mutation on the table "brimstone_event_speakers" */
export type Brimstone_Event_Speakers_Mutation_Response = {
  __typename?: 'brimstone_event_speakers_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Brimstone_Event_Speakers>;
};

/** on_conflict condition type for table "brimstone_event_speakers" */
export type Brimstone_Event_Speakers_On_Conflict = {
  constraint: Brimstone_Event_Speakers_Constraint;
  update_columns?: Array<Brimstone_Event_Speakers_Update_Column>;
  where?: Maybe<Brimstone_Event_Speakers_Bool_Exp>;
};

/** Ordering options when selecting data from "brimstone_event_speakers". */
export type Brimstone_Event_Speakers_Order_By = {
  brimstone_event?: Maybe<Brimstone_Events_Order_By>;
  brimstone_event_id?: Maybe<Order_By>;
  brimstone_speaker?: Maybe<Brimstone_Speakers_Order_By>;
  brimstone_speaker_id?: Maybe<Order_By>;
  created_at?: Maybe<Order_By>;
  deleted_at?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  updated_at?: Maybe<Order_By>;
};

/** primary key columns input for table: brimstone_event_speakers */
export type Brimstone_Event_Speakers_Pk_Columns_Input = {
  id: Scalars['uuid'];
};

/** select columns of table "brimstone_event_speakers" */
export enum Brimstone_Event_Speakers_Select_Column {
  /** column name */
  BrimstoneEventId = 'brimstone_event_id',
  /** column name */
  BrimstoneSpeakerId = 'brimstone_speaker_id',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  DeletedAt = 'deleted_at',
  /** column name */
  Id = 'id',
  /** column name */
  UpdatedAt = 'updated_at'
}

/** input type for updating data in table "brimstone_event_speakers" */
export type Brimstone_Event_Speakers_Set_Input = {
  brimstone_event_id?: Maybe<Scalars['uuid']>;
  brimstone_speaker_id?: Maybe<Scalars['uuid']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  deleted_at?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['uuid']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** Streaming cursor of the table "brimstone_event_speakers" */
export type Brimstone_Event_Speakers_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Brimstone_Event_Speakers_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: Maybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Brimstone_Event_Speakers_Stream_Cursor_Value_Input = {
  brimstone_event_id?: Maybe<Scalars['uuid']>;
  brimstone_speaker_id?: Maybe<Scalars['uuid']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  deleted_at?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['uuid']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** update columns of table "brimstone_event_speakers" */
export enum Brimstone_Event_Speakers_Update_Column {
  /** column name */
  BrimstoneEventId = 'brimstone_event_id',
  /** column name */
  BrimstoneSpeakerId = 'brimstone_speaker_id',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  DeletedAt = 'deleted_at',
  /** column name */
  Id = 'id',
  /** column name */
  UpdatedAt = 'updated_at'
}

export type Brimstone_Event_Speakers_Updates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: Maybe<Brimstone_Event_Speakers_Set_Input>;
  /** filter the rows which have to be updated */
  where: Brimstone_Event_Speakers_Bool_Exp;
};

/** columns and relationships of "brimstone_events" */
export type Brimstone_Events = {
  __typename?: 'brimstone_events';
  /** An array relationship */
  brimstone_event_feedbacks: Array<Brimstone_Event_Feedback>;
  /** An aggregate relationship */
  brimstone_event_feedbacks_aggregate: Brimstone_Event_Feedback_Aggregate;
  /** An array relationship */
  brimstone_event_speakers: Array<Brimstone_Event_Speakers>;
  /** An aggregate relationship */
  brimstone_event_speakers_aggregate: Brimstone_Event_Speakers_Aggregate;
  company_logo: Scalars['String'];
  company_name: Scalars['String'];
  created_at: Scalars['timestamptz'];
  deleted_at?: Maybe<Scalars['timestamptz']>;
  event_type: Scalars['event_type_enum'];
  id: Scalars['uuid'];
  time_start: Scalars['timestamptz'];
  updated_at: Scalars['timestamptz'];
};


/** columns and relationships of "brimstone_events" */
export type Brimstone_EventsBrimstone_Event_FeedbacksArgs = {
  distinct_on?: Maybe<Array<Brimstone_Event_Feedback_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Brimstone_Event_Feedback_Order_By>>;
  where?: Maybe<Brimstone_Event_Feedback_Bool_Exp>;
};


/** columns and relationships of "brimstone_events" */
export type Brimstone_EventsBrimstone_Event_Feedbacks_AggregateArgs = {
  distinct_on?: Maybe<Array<Brimstone_Event_Feedback_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Brimstone_Event_Feedback_Order_By>>;
  where?: Maybe<Brimstone_Event_Feedback_Bool_Exp>;
};


/** columns and relationships of "brimstone_events" */
export type Brimstone_EventsBrimstone_Event_SpeakersArgs = {
  distinct_on?: Maybe<Array<Brimstone_Event_Speakers_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Brimstone_Event_Speakers_Order_By>>;
  where?: Maybe<Brimstone_Event_Speakers_Bool_Exp>;
};


/** columns and relationships of "brimstone_events" */
export type Brimstone_EventsBrimstone_Event_Speakers_AggregateArgs = {
  distinct_on?: Maybe<Array<Brimstone_Event_Speakers_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Brimstone_Event_Speakers_Order_By>>;
  where?: Maybe<Brimstone_Event_Speakers_Bool_Exp>;
};

/** aggregated selection of "brimstone_events" */
export type Brimstone_Events_Aggregate = {
  __typename?: 'brimstone_events_aggregate';
  aggregate?: Maybe<Brimstone_Events_Aggregate_Fields>;
  nodes: Array<Brimstone_Events>;
};

/** aggregate fields of "brimstone_events" */
export type Brimstone_Events_Aggregate_Fields = {
  __typename?: 'brimstone_events_aggregate_fields';
  count: Scalars['Int'];
  max?: Maybe<Brimstone_Events_Max_Fields>;
  min?: Maybe<Brimstone_Events_Min_Fields>;
};


/** aggregate fields of "brimstone_events" */
export type Brimstone_Events_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<Brimstone_Events_Select_Column>>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** Boolean expression to filter rows from the table "brimstone_events". All fields are combined with a logical 'AND'. */
export type Brimstone_Events_Bool_Exp = {
  _and?: Maybe<Array<Brimstone_Events_Bool_Exp>>;
  _not?: Maybe<Brimstone_Events_Bool_Exp>;
  _or?: Maybe<Array<Brimstone_Events_Bool_Exp>>;
  brimstone_event_feedbacks?: Maybe<Brimstone_Event_Feedback_Bool_Exp>;
  brimstone_event_feedbacks_aggregate?: Maybe<Brimstone_Event_Feedback_Aggregate_Bool_Exp>;
  brimstone_event_speakers?: Maybe<Brimstone_Event_Speakers_Bool_Exp>;
  brimstone_event_speakers_aggregate?: Maybe<Brimstone_Event_Speakers_Aggregate_Bool_Exp>;
  company_logo?: Maybe<String_Comparison_Exp>;
  company_name?: Maybe<String_Comparison_Exp>;
  created_at?: Maybe<Timestamptz_Comparison_Exp>;
  deleted_at?: Maybe<Timestamptz_Comparison_Exp>;
  event_type?: Maybe<Event_Type_Enum_Comparison_Exp>;
  id?: Maybe<Uuid_Comparison_Exp>;
  time_start?: Maybe<Timestamptz_Comparison_Exp>;
  updated_at?: Maybe<Timestamptz_Comparison_Exp>;
};

/** unique or primary key constraints on table "brimstone_events" */
export enum Brimstone_Events_Constraint {
  /** unique or primary key constraint on columns "id" */
  BrimstoneEventsPkey = 'brimstone_events_pkey'
}

/** input type for inserting data into table "brimstone_events" */
export type Brimstone_Events_Insert_Input = {
  brimstone_event_feedbacks?: Maybe<Brimstone_Event_Feedback_Arr_Rel_Insert_Input>;
  brimstone_event_speakers?: Maybe<Brimstone_Event_Speakers_Arr_Rel_Insert_Input>;
  company_logo?: Maybe<Scalars['String']>;
  company_name?: Maybe<Scalars['String']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  deleted_at?: Maybe<Scalars['timestamptz']>;
  event_type?: Maybe<Scalars['event_type_enum']>;
  id?: Maybe<Scalars['uuid']>;
  time_start?: Maybe<Scalars['timestamptz']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** aggregate max on columns */
export type Brimstone_Events_Max_Fields = {
  __typename?: 'brimstone_events_max_fields';
  company_logo?: Maybe<Scalars['String']>;
  company_name?: Maybe<Scalars['String']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  deleted_at?: Maybe<Scalars['timestamptz']>;
  event_type?: Maybe<Scalars['event_type_enum']>;
  id?: Maybe<Scalars['uuid']>;
  time_start?: Maybe<Scalars['timestamptz']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** aggregate min on columns */
export type Brimstone_Events_Min_Fields = {
  __typename?: 'brimstone_events_min_fields';
  company_logo?: Maybe<Scalars['String']>;
  company_name?: Maybe<Scalars['String']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  deleted_at?: Maybe<Scalars['timestamptz']>;
  event_type?: Maybe<Scalars['event_type_enum']>;
  id?: Maybe<Scalars['uuid']>;
  time_start?: Maybe<Scalars['timestamptz']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** response of any mutation on the table "brimstone_events" */
export type Brimstone_Events_Mutation_Response = {
  __typename?: 'brimstone_events_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Brimstone_Events>;
};

/** input type for inserting object relation for remote table "brimstone_events" */
export type Brimstone_Events_Obj_Rel_Insert_Input = {
  data: Brimstone_Events_Insert_Input;
  /** upsert condition */
  on_conflict?: Maybe<Brimstone_Events_On_Conflict>;
};

/** on_conflict condition type for table "brimstone_events" */
export type Brimstone_Events_On_Conflict = {
  constraint: Brimstone_Events_Constraint;
  update_columns?: Array<Brimstone_Events_Update_Column>;
  where?: Maybe<Brimstone_Events_Bool_Exp>;
};

/** Ordering options when selecting data from "brimstone_events". */
export type Brimstone_Events_Order_By = {
  brimstone_event_feedbacks_aggregate?: Maybe<Brimstone_Event_Feedback_Aggregate_Order_By>;
  brimstone_event_speakers_aggregate?: Maybe<Brimstone_Event_Speakers_Aggregate_Order_By>;
  company_logo?: Maybe<Order_By>;
  company_name?: Maybe<Order_By>;
  created_at?: Maybe<Order_By>;
  deleted_at?: Maybe<Order_By>;
  event_type?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  time_start?: Maybe<Order_By>;
  updated_at?: Maybe<Order_By>;
};

/** primary key columns input for table: brimstone_events */
export type Brimstone_Events_Pk_Columns_Input = {
  id: Scalars['uuid'];
};

/** select columns of table "brimstone_events" */
export enum Brimstone_Events_Select_Column {
  /** column name */
  CompanyLogo = 'company_logo',
  /** column name */
  CompanyName = 'company_name',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  DeletedAt = 'deleted_at',
  /** column name */
  EventType = 'event_type',
  /** column name */
  Id = 'id',
  /** column name */
  TimeStart = 'time_start',
  /** column name */
  UpdatedAt = 'updated_at'
}

/** input type for updating data in table "brimstone_events" */
export type Brimstone_Events_Set_Input = {
  company_logo?: Maybe<Scalars['String']>;
  company_name?: Maybe<Scalars['String']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  deleted_at?: Maybe<Scalars['timestamptz']>;
  event_type?: Maybe<Scalars['event_type_enum']>;
  id?: Maybe<Scalars['uuid']>;
  time_start?: Maybe<Scalars['timestamptz']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** Streaming cursor of the table "brimstone_events" */
export type Brimstone_Events_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Brimstone_Events_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: Maybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Brimstone_Events_Stream_Cursor_Value_Input = {
  company_logo?: Maybe<Scalars['String']>;
  company_name?: Maybe<Scalars['String']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  deleted_at?: Maybe<Scalars['timestamptz']>;
  event_type?: Maybe<Scalars['event_type_enum']>;
  id?: Maybe<Scalars['uuid']>;
  time_start?: Maybe<Scalars['timestamptz']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** update columns of table "brimstone_events" */
export enum Brimstone_Events_Update_Column {
  /** column name */
  CompanyLogo = 'company_logo',
  /** column name */
  CompanyName = 'company_name',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  DeletedAt = 'deleted_at',
  /** column name */
  EventType = 'event_type',
  /** column name */
  Id = 'id',
  /** column name */
  TimeStart = 'time_start',
  /** column name */
  UpdatedAt = 'updated_at'
}

export type Brimstone_Events_Updates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: Maybe<Brimstone_Events_Set_Input>;
  /** filter the rows which have to be updated */
  where: Brimstone_Events_Bool_Exp;
};

/** columns and relationships of "brimstone_speakers" */
export type Brimstone_Speakers = {
  __typename?: 'brimstone_speakers';
  /** An array relationship */
  brimstone_event_speakers: Array<Brimstone_Event_Speakers>;
  /** An aggregate relationship */
  brimstone_event_speakers_aggregate: Brimstone_Event_Speakers_Aggregate;
  created_at: Scalars['timestamptz'];
  deleted_at?: Maybe<Scalars['timestamptz']>;
  first_name: Scalars['String'];
  id: Scalars['uuid'];
  last_name: Scalars['String'];
  linkedin_url: Scalars['String'];
  updated_at: Scalars['timestamptz'];
};


/** columns and relationships of "brimstone_speakers" */
export type Brimstone_SpeakersBrimstone_Event_SpeakersArgs = {
  distinct_on?: Maybe<Array<Brimstone_Event_Speakers_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Brimstone_Event_Speakers_Order_By>>;
  where?: Maybe<Brimstone_Event_Speakers_Bool_Exp>;
};


/** columns and relationships of "brimstone_speakers" */
export type Brimstone_SpeakersBrimstone_Event_Speakers_AggregateArgs = {
  distinct_on?: Maybe<Array<Brimstone_Event_Speakers_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Brimstone_Event_Speakers_Order_By>>;
  where?: Maybe<Brimstone_Event_Speakers_Bool_Exp>;
};

/** aggregated selection of "brimstone_speakers" */
export type Brimstone_Speakers_Aggregate = {
  __typename?: 'brimstone_speakers_aggregate';
  aggregate?: Maybe<Brimstone_Speakers_Aggregate_Fields>;
  nodes: Array<Brimstone_Speakers>;
};

/** aggregate fields of "brimstone_speakers" */
export type Brimstone_Speakers_Aggregate_Fields = {
  __typename?: 'brimstone_speakers_aggregate_fields';
  count: Scalars['Int'];
  max?: Maybe<Brimstone_Speakers_Max_Fields>;
  min?: Maybe<Brimstone_Speakers_Min_Fields>;
};


/** aggregate fields of "brimstone_speakers" */
export type Brimstone_Speakers_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<Brimstone_Speakers_Select_Column>>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** Boolean expression to filter rows from the table "brimstone_speakers". All fields are combined with a logical 'AND'. */
export type Brimstone_Speakers_Bool_Exp = {
  _and?: Maybe<Array<Brimstone_Speakers_Bool_Exp>>;
  _not?: Maybe<Brimstone_Speakers_Bool_Exp>;
  _or?: Maybe<Array<Brimstone_Speakers_Bool_Exp>>;
  brimstone_event_speakers?: Maybe<Brimstone_Event_Speakers_Bool_Exp>;
  brimstone_event_speakers_aggregate?: Maybe<Brimstone_Event_Speakers_Aggregate_Bool_Exp>;
  created_at?: Maybe<Timestamptz_Comparison_Exp>;
  deleted_at?: Maybe<Timestamptz_Comparison_Exp>;
  first_name?: Maybe<String_Comparison_Exp>;
  id?: Maybe<Uuid_Comparison_Exp>;
  last_name?: Maybe<String_Comparison_Exp>;
  linkedin_url?: Maybe<String_Comparison_Exp>;
  updated_at?: Maybe<Timestamptz_Comparison_Exp>;
};

/** unique or primary key constraints on table "brimstone_speakers" */
export enum Brimstone_Speakers_Constraint {
  /** unique or primary key constraint on columns "id" */
  BrimstoneSpeakersPkey = 'brimstone_speakers_pkey'
}

/** input type for inserting data into table "brimstone_speakers" */
export type Brimstone_Speakers_Insert_Input = {
  brimstone_event_speakers?: Maybe<Brimstone_Event_Speakers_Arr_Rel_Insert_Input>;
  created_at?: Maybe<Scalars['timestamptz']>;
  deleted_at?: Maybe<Scalars['timestamptz']>;
  first_name?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['uuid']>;
  last_name?: Maybe<Scalars['String']>;
  linkedin_url?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** aggregate max on columns */
export type Brimstone_Speakers_Max_Fields = {
  __typename?: 'brimstone_speakers_max_fields';
  created_at?: Maybe<Scalars['timestamptz']>;
  deleted_at?: Maybe<Scalars['timestamptz']>;
  first_name?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['uuid']>;
  last_name?: Maybe<Scalars['String']>;
  linkedin_url?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** aggregate min on columns */
export type Brimstone_Speakers_Min_Fields = {
  __typename?: 'brimstone_speakers_min_fields';
  created_at?: Maybe<Scalars['timestamptz']>;
  deleted_at?: Maybe<Scalars['timestamptz']>;
  first_name?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['uuid']>;
  last_name?: Maybe<Scalars['String']>;
  linkedin_url?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** response of any mutation on the table "brimstone_speakers" */
export type Brimstone_Speakers_Mutation_Response = {
  __typename?: 'brimstone_speakers_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Brimstone_Speakers>;
};

/** input type for inserting object relation for remote table "brimstone_speakers" */
export type Brimstone_Speakers_Obj_Rel_Insert_Input = {
  data: Brimstone_Speakers_Insert_Input;
  /** upsert condition */
  on_conflict?: Maybe<Brimstone_Speakers_On_Conflict>;
};

/** on_conflict condition type for table "brimstone_speakers" */
export type Brimstone_Speakers_On_Conflict = {
  constraint: Brimstone_Speakers_Constraint;
  update_columns?: Array<Brimstone_Speakers_Update_Column>;
  where?: Maybe<Brimstone_Speakers_Bool_Exp>;
};

/** Ordering options when selecting data from "brimstone_speakers". */
export type Brimstone_Speakers_Order_By = {
  brimstone_event_speakers_aggregate?: Maybe<Brimstone_Event_Speakers_Aggregate_Order_By>;
  created_at?: Maybe<Order_By>;
  deleted_at?: Maybe<Order_By>;
  first_name?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  last_name?: Maybe<Order_By>;
  linkedin_url?: Maybe<Order_By>;
  updated_at?: Maybe<Order_By>;
};

/** primary key columns input for table: brimstone_speakers */
export type Brimstone_Speakers_Pk_Columns_Input = {
  id: Scalars['uuid'];
};

/** select columns of table "brimstone_speakers" */
export enum Brimstone_Speakers_Select_Column {
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  DeletedAt = 'deleted_at',
  /** column name */
  FirstName = 'first_name',
  /** column name */
  Id = 'id',
  /** column name */
  LastName = 'last_name',
  /** column name */
  LinkedinUrl = 'linkedin_url',
  /** column name */
  UpdatedAt = 'updated_at'
}

/** input type for updating data in table "brimstone_speakers" */
export type Brimstone_Speakers_Set_Input = {
  created_at?: Maybe<Scalars['timestamptz']>;
  deleted_at?: Maybe<Scalars['timestamptz']>;
  first_name?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['uuid']>;
  last_name?: Maybe<Scalars['String']>;
  linkedin_url?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** Streaming cursor of the table "brimstone_speakers" */
export type Brimstone_Speakers_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Brimstone_Speakers_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: Maybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Brimstone_Speakers_Stream_Cursor_Value_Input = {
  created_at?: Maybe<Scalars['timestamptz']>;
  deleted_at?: Maybe<Scalars['timestamptz']>;
  first_name?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['uuid']>;
  last_name?: Maybe<Scalars['String']>;
  linkedin_url?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** update columns of table "brimstone_speakers" */
export enum Brimstone_Speakers_Update_Column {
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  DeletedAt = 'deleted_at',
  /** column name */
  FirstName = 'first_name',
  /** column name */
  Id = 'id',
  /** column name */
  LastName = 'last_name',
  /** column name */
  LinkedinUrl = 'linkedin_url',
  /** column name */
  UpdatedAt = 'updated_at'
}

export type Brimstone_Speakers_Updates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: Maybe<Brimstone_Speakers_Set_Input>;
  /** filter the rows which have to be updated */
  where: Brimstone_Speakers_Bool_Exp;
};

/** columns and relationships of "candidate_courses" */
export type Candidate_Courses = {
  __typename?: 'candidate_courses';
  /** An object relationship */
  candidate: Candidates;
  candidate_id: Scalars['uuid'];
  completed: Scalars['Boolean'];
  /** An object relationship */
  course: Courses;
  course_id: Scalars['uuid'];
  created_at: Scalars['timestamptz'];
  deleted_at?: Maybe<Scalars['timestamptz']>;
  result: Scalars['course_result_enums'];
  updated_at: Scalars['timestamptz'];
};

/** aggregated selection of "candidate_courses" */
export type Candidate_Courses_Aggregate = {
  __typename?: 'candidate_courses_aggregate';
  aggregate?: Maybe<Candidate_Courses_Aggregate_Fields>;
  nodes: Array<Candidate_Courses>;
};

export type Candidate_Courses_Aggregate_Bool_Exp = {
  bool_and?: Maybe<Candidate_Courses_Aggregate_Bool_Exp_Bool_And>;
  bool_or?: Maybe<Candidate_Courses_Aggregate_Bool_Exp_Bool_Or>;
  count?: Maybe<Candidate_Courses_Aggregate_Bool_Exp_Count>;
};

export type Candidate_Courses_Aggregate_Bool_Exp_Bool_And = {
  arguments: Candidate_Courses_Select_Column_Candidate_Courses_Aggregate_Bool_Exp_Bool_And_Arguments_Columns;
  distinct?: Maybe<Scalars['Boolean']>;
  filter?: Maybe<Candidate_Courses_Bool_Exp>;
  predicate: Boolean_Comparison_Exp;
};

export type Candidate_Courses_Aggregate_Bool_Exp_Bool_Or = {
  arguments: Candidate_Courses_Select_Column_Candidate_Courses_Aggregate_Bool_Exp_Bool_Or_Arguments_Columns;
  distinct?: Maybe<Scalars['Boolean']>;
  filter?: Maybe<Candidate_Courses_Bool_Exp>;
  predicate: Boolean_Comparison_Exp;
};

export type Candidate_Courses_Aggregate_Bool_Exp_Count = {
  arguments?: Maybe<Array<Candidate_Courses_Select_Column>>;
  distinct?: Maybe<Scalars['Boolean']>;
  filter?: Maybe<Candidate_Courses_Bool_Exp>;
  predicate: Int_Comparison_Exp;
};

/** aggregate fields of "candidate_courses" */
export type Candidate_Courses_Aggregate_Fields = {
  __typename?: 'candidate_courses_aggregate_fields';
  count: Scalars['Int'];
  max?: Maybe<Candidate_Courses_Max_Fields>;
  min?: Maybe<Candidate_Courses_Min_Fields>;
};


/** aggregate fields of "candidate_courses" */
export type Candidate_Courses_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<Candidate_Courses_Select_Column>>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "candidate_courses" */
export type Candidate_Courses_Aggregate_Order_By = {
  count?: Maybe<Order_By>;
  max?: Maybe<Candidate_Courses_Max_Order_By>;
  min?: Maybe<Candidate_Courses_Min_Order_By>;
};

/** input type for inserting array relation for remote table "candidate_courses" */
export type Candidate_Courses_Arr_Rel_Insert_Input = {
  data: Array<Candidate_Courses_Insert_Input>;
  /** upsert condition */
  on_conflict?: Maybe<Candidate_Courses_On_Conflict>;
};

/** Boolean expression to filter rows from the table "candidate_courses". All fields are combined with a logical 'AND'. */
export type Candidate_Courses_Bool_Exp = {
  _and?: Maybe<Array<Candidate_Courses_Bool_Exp>>;
  _not?: Maybe<Candidate_Courses_Bool_Exp>;
  _or?: Maybe<Array<Candidate_Courses_Bool_Exp>>;
  candidate?: Maybe<Candidates_Bool_Exp>;
  candidate_id?: Maybe<Uuid_Comparison_Exp>;
  completed?: Maybe<Boolean_Comparison_Exp>;
  course?: Maybe<Courses_Bool_Exp>;
  course_id?: Maybe<Uuid_Comparison_Exp>;
  created_at?: Maybe<Timestamptz_Comparison_Exp>;
  deleted_at?: Maybe<Timestamptz_Comparison_Exp>;
  result?: Maybe<Course_Result_Enums_Comparison_Exp>;
  updated_at?: Maybe<Timestamptz_Comparison_Exp>;
};

/** unique or primary key constraints on table "candidate_courses" */
export enum Candidate_Courses_Constraint {
  /** unique or primary key constraint on columns "course_id", "candidate_id" */
  CandidateCoursesPkey = 'candidate_courses_pkey'
}

/** input type for inserting data into table "candidate_courses" */
export type Candidate_Courses_Insert_Input = {
  candidate?: Maybe<Candidates_Obj_Rel_Insert_Input>;
  candidate_id?: Maybe<Scalars['uuid']>;
  completed?: Maybe<Scalars['Boolean']>;
  course?: Maybe<Courses_Obj_Rel_Insert_Input>;
  course_id?: Maybe<Scalars['uuid']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  deleted_at?: Maybe<Scalars['timestamptz']>;
  result?: Maybe<Scalars['course_result_enums']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** aggregate max on columns */
export type Candidate_Courses_Max_Fields = {
  __typename?: 'candidate_courses_max_fields';
  candidate_id?: Maybe<Scalars['uuid']>;
  course_id?: Maybe<Scalars['uuid']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  deleted_at?: Maybe<Scalars['timestamptz']>;
  result?: Maybe<Scalars['course_result_enums']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** order by max() on columns of table "candidate_courses" */
export type Candidate_Courses_Max_Order_By = {
  candidate_id?: Maybe<Order_By>;
  course_id?: Maybe<Order_By>;
  created_at?: Maybe<Order_By>;
  deleted_at?: Maybe<Order_By>;
  result?: Maybe<Order_By>;
  updated_at?: Maybe<Order_By>;
};

/** aggregate min on columns */
export type Candidate_Courses_Min_Fields = {
  __typename?: 'candidate_courses_min_fields';
  candidate_id?: Maybe<Scalars['uuid']>;
  course_id?: Maybe<Scalars['uuid']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  deleted_at?: Maybe<Scalars['timestamptz']>;
  result?: Maybe<Scalars['course_result_enums']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** order by min() on columns of table "candidate_courses" */
export type Candidate_Courses_Min_Order_By = {
  candidate_id?: Maybe<Order_By>;
  course_id?: Maybe<Order_By>;
  created_at?: Maybe<Order_By>;
  deleted_at?: Maybe<Order_By>;
  result?: Maybe<Order_By>;
  updated_at?: Maybe<Order_By>;
};

/** response of any mutation on the table "candidate_courses" */
export type Candidate_Courses_Mutation_Response = {
  __typename?: 'candidate_courses_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Candidate_Courses>;
};

/** on_conflict condition type for table "candidate_courses" */
export type Candidate_Courses_On_Conflict = {
  constraint: Candidate_Courses_Constraint;
  update_columns?: Array<Candidate_Courses_Update_Column>;
  where?: Maybe<Candidate_Courses_Bool_Exp>;
};

/** Ordering options when selecting data from "candidate_courses". */
export type Candidate_Courses_Order_By = {
  candidate?: Maybe<Candidates_Order_By>;
  candidate_id?: Maybe<Order_By>;
  completed?: Maybe<Order_By>;
  course?: Maybe<Courses_Order_By>;
  course_id?: Maybe<Order_By>;
  created_at?: Maybe<Order_By>;
  deleted_at?: Maybe<Order_By>;
  result?: Maybe<Order_By>;
  updated_at?: Maybe<Order_By>;
};

/** primary key columns input for table: candidate_courses */
export type Candidate_Courses_Pk_Columns_Input = {
  candidate_id: Scalars['uuid'];
  course_id: Scalars['uuid'];
};

/** select columns of table "candidate_courses" */
export enum Candidate_Courses_Select_Column {
  /** column name */
  CandidateId = 'candidate_id',
  /** column name */
  Completed = 'completed',
  /** column name */
  CourseId = 'course_id',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  DeletedAt = 'deleted_at',
  /** column name */
  Result = 'result',
  /** column name */
  UpdatedAt = 'updated_at'
}

/** select "candidate_courses_aggregate_bool_exp_bool_and_arguments_columns" columns of table "candidate_courses" */
export enum Candidate_Courses_Select_Column_Candidate_Courses_Aggregate_Bool_Exp_Bool_And_Arguments_Columns {
  /** column name */
  Completed = 'completed'
}

/** select "candidate_courses_aggregate_bool_exp_bool_or_arguments_columns" columns of table "candidate_courses" */
export enum Candidate_Courses_Select_Column_Candidate_Courses_Aggregate_Bool_Exp_Bool_Or_Arguments_Columns {
  /** column name */
  Completed = 'completed'
}

/** input type for updating data in table "candidate_courses" */
export type Candidate_Courses_Set_Input = {
  candidate_id?: Maybe<Scalars['uuid']>;
  completed?: Maybe<Scalars['Boolean']>;
  course_id?: Maybe<Scalars['uuid']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  deleted_at?: Maybe<Scalars['timestamptz']>;
  result?: Maybe<Scalars['course_result_enums']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** Streaming cursor of the table "candidate_courses" */
export type Candidate_Courses_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Candidate_Courses_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: Maybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Candidate_Courses_Stream_Cursor_Value_Input = {
  candidate_id?: Maybe<Scalars['uuid']>;
  completed?: Maybe<Scalars['Boolean']>;
  course_id?: Maybe<Scalars['uuid']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  deleted_at?: Maybe<Scalars['timestamptz']>;
  result?: Maybe<Scalars['course_result_enums']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** update columns of table "candidate_courses" */
export enum Candidate_Courses_Update_Column {
  /** column name */
  CandidateId = 'candidate_id',
  /** column name */
  Completed = 'completed',
  /** column name */
  CourseId = 'course_id',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  DeletedAt = 'deleted_at',
  /** column name */
  Result = 'result',
  /** column name */
  UpdatedAt = 'updated_at'
}

export type Candidate_Courses_Updates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: Maybe<Candidate_Courses_Set_Input>;
  /** filter the rows which have to be updated */
  where: Candidate_Courses_Bool_Exp;
};

/** columns and relationships of "candidate_degrees" */
export type Candidate_Degrees = {
  __typename?: 'candidate_degrees';
  /** An object relationship */
  candidate: Candidates;
  candidate_id: Scalars['uuid'];
  created_at: Scalars['timestamptz'];
  /** An object relationship */
  degree: Degrees;
  degree_id: Scalars['uuid'];
  deleted_at?: Maybe<Scalars['timestamptz']>;
  id: Scalars['uuid'];
  updated_at: Scalars['timestamptz'];
};

/** aggregated selection of "candidate_degrees" */
export type Candidate_Degrees_Aggregate = {
  __typename?: 'candidate_degrees_aggregate';
  aggregate?: Maybe<Candidate_Degrees_Aggregate_Fields>;
  nodes: Array<Candidate_Degrees>;
};

export type Candidate_Degrees_Aggregate_Bool_Exp = {
  count?: Maybe<Candidate_Degrees_Aggregate_Bool_Exp_Count>;
};

export type Candidate_Degrees_Aggregate_Bool_Exp_Count = {
  arguments?: Maybe<Array<Candidate_Degrees_Select_Column>>;
  distinct?: Maybe<Scalars['Boolean']>;
  filter?: Maybe<Candidate_Degrees_Bool_Exp>;
  predicate: Int_Comparison_Exp;
};

/** aggregate fields of "candidate_degrees" */
export type Candidate_Degrees_Aggregate_Fields = {
  __typename?: 'candidate_degrees_aggregate_fields';
  count: Scalars['Int'];
  max?: Maybe<Candidate_Degrees_Max_Fields>;
  min?: Maybe<Candidate_Degrees_Min_Fields>;
};


/** aggregate fields of "candidate_degrees" */
export type Candidate_Degrees_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<Candidate_Degrees_Select_Column>>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "candidate_degrees" */
export type Candidate_Degrees_Aggregate_Order_By = {
  count?: Maybe<Order_By>;
  max?: Maybe<Candidate_Degrees_Max_Order_By>;
  min?: Maybe<Candidate_Degrees_Min_Order_By>;
};

/** input type for inserting array relation for remote table "candidate_degrees" */
export type Candidate_Degrees_Arr_Rel_Insert_Input = {
  data: Array<Candidate_Degrees_Insert_Input>;
  /** upsert condition */
  on_conflict?: Maybe<Candidate_Degrees_On_Conflict>;
};

/** Boolean expression to filter rows from the table "candidate_degrees". All fields are combined with a logical 'AND'. */
export type Candidate_Degrees_Bool_Exp = {
  _and?: Maybe<Array<Candidate_Degrees_Bool_Exp>>;
  _not?: Maybe<Candidate_Degrees_Bool_Exp>;
  _or?: Maybe<Array<Candidate_Degrees_Bool_Exp>>;
  candidate?: Maybe<Candidates_Bool_Exp>;
  candidate_id?: Maybe<Uuid_Comparison_Exp>;
  created_at?: Maybe<Timestamptz_Comparison_Exp>;
  degree?: Maybe<Degrees_Bool_Exp>;
  degree_id?: Maybe<Uuid_Comparison_Exp>;
  deleted_at?: Maybe<Timestamptz_Comparison_Exp>;
  id?: Maybe<Uuid_Comparison_Exp>;
  updated_at?: Maybe<Timestamptz_Comparison_Exp>;
};

/** unique or primary key constraints on table "candidate_degrees" */
export enum Candidate_Degrees_Constraint {
  /** unique or primary key constraint on columns "degree_id", "candidate_id" */
  CandidateDegreesCandidateIdDegreeIdKey = 'candidate_degrees_candidate_id_degree_id_key',
  /** unique or primary key constraint on columns "id" */
  CandidateDegreesPkey = 'candidate_degrees_pkey'
}

/** input type for inserting data into table "candidate_degrees" */
export type Candidate_Degrees_Insert_Input = {
  candidate?: Maybe<Candidates_Obj_Rel_Insert_Input>;
  candidate_id?: Maybe<Scalars['uuid']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  degree?: Maybe<Degrees_Obj_Rel_Insert_Input>;
  degree_id?: Maybe<Scalars['uuid']>;
  deleted_at?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['uuid']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** aggregate max on columns */
export type Candidate_Degrees_Max_Fields = {
  __typename?: 'candidate_degrees_max_fields';
  candidate_id?: Maybe<Scalars['uuid']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  degree_id?: Maybe<Scalars['uuid']>;
  deleted_at?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['uuid']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** order by max() on columns of table "candidate_degrees" */
export type Candidate_Degrees_Max_Order_By = {
  candidate_id?: Maybe<Order_By>;
  created_at?: Maybe<Order_By>;
  degree_id?: Maybe<Order_By>;
  deleted_at?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  updated_at?: Maybe<Order_By>;
};

/** aggregate min on columns */
export type Candidate_Degrees_Min_Fields = {
  __typename?: 'candidate_degrees_min_fields';
  candidate_id?: Maybe<Scalars['uuid']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  degree_id?: Maybe<Scalars['uuid']>;
  deleted_at?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['uuid']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** order by min() on columns of table "candidate_degrees" */
export type Candidate_Degrees_Min_Order_By = {
  candidate_id?: Maybe<Order_By>;
  created_at?: Maybe<Order_By>;
  degree_id?: Maybe<Order_By>;
  deleted_at?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  updated_at?: Maybe<Order_By>;
};

/** response of any mutation on the table "candidate_degrees" */
export type Candidate_Degrees_Mutation_Response = {
  __typename?: 'candidate_degrees_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Candidate_Degrees>;
};

/** on_conflict condition type for table "candidate_degrees" */
export type Candidate_Degrees_On_Conflict = {
  constraint: Candidate_Degrees_Constraint;
  update_columns?: Array<Candidate_Degrees_Update_Column>;
  where?: Maybe<Candidate_Degrees_Bool_Exp>;
};

/** Ordering options when selecting data from "candidate_degrees". */
export type Candidate_Degrees_Order_By = {
  candidate?: Maybe<Candidates_Order_By>;
  candidate_id?: Maybe<Order_By>;
  created_at?: Maybe<Order_By>;
  degree?: Maybe<Degrees_Order_By>;
  degree_id?: Maybe<Order_By>;
  deleted_at?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  updated_at?: Maybe<Order_By>;
};

/** primary key columns input for table: candidate_degrees */
export type Candidate_Degrees_Pk_Columns_Input = {
  id: Scalars['uuid'];
};

/** select columns of table "candidate_degrees" */
export enum Candidate_Degrees_Select_Column {
  /** column name */
  CandidateId = 'candidate_id',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  DegreeId = 'degree_id',
  /** column name */
  DeletedAt = 'deleted_at',
  /** column name */
  Id = 'id',
  /** column name */
  UpdatedAt = 'updated_at'
}

/** input type for updating data in table "candidate_degrees" */
export type Candidate_Degrees_Set_Input = {
  candidate_id?: Maybe<Scalars['uuid']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  degree_id?: Maybe<Scalars['uuid']>;
  deleted_at?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['uuid']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** Streaming cursor of the table "candidate_degrees" */
export type Candidate_Degrees_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Candidate_Degrees_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: Maybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Candidate_Degrees_Stream_Cursor_Value_Input = {
  candidate_id?: Maybe<Scalars['uuid']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  degree_id?: Maybe<Scalars['uuid']>;
  deleted_at?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['uuid']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** update columns of table "candidate_degrees" */
export enum Candidate_Degrees_Update_Column {
  /** column name */
  CandidateId = 'candidate_id',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  DegreeId = 'degree_id',
  /** column name */
  DeletedAt = 'deleted_at',
  /** column name */
  Id = 'id',
  /** column name */
  UpdatedAt = 'updated_at'
}

export type Candidate_Degrees_Updates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: Maybe<Candidate_Degrees_Set_Input>;
  /** filter the rows which have to be updated */
  where: Candidate_Degrees_Bool_Exp;
};

/** columns and relationships of "candidate_follows" */
export type Candidate_Follows = {
  __typename?: 'candidate_follows';
  /** An object relationship */
  candidate: Candidates;
  candidate_id: Scalars['uuid'];
  created_at: Scalars['timestamptz'];
  deleted_at?: Maybe<Scalars['timestamptz']>;
  /** An object relationship */
  enterprise: Enterprises;
  enterprise_id: Scalars['uuid'];
  updated_at: Scalars['timestamptz'];
};

/** aggregated selection of "candidate_follows" */
export type Candidate_Follows_Aggregate = {
  __typename?: 'candidate_follows_aggregate';
  aggregate?: Maybe<Candidate_Follows_Aggregate_Fields>;
  nodes: Array<Candidate_Follows>;
};

export type Candidate_Follows_Aggregate_Bool_Exp = {
  count?: Maybe<Candidate_Follows_Aggregate_Bool_Exp_Count>;
};

export type Candidate_Follows_Aggregate_Bool_Exp_Count = {
  arguments?: Maybe<Array<Candidate_Follows_Select_Column>>;
  distinct?: Maybe<Scalars['Boolean']>;
  filter?: Maybe<Candidate_Follows_Bool_Exp>;
  predicate: Int_Comparison_Exp;
};

/** aggregate fields of "candidate_follows" */
export type Candidate_Follows_Aggregate_Fields = {
  __typename?: 'candidate_follows_aggregate_fields';
  count: Scalars['Int'];
  max?: Maybe<Candidate_Follows_Max_Fields>;
  min?: Maybe<Candidate_Follows_Min_Fields>;
};


/** aggregate fields of "candidate_follows" */
export type Candidate_Follows_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<Candidate_Follows_Select_Column>>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "candidate_follows" */
export type Candidate_Follows_Aggregate_Order_By = {
  count?: Maybe<Order_By>;
  max?: Maybe<Candidate_Follows_Max_Order_By>;
  min?: Maybe<Candidate_Follows_Min_Order_By>;
};

/** input type for inserting array relation for remote table "candidate_follows" */
export type Candidate_Follows_Arr_Rel_Insert_Input = {
  data: Array<Candidate_Follows_Insert_Input>;
  /** upsert condition */
  on_conflict?: Maybe<Candidate_Follows_On_Conflict>;
};

/** Boolean expression to filter rows from the table "candidate_follows". All fields are combined with a logical 'AND'. */
export type Candidate_Follows_Bool_Exp = {
  _and?: Maybe<Array<Candidate_Follows_Bool_Exp>>;
  _not?: Maybe<Candidate_Follows_Bool_Exp>;
  _or?: Maybe<Array<Candidate_Follows_Bool_Exp>>;
  candidate?: Maybe<Candidates_Bool_Exp>;
  candidate_id?: Maybe<Uuid_Comparison_Exp>;
  created_at?: Maybe<Timestamptz_Comparison_Exp>;
  deleted_at?: Maybe<Timestamptz_Comparison_Exp>;
  enterprise?: Maybe<Enterprises_Bool_Exp>;
  enterprise_id?: Maybe<Uuid_Comparison_Exp>;
  updated_at?: Maybe<Timestamptz_Comparison_Exp>;
};

/** unique or primary key constraints on table "candidate_follows" */
export enum Candidate_Follows_Constraint {
  /** unique or primary key constraint on columns "enterprise_id", "candidate_id" */
  CandidateFollowsPkey = 'candidate_follows_pkey'
}

/** input type for inserting data into table "candidate_follows" */
export type Candidate_Follows_Insert_Input = {
  candidate?: Maybe<Candidates_Obj_Rel_Insert_Input>;
  candidate_id?: Maybe<Scalars['uuid']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  deleted_at?: Maybe<Scalars['timestamptz']>;
  enterprise?: Maybe<Enterprises_Obj_Rel_Insert_Input>;
  enterprise_id?: Maybe<Scalars['uuid']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** aggregate max on columns */
export type Candidate_Follows_Max_Fields = {
  __typename?: 'candidate_follows_max_fields';
  candidate_id?: Maybe<Scalars['uuid']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  deleted_at?: Maybe<Scalars['timestamptz']>;
  enterprise_id?: Maybe<Scalars['uuid']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** order by max() on columns of table "candidate_follows" */
export type Candidate_Follows_Max_Order_By = {
  candidate_id?: Maybe<Order_By>;
  created_at?: Maybe<Order_By>;
  deleted_at?: Maybe<Order_By>;
  enterprise_id?: Maybe<Order_By>;
  updated_at?: Maybe<Order_By>;
};

/** aggregate min on columns */
export type Candidate_Follows_Min_Fields = {
  __typename?: 'candidate_follows_min_fields';
  candidate_id?: Maybe<Scalars['uuid']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  deleted_at?: Maybe<Scalars['timestamptz']>;
  enterprise_id?: Maybe<Scalars['uuid']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** order by min() on columns of table "candidate_follows" */
export type Candidate_Follows_Min_Order_By = {
  candidate_id?: Maybe<Order_By>;
  created_at?: Maybe<Order_By>;
  deleted_at?: Maybe<Order_By>;
  enterprise_id?: Maybe<Order_By>;
  updated_at?: Maybe<Order_By>;
};

/** response of any mutation on the table "candidate_follows" */
export type Candidate_Follows_Mutation_Response = {
  __typename?: 'candidate_follows_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Candidate_Follows>;
};

/** on_conflict condition type for table "candidate_follows" */
export type Candidate_Follows_On_Conflict = {
  constraint: Candidate_Follows_Constraint;
  update_columns?: Array<Candidate_Follows_Update_Column>;
  where?: Maybe<Candidate_Follows_Bool_Exp>;
};

/** Ordering options when selecting data from "candidate_follows". */
export type Candidate_Follows_Order_By = {
  candidate?: Maybe<Candidates_Order_By>;
  candidate_id?: Maybe<Order_By>;
  created_at?: Maybe<Order_By>;
  deleted_at?: Maybe<Order_By>;
  enterprise?: Maybe<Enterprises_Order_By>;
  enterprise_id?: Maybe<Order_By>;
  updated_at?: Maybe<Order_By>;
};

/** primary key columns input for table: candidate_follows */
export type Candidate_Follows_Pk_Columns_Input = {
  candidate_id: Scalars['uuid'];
  enterprise_id: Scalars['uuid'];
};

/** select columns of table "candidate_follows" */
export enum Candidate_Follows_Select_Column {
  /** column name */
  CandidateId = 'candidate_id',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  DeletedAt = 'deleted_at',
  /** column name */
  EnterpriseId = 'enterprise_id',
  /** column name */
  UpdatedAt = 'updated_at'
}

/** input type for updating data in table "candidate_follows" */
export type Candidate_Follows_Set_Input = {
  candidate_id?: Maybe<Scalars['uuid']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  deleted_at?: Maybe<Scalars['timestamptz']>;
  enterprise_id?: Maybe<Scalars['uuid']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** Streaming cursor of the table "candidate_follows" */
export type Candidate_Follows_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Candidate_Follows_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: Maybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Candidate_Follows_Stream_Cursor_Value_Input = {
  candidate_id?: Maybe<Scalars['uuid']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  deleted_at?: Maybe<Scalars['timestamptz']>;
  enterprise_id?: Maybe<Scalars['uuid']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** update columns of table "candidate_follows" */
export enum Candidate_Follows_Update_Column {
  /** column name */
  CandidateId = 'candidate_id',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  DeletedAt = 'deleted_at',
  /** column name */
  EnterpriseId = 'enterprise_id',
  /** column name */
  UpdatedAt = 'updated_at'
}

export type Candidate_Follows_Updates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: Maybe<Candidate_Follows_Set_Input>;
  /** filter the rows which have to be updated */
  where: Candidate_Follows_Bool_Exp;
};

/** columns and relationships of "candidate_industries" */
export type Candidate_Industries = {
  __typename?: 'candidate_industries';
  /** An object relationship */
  candidate: Candidates;
  candidate_id: Scalars['uuid'];
  created_at: Scalars['timestamptz'];
  deleted_at?: Maybe<Scalars['timestamptz']>;
  id: Scalars['uuid'];
  /** An object relationship */
  industry: Industries;
  industry_id: Scalars['uuid'];
  updated_at: Scalars['timestamptz'];
  verified?: Maybe<Scalars['Boolean']>;
};

/** aggregated selection of "candidate_industries" */
export type Candidate_Industries_Aggregate = {
  __typename?: 'candidate_industries_aggregate';
  aggregate?: Maybe<Candidate_Industries_Aggregate_Fields>;
  nodes: Array<Candidate_Industries>;
};

export type Candidate_Industries_Aggregate_Bool_Exp = {
  bool_and?: Maybe<Candidate_Industries_Aggregate_Bool_Exp_Bool_And>;
  bool_or?: Maybe<Candidate_Industries_Aggregate_Bool_Exp_Bool_Or>;
  count?: Maybe<Candidate_Industries_Aggregate_Bool_Exp_Count>;
};

export type Candidate_Industries_Aggregate_Bool_Exp_Bool_And = {
  arguments: Candidate_Industries_Select_Column_Candidate_Industries_Aggregate_Bool_Exp_Bool_And_Arguments_Columns;
  distinct?: Maybe<Scalars['Boolean']>;
  filter?: Maybe<Candidate_Industries_Bool_Exp>;
  predicate: Boolean_Comparison_Exp;
};

export type Candidate_Industries_Aggregate_Bool_Exp_Bool_Or = {
  arguments: Candidate_Industries_Select_Column_Candidate_Industries_Aggregate_Bool_Exp_Bool_Or_Arguments_Columns;
  distinct?: Maybe<Scalars['Boolean']>;
  filter?: Maybe<Candidate_Industries_Bool_Exp>;
  predicate: Boolean_Comparison_Exp;
};

export type Candidate_Industries_Aggregate_Bool_Exp_Count = {
  arguments?: Maybe<Array<Candidate_Industries_Select_Column>>;
  distinct?: Maybe<Scalars['Boolean']>;
  filter?: Maybe<Candidate_Industries_Bool_Exp>;
  predicate: Int_Comparison_Exp;
};

/** aggregate fields of "candidate_industries" */
export type Candidate_Industries_Aggregate_Fields = {
  __typename?: 'candidate_industries_aggregate_fields';
  count: Scalars['Int'];
  max?: Maybe<Candidate_Industries_Max_Fields>;
  min?: Maybe<Candidate_Industries_Min_Fields>;
};


/** aggregate fields of "candidate_industries" */
export type Candidate_Industries_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<Candidate_Industries_Select_Column>>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "candidate_industries" */
export type Candidate_Industries_Aggregate_Order_By = {
  count?: Maybe<Order_By>;
  max?: Maybe<Candidate_Industries_Max_Order_By>;
  min?: Maybe<Candidate_Industries_Min_Order_By>;
};

/** input type for inserting array relation for remote table "candidate_industries" */
export type Candidate_Industries_Arr_Rel_Insert_Input = {
  data: Array<Candidate_Industries_Insert_Input>;
  /** upsert condition */
  on_conflict?: Maybe<Candidate_Industries_On_Conflict>;
};

/** Boolean expression to filter rows from the table "candidate_industries". All fields are combined with a logical 'AND'. */
export type Candidate_Industries_Bool_Exp = {
  _and?: Maybe<Array<Candidate_Industries_Bool_Exp>>;
  _not?: Maybe<Candidate_Industries_Bool_Exp>;
  _or?: Maybe<Array<Candidate_Industries_Bool_Exp>>;
  candidate?: Maybe<Candidates_Bool_Exp>;
  candidate_id?: Maybe<Uuid_Comparison_Exp>;
  created_at?: Maybe<Timestamptz_Comparison_Exp>;
  deleted_at?: Maybe<Timestamptz_Comparison_Exp>;
  id?: Maybe<Uuid_Comparison_Exp>;
  industry?: Maybe<Industries_Bool_Exp>;
  industry_id?: Maybe<Uuid_Comparison_Exp>;
  updated_at?: Maybe<Timestamptz_Comparison_Exp>;
  verified?: Maybe<Boolean_Comparison_Exp>;
};

/** unique or primary key constraints on table "candidate_industries" */
export enum Candidate_Industries_Constraint {
  /** unique or primary key constraint on columns "industry_id", "candidate_id" */
  CandidateIndustriesCandidateIdIndustryIdKey = 'candidate_industries_candidate_id_industry_id_key',
  /** unique or primary key constraint on columns "id" */
  CandidateIndustriesPkey = 'candidate_industries_pkey'
}

/** input type for inserting data into table "candidate_industries" */
export type Candidate_Industries_Insert_Input = {
  candidate?: Maybe<Candidates_Obj_Rel_Insert_Input>;
  candidate_id?: Maybe<Scalars['uuid']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  deleted_at?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['uuid']>;
  industry?: Maybe<Industries_Obj_Rel_Insert_Input>;
  industry_id?: Maybe<Scalars['uuid']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
  verified?: Maybe<Scalars['Boolean']>;
};

/** aggregate max on columns */
export type Candidate_Industries_Max_Fields = {
  __typename?: 'candidate_industries_max_fields';
  candidate_id?: Maybe<Scalars['uuid']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  deleted_at?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['uuid']>;
  industry_id?: Maybe<Scalars['uuid']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** order by max() on columns of table "candidate_industries" */
export type Candidate_Industries_Max_Order_By = {
  candidate_id?: Maybe<Order_By>;
  created_at?: Maybe<Order_By>;
  deleted_at?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  industry_id?: Maybe<Order_By>;
  updated_at?: Maybe<Order_By>;
};

/** aggregate min on columns */
export type Candidate_Industries_Min_Fields = {
  __typename?: 'candidate_industries_min_fields';
  candidate_id?: Maybe<Scalars['uuid']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  deleted_at?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['uuid']>;
  industry_id?: Maybe<Scalars['uuid']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** order by min() on columns of table "candidate_industries" */
export type Candidate_Industries_Min_Order_By = {
  candidate_id?: Maybe<Order_By>;
  created_at?: Maybe<Order_By>;
  deleted_at?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  industry_id?: Maybe<Order_By>;
  updated_at?: Maybe<Order_By>;
};

/** response of any mutation on the table "candidate_industries" */
export type Candidate_Industries_Mutation_Response = {
  __typename?: 'candidate_industries_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Candidate_Industries>;
};

/** on_conflict condition type for table "candidate_industries" */
export type Candidate_Industries_On_Conflict = {
  constraint: Candidate_Industries_Constraint;
  update_columns?: Array<Candidate_Industries_Update_Column>;
  where?: Maybe<Candidate_Industries_Bool_Exp>;
};

/** Ordering options when selecting data from "candidate_industries". */
export type Candidate_Industries_Order_By = {
  candidate?: Maybe<Candidates_Order_By>;
  candidate_id?: Maybe<Order_By>;
  created_at?: Maybe<Order_By>;
  deleted_at?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  industry?: Maybe<Industries_Order_By>;
  industry_id?: Maybe<Order_By>;
  updated_at?: Maybe<Order_By>;
  verified?: Maybe<Order_By>;
};

/** primary key columns input for table: candidate_industries */
export type Candidate_Industries_Pk_Columns_Input = {
  id: Scalars['uuid'];
};

/** select columns of table "candidate_industries" */
export enum Candidate_Industries_Select_Column {
  /** column name */
  CandidateId = 'candidate_id',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  DeletedAt = 'deleted_at',
  /** column name */
  Id = 'id',
  /** column name */
  IndustryId = 'industry_id',
  /** column name */
  UpdatedAt = 'updated_at',
  /** column name */
  Verified = 'verified'
}

/** select "candidate_industries_aggregate_bool_exp_bool_and_arguments_columns" columns of table "candidate_industries" */
export enum Candidate_Industries_Select_Column_Candidate_Industries_Aggregate_Bool_Exp_Bool_And_Arguments_Columns {
  /** column name */
  Verified = 'verified'
}

/** select "candidate_industries_aggregate_bool_exp_bool_or_arguments_columns" columns of table "candidate_industries" */
export enum Candidate_Industries_Select_Column_Candidate_Industries_Aggregate_Bool_Exp_Bool_Or_Arguments_Columns {
  /** column name */
  Verified = 'verified'
}

/** input type for updating data in table "candidate_industries" */
export type Candidate_Industries_Set_Input = {
  candidate_id?: Maybe<Scalars['uuid']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  deleted_at?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['uuid']>;
  industry_id?: Maybe<Scalars['uuid']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
  verified?: Maybe<Scalars['Boolean']>;
};

/** Streaming cursor of the table "candidate_industries" */
export type Candidate_Industries_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Candidate_Industries_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: Maybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Candidate_Industries_Stream_Cursor_Value_Input = {
  candidate_id?: Maybe<Scalars['uuid']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  deleted_at?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['uuid']>;
  industry_id?: Maybe<Scalars['uuid']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
  verified?: Maybe<Scalars['Boolean']>;
};

/** update columns of table "candidate_industries" */
export enum Candidate_Industries_Update_Column {
  /** column name */
  CandidateId = 'candidate_id',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  DeletedAt = 'deleted_at',
  /** column name */
  Id = 'id',
  /** column name */
  IndustryId = 'industry_id',
  /** column name */
  UpdatedAt = 'updated_at',
  /** column name */
  Verified = 'verified'
}

export type Candidate_Industries_Updates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: Maybe<Candidate_Industries_Set_Input>;
  /** filter the rows which have to be updated */
  where: Candidate_Industries_Bool_Exp;
};

/** columns and relationships of "candidate_locations" */
export type Candidate_Locations = {
  __typename?: 'candidate_locations';
  /** An object relationship */
  candidate: Candidates;
  candidate_id: Scalars['uuid'];
  created_at: Scalars['timestamptz'];
  deleted_at?: Maybe<Scalars['timestamptz']>;
  id: Scalars['uuid'];
  locality: Scalars['String'];
  /** An object relationship */
  location?: Maybe<Locations>;
  location_description?: Maybe<Scalars['location_name_enums']>;
  postcode: Scalars['String'];
  state: Scalars['String'];
  updated_at: Scalars['timestamptz'];
};

/** aggregated selection of "candidate_locations" */
export type Candidate_Locations_Aggregate = {
  __typename?: 'candidate_locations_aggregate';
  aggregate?: Maybe<Candidate_Locations_Aggregate_Fields>;
  nodes: Array<Candidate_Locations>;
};

export type Candidate_Locations_Aggregate_Bool_Exp = {
  count?: Maybe<Candidate_Locations_Aggregate_Bool_Exp_Count>;
};

export type Candidate_Locations_Aggregate_Bool_Exp_Count = {
  arguments?: Maybe<Array<Candidate_Locations_Select_Column>>;
  distinct?: Maybe<Scalars['Boolean']>;
  filter?: Maybe<Candidate_Locations_Bool_Exp>;
  predicate: Int_Comparison_Exp;
};

/** aggregate fields of "candidate_locations" */
export type Candidate_Locations_Aggregate_Fields = {
  __typename?: 'candidate_locations_aggregate_fields';
  count: Scalars['Int'];
  max?: Maybe<Candidate_Locations_Max_Fields>;
  min?: Maybe<Candidate_Locations_Min_Fields>;
};


/** aggregate fields of "candidate_locations" */
export type Candidate_Locations_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<Candidate_Locations_Select_Column>>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "candidate_locations" */
export type Candidate_Locations_Aggregate_Order_By = {
  count?: Maybe<Order_By>;
  max?: Maybe<Candidate_Locations_Max_Order_By>;
  min?: Maybe<Candidate_Locations_Min_Order_By>;
};

/** input type for inserting array relation for remote table "candidate_locations" */
export type Candidate_Locations_Arr_Rel_Insert_Input = {
  data: Array<Candidate_Locations_Insert_Input>;
  /** upsert condition */
  on_conflict?: Maybe<Candidate_Locations_On_Conflict>;
};

/** Boolean expression to filter rows from the table "candidate_locations". All fields are combined with a logical 'AND'. */
export type Candidate_Locations_Bool_Exp = {
  _and?: Maybe<Array<Candidate_Locations_Bool_Exp>>;
  _not?: Maybe<Candidate_Locations_Bool_Exp>;
  _or?: Maybe<Array<Candidate_Locations_Bool_Exp>>;
  candidate?: Maybe<Candidates_Bool_Exp>;
  candidate_id?: Maybe<Uuid_Comparison_Exp>;
  created_at?: Maybe<Timestamptz_Comparison_Exp>;
  deleted_at?: Maybe<Timestamptz_Comparison_Exp>;
  id?: Maybe<Uuid_Comparison_Exp>;
  locality?: Maybe<String_Comparison_Exp>;
  location?: Maybe<Locations_Bool_Exp>;
  location_description?: Maybe<Location_Name_Enums_Comparison_Exp>;
  postcode?: Maybe<String_Comparison_Exp>;
  state?: Maybe<String_Comparison_Exp>;
  updated_at?: Maybe<Timestamptz_Comparison_Exp>;
};

/** unique or primary key constraints on table "candidate_locations" */
export enum Candidate_Locations_Constraint {
  /** unique or primary key constraint on columns "id" */
  CandidateLocationsPkey = 'candidate_locations_pkey'
}

/** input type for inserting data into table "candidate_locations" */
export type Candidate_Locations_Insert_Input = {
  candidate?: Maybe<Candidates_Obj_Rel_Insert_Input>;
  candidate_id?: Maybe<Scalars['uuid']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  deleted_at?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['uuid']>;
  locality?: Maybe<Scalars['String']>;
  location?: Maybe<Locations_Obj_Rel_Insert_Input>;
  location_description?: Maybe<Scalars['location_name_enums']>;
  postcode?: Maybe<Scalars['String']>;
  state?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** aggregate max on columns */
export type Candidate_Locations_Max_Fields = {
  __typename?: 'candidate_locations_max_fields';
  candidate_id?: Maybe<Scalars['uuid']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  deleted_at?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['uuid']>;
  locality?: Maybe<Scalars['String']>;
  location_description?: Maybe<Scalars['location_name_enums']>;
  postcode?: Maybe<Scalars['String']>;
  state?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** order by max() on columns of table "candidate_locations" */
export type Candidate_Locations_Max_Order_By = {
  candidate_id?: Maybe<Order_By>;
  created_at?: Maybe<Order_By>;
  deleted_at?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  locality?: Maybe<Order_By>;
  location_description?: Maybe<Order_By>;
  postcode?: Maybe<Order_By>;
  state?: Maybe<Order_By>;
  updated_at?: Maybe<Order_By>;
};

/** aggregate min on columns */
export type Candidate_Locations_Min_Fields = {
  __typename?: 'candidate_locations_min_fields';
  candidate_id?: Maybe<Scalars['uuid']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  deleted_at?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['uuid']>;
  locality?: Maybe<Scalars['String']>;
  location_description?: Maybe<Scalars['location_name_enums']>;
  postcode?: Maybe<Scalars['String']>;
  state?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** order by min() on columns of table "candidate_locations" */
export type Candidate_Locations_Min_Order_By = {
  candidate_id?: Maybe<Order_By>;
  created_at?: Maybe<Order_By>;
  deleted_at?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  locality?: Maybe<Order_By>;
  location_description?: Maybe<Order_By>;
  postcode?: Maybe<Order_By>;
  state?: Maybe<Order_By>;
  updated_at?: Maybe<Order_By>;
};

/** response of any mutation on the table "candidate_locations" */
export type Candidate_Locations_Mutation_Response = {
  __typename?: 'candidate_locations_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Candidate_Locations>;
};

/** on_conflict condition type for table "candidate_locations" */
export type Candidate_Locations_On_Conflict = {
  constraint: Candidate_Locations_Constraint;
  update_columns?: Array<Candidate_Locations_Update_Column>;
  where?: Maybe<Candidate_Locations_Bool_Exp>;
};

/** Ordering options when selecting data from "candidate_locations". */
export type Candidate_Locations_Order_By = {
  candidate?: Maybe<Candidates_Order_By>;
  candidate_id?: Maybe<Order_By>;
  created_at?: Maybe<Order_By>;
  deleted_at?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  locality?: Maybe<Order_By>;
  location?: Maybe<Locations_Order_By>;
  location_description?: Maybe<Order_By>;
  postcode?: Maybe<Order_By>;
  state?: Maybe<Order_By>;
  updated_at?: Maybe<Order_By>;
};

/** primary key columns input for table: candidate_locations */
export type Candidate_Locations_Pk_Columns_Input = {
  id: Scalars['uuid'];
};

/** select columns of table "candidate_locations" */
export enum Candidate_Locations_Select_Column {
  /** column name */
  CandidateId = 'candidate_id',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  DeletedAt = 'deleted_at',
  /** column name */
  Id = 'id',
  /** column name */
  Locality = 'locality',
  /** column name */
  LocationDescription = 'location_description',
  /** column name */
  Postcode = 'postcode',
  /** column name */
  State = 'state',
  /** column name */
  UpdatedAt = 'updated_at'
}

/** input type for updating data in table "candidate_locations" */
export type Candidate_Locations_Set_Input = {
  candidate_id?: Maybe<Scalars['uuid']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  deleted_at?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['uuid']>;
  locality?: Maybe<Scalars['String']>;
  location_description?: Maybe<Scalars['location_name_enums']>;
  postcode?: Maybe<Scalars['String']>;
  state?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** Streaming cursor of the table "candidate_locations" */
export type Candidate_Locations_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Candidate_Locations_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: Maybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Candidate_Locations_Stream_Cursor_Value_Input = {
  candidate_id?: Maybe<Scalars['uuid']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  deleted_at?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['uuid']>;
  locality?: Maybe<Scalars['String']>;
  location_description?: Maybe<Scalars['location_name_enums']>;
  postcode?: Maybe<Scalars['String']>;
  state?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** update columns of table "candidate_locations" */
export enum Candidate_Locations_Update_Column {
  /** column name */
  CandidateId = 'candidate_id',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  DeletedAt = 'deleted_at',
  /** column name */
  Id = 'id',
  /** column name */
  Locality = 'locality',
  /** column name */
  LocationDescription = 'location_description',
  /** column name */
  Postcode = 'postcode',
  /** column name */
  State = 'state',
  /** column name */
  UpdatedAt = 'updated_at'
}

export type Candidate_Locations_Updates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: Maybe<Candidate_Locations_Set_Input>;
  /** filter the rows which have to be updated */
  where: Candidate_Locations_Bool_Exp;
};

/** columns and relationships of "candidate_majors" */
export type Candidate_Majors = {
  __typename?: 'candidate_majors';
  /** An object relationship */
  candidate: Candidates;
  candidate_id: Scalars['uuid'];
  created_at: Scalars['timestamptz'];
  deleted_at?: Maybe<Scalars['timestamptz']>;
  id: Scalars['uuid'];
  /** An object relationship */
  major: Majors;
  major_id: Scalars['uuid'];
  updated_at: Scalars['timestamptz'];
};

/** aggregated selection of "candidate_majors" */
export type Candidate_Majors_Aggregate = {
  __typename?: 'candidate_majors_aggregate';
  aggregate?: Maybe<Candidate_Majors_Aggregate_Fields>;
  nodes: Array<Candidate_Majors>;
};

export type Candidate_Majors_Aggregate_Bool_Exp = {
  count?: Maybe<Candidate_Majors_Aggregate_Bool_Exp_Count>;
};

export type Candidate_Majors_Aggregate_Bool_Exp_Count = {
  arguments?: Maybe<Array<Candidate_Majors_Select_Column>>;
  distinct?: Maybe<Scalars['Boolean']>;
  filter?: Maybe<Candidate_Majors_Bool_Exp>;
  predicate: Int_Comparison_Exp;
};

/** aggregate fields of "candidate_majors" */
export type Candidate_Majors_Aggregate_Fields = {
  __typename?: 'candidate_majors_aggregate_fields';
  count: Scalars['Int'];
  max?: Maybe<Candidate_Majors_Max_Fields>;
  min?: Maybe<Candidate_Majors_Min_Fields>;
};


/** aggregate fields of "candidate_majors" */
export type Candidate_Majors_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<Candidate_Majors_Select_Column>>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "candidate_majors" */
export type Candidate_Majors_Aggregate_Order_By = {
  count?: Maybe<Order_By>;
  max?: Maybe<Candidate_Majors_Max_Order_By>;
  min?: Maybe<Candidate_Majors_Min_Order_By>;
};

/** input type for inserting array relation for remote table "candidate_majors" */
export type Candidate_Majors_Arr_Rel_Insert_Input = {
  data: Array<Candidate_Majors_Insert_Input>;
  /** upsert condition */
  on_conflict?: Maybe<Candidate_Majors_On_Conflict>;
};

/** Boolean expression to filter rows from the table "candidate_majors". All fields are combined with a logical 'AND'. */
export type Candidate_Majors_Bool_Exp = {
  _and?: Maybe<Array<Candidate_Majors_Bool_Exp>>;
  _not?: Maybe<Candidate_Majors_Bool_Exp>;
  _or?: Maybe<Array<Candidate_Majors_Bool_Exp>>;
  candidate?: Maybe<Candidates_Bool_Exp>;
  candidate_id?: Maybe<Uuid_Comparison_Exp>;
  created_at?: Maybe<Timestamptz_Comparison_Exp>;
  deleted_at?: Maybe<Timestamptz_Comparison_Exp>;
  id?: Maybe<Uuid_Comparison_Exp>;
  major?: Maybe<Majors_Bool_Exp>;
  major_id?: Maybe<Uuid_Comparison_Exp>;
  updated_at?: Maybe<Timestamptz_Comparison_Exp>;
};

/** unique or primary key constraints on table "candidate_majors" */
export enum Candidate_Majors_Constraint {
  /** unique or primary key constraint on columns "candidate_id", "major_id" */
  CandidateMajorsCandidateIdMajorIdKey = 'candidate_majors_candidate_id_major_id_key',
  /** unique or primary key constraint on columns "id" */
  CandidateMajorsPkey = 'candidate_majors_pkey'
}

/** input type for inserting data into table "candidate_majors" */
export type Candidate_Majors_Insert_Input = {
  candidate?: Maybe<Candidates_Obj_Rel_Insert_Input>;
  candidate_id?: Maybe<Scalars['uuid']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  deleted_at?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['uuid']>;
  major?: Maybe<Majors_Obj_Rel_Insert_Input>;
  major_id?: Maybe<Scalars['uuid']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** aggregate max on columns */
export type Candidate_Majors_Max_Fields = {
  __typename?: 'candidate_majors_max_fields';
  candidate_id?: Maybe<Scalars['uuid']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  deleted_at?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['uuid']>;
  major_id?: Maybe<Scalars['uuid']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** order by max() on columns of table "candidate_majors" */
export type Candidate_Majors_Max_Order_By = {
  candidate_id?: Maybe<Order_By>;
  created_at?: Maybe<Order_By>;
  deleted_at?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  major_id?: Maybe<Order_By>;
  updated_at?: Maybe<Order_By>;
};

/** aggregate min on columns */
export type Candidate_Majors_Min_Fields = {
  __typename?: 'candidate_majors_min_fields';
  candidate_id?: Maybe<Scalars['uuid']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  deleted_at?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['uuid']>;
  major_id?: Maybe<Scalars['uuid']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** order by min() on columns of table "candidate_majors" */
export type Candidate_Majors_Min_Order_By = {
  candidate_id?: Maybe<Order_By>;
  created_at?: Maybe<Order_By>;
  deleted_at?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  major_id?: Maybe<Order_By>;
  updated_at?: Maybe<Order_By>;
};

/** response of any mutation on the table "candidate_majors" */
export type Candidate_Majors_Mutation_Response = {
  __typename?: 'candidate_majors_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Candidate_Majors>;
};

/** on_conflict condition type for table "candidate_majors" */
export type Candidate_Majors_On_Conflict = {
  constraint: Candidate_Majors_Constraint;
  update_columns?: Array<Candidate_Majors_Update_Column>;
  where?: Maybe<Candidate_Majors_Bool_Exp>;
};

/** Ordering options when selecting data from "candidate_majors". */
export type Candidate_Majors_Order_By = {
  candidate?: Maybe<Candidates_Order_By>;
  candidate_id?: Maybe<Order_By>;
  created_at?: Maybe<Order_By>;
  deleted_at?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  major?: Maybe<Majors_Order_By>;
  major_id?: Maybe<Order_By>;
  updated_at?: Maybe<Order_By>;
};

/** primary key columns input for table: candidate_majors */
export type Candidate_Majors_Pk_Columns_Input = {
  id: Scalars['uuid'];
};

/** select columns of table "candidate_majors" */
export enum Candidate_Majors_Select_Column {
  /** column name */
  CandidateId = 'candidate_id',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  DeletedAt = 'deleted_at',
  /** column name */
  Id = 'id',
  /** column name */
  MajorId = 'major_id',
  /** column name */
  UpdatedAt = 'updated_at'
}

/** input type for updating data in table "candidate_majors" */
export type Candidate_Majors_Set_Input = {
  candidate_id?: Maybe<Scalars['uuid']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  deleted_at?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['uuid']>;
  major_id?: Maybe<Scalars['uuid']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** Streaming cursor of the table "candidate_majors" */
export type Candidate_Majors_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Candidate_Majors_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: Maybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Candidate_Majors_Stream_Cursor_Value_Input = {
  candidate_id?: Maybe<Scalars['uuid']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  deleted_at?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['uuid']>;
  major_id?: Maybe<Scalars['uuid']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** update columns of table "candidate_majors" */
export enum Candidate_Majors_Update_Column {
  /** column name */
  CandidateId = 'candidate_id',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  DeletedAt = 'deleted_at',
  /** column name */
  Id = 'id',
  /** column name */
  MajorId = 'major_id',
  /** column name */
  UpdatedAt = 'updated_at'
}

export type Candidate_Majors_Updates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: Maybe<Candidate_Majors_Set_Input>;
  /** filter the rows which have to be updated */
  where: Candidate_Majors_Bool_Exp;
};

/** columns and relationships of "candidate_placements" */
export type Candidate_Placements = {
  __typename?: 'candidate_placements';
  /** An object relationship */
  candidate: Candidates;
  candidate_id: Scalars['uuid'];
  /** An object relationship */
  client: Clients;
  client_id: Scalars['uuid'];
  commences_on?: Maybe<Scalars['timestamptz']>;
  created_at: Scalars['timestamptz'];
  deleted_at?: Maybe<Scalars['timestamptz']>;
  desisted_on?: Maybe<Scalars['timestamptz']>;
  id: Scalars['uuid'];
  /** An object relationship */
  job?: Maybe<Jobs>;
  job_id?: Maybe<Scalars['uuid']>;
  updated_at: Scalars['timestamptz'];
};

/** aggregated selection of "candidate_placements" */
export type Candidate_Placements_Aggregate = {
  __typename?: 'candidate_placements_aggregate';
  aggregate?: Maybe<Candidate_Placements_Aggregate_Fields>;
  nodes: Array<Candidate_Placements>;
};

export type Candidate_Placements_Aggregate_Bool_Exp = {
  count?: Maybe<Candidate_Placements_Aggregate_Bool_Exp_Count>;
};

export type Candidate_Placements_Aggregate_Bool_Exp_Count = {
  arguments?: Maybe<Array<Candidate_Placements_Select_Column>>;
  distinct?: Maybe<Scalars['Boolean']>;
  filter?: Maybe<Candidate_Placements_Bool_Exp>;
  predicate: Int_Comparison_Exp;
};

/** aggregate fields of "candidate_placements" */
export type Candidate_Placements_Aggregate_Fields = {
  __typename?: 'candidate_placements_aggregate_fields';
  count: Scalars['Int'];
  max?: Maybe<Candidate_Placements_Max_Fields>;
  min?: Maybe<Candidate_Placements_Min_Fields>;
};


/** aggregate fields of "candidate_placements" */
export type Candidate_Placements_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<Candidate_Placements_Select_Column>>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "candidate_placements" */
export type Candidate_Placements_Aggregate_Order_By = {
  count?: Maybe<Order_By>;
  max?: Maybe<Candidate_Placements_Max_Order_By>;
  min?: Maybe<Candidate_Placements_Min_Order_By>;
};

/** input type for inserting array relation for remote table "candidate_placements" */
export type Candidate_Placements_Arr_Rel_Insert_Input = {
  data: Array<Candidate_Placements_Insert_Input>;
  /** upsert condition */
  on_conflict?: Maybe<Candidate_Placements_On_Conflict>;
};

/** Boolean expression to filter rows from the table "candidate_placements". All fields are combined with a logical 'AND'. */
export type Candidate_Placements_Bool_Exp = {
  _and?: Maybe<Array<Candidate_Placements_Bool_Exp>>;
  _not?: Maybe<Candidate_Placements_Bool_Exp>;
  _or?: Maybe<Array<Candidate_Placements_Bool_Exp>>;
  candidate?: Maybe<Candidates_Bool_Exp>;
  candidate_id?: Maybe<Uuid_Comparison_Exp>;
  client?: Maybe<Clients_Bool_Exp>;
  client_id?: Maybe<Uuid_Comparison_Exp>;
  commences_on?: Maybe<Timestamptz_Comparison_Exp>;
  created_at?: Maybe<Timestamptz_Comparison_Exp>;
  deleted_at?: Maybe<Timestamptz_Comparison_Exp>;
  desisted_on?: Maybe<Timestamptz_Comparison_Exp>;
  id?: Maybe<Uuid_Comparison_Exp>;
  job?: Maybe<Jobs_Bool_Exp>;
  job_id?: Maybe<Uuid_Comparison_Exp>;
  updated_at?: Maybe<Timestamptz_Comparison_Exp>;
};

/** unique or primary key constraints on table "candidate_placements" */
export enum Candidate_Placements_Constraint {
  /** unique or primary key constraint on columns "id" */
  CandidatePlacementsPkey = 'candidate_placements_pkey'
}

/** input type for inserting data into table "candidate_placements" */
export type Candidate_Placements_Insert_Input = {
  candidate?: Maybe<Candidates_Obj_Rel_Insert_Input>;
  candidate_id?: Maybe<Scalars['uuid']>;
  client?: Maybe<Clients_Obj_Rel_Insert_Input>;
  client_id?: Maybe<Scalars['uuid']>;
  commences_on?: Maybe<Scalars['timestamptz']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  deleted_at?: Maybe<Scalars['timestamptz']>;
  desisted_on?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['uuid']>;
  job?: Maybe<Jobs_Obj_Rel_Insert_Input>;
  job_id?: Maybe<Scalars['uuid']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** aggregate max on columns */
export type Candidate_Placements_Max_Fields = {
  __typename?: 'candidate_placements_max_fields';
  candidate_id?: Maybe<Scalars['uuid']>;
  client_id?: Maybe<Scalars['uuid']>;
  commences_on?: Maybe<Scalars['timestamptz']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  deleted_at?: Maybe<Scalars['timestamptz']>;
  desisted_on?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['uuid']>;
  job_id?: Maybe<Scalars['uuid']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** order by max() on columns of table "candidate_placements" */
export type Candidate_Placements_Max_Order_By = {
  candidate_id?: Maybe<Order_By>;
  client_id?: Maybe<Order_By>;
  commences_on?: Maybe<Order_By>;
  created_at?: Maybe<Order_By>;
  deleted_at?: Maybe<Order_By>;
  desisted_on?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  job_id?: Maybe<Order_By>;
  updated_at?: Maybe<Order_By>;
};

/** aggregate min on columns */
export type Candidate_Placements_Min_Fields = {
  __typename?: 'candidate_placements_min_fields';
  candidate_id?: Maybe<Scalars['uuid']>;
  client_id?: Maybe<Scalars['uuid']>;
  commences_on?: Maybe<Scalars['timestamptz']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  deleted_at?: Maybe<Scalars['timestamptz']>;
  desisted_on?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['uuid']>;
  job_id?: Maybe<Scalars['uuid']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** order by min() on columns of table "candidate_placements" */
export type Candidate_Placements_Min_Order_By = {
  candidate_id?: Maybe<Order_By>;
  client_id?: Maybe<Order_By>;
  commences_on?: Maybe<Order_By>;
  created_at?: Maybe<Order_By>;
  deleted_at?: Maybe<Order_By>;
  desisted_on?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  job_id?: Maybe<Order_By>;
  updated_at?: Maybe<Order_By>;
};

/** response of any mutation on the table "candidate_placements" */
export type Candidate_Placements_Mutation_Response = {
  __typename?: 'candidate_placements_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Candidate_Placements>;
};

/** on_conflict condition type for table "candidate_placements" */
export type Candidate_Placements_On_Conflict = {
  constraint: Candidate_Placements_Constraint;
  update_columns?: Array<Candidate_Placements_Update_Column>;
  where?: Maybe<Candidate_Placements_Bool_Exp>;
};

/** Ordering options when selecting data from "candidate_placements". */
export type Candidate_Placements_Order_By = {
  candidate?: Maybe<Candidates_Order_By>;
  candidate_id?: Maybe<Order_By>;
  client?: Maybe<Clients_Order_By>;
  client_id?: Maybe<Order_By>;
  commences_on?: Maybe<Order_By>;
  created_at?: Maybe<Order_By>;
  deleted_at?: Maybe<Order_By>;
  desisted_on?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  job?: Maybe<Jobs_Order_By>;
  job_id?: Maybe<Order_By>;
  updated_at?: Maybe<Order_By>;
};

/** primary key columns input for table: candidate_placements */
export type Candidate_Placements_Pk_Columns_Input = {
  id: Scalars['uuid'];
};

/** select columns of table "candidate_placements" */
export enum Candidate_Placements_Select_Column {
  /** column name */
  CandidateId = 'candidate_id',
  /** column name */
  ClientId = 'client_id',
  /** column name */
  CommencesOn = 'commences_on',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  DeletedAt = 'deleted_at',
  /** column name */
  DesistedOn = 'desisted_on',
  /** column name */
  Id = 'id',
  /** column name */
  JobId = 'job_id',
  /** column name */
  UpdatedAt = 'updated_at'
}

/** input type for updating data in table "candidate_placements" */
export type Candidate_Placements_Set_Input = {
  candidate_id?: Maybe<Scalars['uuid']>;
  client_id?: Maybe<Scalars['uuid']>;
  commences_on?: Maybe<Scalars['timestamptz']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  deleted_at?: Maybe<Scalars['timestamptz']>;
  desisted_on?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['uuid']>;
  job_id?: Maybe<Scalars['uuid']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** Streaming cursor of the table "candidate_placements" */
export type Candidate_Placements_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Candidate_Placements_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: Maybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Candidate_Placements_Stream_Cursor_Value_Input = {
  candidate_id?: Maybe<Scalars['uuid']>;
  client_id?: Maybe<Scalars['uuid']>;
  commences_on?: Maybe<Scalars['timestamptz']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  deleted_at?: Maybe<Scalars['timestamptz']>;
  desisted_on?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['uuid']>;
  job_id?: Maybe<Scalars['uuid']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** update columns of table "candidate_placements" */
export enum Candidate_Placements_Update_Column {
  /** column name */
  CandidateId = 'candidate_id',
  /** column name */
  ClientId = 'client_id',
  /** column name */
  CommencesOn = 'commences_on',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  DeletedAt = 'deleted_at',
  /** column name */
  DesistedOn = 'desisted_on',
  /** column name */
  Id = 'id',
  /** column name */
  JobId = 'job_id',
  /** column name */
  UpdatedAt = 'updated_at'
}

export type Candidate_Placements_Updates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: Maybe<Candidate_Placements_Set_Input>;
  /** filter the rows which have to be updated */
  where: Candidate_Placements_Bool_Exp;
};

/** columns and relationships of "candidate_skills" */
export type Candidate_Skills = {
  __typename?: 'candidate_skills';
  /** An object relationship */
  candidate: Candidates;
  candidate_id: Scalars['uuid'];
  created_at: Scalars['timestamptz'];
  deleted_at?: Maybe<Scalars['timestamptz']>;
  id: Scalars['uuid'];
  score?: Maybe<Scalars['skill_score_enums']>;
  /** An object relationship */
  skill: Skills;
  skill_id: Scalars['uuid'];
  updated_at: Scalars['timestamptz'];
};

/** aggregated selection of "candidate_skills" */
export type Candidate_Skills_Aggregate = {
  __typename?: 'candidate_skills_aggregate';
  aggregate?: Maybe<Candidate_Skills_Aggregate_Fields>;
  nodes: Array<Candidate_Skills>;
};

export type Candidate_Skills_Aggregate_Bool_Exp = {
  count?: Maybe<Candidate_Skills_Aggregate_Bool_Exp_Count>;
};

export type Candidate_Skills_Aggregate_Bool_Exp_Count = {
  arguments?: Maybe<Array<Candidate_Skills_Select_Column>>;
  distinct?: Maybe<Scalars['Boolean']>;
  filter?: Maybe<Candidate_Skills_Bool_Exp>;
  predicate: Int_Comparison_Exp;
};

/** aggregate fields of "candidate_skills" */
export type Candidate_Skills_Aggregate_Fields = {
  __typename?: 'candidate_skills_aggregate_fields';
  count: Scalars['Int'];
  max?: Maybe<Candidate_Skills_Max_Fields>;
  min?: Maybe<Candidate_Skills_Min_Fields>;
};


/** aggregate fields of "candidate_skills" */
export type Candidate_Skills_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<Candidate_Skills_Select_Column>>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "candidate_skills" */
export type Candidate_Skills_Aggregate_Order_By = {
  count?: Maybe<Order_By>;
  max?: Maybe<Candidate_Skills_Max_Order_By>;
  min?: Maybe<Candidate_Skills_Min_Order_By>;
};

/** input type for inserting array relation for remote table "candidate_skills" */
export type Candidate_Skills_Arr_Rel_Insert_Input = {
  data: Array<Candidate_Skills_Insert_Input>;
  /** upsert condition */
  on_conflict?: Maybe<Candidate_Skills_On_Conflict>;
};

/** Boolean expression to filter rows from the table "candidate_skills". All fields are combined with a logical 'AND'. */
export type Candidate_Skills_Bool_Exp = {
  _and?: Maybe<Array<Candidate_Skills_Bool_Exp>>;
  _not?: Maybe<Candidate_Skills_Bool_Exp>;
  _or?: Maybe<Array<Candidate_Skills_Bool_Exp>>;
  candidate?: Maybe<Candidates_Bool_Exp>;
  candidate_id?: Maybe<Uuid_Comparison_Exp>;
  created_at?: Maybe<Timestamptz_Comparison_Exp>;
  deleted_at?: Maybe<Timestamptz_Comparison_Exp>;
  id?: Maybe<Uuid_Comparison_Exp>;
  score?: Maybe<Skill_Score_Enums_Comparison_Exp>;
  skill?: Maybe<Skills_Bool_Exp>;
  skill_id?: Maybe<Uuid_Comparison_Exp>;
  updated_at?: Maybe<Timestamptz_Comparison_Exp>;
};

/** unique or primary key constraints on table "candidate_skills" */
export enum Candidate_Skills_Constraint {
  /** unique or primary key constraint on columns "skill_id", "candidate_id" */
  CandidateSkillsCandidateIdSkillIdKey = 'candidate_skills_candidate_id_skill_id_key',
  /** unique or primary key constraint on columns "id" */
  CandidateSkillsPkey = 'candidate_skills_pkey'
}

/** input type for inserting data into table "candidate_skills" */
export type Candidate_Skills_Insert_Input = {
  candidate?: Maybe<Candidates_Obj_Rel_Insert_Input>;
  candidate_id?: Maybe<Scalars['uuid']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  deleted_at?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['uuid']>;
  score?: Maybe<Scalars['skill_score_enums']>;
  skill?: Maybe<Skills_Obj_Rel_Insert_Input>;
  skill_id?: Maybe<Scalars['uuid']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** aggregate max on columns */
export type Candidate_Skills_Max_Fields = {
  __typename?: 'candidate_skills_max_fields';
  candidate_id?: Maybe<Scalars['uuid']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  deleted_at?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['uuid']>;
  score?: Maybe<Scalars['skill_score_enums']>;
  skill_id?: Maybe<Scalars['uuid']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** order by max() on columns of table "candidate_skills" */
export type Candidate_Skills_Max_Order_By = {
  candidate_id?: Maybe<Order_By>;
  created_at?: Maybe<Order_By>;
  deleted_at?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  score?: Maybe<Order_By>;
  skill_id?: Maybe<Order_By>;
  updated_at?: Maybe<Order_By>;
};

/** aggregate min on columns */
export type Candidate_Skills_Min_Fields = {
  __typename?: 'candidate_skills_min_fields';
  candidate_id?: Maybe<Scalars['uuid']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  deleted_at?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['uuid']>;
  score?: Maybe<Scalars['skill_score_enums']>;
  skill_id?: Maybe<Scalars['uuid']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** order by min() on columns of table "candidate_skills" */
export type Candidate_Skills_Min_Order_By = {
  candidate_id?: Maybe<Order_By>;
  created_at?: Maybe<Order_By>;
  deleted_at?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  score?: Maybe<Order_By>;
  skill_id?: Maybe<Order_By>;
  updated_at?: Maybe<Order_By>;
};

/** response of any mutation on the table "candidate_skills" */
export type Candidate_Skills_Mutation_Response = {
  __typename?: 'candidate_skills_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Candidate_Skills>;
};

/** on_conflict condition type for table "candidate_skills" */
export type Candidate_Skills_On_Conflict = {
  constraint: Candidate_Skills_Constraint;
  update_columns?: Array<Candidate_Skills_Update_Column>;
  where?: Maybe<Candidate_Skills_Bool_Exp>;
};

/** Ordering options when selecting data from "candidate_skills". */
export type Candidate_Skills_Order_By = {
  candidate?: Maybe<Candidates_Order_By>;
  candidate_id?: Maybe<Order_By>;
  created_at?: Maybe<Order_By>;
  deleted_at?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  score?: Maybe<Order_By>;
  skill?: Maybe<Skills_Order_By>;
  skill_id?: Maybe<Order_By>;
  updated_at?: Maybe<Order_By>;
};

/** primary key columns input for table: candidate_skills */
export type Candidate_Skills_Pk_Columns_Input = {
  id: Scalars['uuid'];
};

/** select columns of table "candidate_skills" */
export enum Candidate_Skills_Select_Column {
  /** column name */
  CandidateId = 'candidate_id',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  DeletedAt = 'deleted_at',
  /** column name */
  Id = 'id',
  /** column name */
  Score = 'score',
  /** column name */
  SkillId = 'skill_id',
  /** column name */
  UpdatedAt = 'updated_at'
}

/** input type for updating data in table "candidate_skills" */
export type Candidate_Skills_Set_Input = {
  candidate_id?: Maybe<Scalars['uuid']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  deleted_at?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['uuid']>;
  score?: Maybe<Scalars['skill_score_enums']>;
  skill_id?: Maybe<Scalars['uuid']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** Streaming cursor of the table "candidate_skills" */
export type Candidate_Skills_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Candidate_Skills_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: Maybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Candidate_Skills_Stream_Cursor_Value_Input = {
  candidate_id?: Maybe<Scalars['uuid']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  deleted_at?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['uuid']>;
  score?: Maybe<Scalars['skill_score_enums']>;
  skill_id?: Maybe<Scalars['uuid']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** update columns of table "candidate_skills" */
export enum Candidate_Skills_Update_Column {
  /** column name */
  CandidateId = 'candidate_id',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  DeletedAt = 'deleted_at',
  /** column name */
  Id = 'id',
  /** column name */
  Score = 'score',
  /** column name */
  SkillId = 'skill_id',
  /** column name */
  UpdatedAt = 'updated_at'
}

export type Candidate_Skills_Updates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: Maybe<Candidate_Skills_Set_Input>;
  /** filter the rows which have to be updated */
  where: Candidate_Skills_Bool_Exp;
};


/** Boolean expression to compare columns of type "candidate_status_enums". All fields are combined with logical 'AND'. */
export type Candidate_Status_Enums_Comparison_Exp = {
  _eq?: Maybe<Scalars['candidate_status_enums']>;
  _gt?: Maybe<Scalars['candidate_status_enums']>;
  _gte?: Maybe<Scalars['candidate_status_enums']>;
  _in?: Maybe<Array<Scalars['candidate_status_enums']>>;
  _is_null?: Maybe<Scalars['Boolean']>;
  _lt?: Maybe<Scalars['candidate_status_enums']>;
  _lte?: Maybe<Scalars['candidate_status_enums']>;
  _neq?: Maybe<Scalars['candidate_status_enums']>;
  _nin?: Maybe<Array<Scalars['candidate_status_enums']>>;
};

/** columns and relationships of "candidates" */
export type Candidates = {
  __typename?: 'candidates';
  available_part_time_days_from?: Maybe<Scalars['Int']>;
  available_part_time_days_to?: Maybe<Scalars['Int']>;
  bio?: Maybe<Scalars['String']>;
  /** An array relationship */
  candidate_courses: Array<Candidate_Courses>;
  /** An aggregate relationship */
  candidate_courses_aggregate: Candidate_Courses_Aggregate;
  /** An array relationship */
  candidate_degrees: Array<Candidate_Degrees>;
  /** An aggregate relationship */
  candidate_degrees_aggregate: Candidate_Degrees_Aggregate;
  /** An array relationship */
  candidate_follows: Array<Candidate_Follows>;
  /** An aggregate relationship */
  candidate_follows_aggregate: Candidate_Follows_Aggregate;
  /** An array relationship */
  candidate_industries: Array<Candidate_Industries>;
  /** An aggregate relationship */
  candidate_industries_aggregate: Candidate_Industries_Aggregate;
  /** An array relationship */
  candidate_locations: Array<Candidate_Locations>;
  /** An aggregate relationship */
  candidate_locations_aggregate: Candidate_Locations_Aggregate;
  /** An array relationship */
  candidate_majors: Array<Candidate_Majors>;
  /** An aggregate relationship */
  candidate_majors_aggregate: Candidate_Majors_Aggregate;
  /** An array relationship */
  candidate_placements: Array<Candidate_Placements>;
  /** An aggregate relationship */
  candidate_placements_aggregate: Candidate_Placements_Aggregate;
  /** An array relationship */
  candidate_skills: Array<Candidate_Skills>;
  /** An aggregate relationship */
  candidate_skills_aggregate: Candidate_Skills_Aggregate;
  created_at: Scalars['timestamptz'];
  cv_url?: Maybe<Scalars['String']>;
  deleted_at?: Maybe<Scalars['timestamptz']>;
  education_level?: Maybe<Scalars['highest_level_edu_enums']>;
  email?: Maybe<Scalars['String']>;
  /** An array relationship */
  events: Array<Events>;
  /** An aggregate relationship */
  events_aggregate: Events_Aggregate;
  /** An array relationship */
  exclusive_candidates: Array<Exclusive_Candidates>;
  /** An aggregate relationship */
  exclusive_candidates_aggregate: Exclusive_Candidates_Aggregate;
  first_name?: Maybe<Scalars['String']>;
  graduates_on?: Maybe<Scalars['timestamptz']>;
  has_work_experience?: Maybe<Scalars['Boolean']>;
  id: Scalars['uuid'];
  /** An array relationship */
  interview_participants: Array<Interview_Participants>;
  /** An aggregate relationship */
  interview_participants_aggregate: Interview_Participants_Aggregate;
  /** An array relationship */
  job_candidates: Array<Job_Candidates>;
  /** An aggregate relationship */
  job_candidates_aggregate: Job_Candidates_Aggregate;
  last_name?: Maybe<Scalars['String']>;
  notes?: Maybe<Scalars['String']>;
  /** An array relationship */
  offers: Array<Offers>;
  /** An aggregate relationship */
  offers_aggregate: Offers_Aggregate;
  open_to_part_time_prior_to_graduation?: Maybe<Scalars['Boolean']>;
  /** An object relationship */
  owner?: Maybe<Users>;
  owner_id?: Maybe<Scalars['String']>;
  phone?: Maybe<Scalars['String']>;
  photo_url?: Maybe<Scalars['String']>;
  profile_progress?: Maybe<Scalars['Int']>;
  psych_url?: Maybe<Scalars['String']>;
  published?: Maybe<Scalars['Boolean']>;
  published_on?: Maybe<Scalars['timestamptz']>;
  relocate?: Maybe<Scalars['Boolean']>;
  residency_status?: Maybe<Scalars['residency_status_enums']>;
  /** An array relationship */
  shortlists: Array<Shortlists>;
  /** An aggregate relationship */
  shortlists_aggregate: Shortlists_Aggregate;
  source?: Maybe<Scalars['String']>;
  source_tenant_id?: Maybe<Scalars['uuid']>;
  status?: Maybe<Scalars['candidate_status_enums']>;
  /** An object relationship */
  tenant?: Maybe<Tenants>;
  /** An array relationship */
  tenant_candidates: Array<Tenant_Candidates>;
  /** An aggregate relationship */
  tenant_candidates_aggregate: Tenant_Candidates_Aggregate;
  test?: Maybe<Scalars['Boolean']>;
  transcripts_url?: Maybe<Scalars['String']>;
  /** An object relationship */
  university?: Maybe<Universities>;
  university_id?: Maybe<Scalars['uuid']>;
  updated_at: Scalars['timestamptz'];
  video_url?: Maybe<Scalars['String']>;
  work_arrangement?: Maybe<Scalars['work_arrangement_enums']>;
};


/** columns and relationships of "candidates" */
export type CandidatesCandidate_CoursesArgs = {
  distinct_on?: Maybe<Array<Candidate_Courses_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Candidate_Courses_Order_By>>;
  where?: Maybe<Candidate_Courses_Bool_Exp>;
};


/** columns and relationships of "candidates" */
export type CandidatesCandidate_Courses_AggregateArgs = {
  distinct_on?: Maybe<Array<Candidate_Courses_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Candidate_Courses_Order_By>>;
  where?: Maybe<Candidate_Courses_Bool_Exp>;
};


/** columns and relationships of "candidates" */
export type CandidatesCandidate_DegreesArgs = {
  distinct_on?: Maybe<Array<Candidate_Degrees_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Candidate_Degrees_Order_By>>;
  where?: Maybe<Candidate_Degrees_Bool_Exp>;
};


/** columns and relationships of "candidates" */
export type CandidatesCandidate_Degrees_AggregateArgs = {
  distinct_on?: Maybe<Array<Candidate_Degrees_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Candidate_Degrees_Order_By>>;
  where?: Maybe<Candidate_Degrees_Bool_Exp>;
};


/** columns and relationships of "candidates" */
export type CandidatesCandidate_FollowsArgs = {
  distinct_on?: Maybe<Array<Candidate_Follows_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Candidate_Follows_Order_By>>;
  where?: Maybe<Candidate_Follows_Bool_Exp>;
};


/** columns and relationships of "candidates" */
export type CandidatesCandidate_Follows_AggregateArgs = {
  distinct_on?: Maybe<Array<Candidate_Follows_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Candidate_Follows_Order_By>>;
  where?: Maybe<Candidate_Follows_Bool_Exp>;
};


/** columns and relationships of "candidates" */
export type CandidatesCandidate_IndustriesArgs = {
  distinct_on?: Maybe<Array<Candidate_Industries_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Candidate_Industries_Order_By>>;
  where?: Maybe<Candidate_Industries_Bool_Exp>;
};


/** columns and relationships of "candidates" */
export type CandidatesCandidate_Industries_AggregateArgs = {
  distinct_on?: Maybe<Array<Candidate_Industries_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Candidate_Industries_Order_By>>;
  where?: Maybe<Candidate_Industries_Bool_Exp>;
};


/** columns and relationships of "candidates" */
export type CandidatesCandidate_LocationsArgs = {
  distinct_on?: Maybe<Array<Candidate_Locations_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Candidate_Locations_Order_By>>;
  where?: Maybe<Candidate_Locations_Bool_Exp>;
};


/** columns and relationships of "candidates" */
export type CandidatesCandidate_Locations_AggregateArgs = {
  distinct_on?: Maybe<Array<Candidate_Locations_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Candidate_Locations_Order_By>>;
  where?: Maybe<Candidate_Locations_Bool_Exp>;
};


/** columns and relationships of "candidates" */
export type CandidatesCandidate_MajorsArgs = {
  distinct_on?: Maybe<Array<Candidate_Majors_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Candidate_Majors_Order_By>>;
  where?: Maybe<Candidate_Majors_Bool_Exp>;
};


/** columns and relationships of "candidates" */
export type CandidatesCandidate_Majors_AggregateArgs = {
  distinct_on?: Maybe<Array<Candidate_Majors_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Candidate_Majors_Order_By>>;
  where?: Maybe<Candidate_Majors_Bool_Exp>;
};


/** columns and relationships of "candidates" */
export type CandidatesCandidate_PlacementsArgs = {
  distinct_on?: Maybe<Array<Candidate_Placements_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Candidate_Placements_Order_By>>;
  where?: Maybe<Candidate_Placements_Bool_Exp>;
};


/** columns and relationships of "candidates" */
export type CandidatesCandidate_Placements_AggregateArgs = {
  distinct_on?: Maybe<Array<Candidate_Placements_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Candidate_Placements_Order_By>>;
  where?: Maybe<Candidate_Placements_Bool_Exp>;
};


/** columns and relationships of "candidates" */
export type CandidatesCandidate_SkillsArgs = {
  distinct_on?: Maybe<Array<Candidate_Skills_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Candidate_Skills_Order_By>>;
  where?: Maybe<Candidate_Skills_Bool_Exp>;
};


/** columns and relationships of "candidates" */
export type CandidatesCandidate_Skills_AggregateArgs = {
  distinct_on?: Maybe<Array<Candidate_Skills_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Candidate_Skills_Order_By>>;
  where?: Maybe<Candidate_Skills_Bool_Exp>;
};


/** columns and relationships of "candidates" */
export type CandidatesEventsArgs = {
  distinct_on?: Maybe<Array<Events_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Events_Order_By>>;
  where?: Maybe<Events_Bool_Exp>;
};


/** columns and relationships of "candidates" */
export type CandidatesEvents_AggregateArgs = {
  distinct_on?: Maybe<Array<Events_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Events_Order_By>>;
  where?: Maybe<Events_Bool_Exp>;
};


/** columns and relationships of "candidates" */
export type CandidatesExclusive_CandidatesArgs = {
  distinct_on?: Maybe<Array<Exclusive_Candidates_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Exclusive_Candidates_Order_By>>;
  where?: Maybe<Exclusive_Candidates_Bool_Exp>;
};


/** columns and relationships of "candidates" */
export type CandidatesExclusive_Candidates_AggregateArgs = {
  distinct_on?: Maybe<Array<Exclusive_Candidates_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Exclusive_Candidates_Order_By>>;
  where?: Maybe<Exclusive_Candidates_Bool_Exp>;
};


/** columns and relationships of "candidates" */
export type CandidatesInterview_ParticipantsArgs = {
  distinct_on?: Maybe<Array<Interview_Participants_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Interview_Participants_Order_By>>;
  where?: Maybe<Interview_Participants_Bool_Exp>;
};


/** columns and relationships of "candidates" */
export type CandidatesInterview_Participants_AggregateArgs = {
  distinct_on?: Maybe<Array<Interview_Participants_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Interview_Participants_Order_By>>;
  where?: Maybe<Interview_Participants_Bool_Exp>;
};


/** columns and relationships of "candidates" */
export type CandidatesJob_CandidatesArgs = {
  distinct_on?: Maybe<Array<Job_Candidates_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Job_Candidates_Order_By>>;
  where?: Maybe<Job_Candidates_Bool_Exp>;
};


/** columns and relationships of "candidates" */
export type CandidatesJob_Candidates_AggregateArgs = {
  distinct_on?: Maybe<Array<Job_Candidates_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Job_Candidates_Order_By>>;
  where?: Maybe<Job_Candidates_Bool_Exp>;
};


/** columns and relationships of "candidates" */
export type CandidatesOffersArgs = {
  distinct_on?: Maybe<Array<Offers_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Offers_Order_By>>;
  where?: Maybe<Offers_Bool_Exp>;
};


/** columns and relationships of "candidates" */
export type CandidatesOffers_AggregateArgs = {
  distinct_on?: Maybe<Array<Offers_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Offers_Order_By>>;
  where?: Maybe<Offers_Bool_Exp>;
};


/** columns and relationships of "candidates" */
export type CandidatesShortlistsArgs = {
  distinct_on?: Maybe<Array<Shortlists_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Shortlists_Order_By>>;
  where?: Maybe<Shortlists_Bool_Exp>;
};


/** columns and relationships of "candidates" */
export type CandidatesShortlists_AggregateArgs = {
  distinct_on?: Maybe<Array<Shortlists_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Shortlists_Order_By>>;
  where?: Maybe<Shortlists_Bool_Exp>;
};


/** columns and relationships of "candidates" */
export type CandidatesTenant_CandidatesArgs = {
  distinct_on?: Maybe<Array<Tenant_Candidates_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Tenant_Candidates_Order_By>>;
  where?: Maybe<Tenant_Candidates_Bool_Exp>;
};


/** columns and relationships of "candidates" */
export type CandidatesTenant_Candidates_AggregateArgs = {
  distinct_on?: Maybe<Array<Tenant_Candidates_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Tenant_Candidates_Order_By>>;
  where?: Maybe<Tenant_Candidates_Bool_Exp>;
};

/** aggregated selection of "candidates" */
export type Candidates_Aggregate = {
  __typename?: 'candidates_aggregate';
  aggregate?: Maybe<Candidates_Aggregate_Fields>;
  nodes: Array<Candidates>;
};

export type Candidates_Aggregate_Bool_Exp = {
  bool_and?: Maybe<Candidates_Aggregate_Bool_Exp_Bool_And>;
  bool_or?: Maybe<Candidates_Aggregate_Bool_Exp_Bool_Or>;
  count?: Maybe<Candidates_Aggregate_Bool_Exp_Count>;
};

export type Candidates_Aggregate_Bool_Exp_Bool_And = {
  arguments: Candidates_Select_Column_Candidates_Aggregate_Bool_Exp_Bool_And_Arguments_Columns;
  distinct?: Maybe<Scalars['Boolean']>;
  filter?: Maybe<Candidates_Bool_Exp>;
  predicate: Boolean_Comparison_Exp;
};

export type Candidates_Aggregate_Bool_Exp_Bool_Or = {
  arguments: Candidates_Select_Column_Candidates_Aggregate_Bool_Exp_Bool_Or_Arguments_Columns;
  distinct?: Maybe<Scalars['Boolean']>;
  filter?: Maybe<Candidates_Bool_Exp>;
  predicate: Boolean_Comparison_Exp;
};

export type Candidates_Aggregate_Bool_Exp_Count = {
  arguments?: Maybe<Array<Candidates_Select_Column>>;
  distinct?: Maybe<Scalars['Boolean']>;
  filter?: Maybe<Candidates_Bool_Exp>;
  predicate: Int_Comparison_Exp;
};

/** aggregate fields of "candidates" */
export type Candidates_Aggregate_Fields = {
  __typename?: 'candidates_aggregate_fields';
  avg?: Maybe<Candidates_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<Candidates_Max_Fields>;
  min?: Maybe<Candidates_Min_Fields>;
  stddev?: Maybe<Candidates_Stddev_Fields>;
  stddev_pop?: Maybe<Candidates_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Candidates_Stddev_Samp_Fields>;
  sum?: Maybe<Candidates_Sum_Fields>;
  var_pop?: Maybe<Candidates_Var_Pop_Fields>;
  var_samp?: Maybe<Candidates_Var_Samp_Fields>;
  variance?: Maybe<Candidates_Variance_Fields>;
};


/** aggregate fields of "candidates" */
export type Candidates_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<Candidates_Select_Column>>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "candidates" */
export type Candidates_Aggregate_Order_By = {
  avg?: Maybe<Candidates_Avg_Order_By>;
  count?: Maybe<Order_By>;
  max?: Maybe<Candidates_Max_Order_By>;
  min?: Maybe<Candidates_Min_Order_By>;
  stddev?: Maybe<Candidates_Stddev_Order_By>;
  stddev_pop?: Maybe<Candidates_Stddev_Pop_Order_By>;
  stddev_samp?: Maybe<Candidates_Stddev_Samp_Order_By>;
  sum?: Maybe<Candidates_Sum_Order_By>;
  var_pop?: Maybe<Candidates_Var_Pop_Order_By>;
  var_samp?: Maybe<Candidates_Var_Samp_Order_By>;
  variance?: Maybe<Candidates_Variance_Order_By>;
};

/** input type for inserting array relation for remote table "candidates" */
export type Candidates_Arr_Rel_Insert_Input = {
  data: Array<Candidates_Insert_Input>;
  /** upsert condition */
  on_conflict?: Maybe<Candidates_On_Conflict>;
};

/** aggregate avg on columns */
export type Candidates_Avg_Fields = {
  __typename?: 'candidates_avg_fields';
  available_part_time_days_from?: Maybe<Scalars['Float']>;
  available_part_time_days_to?: Maybe<Scalars['Float']>;
  profile_progress?: Maybe<Scalars['Float']>;
};

/** order by avg() on columns of table "candidates" */
export type Candidates_Avg_Order_By = {
  available_part_time_days_from?: Maybe<Order_By>;
  available_part_time_days_to?: Maybe<Order_By>;
  profile_progress?: Maybe<Order_By>;
};

/** Boolean expression to filter rows from the table "candidates". All fields are combined with a logical 'AND'. */
export type Candidates_Bool_Exp = {
  _and?: Maybe<Array<Candidates_Bool_Exp>>;
  _not?: Maybe<Candidates_Bool_Exp>;
  _or?: Maybe<Array<Candidates_Bool_Exp>>;
  available_part_time_days_from?: Maybe<Int_Comparison_Exp>;
  available_part_time_days_to?: Maybe<Int_Comparison_Exp>;
  bio?: Maybe<String_Comparison_Exp>;
  candidate_courses?: Maybe<Candidate_Courses_Bool_Exp>;
  candidate_courses_aggregate?: Maybe<Candidate_Courses_Aggregate_Bool_Exp>;
  candidate_degrees?: Maybe<Candidate_Degrees_Bool_Exp>;
  candidate_degrees_aggregate?: Maybe<Candidate_Degrees_Aggregate_Bool_Exp>;
  candidate_follows?: Maybe<Candidate_Follows_Bool_Exp>;
  candidate_follows_aggregate?: Maybe<Candidate_Follows_Aggregate_Bool_Exp>;
  candidate_industries?: Maybe<Candidate_Industries_Bool_Exp>;
  candidate_industries_aggregate?: Maybe<Candidate_Industries_Aggregate_Bool_Exp>;
  candidate_locations?: Maybe<Candidate_Locations_Bool_Exp>;
  candidate_locations_aggregate?: Maybe<Candidate_Locations_Aggregate_Bool_Exp>;
  candidate_majors?: Maybe<Candidate_Majors_Bool_Exp>;
  candidate_majors_aggregate?: Maybe<Candidate_Majors_Aggregate_Bool_Exp>;
  candidate_placements?: Maybe<Candidate_Placements_Bool_Exp>;
  candidate_placements_aggregate?: Maybe<Candidate_Placements_Aggregate_Bool_Exp>;
  candidate_skills?: Maybe<Candidate_Skills_Bool_Exp>;
  candidate_skills_aggregate?: Maybe<Candidate_Skills_Aggregate_Bool_Exp>;
  created_at?: Maybe<Timestamptz_Comparison_Exp>;
  cv_url?: Maybe<String_Comparison_Exp>;
  deleted_at?: Maybe<Timestamptz_Comparison_Exp>;
  education_level?: Maybe<Highest_Level_Edu_Enums_Comparison_Exp>;
  email?: Maybe<String_Comparison_Exp>;
  events?: Maybe<Events_Bool_Exp>;
  events_aggregate?: Maybe<Events_Aggregate_Bool_Exp>;
  exclusive_candidates?: Maybe<Exclusive_Candidates_Bool_Exp>;
  exclusive_candidates_aggregate?: Maybe<Exclusive_Candidates_Aggregate_Bool_Exp>;
  first_name?: Maybe<String_Comparison_Exp>;
  graduates_on?: Maybe<Timestamptz_Comparison_Exp>;
  has_work_experience?: Maybe<Boolean_Comparison_Exp>;
  id?: Maybe<Uuid_Comparison_Exp>;
  interview_participants?: Maybe<Interview_Participants_Bool_Exp>;
  interview_participants_aggregate?: Maybe<Interview_Participants_Aggregate_Bool_Exp>;
  job_candidates?: Maybe<Job_Candidates_Bool_Exp>;
  job_candidates_aggregate?: Maybe<Job_Candidates_Aggregate_Bool_Exp>;
  last_name?: Maybe<String_Comparison_Exp>;
  notes?: Maybe<String_Comparison_Exp>;
  offers?: Maybe<Offers_Bool_Exp>;
  offers_aggregate?: Maybe<Offers_Aggregate_Bool_Exp>;
  open_to_part_time_prior_to_graduation?: Maybe<Boolean_Comparison_Exp>;
  owner?: Maybe<Users_Bool_Exp>;
  owner_id?: Maybe<String_Comparison_Exp>;
  phone?: Maybe<String_Comparison_Exp>;
  photo_url?: Maybe<String_Comparison_Exp>;
  profile_progress?: Maybe<Int_Comparison_Exp>;
  psych_url?: Maybe<String_Comparison_Exp>;
  published?: Maybe<Boolean_Comparison_Exp>;
  published_on?: Maybe<Timestamptz_Comparison_Exp>;
  relocate?: Maybe<Boolean_Comparison_Exp>;
  residency_status?: Maybe<Residency_Status_Enums_Comparison_Exp>;
  shortlists?: Maybe<Shortlists_Bool_Exp>;
  shortlists_aggregate?: Maybe<Shortlists_Aggregate_Bool_Exp>;
  source?: Maybe<String_Comparison_Exp>;
  source_tenant_id?: Maybe<Uuid_Comparison_Exp>;
  status?: Maybe<Candidate_Status_Enums_Comparison_Exp>;
  tenant?: Maybe<Tenants_Bool_Exp>;
  tenant_candidates?: Maybe<Tenant_Candidates_Bool_Exp>;
  tenant_candidates_aggregate?: Maybe<Tenant_Candidates_Aggregate_Bool_Exp>;
  test?: Maybe<Boolean_Comparison_Exp>;
  transcripts_url?: Maybe<String_Comparison_Exp>;
  university?: Maybe<Universities_Bool_Exp>;
  university_id?: Maybe<Uuid_Comparison_Exp>;
  updated_at?: Maybe<Timestamptz_Comparison_Exp>;
  video_url?: Maybe<String_Comparison_Exp>;
  work_arrangement?: Maybe<Work_Arrangement_Enums_Comparison_Exp>;
};

/** unique or primary key constraints on table "candidates" */
export enum Candidates_Constraint {
  /** unique or primary key constraint on columns "id" */
  CandidatesPkey = 'candidates_pkey'
}

/** input type for incrementing numeric columns in table "candidates" */
export type Candidates_Inc_Input = {
  available_part_time_days_from?: Maybe<Scalars['Int']>;
  available_part_time_days_to?: Maybe<Scalars['Int']>;
  profile_progress?: Maybe<Scalars['Int']>;
};

/** input type for inserting data into table "candidates" */
export type Candidates_Insert_Input = {
  available_part_time_days_from?: Maybe<Scalars['Int']>;
  available_part_time_days_to?: Maybe<Scalars['Int']>;
  bio?: Maybe<Scalars['String']>;
  candidate_courses?: Maybe<Candidate_Courses_Arr_Rel_Insert_Input>;
  candidate_degrees?: Maybe<Candidate_Degrees_Arr_Rel_Insert_Input>;
  candidate_follows?: Maybe<Candidate_Follows_Arr_Rel_Insert_Input>;
  candidate_industries?: Maybe<Candidate_Industries_Arr_Rel_Insert_Input>;
  candidate_locations?: Maybe<Candidate_Locations_Arr_Rel_Insert_Input>;
  candidate_majors?: Maybe<Candidate_Majors_Arr_Rel_Insert_Input>;
  candidate_placements?: Maybe<Candidate_Placements_Arr_Rel_Insert_Input>;
  candidate_skills?: Maybe<Candidate_Skills_Arr_Rel_Insert_Input>;
  created_at?: Maybe<Scalars['timestamptz']>;
  cv_url?: Maybe<Scalars['String']>;
  deleted_at?: Maybe<Scalars['timestamptz']>;
  education_level?: Maybe<Scalars['highest_level_edu_enums']>;
  email?: Maybe<Scalars['String']>;
  events?: Maybe<Events_Arr_Rel_Insert_Input>;
  exclusive_candidates?: Maybe<Exclusive_Candidates_Arr_Rel_Insert_Input>;
  first_name?: Maybe<Scalars['String']>;
  graduates_on?: Maybe<Scalars['timestamptz']>;
  has_work_experience?: Maybe<Scalars['Boolean']>;
  id?: Maybe<Scalars['uuid']>;
  interview_participants?: Maybe<Interview_Participants_Arr_Rel_Insert_Input>;
  job_candidates?: Maybe<Job_Candidates_Arr_Rel_Insert_Input>;
  last_name?: Maybe<Scalars['String']>;
  notes?: Maybe<Scalars['String']>;
  offers?: Maybe<Offers_Arr_Rel_Insert_Input>;
  open_to_part_time_prior_to_graduation?: Maybe<Scalars['Boolean']>;
  owner?: Maybe<Users_Obj_Rel_Insert_Input>;
  owner_id?: Maybe<Scalars['String']>;
  phone?: Maybe<Scalars['String']>;
  photo_url?: Maybe<Scalars['String']>;
  profile_progress?: Maybe<Scalars['Int']>;
  psych_url?: Maybe<Scalars['String']>;
  published?: Maybe<Scalars['Boolean']>;
  published_on?: Maybe<Scalars['timestamptz']>;
  relocate?: Maybe<Scalars['Boolean']>;
  residency_status?: Maybe<Scalars['residency_status_enums']>;
  shortlists?: Maybe<Shortlists_Arr_Rel_Insert_Input>;
  source?: Maybe<Scalars['String']>;
  source_tenant_id?: Maybe<Scalars['uuid']>;
  status?: Maybe<Scalars['candidate_status_enums']>;
  tenant?: Maybe<Tenants_Obj_Rel_Insert_Input>;
  tenant_candidates?: Maybe<Tenant_Candidates_Arr_Rel_Insert_Input>;
  test?: Maybe<Scalars['Boolean']>;
  transcripts_url?: Maybe<Scalars['String']>;
  university?: Maybe<Universities_Obj_Rel_Insert_Input>;
  university_id?: Maybe<Scalars['uuid']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
  video_url?: Maybe<Scalars['String']>;
  work_arrangement?: Maybe<Scalars['work_arrangement_enums']>;
};

/** aggregate max on columns */
export type Candidates_Max_Fields = {
  __typename?: 'candidates_max_fields';
  available_part_time_days_from?: Maybe<Scalars['Int']>;
  available_part_time_days_to?: Maybe<Scalars['Int']>;
  bio?: Maybe<Scalars['String']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  cv_url?: Maybe<Scalars['String']>;
  deleted_at?: Maybe<Scalars['timestamptz']>;
  education_level?: Maybe<Scalars['highest_level_edu_enums']>;
  email?: Maybe<Scalars['String']>;
  first_name?: Maybe<Scalars['String']>;
  graduates_on?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['uuid']>;
  last_name?: Maybe<Scalars['String']>;
  notes?: Maybe<Scalars['String']>;
  owner_id?: Maybe<Scalars['String']>;
  phone?: Maybe<Scalars['String']>;
  photo_url?: Maybe<Scalars['String']>;
  profile_progress?: Maybe<Scalars['Int']>;
  psych_url?: Maybe<Scalars['String']>;
  published_on?: Maybe<Scalars['timestamptz']>;
  residency_status?: Maybe<Scalars['residency_status_enums']>;
  source?: Maybe<Scalars['String']>;
  source_tenant_id?: Maybe<Scalars['uuid']>;
  status?: Maybe<Scalars['candidate_status_enums']>;
  transcripts_url?: Maybe<Scalars['String']>;
  university_id?: Maybe<Scalars['uuid']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
  video_url?: Maybe<Scalars['String']>;
  work_arrangement?: Maybe<Scalars['work_arrangement_enums']>;
};

/** order by max() on columns of table "candidates" */
export type Candidates_Max_Order_By = {
  available_part_time_days_from?: Maybe<Order_By>;
  available_part_time_days_to?: Maybe<Order_By>;
  bio?: Maybe<Order_By>;
  created_at?: Maybe<Order_By>;
  cv_url?: Maybe<Order_By>;
  deleted_at?: Maybe<Order_By>;
  education_level?: Maybe<Order_By>;
  email?: Maybe<Order_By>;
  first_name?: Maybe<Order_By>;
  graduates_on?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  last_name?: Maybe<Order_By>;
  notes?: Maybe<Order_By>;
  owner_id?: Maybe<Order_By>;
  phone?: Maybe<Order_By>;
  photo_url?: Maybe<Order_By>;
  profile_progress?: Maybe<Order_By>;
  psych_url?: Maybe<Order_By>;
  published_on?: Maybe<Order_By>;
  residency_status?: Maybe<Order_By>;
  source?: Maybe<Order_By>;
  source_tenant_id?: Maybe<Order_By>;
  status?: Maybe<Order_By>;
  transcripts_url?: Maybe<Order_By>;
  university_id?: Maybe<Order_By>;
  updated_at?: Maybe<Order_By>;
  video_url?: Maybe<Order_By>;
  work_arrangement?: Maybe<Order_By>;
};

/** aggregate min on columns */
export type Candidates_Min_Fields = {
  __typename?: 'candidates_min_fields';
  available_part_time_days_from?: Maybe<Scalars['Int']>;
  available_part_time_days_to?: Maybe<Scalars['Int']>;
  bio?: Maybe<Scalars['String']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  cv_url?: Maybe<Scalars['String']>;
  deleted_at?: Maybe<Scalars['timestamptz']>;
  education_level?: Maybe<Scalars['highest_level_edu_enums']>;
  email?: Maybe<Scalars['String']>;
  first_name?: Maybe<Scalars['String']>;
  graduates_on?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['uuid']>;
  last_name?: Maybe<Scalars['String']>;
  notes?: Maybe<Scalars['String']>;
  owner_id?: Maybe<Scalars['String']>;
  phone?: Maybe<Scalars['String']>;
  photo_url?: Maybe<Scalars['String']>;
  profile_progress?: Maybe<Scalars['Int']>;
  psych_url?: Maybe<Scalars['String']>;
  published_on?: Maybe<Scalars['timestamptz']>;
  residency_status?: Maybe<Scalars['residency_status_enums']>;
  source?: Maybe<Scalars['String']>;
  source_tenant_id?: Maybe<Scalars['uuid']>;
  status?: Maybe<Scalars['candidate_status_enums']>;
  transcripts_url?: Maybe<Scalars['String']>;
  university_id?: Maybe<Scalars['uuid']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
  video_url?: Maybe<Scalars['String']>;
  work_arrangement?: Maybe<Scalars['work_arrangement_enums']>;
};

/** order by min() on columns of table "candidates" */
export type Candidates_Min_Order_By = {
  available_part_time_days_from?: Maybe<Order_By>;
  available_part_time_days_to?: Maybe<Order_By>;
  bio?: Maybe<Order_By>;
  created_at?: Maybe<Order_By>;
  cv_url?: Maybe<Order_By>;
  deleted_at?: Maybe<Order_By>;
  education_level?: Maybe<Order_By>;
  email?: Maybe<Order_By>;
  first_name?: Maybe<Order_By>;
  graduates_on?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  last_name?: Maybe<Order_By>;
  notes?: Maybe<Order_By>;
  owner_id?: Maybe<Order_By>;
  phone?: Maybe<Order_By>;
  photo_url?: Maybe<Order_By>;
  profile_progress?: Maybe<Order_By>;
  psych_url?: Maybe<Order_By>;
  published_on?: Maybe<Order_By>;
  residency_status?: Maybe<Order_By>;
  source?: Maybe<Order_By>;
  source_tenant_id?: Maybe<Order_By>;
  status?: Maybe<Order_By>;
  transcripts_url?: Maybe<Order_By>;
  university_id?: Maybe<Order_By>;
  updated_at?: Maybe<Order_By>;
  video_url?: Maybe<Order_By>;
  work_arrangement?: Maybe<Order_By>;
};

/** response of any mutation on the table "candidates" */
export type Candidates_Mutation_Response = {
  __typename?: 'candidates_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Candidates>;
};

/** input type for inserting object relation for remote table "candidates" */
export type Candidates_Obj_Rel_Insert_Input = {
  data: Candidates_Insert_Input;
  /** upsert condition */
  on_conflict?: Maybe<Candidates_On_Conflict>;
};

/** on_conflict condition type for table "candidates" */
export type Candidates_On_Conflict = {
  constraint: Candidates_Constraint;
  update_columns?: Array<Candidates_Update_Column>;
  where?: Maybe<Candidates_Bool_Exp>;
};

/** Ordering options when selecting data from "candidates". */
export type Candidates_Order_By = {
  available_part_time_days_from?: Maybe<Order_By>;
  available_part_time_days_to?: Maybe<Order_By>;
  bio?: Maybe<Order_By>;
  candidate_courses_aggregate?: Maybe<Candidate_Courses_Aggregate_Order_By>;
  candidate_degrees_aggregate?: Maybe<Candidate_Degrees_Aggregate_Order_By>;
  candidate_follows_aggregate?: Maybe<Candidate_Follows_Aggregate_Order_By>;
  candidate_industries_aggregate?: Maybe<Candidate_Industries_Aggregate_Order_By>;
  candidate_locations_aggregate?: Maybe<Candidate_Locations_Aggregate_Order_By>;
  candidate_majors_aggregate?: Maybe<Candidate_Majors_Aggregate_Order_By>;
  candidate_placements_aggregate?: Maybe<Candidate_Placements_Aggregate_Order_By>;
  candidate_skills_aggregate?: Maybe<Candidate_Skills_Aggregate_Order_By>;
  created_at?: Maybe<Order_By>;
  cv_url?: Maybe<Order_By>;
  deleted_at?: Maybe<Order_By>;
  education_level?: Maybe<Order_By>;
  email?: Maybe<Order_By>;
  events_aggregate?: Maybe<Events_Aggregate_Order_By>;
  exclusive_candidates_aggregate?: Maybe<Exclusive_Candidates_Aggregate_Order_By>;
  first_name?: Maybe<Order_By>;
  graduates_on?: Maybe<Order_By>;
  has_work_experience?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  interview_participants_aggregate?: Maybe<Interview_Participants_Aggregate_Order_By>;
  job_candidates_aggregate?: Maybe<Job_Candidates_Aggregate_Order_By>;
  last_name?: Maybe<Order_By>;
  notes?: Maybe<Order_By>;
  offers_aggregate?: Maybe<Offers_Aggregate_Order_By>;
  open_to_part_time_prior_to_graduation?: Maybe<Order_By>;
  owner?: Maybe<Users_Order_By>;
  owner_id?: Maybe<Order_By>;
  phone?: Maybe<Order_By>;
  photo_url?: Maybe<Order_By>;
  profile_progress?: Maybe<Order_By>;
  psych_url?: Maybe<Order_By>;
  published?: Maybe<Order_By>;
  published_on?: Maybe<Order_By>;
  relocate?: Maybe<Order_By>;
  residency_status?: Maybe<Order_By>;
  shortlists_aggregate?: Maybe<Shortlists_Aggregate_Order_By>;
  source?: Maybe<Order_By>;
  source_tenant_id?: Maybe<Order_By>;
  status?: Maybe<Order_By>;
  tenant?: Maybe<Tenants_Order_By>;
  tenant_candidates_aggregate?: Maybe<Tenant_Candidates_Aggregate_Order_By>;
  test?: Maybe<Order_By>;
  transcripts_url?: Maybe<Order_By>;
  university?: Maybe<Universities_Order_By>;
  university_id?: Maybe<Order_By>;
  updated_at?: Maybe<Order_By>;
  video_url?: Maybe<Order_By>;
  work_arrangement?: Maybe<Order_By>;
};

/** primary key columns input for table: candidates */
export type Candidates_Pk_Columns_Input = {
  id: Scalars['uuid'];
};

/** select columns of table "candidates" */
export enum Candidates_Select_Column {
  /** column name */
  AvailablePartTimeDaysFrom = 'available_part_time_days_from',
  /** column name */
  AvailablePartTimeDaysTo = 'available_part_time_days_to',
  /** column name */
  Bio = 'bio',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  CvUrl = 'cv_url',
  /** column name */
  DeletedAt = 'deleted_at',
  /** column name */
  EducationLevel = 'education_level',
  /** column name */
  Email = 'email',
  /** column name */
  FirstName = 'first_name',
  /** column name */
  GraduatesOn = 'graduates_on',
  /** column name */
  HasWorkExperience = 'has_work_experience',
  /** column name */
  Id = 'id',
  /** column name */
  LastName = 'last_name',
  /** column name */
  Notes = 'notes',
  /** column name */
  OpenToPartTimePriorToGraduation = 'open_to_part_time_prior_to_graduation',
  /** column name */
  OwnerId = 'owner_id',
  /** column name */
  Phone = 'phone',
  /** column name */
  PhotoUrl = 'photo_url',
  /** column name */
  ProfileProgress = 'profile_progress',
  /** column name */
  PsychUrl = 'psych_url',
  /** column name */
  Published = 'published',
  /** column name */
  PublishedOn = 'published_on',
  /** column name */
  Relocate = 'relocate',
  /** column name */
  ResidencyStatus = 'residency_status',
  /** column name */
  Source = 'source',
  /** column name */
  SourceTenantId = 'source_tenant_id',
  /** column name */
  Status = 'status',
  /** column name */
  Test = 'test',
  /** column name */
  TranscriptsUrl = 'transcripts_url',
  /** column name */
  UniversityId = 'university_id',
  /** column name */
  UpdatedAt = 'updated_at',
  /** column name */
  VideoUrl = 'video_url',
  /** column name */
  WorkArrangement = 'work_arrangement'
}

/** select "candidates_aggregate_bool_exp_bool_and_arguments_columns" columns of table "candidates" */
export enum Candidates_Select_Column_Candidates_Aggregate_Bool_Exp_Bool_And_Arguments_Columns {
  /** column name */
  HasWorkExperience = 'has_work_experience',
  /** column name */
  OpenToPartTimePriorToGraduation = 'open_to_part_time_prior_to_graduation',
  /** column name */
  Published = 'published',
  /** column name */
  Relocate = 'relocate',
  /** column name */
  Test = 'test'
}

/** select "candidates_aggregate_bool_exp_bool_or_arguments_columns" columns of table "candidates" */
export enum Candidates_Select_Column_Candidates_Aggregate_Bool_Exp_Bool_Or_Arguments_Columns {
  /** column name */
  HasWorkExperience = 'has_work_experience',
  /** column name */
  OpenToPartTimePriorToGraduation = 'open_to_part_time_prior_to_graduation',
  /** column name */
  Published = 'published',
  /** column name */
  Relocate = 'relocate',
  /** column name */
  Test = 'test'
}

/** input type for updating data in table "candidates" */
export type Candidates_Set_Input = {
  available_part_time_days_from?: Maybe<Scalars['Int']>;
  available_part_time_days_to?: Maybe<Scalars['Int']>;
  bio?: Maybe<Scalars['String']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  cv_url?: Maybe<Scalars['String']>;
  deleted_at?: Maybe<Scalars['timestamptz']>;
  education_level?: Maybe<Scalars['highest_level_edu_enums']>;
  email?: Maybe<Scalars['String']>;
  first_name?: Maybe<Scalars['String']>;
  graduates_on?: Maybe<Scalars['timestamptz']>;
  has_work_experience?: Maybe<Scalars['Boolean']>;
  id?: Maybe<Scalars['uuid']>;
  last_name?: Maybe<Scalars['String']>;
  notes?: Maybe<Scalars['String']>;
  open_to_part_time_prior_to_graduation?: Maybe<Scalars['Boolean']>;
  owner_id?: Maybe<Scalars['String']>;
  phone?: Maybe<Scalars['String']>;
  photo_url?: Maybe<Scalars['String']>;
  profile_progress?: Maybe<Scalars['Int']>;
  psych_url?: Maybe<Scalars['String']>;
  published?: Maybe<Scalars['Boolean']>;
  published_on?: Maybe<Scalars['timestamptz']>;
  relocate?: Maybe<Scalars['Boolean']>;
  residency_status?: Maybe<Scalars['residency_status_enums']>;
  source?: Maybe<Scalars['String']>;
  source_tenant_id?: Maybe<Scalars['uuid']>;
  status?: Maybe<Scalars['candidate_status_enums']>;
  test?: Maybe<Scalars['Boolean']>;
  transcripts_url?: Maybe<Scalars['String']>;
  university_id?: Maybe<Scalars['uuid']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
  video_url?: Maybe<Scalars['String']>;
  work_arrangement?: Maybe<Scalars['work_arrangement_enums']>;
};

/** aggregate stddev on columns */
export type Candidates_Stddev_Fields = {
  __typename?: 'candidates_stddev_fields';
  available_part_time_days_from?: Maybe<Scalars['Float']>;
  available_part_time_days_to?: Maybe<Scalars['Float']>;
  profile_progress?: Maybe<Scalars['Float']>;
};

/** order by stddev() on columns of table "candidates" */
export type Candidates_Stddev_Order_By = {
  available_part_time_days_from?: Maybe<Order_By>;
  available_part_time_days_to?: Maybe<Order_By>;
  profile_progress?: Maybe<Order_By>;
};

/** aggregate stddev_pop on columns */
export type Candidates_Stddev_Pop_Fields = {
  __typename?: 'candidates_stddev_pop_fields';
  available_part_time_days_from?: Maybe<Scalars['Float']>;
  available_part_time_days_to?: Maybe<Scalars['Float']>;
  profile_progress?: Maybe<Scalars['Float']>;
};

/** order by stddev_pop() on columns of table "candidates" */
export type Candidates_Stddev_Pop_Order_By = {
  available_part_time_days_from?: Maybe<Order_By>;
  available_part_time_days_to?: Maybe<Order_By>;
  profile_progress?: Maybe<Order_By>;
};

/** aggregate stddev_samp on columns */
export type Candidates_Stddev_Samp_Fields = {
  __typename?: 'candidates_stddev_samp_fields';
  available_part_time_days_from?: Maybe<Scalars['Float']>;
  available_part_time_days_to?: Maybe<Scalars['Float']>;
  profile_progress?: Maybe<Scalars['Float']>;
};

/** order by stddev_samp() on columns of table "candidates" */
export type Candidates_Stddev_Samp_Order_By = {
  available_part_time_days_from?: Maybe<Order_By>;
  available_part_time_days_to?: Maybe<Order_By>;
  profile_progress?: Maybe<Order_By>;
};

/** Streaming cursor of the table "candidates" */
export type Candidates_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Candidates_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: Maybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Candidates_Stream_Cursor_Value_Input = {
  available_part_time_days_from?: Maybe<Scalars['Int']>;
  available_part_time_days_to?: Maybe<Scalars['Int']>;
  bio?: Maybe<Scalars['String']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  cv_url?: Maybe<Scalars['String']>;
  deleted_at?: Maybe<Scalars['timestamptz']>;
  education_level?: Maybe<Scalars['highest_level_edu_enums']>;
  email?: Maybe<Scalars['String']>;
  first_name?: Maybe<Scalars['String']>;
  graduates_on?: Maybe<Scalars['timestamptz']>;
  has_work_experience?: Maybe<Scalars['Boolean']>;
  id?: Maybe<Scalars['uuid']>;
  last_name?: Maybe<Scalars['String']>;
  notes?: Maybe<Scalars['String']>;
  open_to_part_time_prior_to_graduation?: Maybe<Scalars['Boolean']>;
  owner_id?: Maybe<Scalars['String']>;
  phone?: Maybe<Scalars['String']>;
  photo_url?: Maybe<Scalars['String']>;
  profile_progress?: Maybe<Scalars['Int']>;
  psych_url?: Maybe<Scalars['String']>;
  published?: Maybe<Scalars['Boolean']>;
  published_on?: Maybe<Scalars['timestamptz']>;
  relocate?: Maybe<Scalars['Boolean']>;
  residency_status?: Maybe<Scalars['residency_status_enums']>;
  source?: Maybe<Scalars['String']>;
  source_tenant_id?: Maybe<Scalars['uuid']>;
  status?: Maybe<Scalars['candidate_status_enums']>;
  test?: Maybe<Scalars['Boolean']>;
  transcripts_url?: Maybe<Scalars['String']>;
  university_id?: Maybe<Scalars['uuid']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
  video_url?: Maybe<Scalars['String']>;
  work_arrangement?: Maybe<Scalars['work_arrangement_enums']>;
};

/** aggregate sum on columns */
export type Candidates_Sum_Fields = {
  __typename?: 'candidates_sum_fields';
  available_part_time_days_from?: Maybe<Scalars['Int']>;
  available_part_time_days_to?: Maybe<Scalars['Int']>;
  profile_progress?: Maybe<Scalars['Int']>;
};

/** order by sum() on columns of table "candidates" */
export type Candidates_Sum_Order_By = {
  available_part_time_days_from?: Maybe<Order_By>;
  available_part_time_days_to?: Maybe<Order_By>;
  profile_progress?: Maybe<Order_By>;
};

/** update columns of table "candidates" */
export enum Candidates_Update_Column {
  /** column name */
  AvailablePartTimeDaysFrom = 'available_part_time_days_from',
  /** column name */
  AvailablePartTimeDaysTo = 'available_part_time_days_to',
  /** column name */
  Bio = 'bio',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  CvUrl = 'cv_url',
  /** column name */
  DeletedAt = 'deleted_at',
  /** column name */
  EducationLevel = 'education_level',
  /** column name */
  Email = 'email',
  /** column name */
  FirstName = 'first_name',
  /** column name */
  GraduatesOn = 'graduates_on',
  /** column name */
  HasWorkExperience = 'has_work_experience',
  /** column name */
  Id = 'id',
  /** column name */
  LastName = 'last_name',
  /** column name */
  Notes = 'notes',
  /** column name */
  OpenToPartTimePriorToGraduation = 'open_to_part_time_prior_to_graduation',
  /** column name */
  OwnerId = 'owner_id',
  /** column name */
  Phone = 'phone',
  /** column name */
  PhotoUrl = 'photo_url',
  /** column name */
  ProfileProgress = 'profile_progress',
  /** column name */
  PsychUrl = 'psych_url',
  /** column name */
  Published = 'published',
  /** column name */
  PublishedOn = 'published_on',
  /** column name */
  Relocate = 'relocate',
  /** column name */
  ResidencyStatus = 'residency_status',
  /** column name */
  Source = 'source',
  /** column name */
  SourceTenantId = 'source_tenant_id',
  /** column name */
  Status = 'status',
  /** column name */
  Test = 'test',
  /** column name */
  TranscriptsUrl = 'transcripts_url',
  /** column name */
  UniversityId = 'university_id',
  /** column name */
  UpdatedAt = 'updated_at',
  /** column name */
  VideoUrl = 'video_url',
  /** column name */
  WorkArrangement = 'work_arrangement'
}

export type Candidates_Updates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: Maybe<Candidates_Inc_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: Maybe<Candidates_Set_Input>;
  /** filter the rows which have to be updated */
  where: Candidates_Bool_Exp;
};

/** aggregate var_pop on columns */
export type Candidates_Var_Pop_Fields = {
  __typename?: 'candidates_var_pop_fields';
  available_part_time_days_from?: Maybe<Scalars['Float']>;
  available_part_time_days_to?: Maybe<Scalars['Float']>;
  profile_progress?: Maybe<Scalars['Float']>;
};

/** order by var_pop() on columns of table "candidates" */
export type Candidates_Var_Pop_Order_By = {
  available_part_time_days_from?: Maybe<Order_By>;
  available_part_time_days_to?: Maybe<Order_By>;
  profile_progress?: Maybe<Order_By>;
};

/** aggregate var_samp on columns */
export type Candidates_Var_Samp_Fields = {
  __typename?: 'candidates_var_samp_fields';
  available_part_time_days_from?: Maybe<Scalars['Float']>;
  available_part_time_days_to?: Maybe<Scalars['Float']>;
  profile_progress?: Maybe<Scalars['Float']>;
};

/** order by var_samp() on columns of table "candidates" */
export type Candidates_Var_Samp_Order_By = {
  available_part_time_days_from?: Maybe<Order_By>;
  available_part_time_days_to?: Maybe<Order_By>;
  profile_progress?: Maybe<Order_By>;
};

/** aggregate variance on columns */
export type Candidates_Variance_Fields = {
  __typename?: 'candidates_variance_fields';
  available_part_time_days_from?: Maybe<Scalars['Float']>;
  available_part_time_days_to?: Maybe<Scalars['Float']>;
  profile_progress?: Maybe<Scalars['Float']>;
};

/** order by variance() on columns of table "candidates" */
export type Candidates_Variance_Order_By = {
  available_part_time_days_from?: Maybe<Order_By>;
  available_part_time_days_to?: Maybe<Order_By>;
  profile_progress?: Maybe<Order_By>;
};

/** columns and relationships of "client_industries" */
export type Client_Industries = {
  __typename?: 'client_industries';
  /** An object relationship */
  client: Clients;
  client_id: Scalars['uuid'];
  created_at: Scalars['timestamptz'];
  deleted_at?: Maybe<Scalars['timestamptz']>;
  id: Scalars['uuid'];
  /** An object relationship */
  industry: Industries;
  industry_id: Scalars['uuid'];
  updated_at: Scalars['timestamptz'];
};

/** aggregated selection of "client_industries" */
export type Client_Industries_Aggregate = {
  __typename?: 'client_industries_aggregate';
  aggregate?: Maybe<Client_Industries_Aggregate_Fields>;
  nodes: Array<Client_Industries>;
};

export type Client_Industries_Aggregate_Bool_Exp = {
  count?: Maybe<Client_Industries_Aggregate_Bool_Exp_Count>;
};

export type Client_Industries_Aggregate_Bool_Exp_Count = {
  arguments?: Maybe<Array<Client_Industries_Select_Column>>;
  distinct?: Maybe<Scalars['Boolean']>;
  filter?: Maybe<Client_Industries_Bool_Exp>;
  predicate: Int_Comparison_Exp;
};

/** aggregate fields of "client_industries" */
export type Client_Industries_Aggregate_Fields = {
  __typename?: 'client_industries_aggregate_fields';
  count: Scalars['Int'];
  max?: Maybe<Client_Industries_Max_Fields>;
  min?: Maybe<Client_Industries_Min_Fields>;
};


/** aggregate fields of "client_industries" */
export type Client_Industries_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<Client_Industries_Select_Column>>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "client_industries" */
export type Client_Industries_Aggregate_Order_By = {
  count?: Maybe<Order_By>;
  max?: Maybe<Client_Industries_Max_Order_By>;
  min?: Maybe<Client_Industries_Min_Order_By>;
};

/** input type for inserting array relation for remote table "client_industries" */
export type Client_Industries_Arr_Rel_Insert_Input = {
  data: Array<Client_Industries_Insert_Input>;
  /** upsert condition */
  on_conflict?: Maybe<Client_Industries_On_Conflict>;
};

/** Boolean expression to filter rows from the table "client_industries". All fields are combined with a logical 'AND'. */
export type Client_Industries_Bool_Exp = {
  _and?: Maybe<Array<Client_Industries_Bool_Exp>>;
  _not?: Maybe<Client_Industries_Bool_Exp>;
  _or?: Maybe<Array<Client_Industries_Bool_Exp>>;
  client?: Maybe<Clients_Bool_Exp>;
  client_id?: Maybe<Uuid_Comparison_Exp>;
  created_at?: Maybe<Timestamptz_Comparison_Exp>;
  deleted_at?: Maybe<Timestamptz_Comparison_Exp>;
  id?: Maybe<Uuid_Comparison_Exp>;
  industry?: Maybe<Industries_Bool_Exp>;
  industry_id?: Maybe<Uuid_Comparison_Exp>;
  updated_at?: Maybe<Timestamptz_Comparison_Exp>;
};

/** unique or primary key constraints on table "client_industries" */
export enum Client_Industries_Constraint {
  /** unique or primary key constraint on columns "industry_id", "client_id" */
  ClientIndustriesClientIdIndustryIdKey = 'client_industries_client_id_industry_id_key',
  /** unique or primary key constraint on columns "id" */
  ClientIndustriesPkey = 'client_industries_pkey'
}

/** input type for inserting data into table "client_industries" */
export type Client_Industries_Insert_Input = {
  client?: Maybe<Clients_Obj_Rel_Insert_Input>;
  client_id?: Maybe<Scalars['uuid']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  deleted_at?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['uuid']>;
  industry?: Maybe<Industries_Obj_Rel_Insert_Input>;
  industry_id?: Maybe<Scalars['uuid']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** aggregate max on columns */
export type Client_Industries_Max_Fields = {
  __typename?: 'client_industries_max_fields';
  client_id?: Maybe<Scalars['uuid']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  deleted_at?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['uuid']>;
  industry_id?: Maybe<Scalars['uuid']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** order by max() on columns of table "client_industries" */
export type Client_Industries_Max_Order_By = {
  client_id?: Maybe<Order_By>;
  created_at?: Maybe<Order_By>;
  deleted_at?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  industry_id?: Maybe<Order_By>;
  updated_at?: Maybe<Order_By>;
};

/** aggregate min on columns */
export type Client_Industries_Min_Fields = {
  __typename?: 'client_industries_min_fields';
  client_id?: Maybe<Scalars['uuid']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  deleted_at?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['uuid']>;
  industry_id?: Maybe<Scalars['uuid']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** order by min() on columns of table "client_industries" */
export type Client_Industries_Min_Order_By = {
  client_id?: Maybe<Order_By>;
  created_at?: Maybe<Order_By>;
  deleted_at?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  industry_id?: Maybe<Order_By>;
  updated_at?: Maybe<Order_By>;
};

/** response of any mutation on the table "client_industries" */
export type Client_Industries_Mutation_Response = {
  __typename?: 'client_industries_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Client_Industries>;
};

/** on_conflict condition type for table "client_industries" */
export type Client_Industries_On_Conflict = {
  constraint: Client_Industries_Constraint;
  update_columns?: Array<Client_Industries_Update_Column>;
  where?: Maybe<Client_Industries_Bool_Exp>;
};

/** Ordering options when selecting data from "client_industries". */
export type Client_Industries_Order_By = {
  client?: Maybe<Clients_Order_By>;
  client_id?: Maybe<Order_By>;
  created_at?: Maybe<Order_By>;
  deleted_at?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  industry?: Maybe<Industries_Order_By>;
  industry_id?: Maybe<Order_By>;
  updated_at?: Maybe<Order_By>;
};

/** primary key columns input for table: client_industries */
export type Client_Industries_Pk_Columns_Input = {
  id: Scalars['uuid'];
};

/** select columns of table "client_industries" */
export enum Client_Industries_Select_Column {
  /** column name */
  ClientId = 'client_id',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  DeletedAt = 'deleted_at',
  /** column name */
  Id = 'id',
  /** column name */
  IndustryId = 'industry_id',
  /** column name */
  UpdatedAt = 'updated_at'
}

/** input type for updating data in table "client_industries" */
export type Client_Industries_Set_Input = {
  client_id?: Maybe<Scalars['uuid']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  deleted_at?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['uuid']>;
  industry_id?: Maybe<Scalars['uuid']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** Streaming cursor of the table "client_industries" */
export type Client_Industries_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Client_Industries_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: Maybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Client_Industries_Stream_Cursor_Value_Input = {
  client_id?: Maybe<Scalars['uuid']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  deleted_at?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['uuid']>;
  industry_id?: Maybe<Scalars['uuid']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** update columns of table "client_industries" */
export enum Client_Industries_Update_Column {
  /** column name */
  ClientId = 'client_id',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  DeletedAt = 'deleted_at',
  /** column name */
  Id = 'id',
  /** column name */
  IndustryId = 'industry_id',
  /** column name */
  UpdatedAt = 'updated_at'
}

export type Client_Industries_Updates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: Maybe<Client_Industries_Set_Input>;
  /** filter the rows which have to be updated */
  where: Client_Industries_Bool_Exp;
};

/** columns and relationships of "client_jobs" */
export type Client_Jobs = {
  __typename?: 'client_jobs';
  /** An object relationship */
  client: Clients;
  client_id: Scalars['uuid'];
  created_at: Scalars['timestamptz'];
  deleted_at?: Maybe<Scalars['timestamptz']>;
  id: Scalars['uuid'];
  /** An object relationship */
  job: Jobs;
  job_id: Scalars['uuid'];
  updated_at: Scalars['timestamptz'];
};

/** aggregated selection of "client_jobs" */
export type Client_Jobs_Aggregate = {
  __typename?: 'client_jobs_aggregate';
  aggregate?: Maybe<Client_Jobs_Aggregate_Fields>;
  nodes: Array<Client_Jobs>;
};

export type Client_Jobs_Aggregate_Bool_Exp = {
  count?: Maybe<Client_Jobs_Aggregate_Bool_Exp_Count>;
};

export type Client_Jobs_Aggregate_Bool_Exp_Count = {
  arguments?: Maybe<Array<Client_Jobs_Select_Column>>;
  distinct?: Maybe<Scalars['Boolean']>;
  filter?: Maybe<Client_Jobs_Bool_Exp>;
  predicate: Int_Comparison_Exp;
};

/** aggregate fields of "client_jobs" */
export type Client_Jobs_Aggregate_Fields = {
  __typename?: 'client_jobs_aggregate_fields';
  count: Scalars['Int'];
  max?: Maybe<Client_Jobs_Max_Fields>;
  min?: Maybe<Client_Jobs_Min_Fields>;
};


/** aggregate fields of "client_jobs" */
export type Client_Jobs_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<Client_Jobs_Select_Column>>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "client_jobs" */
export type Client_Jobs_Aggregate_Order_By = {
  count?: Maybe<Order_By>;
  max?: Maybe<Client_Jobs_Max_Order_By>;
  min?: Maybe<Client_Jobs_Min_Order_By>;
};

/** input type for inserting array relation for remote table "client_jobs" */
export type Client_Jobs_Arr_Rel_Insert_Input = {
  data: Array<Client_Jobs_Insert_Input>;
  /** upsert condition */
  on_conflict?: Maybe<Client_Jobs_On_Conflict>;
};

/** Boolean expression to filter rows from the table "client_jobs". All fields are combined with a logical 'AND'. */
export type Client_Jobs_Bool_Exp = {
  _and?: Maybe<Array<Client_Jobs_Bool_Exp>>;
  _not?: Maybe<Client_Jobs_Bool_Exp>;
  _or?: Maybe<Array<Client_Jobs_Bool_Exp>>;
  client?: Maybe<Clients_Bool_Exp>;
  client_id?: Maybe<Uuid_Comparison_Exp>;
  created_at?: Maybe<Timestamptz_Comparison_Exp>;
  deleted_at?: Maybe<Timestamptz_Comparison_Exp>;
  id?: Maybe<Uuid_Comparison_Exp>;
  job?: Maybe<Jobs_Bool_Exp>;
  job_id?: Maybe<Uuid_Comparison_Exp>;
  updated_at?: Maybe<Timestamptz_Comparison_Exp>;
};

/** unique or primary key constraints on table "client_jobs" */
export enum Client_Jobs_Constraint {
  /** unique or primary key constraint on columns "id" */
  ClientJobsPkey = 'client_jobs_pkey'
}

/** input type for inserting data into table "client_jobs" */
export type Client_Jobs_Insert_Input = {
  client?: Maybe<Clients_Obj_Rel_Insert_Input>;
  client_id?: Maybe<Scalars['uuid']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  deleted_at?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['uuid']>;
  job?: Maybe<Jobs_Obj_Rel_Insert_Input>;
  job_id?: Maybe<Scalars['uuid']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** aggregate max on columns */
export type Client_Jobs_Max_Fields = {
  __typename?: 'client_jobs_max_fields';
  client_id?: Maybe<Scalars['uuid']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  deleted_at?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['uuid']>;
  job_id?: Maybe<Scalars['uuid']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** order by max() on columns of table "client_jobs" */
export type Client_Jobs_Max_Order_By = {
  client_id?: Maybe<Order_By>;
  created_at?: Maybe<Order_By>;
  deleted_at?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  job_id?: Maybe<Order_By>;
  updated_at?: Maybe<Order_By>;
};

/** aggregate min on columns */
export type Client_Jobs_Min_Fields = {
  __typename?: 'client_jobs_min_fields';
  client_id?: Maybe<Scalars['uuid']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  deleted_at?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['uuid']>;
  job_id?: Maybe<Scalars['uuid']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** order by min() on columns of table "client_jobs" */
export type Client_Jobs_Min_Order_By = {
  client_id?: Maybe<Order_By>;
  created_at?: Maybe<Order_By>;
  deleted_at?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  job_id?: Maybe<Order_By>;
  updated_at?: Maybe<Order_By>;
};

/** response of any mutation on the table "client_jobs" */
export type Client_Jobs_Mutation_Response = {
  __typename?: 'client_jobs_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Client_Jobs>;
};

/** on_conflict condition type for table "client_jobs" */
export type Client_Jobs_On_Conflict = {
  constraint: Client_Jobs_Constraint;
  update_columns?: Array<Client_Jobs_Update_Column>;
  where?: Maybe<Client_Jobs_Bool_Exp>;
};

/** Ordering options when selecting data from "client_jobs". */
export type Client_Jobs_Order_By = {
  client?: Maybe<Clients_Order_By>;
  client_id?: Maybe<Order_By>;
  created_at?: Maybe<Order_By>;
  deleted_at?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  job?: Maybe<Jobs_Order_By>;
  job_id?: Maybe<Order_By>;
  updated_at?: Maybe<Order_By>;
};

/** primary key columns input for table: client_jobs */
export type Client_Jobs_Pk_Columns_Input = {
  id: Scalars['uuid'];
};

/** select columns of table "client_jobs" */
export enum Client_Jobs_Select_Column {
  /** column name */
  ClientId = 'client_id',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  DeletedAt = 'deleted_at',
  /** column name */
  Id = 'id',
  /** column name */
  JobId = 'job_id',
  /** column name */
  UpdatedAt = 'updated_at'
}

/** input type for updating data in table "client_jobs" */
export type Client_Jobs_Set_Input = {
  client_id?: Maybe<Scalars['uuid']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  deleted_at?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['uuid']>;
  job_id?: Maybe<Scalars['uuid']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** Streaming cursor of the table "client_jobs" */
export type Client_Jobs_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Client_Jobs_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: Maybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Client_Jobs_Stream_Cursor_Value_Input = {
  client_id?: Maybe<Scalars['uuid']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  deleted_at?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['uuid']>;
  job_id?: Maybe<Scalars['uuid']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** update columns of table "client_jobs" */
export enum Client_Jobs_Update_Column {
  /** column name */
  ClientId = 'client_id',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  DeletedAt = 'deleted_at',
  /** column name */
  Id = 'id',
  /** column name */
  JobId = 'job_id',
  /** column name */
  UpdatedAt = 'updated_at'
}

export type Client_Jobs_Updates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: Maybe<Client_Jobs_Set_Input>;
  /** filter the rows which have to be updated */
  where: Client_Jobs_Bool_Exp;
};

/** columns and relationships of "client_locations" */
export type Client_Locations = {
  __typename?: 'client_locations';
  /** An object relationship */
  client: Clients;
  client_id: Scalars['uuid'];
  created_at: Scalars['timestamptz'];
  deleted_at?: Maybe<Scalars['timestamptz']>;
  id: Scalars['uuid'];
  locality: Scalars['String'];
  /** An object relationship */
  location?: Maybe<Locations>;
  location_description?: Maybe<Scalars['location_name_enums']>;
  postcode: Scalars['String'];
  state: Scalars['String'];
  updated_at: Scalars['timestamptz'];
};

/** aggregated selection of "client_locations" */
export type Client_Locations_Aggregate = {
  __typename?: 'client_locations_aggregate';
  aggregate?: Maybe<Client_Locations_Aggregate_Fields>;
  nodes: Array<Client_Locations>;
};

export type Client_Locations_Aggregate_Bool_Exp = {
  count?: Maybe<Client_Locations_Aggregate_Bool_Exp_Count>;
};

export type Client_Locations_Aggregate_Bool_Exp_Count = {
  arguments?: Maybe<Array<Client_Locations_Select_Column>>;
  distinct?: Maybe<Scalars['Boolean']>;
  filter?: Maybe<Client_Locations_Bool_Exp>;
  predicate: Int_Comparison_Exp;
};

/** aggregate fields of "client_locations" */
export type Client_Locations_Aggregate_Fields = {
  __typename?: 'client_locations_aggregate_fields';
  count: Scalars['Int'];
  max?: Maybe<Client_Locations_Max_Fields>;
  min?: Maybe<Client_Locations_Min_Fields>;
};


/** aggregate fields of "client_locations" */
export type Client_Locations_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<Client_Locations_Select_Column>>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "client_locations" */
export type Client_Locations_Aggregate_Order_By = {
  count?: Maybe<Order_By>;
  max?: Maybe<Client_Locations_Max_Order_By>;
  min?: Maybe<Client_Locations_Min_Order_By>;
};

/** input type for inserting array relation for remote table "client_locations" */
export type Client_Locations_Arr_Rel_Insert_Input = {
  data: Array<Client_Locations_Insert_Input>;
  /** upsert condition */
  on_conflict?: Maybe<Client_Locations_On_Conflict>;
};

/** Boolean expression to filter rows from the table "client_locations". All fields are combined with a logical 'AND'. */
export type Client_Locations_Bool_Exp = {
  _and?: Maybe<Array<Client_Locations_Bool_Exp>>;
  _not?: Maybe<Client_Locations_Bool_Exp>;
  _or?: Maybe<Array<Client_Locations_Bool_Exp>>;
  client?: Maybe<Clients_Bool_Exp>;
  client_id?: Maybe<Uuid_Comparison_Exp>;
  created_at?: Maybe<Timestamptz_Comparison_Exp>;
  deleted_at?: Maybe<Timestamptz_Comparison_Exp>;
  id?: Maybe<Uuid_Comparison_Exp>;
  locality?: Maybe<String_Comparison_Exp>;
  location?: Maybe<Locations_Bool_Exp>;
  location_description?: Maybe<Location_Name_Enums_Comparison_Exp>;
  postcode?: Maybe<String_Comparison_Exp>;
  state?: Maybe<String_Comparison_Exp>;
  updated_at?: Maybe<Timestamptz_Comparison_Exp>;
};

/** unique or primary key constraints on table "client_locations" */
export enum Client_Locations_Constraint {
  /** unique or primary key constraint on columns "id" */
  ClientLocationsPkey = 'client_locations_pkey'
}

/** input type for inserting data into table "client_locations" */
export type Client_Locations_Insert_Input = {
  client?: Maybe<Clients_Obj_Rel_Insert_Input>;
  client_id?: Maybe<Scalars['uuid']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  deleted_at?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['uuid']>;
  locality?: Maybe<Scalars['String']>;
  location?: Maybe<Locations_Obj_Rel_Insert_Input>;
  location_description?: Maybe<Scalars['location_name_enums']>;
  postcode?: Maybe<Scalars['String']>;
  state?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** aggregate max on columns */
export type Client_Locations_Max_Fields = {
  __typename?: 'client_locations_max_fields';
  client_id?: Maybe<Scalars['uuid']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  deleted_at?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['uuid']>;
  locality?: Maybe<Scalars['String']>;
  location_description?: Maybe<Scalars['location_name_enums']>;
  postcode?: Maybe<Scalars['String']>;
  state?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** order by max() on columns of table "client_locations" */
export type Client_Locations_Max_Order_By = {
  client_id?: Maybe<Order_By>;
  created_at?: Maybe<Order_By>;
  deleted_at?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  locality?: Maybe<Order_By>;
  location_description?: Maybe<Order_By>;
  postcode?: Maybe<Order_By>;
  state?: Maybe<Order_By>;
  updated_at?: Maybe<Order_By>;
};

/** aggregate min on columns */
export type Client_Locations_Min_Fields = {
  __typename?: 'client_locations_min_fields';
  client_id?: Maybe<Scalars['uuid']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  deleted_at?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['uuid']>;
  locality?: Maybe<Scalars['String']>;
  location_description?: Maybe<Scalars['location_name_enums']>;
  postcode?: Maybe<Scalars['String']>;
  state?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** order by min() on columns of table "client_locations" */
export type Client_Locations_Min_Order_By = {
  client_id?: Maybe<Order_By>;
  created_at?: Maybe<Order_By>;
  deleted_at?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  locality?: Maybe<Order_By>;
  location_description?: Maybe<Order_By>;
  postcode?: Maybe<Order_By>;
  state?: Maybe<Order_By>;
  updated_at?: Maybe<Order_By>;
};

/** response of any mutation on the table "client_locations" */
export type Client_Locations_Mutation_Response = {
  __typename?: 'client_locations_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Client_Locations>;
};

/** on_conflict condition type for table "client_locations" */
export type Client_Locations_On_Conflict = {
  constraint: Client_Locations_Constraint;
  update_columns?: Array<Client_Locations_Update_Column>;
  where?: Maybe<Client_Locations_Bool_Exp>;
};

/** Ordering options when selecting data from "client_locations". */
export type Client_Locations_Order_By = {
  client?: Maybe<Clients_Order_By>;
  client_id?: Maybe<Order_By>;
  created_at?: Maybe<Order_By>;
  deleted_at?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  locality?: Maybe<Order_By>;
  location?: Maybe<Locations_Order_By>;
  location_description?: Maybe<Order_By>;
  postcode?: Maybe<Order_By>;
  state?: Maybe<Order_By>;
  updated_at?: Maybe<Order_By>;
};

/** primary key columns input for table: client_locations */
export type Client_Locations_Pk_Columns_Input = {
  id: Scalars['uuid'];
};

/** select columns of table "client_locations" */
export enum Client_Locations_Select_Column {
  /** column name */
  ClientId = 'client_id',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  DeletedAt = 'deleted_at',
  /** column name */
  Id = 'id',
  /** column name */
  Locality = 'locality',
  /** column name */
  LocationDescription = 'location_description',
  /** column name */
  Postcode = 'postcode',
  /** column name */
  State = 'state',
  /** column name */
  UpdatedAt = 'updated_at'
}

/** input type for updating data in table "client_locations" */
export type Client_Locations_Set_Input = {
  client_id?: Maybe<Scalars['uuid']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  deleted_at?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['uuid']>;
  locality?: Maybe<Scalars['String']>;
  location_description?: Maybe<Scalars['location_name_enums']>;
  postcode?: Maybe<Scalars['String']>;
  state?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** Streaming cursor of the table "client_locations" */
export type Client_Locations_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Client_Locations_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: Maybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Client_Locations_Stream_Cursor_Value_Input = {
  client_id?: Maybe<Scalars['uuid']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  deleted_at?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['uuid']>;
  locality?: Maybe<Scalars['String']>;
  location_description?: Maybe<Scalars['location_name_enums']>;
  postcode?: Maybe<Scalars['String']>;
  state?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** update columns of table "client_locations" */
export enum Client_Locations_Update_Column {
  /** column name */
  ClientId = 'client_id',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  DeletedAt = 'deleted_at',
  /** column name */
  Id = 'id',
  /** column name */
  Locality = 'locality',
  /** column name */
  LocationDescription = 'location_description',
  /** column name */
  Postcode = 'postcode',
  /** column name */
  State = 'state',
  /** column name */
  UpdatedAt = 'updated_at'
}

export type Client_Locations_Updates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: Maybe<Client_Locations_Set_Input>;
  /** filter the rows which have to be updated */
  where: Client_Locations_Bool_Exp;
};

/** columns and relationships of "client_users" */
export type Client_Users = {
  __typename?: 'client_users';
  /** An object relationship */
  client: Clients;
  client_id: Scalars['uuid'];
  created_at: Scalars['timestamptz'];
  deleted_at?: Maybe<Scalars['timestamptz']>;
  id: Scalars['uuid'];
  updated_at: Scalars['timestamptz'];
  /** An object relationship */
  user: Users;
  user_id: Scalars['uuid'];
};

/** aggregated selection of "client_users" */
export type Client_Users_Aggregate = {
  __typename?: 'client_users_aggregate';
  aggregate?: Maybe<Client_Users_Aggregate_Fields>;
  nodes: Array<Client_Users>;
};

export type Client_Users_Aggregate_Bool_Exp = {
  count?: Maybe<Client_Users_Aggregate_Bool_Exp_Count>;
};

export type Client_Users_Aggregate_Bool_Exp_Count = {
  arguments?: Maybe<Array<Client_Users_Select_Column>>;
  distinct?: Maybe<Scalars['Boolean']>;
  filter?: Maybe<Client_Users_Bool_Exp>;
  predicate: Int_Comparison_Exp;
};

/** aggregate fields of "client_users" */
export type Client_Users_Aggregate_Fields = {
  __typename?: 'client_users_aggregate_fields';
  count: Scalars['Int'];
  max?: Maybe<Client_Users_Max_Fields>;
  min?: Maybe<Client_Users_Min_Fields>;
};


/** aggregate fields of "client_users" */
export type Client_Users_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<Client_Users_Select_Column>>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "client_users" */
export type Client_Users_Aggregate_Order_By = {
  count?: Maybe<Order_By>;
  max?: Maybe<Client_Users_Max_Order_By>;
  min?: Maybe<Client_Users_Min_Order_By>;
};

/** input type for inserting array relation for remote table "client_users" */
export type Client_Users_Arr_Rel_Insert_Input = {
  data: Array<Client_Users_Insert_Input>;
  /** upsert condition */
  on_conflict?: Maybe<Client_Users_On_Conflict>;
};

/** Boolean expression to filter rows from the table "client_users". All fields are combined with a logical 'AND'. */
export type Client_Users_Bool_Exp = {
  _and?: Maybe<Array<Client_Users_Bool_Exp>>;
  _not?: Maybe<Client_Users_Bool_Exp>;
  _or?: Maybe<Array<Client_Users_Bool_Exp>>;
  client?: Maybe<Clients_Bool_Exp>;
  client_id?: Maybe<Uuid_Comparison_Exp>;
  created_at?: Maybe<Timestamptz_Comparison_Exp>;
  deleted_at?: Maybe<Timestamptz_Comparison_Exp>;
  id?: Maybe<Uuid_Comparison_Exp>;
  updated_at?: Maybe<Timestamptz_Comparison_Exp>;
  user?: Maybe<Users_Bool_Exp>;
  user_id?: Maybe<Uuid_Comparison_Exp>;
};

/** unique or primary key constraints on table "client_users" */
export enum Client_Users_Constraint {
  /** unique or primary key constraint on columns "id" */
  ClientUsersPkey = 'client_users_pkey'
}

/** input type for inserting data into table "client_users" */
export type Client_Users_Insert_Input = {
  client?: Maybe<Clients_Obj_Rel_Insert_Input>;
  client_id?: Maybe<Scalars['uuid']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  deleted_at?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['uuid']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
  user?: Maybe<Users_Obj_Rel_Insert_Input>;
  user_id?: Maybe<Scalars['uuid']>;
};

/** aggregate max on columns */
export type Client_Users_Max_Fields = {
  __typename?: 'client_users_max_fields';
  client_id?: Maybe<Scalars['uuid']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  deleted_at?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['uuid']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
  user_id?: Maybe<Scalars['uuid']>;
};

/** order by max() on columns of table "client_users" */
export type Client_Users_Max_Order_By = {
  client_id?: Maybe<Order_By>;
  created_at?: Maybe<Order_By>;
  deleted_at?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  updated_at?: Maybe<Order_By>;
  user_id?: Maybe<Order_By>;
};

/** aggregate min on columns */
export type Client_Users_Min_Fields = {
  __typename?: 'client_users_min_fields';
  client_id?: Maybe<Scalars['uuid']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  deleted_at?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['uuid']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
  user_id?: Maybe<Scalars['uuid']>;
};

/** order by min() on columns of table "client_users" */
export type Client_Users_Min_Order_By = {
  client_id?: Maybe<Order_By>;
  created_at?: Maybe<Order_By>;
  deleted_at?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  updated_at?: Maybe<Order_By>;
  user_id?: Maybe<Order_By>;
};

/** response of any mutation on the table "client_users" */
export type Client_Users_Mutation_Response = {
  __typename?: 'client_users_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Client_Users>;
};

/** on_conflict condition type for table "client_users" */
export type Client_Users_On_Conflict = {
  constraint: Client_Users_Constraint;
  update_columns?: Array<Client_Users_Update_Column>;
  where?: Maybe<Client_Users_Bool_Exp>;
};

/** Ordering options when selecting data from "client_users". */
export type Client_Users_Order_By = {
  client?: Maybe<Clients_Order_By>;
  client_id?: Maybe<Order_By>;
  created_at?: Maybe<Order_By>;
  deleted_at?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  updated_at?: Maybe<Order_By>;
  user?: Maybe<Users_Order_By>;
  user_id?: Maybe<Order_By>;
};

/** primary key columns input for table: client_users */
export type Client_Users_Pk_Columns_Input = {
  id: Scalars['uuid'];
};

/** select columns of table "client_users" */
export enum Client_Users_Select_Column {
  /** column name */
  ClientId = 'client_id',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  DeletedAt = 'deleted_at',
  /** column name */
  Id = 'id',
  /** column name */
  UpdatedAt = 'updated_at',
  /** column name */
  UserId = 'user_id'
}

/** input type for updating data in table "client_users" */
export type Client_Users_Set_Input = {
  client_id?: Maybe<Scalars['uuid']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  deleted_at?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['uuid']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
  user_id?: Maybe<Scalars['uuid']>;
};

/** Streaming cursor of the table "client_users" */
export type Client_Users_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Client_Users_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: Maybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Client_Users_Stream_Cursor_Value_Input = {
  client_id?: Maybe<Scalars['uuid']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  deleted_at?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['uuid']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
  user_id?: Maybe<Scalars['uuid']>;
};

/** update columns of table "client_users" */
export enum Client_Users_Update_Column {
  /** column name */
  ClientId = 'client_id',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  DeletedAt = 'deleted_at',
  /** column name */
  Id = 'id',
  /** column name */
  UpdatedAt = 'updated_at',
  /** column name */
  UserId = 'user_id'
}

export type Client_Users_Updates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: Maybe<Client_Users_Set_Input>;
  /** filter the rows which have to be updated */
  where: Client_Users_Bool_Exp;
};

/** columns and relationships of "clients" */
export type Clients = {
  __typename?: 'clients';
  business_name: Scalars['String'];
  /** An array relationship */
  candidate_placements: Array<Candidate_Placements>;
  /** An aggregate relationship */
  candidate_placements_aggregate: Candidate_Placements_Aggregate;
  /** An array relationship */
  client_industries: Array<Client_Industries>;
  /** An aggregate relationship */
  client_industries_aggregate: Client_Industries_Aggregate;
  /** An array relationship */
  client_jobs: Array<Client_Jobs>;
  /** An aggregate relationship */
  client_jobs_aggregate: Client_Jobs_Aggregate;
  /** An array relationship */
  client_locations: Array<Client_Locations>;
  /** An aggregate relationship */
  client_locations_aggregate: Client_Locations_Aggregate;
  /** An array relationship */
  client_users: Array<Client_Users>;
  /** An aggregate relationship */
  client_users_aggregate: Client_Users_Aggregate;
  /** An array relationship */
  contacts: Array<Contacts>;
  /** An aggregate relationship */
  contacts_aggregate: Contacts_Aggregate;
  created_at: Scalars['timestamptz'];
  deleted_at?: Maybe<Scalars['timestamptz']>;
  domain?: Maybe<Scalars['String']>;
  /** An array relationship */
  enterprise_clients: Array<Enterprise_Clients>;
  /** An aggregate relationship */
  enterprise_clients_aggregate: Enterprise_Clients_Aggregate;
  /** An array relationship */
  events: Array<Events>;
  /** An aggregate relationship */
  events_aggregate: Events_Aggregate;
  id: Scalars['uuid'];
  /** An array relationship */
  interview_participants: Array<Interview_Participants>;
  /** An aggregate relationship */
  interview_participants_aggregate: Interview_Participants_Aggregate;
  notes?: Maybe<Scalars['String']>;
  /** An array relationship */
  offers: Array<Offers>;
  /** An aggregate relationship */
  offers_aggregate: Offers_Aggregate;
  /** An object relationship */
  owner?: Maybe<Users>;
  owner_id?: Maybe<Scalars['String']>;
  /** An array relationship */
  shortlists: Array<Shortlists>;
  /** An aggregate relationship */
  shortlists_aggregate: Shortlists_Aggregate;
  /** An object relationship */
  tenant: Tenants;
  tenant_id: Scalars['uuid'];
  terms_accepted_on?: Maybe<Scalars['timestamptz']>;
  test?: Maybe<Scalars['Boolean']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};


/** columns and relationships of "clients" */
export type ClientsCandidate_PlacementsArgs = {
  distinct_on?: Maybe<Array<Candidate_Placements_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Candidate_Placements_Order_By>>;
  where?: Maybe<Candidate_Placements_Bool_Exp>;
};


/** columns and relationships of "clients" */
export type ClientsCandidate_Placements_AggregateArgs = {
  distinct_on?: Maybe<Array<Candidate_Placements_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Candidate_Placements_Order_By>>;
  where?: Maybe<Candidate_Placements_Bool_Exp>;
};


/** columns and relationships of "clients" */
export type ClientsClient_IndustriesArgs = {
  distinct_on?: Maybe<Array<Client_Industries_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Client_Industries_Order_By>>;
  where?: Maybe<Client_Industries_Bool_Exp>;
};


/** columns and relationships of "clients" */
export type ClientsClient_Industries_AggregateArgs = {
  distinct_on?: Maybe<Array<Client_Industries_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Client_Industries_Order_By>>;
  where?: Maybe<Client_Industries_Bool_Exp>;
};


/** columns and relationships of "clients" */
export type ClientsClient_JobsArgs = {
  distinct_on?: Maybe<Array<Client_Jobs_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Client_Jobs_Order_By>>;
  where?: Maybe<Client_Jobs_Bool_Exp>;
};


/** columns and relationships of "clients" */
export type ClientsClient_Jobs_AggregateArgs = {
  distinct_on?: Maybe<Array<Client_Jobs_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Client_Jobs_Order_By>>;
  where?: Maybe<Client_Jobs_Bool_Exp>;
};


/** columns and relationships of "clients" */
export type ClientsClient_LocationsArgs = {
  distinct_on?: Maybe<Array<Client_Locations_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Client_Locations_Order_By>>;
  where?: Maybe<Client_Locations_Bool_Exp>;
};


/** columns and relationships of "clients" */
export type ClientsClient_Locations_AggregateArgs = {
  distinct_on?: Maybe<Array<Client_Locations_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Client_Locations_Order_By>>;
  where?: Maybe<Client_Locations_Bool_Exp>;
};


/** columns and relationships of "clients" */
export type ClientsClient_UsersArgs = {
  distinct_on?: Maybe<Array<Client_Users_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Client_Users_Order_By>>;
  where?: Maybe<Client_Users_Bool_Exp>;
};


/** columns and relationships of "clients" */
export type ClientsClient_Users_AggregateArgs = {
  distinct_on?: Maybe<Array<Client_Users_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Client_Users_Order_By>>;
  where?: Maybe<Client_Users_Bool_Exp>;
};


/** columns and relationships of "clients" */
export type ClientsContactsArgs = {
  distinct_on?: Maybe<Array<Contacts_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Contacts_Order_By>>;
  where?: Maybe<Contacts_Bool_Exp>;
};


/** columns and relationships of "clients" */
export type ClientsContacts_AggregateArgs = {
  distinct_on?: Maybe<Array<Contacts_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Contacts_Order_By>>;
  where?: Maybe<Contacts_Bool_Exp>;
};


/** columns and relationships of "clients" */
export type ClientsEnterprise_ClientsArgs = {
  distinct_on?: Maybe<Array<Enterprise_Clients_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Enterprise_Clients_Order_By>>;
  where?: Maybe<Enterprise_Clients_Bool_Exp>;
};


/** columns and relationships of "clients" */
export type ClientsEnterprise_Clients_AggregateArgs = {
  distinct_on?: Maybe<Array<Enterprise_Clients_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Enterprise_Clients_Order_By>>;
  where?: Maybe<Enterprise_Clients_Bool_Exp>;
};


/** columns and relationships of "clients" */
export type ClientsEventsArgs = {
  distinct_on?: Maybe<Array<Events_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Events_Order_By>>;
  where?: Maybe<Events_Bool_Exp>;
};


/** columns and relationships of "clients" */
export type ClientsEvents_AggregateArgs = {
  distinct_on?: Maybe<Array<Events_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Events_Order_By>>;
  where?: Maybe<Events_Bool_Exp>;
};


/** columns and relationships of "clients" */
export type ClientsInterview_ParticipantsArgs = {
  distinct_on?: Maybe<Array<Interview_Participants_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Interview_Participants_Order_By>>;
  where?: Maybe<Interview_Participants_Bool_Exp>;
};


/** columns and relationships of "clients" */
export type ClientsInterview_Participants_AggregateArgs = {
  distinct_on?: Maybe<Array<Interview_Participants_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Interview_Participants_Order_By>>;
  where?: Maybe<Interview_Participants_Bool_Exp>;
};


/** columns and relationships of "clients" */
export type ClientsOffersArgs = {
  distinct_on?: Maybe<Array<Offers_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Offers_Order_By>>;
  where?: Maybe<Offers_Bool_Exp>;
};


/** columns and relationships of "clients" */
export type ClientsOffers_AggregateArgs = {
  distinct_on?: Maybe<Array<Offers_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Offers_Order_By>>;
  where?: Maybe<Offers_Bool_Exp>;
};


/** columns and relationships of "clients" */
export type ClientsShortlistsArgs = {
  distinct_on?: Maybe<Array<Shortlists_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Shortlists_Order_By>>;
  where?: Maybe<Shortlists_Bool_Exp>;
};


/** columns and relationships of "clients" */
export type ClientsShortlists_AggregateArgs = {
  distinct_on?: Maybe<Array<Shortlists_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Shortlists_Order_By>>;
  where?: Maybe<Shortlists_Bool_Exp>;
};

/** aggregated selection of "clients" */
export type Clients_Aggregate = {
  __typename?: 'clients_aggregate';
  aggregate?: Maybe<Clients_Aggregate_Fields>;
  nodes: Array<Clients>;
};

export type Clients_Aggregate_Bool_Exp = {
  bool_and?: Maybe<Clients_Aggregate_Bool_Exp_Bool_And>;
  bool_or?: Maybe<Clients_Aggregate_Bool_Exp_Bool_Or>;
  count?: Maybe<Clients_Aggregate_Bool_Exp_Count>;
};

export type Clients_Aggregate_Bool_Exp_Bool_And = {
  arguments: Clients_Select_Column_Clients_Aggregate_Bool_Exp_Bool_And_Arguments_Columns;
  distinct?: Maybe<Scalars['Boolean']>;
  filter?: Maybe<Clients_Bool_Exp>;
  predicate: Boolean_Comparison_Exp;
};

export type Clients_Aggregate_Bool_Exp_Bool_Or = {
  arguments: Clients_Select_Column_Clients_Aggregate_Bool_Exp_Bool_Or_Arguments_Columns;
  distinct?: Maybe<Scalars['Boolean']>;
  filter?: Maybe<Clients_Bool_Exp>;
  predicate: Boolean_Comparison_Exp;
};

export type Clients_Aggregate_Bool_Exp_Count = {
  arguments?: Maybe<Array<Clients_Select_Column>>;
  distinct?: Maybe<Scalars['Boolean']>;
  filter?: Maybe<Clients_Bool_Exp>;
  predicate: Int_Comparison_Exp;
};

/** aggregate fields of "clients" */
export type Clients_Aggregate_Fields = {
  __typename?: 'clients_aggregate_fields';
  count: Scalars['Int'];
  max?: Maybe<Clients_Max_Fields>;
  min?: Maybe<Clients_Min_Fields>;
};


/** aggregate fields of "clients" */
export type Clients_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<Clients_Select_Column>>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "clients" */
export type Clients_Aggregate_Order_By = {
  count?: Maybe<Order_By>;
  max?: Maybe<Clients_Max_Order_By>;
  min?: Maybe<Clients_Min_Order_By>;
};

/** input type for inserting array relation for remote table "clients" */
export type Clients_Arr_Rel_Insert_Input = {
  data: Array<Clients_Insert_Input>;
  /** upsert condition */
  on_conflict?: Maybe<Clients_On_Conflict>;
};

/** Boolean expression to filter rows from the table "clients". All fields are combined with a logical 'AND'. */
export type Clients_Bool_Exp = {
  _and?: Maybe<Array<Clients_Bool_Exp>>;
  _not?: Maybe<Clients_Bool_Exp>;
  _or?: Maybe<Array<Clients_Bool_Exp>>;
  business_name?: Maybe<String_Comparison_Exp>;
  candidate_placements?: Maybe<Candidate_Placements_Bool_Exp>;
  candidate_placements_aggregate?: Maybe<Candidate_Placements_Aggregate_Bool_Exp>;
  client_industries?: Maybe<Client_Industries_Bool_Exp>;
  client_industries_aggregate?: Maybe<Client_Industries_Aggregate_Bool_Exp>;
  client_jobs?: Maybe<Client_Jobs_Bool_Exp>;
  client_jobs_aggregate?: Maybe<Client_Jobs_Aggregate_Bool_Exp>;
  client_locations?: Maybe<Client_Locations_Bool_Exp>;
  client_locations_aggregate?: Maybe<Client_Locations_Aggregate_Bool_Exp>;
  client_users?: Maybe<Client_Users_Bool_Exp>;
  client_users_aggregate?: Maybe<Client_Users_Aggregate_Bool_Exp>;
  contacts?: Maybe<Contacts_Bool_Exp>;
  contacts_aggregate?: Maybe<Contacts_Aggregate_Bool_Exp>;
  created_at?: Maybe<Timestamptz_Comparison_Exp>;
  deleted_at?: Maybe<Timestamptz_Comparison_Exp>;
  domain?: Maybe<String_Comparison_Exp>;
  enterprise_clients?: Maybe<Enterprise_Clients_Bool_Exp>;
  enterprise_clients_aggregate?: Maybe<Enterprise_Clients_Aggregate_Bool_Exp>;
  events?: Maybe<Events_Bool_Exp>;
  events_aggregate?: Maybe<Events_Aggregate_Bool_Exp>;
  id?: Maybe<Uuid_Comparison_Exp>;
  interview_participants?: Maybe<Interview_Participants_Bool_Exp>;
  interview_participants_aggregate?: Maybe<Interview_Participants_Aggregate_Bool_Exp>;
  notes?: Maybe<String_Comparison_Exp>;
  offers?: Maybe<Offers_Bool_Exp>;
  offers_aggregate?: Maybe<Offers_Aggregate_Bool_Exp>;
  owner?: Maybe<Users_Bool_Exp>;
  owner_id?: Maybe<String_Comparison_Exp>;
  shortlists?: Maybe<Shortlists_Bool_Exp>;
  shortlists_aggregate?: Maybe<Shortlists_Aggregate_Bool_Exp>;
  tenant?: Maybe<Tenants_Bool_Exp>;
  tenant_id?: Maybe<Uuid_Comparison_Exp>;
  terms_accepted_on?: Maybe<Timestamptz_Comparison_Exp>;
  test?: Maybe<Boolean_Comparison_Exp>;
  updated_at?: Maybe<Timestamptz_Comparison_Exp>;
};

/** unique or primary key constraints on table "clients" */
export enum Clients_Constraint {
  /** unique or primary key constraint on columns "id" */
  ClientsPkey = 'clients_pkey'
}

/** input type for inserting data into table "clients" */
export type Clients_Insert_Input = {
  business_name?: Maybe<Scalars['String']>;
  candidate_placements?: Maybe<Candidate_Placements_Arr_Rel_Insert_Input>;
  client_industries?: Maybe<Client_Industries_Arr_Rel_Insert_Input>;
  client_jobs?: Maybe<Client_Jobs_Arr_Rel_Insert_Input>;
  client_locations?: Maybe<Client_Locations_Arr_Rel_Insert_Input>;
  client_users?: Maybe<Client_Users_Arr_Rel_Insert_Input>;
  contacts?: Maybe<Contacts_Arr_Rel_Insert_Input>;
  created_at?: Maybe<Scalars['timestamptz']>;
  deleted_at?: Maybe<Scalars['timestamptz']>;
  domain?: Maybe<Scalars['String']>;
  enterprise_clients?: Maybe<Enterprise_Clients_Arr_Rel_Insert_Input>;
  events?: Maybe<Events_Arr_Rel_Insert_Input>;
  id?: Maybe<Scalars['uuid']>;
  interview_participants?: Maybe<Interview_Participants_Arr_Rel_Insert_Input>;
  notes?: Maybe<Scalars['String']>;
  offers?: Maybe<Offers_Arr_Rel_Insert_Input>;
  owner?: Maybe<Users_Obj_Rel_Insert_Input>;
  owner_id?: Maybe<Scalars['String']>;
  shortlists?: Maybe<Shortlists_Arr_Rel_Insert_Input>;
  tenant?: Maybe<Tenants_Obj_Rel_Insert_Input>;
  tenant_id?: Maybe<Scalars['uuid']>;
  terms_accepted_on?: Maybe<Scalars['timestamptz']>;
  test?: Maybe<Scalars['Boolean']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** aggregate max on columns */
export type Clients_Max_Fields = {
  __typename?: 'clients_max_fields';
  business_name?: Maybe<Scalars['String']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  deleted_at?: Maybe<Scalars['timestamptz']>;
  domain?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['uuid']>;
  notes?: Maybe<Scalars['String']>;
  owner_id?: Maybe<Scalars['String']>;
  tenant_id?: Maybe<Scalars['uuid']>;
  terms_accepted_on?: Maybe<Scalars['timestamptz']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** order by max() on columns of table "clients" */
export type Clients_Max_Order_By = {
  business_name?: Maybe<Order_By>;
  created_at?: Maybe<Order_By>;
  deleted_at?: Maybe<Order_By>;
  domain?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  notes?: Maybe<Order_By>;
  owner_id?: Maybe<Order_By>;
  tenant_id?: Maybe<Order_By>;
  terms_accepted_on?: Maybe<Order_By>;
  updated_at?: Maybe<Order_By>;
};

/** aggregate min on columns */
export type Clients_Min_Fields = {
  __typename?: 'clients_min_fields';
  business_name?: Maybe<Scalars['String']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  deleted_at?: Maybe<Scalars['timestamptz']>;
  domain?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['uuid']>;
  notes?: Maybe<Scalars['String']>;
  owner_id?: Maybe<Scalars['String']>;
  tenant_id?: Maybe<Scalars['uuid']>;
  terms_accepted_on?: Maybe<Scalars['timestamptz']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** order by min() on columns of table "clients" */
export type Clients_Min_Order_By = {
  business_name?: Maybe<Order_By>;
  created_at?: Maybe<Order_By>;
  deleted_at?: Maybe<Order_By>;
  domain?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  notes?: Maybe<Order_By>;
  owner_id?: Maybe<Order_By>;
  tenant_id?: Maybe<Order_By>;
  terms_accepted_on?: Maybe<Order_By>;
  updated_at?: Maybe<Order_By>;
};

/** response of any mutation on the table "clients" */
export type Clients_Mutation_Response = {
  __typename?: 'clients_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Clients>;
};

/** input type for inserting object relation for remote table "clients" */
export type Clients_Obj_Rel_Insert_Input = {
  data: Clients_Insert_Input;
  /** upsert condition */
  on_conflict?: Maybe<Clients_On_Conflict>;
};

/** on_conflict condition type for table "clients" */
export type Clients_On_Conflict = {
  constraint: Clients_Constraint;
  update_columns?: Array<Clients_Update_Column>;
  where?: Maybe<Clients_Bool_Exp>;
};

/** Ordering options when selecting data from "clients". */
export type Clients_Order_By = {
  business_name?: Maybe<Order_By>;
  candidate_placements_aggregate?: Maybe<Candidate_Placements_Aggregate_Order_By>;
  client_industries_aggregate?: Maybe<Client_Industries_Aggregate_Order_By>;
  client_jobs_aggregate?: Maybe<Client_Jobs_Aggregate_Order_By>;
  client_locations_aggregate?: Maybe<Client_Locations_Aggregate_Order_By>;
  client_users_aggregate?: Maybe<Client_Users_Aggregate_Order_By>;
  contacts_aggregate?: Maybe<Contacts_Aggregate_Order_By>;
  created_at?: Maybe<Order_By>;
  deleted_at?: Maybe<Order_By>;
  domain?: Maybe<Order_By>;
  enterprise_clients_aggregate?: Maybe<Enterprise_Clients_Aggregate_Order_By>;
  events_aggregate?: Maybe<Events_Aggregate_Order_By>;
  id?: Maybe<Order_By>;
  interview_participants_aggregate?: Maybe<Interview_Participants_Aggregate_Order_By>;
  notes?: Maybe<Order_By>;
  offers_aggregate?: Maybe<Offers_Aggregate_Order_By>;
  owner?: Maybe<Users_Order_By>;
  owner_id?: Maybe<Order_By>;
  shortlists_aggregate?: Maybe<Shortlists_Aggregate_Order_By>;
  tenant?: Maybe<Tenants_Order_By>;
  tenant_id?: Maybe<Order_By>;
  terms_accepted_on?: Maybe<Order_By>;
  test?: Maybe<Order_By>;
  updated_at?: Maybe<Order_By>;
};

/** primary key columns input for table: clients */
export type Clients_Pk_Columns_Input = {
  id: Scalars['uuid'];
};

/** select columns of table "clients" */
export enum Clients_Select_Column {
  /** column name */
  BusinessName = 'business_name',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  DeletedAt = 'deleted_at',
  /** column name */
  Domain = 'domain',
  /** column name */
  Id = 'id',
  /** column name */
  Notes = 'notes',
  /** column name */
  OwnerId = 'owner_id',
  /** column name */
  TenantId = 'tenant_id',
  /** column name */
  TermsAcceptedOn = 'terms_accepted_on',
  /** column name */
  Test = 'test',
  /** column name */
  UpdatedAt = 'updated_at'
}

/** select "clients_aggregate_bool_exp_bool_and_arguments_columns" columns of table "clients" */
export enum Clients_Select_Column_Clients_Aggregate_Bool_Exp_Bool_And_Arguments_Columns {
  /** column name */
  Test = 'test'
}

/** select "clients_aggregate_bool_exp_bool_or_arguments_columns" columns of table "clients" */
export enum Clients_Select_Column_Clients_Aggregate_Bool_Exp_Bool_Or_Arguments_Columns {
  /** column name */
  Test = 'test'
}

/** input type for updating data in table "clients" */
export type Clients_Set_Input = {
  business_name?: Maybe<Scalars['String']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  deleted_at?: Maybe<Scalars['timestamptz']>;
  domain?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['uuid']>;
  notes?: Maybe<Scalars['String']>;
  owner_id?: Maybe<Scalars['String']>;
  tenant_id?: Maybe<Scalars['uuid']>;
  terms_accepted_on?: Maybe<Scalars['timestamptz']>;
  test?: Maybe<Scalars['Boolean']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** Streaming cursor of the table "clients" */
export type Clients_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Clients_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: Maybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Clients_Stream_Cursor_Value_Input = {
  business_name?: Maybe<Scalars['String']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  deleted_at?: Maybe<Scalars['timestamptz']>;
  domain?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['uuid']>;
  notes?: Maybe<Scalars['String']>;
  owner_id?: Maybe<Scalars['String']>;
  tenant_id?: Maybe<Scalars['uuid']>;
  terms_accepted_on?: Maybe<Scalars['timestamptz']>;
  test?: Maybe<Scalars['Boolean']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** update columns of table "clients" */
export enum Clients_Update_Column {
  /** column name */
  BusinessName = 'business_name',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  DeletedAt = 'deleted_at',
  /** column name */
  Domain = 'domain',
  /** column name */
  Id = 'id',
  /** column name */
  Notes = 'notes',
  /** column name */
  OwnerId = 'owner_id',
  /** column name */
  TenantId = 'tenant_id',
  /** column name */
  TermsAcceptedOn = 'terms_accepted_on',
  /** column name */
  Test = 'test',
  /** column name */
  UpdatedAt = 'updated_at'
}

export type Clients_Updates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: Maybe<Clients_Set_Input>;
  /** filter the rows which have to be updated */
  where: Clients_Bool_Exp;
};

/** columns and relationships of "contacts" */
export type Contacts = {
  __typename?: 'contacts';
  /** An object relationship */
  client: Clients;
  client_id: Scalars['uuid'];
  created_at: Scalars['timestamptz'];
  deleted_at?: Maybe<Scalars['timestamptz']>;
  domain?: Maybe<Scalars['String']>;
  email?: Maybe<Scalars['String']>;
  /** An array relationship */
  events: Array<Events>;
  /** An aggregate relationship */
  events_aggregate: Events_Aggregate;
  first_name: Scalars['String'];
  id: Scalars['uuid'];
  last_name?: Maybe<Scalars['String']>;
  /** An object relationship */
  owner?: Maybe<Users>;
  owner_id?: Maybe<Scalars['String']>;
  phone?: Maybe<Scalars['String']>;
  primary_contact?: Maybe<Scalars['Boolean']>;
  test?: Maybe<Scalars['Boolean']>;
  updated_at: Scalars['timestamptz'];
};


/** columns and relationships of "contacts" */
export type ContactsEventsArgs = {
  distinct_on?: Maybe<Array<Events_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Events_Order_By>>;
  where?: Maybe<Events_Bool_Exp>;
};


/** columns and relationships of "contacts" */
export type ContactsEvents_AggregateArgs = {
  distinct_on?: Maybe<Array<Events_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Events_Order_By>>;
  where?: Maybe<Events_Bool_Exp>;
};

/** aggregated selection of "contacts" */
export type Contacts_Aggregate = {
  __typename?: 'contacts_aggregate';
  aggregate?: Maybe<Contacts_Aggregate_Fields>;
  nodes: Array<Contacts>;
};

export type Contacts_Aggregate_Bool_Exp = {
  bool_and?: Maybe<Contacts_Aggregate_Bool_Exp_Bool_And>;
  bool_or?: Maybe<Contacts_Aggregate_Bool_Exp_Bool_Or>;
  count?: Maybe<Contacts_Aggregate_Bool_Exp_Count>;
};

export type Contacts_Aggregate_Bool_Exp_Bool_And = {
  arguments: Contacts_Select_Column_Contacts_Aggregate_Bool_Exp_Bool_And_Arguments_Columns;
  distinct?: Maybe<Scalars['Boolean']>;
  filter?: Maybe<Contacts_Bool_Exp>;
  predicate: Boolean_Comparison_Exp;
};

export type Contacts_Aggregate_Bool_Exp_Bool_Or = {
  arguments: Contacts_Select_Column_Contacts_Aggregate_Bool_Exp_Bool_Or_Arguments_Columns;
  distinct?: Maybe<Scalars['Boolean']>;
  filter?: Maybe<Contacts_Bool_Exp>;
  predicate: Boolean_Comparison_Exp;
};

export type Contacts_Aggregate_Bool_Exp_Count = {
  arguments?: Maybe<Array<Contacts_Select_Column>>;
  distinct?: Maybe<Scalars['Boolean']>;
  filter?: Maybe<Contacts_Bool_Exp>;
  predicate: Int_Comparison_Exp;
};

/** aggregate fields of "contacts" */
export type Contacts_Aggregate_Fields = {
  __typename?: 'contacts_aggregate_fields';
  count: Scalars['Int'];
  max?: Maybe<Contacts_Max_Fields>;
  min?: Maybe<Contacts_Min_Fields>;
};


/** aggregate fields of "contacts" */
export type Contacts_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<Contacts_Select_Column>>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "contacts" */
export type Contacts_Aggregate_Order_By = {
  count?: Maybe<Order_By>;
  max?: Maybe<Contacts_Max_Order_By>;
  min?: Maybe<Contacts_Min_Order_By>;
};

/** input type for inserting array relation for remote table "contacts" */
export type Contacts_Arr_Rel_Insert_Input = {
  data: Array<Contacts_Insert_Input>;
  /** upsert condition */
  on_conflict?: Maybe<Contacts_On_Conflict>;
};

/** Boolean expression to filter rows from the table "contacts". All fields are combined with a logical 'AND'. */
export type Contacts_Bool_Exp = {
  _and?: Maybe<Array<Contacts_Bool_Exp>>;
  _not?: Maybe<Contacts_Bool_Exp>;
  _or?: Maybe<Array<Contacts_Bool_Exp>>;
  client?: Maybe<Clients_Bool_Exp>;
  client_id?: Maybe<Uuid_Comparison_Exp>;
  created_at?: Maybe<Timestamptz_Comparison_Exp>;
  deleted_at?: Maybe<Timestamptz_Comparison_Exp>;
  domain?: Maybe<String_Comparison_Exp>;
  email?: Maybe<String_Comparison_Exp>;
  events?: Maybe<Events_Bool_Exp>;
  events_aggregate?: Maybe<Events_Aggregate_Bool_Exp>;
  first_name?: Maybe<String_Comparison_Exp>;
  id?: Maybe<Uuid_Comparison_Exp>;
  last_name?: Maybe<String_Comparison_Exp>;
  owner?: Maybe<Users_Bool_Exp>;
  owner_id?: Maybe<String_Comparison_Exp>;
  phone?: Maybe<String_Comparison_Exp>;
  primary_contact?: Maybe<Boolean_Comparison_Exp>;
  test?: Maybe<Boolean_Comparison_Exp>;
  updated_at?: Maybe<Timestamptz_Comparison_Exp>;
};

/** unique or primary key constraints on table "contacts" */
export enum Contacts_Constraint {
  /** unique or primary key constraint on columns "id" */
  ContactsPkey = 'contacts_pkey'
}

/** input type for inserting data into table "contacts" */
export type Contacts_Insert_Input = {
  client?: Maybe<Clients_Obj_Rel_Insert_Input>;
  client_id?: Maybe<Scalars['uuid']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  deleted_at?: Maybe<Scalars['timestamptz']>;
  domain?: Maybe<Scalars['String']>;
  email?: Maybe<Scalars['String']>;
  events?: Maybe<Events_Arr_Rel_Insert_Input>;
  first_name?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['uuid']>;
  last_name?: Maybe<Scalars['String']>;
  owner?: Maybe<Users_Obj_Rel_Insert_Input>;
  owner_id?: Maybe<Scalars['String']>;
  phone?: Maybe<Scalars['String']>;
  primary_contact?: Maybe<Scalars['Boolean']>;
  test?: Maybe<Scalars['Boolean']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** aggregate max on columns */
export type Contacts_Max_Fields = {
  __typename?: 'contacts_max_fields';
  client_id?: Maybe<Scalars['uuid']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  deleted_at?: Maybe<Scalars['timestamptz']>;
  domain?: Maybe<Scalars['String']>;
  email?: Maybe<Scalars['String']>;
  first_name?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['uuid']>;
  last_name?: Maybe<Scalars['String']>;
  owner_id?: Maybe<Scalars['String']>;
  phone?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** order by max() on columns of table "contacts" */
export type Contacts_Max_Order_By = {
  client_id?: Maybe<Order_By>;
  created_at?: Maybe<Order_By>;
  deleted_at?: Maybe<Order_By>;
  domain?: Maybe<Order_By>;
  email?: Maybe<Order_By>;
  first_name?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  last_name?: Maybe<Order_By>;
  owner_id?: Maybe<Order_By>;
  phone?: Maybe<Order_By>;
  updated_at?: Maybe<Order_By>;
};

/** aggregate min on columns */
export type Contacts_Min_Fields = {
  __typename?: 'contacts_min_fields';
  client_id?: Maybe<Scalars['uuid']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  deleted_at?: Maybe<Scalars['timestamptz']>;
  domain?: Maybe<Scalars['String']>;
  email?: Maybe<Scalars['String']>;
  first_name?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['uuid']>;
  last_name?: Maybe<Scalars['String']>;
  owner_id?: Maybe<Scalars['String']>;
  phone?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** order by min() on columns of table "contacts" */
export type Contacts_Min_Order_By = {
  client_id?: Maybe<Order_By>;
  created_at?: Maybe<Order_By>;
  deleted_at?: Maybe<Order_By>;
  domain?: Maybe<Order_By>;
  email?: Maybe<Order_By>;
  first_name?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  last_name?: Maybe<Order_By>;
  owner_id?: Maybe<Order_By>;
  phone?: Maybe<Order_By>;
  updated_at?: Maybe<Order_By>;
};

/** response of any mutation on the table "contacts" */
export type Contacts_Mutation_Response = {
  __typename?: 'contacts_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Contacts>;
};

/** input type for inserting object relation for remote table "contacts" */
export type Contacts_Obj_Rel_Insert_Input = {
  data: Contacts_Insert_Input;
  /** upsert condition */
  on_conflict?: Maybe<Contacts_On_Conflict>;
};

/** on_conflict condition type for table "contacts" */
export type Contacts_On_Conflict = {
  constraint: Contacts_Constraint;
  update_columns?: Array<Contacts_Update_Column>;
  where?: Maybe<Contacts_Bool_Exp>;
};

/** Ordering options when selecting data from "contacts". */
export type Contacts_Order_By = {
  client?: Maybe<Clients_Order_By>;
  client_id?: Maybe<Order_By>;
  created_at?: Maybe<Order_By>;
  deleted_at?: Maybe<Order_By>;
  domain?: Maybe<Order_By>;
  email?: Maybe<Order_By>;
  events_aggregate?: Maybe<Events_Aggregate_Order_By>;
  first_name?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  last_name?: Maybe<Order_By>;
  owner?: Maybe<Users_Order_By>;
  owner_id?: Maybe<Order_By>;
  phone?: Maybe<Order_By>;
  primary_contact?: Maybe<Order_By>;
  test?: Maybe<Order_By>;
  updated_at?: Maybe<Order_By>;
};

/** primary key columns input for table: contacts */
export type Contacts_Pk_Columns_Input = {
  id: Scalars['uuid'];
};

/** select columns of table "contacts" */
export enum Contacts_Select_Column {
  /** column name */
  ClientId = 'client_id',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  DeletedAt = 'deleted_at',
  /** column name */
  Domain = 'domain',
  /** column name */
  Email = 'email',
  /** column name */
  FirstName = 'first_name',
  /** column name */
  Id = 'id',
  /** column name */
  LastName = 'last_name',
  /** column name */
  OwnerId = 'owner_id',
  /** column name */
  Phone = 'phone',
  /** column name */
  PrimaryContact = 'primary_contact',
  /** column name */
  Test = 'test',
  /** column name */
  UpdatedAt = 'updated_at'
}

/** select "contacts_aggregate_bool_exp_bool_and_arguments_columns" columns of table "contacts" */
export enum Contacts_Select_Column_Contacts_Aggregate_Bool_Exp_Bool_And_Arguments_Columns {
  /** column name */
  PrimaryContact = 'primary_contact',
  /** column name */
  Test = 'test'
}

/** select "contacts_aggregate_bool_exp_bool_or_arguments_columns" columns of table "contacts" */
export enum Contacts_Select_Column_Contacts_Aggregate_Bool_Exp_Bool_Or_Arguments_Columns {
  /** column name */
  PrimaryContact = 'primary_contact',
  /** column name */
  Test = 'test'
}

/** input type for updating data in table "contacts" */
export type Contacts_Set_Input = {
  client_id?: Maybe<Scalars['uuid']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  deleted_at?: Maybe<Scalars['timestamptz']>;
  domain?: Maybe<Scalars['String']>;
  email?: Maybe<Scalars['String']>;
  first_name?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['uuid']>;
  last_name?: Maybe<Scalars['String']>;
  owner_id?: Maybe<Scalars['String']>;
  phone?: Maybe<Scalars['String']>;
  primary_contact?: Maybe<Scalars['Boolean']>;
  test?: Maybe<Scalars['Boolean']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** Streaming cursor of the table "contacts" */
export type Contacts_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Contacts_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: Maybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Contacts_Stream_Cursor_Value_Input = {
  client_id?: Maybe<Scalars['uuid']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  deleted_at?: Maybe<Scalars['timestamptz']>;
  domain?: Maybe<Scalars['String']>;
  email?: Maybe<Scalars['String']>;
  first_name?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['uuid']>;
  last_name?: Maybe<Scalars['String']>;
  owner_id?: Maybe<Scalars['String']>;
  phone?: Maybe<Scalars['String']>;
  primary_contact?: Maybe<Scalars['Boolean']>;
  test?: Maybe<Scalars['Boolean']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** update columns of table "contacts" */
export enum Contacts_Update_Column {
  /** column name */
  ClientId = 'client_id',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  DeletedAt = 'deleted_at',
  /** column name */
  Domain = 'domain',
  /** column name */
  Email = 'email',
  /** column name */
  FirstName = 'first_name',
  /** column name */
  Id = 'id',
  /** column name */
  LastName = 'last_name',
  /** column name */
  OwnerId = 'owner_id',
  /** column name */
  Phone = 'phone',
  /** column name */
  PrimaryContact = 'primary_contact',
  /** column name */
  Test = 'test',
  /** column name */
  UpdatedAt = 'updated_at'
}

export type Contacts_Updates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: Maybe<Contacts_Set_Input>;
  /** filter the rows which have to be updated */
  where: Contacts_Bool_Exp;
};


/** Boolean expression to compare columns of type "course_result_enums". All fields are combined with logical 'AND'. */
export type Course_Result_Enums_Comparison_Exp = {
  _eq?: Maybe<Scalars['course_result_enums']>;
  _gt?: Maybe<Scalars['course_result_enums']>;
  _gte?: Maybe<Scalars['course_result_enums']>;
  _in?: Maybe<Array<Scalars['course_result_enums']>>;
  _is_null?: Maybe<Scalars['Boolean']>;
  _lt?: Maybe<Scalars['course_result_enums']>;
  _lte?: Maybe<Scalars['course_result_enums']>;
  _neq?: Maybe<Scalars['course_result_enums']>;
  _nin?: Maybe<Array<Scalars['course_result_enums']>>;
};

/** columns and relationships of "courses" */
export type Courses = {
  __typename?: 'courses';
  /** An array relationship */
  candidate_courses: Array<Candidate_Courses>;
  /** An aggregate relationship */
  candidate_courses_aggregate: Candidate_Courses_Aggregate;
  content_url?: Maybe<Scalars['String']>;
  created_at: Scalars['timestamptz'];
  deleted_at?: Maybe<Scalars['timestamptz']>;
  details?: Maybe<Scalars['String']>;
  form_id?: Maybe<Scalars['String']>;
  id: Scalars['uuid'];
  img?: Maybe<Scalars['String']>;
  instruction?: Maybe<Scalars['String']>;
  live: Scalars['Boolean'];
  name: Scalars['String'];
  overview?: Maybe<Scalars['String']>;
  priority?: Maybe<Scalars['Int']>;
  provider?: Maybe<Scalars['String']>;
  quiz_url?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};


/** columns and relationships of "courses" */
export type CoursesCandidate_CoursesArgs = {
  distinct_on?: Maybe<Array<Candidate_Courses_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Candidate_Courses_Order_By>>;
  where?: Maybe<Candidate_Courses_Bool_Exp>;
};


/** columns and relationships of "courses" */
export type CoursesCandidate_Courses_AggregateArgs = {
  distinct_on?: Maybe<Array<Candidate_Courses_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Candidate_Courses_Order_By>>;
  where?: Maybe<Candidate_Courses_Bool_Exp>;
};

/** aggregated selection of "courses" */
export type Courses_Aggregate = {
  __typename?: 'courses_aggregate';
  aggregate?: Maybe<Courses_Aggregate_Fields>;
  nodes: Array<Courses>;
};

/** aggregate fields of "courses" */
export type Courses_Aggregate_Fields = {
  __typename?: 'courses_aggregate_fields';
  avg?: Maybe<Courses_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<Courses_Max_Fields>;
  min?: Maybe<Courses_Min_Fields>;
  stddev?: Maybe<Courses_Stddev_Fields>;
  stddev_pop?: Maybe<Courses_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Courses_Stddev_Samp_Fields>;
  sum?: Maybe<Courses_Sum_Fields>;
  var_pop?: Maybe<Courses_Var_Pop_Fields>;
  var_samp?: Maybe<Courses_Var_Samp_Fields>;
  variance?: Maybe<Courses_Variance_Fields>;
};


/** aggregate fields of "courses" */
export type Courses_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<Courses_Select_Column>>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** aggregate avg on columns */
export type Courses_Avg_Fields = {
  __typename?: 'courses_avg_fields';
  priority?: Maybe<Scalars['Float']>;
};

/** Boolean expression to filter rows from the table "courses". All fields are combined with a logical 'AND'. */
export type Courses_Bool_Exp = {
  _and?: Maybe<Array<Courses_Bool_Exp>>;
  _not?: Maybe<Courses_Bool_Exp>;
  _or?: Maybe<Array<Courses_Bool_Exp>>;
  candidate_courses?: Maybe<Candidate_Courses_Bool_Exp>;
  candidate_courses_aggregate?: Maybe<Candidate_Courses_Aggregate_Bool_Exp>;
  content_url?: Maybe<String_Comparison_Exp>;
  created_at?: Maybe<Timestamptz_Comparison_Exp>;
  deleted_at?: Maybe<Timestamptz_Comparison_Exp>;
  details?: Maybe<String_Comparison_Exp>;
  form_id?: Maybe<String_Comparison_Exp>;
  id?: Maybe<Uuid_Comparison_Exp>;
  img?: Maybe<String_Comparison_Exp>;
  instruction?: Maybe<String_Comparison_Exp>;
  live?: Maybe<Boolean_Comparison_Exp>;
  name?: Maybe<String_Comparison_Exp>;
  overview?: Maybe<String_Comparison_Exp>;
  priority?: Maybe<Int_Comparison_Exp>;
  provider?: Maybe<String_Comparison_Exp>;
  quiz_url?: Maybe<String_Comparison_Exp>;
  updated_at?: Maybe<Timestamptz_Comparison_Exp>;
};

/** unique or primary key constraints on table "courses" */
export enum Courses_Constraint {
  /** unique or primary key constraint on columns "id" */
  CoursesPkey = 'courses_pkey'
}

/** input type for incrementing numeric columns in table "courses" */
export type Courses_Inc_Input = {
  priority?: Maybe<Scalars['Int']>;
};

/** input type for inserting data into table "courses" */
export type Courses_Insert_Input = {
  candidate_courses?: Maybe<Candidate_Courses_Arr_Rel_Insert_Input>;
  content_url?: Maybe<Scalars['String']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  deleted_at?: Maybe<Scalars['timestamptz']>;
  details?: Maybe<Scalars['String']>;
  form_id?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['uuid']>;
  img?: Maybe<Scalars['String']>;
  instruction?: Maybe<Scalars['String']>;
  live?: Maybe<Scalars['Boolean']>;
  name?: Maybe<Scalars['String']>;
  overview?: Maybe<Scalars['String']>;
  priority?: Maybe<Scalars['Int']>;
  provider?: Maybe<Scalars['String']>;
  quiz_url?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** aggregate max on columns */
export type Courses_Max_Fields = {
  __typename?: 'courses_max_fields';
  content_url?: Maybe<Scalars['String']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  deleted_at?: Maybe<Scalars['timestamptz']>;
  details?: Maybe<Scalars['String']>;
  form_id?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['uuid']>;
  img?: Maybe<Scalars['String']>;
  instruction?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  overview?: Maybe<Scalars['String']>;
  priority?: Maybe<Scalars['Int']>;
  provider?: Maybe<Scalars['String']>;
  quiz_url?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** aggregate min on columns */
export type Courses_Min_Fields = {
  __typename?: 'courses_min_fields';
  content_url?: Maybe<Scalars['String']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  deleted_at?: Maybe<Scalars['timestamptz']>;
  details?: Maybe<Scalars['String']>;
  form_id?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['uuid']>;
  img?: Maybe<Scalars['String']>;
  instruction?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  overview?: Maybe<Scalars['String']>;
  priority?: Maybe<Scalars['Int']>;
  provider?: Maybe<Scalars['String']>;
  quiz_url?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** response of any mutation on the table "courses" */
export type Courses_Mutation_Response = {
  __typename?: 'courses_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Courses>;
};

/** input type for inserting object relation for remote table "courses" */
export type Courses_Obj_Rel_Insert_Input = {
  data: Courses_Insert_Input;
  /** upsert condition */
  on_conflict?: Maybe<Courses_On_Conflict>;
};

/** on_conflict condition type for table "courses" */
export type Courses_On_Conflict = {
  constraint: Courses_Constraint;
  update_columns?: Array<Courses_Update_Column>;
  where?: Maybe<Courses_Bool_Exp>;
};

/** Ordering options when selecting data from "courses". */
export type Courses_Order_By = {
  candidate_courses_aggregate?: Maybe<Candidate_Courses_Aggregate_Order_By>;
  content_url?: Maybe<Order_By>;
  created_at?: Maybe<Order_By>;
  deleted_at?: Maybe<Order_By>;
  details?: Maybe<Order_By>;
  form_id?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  img?: Maybe<Order_By>;
  instruction?: Maybe<Order_By>;
  live?: Maybe<Order_By>;
  name?: Maybe<Order_By>;
  overview?: Maybe<Order_By>;
  priority?: Maybe<Order_By>;
  provider?: Maybe<Order_By>;
  quiz_url?: Maybe<Order_By>;
  updated_at?: Maybe<Order_By>;
};

/** primary key columns input for table: courses */
export type Courses_Pk_Columns_Input = {
  id: Scalars['uuid'];
};

/** select columns of table "courses" */
export enum Courses_Select_Column {
  /** column name */
  ContentUrl = 'content_url',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  DeletedAt = 'deleted_at',
  /** column name */
  Details = 'details',
  /** column name */
  FormId = 'form_id',
  /** column name */
  Id = 'id',
  /** column name */
  Img = 'img',
  /** column name */
  Instruction = 'instruction',
  /** column name */
  Live = 'live',
  /** column name */
  Name = 'name',
  /** column name */
  Overview = 'overview',
  /** column name */
  Priority = 'priority',
  /** column name */
  Provider = 'provider',
  /** column name */
  QuizUrl = 'quiz_url',
  /** column name */
  UpdatedAt = 'updated_at'
}

/** input type for updating data in table "courses" */
export type Courses_Set_Input = {
  content_url?: Maybe<Scalars['String']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  deleted_at?: Maybe<Scalars['timestamptz']>;
  details?: Maybe<Scalars['String']>;
  form_id?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['uuid']>;
  img?: Maybe<Scalars['String']>;
  instruction?: Maybe<Scalars['String']>;
  live?: Maybe<Scalars['Boolean']>;
  name?: Maybe<Scalars['String']>;
  overview?: Maybe<Scalars['String']>;
  priority?: Maybe<Scalars['Int']>;
  provider?: Maybe<Scalars['String']>;
  quiz_url?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** aggregate stddev on columns */
export type Courses_Stddev_Fields = {
  __typename?: 'courses_stddev_fields';
  priority?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_pop on columns */
export type Courses_Stddev_Pop_Fields = {
  __typename?: 'courses_stddev_pop_fields';
  priority?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_samp on columns */
export type Courses_Stddev_Samp_Fields = {
  __typename?: 'courses_stddev_samp_fields';
  priority?: Maybe<Scalars['Float']>;
};

/** Streaming cursor of the table "courses" */
export type Courses_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Courses_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: Maybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Courses_Stream_Cursor_Value_Input = {
  content_url?: Maybe<Scalars['String']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  deleted_at?: Maybe<Scalars['timestamptz']>;
  details?: Maybe<Scalars['String']>;
  form_id?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['uuid']>;
  img?: Maybe<Scalars['String']>;
  instruction?: Maybe<Scalars['String']>;
  live?: Maybe<Scalars['Boolean']>;
  name?: Maybe<Scalars['String']>;
  overview?: Maybe<Scalars['String']>;
  priority?: Maybe<Scalars['Int']>;
  provider?: Maybe<Scalars['String']>;
  quiz_url?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** aggregate sum on columns */
export type Courses_Sum_Fields = {
  __typename?: 'courses_sum_fields';
  priority?: Maybe<Scalars['Int']>;
};

/** update columns of table "courses" */
export enum Courses_Update_Column {
  /** column name */
  ContentUrl = 'content_url',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  DeletedAt = 'deleted_at',
  /** column name */
  Details = 'details',
  /** column name */
  FormId = 'form_id',
  /** column name */
  Id = 'id',
  /** column name */
  Img = 'img',
  /** column name */
  Instruction = 'instruction',
  /** column name */
  Live = 'live',
  /** column name */
  Name = 'name',
  /** column name */
  Overview = 'overview',
  /** column name */
  Priority = 'priority',
  /** column name */
  Provider = 'provider',
  /** column name */
  QuizUrl = 'quiz_url',
  /** column name */
  UpdatedAt = 'updated_at'
}

export type Courses_Updates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: Maybe<Courses_Inc_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: Maybe<Courses_Set_Input>;
  /** filter the rows which have to be updated */
  where: Courses_Bool_Exp;
};

/** aggregate var_pop on columns */
export type Courses_Var_Pop_Fields = {
  __typename?: 'courses_var_pop_fields';
  priority?: Maybe<Scalars['Float']>;
};

/** aggregate var_samp on columns */
export type Courses_Var_Samp_Fields = {
  __typename?: 'courses_var_samp_fields';
  priority?: Maybe<Scalars['Float']>;
};

/** aggregate variance on columns */
export type Courses_Variance_Fields = {
  __typename?: 'courses_variance_fields';
  priority?: Maybe<Scalars['Float']>;
};

/** ordering argument of a cursor */
export enum Cursor_Ordering {
  /** ascending ordering of the cursor */
  Asc = 'ASC',
  /** descending ordering of the cursor */
  Desc = 'DESC'
}

/** columns and relationships of "degrees" */
export type Degrees = {
  __typename?: 'degrees';
  /** An array relationship */
  candidate_degrees: Array<Candidate_Degrees>;
  /** An aggregate relationship */
  candidate_degrees_aggregate: Candidate_Degrees_Aggregate;
  created_at: Scalars['timestamptz'];
  deleted_at?: Maybe<Scalars['timestamptz']>;
  description?: Maybe<Scalars['String']>;
  id: Scalars['uuid'];
  title: Scalars['String'];
  updated_at: Scalars['timestamptz'];
};


/** columns and relationships of "degrees" */
export type DegreesCandidate_DegreesArgs = {
  distinct_on?: Maybe<Array<Candidate_Degrees_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Candidate_Degrees_Order_By>>;
  where?: Maybe<Candidate_Degrees_Bool_Exp>;
};


/** columns and relationships of "degrees" */
export type DegreesCandidate_Degrees_AggregateArgs = {
  distinct_on?: Maybe<Array<Candidate_Degrees_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Candidate_Degrees_Order_By>>;
  where?: Maybe<Candidate_Degrees_Bool_Exp>;
};

/** aggregated selection of "degrees" */
export type Degrees_Aggregate = {
  __typename?: 'degrees_aggregate';
  aggregate?: Maybe<Degrees_Aggregate_Fields>;
  nodes: Array<Degrees>;
};

/** aggregate fields of "degrees" */
export type Degrees_Aggregate_Fields = {
  __typename?: 'degrees_aggregate_fields';
  count: Scalars['Int'];
  max?: Maybe<Degrees_Max_Fields>;
  min?: Maybe<Degrees_Min_Fields>;
};


/** aggregate fields of "degrees" */
export type Degrees_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<Degrees_Select_Column>>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** Boolean expression to filter rows from the table "degrees". All fields are combined with a logical 'AND'. */
export type Degrees_Bool_Exp = {
  _and?: Maybe<Array<Degrees_Bool_Exp>>;
  _not?: Maybe<Degrees_Bool_Exp>;
  _or?: Maybe<Array<Degrees_Bool_Exp>>;
  candidate_degrees?: Maybe<Candidate_Degrees_Bool_Exp>;
  candidate_degrees_aggregate?: Maybe<Candidate_Degrees_Aggregate_Bool_Exp>;
  created_at?: Maybe<Timestamptz_Comparison_Exp>;
  deleted_at?: Maybe<Timestamptz_Comparison_Exp>;
  description?: Maybe<String_Comparison_Exp>;
  id?: Maybe<Uuid_Comparison_Exp>;
  title?: Maybe<String_Comparison_Exp>;
  updated_at?: Maybe<Timestamptz_Comparison_Exp>;
};

/** unique or primary key constraints on table "degrees" */
export enum Degrees_Constraint {
  /** unique or primary key constraint on columns "id" */
  DegreesPkey = 'degrees_pkey',
  /** unique or primary key constraint on columns "title" */
  DegreesTitleKey = 'degrees_title_key'
}

/** input type for inserting data into table "degrees" */
export type Degrees_Insert_Input = {
  candidate_degrees?: Maybe<Candidate_Degrees_Arr_Rel_Insert_Input>;
  created_at?: Maybe<Scalars['timestamptz']>;
  deleted_at?: Maybe<Scalars['timestamptz']>;
  description?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['uuid']>;
  title?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** aggregate max on columns */
export type Degrees_Max_Fields = {
  __typename?: 'degrees_max_fields';
  created_at?: Maybe<Scalars['timestamptz']>;
  deleted_at?: Maybe<Scalars['timestamptz']>;
  description?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['uuid']>;
  title?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** aggregate min on columns */
export type Degrees_Min_Fields = {
  __typename?: 'degrees_min_fields';
  created_at?: Maybe<Scalars['timestamptz']>;
  deleted_at?: Maybe<Scalars['timestamptz']>;
  description?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['uuid']>;
  title?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** response of any mutation on the table "degrees" */
export type Degrees_Mutation_Response = {
  __typename?: 'degrees_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Degrees>;
};

/** input type for inserting object relation for remote table "degrees" */
export type Degrees_Obj_Rel_Insert_Input = {
  data: Degrees_Insert_Input;
  /** upsert condition */
  on_conflict?: Maybe<Degrees_On_Conflict>;
};

/** on_conflict condition type for table "degrees" */
export type Degrees_On_Conflict = {
  constraint: Degrees_Constraint;
  update_columns?: Array<Degrees_Update_Column>;
  where?: Maybe<Degrees_Bool_Exp>;
};

/** Ordering options when selecting data from "degrees". */
export type Degrees_Order_By = {
  candidate_degrees_aggregate?: Maybe<Candidate_Degrees_Aggregate_Order_By>;
  created_at?: Maybe<Order_By>;
  deleted_at?: Maybe<Order_By>;
  description?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  title?: Maybe<Order_By>;
  updated_at?: Maybe<Order_By>;
};

/** primary key columns input for table: degrees */
export type Degrees_Pk_Columns_Input = {
  id: Scalars['uuid'];
};

/** select columns of table "degrees" */
export enum Degrees_Select_Column {
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  DeletedAt = 'deleted_at',
  /** column name */
  Description = 'description',
  /** column name */
  Id = 'id',
  /** column name */
  Title = 'title',
  /** column name */
  UpdatedAt = 'updated_at'
}

/** input type for updating data in table "degrees" */
export type Degrees_Set_Input = {
  created_at?: Maybe<Scalars['timestamptz']>;
  deleted_at?: Maybe<Scalars['timestamptz']>;
  description?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['uuid']>;
  title?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** Streaming cursor of the table "degrees" */
export type Degrees_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Degrees_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: Maybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Degrees_Stream_Cursor_Value_Input = {
  created_at?: Maybe<Scalars['timestamptz']>;
  deleted_at?: Maybe<Scalars['timestamptz']>;
  description?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['uuid']>;
  title?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** update columns of table "degrees" */
export enum Degrees_Update_Column {
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  DeletedAt = 'deleted_at',
  /** column name */
  Description = 'description',
  /** column name */
  Id = 'id',
  /** column name */
  Title = 'title',
  /** column name */
  UpdatedAt = 'updated_at'
}

export type Degrees_Updates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: Maybe<Degrees_Set_Input>;
  /** filter the rows which have to be updated */
  where: Degrees_Bool_Exp;
};

/** columns and relationships of "enterprise_clients" */
export type Enterprise_Clients = {
  __typename?: 'enterprise_clients';
  /** An object relationship */
  client: Clients;
  client_id: Scalars['uuid'];
  created_at: Scalars['timestamptz'];
  deleted_at?: Maybe<Scalars['timestamptz']>;
  /** An object relationship */
  enterprise: Enterprises;
  enterprise_id: Scalars['uuid'];
  updated_at: Scalars['timestamptz'];
};

/** aggregated selection of "enterprise_clients" */
export type Enterprise_Clients_Aggregate = {
  __typename?: 'enterprise_clients_aggregate';
  aggregate?: Maybe<Enterprise_Clients_Aggregate_Fields>;
  nodes: Array<Enterprise_Clients>;
};

export type Enterprise_Clients_Aggregate_Bool_Exp = {
  count?: Maybe<Enterprise_Clients_Aggregate_Bool_Exp_Count>;
};

export type Enterprise_Clients_Aggregate_Bool_Exp_Count = {
  arguments?: Maybe<Array<Enterprise_Clients_Select_Column>>;
  distinct?: Maybe<Scalars['Boolean']>;
  filter?: Maybe<Enterprise_Clients_Bool_Exp>;
  predicate: Int_Comparison_Exp;
};

/** aggregate fields of "enterprise_clients" */
export type Enterprise_Clients_Aggregate_Fields = {
  __typename?: 'enterprise_clients_aggregate_fields';
  count: Scalars['Int'];
  max?: Maybe<Enterprise_Clients_Max_Fields>;
  min?: Maybe<Enterprise_Clients_Min_Fields>;
};


/** aggregate fields of "enterprise_clients" */
export type Enterprise_Clients_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<Enterprise_Clients_Select_Column>>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "enterprise_clients" */
export type Enterprise_Clients_Aggregate_Order_By = {
  count?: Maybe<Order_By>;
  max?: Maybe<Enterprise_Clients_Max_Order_By>;
  min?: Maybe<Enterprise_Clients_Min_Order_By>;
};

/** input type for inserting array relation for remote table "enterprise_clients" */
export type Enterprise_Clients_Arr_Rel_Insert_Input = {
  data: Array<Enterprise_Clients_Insert_Input>;
  /** upsert condition */
  on_conflict?: Maybe<Enterprise_Clients_On_Conflict>;
};

/** Boolean expression to filter rows from the table "enterprise_clients". All fields are combined with a logical 'AND'. */
export type Enterprise_Clients_Bool_Exp = {
  _and?: Maybe<Array<Enterprise_Clients_Bool_Exp>>;
  _not?: Maybe<Enterprise_Clients_Bool_Exp>;
  _or?: Maybe<Array<Enterprise_Clients_Bool_Exp>>;
  client?: Maybe<Clients_Bool_Exp>;
  client_id?: Maybe<Uuid_Comparison_Exp>;
  created_at?: Maybe<Timestamptz_Comparison_Exp>;
  deleted_at?: Maybe<Timestamptz_Comparison_Exp>;
  enterprise?: Maybe<Enterprises_Bool_Exp>;
  enterprise_id?: Maybe<Uuid_Comparison_Exp>;
  updated_at?: Maybe<Timestamptz_Comparison_Exp>;
};

/** unique or primary key constraints on table "enterprise_clients" */
export enum Enterprise_Clients_Constraint {
  /** unique or primary key constraint on columns "enterprise_id", "client_id" */
  EnterpriseClientsPkey = 'enterprise_clients_pkey'
}

/** input type for inserting data into table "enterprise_clients" */
export type Enterprise_Clients_Insert_Input = {
  client?: Maybe<Clients_Obj_Rel_Insert_Input>;
  client_id?: Maybe<Scalars['uuid']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  deleted_at?: Maybe<Scalars['timestamptz']>;
  enterprise?: Maybe<Enterprises_Obj_Rel_Insert_Input>;
  enterprise_id?: Maybe<Scalars['uuid']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** aggregate max on columns */
export type Enterprise_Clients_Max_Fields = {
  __typename?: 'enterprise_clients_max_fields';
  client_id?: Maybe<Scalars['uuid']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  deleted_at?: Maybe<Scalars['timestamptz']>;
  enterprise_id?: Maybe<Scalars['uuid']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** order by max() on columns of table "enterprise_clients" */
export type Enterprise_Clients_Max_Order_By = {
  client_id?: Maybe<Order_By>;
  created_at?: Maybe<Order_By>;
  deleted_at?: Maybe<Order_By>;
  enterprise_id?: Maybe<Order_By>;
  updated_at?: Maybe<Order_By>;
};

/** aggregate min on columns */
export type Enterprise_Clients_Min_Fields = {
  __typename?: 'enterprise_clients_min_fields';
  client_id?: Maybe<Scalars['uuid']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  deleted_at?: Maybe<Scalars['timestamptz']>;
  enterprise_id?: Maybe<Scalars['uuid']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** order by min() on columns of table "enterprise_clients" */
export type Enterprise_Clients_Min_Order_By = {
  client_id?: Maybe<Order_By>;
  created_at?: Maybe<Order_By>;
  deleted_at?: Maybe<Order_By>;
  enterprise_id?: Maybe<Order_By>;
  updated_at?: Maybe<Order_By>;
};

/** response of any mutation on the table "enterprise_clients" */
export type Enterprise_Clients_Mutation_Response = {
  __typename?: 'enterprise_clients_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Enterprise_Clients>;
};

/** on_conflict condition type for table "enterprise_clients" */
export type Enterprise_Clients_On_Conflict = {
  constraint: Enterprise_Clients_Constraint;
  update_columns?: Array<Enterprise_Clients_Update_Column>;
  where?: Maybe<Enterprise_Clients_Bool_Exp>;
};

/** Ordering options when selecting data from "enterprise_clients". */
export type Enterprise_Clients_Order_By = {
  client?: Maybe<Clients_Order_By>;
  client_id?: Maybe<Order_By>;
  created_at?: Maybe<Order_By>;
  deleted_at?: Maybe<Order_By>;
  enterprise?: Maybe<Enterprises_Order_By>;
  enterprise_id?: Maybe<Order_By>;
  updated_at?: Maybe<Order_By>;
};

/** primary key columns input for table: enterprise_clients */
export type Enterprise_Clients_Pk_Columns_Input = {
  client_id: Scalars['uuid'];
  enterprise_id: Scalars['uuid'];
};

/** select columns of table "enterprise_clients" */
export enum Enterprise_Clients_Select_Column {
  /** column name */
  ClientId = 'client_id',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  DeletedAt = 'deleted_at',
  /** column name */
  EnterpriseId = 'enterprise_id',
  /** column name */
  UpdatedAt = 'updated_at'
}

/** input type for updating data in table "enterprise_clients" */
export type Enterprise_Clients_Set_Input = {
  client_id?: Maybe<Scalars['uuid']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  deleted_at?: Maybe<Scalars['timestamptz']>;
  enterprise_id?: Maybe<Scalars['uuid']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** Streaming cursor of the table "enterprise_clients" */
export type Enterprise_Clients_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Enterprise_Clients_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: Maybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Enterprise_Clients_Stream_Cursor_Value_Input = {
  client_id?: Maybe<Scalars['uuid']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  deleted_at?: Maybe<Scalars['timestamptz']>;
  enterprise_id?: Maybe<Scalars['uuid']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** update columns of table "enterprise_clients" */
export enum Enterprise_Clients_Update_Column {
  /** column name */
  ClientId = 'client_id',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  DeletedAt = 'deleted_at',
  /** column name */
  EnterpriseId = 'enterprise_id',
  /** column name */
  UpdatedAt = 'updated_at'
}

export type Enterprise_Clients_Updates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: Maybe<Enterprise_Clients_Set_Input>;
  /** filter the rows which have to be updated */
  where: Enterprise_Clients_Bool_Exp;
};

/** columns and relationships of "enterprise_locations" */
export type Enterprise_Locations = {
  __typename?: 'enterprise_locations';
  created_at: Scalars['timestamptz'];
  deleted_at?: Maybe<Scalars['timestamptz']>;
  /** An object relationship */
  enterprise: Enterprises;
  enterprise_id: Scalars['uuid'];
  id: Scalars['uuid'];
  locality: Scalars['String'];
  /** An object relationship */
  location?: Maybe<Locations>;
  location_description?: Maybe<Scalars['location_name_enums']>;
  postcode: Scalars['String'];
  state: Scalars['String'];
  updated_at: Scalars['timestamptz'];
};

/** aggregated selection of "enterprise_locations" */
export type Enterprise_Locations_Aggregate = {
  __typename?: 'enterprise_locations_aggregate';
  aggregate?: Maybe<Enterprise_Locations_Aggregate_Fields>;
  nodes: Array<Enterprise_Locations>;
};

export type Enterprise_Locations_Aggregate_Bool_Exp = {
  count?: Maybe<Enterprise_Locations_Aggregate_Bool_Exp_Count>;
};

export type Enterprise_Locations_Aggregate_Bool_Exp_Count = {
  arguments?: Maybe<Array<Enterprise_Locations_Select_Column>>;
  distinct?: Maybe<Scalars['Boolean']>;
  filter?: Maybe<Enterprise_Locations_Bool_Exp>;
  predicate: Int_Comparison_Exp;
};

/** aggregate fields of "enterprise_locations" */
export type Enterprise_Locations_Aggregate_Fields = {
  __typename?: 'enterprise_locations_aggregate_fields';
  count: Scalars['Int'];
  max?: Maybe<Enterprise_Locations_Max_Fields>;
  min?: Maybe<Enterprise_Locations_Min_Fields>;
};


/** aggregate fields of "enterprise_locations" */
export type Enterprise_Locations_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<Enterprise_Locations_Select_Column>>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "enterprise_locations" */
export type Enterprise_Locations_Aggregate_Order_By = {
  count?: Maybe<Order_By>;
  max?: Maybe<Enterprise_Locations_Max_Order_By>;
  min?: Maybe<Enterprise_Locations_Min_Order_By>;
};

/** input type for inserting array relation for remote table "enterprise_locations" */
export type Enterprise_Locations_Arr_Rel_Insert_Input = {
  data: Array<Enterprise_Locations_Insert_Input>;
  /** upsert condition */
  on_conflict?: Maybe<Enterprise_Locations_On_Conflict>;
};

/** Boolean expression to filter rows from the table "enterprise_locations". All fields are combined with a logical 'AND'. */
export type Enterprise_Locations_Bool_Exp = {
  _and?: Maybe<Array<Enterprise_Locations_Bool_Exp>>;
  _not?: Maybe<Enterprise_Locations_Bool_Exp>;
  _or?: Maybe<Array<Enterprise_Locations_Bool_Exp>>;
  created_at?: Maybe<Timestamptz_Comparison_Exp>;
  deleted_at?: Maybe<Timestamptz_Comparison_Exp>;
  enterprise?: Maybe<Enterprises_Bool_Exp>;
  enterprise_id?: Maybe<Uuid_Comparison_Exp>;
  id?: Maybe<Uuid_Comparison_Exp>;
  locality?: Maybe<String_Comparison_Exp>;
  location?: Maybe<Locations_Bool_Exp>;
  location_description?: Maybe<Location_Name_Enums_Comparison_Exp>;
  postcode?: Maybe<String_Comparison_Exp>;
  state?: Maybe<String_Comparison_Exp>;
  updated_at?: Maybe<Timestamptz_Comparison_Exp>;
};

/** unique or primary key constraints on table "enterprise_locations" */
export enum Enterprise_Locations_Constraint {
  /** unique or primary key constraint on columns "id" */
  EnterpriseLocationsPkey = 'enterprise_locations_pkey',
  /** unique or primary key constraint on columns "state", "locality", "postcode", "enterprise_id" */
  EnterpriseLocationsTenantIdPostcodeLocalityStateKey = 'enterprise_locations_tenant_id_postcode_locality_state_key'
}

/** input type for inserting data into table "enterprise_locations" */
export type Enterprise_Locations_Insert_Input = {
  created_at?: Maybe<Scalars['timestamptz']>;
  deleted_at?: Maybe<Scalars['timestamptz']>;
  enterprise?: Maybe<Enterprises_Obj_Rel_Insert_Input>;
  enterprise_id?: Maybe<Scalars['uuid']>;
  id?: Maybe<Scalars['uuid']>;
  locality?: Maybe<Scalars['String']>;
  location?: Maybe<Locations_Obj_Rel_Insert_Input>;
  location_description?: Maybe<Scalars['location_name_enums']>;
  postcode?: Maybe<Scalars['String']>;
  state?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** aggregate max on columns */
export type Enterprise_Locations_Max_Fields = {
  __typename?: 'enterprise_locations_max_fields';
  created_at?: Maybe<Scalars['timestamptz']>;
  deleted_at?: Maybe<Scalars['timestamptz']>;
  enterprise_id?: Maybe<Scalars['uuid']>;
  id?: Maybe<Scalars['uuid']>;
  locality?: Maybe<Scalars['String']>;
  location_description?: Maybe<Scalars['location_name_enums']>;
  postcode?: Maybe<Scalars['String']>;
  state?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** order by max() on columns of table "enterprise_locations" */
export type Enterprise_Locations_Max_Order_By = {
  created_at?: Maybe<Order_By>;
  deleted_at?: Maybe<Order_By>;
  enterprise_id?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  locality?: Maybe<Order_By>;
  location_description?: Maybe<Order_By>;
  postcode?: Maybe<Order_By>;
  state?: Maybe<Order_By>;
  updated_at?: Maybe<Order_By>;
};

/** aggregate min on columns */
export type Enterprise_Locations_Min_Fields = {
  __typename?: 'enterprise_locations_min_fields';
  created_at?: Maybe<Scalars['timestamptz']>;
  deleted_at?: Maybe<Scalars['timestamptz']>;
  enterprise_id?: Maybe<Scalars['uuid']>;
  id?: Maybe<Scalars['uuid']>;
  locality?: Maybe<Scalars['String']>;
  location_description?: Maybe<Scalars['location_name_enums']>;
  postcode?: Maybe<Scalars['String']>;
  state?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** order by min() on columns of table "enterprise_locations" */
export type Enterprise_Locations_Min_Order_By = {
  created_at?: Maybe<Order_By>;
  deleted_at?: Maybe<Order_By>;
  enterprise_id?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  locality?: Maybe<Order_By>;
  location_description?: Maybe<Order_By>;
  postcode?: Maybe<Order_By>;
  state?: Maybe<Order_By>;
  updated_at?: Maybe<Order_By>;
};

/** response of any mutation on the table "enterprise_locations" */
export type Enterprise_Locations_Mutation_Response = {
  __typename?: 'enterprise_locations_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Enterprise_Locations>;
};

/** on_conflict condition type for table "enterprise_locations" */
export type Enterprise_Locations_On_Conflict = {
  constraint: Enterprise_Locations_Constraint;
  update_columns?: Array<Enterprise_Locations_Update_Column>;
  where?: Maybe<Enterprise_Locations_Bool_Exp>;
};

/** Ordering options when selecting data from "enterprise_locations". */
export type Enterprise_Locations_Order_By = {
  created_at?: Maybe<Order_By>;
  deleted_at?: Maybe<Order_By>;
  enterprise?: Maybe<Enterprises_Order_By>;
  enterprise_id?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  locality?: Maybe<Order_By>;
  location?: Maybe<Locations_Order_By>;
  location_description?: Maybe<Order_By>;
  postcode?: Maybe<Order_By>;
  state?: Maybe<Order_By>;
  updated_at?: Maybe<Order_By>;
};

/** primary key columns input for table: enterprise_locations */
export type Enterprise_Locations_Pk_Columns_Input = {
  id: Scalars['uuid'];
};

/** select columns of table "enterprise_locations" */
export enum Enterprise_Locations_Select_Column {
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  DeletedAt = 'deleted_at',
  /** column name */
  EnterpriseId = 'enterprise_id',
  /** column name */
  Id = 'id',
  /** column name */
  Locality = 'locality',
  /** column name */
  LocationDescription = 'location_description',
  /** column name */
  Postcode = 'postcode',
  /** column name */
  State = 'state',
  /** column name */
  UpdatedAt = 'updated_at'
}

/** input type for updating data in table "enterprise_locations" */
export type Enterprise_Locations_Set_Input = {
  created_at?: Maybe<Scalars['timestamptz']>;
  deleted_at?: Maybe<Scalars['timestamptz']>;
  enterprise_id?: Maybe<Scalars['uuid']>;
  id?: Maybe<Scalars['uuid']>;
  locality?: Maybe<Scalars['String']>;
  location_description?: Maybe<Scalars['location_name_enums']>;
  postcode?: Maybe<Scalars['String']>;
  state?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** Streaming cursor of the table "enterprise_locations" */
export type Enterprise_Locations_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Enterprise_Locations_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: Maybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Enterprise_Locations_Stream_Cursor_Value_Input = {
  created_at?: Maybe<Scalars['timestamptz']>;
  deleted_at?: Maybe<Scalars['timestamptz']>;
  enterprise_id?: Maybe<Scalars['uuid']>;
  id?: Maybe<Scalars['uuid']>;
  locality?: Maybe<Scalars['String']>;
  location_description?: Maybe<Scalars['location_name_enums']>;
  postcode?: Maybe<Scalars['String']>;
  state?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** update columns of table "enterprise_locations" */
export enum Enterprise_Locations_Update_Column {
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  DeletedAt = 'deleted_at',
  /** column name */
  EnterpriseId = 'enterprise_id',
  /** column name */
  Id = 'id',
  /** column name */
  Locality = 'locality',
  /** column name */
  LocationDescription = 'location_description',
  /** column name */
  Postcode = 'postcode',
  /** column name */
  State = 'state',
  /** column name */
  UpdatedAt = 'updated_at'
}

export type Enterprise_Locations_Updates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: Maybe<Enterprise_Locations_Set_Input>;
  /** filter the rows which have to be updated */
  where: Enterprise_Locations_Bool_Exp;
};

/** columns and relationships of "enterprises" */
export type Enterprises = {
  __typename?: 'enterprises';
  /** An array relationship */
  candidate_follows: Array<Candidate_Follows>;
  /** An aggregate relationship */
  candidate_follows_aggregate: Candidate_Follows_Aggregate;
  created_at: Scalars['timestamptz'];
  deleted_at?: Maybe<Scalars['timestamptz']>;
  email?: Maybe<Scalars['String']>;
  enabled: Scalars['Boolean'];
  /** An array relationship */
  enterprise_clients: Array<Enterprise_Clients>;
  /** An aggregate relationship */
  enterprise_clients_aggregate: Enterprise_Clients_Aggregate;
  /** An array relationship */
  enterprise_locations: Array<Enterprise_Locations>;
  /** An aggregate relationship */
  enterprise_locations_aggregate: Enterprise_Locations_Aggregate;
  /** An array relationship */
  events: Array<Events>;
  /** An aggregate relationship */
  events_aggregate: Events_Aggregate;
  /** An array relationship */
  exclusive_candidates: Array<Exclusive_Candidates>;
  /** An aggregate relationship */
  exclusive_candidates_aggregate: Exclusive_Candidates_Aggregate;
  id: Scalars['uuid'];
  logo?: Maybe<Scalars['String']>;
  logo_inverse?: Maybe<Scalars['String']>;
  lp_img?: Maybe<Scalars['String']>;
  lp_job?: Maybe<Scalars['String']>;
  name: Scalars['String'];
  overview?: Maybe<Scalars['String']>;
  overview_extended?: Maybe<Scalars['String']>;
  owner_id?: Maybe<Scalars['String']>;
  phone?: Maybe<Scalars['String']>;
  public?: Maybe<Scalars['Boolean']>;
  shortname: Scalars['String'];
  staff_count?: Maybe<Scalars['Int']>;
  staff_count_max?: Maybe<Scalars['Int']>;
  test?: Maybe<Scalars['Boolean']>;
  theme_bg_color?: Maybe<Scalars['String']>;
  updated_at: Scalars['timestamptz'];
  /** An object relationship */
  user?: Maybe<Users>;
  website?: Maybe<Scalars['String']>;
  year_founded: Scalars['timestamptz'];
};


/** columns and relationships of "enterprises" */
export type EnterprisesCandidate_FollowsArgs = {
  distinct_on?: Maybe<Array<Candidate_Follows_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Candidate_Follows_Order_By>>;
  where?: Maybe<Candidate_Follows_Bool_Exp>;
};


/** columns and relationships of "enterprises" */
export type EnterprisesCandidate_Follows_AggregateArgs = {
  distinct_on?: Maybe<Array<Candidate_Follows_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Candidate_Follows_Order_By>>;
  where?: Maybe<Candidate_Follows_Bool_Exp>;
};


/** columns and relationships of "enterprises" */
export type EnterprisesEnterprise_ClientsArgs = {
  distinct_on?: Maybe<Array<Enterprise_Clients_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Enterprise_Clients_Order_By>>;
  where?: Maybe<Enterprise_Clients_Bool_Exp>;
};


/** columns and relationships of "enterprises" */
export type EnterprisesEnterprise_Clients_AggregateArgs = {
  distinct_on?: Maybe<Array<Enterprise_Clients_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Enterprise_Clients_Order_By>>;
  where?: Maybe<Enterprise_Clients_Bool_Exp>;
};


/** columns and relationships of "enterprises" */
export type EnterprisesEnterprise_LocationsArgs = {
  distinct_on?: Maybe<Array<Enterprise_Locations_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Enterprise_Locations_Order_By>>;
  where?: Maybe<Enterprise_Locations_Bool_Exp>;
};


/** columns and relationships of "enterprises" */
export type EnterprisesEnterprise_Locations_AggregateArgs = {
  distinct_on?: Maybe<Array<Enterprise_Locations_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Enterprise_Locations_Order_By>>;
  where?: Maybe<Enterprise_Locations_Bool_Exp>;
};


/** columns and relationships of "enterprises" */
export type EnterprisesEventsArgs = {
  distinct_on?: Maybe<Array<Events_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Events_Order_By>>;
  where?: Maybe<Events_Bool_Exp>;
};


/** columns and relationships of "enterprises" */
export type EnterprisesEvents_AggregateArgs = {
  distinct_on?: Maybe<Array<Events_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Events_Order_By>>;
  where?: Maybe<Events_Bool_Exp>;
};


/** columns and relationships of "enterprises" */
export type EnterprisesExclusive_CandidatesArgs = {
  distinct_on?: Maybe<Array<Exclusive_Candidates_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Exclusive_Candidates_Order_By>>;
  where?: Maybe<Exclusive_Candidates_Bool_Exp>;
};


/** columns and relationships of "enterprises" */
export type EnterprisesExclusive_Candidates_AggregateArgs = {
  distinct_on?: Maybe<Array<Exclusive_Candidates_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Exclusive_Candidates_Order_By>>;
  where?: Maybe<Exclusive_Candidates_Bool_Exp>;
};

/** aggregated selection of "enterprises" */
export type Enterprises_Aggregate = {
  __typename?: 'enterprises_aggregate';
  aggregate?: Maybe<Enterprises_Aggregate_Fields>;
  nodes: Array<Enterprises>;
};

export type Enterprises_Aggregate_Bool_Exp = {
  bool_and?: Maybe<Enterprises_Aggregate_Bool_Exp_Bool_And>;
  bool_or?: Maybe<Enterprises_Aggregate_Bool_Exp_Bool_Or>;
  count?: Maybe<Enterprises_Aggregate_Bool_Exp_Count>;
};

export type Enterprises_Aggregate_Bool_Exp_Bool_And = {
  arguments: Enterprises_Select_Column_Enterprises_Aggregate_Bool_Exp_Bool_And_Arguments_Columns;
  distinct?: Maybe<Scalars['Boolean']>;
  filter?: Maybe<Enterprises_Bool_Exp>;
  predicate: Boolean_Comparison_Exp;
};

export type Enterprises_Aggregate_Bool_Exp_Bool_Or = {
  arguments: Enterprises_Select_Column_Enterprises_Aggregate_Bool_Exp_Bool_Or_Arguments_Columns;
  distinct?: Maybe<Scalars['Boolean']>;
  filter?: Maybe<Enterprises_Bool_Exp>;
  predicate: Boolean_Comparison_Exp;
};

export type Enterprises_Aggregate_Bool_Exp_Count = {
  arguments?: Maybe<Array<Enterprises_Select_Column>>;
  distinct?: Maybe<Scalars['Boolean']>;
  filter?: Maybe<Enterprises_Bool_Exp>;
  predicate: Int_Comparison_Exp;
};

/** aggregate fields of "enterprises" */
export type Enterprises_Aggregate_Fields = {
  __typename?: 'enterprises_aggregate_fields';
  avg?: Maybe<Enterprises_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<Enterprises_Max_Fields>;
  min?: Maybe<Enterprises_Min_Fields>;
  stddev?: Maybe<Enterprises_Stddev_Fields>;
  stddev_pop?: Maybe<Enterprises_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Enterprises_Stddev_Samp_Fields>;
  sum?: Maybe<Enterprises_Sum_Fields>;
  var_pop?: Maybe<Enterprises_Var_Pop_Fields>;
  var_samp?: Maybe<Enterprises_Var_Samp_Fields>;
  variance?: Maybe<Enterprises_Variance_Fields>;
};


/** aggregate fields of "enterprises" */
export type Enterprises_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<Enterprises_Select_Column>>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "enterprises" */
export type Enterprises_Aggregate_Order_By = {
  avg?: Maybe<Enterprises_Avg_Order_By>;
  count?: Maybe<Order_By>;
  max?: Maybe<Enterprises_Max_Order_By>;
  min?: Maybe<Enterprises_Min_Order_By>;
  stddev?: Maybe<Enterprises_Stddev_Order_By>;
  stddev_pop?: Maybe<Enterprises_Stddev_Pop_Order_By>;
  stddev_samp?: Maybe<Enterprises_Stddev_Samp_Order_By>;
  sum?: Maybe<Enterprises_Sum_Order_By>;
  var_pop?: Maybe<Enterprises_Var_Pop_Order_By>;
  var_samp?: Maybe<Enterprises_Var_Samp_Order_By>;
  variance?: Maybe<Enterprises_Variance_Order_By>;
};

/** input type for inserting array relation for remote table "enterprises" */
export type Enterprises_Arr_Rel_Insert_Input = {
  data: Array<Enterprises_Insert_Input>;
  /** upsert condition */
  on_conflict?: Maybe<Enterprises_On_Conflict>;
};

/** aggregate avg on columns */
export type Enterprises_Avg_Fields = {
  __typename?: 'enterprises_avg_fields';
  staff_count?: Maybe<Scalars['Float']>;
  staff_count_max?: Maybe<Scalars['Float']>;
};

/** order by avg() on columns of table "enterprises" */
export type Enterprises_Avg_Order_By = {
  staff_count?: Maybe<Order_By>;
  staff_count_max?: Maybe<Order_By>;
};

/** Boolean expression to filter rows from the table "enterprises". All fields are combined with a logical 'AND'. */
export type Enterprises_Bool_Exp = {
  _and?: Maybe<Array<Enterprises_Bool_Exp>>;
  _not?: Maybe<Enterprises_Bool_Exp>;
  _or?: Maybe<Array<Enterprises_Bool_Exp>>;
  candidate_follows?: Maybe<Candidate_Follows_Bool_Exp>;
  candidate_follows_aggregate?: Maybe<Candidate_Follows_Aggregate_Bool_Exp>;
  created_at?: Maybe<Timestamptz_Comparison_Exp>;
  deleted_at?: Maybe<Timestamptz_Comparison_Exp>;
  email?: Maybe<String_Comparison_Exp>;
  enabled?: Maybe<Boolean_Comparison_Exp>;
  enterprise_clients?: Maybe<Enterprise_Clients_Bool_Exp>;
  enterprise_clients_aggregate?: Maybe<Enterprise_Clients_Aggregate_Bool_Exp>;
  enterprise_locations?: Maybe<Enterprise_Locations_Bool_Exp>;
  enterprise_locations_aggregate?: Maybe<Enterprise_Locations_Aggregate_Bool_Exp>;
  events?: Maybe<Events_Bool_Exp>;
  events_aggregate?: Maybe<Events_Aggregate_Bool_Exp>;
  exclusive_candidates?: Maybe<Exclusive_Candidates_Bool_Exp>;
  exclusive_candidates_aggregate?: Maybe<Exclusive_Candidates_Aggregate_Bool_Exp>;
  id?: Maybe<Uuid_Comparison_Exp>;
  logo?: Maybe<String_Comparison_Exp>;
  logo_inverse?: Maybe<String_Comparison_Exp>;
  lp_img?: Maybe<String_Comparison_Exp>;
  lp_job?: Maybe<String_Comparison_Exp>;
  name?: Maybe<String_Comparison_Exp>;
  overview?: Maybe<String_Comparison_Exp>;
  overview_extended?: Maybe<String_Comparison_Exp>;
  owner_id?: Maybe<String_Comparison_Exp>;
  phone?: Maybe<String_Comparison_Exp>;
  public?: Maybe<Boolean_Comparison_Exp>;
  shortname?: Maybe<String_Comparison_Exp>;
  staff_count?: Maybe<Int_Comparison_Exp>;
  staff_count_max?: Maybe<Int_Comparison_Exp>;
  test?: Maybe<Boolean_Comparison_Exp>;
  theme_bg_color?: Maybe<String_Comparison_Exp>;
  updated_at?: Maybe<Timestamptz_Comparison_Exp>;
  user?: Maybe<Users_Bool_Exp>;
  website?: Maybe<String_Comparison_Exp>;
  year_founded?: Maybe<Timestamptz_Comparison_Exp>;
};

/** unique or primary key constraints on table "enterprises" */
export enum Enterprises_Constraint {
  /** unique or primary key constraint on columns "id" */
  EnterprisesPkey = 'enterprises_pkey',
  /** unique or primary key constraint on columns "shortname" */
  EnterprisesShortnameKey = 'enterprises_shortname_key'
}

/** input type for incrementing numeric columns in table "enterprises" */
export type Enterprises_Inc_Input = {
  staff_count?: Maybe<Scalars['Int']>;
  staff_count_max?: Maybe<Scalars['Int']>;
};

/** input type for inserting data into table "enterprises" */
export type Enterprises_Insert_Input = {
  candidate_follows?: Maybe<Candidate_Follows_Arr_Rel_Insert_Input>;
  created_at?: Maybe<Scalars['timestamptz']>;
  deleted_at?: Maybe<Scalars['timestamptz']>;
  email?: Maybe<Scalars['String']>;
  enabled?: Maybe<Scalars['Boolean']>;
  enterprise_clients?: Maybe<Enterprise_Clients_Arr_Rel_Insert_Input>;
  enterprise_locations?: Maybe<Enterprise_Locations_Arr_Rel_Insert_Input>;
  events?: Maybe<Events_Arr_Rel_Insert_Input>;
  exclusive_candidates?: Maybe<Exclusive_Candidates_Arr_Rel_Insert_Input>;
  id?: Maybe<Scalars['uuid']>;
  logo?: Maybe<Scalars['String']>;
  logo_inverse?: Maybe<Scalars['String']>;
  lp_img?: Maybe<Scalars['String']>;
  lp_job?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  overview?: Maybe<Scalars['String']>;
  overview_extended?: Maybe<Scalars['String']>;
  owner_id?: Maybe<Scalars['String']>;
  phone?: Maybe<Scalars['String']>;
  public?: Maybe<Scalars['Boolean']>;
  shortname?: Maybe<Scalars['String']>;
  staff_count?: Maybe<Scalars['Int']>;
  staff_count_max?: Maybe<Scalars['Int']>;
  test?: Maybe<Scalars['Boolean']>;
  theme_bg_color?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
  user?: Maybe<Users_Obj_Rel_Insert_Input>;
  website?: Maybe<Scalars['String']>;
  year_founded?: Maybe<Scalars['timestamptz']>;
};

/** aggregate max on columns */
export type Enterprises_Max_Fields = {
  __typename?: 'enterprises_max_fields';
  created_at?: Maybe<Scalars['timestamptz']>;
  deleted_at?: Maybe<Scalars['timestamptz']>;
  email?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['uuid']>;
  logo?: Maybe<Scalars['String']>;
  logo_inverse?: Maybe<Scalars['String']>;
  lp_img?: Maybe<Scalars['String']>;
  lp_job?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  overview?: Maybe<Scalars['String']>;
  overview_extended?: Maybe<Scalars['String']>;
  owner_id?: Maybe<Scalars['String']>;
  phone?: Maybe<Scalars['String']>;
  shortname?: Maybe<Scalars['String']>;
  staff_count?: Maybe<Scalars['Int']>;
  staff_count_max?: Maybe<Scalars['Int']>;
  theme_bg_color?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
  website?: Maybe<Scalars['String']>;
  year_founded?: Maybe<Scalars['timestamptz']>;
};

/** order by max() on columns of table "enterprises" */
export type Enterprises_Max_Order_By = {
  created_at?: Maybe<Order_By>;
  deleted_at?: Maybe<Order_By>;
  email?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  logo?: Maybe<Order_By>;
  logo_inverse?: Maybe<Order_By>;
  lp_img?: Maybe<Order_By>;
  lp_job?: Maybe<Order_By>;
  name?: Maybe<Order_By>;
  overview?: Maybe<Order_By>;
  overview_extended?: Maybe<Order_By>;
  owner_id?: Maybe<Order_By>;
  phone?: Maybe<Order_By>;
  shortname?: Maybe<Order_By>;
  staff_count?: Maybe<Order_By>;
  staff_count_max?: Maybe<Order_By>;
  theme_bg_color?: Maybe<Order_By>;
  updated_at?: Maybe<Order_By>;
  website?: Maybe<Order_By>;
  year_founded?: Maybe<Order_By>;
};

/** aggregate min on columns */
export type Enterprises_Min_Fields = {
  __typename?: 'enterprises_min_fields';
  created_at?: Maybe<Scalars['timestamptz']>;
  deleted_at?: Maybe<Scalars['timestamptz']>;
  email?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['uuid']>;
  logo?: Maybe<Scalars['String']>;
  logo_inverse?: Maybe<Scalars['String']>;
  lp_img?: Maybe<Scalars['String']>;
  lp_job?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  overview?: Maybe<Scalars['String']>;
  overview_extended?: Maybe<Scalars['String']>;
  owner_id?: Maybe<Scalars['String']>;
  phone?: Maybe<Scalars['String']>;
  shortname?: Maybe<Scalars['String']>;
  staff_count?: Maybe<Scalars['Int']>;
  staff_count_max?: Maybe<Scalars['Int']>;
  theme_bg_color?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
  website?: Maybe<Scalars['String']>;
  year_founded?: Maybe<Scalars['timestamptz']>;
};

/** order by min() on columns of table "enterprises" */
export type Enterprises_Min_Order_By = {
  created_at?: Maybe<Order_By>;
  deleted_at?: Maybe<Order_By>;
  email?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  logo?: Maybe<Order_By>;
  logo_inverse?: Maybe<Order_By>;
  lp_img?: Maybe<Order_By>;
  lp_job?: Maybe<Order_By>;
  name?: Maybe<Order_By>;
  overview?: Maybe<Order_By>;
  overview_extended?: Maybe<Order_By>;
  owner_id?: Maybe<Order_By>;
  phone?: Maybe<Order_By>;
  shortname?: Maybe<Order_By>;
  staff_count?: Maybe<Order_By>;
  staff_count_max?: Maybe<Order_By>;
  theme_bg_color?: Maybe<Order_By>;
  updated_at?: Maybe<Order_By>;
  website?: Maybe<Order_By>;
  year_founded?: Maybe<Order_By>;
};

/** response of any mutation on the table "enterprises" */
export type Enterprises_Mutation_Response = {
  __typename?: 'enterprises_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Enterprises>;
};

/** input type for inserting object relation for remote table "enterprises" */
export type Enterprises_Obj_Rel_Insert_Input = {
  data: Enterprises_Insert_Input;
  /** upsert condition */
  on_conflict?: Maybe<Enterprises_On_Conflict>;
};

/** on_conflict condition type for table "enterprises" */
export type Enterprises_On_Conflict = {
  constraint: Enterprises_Constraint;
  update_columns?: Array<Enterprises_Update_Column>;
  where?: Maybe<Enterprises_Bool_Exp>;
};

/** Ordering options when selecting data from "enterprises". */
export type Enterprises_Order_By = {
  candidate_follows_aggregate?: Maybe<Candidate_Follows_Aggregate_Order_By>;
  created_at?: Maybe<Order_By>;
  deleted_at?: Maybe<Order_By>;
  email?: Maybe<Order_By>;
  enabled?: Maybe<Order_By>;
  enterprise_clients_aggregate?: Maybe<Enterprise_Clients_Aggregate_Order_By>;
  enterprise_locations_aggregate?: Maybe<Enterprise_Locations_Aggregate_Order_By>;
  events_aggregate?: Maybe<Events_Aggregate_Order_By>;
  exclusive_candidates_aggregate?: Maybe<Exclusive_Candidates_Aggregate_Order_By>;
  id?: Maybe<Order_By>;
  logo?: Maybe<Order_By>;
  logo_inverse?: Maybe<Order_By>;
  lp_img?: Maybe<Order_By>;
  lp_job?: Maybe<Order_By>;
  name?: Maybe<Order_By>;
  overview?: Maybe<Order_By>;
  overview_extended?: Maybe<Order_By>;
  owner_id?: Maybe<Order_By>;
  phone?: Maybe<Order_By>;
  public?: Maybe<Order_By>;
  shortname?: Maybe<Order_By>;
  staff_count?: Maybe<Order_By>;
  staff_count_max?: Maybe<Order_By>;
  test?: Maybe<Order_By>;
  theme_bg_color?: Maybe<Order_By>;
  updated_at?: Maybe<Order_By>;
  user?: Maybe<Users_Order_By>;
  website?: Maybe<Order_By>;
  year_founded?: Maybe<Order_By>;
};

/** primary key columns input for table: enterprises */
export type Enterprises_Pk_Columns_Input = {
  id: Scalars['uuid'];
};

/** select columns of table "enterprises" */
export enum Enterprises_Select_Column {
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  DeletedAt = 'deleted_at',
  /** column name */
  Email = 'email',
  /** column name */
  Enabled = 'enabled',
  /** column name */
  Id = 'id',
  /** column name */
  Logo = 'logo',
  /** column name */
  LogoInverse = 'logo_inverse',
  /** column name */
  LpImg = 'lp_img',
  /** column name */
  LpJob = 'lp_job',
  /** column name */
  Name = 'name',
  /** column name */
  Overview = 'overview',
  /** column name */
  OverviewExtended = 'overview_extended',
  /** column name */
  OwnerId = 'owner_id',
  /** column name */
  Phone = 'phone',
  /** column name */
  Public = 'public',
  /** column name */
  Shortname = 'shortname',
  /** column name */
  StaffCount = 'staff_count',
  /** column name */
  StaffCountMax = 'staff_count_max',
  /** column name */
  Test = 'test',
  /** column name */
  ThemeBgColor = 'theme_bg_color',
  /** column name */
  UpdatedAt = 'updated_at',
  /** column name */
  Website = 'website',
  /** column name */
  YearFounded = 'year_founded'
}

/** select "enterprises_aggregate_bool_exp_bool_and_arguments_columns" columns of table "enterprises" */
export enum Enterprises_Select_Column_Enterprises_Aggregate_Bool_Exp_Bool_And_Arguments_Columns {
  /** column name */
  Enabled = 'enabled',
  /** column name */
  Public = 'public',
  /** column name */
  Test = 'test'
}

/** select "enterprises_aggregate_bool_exp_bool_or_arguments_columns" columns of table "enterprises" */
export enum Enterprises_Select_Column_Enterprises_Aggregate_Bool_Exp_Bool_Or_Arguments_Columns {
  /** column name */
  Enabled = 'enabled',
  /** column name */
  Public = 'public',
  /** column name */
  Test = 'test'
}

/** input type for updating data in table "enterprises" */
export type Enterprises_Set_Input = {
  created_at?: Maybe<Scalars['timestamptz']>;
  deleted_at?: Maybe<Scalars['timestamptz']>;
  email?: Maybe<Scalars['String']>;
  enabled?: Maybe<Scalars['Boolean']>;
  id?: Maybe<Scalars['uuid']>;
  logo?: Maybe<Scalars['String']>;
  logo_inverse?: Maybe<Scalars['String']>;
  lp_img?: Maybe<Scalars['String']>;
  lp_job?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  overview?: Maybe<Scalars['String']>;
  overview_extended?: Maybe<Scalars['String']>;
  owner_id?: Maybe<Scalars['String']>;
  phone?: Maybe<Scalars['String']>;
  public?: Maybe<Scalars['Boolean']>;
  shortname?: Maybe<Scalars['String']>;
  staff_count?: Maybe<Scalars['Int']>;
  staff_count_max?: Maybe<Scalars['Int']>;
  test?: Maybe<Scalars['Boolean']>;
  theme_bg_color?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
  website?: Maybe<Scalars['String']>;
  year_founded?: Maybe<Scalars['timestamptz']>;
};

/** aggregate stddev on columns */
export type Enterprises_Stddev_Fields = {
  __typename?: 'enterprises_stddev_fields';
  staff_count?: Maybe<Scalars['Float']>;
  staff_count_max?: Maybe<Scalars['Float']>;
};

/** order by stddev() on columns of table "enterprises" */
export type Enterprises_Stddev_Order_By = {
  staff_count?: Maybe<Order_By>;
  staff_count_max?: Maybe<Order_By>;
};

/** aggregate stddev_pop on columns */
export type Enterprises_Stddev_Pop_Fields = {
  __typename?: 'enterprises_stddev_pop_fields';
  staff_count?: Maybe<Scalars['Float']>;
  staff_count_max?: Maybe<Scalars['Float']>;
};

/** order by stddev_pop() on columns of table "enterprises" */
export type Enterprises_Stddev_Pop_Order_By = {
  staff_count?: Maybe<Order_By>;
  staff_count_max?: Maybe<Order_By>;
};

/** aggregate stddev_samp on columns */
export type Enterprises_Stddev_Samp_Fields = {
  __typename?: 'enterprises_stddev_samp_fields';
  staff_count?: Maybe<Scalars['Float']>;
  staff_count_max?: Maybe<Scalars['Float']>;
};

/** order by stddev_samp() on columns of table "enterprises" */
export type Enterprises_Stddev_Samp_Order_By = {
  staff_count?: Maybe<Order_By>;
  staff_count_max?: Maybe<Order_By>;
};

/** Streaming cursor of the table "enterprises" */
export type Enterprises_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Enterprises_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: Maybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Enterprises_Stream_Cursor_Value_Input = {
  created_at?: Maybe<Scalars['timestamptz']>;
  deleted_at?: Maybe<Scalars['timestamptz']>;
  email?: Maybe<Scalars['String']>;
  enabled?: Maybe<Scalars['Boolean']>;
  id?: Maybe<Scalars['uuid']>;
  logo?: Maybe<Scalars['String']>;
  logo_inverse?: Maybe<Scalars['String']>;
  lp_img?: Maybe<Scalars['String']>;
  lp_job?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  overview?: Maybe<Scalars['String']>;
  overview_extended?: Maybe<Scalars['String']>;
  owner_id?: Maybe<Scalars['String']>;
  phone?: Maybe<Scalars['String']>;
  public?: Maybe<Scalars['Boolean']>;
  shortname?: Maybe<Scalars['String']>;
  staff_count?: Maybe<Scalars['Int']>;
  staff_count_max?: Maybe<Scalars['Int']>;
  test?: Maybe<Scalars['Boolean']>;
  theme_bg_color?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
  website?: Maybe<Scalars['String']>;
  year_founded?: Maybe<Scalars['timestamptz']>;
};

/** aggregate sum on columns */
export type Enterprises_Sum_Fields = {
  __typename?: 'enterprises_sum_fields';
  staff_count?: Maybe<Scalars['Int']>;
  staff_count_max?: Maybe<Scalars['Int']>;
};

/** order by sum() on columns of table "enterprises" */
export type Enterprises_Sum_Order_By = {
  staff_count?: Maybe<Order_By>;
  staff_count_max?: Maybe<Order_By>;
};

/** update columns of table "enterprises" */
export enum Enterprises_Update_Column {
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  DeletedAt = 'deleted_at',
  /** column name */
  Email = 'email',
  /** column name */
  Enabled = 'enabled',
  /** column name */
  Id = 'id',
  /** column name */
  Logo = 'logo',
  /** column name */
  LogoInverse = 'logo_inverse',
  /** column name */
  LpImg = 'lp_img',
  /** column name */
  LpJob = 'lp_job',
  /** column name */
  Name = 'name',
  /** column name */
  Overview = 'overview',
  /** column name */
  OverviewExtended = 'overview_extended',
  /** column name */
  OwnerId = 'owner_id',
  /** column name */
  Phone = 'phone',
  /** column name */
  Public = 'public',
  /** column name */
  Shortname = 'shortname',
  /** column name */
  StaffCount = 'staff_count',
  /** column name */
  StaffCountMax = 'staff_count_max',
  /** column name */
  Test = 'test',
  /** column name */
  ThemeBgColor = 'theme_bg_color',
  /** column name */
  UpdatedAt = 'updated_at',
  /** column name */
  Website = 'website',
  /** column name */
  YearFounded = 'year_founded'
}

export type Enterprises_Updates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: Maybe<Enterprises_Inc_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: Maybe<Enterprises_Set_Input>;
  /** filter the rows which have to be updated */
  where: Enterprises_Bool_Exp;
};

/** aggregate var_pop on columns */
export type Enterprises_Var_Pop_Fields = {
  __typename?: 'enterprises_var_pop_fields';
  staff_count?: Maybe<Scalars['Float']>;
  staff_count_max?: Maybe<Scalars['Float']>;
};

/** order by var_pop() on columns of table "enterprises" */
export type Enterprises_Var_Pop_Order_By = {
  staff_count?: Maybe<Order_By>;
  staff_count_max?: Maybe<Order_By>;
};

/** aggregate var_samp on columns */
export type Enterprises_Var_Samp_Fields = {
  __typename?: 'enterprises_var_samp_fields';
  staff_count?: Maybe<Scalars['Float']>;
  staff_count_max?: Maybe<Scalars['Float']>;
};

/** order by var_samp() on columns of table "enterprises" */
export type Enterprises_Var_Samp_Order_By = {
  staff_count?: Maybe<Order_By>;
  staff_count_max?: Maybe<Order_By>;
};

/** aggregate variance on columns */
export type Enterprises_Variance_Fields = {
  __typename?: 'enterprises_variance_fields';
  staff_count?: Maybe<Scalars['Float']>;
  staff_count_max?: Maybe<Scalars['Float']>;
};

/** order by variance() on columns of table "enterprises" */
export type Enterprises_Variance_Order_By = {
  staff_count?: Maybe<Order_By>;
  staff_count_max?: Maybe<Order_By>;
};


/** Boolean expression to compare columns of type "event_type_enum". All fields are combined with logical 'AND'. */
export type Event_Type_Enum_Comparison_Exp = {
  _eq?: Maybe<Scalars['event_type_enum']>;
  _gt?: Maybe<Scalars['event_type_enum']>;
  _gte?: Maybe<Scalars['event_type_enum']>;
  _in?: Maybe<Array<Scalars['event_type_enum']>>;
  _is_null?: Maybe<Scalars['Boolean']>;
  _lt?: Maybe<Scalars['event_type_enum']>;
  _lte?: Maybe<Scalars['event_type_enum']>;
  _neq?: Maybe<Scalars['event_type_enum']>;
  _nin?: Maybe<Array<Scalars['event_type_enum']>>;
};

/** columns and relationships of "events" */
export type Events = {
  __typename?: 'events';
  /** An object relationship */
  candidate?: Maybe<Candidates>;
  candidate_id?: Maybe<Scalars['uuid']>;
  /** An object relationship */
  client?: Maybe<Clients>;
  client_id?: Maybe<Scalars['uuid']>;
  /** An object relationship */
  contact?: Maybe<Contacts>;
  contact_id?: Maybe<Scalars['uuid']>;
  context?: Maybe<Scalars['String']>;
  created_at: Scalars['timestamptz'];
  deleted_at?: Maybe<Scalars['timestamptz']>;
  /** An object relationship */
  enterprise?: Maybe<Enterprises>;
  enterprise_id?: Maybe<Scalars['uuid']>;
  event: Scalars['String'];
  /** An object relationship */
  feature_popup?: Maybe<Feature_Popups>;
  feature_popups_id?: Maybe<Scalars['uuid']>;
  id: Scalars['uuid'];
  /** An object relationship */
  interview?: Maybe<Interviews>;
  interview_id?: Maybe<Scalars['uuid']>;
  /** An object relationship */
  job?: Maybe<Jobs>;
  job_id?: Maybe<Scalars['uuid']>;
  owner_id: Scalars['String'];
  /** An object relationship */
  short_url?: Maybe<Short_Urls>;
  short_url_id?: Maybe<Scalars['uuid']>;
  /** An object relationship */
  shortlist?: Maybe<Shortlists>;
  shortlist_id?: Maybe<Scalars['uuid']>;
  source?: Maybe<Scalars['String']>;
  /** An object relationship */
  tenant?: Maybe<Tenants>;
  tenant_id?: Maybe<Scalars['uuid']>;
  updated_at: Scalars['timestamptz'];
  /** An object relationship */
  user: Users;
};

/** aggregated selection of "events" */
export type Events_Aggregate = {
  __typename?: 'events_aggregate';
  aggregate?: Maybe<Events_Aggregate_Fields>;
  nodes: Array<Events>;
};

export type Events_Aggregate_Bool_Exp = {
  count?: Maybe<Events_Aggregate_Bool_Exp_Count>;
};

export type Events_Aggregate_Bool_Exp_Count = {
  arguments?: Maybe<Array<Events_Select_Column>>;
  distinct?: Maybe<Scalars['Boolean']>;
  filter?: Maybe<Events_Bool_Exp>;
  predicate: Int_Comparison_Exp;
};

/** aggregate fields of "events" */
export type Events_Aggregate_Fields = {
  __typename?: 'events_aggregate_fields';
  count: Scalars['Int'];
  max?: Maybe<Events_Max_Fields>;
  min?: Maybe<Events_Min_Fields>;
};


/** aggregate fields of "events" */
export type Events_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<Events_Select_Column>>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "events" */
export type Events_Aggregate_Order_By = {
  count?: Maybe<Order_By>;
  max?: Maybe<Events_Max_Order_By>;
  min?: Maybe<Events_Min_Order_By>;
};

/** input type for inserting array relation for remote table "events" */
export type Events_Arr_Rel_Insert_Input = {
  data: Array<Events_Insert_Input>;
  /** upsert condition */
  on_conflict?: Maybe<Events_On_Conflict>;
};

/** Boolean expression to filter rows from the table "events". All fields are combined with a logical 'AND'. */
export type Events_Bool_Exp = {
  _and?: Maybe<Array<Events_Bool_Exp>>;
  _not?: Maybe<Events_Bool_Exp>;
  _or?: Maybe<Array<Events_Bool_Exp>>;
  candidate?: Maybe<Candidates_Bool_Exp>;
  candidate_id?: Maybe<Uuid_Comparison_Exp>;
  client?: Maybe<Clients_Bool_Exp>;
  client_id?: Maybe<Uuid_Comparison_Exp>;
  contact?: Maybe<Contacts_Bool_Exp>;
  contact_id?: Maybe<Uuid_Comparison_Exp>;
  context?: Maybe<String_Comparison_Exp>;
  created_at?: Maybe<Timestamptz_Comparison_Exp>;
  deleted_at?: Maybe<Timestamptz_Comparison_Exp>;
  enterprise?: Maybe<Enterprises_Bool_Exp>;
  enterprise_id?: Maybe<Uuid_Comparison_Exp>;
  event?: Maybe<String_Comparison_Exp>;
  feature_popup?: Maybe<Feature_Popups_Bool_Exp>;
  feature_popups_id?: Maybe<Uuid_Comparison_Exp>;
  id?: Maybe<Uuid_Comparison_Exp>;
  interview?: Maybe<Interviews_Bool_Exp>;
  interview_id?: Maybe<Uuid_Comparison_Exp>;
  job?: Maybe<Jobs_Bool_Exp>;
  job_id?: Maybe<Uuid_Comparison_Exp>;
  owner_id?: Maybe<String_Comparison_Exp>;
  short_url?: Maybe<Short_Urls_Bool_Exp>;
  short_url_id?: Maybe<Uuid_Comparison_Exp>;
  shortlist?: Maybe<Shortlists_Bool_Exp>;
  shortlist_id?: Maybe<Uuid_Comparison_Exp>;
  source?: Maybe<String_Comparison_Exp>;
  tenant?: Maybe<Tenants_Bool_Exp>;
  tenant_id?: Maybe<Uuid_Comparison_Exp>;
  updated_at?: Maybe<Timestamptz_Comparison_Exp>;
  user?: Maybe<Users_Bool_Exp>;
};

/** unique or primary key constraints on table "events" */
export enum Events_Constraint {
  /** unique or primary key constraint on columns "id" */
  EventsPkey = 'events_pkey'
}

/** input type for inserting data into table "events" */
export type Events_Insert_Input = {
  candidate?: Maybe<Candidates_Obj_Rel_Insert_Input>;
  candidate_id?: Maybe<Scalars['uuid']>;
  client?: Maybe<Clients_Obj_Rel_Insert_Input>;
  client_id?: Maybe<Scalars['uuid']>;
  contact?: Maybe<Contacts_Obj_Rel_Insert_Input>;
  contact_id?: Maybe<Scalars['uuid']>;
  context?: Maybe<Scalars['String']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  deleted_at?: Maybe<Scalars['timestamptz']>;
  enterprise?: Maybe<Enterprises_Obj_Rel_Insert_Input>;
  enterprise_id?: Maybe<Scalars['uuid']>;
  event?: Maybe<Scalars['String']>;
  feature_popup?: Maybe<Feature_Popups_Obj_Rel_Insert_Input>;
  feature_popups_id?: Maybe<Scalars['uuid']>;
  id?: Maybe<Scalars['uuid']>;
  interview?: Maybe<Interviews_Obj_Rel_Insert_Input>;
  interview_id?: Maybe<Scalars['uuid']>;
  job?: Maybe<Jobs_Obj_Rel_Insert_Input>;
  job_id?: Maybe<Scalars['uuid']>;
  owner_id?: Maybe<Scalars['String']>;
  short_url?: Maybe<Short_Urls_Obj_Rel_Insert_Input>;
  short_url_id?: Maybe<Scalars['uuid']>;
  shortlist?: Maybe<Shortlists_Obj_Rel_Insert_Input>;
  shortlist_id?: Maybe<Scalars['uuid']>;
  source?: Maybe<Scalars['String']>;
  tenant?: Maybe<Tenants_Obj_Rel_Insert_Input>;
  tenant_id?: Maybe<Scalars['uuid']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
  user?: Maybe<Users_Obj_Rel_Insert_Input>;
};

/** aggregate max on columns */
export type Events_Max_Fields = {
  __typename?: 'events_max_fields';
  candidate_id?: Maybe<Scalars['uuid']>;
  client_id?: Maybe<Scalars['uuid']>;
  contact_id?: Maybe<Scalars['uuid']>;
  context?: Maybe<Scalars['String']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  deleted_at?: Maybe<Scalars['timestamptz']>;
  enterprise_id?: Maybe<Scalars['uuid']>;
  event?: Maybe<Scalars['String']>;
  feature_popups_id?: Maybe<Scalars['uuid']>;
  id?: Maybe<Scalars['uuid']>;
  interview_id?: Maybe<Scalars['uuid']>;
  job_id?: Maybe<Scalars['uuid']>;
  owner_id?: Maybe<Scalars['String']>;
  short_url_id?: Maybe<Scalars['uuid']>;
  shortlist_id?: Maybe<Scalars['uuid']>;
  source?: Maybe<Scalars['String']>;
  tenant_id?: Maybe<Scalars['uuid']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** order by max() on columns of table "events" */
export type Events_Max_Order_By = {
  candidate_id?: Maybe<Order_By>;
  client_id?: Maybe<Order_By>;
  contact_id?: Maybe<Order_By>;
  context?: Maybe<Order_By>;
  created_at?: Maybe<Order_By>;
  deleted_at?: Maybe<Order_By>;
  enterprise_id?: Maybe<Order_By>;
  event?: Maybe<Order_By>;
  feature_popups_id?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  interview_id?: Maybe<Order_By>;
  job_id?: Maybe<Order_By>;
  owner_id?: Maybe<Order_By>;
  short_url_id?: Maybe<Order_By>;
  shortlist_id?: Maybe<Order_By>;
  source?: Maybe<Order_By>;
  tenant_id?: Maybe<Order_By>;
  updated_at?: Maybe<Order_By>;
};

/** aggregate min on columns */
export type Events_Min_Fields = {
  __typename?: 'events_min_fields';
  candidate_id?: Maybe<Scalars['uuid']>;
  client_id?: Maybe<Scalars['uuid']>;
  contact_id?: Maybe<Scalars['uuid']>;
  context?: Maybe<Scalars['String']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  deleted_at?: Maybe<Scalars['timestamptz']>;
  enterprise_id?: Maybe<Scalars['uuid']>;
  event?: Maybe<Scalars['String']>;
  feature_popups_id?: Maybe<Scalars['uuid']>;
  id?: Maybe<Scalars['uuid']>;
  interview_id?: Maybe<Scalars['uuid']>;
  job_id?: Maybe<Scalars['uuid']>;
  owner_id?: Maybe<Scalars['String']>;
  short_url_id?: Maybe<Scalars['uuid']>;
  shortlist_id?: Maybe<Scalars['uuid']>;
  source?: Maybe<Scalars['String']>;
  tenant_id?: Maybe<Scalars['uuid']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** order by min() on columns of table "events" */
export type Events_Min_Order_By = {
  candidate_id?: Maybe<Order_By>;
  client_id?: Maybe<Order_By>;
  contact_id?: Maybe<Order_By>;
  context?: Maybe<Order_By>;
  created_at?: Maybe<Order_By>;
  deleted_at?: Maybe<Order_By>;
  enterprise_id?: Maybe<Order_By>;
  event?: Maybe<Order_By>;
  feature_popups_id?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  interview_id?: Maybe<Order_By>;
  job_id?: Maybe<Order_By>;
  owner_id?: Maybe<Order_By>;
  short_url_id?: Maybe<Order_By>;
  shortlist_id?: Maybe<Order_By>;
  source?: Maybe<Order_By>;
  tenant_id?: Maybe<Order_By>;
  updated_at?: Maybe<Order_By>;
};

/** response of any mutation on the table "events" */
export type Events_Mutation_Response = {
  __typename?: 'events_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Events>;
};

/** on_conflict condition type for table "events" */
export type Events_On_Conflict = {
  constraint: Events_Constraint;
  update_columns?: Array<Events_Update_Column>;
  where?: Maybe<Events_Bool_Exp>;
};

/** Ordering options when selecting data from "events". */
export type Events_Order_By = {
  candidate?: Maybe<Candidates_Order_By>;
  candidate_id?: Maybe<Order_By>;
  client?: Maybe<Clients_Order_By>;
  client_id?: Maybe<Order_By>;
  contact?: Maybe<Contacts_Order_By>;
  contact_id?: Maybe<Order_By>;
  context?: Maybe<Order_By>;
  created_at?: Maybe<Order_By>;
  deleted_at?: Maybe<Order_By>;
  enterprise?: Maybe<Enterprises_Order_By>;
  enterprise_id?: Maybe<Order_By>;
  event?: Maybe<Order_By>;
  feature_popup?: Maybe<Feature_Popups_Order_By>;
  feature_popups_id?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  interview?: Maybe<Interviews_Order_By>;
  interview_id?: Maybe<Order_By>;
  job?: Maybe<Jobs_Order_By>;
  job_id?: Maybe<Order_By>;
  owner_id?: Maybe<Order_By>;
  short_url?: Maybe<Short_Urls_Order_By>;
  short_url_id?: Maybe<Order_By>;
  shortlist?: Maybe<Shortlists_Order_By>;
  shortlist_id?: Maybe<Order_By>;
  source?: Maybe<Order_By>;
  tenant?: Maybe<Tenants_Order_By>;
  tenant_id?: Maybe<Order_By>;
  updated_at?: Maybe<Order_By>;
  user?: Maybe<Users_Order_By>;
};

/** primary key columns input for table: events */
export type Events_Pk_Columns_Input = {
  id: Scalars['uuid'];
};

/** select columns of table "events" */
export enum Events_Select_Column {
  /** column name */
  CandidateId = 'candidate_id',
  /** column name */
  ClientId = 'client_id',
  /** column name */
  ContactId = 'contact_id',
  /** column name */
  Context = 'context',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  DeletedAt = 'deleted_at',
  /** column name */
  EnterpriseId = 'enterprise_id',
  /** column name */
  Event = 'event',
  /** column name */
  FeaturePopupsId = 'feature_popups_id',
  /** column name */
  Id = 'id',
  /** column name */
  InterviewId = 'interview_id',
  /** column name */
  JobId = 'job_id',
  /** column name */
  OwnerId = 'owner_id',
  /** column name */
  ShortUrlId = 'short_url_id',
  /** column name */
  ShortlistId = 'shortlist_id',
  /** column name */
  Source = 'source',
  /** column name */
  TenantId = 'tenant_id',
  /** column name */
  UpdatedAt = 'updated_at'
}

/** input type for updating data in table "events" */
export type Events_Set_Input = {
  candidate_id?: Maybe<Scalars['uuid']>;
  client_id?: Maybe<Scalars['uuid']>;
  contact_id?: Maybe<Scalars['uuid']>;
  context?: Maybe<Scalars['String']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  deleted_at?: Maybe<Scalars['timestamptz']>;
  enterprise_id?: Maybe<Scalars['uuid']>;
  event?: Maybe<Scalars['String']>;
  feature_popups_id?: Maybe<Scalars['uuid']>;
  id?: Maybe<Scalars['uuid']>;
  interview_id?: Maybe<Scalars['uuid']>;
  job_id?: Maybe<Scalars['uuid']>;
  owner_id?: Maybe<Scalars['String']>;
  short_url_id?: Maybe<Scalars['uuid']>;
  shortlist_id?: Maybe<Scalars['uuid']>;
  source?: Maybe<Scalars['String']>;
  tenant_id?: Maybe<Scalars['uuid']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** Streaming cursor of the table "events" */
export type Events_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Events_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: Maybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Events_Stream_Cursor_Value_Input = {
  candidate_id?: Maybe<Scalars['uuid']>;
  client_id?: Maybe<Scalars['uuid']>;
  contact_id?: Maybe<Scalars['uuid']>;
  context?: Maybe<Scalars['String']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  deleted_at?: Maybe<Scalars['timestamptz']>;
  enterprise_id?: Maybe<Scalars['uuid']>;
  event?: Maybe<Scalars['String']>;
  feature_popups_id?: Maybe<Scalars['uuid']>;
  id?: Maybe<Scalars['uuid']>;
  interview_id?: Maybe<Scalars['uuid']>;
  job_id?: Maybe<Scalars['uuid']>;
  owner_id?: Maybe<Scalars['String']>;
  short_url_id?: Maybe<Scalars['uuid']>;
  shortlist_id?: Maybe<Scalars['uuid']>;
  source?: Maybe<Scalars['String']>;
  tenant_id?: Maybe<Scalars['uuid']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** update columns of table "events" */
export enum Events_Update_Column {
  /** column name */
  CandidateId = 'candidate_id',
  /** column name */
  ClientId = 'client_id',
  /** column name */
  ContactId = 'contact_id',
  /** column name */
  Context = 'context',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  DeletedAt = 'deleted_at',
  /** column name */
  EnterpriseId = 'enterprise_id',
  /** column name */
  Event = 'event',
  /** column name */
  FeaturePopupsId = 'feature_popups_id',
  /** column name */
  Id = 'id',
  /** column name */
  InterviewId = 'interview_id',
  /** column name */
  JobId = 'job_id',
  /** column name */
  OwnerId = 'owner_id',
  /** column name */
  ShortUrlId = 'short_url_id',
  /** column name */
  ShortlistId = 'shortlist_id',
  /** column name */
  Source = 'source',
  /** column name */
  TenantId = 'tenant_id',
  /** column name */
  UpdatedAt = 'updated_at'
}

export type Events_Updates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: Maybe<Events_Set_Input>;
  /** filter the rows which have to be updated */
  where: Events_Bool_Exp;
};

/** columns and relationships of "exclusive_candidates" */
export type Exclusive_Candidates = {
  __typename?: 'exclusive_candidates';
  /** An object relationship */
  candidate: Candidates;
  candidate_id: Scalars['uuid'];
  created_at: Scalars['timestamptz'];
  deleted_at?: Maybe<Scalars['timestamptz']>;
  /** An object relationship */
  enterprise: Enterprises;
  enterprise_id: Scalars['uuid'];
  id: Scalars['uuid'];
  published?: Maybe<Scalars['Boolean']>;
  published_on: Scalars['timestamptz'];
  updated_at: Scalars['timestamptz'];
};

/** aggregated selection of "exclusive_candidates" */
export type Exclusive_Candidates_Aggregate = {
  __typename?: 'exclusive_candidates_aggregate';
  aggregate?: Maybe<Exclusive_Candidates_Aggregate_Fields>;
  nodes: Array<Exclusive_Candidates>;
};

export type Exclusive_Candidates_Aggregate_Bool_Exp = {
  bool_and?: Maybe<Exclusive_Candidates_Aggregate_Bool_Exp_Bool_And>;
  bool_or?: Maybe<Exclusive_Candidates_Aggregate_Bool_Exp_Bool_Or>;
  count?: Maybe<Exclusive_Candidates_Aggregate_Bool_Exp_Count>;
};

export type Exclusive_Candidates_Aggregate_Bool_Exp_Bool_And = {
  arguments: Exclusive_Candidates_Select_Column_Exclusive_Candidates_Aggregate_Bool_Exp_Bool_And_Arguments_Columns;
  distinct?: Maybe<Scalars['Boolean']>;
  filter?: Maybe<Exclusive_Candidates_Bool_Exp>;
  predicate: Boolean_Comparison_Exp;
};

export type Exclusive_Candidates_Aggregate_Bool_Exp_Bool_Or = {
  arguments: Exclusive_Candidates_Select_Column_Exclusive_Candidates_Aggregate_Bool_Exp_Bool_Or_Arguments_Columns;
  distinct?: Maybe<Scalars['Boolean']>;
  filter?: Maybe<Exclusive_Candidates_Bool_Exp>;
  predicate: Boolean_Comparison_Exp;
};

export type Exclusive_Candidates_Aggregate_Bool_Exp_Count = {
  arguments?: Maybe<Array<Exclusive_Candidates_Select_Column>>;
  distinct?: Maybe<Scalars['Boolean']>;
  filter?: Maybe<Exclusive_Candidates_Bool_Exp>;
  predicate: Int_Comparison_Exp;
};

/** aggregate fields of "exclusive_candidates" */
export type Exclusive_Candidates_Aggregate_Fields = {
  __typename?: 'exclusive_candidates_aggregate_fields';
  count: Scalars['Int'];
  max?: Maybe<Exclusive_Candidates_Max_Fields>;
  min?: Maybe<Exclusive_Candidates_Min_Fields>;
};


/** aggregate fields of "exclusive_candidates" */
export type Exclusive_Candidates_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<Exclusive_Candidates_Select_Column>>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "exclusive_candidates" */
export type Exclusive_Candidates_Aggregate_Order_By = {
  count?: Maybe<Order_By>;
  max?: Maybe<Exclusive_Candidates_Max_Order_By>;
  min?: Maybe<Exclusive_Candidates_Min_Order_By>;
};

/** input type for inserting array relation for remote table "exclusive_candidates" */
export type Exclusive_Candidates_Arr_Rel_Insert_Input = {
  data: Array<Exclusive_Candidates_Insert_Input>;
  /** upsert condition */
  on_conflict?: Maybe<Exclusive_Candidates_On_Conflict>;
};

/** Boolean expression to filter rows from the table "exclusive_candidates". All fields are combined with a logical 'AND'. */
export type Exclusive_Candidates_Bool_Exp = {
  _and?: Maybe<Array<Exclusive_Candidates_Bool_Exp>>;
  _not?: Maybe<Exclusive_Candidates_Bool_Exp>;
  _or?: Maybe<Array<Exclusive_Candidates_Bool_Exp>>;
  candidate?: Maybe<Candidates_Bool_Exp>;
  candidate_id?: Maybe<Uuid_Comparison_Exp>;
  created_at?: Maybe<Timestamptz_Comparison_Exp>;
  deleted_at?: Maybe<Timestamptz_Comparison_Exp>;
  enterprise?: Maybe<Enterprises_Bool_Exp>;
  enterprise_id?: Maybe<Uuid_Comparison_Exp>;
  id?: Maybe<Uuid_Comparison_Exp>;
  published?: Maybe<Boolean_Comparison_Exp>;
  published_on?: Maybe<Timestamptz_Comparison_Exp>;
  updated_at?: Maybe<Timestamptz_Comparison_Exp>;
};

/** unique or primary key constraints on table "exclusive_candidates" */
export enum Exclusive_Candidates_Constraint {
  /** unique or primary key constraint on columns "enterprise_id", "candidate_id" */
  ExclusiveCandidatesCandidateIdEnterpriseIdKey = 'exclusive_candidates_candidate_id_enterprise_id_key',
  /** unique or primary key constraint on columns "id" */
  ExclusiveCandidatesPkey = 'exclusive_candidates_pkey'
}

/** input type for inserting data into table "exclusive_candidates" */
export type Exclusive_Candidates_Insert_Input = {
  candidate?: Maybe<Candidates_Obj_Rel_Insert_Input>;
  candidate_id?: Maybe<Scalars['uuid']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  deleted_at?: Maybe<Scalars['timestamptz']>;
  enterprise?: Maybe<Enterprises_Obj_Rel_Insert_Input>;
  enterprise_id?: Maybe<Scalars['uuid']>;
  id?: Maybe<Scalars['uuid']>;
  published?: Maybe<Scalars['Boolean']>;
  published_on?: Maybe<Scalars['timestamptz']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** aggregate max on columns */
export type Exclusive_Candidates_Max_Fields = {
  __typename?: 'exclusive_candidates_max_fields';
  candidate_id?: Maybe<Scalars['uuid']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  deleted_at?: Maybe<Scalars['timestamptz']>;
  enterprise_id?: Maybe<Scalars['uuid']>;
  id?: Maybe<Scalars['uuid']>;
  published_on?: Maybe<Scalars['timestamptz']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** order by max() on columns of table "exclusive_candidates" */
export type Exclusive_Candidates_Max_Order_By = {
  candidate_id?: Maybe<Order_By>;
  created_at?: Maybe<Order_By>;
  deleted_at?: Maybe<Order_By>;
  enterprise_id?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  published_on?: Maybe<Order_By>;
  updated_at?: Maybe<Order_By>;
};

/** aggregate min on columns */
export type Exclusive_Candidates_Min_Fields = {
  __typename?: 'exclusive_candidates_min_fields';
  candidate_id?: Maybe<Scalars['uuid']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  deleted_at?: Maybe<Scalars['timestamptz']>;
  enterprise_id?: Maybe<Scalars['uuid']>;
  id?: Maybe<Scalars['uuid']>;
  published_on?: Maybe<Scalars['timestamptz']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** order by min() on columns of table "exclusive_candidates" */
export type Exclusive_Candidates_Min_Order_By = {
  candidate_id?: Maybe<Order_By>;
  created_at?: Maybe<Order_By>;
  deleted_at?: Maybe<Order_By>;
  enterprise_id?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  published_on?: Maybe<Order_By>;
  updated_at?: Maybe<Order_By>;
};

/** response of any mutation on the table "exclusive_candidates" */
export type Exclusive_Candidates_Mutation_Response = {
  __typename?: 'exclusive_candidates_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Exclusive_Candidates>;
};

/** on_conflict condition type for table "exclusive_candidates" */
export type Exclusive_Candidates_On_Conflict = {
  constraint: Exclusive_Candidates_Constraint;
  update_columns?: Array<Exclusive_Candidates_Update_Column>;
  where?: Maybe<Exclusive_Candidates_Bool_Exp>;
};

/** Ordering options when selecting data from "exclusive_candidates". */
export type Exclusive_Candidates_Order_By = {
  candidate?: Maybe<Candidates_Order_By>;
  candidate_id?: Maybe<Order_By>;
  created_at?: Maybe<Order_By>;
  deleted_at?: Maybe<Order_By>;
  enterprise?: Maybe<Enterprises_Order_By>;
  enterprise_id?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  published?: Maybe<Order_By>;
  published_on?: Maybe<Order_By>;
  updated_at?: Maybe<Order_By>;
};

/** primary key columns input for table: exclusive_candidates */
export type Exclusive_Candidates_Pk_Columns_Input = {
  id: Scalars['uuid'];
};

/** select columns of table "exclusive_candidates" */
export enum Exclusive_Candidates_Select_Column {
  /** column name */
  CandidateId = 'candidate_id',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  DeletedAt = 'deleted_at',
  /** column name */
  EnterpriseId = 'enterprise_id',
  /** column name */
  Id = 'id',
  /** column name */
  Published = 'published',
  /** column name */
  PublishedOn = 'published_on',
  /** column name */
  UpdatedAt = 'updated_at'
}

/** select "exclusive_candidates_aggregate_bool_exp_bool_and_arguments_columns" columns of table "exclusive_candidates" */
export enum Exclusive_Candidates_Select_Column_Exclusive_Candidates_Aggregate_Bool_Exp_Bool_And_Arguments_Columns {
  /** column name */
  Published = 'published'
}

/** select "exclusive_candidates_aggregate_bool_exp_bool_or_arguments_columns" columns of table "exclusive_candidates" */
export enum Exclusive_Candidates_Select_Column_Exclusive_Candidates_Aggregate_Bool_Exp_Bool_Or_Arguments_Columns {
  /** column name */
  Published = 'published'
}

/** input type for updating data in table "exclusive_candidates" */
export type Exclusive_Candidates_Set_Input = {
  candidate_id?: Maybe<Scalars['uuid']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  deleted_at?: Maybe<Scalars['timestamptz']>;
  enterprise_id?: Maybe<Scalars['uuid']>;
  id?: Maybe<Scalars['uuid']>;
  published?: Maybe<Scalars['Boolean']>;
  published_on?: Maybe<Scalars['timestamptz']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** Streaming cursor of the table "exclusive_candidates" */
export type Exclusive_Candidates_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Exclusive_Candidates_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: Maybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Exclusive_Candidates_Stream_Cursor_Value_Input = {
  candidate_id?: Maybe<Scalars['uuid']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  deleted_at?: Maybe<Scalars['timestamptz']>;
  enterprise_id?: Maybe<Scalars['uuid']>;
  id?: Maybe<Scalars['uuid']>;
  published?: Maybe<Scalars['Boolean']>;
  published_on?: Maybe<Scalars['timestamptz']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** update columns of table "exclusive_candidates" */
export enum Exclusive_Candidates_Update_Column {
  /** column name */
  CandidateId = 'candidate_id',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  DeletedAt = 'deleted_at',
  /** column name */
  EnterpriseId = 'enterprise_id',
  /** column name */
  Id = 'id',
  /** column name */
  Published = 'published',
  /** column name */
  PublishedOn = 'published_on',
  /** column name */
  UpdatedAt = 'updated_at'
}

export type Exclusive_Candidates_Updates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: Maybe<Exclusive_Candidates_Set_Input>;
  /** filter the rows which have to be updated */
  where: Exclusive_Candidates_Bool_Exp;
};

/** columns and relationships of "feature_popups" */
export type Feature_Popups = {
  __typename?: 'feature_popups';
  body?: Maybe<Scalars['String']>;
  created_at: Scalars['timestamptz'];
  deleted_at?: Maybe<Scalars['timestamptz']>;
  /** An array relationship */
  events: Array<Events>;
  /** An aggregate relationship */
  events_aggregate: Events_Aggregate;
  /** An object relationship */
  feature_popup?: Maybe<Feature_Popups>;
  /** An array relationship */
  feature_popups: Array<Feature_Popups>;
  /** An aggregate relationship */
  feature_popups_aggregate: Feature_Popups_Aggregate;
  goes_after?: Maybe<Scalars['uuid']>;
  id: Scalars['uuid'];
  location?: Maybe<Scalars['String']>;
  location_css_selector?: Maybe<Scalars['String']>;
  name: Scalars['String'];
  photo_url?: Maybe<Scalars['String']>;
  primary_button_text: Scalars['String'];
  primary_button_url?: Maybe<Scalars['String']>;
  secondary_button_text?: Maybe<Scalars['String']>;
  show_to_role: Scalars['user_role_enums'];
  show_until?: Maybe<Scalars['timestamptz']>;
  title: Scalars['String'];
  updated_at: Scalars['timestamptz'];
};


/** columns and relationships of "feature_popups" */
export type Feature_PopupsEventsArgs = {
  distinct_on?: Maybe<Array<Events_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Events_Order_By>>;
  where?: Maybe<Events_Bool_Exp>;
};


/** columns and relationships of "feature_popups" */
export type Feature_PopupsEvents_AggregateArgs = {
  distinct_on?: Maybe<Array<Events_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Events_Order_By>>;
  where?: Maybe<Events_Bool_Exp>;
};


/** columns and relationships of "feature_popups" */
export type Feature_PopupsFeature_PopupsArgs = {
  distinct_on?: Maybe<Array<Feature_Popups_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Feature_Popups_Order_By>>;
  where?: Maybe<Feature_Popups_Bool_Exp>;
};


/** columns and relationships of "feature_popups" */
export type Feature_PopupsFeature_Popups_AggregateArgs = {
  distinct_on?: Maybe<Array<Feature_Popups_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Feature_Popups_Order_By>>;
  where?: Maybe<Feature_Popups_Bool_Exp>;
};

/** aggregated selection of "feature_popups" */
export type Feature_Popups_Aggregate = {
  __typename?: 'feature_popups_aggregate';
  aggregate?: Maybe<Feature_Popups_Aggregate_Fields>;
  nodes: Array<Feature_Popups>;
};

export type Feature_Popups_Aggregate_Bool_Exp = {
  count?: Maybe<Feature_Popups_Aggregate_Bool_Exp_Count>;
};

export type Feature_Popups_Aggregate_Bool_Exp_Count = {
  arguments?: Maybe<Array<Feature_Popups_Select_Column>>;
  distinct?: Maybe<Scalars['Boolean']>;
  filter?: Maybe<Feature_Popups_Bool_Exp>;
  predicate: Int_Comparison_Exp;
};

/** aggregate fields of "feature_popups" */
export type Feature_Popups_Aggregate_Fields = {
  __typename?: 'feature_popups_aggregate_fields';
  count: Scalars['Int'];
  max?: Maybe<Feature_Popups_Max_Fields>;
  min?: Maybe<Feature_Popups_Min_Fields>;
};


/** aggregate fields of "feature_popups" */
export type Feature_Popups_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<Feature_Popups_Select_Column>>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "feature_popups" */
export type Feature_Popups_Aggregate_Order_By = {
  count?: Maybe<Order_By>;
  max?: Maybe<Feature_Popups_Max_Order_By>;
  min?: Maybe<Feature_Popups_Min_Order_By>;
};

/** input type for inserting array relation for remote table "feature_popups" */
export type Feature_Popups_Arr_Rel_Insert_Input = {
  data: Array<Feature_Popups_Insert_Input>;
  /** upsert condition */
  on_conflict?: Maybe<Feature_Popups_On_Conflict>;
};

/** Boolean expression to filter rows from the table "feature_popups". All fields are combined with a logical 'AND'. */
export type Feature_Popups_Bool_Exp = {
  _and?: Maybe<Array<Feature_Popups_Bool_Exp>>;
  _not?: Maybe<Feature_Popups_Bool_Exp>;
  _or?: Maybe<Array<Feature_Popups_Bool_Exp>>;
  body?: Maybe<String_Comparison_Exp>;
  created_at?: Maybe<Timestamptz_Comparison_Exp>;
  deleted_at?: Maybe<Timestamptz_Comparison_Exp>;
  events?: Maybe<Events_Bool_Exp>;
  events_aggregate?: Maybe<Events_Aggregate_Bool_Exp>;
  feature_popup?: Maybe<Feature_Popups_Bool_Exp>;
  feature_popups?: Maybe<Feature_Popups_Bool_Exp>;
  feature_popups_aggregate?: Maybe<Feature_Popups_Aggregate_Bool_Exp>;
  goes_after?: Maybe<Uuid_Comparison_Exp>;
  id?: Maybe<Uuid_Comparison_Exp>;
  location?: Maybe<String_Comparison_Exp>;
  location_css_selector?: Maybe<String_Comparison_Exp>;
  name?: Maybe<String_Comparison_Exp>;
  photo_url?: Maybe<String_Comparison_Exp>;
  primary_button_text?: Maybe<String_Comparison_Exp>;
  primary_button_url?: Maybe<String_Comparison_Exp>;
  secondary_button_text?: Maybe<String_Comparison_Exp>;
  show_to_role?: Maybe<User_Role_Enums_Comparison_Exp>;
  show_until?: Maybe<Timestamptz_Comparison_Exp>;
  title?: Maybe<String_Comparison_Exp>;
  updated_at?: Maybe<Timestamptz_Comparison_Exp>;
};

/** unique or primary key constraints on table "feature_popups" */
export enum Feature_Popups_Constraint {
  /** unique or primary key constraint on columns "id" */
  FeaturePopupsPkey = 'feature_popups_pkey'
}

/** input type for inserting data into table "feature_popups" */
export type Feature_Popups_Insert_Input = {
  body?: Maybe<Scalars['String']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  deleted_at?: Maybe<Scalars['timestamptz']>;
  events?: Maybe<Events_Arr_Rel_Insert_Input>;
  feature_popup?: Maybe<Feature_Popups_Obj_Rel_Insert_Input>;
  feature_popups?: Maybe<Feature_Popups_Arr_Rel_Insert_Input>;
  goes_after?: Maybe<Scalars['uuid']>;
  id?: Maybe<Scalars['uuid']>;
  location?: Maybe<Scalars['String']>;
  location_css_selector?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  photo_url?: Maybe<Scalars['String']>;
  primary_button_text?: Maybe<Scalars['String']>;
  primary_button_url?: Maybe<Scalars['String']>;
  secondary_button_text?: Maybe<Scalars['String']>;
  show_to_role?: Maybe<Scalars['user_role_enums']>;
  show_until?: Maybe<Scalars['timestamptz']>;
  title?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** aggregate max on columns */
export type Feature_Popups_Max_Fields = {
  __typename?: 'feature_popups_max_fields';
  body?: Maybe<Scalars['String']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  deleted_at?: Maybe<Scalars['timestamptz']>;
  goes_after?: Maybe<Scalars['uuid']>;
  id?: Maybe<Scalars['uuid']>;
  location?: Maybe<Scalars['String']>;
  location_css_selector?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  photo_url?: Maybe<Scalars['String']>;
  primary_button_text?: Maybe<Scalars['String']>;
  primary_button_url?: Maybe<Scalars['String']>;
  secondary_button_text?: Maybe<Scalars['String']>;
  show_to_role?: Maybe<Scalars['user_role_enums']>;
  show_until?: Maybe<Scalars['timestamptz']>;
  title?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** order by max() on columns of table "feature_popups" */
export type Feature_Popups_Max_Order_By = {
  body?: Maybe<Order_By>;
  created_at?: Maybe<Order_By>;
  deleted_at?: Maybe<Order_By>;
  goes_after?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  location?: Maybe<Order_By>;
  location_css_selector?: Maybe<Order_By>;
  name?: Maybe<Order_By>;
  photo_url?: Maybe<Order_By>;
  primary_button_text?: Maybe<Order_By>;
  primary_button_url?: Maybe<Order_By>;
  secondary_button_text?: Maybe<Order_By>;
  show_to_role?: Maybe<Order_By>;
  show_until?: Maybe<Order_By>;
  title?: Maybe<Order_By>;
  updated_at?: Maybe<Order_By>;
};

/** aggregate min on columns */
export type Feature_Popups_Min_Fields = {
  __typename?: 'feature_popups_min_fields';
  body?: Maybe<Scalars['String']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  deleted_at?: Maybe<Scalars['timestamptz']>;
  goes_after?: Maybe<Scalars['uuid']>;
  id?: Maybe<Scalars['uuid']>;
  location?: Maybe<Scalars['String']>;
  location_css_selector?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  photo_url?: Maybe<Scalars['String']>;
  primary_button_text?: Maybe<Scalars['String']>;
  primary_button_url?: Maybe<Scalars['String']>;
  secondary_button_text?: Maybe<Scalars['String']>;
  show_to_role?: Maybe<Scalars['user_role_enums']>;
  show_until?: Maybe<Scalars['timestamptz']>;
  title?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** order by min() on columns of table "feature_popups" */
export type Feature_Popups_Min_Order_By = {
  body?: Maybe<Order_By>;
  created_at?: Maybe<Order_By>;
  deleted_at?: Maybe<Order_By>;
  goes_after?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  location?: Maybe<Order_By>;
  location_css_selector?: Maybe<Order_By>;
  name?: Maybe<Order_By>;
  photo_url?: Maybe<Order_By>;
  primary_button_text?: Maybe<Order_By>;
  primary_button_url?: Maybe<Order_By>;
  secondary_button_text?: Maybe<Order_By>;
  show_to_role?: Maybe<Order_By>;
  show_until?: Maybe<Order_By>;
  title?: Maybe<Order_By>;
  updated_at?: Maybe<Order_By>;
};

/** response of any mutation on the table "feature_popups" */
export type Feature_Popups_Mutation_Response = {
  __typename?: 'feature_popups_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Feature_Popups>;
};

/** input type for inserting object relation for remote table "feature_popups" */
export type Feature_Popups_Obj_Rel_Insert_Input = {
  data: Feature_Popups_Insert_Input;
  /** upsert condition */
  on_conflict?: Maybe<Feature_Popups_On_Conflict>;
};

/** on_conflict condition type for table "feature_popups" */
export type Feature_Popups_On_Conflict = {
  constraint: Feature_Popups_Constraint;
  update_columns?: Array<Feature_Popups_Update_Column>;
  where?: Maybe<Feature_Popups_Bool_Exp>;
};

/** Ordering options when selecting data from "feature_popups". */
export type Feature_Popups_Order_By = {
  body?: Maybe<Order_By>;
  created_at?: Maybe<Order_By>;
  deleted_at?: Maybe<Order_By>;
  events_aggregate?: Maybe<Events_Aggregate_Order_By>;
  feature_popup?: Maybe<Feature_Popups_Order_By>;
  feature_popups_aggregate?: Maybe<Feature_Popups_Aggregate_Order_By>;
  goes_after?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  location?: Maybe<Order_By>;
  location_css_selector?: Maybe<Order_By>;
  name?: Maybe<Order_By>;
  photo_url?: Maybe<Order_By>;
  primary_button_text?: Maybe<Order_By>;
  primary_button_url?: Maybe<Order_By>;
  secondary_button_text?: Maybe<Order_By>;
  show_to_role?: Maybe<Order_By>;
  show_until?: Maybe<Order_By>;
  title?: Maybe<Order_By>;
  updated_at?: Maybe<Order_By>;
};

/** primary key columns input for table: feature_popups */
export type Feature_Popups_Pk_Columns_Input = {
  id: Scalars['uuid'];
};

/** select columns of table "feature_popups" */
export enum Feature_Popups_Select_Column {
  /** column name */
  Body = 'body',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  DeletedAt = 'deleted_at',
  /** column name */
  GoesAfter = 'goes_after',
  /** column name */
  Id = 'id',
  /** column name */
  Location = 'location',
  /** column name */
  LocationCssSelector = 'location_css_selector',
  /** column name */
  Name = 'name',
  /** column name */
  PhotoUrl = 'photo_url',
  /** column name */
  PrimaryButtonText = 'primary_button_text',
  /** column name */
  PrimaryButtonUrl = 'primary_button_url',
  /** column name */
  SecondaryButtonText = 'secondary_button_text',
  /** column name */
  ShowToRole = 'show_to_role',
  /** column name */
  ShowUntil = 'show_until',
  /** column name */
  Title = 'title',
  /** column name */
  UpdatedAt = 'updated_at'
}

/** input type for updating data in table "feature_popups" */
export type Feature_Popups_Set_Input = {
  body?: Maybe<Scalars['String']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  deleted_at?: Maybe<Scalars['timestamptz']>;
  goes_after?: Maybe<Scalars['uuid']>;
  id?: Maybe<Scalars['uuid']>;
  location?: Maybe<Scalars['String']>;
  location_css_selector?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  photo_url?: Maybe<Scalars['String']>;
  primary_button_text?: Maybe<Scalars['String']>;
  primary_button_url?: Maybe<Scalars['String']>;
  secondary_button_text?: Maybe<Scalars['String']>;
  show_to_role?: Maybe<Scalars['user_role_enums']>;
  show_until?: Maybe<Scalars['timestamptz']>;
  title?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** Streaming cursor of the table "feature_popups" */
export type Feature_Popups_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Feature_Popups_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: Maybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Feature_Popups_Stream_Cursor_Value_Input = {
  body?: Maybe<Scalars['String']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  deleted_at?: Maybe<Scalars['timestamptz']>;
  goes_after?: Maybe<Scalars['uuid']>;
  id?: Maybe<Scalars['uuid']>;
  location?: Maybe<Scalars['String']>;
  location_css_selector?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  photo_url?: Maybe<Scalars['String']>;
  primary_button_text?: Maybe<Scalars['String']>;
  primary_button_url?: Maybe<Scalars['String']>;
  secondary_button_text?: Maybe<Scalars['String']>;
  show_to_role?: Maybe<Scalars['user_role_enums']>;
  show_until?: Maybe<Scalars['timestamptz']>;
  title?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** update columns of table "feature_popups" */
export enum Feature_Popups_Update_Column {
  /** column name */
  Body = 'body',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  DeletedAt = 'deleted_at',
  /** column name */
  GoesAfter = 'goes_after',
  /** column name */
  Id = 'id',
  /** column name */
  Location = 'location',
  /** column name */
  LocationCssSelector = 'location_css_selector',
  /** column name */
  Name = 'name',
  /** column name */
  PhotoUrl = 'photo_url',
  /** column name */
  PrimaryButtonText = 'primary_button_text',
  /** column name */
  PrimaryButtonUrl = 'primary_button_url',
  /** column name */
  SecondaryButtonText = 'secondary_button_text',
  /** column name */
  ShowToRole = 'show_to_role',
  /** column name */
  ShowUntil = 'show_until',
  /** column name */
  Title = 'title',
  /** column name */
  UpdatedAt = 'updated_at'
}

export type Feature_Popups_Updates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: Maybe<Feature_Popups_Set_Input>;
  /** filter the rows which have to be updated */
  where: Feature_Popups_Bool_Exp;
};


export type Geography_Cast_Exp = {
  geometry?: Maybe<Geometry_Comparison_Exp>;
};

/** columns and relationships of "geography_columns" */
export type Geography_Columns = {
  __typename?: 'geography_columns';
  coord_dimension?: Maybe<Scalars['Int']>;
  f_geography_column?: Maybe<Scalars['name']>;
  f_table_catalog?: Maybe<Scalars['name']>;
  f_table_name?: Maybe<Scalars['name']>;
  f_table_schema?: Maybe<Scalars['name']>;
  srid?: Maybe<Scalars['Int']>;
  type?: Maybe<Scalars['String']>;
};

/** aggregated selection of "geography_columns" */
export type Geography_Columns_Aggregate = {
  __typename?: 'geography_columns_aggregate';
  aggregate?: Maybe<Geography_Columns_Aggregate_Fields>;
  nodes: Array<Geography_Columns>;
};

/** aggregate fields of "geography_columns" */
export type Geography_Columns_Aggregate_Fields = {
  __typename?: 'geography_columns_aggregate_fields';
  avg?: Maybe<Geography_Columns_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<Geography_Columns_Max_Fields>;
  min?: Maybe<Geography_Columns_Min_Fields>;
  stddev?: Maybe<Geography_Columns_Stddev_Fields>;
  stddev_pop?: Maybe<Geography_Columns_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Geography_Columns_Stddev_Samp_Fields>;
  sum?: Maybe<Geography_Columns_Sum_Fields>;
  var_pop?: Maybe<Geography_Columns_Var_Pop_Fields>;
  var_samp?: Maybe<Geography_Columns_Var_Samp_Fields>;
  variance?: Maybe<Geography_Columns_Variance_Fields>;
};


/** aggregate fields of "geography_columns" */
export type Geography_Columns_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<Geography_Columns_Select_Column>>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** aggregate avg on columns */
export type Geography_Columns_Avg_Fields = {
  __typename?: 'geography_columns_avg_fields';
  coord_dimension?: Maybe<Scalars['Float']>;
  srid?: Maybe<Scalars['Float']>;
};

/** Boolean expression to filter rows from the table "geography_columns". All fields are combined with a logical 'AND'. */
export type Geography_Columns_Bool_Exp = {
  _and?: Maybe<Array<Geography_Columns_Bool_Exp>>;
  _not?: Maybe<Geography_Columns_Bool_Exp>;
  _or?: Maybe<Array<Geography_Columns_Bool_Exp>>;
  coord_dimension?: Maybe<Int_Comparison_Exp>;
  f_geography_column?: Maybe<Name_Comparison_Exp>;
  f_table_catalog?: Maybe<Name_Comparison_Exp>;
  f_table_name?: Maybe<Name_Comparison_Exp>;
  f_table_schema?: Maybe<Name_Comparison_Exp>;
  srid?: Maybe<Int_Comparison_Exp>;
  type?: Maybe<String_Comparison_Exp>;
};

/** aggregate max on columns */
export type Geography_Columns_Max_Fields = {
  __typename?: 'geography_columns_max_fields';
  coord_dimension?: Maybe<Scalars['Int']>;
  srid?: Maybe<Scalars['Int']>;
  type?: Maybe<Scalars['String']>;
};

/** aggregate min on columns */
export type Geography_Columns_Min_Fields = {
  __typename?: 'geography_columns_min_fields';
  coord_dimension?: Maybe<Scalars['Int']>;
  srid?: Maybe<Scalars['Int']>;
  type?: Maybe<Scalars['String']>;
};

/** Ordering options when selecting data from "geography_columns". */
export type Geography_Columns_Order_By = {
  coord_dimension?: Maybe<Order_By>;
  f_geography_column?: Maybe<Order_By>;
  f_table_catalog?: Maybe<Order_By>;
  f_table_name?: Maybe<Order_By>;
  f_table_schema?: Maybe<Order_By>;
  srid?: Maybe<Order_By>;
  type?: Maybe<Order_By>;
};

/** select columns of table "geography_columns" */
export enum Geography_Columns_Select_Column {
  /** column name */
  CoordDimension = 'coord_dimension',
  /** column name */
  FGeographyColumn = 'f_geography_column',
  /** column name */
  FTableCatalog = 'f_table_catalog',
  /** column name */
  FTableName = 'f_table_name',
  /** column name */
  FTableSchema = 'f_table_schema',
  /** column name */
  Srid = 'srid',
  /** column name */
  Type = 'type'
}

/** aggregate stddev on columns */
export type Geography_Columns_Stddev_Fields = {
  __typename?: 'geography_columns_stddev_fields';
  coord_dimension?: Maybe<Scalars['Float']>;
  srid?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_pop on columns */
export type Geography_Columns_Stddev_Pop_Fields = {
  __typename?: 'geography_columns_stddev_pop_fields';
  coord_dimension?: Maybe<Scalars['Float']>;
  srid?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_samp on columns */
export type Geography_Columns_Stddev_Samp_Fields = {
  __typename?: 'geography_columns_stddev_samp_fields';
  coord_dimension?: Maybe<Scalars['Float']>;
  srid?: Maybe<Scalars['Float']>;
};

/** Streaming cursor of the table "geography_columns" */
export type Geography_Columns_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Geography_Columns_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: Maybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Geography_Columns_Stream_Cursor_Value_Input = {
  coord_dimension?: Maybe<Scalars['Int']>;
  f_geography_column?: Maybe<Scalars['name']>;
  f_table_catalog?: Maybe<Scalars['name']>;
  f_table_name?: Maybe<Scalars['name']>;
  f_table_schema?: Maybe<Scalars['name']>;
  srid?: Maybe<Scalars['Int']>;
  type?: Maybe<Scalars['String']>;
};

/** aggregate sum on columns */
export type Geography_Columns_Sum_Fields = {
  __typename?: 'geography_columns_sum_fields';
  coord_dimension?: Maybe<Scalars['Int']>;
  srid?: Maybe<Scalars['Int']>;
};

/** aggregate var_pop on columns */
export type Geography_Columns_Var_Pop_Fields = {
  __typename?: 'geography_columns_var_pop_fields';
  coord_dimension?: Maybe<Scalars['Float']>;
  srid?: Maybe<Scalars['Float']>;
};

/** aggregate var_samp on columns */
export type Geography_Columns_Var_Samp_Fields = {
  __typename?: 'geography_columns_var_samp_fields';
  coord_dimension?: Maybe<Scalars['Float']>;
  srid?: Maybe<Scalars['Float']>;
};

/** aggregate variance on columns */
export type Geography_Columns_Variance_Fields = {
  __typename?: 'geography_columns_variance_fields';
  coord_dimension?: Maybe<Scalars['Float']>;
  srid?: Maybe<Scalars['Float']>;
};

/** Boolean expression to compare columns of type "geography". All fields are combined with logical 'AND'. */
export type Geography_Comparison_Exp = {
  _cast?: Maybe<Geography_Cast_Exp>;
  _eq?: Maybe<Scalars['geography']>;
  _gt?: Maybe<Scalars['geography']>;
  _gte?: Maybe<Scalars['geography']>;
  _in?: Maybe<Array<Scalars['geography']>>;
  _is_null?: Maybe<Scalars['Boolean']>;
  _lt?: Maybe<Scalars['geography']>;
  _lte?: Maybe<Scalars['geography']>;
  _neq?: Maybe<Scalars['geography']>;
  _nin?: Maybe<Array<Scalars['geography']>>;
  /** is the column within a given distance from the given geography value */
  _st_d_within?: Maybe<St_D_Within_Geography_Input>;
  /** does the column spatially intersect the given geography value */
  _st_intersects?: Maybe<Scalars['geography']>;
};


export type Geometry_Cast_Exp = {
  geography?: Maybe<Geography_Comparison_Exp>;
};

/** columns and relationships of "geometry_columns" */
export type Geometry_Columns = {
  __typename?: 'geometry_columns';
  coord_dimension?: Maybe<Scalars['Int']>;
  f_geometry_column?: Maybe<Scalars['name']>;
  f_table_catalog?: Maybe<Scalars['String']>;
  f_table_name?: Maybe<Scalars['name']>;
  f_table_schema?: Maybe<Scalars['name']>;
  srid?: Maybe<Scalars['Int']>;
  type?: Maybe<Scalars['String']>;
};

/** aggregated selection of "geometry_columns" */
export type Geometry_Columns_Aggregate = {
  __typename?: 'geometry_columns_aggregate';
  aggregate?: Maybe<Geometry_Columns_Aggregate_Fields>;
  nodes: Array<Geometry_Columns>;
};

/** aggregate fields of "geometry_columns" */
export type Geometry_Columns_Aggregate_Fields = {
  __typename?: 'geometry_columns_aggregate_fields';
  avg?: Maybe<Geometry_Columns_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<Geometry_Columns_Max_Fields>;
  min?: Maybe<Geometry_Columns_Min_Fields>;
  stddev?: Maybe<Geometry_Columns_Stddev_Fields>;
  stddev_pop?: Maybe<Geometry_Columns_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Geometry_Columns_Stddev_Samp_Fields>;
  sum?: Maybe<Geometry_Columns_Sum_Fields>;
  var_pop?: Maybe<Geometry_Columns_Var_Pop_Fields>;
  var_samp?: Maybe<Geometry_Columns_Var_Samp_Fields>;
  variance?: Maybe<Geometry_Columns_Variance_Fields>;
};


/** aggregate fields of "geometry_columns" */
export type Geometry_Columns_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<Geometry_Columns_Select_Column>>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** aggregate avg on columns */
export type Geometry_Columns_Avg_Fields = {
  __typename?: 'geometry_columns_avg_fields';
  coord_dimension?: Maybe<Scalars['Float']>;
  srid?: Maybe<Scalars['Float']>;
};

/** Boolean expression to filter rows from the table "geometry_columns". All fields are combined with a logical 'AND'. */
export type Geometry_Columns_Bool_Exp = {
  _and?: Maybe<Array<Geometry_Columns_Bool_Exp>>;
  _not?: Maybe<Geometry_Columns_Bool_Exp>;
  _or?: Maybe<Array<Geometry_Columns_Bool_Exp>>;
  coord_dimension?: Maybe<Int_Comparison_Exp>;
  f_geometry_column?: Maybe<Name_Comparison_Exp>;
  f_table_catalog?: Maybe<String_Comparison_Exp>;
  f_table_name?: Maybe<Name_Comparison_Exp>;
  f_table_schema?: Maybe<Name_Comparison_Exp>;
  srid?: Maybe<Int_Comparison_Exp>;
  type?: Maybe<String_Comparison_Exp>;
};

/** input type for incrementing numeric columns in table "geometry_columns" */
export type Geometry_Columns_Inc_Input = {
  coord_dimension?: Maybe<Scalars['Int']>;
  srid?: Maybe<Scalars['Int']>;
};

/** input type for inserting data into table "geometry_columns" */
export type Geometry_Columns_Insert_Input = {
  coord_dimension?: Maybe<Scalars['Int']>;
  f_geometry_column?: Maybe<Scalars['name']>;
  f_table_catalog?: Maybe<Scalars['String']>;
  f_table_name?: Maybe<Scalars['name']>;
  f_table_schema?: Maybe<Scalars['name']>;
  srid?: Maybe<Scalars['Int']>;
  type?: Maybe<Scalars['String']>;
};

/** aggregate max on columns */
export type Geometry_Columns_Max_Fields = {
  __typename?: 'geometry_columns_max_fields';
  coord_dimension?: Maybe<Scalars['Int']>;
  f_table_catalog?: Maybe<Scalars['String']>;
  srid?: Maybe<Scalars['Int']>;
  type?: Maybe<Scalars['String']>;
};

/** aggregate min on columns */
export type Geometry_Columns_Min_Fields = {
  __typename?: 'geometry_columns_min_fields';
  coord_dimension?: Maybe<Scalars['Int']>;
  f_table_catalog?: Maybe<Scalars['String']>;
  srid?: Maybe<Scalars['Int']>;
  type?: Maybe<Scalars['String']>;
};

/** response of any mutation on the table "geometry_columns" */
export type Geometry_Columns_Mutation_Response = {
  __typename?: 'geometry_columns_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Geometry_Columns>;
};

/** Ordering options when selecting data from "geometry_columns". */
export type Geometry_Columns_Order_By = {
  coord_dimension?: Maybe<Order_By>;
  f_geometry_column?: Maybe<Order_By>;
  f_table_catalog?: Maybe<Order_By>;
  f_table_name?: Maybe<Order_By>;
  f_table_schema?: Maybe<Order_By>;
  srid?: Maybe<Order_By>;
  type?: Maybe<Order_By>;
};

/** select columns of table "geometry_columns" */
export enum Geometry_Columns_Select_Column {
  /** column name */
  CoordDimension = 'coord_dimension',
  /** column name */
  FGeometryColumn = 'f_geometry_column',
  /** column name */
  FTableCatalog = 'f_table_catalog',
  /** column name */
  FTableName = 'f_table_name',
  /** column name */
  FTableSchema = 'f_table_schema',
  /** column name */
  Srid = 'srid',
  /** column name */
  Type = 'type'
}

/** input type for updating data in table "geometry_columns" */
export type Geometry_Columns_Set_Input = {
  coord_dimension?: Maybe<Scalars['Int']>;
  f_geometry_column?: Maybe<Scalars['name']>;
  f_table_catalog?: Maybe<Scalars['String']>;
  f_table_name?: Maybe<Scalars['name']>;
  f_table_schema?: Maybe<Scalars['name']>;
  srid?: Maybe<Scalars['Int']>;
  type?: Maybe<Scalars['String']>;
};

/** aggregate stddev on columns */
export type Geometry_Columns_Stddev_Fields = {
  __typename?: 'geometry_columns_stddev_fields';
  coord_dimension?: Maybe<Scalars['Float']>;
  srid?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_pop on columns */
export type Geometry_Columns_Stddev_Pop_Fields = {
  __typename?: 'geometry_columns_stddev_pop_fields';
  coord_dimension?: Maybe<Scalars['Float']>;
  srid?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_samp on columns */
export type Geometry_Columns_Stddev_Samp_Fields = {
  __typename?: 'geometry_columns_stddev_samp_fields';
  coord_dimension?: Maybe<Scalars['Float']>;
  srid?: Maybe<Scalars['Float']>;
};

/** Streaming cursor of the table "geometry_columns" */
export type Geometry_Columns_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Geometry_Columns_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: Maybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Geometry_Columns_Stream_Cursor_Value_Input = {
  coord_dimension?: Maybe<Scalars['Int']>;
  f_geometry_column?: Maybe<Scalars['name']>;
  f_table_catalog?: Maybe<Scalars['String']>;
  f_table_name?: Maybe<Scalars['name']>;
  f_table_schema?: Maybe<Scalars['name']>;
  srid?: Maybe<Scalars['Int']>;
  type?: Maybe<Scalars['String']>;
};

/** aggregate sum on columns */
export type Geometry_Columns_Sum_Fields = {
  __typename?: 'geometry_columns_sum_fields';
  coord_dimension?: Maybe<Scalars['Int']>;
  srid?: Maybe<Scalars['Int']>;
};

export type Geometry_Columns_Updates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: Maybe<Geometry_Columns_Inc_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: Maybe<Geometry_Columns_Set_Input>;
  /** filter the rows which have to be updated */
  where: Geometry_Columns_Bool_Exp;
};

/** aggregate var_pop on columns */
export type Geometry_Columns_Var_Pop_Fields = {
  __typename?: 'geometry_columns_var_pop_fields';
  coord_dimension?: Maybe<Scalars['Float']>;
  srid?: Maybe<Scalars['Float']>;
};

/** aggregate var_samp on columns */
export type Geometry_Columns_Var_Samp_Fields = {
  __typename?: 'geometry_columns_var_samp_fields';
  coord_dimension?: Maybe<Scalars['Float']>;
  srid?: Maybe<Scalars['Float']>;
};

/** aggregate variance on columns */
export type Geometry_Columns_Variance_Fields = {
  __typename?: 'geometry_columns_variance_fields';
  coord_dimension?: Maybe<Scalars['Float']>;
  srid?: Maybe<Scalars['Float']>;
};

/** Boolean expression to compare columns of type "geometry". All fields are combined with logical 'AND'. */
export type Geometry_Comparison_Exp = {
  _cast?: Maybe<Geometry_Cast_Exp>;
  _eq?: Maybe<Scalars['geometry']>;
  _gt?: Maybe<Scalars['geometry']>;
  _gte?: Maybe<Scalars['geometry']>;
  _in?: Maybe<Array<Scalars['geometry']>>;
  _is_null?: Maybe<Scalars['Boolean']>;
  _lt?: Maybe<Scalars['geometry']>;
  _lte?: Maybe<Scalars['geometry']>;
  _neq?: Maybe<Scalars['geometry']>;
  _nin?: Maybe<Array<Scalars['geometry']>>;
  /** is the column within a given 3D distance from the given geometry value */
  _st_3d_d_within?: Maybe<St_D_Within_Input>;
  /** does the column spatially intersect the given geometry value in 3D */
  _st_3d_intersects?: Maybe<Scalars['geometry']>;
  /** does the column contain the given geometry value */
  _st_contains?: Maybe<Scalars['geometry']>;
  /** does the column cross the given geometry value */
  _st_crosses?: Maybe<Scalars['geometry']>;
  /** is the column within a given distance from the given geometry value */
  _st_d_within?: Maybe<St_D_Within_Input>;
  /** is the column equal to given geometry value (directionality is ignored) */
  _st_equals?: Maybe<Scalars['geometry']>;
  /** does the column spatially intersect the given geometry value */
  _st_intersects?: Maybe<Scalars['geometry']>;
  /** does the column 'spatially overlap' (intersect but not completely contain) the given geometry value */
  _st_overlaps?: Maybe<Scalars['geometry']>;
  /** does the column have atleast one point in common with the given geometry value */
  _st_touches?: Maybe<Scalars['geometry']>;
  /** is the column contained in the given geometry value */
  _st_within?: Maybe<Scalars['geometry']>;
};


/** Boolean expression to compare columns of type "graduation_timeframe_enums". All fields are combined with logical 'AND'. */
export type Graduation_Timeframe_Enums_Comparison_Exp = {
  _eq?: Maybe<Scalars['graduation_timeframe_enums']>;
  _gt?: Maybe<Scalars['graduation_timeframe_enums']>;
  _gte?: Maybe<Scalars['graduation_timeframe_enums']>;
  _in?: Maybe<Array<Scalars['graduation_timeframe_enums']>>;
  _is_null?: Maybe<Scalars['Boolean']>;
  _lt?: Maybe<Scalars['graduation_timeframe_enums']>;
  _lte?: Maybe<Scalars['graduation_timeframe_enums']>;
  _neq?: Maybe<Scalars['graduation_timeframe_enums']>;
  _nin?: Maybe<Array<Scalars['graduation_timeframe_enums']>>;
};


/** Boolean expression to compare columns of type "highest_level_edu_enums". All fields are combined with logical 'AND'. */
export type Highest_Level_Edu_Enums_Comparison_Exp = {
  _eq?: Maybe<Scalars['highest_level_edu_enums']>;
  _gt?: Maybe<Scalars['highest_level_edu_enums']>;
  _gte?: Maybe<Scalars['highest_level_edu_enums']>;
  _in?: Maybe<Array<Scalars['highest_level_edu_enums']>>;
  _is_null?: Maybe<Scalars['Boolean']>;
  _lt?: Maybe<Scalars['highest_level_edu_enums']>;
  _lte?: Maybe<Scalars['highest_level_edu_enums']>;
  _neq?: Maybe<Scalars['highest_level_edu_enums']>;
  _nin?: Maybe<Array<Scalars['highest_level_edu_enums']>>;
};

/** columns and relationships of "industries" */
export type Industries = {
  __typename?: 'industries';
  /** An array relationship */
  candidate_industries: Array<Candidate_Industries>;
  /** An aggregate relationship */
  candidate_industries_aggregate: Candidate_Industries_Aggregate;
  /** An array relationship */
  client_industries: Array<Client_Industries>;
  /** An aggregate relationship */
  client_industries_aggregate: Client_Industries_Aggregate;
  created_at: Scalars['timestamptz'];
  deleted_at?: Maybe<Scalars['timestamptz']>;
  description?: Maybe<Scalars['String']>;
  id: Scalars['uuid'];
  title: Scalars['String'];
  updated_at: Scalars['timestamptz'];
};


/** columns and relationships of "industries" */
export type IndustriesCandidate_IndustriesArgs = {
  distinct_on?: Maybe<Array<Candidate_Industries_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Candidate_Industries_Order_By>>;
  where?: Maybe<Candidate_Industries_Bool_Exp>;
};


/** columns and relationships of "industries" */
export type IndustriesCandidate_Industries_AggregateArgs = {
  distinct_on?: Maybe<Array<Candidate_Industries_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Candidate_Industries_Order_By>>;
  where?: Maybe<Candidate_Industries_Bool_Exp>;
};


/** columns and relationships of "industries" */
export type IndustriesClient_IndustriesArgs = {
  distinct_on?: Maybe<Array<Client_Industries_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Client_Industries_Order_By>>;
  where?: Maybe<Client_Industries_Bool_Exp>;
};


/** columns and relationships of "industries" */
export type IndustriesClient_Industries_AggregateArgs = {
  distinct_on?: Maybe<Array<Client_Industries_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Client_Industries_Order_By>>;
  where?: Maybe<Client_Industries_Bool_Exp>;
};

/** aggregated selection of "industries" */
export type Industries_Aggregate = {
  __typename?: 'industries_aggregate';
  aggregate?: Maybe<Industries_Aggregate_Fields>;
  nodes: Array<Industries>;
};

/** aggregate fields of "industries" */
export type Industries_Aggregate_Fields = {
  __typename?: 'industries_aggregate_fields';
  count: Scalars['Int'];
  max?: Maybe<Industries_Max_Fields>;
  min?: Maybe<Industries_Min_Fields>;
};


/** aggregate fields of "industries" */
export type Industries_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<Industries_Select_Column>>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** Boolean expression to filter rows from the table "industries". All fields are combined with a logical 'AND'. */
export type Industries_Bool_Exp = {
  _and?: Maybe<Array<Industries_Bool_Exp>>;
  _not?: Maybe<Industries_Bool_Exp>;
  _or?: Maybe<Array<Industries_Bool_Exp>>;
  candidate_industries?: Maybe<Candidate_Industries_Bool_Exp>;
  candidate_industries_aggregate?: Maybe<Candidate_Industries_Aggregate_Bool_Exp>;
  client_industries?: Maybe<Client_Industries_Bool_Exp>;
  client_industries_aggregate?: Maybe<Client_Industries_Aggregate_Bool_Exp>;
  created_at?: Maybe<Timestamptz_Comparison_Exp>;
  deleted_at?: Maybe<Timestamptz_Comparison_Exp>;
  description?: Maybe<String_Comparison_Exp>;
  id?: Maybe<Uuid_Comparison_Exp>;
  title?: Maybe<String_Comparison_Exp>;
  updated_at?: Maybe<Timestamptz_Comparison_Exp>;
};

/** unique or primary key constraints on table "industries" */
export enum Industries_Constraint {
  /** unique or primary key constraint on columns "id" */
  IndustriesPkey = 'industries_pkey'
}

/** input type for inserting data into table "industries" */
export type Industries_Insert_Input = {
  candidate_industries?: Maybe<Candidate_Industries_Arr_Rel_Insert_Input>;
  client_industries?: Maybe<Client_Industries_Arr_Rel_Insert_Input>;
  created_at?: Maybe<Scalars['timestamptz']>;
  deleted_at?: Maybe<Scalars['timestamptz']>;
  description?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['uuid']>;
  title?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** aggregate max on columns */
export type Industries_Max_Fields = {
  __typename?: 'industries_max_fields';
  created_at?: Maybe<Scalars['timestamptz']>;
  deleted_at?: Maybe<Scalars['timestamptz']>;
  description?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['uuid']>;
  title?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** aggregate min on columns */
export type Industries_Min_Fields = {
  __typename?: 'industries_min_fields';
  created_at?: Maybe<Scalars['timestamptz']>;
  deleted_at?: Maybe<Scalars['timestamptz']>;
  description?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['uuid']>;
  title?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** response of any mutation on the table "industries" */
export type Industries_Mutation_Response = {
  __typename?: 'industries_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Industries>;
};

/** input type for inserting object relation for remote table "industries" */
export type Industries_Obj_Rel_Insert_Input = {
  data: Industries_Insert_Input;
  /** upsert condition */
  on_conflict?: Maybe<Industries_On_Conflict>;
};

/** on_conflict condition type for table "industries" */
export type Industries_On_Conflict = {
  constraint: Industries_Constraint;
  update_columns?: Array<Industries_Update_Column>;
  where?: Maybe<Industries_Bool_Exp>;
};

/** Ordering options when selecting data from "industries". */
export type Industries_Order_By = {
  candidate_industries_aggregate?: Maybe<Candidate_Industries_Aggregate_Order_By>;
  client_industries_aggregate?: Maybe<Client_Industries_Aggregate_Order_By>;
  created_at?: Maybe<Order_By>;
  deleted_at?: Maybe<Order_By>;
  description?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  title?: Maybe<Order_By>;
  updated_at?: Maybe<Order_By>;
};

/** primary key columns input for table: industries */
export type Industries_Pk_Columns_Input = {
  id: Scalars['uuid'];
};

/** select columns of table "industries" */
export enum Industries_Select_Column {
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  DeletedAt = 'deleted_at',
  /** column name */
  Description = 'description',
  /** column name */
  Id = 'id',
  /** column name */
  Title = 'title',
  /** column name */
  UpdatedAt = 'updated_at'
}

/** input type for updating data in table "industries" */
export type Industries_Set_Input = {
  created_at?: Maybe<Scalars['timestamptz']>;
  deleted_at?: Maybe<Scalars['timestamptz']>;
  description?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['uuid']>;
  title?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** Streaming cursor of the table "industries" */
export type Industries_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Industries_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: Maybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Industries_Stream_Cursor_Value_Input = {
  created_at?: Maybe<Scalars['timestamptz']>;
  deleted_at?: Maybe<Scalars['timestamptz']>;
  description?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['uuid']>;
  title?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** update columns of table "industries" */
export enum Industries_Update_Column {
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  DeletedAt = 'deleted_at',
  /** column name */
  Description = 'description',
  /** column name */
  Id = 'id',
  /** column name */
  Title = 'title',
  /** column name */
  UpdatedAt = 'updated_at'
}

export type Industries_Updates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: Maybe<Industries_Set_Input>;
  /** filter the rows which have to be updated */
  where: Industries_Bool_Exp;
};


/** Boolean expression to compare columns of type "interview_mode_enums". All fields are combined with logical 'AND'. */
export type Interview_Mode_Enums_Comparison_Exp = {
  _eq?: Maybe<Scalars['interview_mode_enums']>;
  _gt?: Maybe<Scalars['interview_mode_enums']>;
  _gte?: Maybe<Scalars['interview_mode_enums']>;
  _in?: Maybe<Array<Scalars['interview_mode_enums']>>;
  _is_null?: Maybe<Scalars['Boolean']>;
  _lt?: Maybe<Scalars['interview_mode_enums']>;
  _lte?: Maybe<Scalars['interview_mode_enums']>;
  _neq?: Maybe<Scalars['interview_mode_enums']>;
  _nin?: Maybe<Array<Scalars['interview_mode_enums']>>;
};


/** Boolean expression to compare columns of type "interview_outcome_enums". All fields are combined with logical 'AND'. */
export type Interview_Outcome_Enums_Comparison_Exp = {
  _eq?: Maybe<Scalars['interview_outcome_enums']>;
  _gt?: Maybe<Scalars['interview_outcome_enums']>;
  _gte?: Maybe<Scalars['interview_outcome_enums']>;
  _in?: Maybe<Array<Scalars['interview_outcome_enums']>>;
  _is_null?: Maybe<Scalars['Boolean']>;
  _lt?: Maybe<Scalars['interview_outcome_enums']>;
  _lte?: Maybe<Scalars['interview_outcome_enums']>;
  _neq?: Maybe<Scalars['interview_outcome_enums']>;
  _nin?: Maybe<Array<Scalars['interview_outcome_enums']>>;
};

/** columns and relationships of "interview_participants" */
export type Interview_Participants = {
  __typename?: 'interview_participants';
  accepted?: Maybe<Scalars['Boolean']>;
  /** An object relationship */
  candidate?: Maybe<Candidates>;
  candidate_id?: Maybe<Scalars['uuid']>;
  /** An object relationship */
  client?: Maybe<Clients>;
  client_id?: Maybe<Scalars['uuid']>;
  created_at: Scalars['timestamptz'];
  declined?: Maybe<Scalars['Boolean']>;
  deleted_at?: Maybe<Scalars['timestamptz']>;
  id: Scalars['uuid'];
  /** An object relationship */
  interview?: Maybe<Interviews>;
  interview_id?: Maybe<Scalars['uuid']>;
  reason_to_decline?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** aggregated selection of "interview_participants" */
export type Interview_Participants_Aggregate = {
  __typename?: 'interview_participants_aggregate';
  aggregate?: Maybe<Interview_Participants_Aggregate_Fields>;
  nodes: Array<Interview_Participants>;
};

export type Interview_Participants_Aggregate_Bool_Exp = {
  bool_and?: Maybe<Interview_Participants_Aggregate_Bool_Exp_Bool_And>;
  bool_or?: Maybe<Interview_Participants_Aggregate_Bool_Exp_Bool_Or>;
  count?: Maybe<Interview_Participants_Aggregate_Bool_Exp_Count>;
};

export type Interview_Participants_Aggregate_Bool_Exp_Bool_And = {
  arguments: Interview_Participants_Select_Column_Interview_Participants_Aggregate_Bool_Exp_Bool_And_Arguments_Columns;
  distinct?: Maybe<Scalars['Boolean']>;
  filter?: Maybe<Interview_Participants_Bool_Exp>;
  predicate: Boolean_Comparison_Exp;
};

export type Interview_Participants_Aggregate_Bool_Exp_Bool_Or = {
  arguments: Interview_Participants_Select_Column_Interview_Participants_Aggregate_Bool_Exp_Bool_Or_Arguments_Columns;
  distinct?: Maybe<Scalars['Boolean']>;
  filter?: Maybe<Interview_Participants_Bool_Exp>;
  predicate: Boolean_Comparison_Exp;
};

export type Interview_Participants_Aggregate_Bool_Exp_Count = {
  arguments?: Maybe<Array<Interview_Participants_Select_Column>>;
  distinct?: Maybe<Scalars['Boolean']>;
  filter?: Maybe<Interview_Participants_Bool_Exp>;
  predicate: Int_Comparison_Exp;
};

/** aggregate fields of "interview_participants" */
export type Interview_Participants_Aggregate_Fields = {
  __typename?: 'interview_participants_aggregate_fields';
  count: Scalars['Int'];
  max?: Maybe<Interview_Participants_Max_Fields>;
  min?: Maybe<Interview_Participants_Min_Fields>;
};


/** aggregate fields of "interview_participants" */
export type Interview_Participants_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<Interview_Participants_Select_Column>>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "interview_participants" */
export type Interview_Participants_Aggregate_Order_By = {
  count?: Maybe<Order_By>;
  max?: Maybe<Interview_Participants_Max_Order_By>;
  min?: Maybe<Interview_Participants_Min_Order_By>;
};

/** input type for inserting array relation for remote table "interview_participants" */
export type Interview_Participants_Arr_Rel_Insert_Input = {
  data: Array<Interview_Participants_Insert_Input>;
  /** upsert condition */
  on_conflict?: Maybe<Interview_Participants_On_Conflict>;
};

/** Boolean expression to filter rows from the table "interview_participants". All fields are combined with a logical 'AND'. */
export type Interview_Participants_Bool_Exp = {
  _and?: Maybe<Array<Interview_Participants_Bool_Exp>>;
  _not?: Maybe<Interview_Participants_Bool_Exp>;
  _or?: Maybe<Array<Interview_Participants_Bool_Exp>>;
  accepted?: Maybe<Boolean_Comparison_Exp>;
  candidate?: Maybe<Candidates_Bool_Exp>;
  candidate_id?: Maybe<Uuid_Comparison_Exp>;
  client?: Maybe<Clients_Bool_Exp>;
  client_id?: Maybe<Uuid_Comparison_Exp>;
  created_at?: Maybe<Timestamptz_Comparison_Exp>;
  declined?: Maybe<Boolean_Comparison_Exp>;
  deleted_at?: Maybe<Timestamptz_Comparison_Exp>;
  id?: Maybe<Uuid_Comparison_Exp>;
  interview?: Maybe<Interviews_Bool_Exp>;
  interview_id?: Maybe<Uuid_Comparison_Exp>;
  reason_to_decline?: Maybe<String_Comparison_Exp>;
  updated_at?: Maybe<Timestamptz_Comparison_Exp>;
};

/** unique or primary key constraints on table "interview_participants" */
export enum Interview_Participants_Constraint {
  /** unique or primary key constraint on columns "id" */
  InterviewParticipantsPkey = 'interview_participants_pkey'
}

/** input type for inserting data into table "interview_participants" */
export type Interview_Participants_Insert_Input = {
  accepted?: Maybe<Scalars['Boolean']>;
  candidate?: Maybe<Candidates_Obj_Rel_Insert_Input>;
  candidate_id?: Maybe<Scalars['uuid']>;
  client?: Maybe<Clients_Obj_Rel_Insert_Input>;
  client_id?: Maybe<Scalars['uuid']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  declined?: Maybe<Scalars['Boolean']>;
  deleted_at?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['uuid']>;
  interview?: Maybe<Interviews_Obj_Rel_Insert_Input>;
  interview_id?: Maybe<Scalars['uuid']>;
  reason_to_decline?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** aggregate max on columns */
export type Interview_Participants_Max_Fields = {
  __typename?: 'interview_participants_max_fields';
  candidate_id?: Maybe<Scalars['uuid']>;
  client_id?: Maybe<Scalars['uuid']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  deleted_at?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['uuid']>;
  interview_id?: Maybe<Scalars['uuid']>;
  reason_to_decline?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** order by max() on columns of table "interview_participants" */
export type Interview_Participants_Max_Order_By = {
  candidate_id?: Maybe<Order_By>;
  client_id?: Maybe<Order_By>;
  created_at?: Maybe<Order_By>;
  deleted_at?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  interview_id?: Maybe<Order_By>;
  reason_to_decline?: Maybe<Order_By>;
  updated_at?: Maybe<Order_By>;
};

/** aggregate min on columns */
export type Interview_Participants_Min_Fields = {
  __typename?: 'interview_participants_min_fields';
  candidate_id?: Maybe<Scalars['uuid']>;
  client_id?: Maybe<Scalars['uuid']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  deleted_at?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['uuid']>;
  interview_id?: Maybe<Scalars['uuid']>;
  reason_to_decline?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** order by min() on columns of table "interview_participants" */
export type Interview_Participants_Min_Order_By = {
  candidate_id?: Maybe<Order_By>;
  client_id?: Maybe<Order_By>;
  created_at?: Maybe<Order_By>;
  deleted_at?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  interview_id?: Maybe<Order_By>;
  reason_to_decline?: Maybe<Order_By>;
  updated_at?: Maybe<Order_By>;
};

/** response of any mutation on the table "interview_participants" */
export type Interview_Participants_Mutation_Response = {
  __typename?: 'interview_participants_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Interview_Participants>;
};

/** on_conflict condition type for table "interview_participants" */
export type Interview_Participants_On_Conflict = {
  constraint: Interview_Participants_Constraint;
  update_columns?: Array<Interview_Participants_Update_Column>;
  where?: Maybe<Interview_Participants_Bool_Exp>;
};

/** Ordering options when selecting data from "interview_participants". */
export type Interview_Participants_Order_By = {
  accepted?: Maybe<Order_By>;
  candidate?: Maybe<Candidates_Order_By>;
  candidate_id?: Maybe<Order_By>;
  client?: Maybe<Clients_Order_By>;
  client_id?: Maybe<Order_By>;
  created_at?: Maybe<Order_By>;
  declined?: Maybe<Order_By>;
  deleted_at?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  interview?: Maybe<Interviews_Order_By>;
  interview_id?: Maybe<Order_By>;
  reason_to_decline?: Maybe<Order_By>;
  updated_at?: Maybe<Order_By>;
};

/** primary key columns input for table: interview_participants */
export type Interview_Participants_Pk_Columns_Input = {
  id: Scalars['uuid'];
};

/** select columns of table "interview_participants" */
export enum Interview_Participants_Select_Column {
  /** column name */
  Accepted = 'accepted',
  /** column name */
  CandidateId = 'candidate_id',
  /** column name */
  ClientId = 'client_id',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Declined = 'declined',
  /** column name */
  DeletedAt = 'deleted_at',
  /** column name */
  Id = 'id',
  /** column name */
  InterviewId = 'interview_id',
  /** column name */
  ReasonToDecline = 'reason_to_decline',
  /** column name */
  UpdatedAt = 'updated_at'
}

/** select "interview_participants_aggregate_bool_exp_bool_and_arguments_columns" columns of table "interview_participants" */
export enum Interview_Participants_Select_Column_Interview_Participants_Aggregate_Bool_Exp_Bool_And_Arguments_Columns {
  /** column name */
  Accepted = 'accepted',
  /** column name */
  Declined = 'declined'
}

/** select "interview_participants_aggregate_bool_exp_bool_or_arguments_columns" columns of table "interview_participants" */
export enum Interview_Participants_Select_Column_Interview_Participants_Aggregate_Bool_Exp_Bool_Or_Arguments_Columns {
  /** column name */
  Accepted = 'accepted',
  /** column name */
  Declined = 'declined'
}

/** input type for updating data in table "interview_participants" */
export type Interview_Participants_Set_Input = {
  accepted?: Maybe<Scalars['Boolean']>;
  candidate_id?: Maybe<Scalars['uuid']>;
  client_id?: Maybe<Scalars['uuid']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  declined?: Maybe<Scalars['Boolean']>;
  deleted_at?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['uuid']>;
  interview_id?: Maybe<Scalars['uuid']>;
  reason_to_decline?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** Streaming cursor of the table "interview_participants" */
export type Interview_Participants_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Interview_Participants_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: Maybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Interview_Participants_Stream_Cursor_Value_Input = {
  accepted?: Maybe<Scalars['Boolean']>;
  candidate_id?: Maybe<Scalars['uuid']>;
  client_id?: Maybe<Scalars['uuid']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  declined?: Maybe<Scalars['Boolean']>;
  deleted_at?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['uuid']>;
  interview_id?: Maybe<Scalars['uuid']>;
  reason_to_decline?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** update columns of table "interview_participants" */
export enum Interview_Participants_Update_Column {
  /** column name */
  Accepted = 'accepted',
  /** column name */
  CandidateId = 'candidate_id',
  /** column name */
  ClientId = 'client_id',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Declined = 'declined',
  /** column name */
  DeletedAt = 'deleted_at',
  /** column name */
  Id = 'id',
  /** column name */
  InterviewId = 'interview_id',
  /** column name */
  ReasonToDecline = 'reason_to_decline',
  /** column name */
  UpdatedAt = 'updated_at'
}

export type Interview_Participants_Updates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: Maybe<Interview_Participants_Set_Input>;
  /** filter the rows which have to be updated */
  where: Interview_Participants_Bool_Exp;
};


/** Boolean expression to compare columns of type "interview_status_enums". All fields are combined with logical 'AND'. */
export type Interview_Status_Enums_Comparison_Exp = {
  _eq?: Maybe<Scalars['interview_status_enums']>;
  _gt?: Maybe<Scalars['interview_status_enums']>;
  _gte?: Maybe<Scalars['interview_status_enums']>;
  _in?: Maybe<Array<Scalars['interview_status_enums']>>;
  _is_null?: Maybe<Scalars['Boolean']>;
  _lt?: Maybe<Scalars['interview_status_enums']>;
  _lte?: Maybe<Scalars['interview_status_enums']>;
  _neq?: Maybe<Scalars['interview_status_enums']>;
  _nin?: Maybe<Array<Scalars['interview_status_enums']>>;
};


/** Boolean expression to compare columns of type "interview_type_enums". All fields are combined with logical 'AND'. */
export type Interview_Type_Enums_Comparison_Exp = {
  _eq?: Maybe<Scalars['interview_type_enums']>;
  _gt?: Maybe<Scalars['interview_type_enums']>;
  _gte?: Maybe<Scalars['interview_type_enums']>;
  _in?: Maybe<Array<Scalars['interview_type_enums']>>;
  _is_null?: Maybe<Scalars['Boolean']>;
  _lt?: Maybe<Scalars['interview_type_enums']>;
  _lte?: Maybe<Scalars['interview_type_enums']>;
  _neq?: Maybe<Scalars['interview_type_enums']>;
  _nin?: Maybe<Array<Scalars['interview_type_enums']>>;
};

/** columns and relationships of "interviews" */
export type Interviews = {
  __typename?: 'interviews';
  created_at: Scalars['timestamptz'];
  deleted_at?: Maybe<Scalars['timestamptz']>;
  edit_hash?: Maybe<Scalars['String']>;
  /** An array relationship */
  events: Array<Events>;
  /** An aggregate relationship */
  events_aggregate: Events_Aggregate;
  id: Scalars['uuid'];
  interview_notes?: Maybe<Scalars['String']>;
  /** An array relationship */
  interview_participants: Array<Interview_Participants>;
  /** An aggregate relationship */
  interview_participants_aggregate: Interview_Participants_Aggregate;
  /** An object relationship */
  job?: Maybe<Jobs>;
  job_id?: Maybe<Scalars['uuid']>;
  location?: Maybe<Scalars['String']>;
  meeting_link?: Maybe<Scalars['String']>;
  metadata?: Maybe<Scalars['jsonb']>;
  mode: Scalars['interview_mode_enums'];
  nylas_event_id?: Maybe<Scalars['String']>;
  outcome: Scalars['interview_outcome_enums'];
  /** An object relationship */
  owner?: Maybe<Users>;
  owner_id?: Maybe<Scalars['String']>;
  phone?: Maybe<Scalars['String']>;
  rescheduled?: Maybe<Scalars['Boolean']>;
  scheduled_on?: Maybe<Scalars['timestamptz']>;
  status: Scalars['interview_status_enums'];
  time_zone?: Maybe<Scalars['String']>;
  type: Scalars['interview_type_enums'];
  updated_at?: Maybe<Scalars['timestamptz']>;
};


/** columns and relationships of "interviews" */
export type InterviewsEventsArgs = {
  distinct_on?: Maybe<Array<Events_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Events_Order_By>>;
  where?: Maybe<Events_Bool_Exp>;
};


/** columns and relationships of "interviews" */
export type InterviewsEvents_AggregateArgs = {
  distinct_on?: Maybe<Array<Events_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Events_Order_By>>;
  where?: Maybe<Events_Bool_Exp>;
};


/** columns and relationships of "interviews" */
export type InterviewsInterview_ParticipantsArgs = {
  distinct_on?: Maybe<Array<Interview_Participants_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Interview_Participants_Order_By>>;
  where?: Maybe<Interview_Participants_Bool_Exp>;
};


/** columns and relationships of "interviews" */
export type InterviewsInterview_Participants_AggregateArgs = {
  distinct_on?: Maybe<Array<Interview_Participants_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Interview_Participants_Order_By>>;
  where?: Maybe<Interview_Participants_Bool_Exp>;
};


/** columns and relationships of "interviews" */
export type InterviewsMetadataArgs = {
  path?: Maybe<Scalars['String']>;
};

/** aggregated selection of "interviews" */
export type Interviews_Aggregate = {
  __typename?: 'interviews_aggregate';
  aggregate?: Maybe<Interviews_Aggregate_Fields>;
  nodes: Array<Interviews>;
};

export type Interviews_Aggregate_Bool_Exp = {
  bool_and?: Maybe<Interviews_Aggregate_Bool_Exp_Bool_And>;
  bool_or?: Maybe<Interviews_Aggregate_Bool_Exp_Bool_Or>;
  count?: Maybe<Interviews_Aggregate_Bool_Exp_Count>;
};

export type Interviews_Aggregate_Bool_Exp_Bool_And = {
  arguments: Interviews_Select_Column_Interviews_Aggregate_Bool_Exp_Bool_And_Arguments_Columns;
  distinct?: Maybe<Scalars['Boolean']>;
  filter?: Maybe<Interviews_Bool_Exp>;
  predicate: Boolean_Comparison_Exp;
};

export type Interviews_Aggregate_Bool_Exp_Bool_Or = {
  arguments: Interviews_Select_Column_Interviews_Aggregate_Bool_Exp_Bool_Or_Arguments_Columns;
  distinct?: Maybe<Scalars['Boolean']>;
  filter?: Maybe<Interviews_Bool_Exp>;
  predicate: Boolean_Comparison_Exp;
};

export type Interviews_Aggregate_Bool_Exp_Count = {
  arguments?: Maybe<Array<Interviews_Select_Column>>;
  distinct?: Maybe<Scalars['Boolean']>;
  filter?: Maybe<Interviews_Bool_Exp>;
  predicate: Int_Comparison_Exp;
};

/** aggregate fields of "interviews" */
export type Interviews_Aggregate_Fields = {
  __typename?: 'interviews_aggregate_fields';
  count: Scalars['Int'];
  max?: Maybe<Interviews_Max_Fields>;
  min?: Maybe<Interviews_Min_Fields>;
};


/** aggregate fields of "interviews" */
export type Interviews_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<Interviews_Select_Column>>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "interviews" */
export type Interviews_Aggregate_Order_By = {
  count?: Maybe<Order_By>;
  max?: Maybe<Interviews_Max_Order_By>;
  min?: Maybe<Interviews_Min_Order_By>;
};

/** append existing jsonb value of filtered columns with new jsonb value */
export type Interviews_Append_Input = {
  metadata?: Maybe<Scalars['jsonb']>;
};

/** input type for inserting array relation for remote table "interviews" */
export type Interviews_Arr_Rel_Insert_Input = {
  data: Array<Interviews_Insert_Input>;
  /** upsert condition */
  on_conflict?: Maybe<Interviews_On_Conflict>;
};

/** Boolean expression to filter rows from the table "interviews". All fields are combined with a logical 'AND'. */
export type Interviews_Bool_Exp = {
  _and?: Maybe<Array<Interviews_Bool_Exp>>;
  _not?: Maybe<Interviews_Bool_Exp>;
  _or?: Maybe<Array<Interviews_Bool_Exp>>;
  created_at?: Maybe<Timestamptz_Comparison_Exp>;
  deleted_at?: Maybe<Timestamptz_Comparison_Exp>;
  edit_hash?: Maybe<String_Comparison_Exp>;
  events?: Maybe<Events_Bool_Exp>;
  events_aggregate?: Maybe<Events_Aggregate_Bool_Exp>;
  id?: Maybe<Uuid_Comparison_Exp>;
  interview_notes?: Maybe<String_Comparison_Exp>;
  interview_participants?: Maybe<Interview_Participants_Bool_Exp>;
  interview_participants_aggregate?: Maybe<Interview_Participants_Aggregate_Bool_Exp>;
  job?: Maybe<Jobs_Bool_Exp>;
  job_id?: Maybe<Uuid_Comparison_Exp>;
  location?: Maybe<String_Comparison_Exp>;
  meeting_link?: Maybe<String_Comparison_Exp>;
  metadata?: Maybe<Jsonb_Comparison_Exp>;
  mode?: Maybe<Interview_Mode_Enums_Comparison_Exp>;
  nylas_event_id?: Maybe<String_Comparison_Exp>;
  outcome?: Maybe<Interview_Outcome_Enums_Comparison_Exp>;
  owner?: Maybe<Users_Bool_Exp>;
  owner_id?: Maybe<String_Comparison_Exp>;
  phone?: Maybe<String_Comparison_Exp>;
  rescheduled?: Maybe<Boolean_Comparison_Exp>;
  scheduled_on?: Maybe<Timestamptz_Comparison_Exp>;
  status?: Maybe<Interview_Status_Enums_Comparison_Exp>;
  time_zone?: Maybe<String_Comparison_Exp>;
  type?: Maybe<Interview_Type_Enums_Comparison_Exp>;
  updated_at?: Maybe<Timestamptz_Comparison_Exp>;
};

/** unique or primary key constraints on table "interviews" */
export enum Interviews_Constraint {
  /** unique or primary key constraint on columns "id" */
  InterviewsPkey = 'interviews_pkey'
}

/** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
export type Interviews_Delete_At_Path_Input = {
  metadata?: Maybe<Array<Scalars['String']>>;
};

/** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
export type Interviews_Delete_Elem_Input = {
  metadata?: Maybe<Scalars['Int']>;
};

/** delete key/value pair or string element. key/value pairs are matched based on their key value */
export type Interviews_Delete_Key_Input = {
  metadata?: Maybe<Scalars['String']>;
};

/** input type for inserting data into table "interviews" */
export type Interviews_Insert_Input = {
  created_at?: Maybe<Scalars['timestamptz']>;
  deleted_at?: Maybe<Scalars['timestamptz']>;
  edit_hash?: Maybe<Scalars['String']>;
  events?: Maybe<Events_Arr_Rel_Insert_Input>;
  id?: Maybe<Scalars['uuid']>;
  interview_notes?: Maybe<Scalars['String']>;
  interview_participants?: Maybe<Interview_Participants_Arr_Rel_Insert_Input>;
  job?: Maybe<Jobs_Obj_Rel_Insert_Input>;
  job_id?: Maybe<Scalars['uuid']>;
  location?: Maybe<Scalars['String']>;
  meeting_link?: Maybe<Scalars['String']>;
  metadata?: Maybe<Scalars['jsonb']>;
  mode?: Maybe<Scalars['interview_mode_enums']>;
  nylas_event_id?: Maybe<Scalars['String']>;
  outcome?: Maybe<Scalars['interview_outcome_enums']>;
  owner?: Maybe<Users_Obj_Rel_Insert_Input>;
  owner_id?: Maybe<Scalars['String']>;
  phone?: Maybe<Scalars['String']>;
  rescheduled?: Maybe<Scalars['Boolean']>;
  scheduled_on?: Maybe<Scalars['timestamptz']>;
  status?: Maybe<Scalars['interview_status_enums']>;
  time_zone?: Maybe<Scalars['String']>;
  type?: Maybe<Scalars['interview_type_enums']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** aggregate max on columns */
export type Interviews_Max_Fields = {
  __typename?: 'interviews_max_fields';
  created_at?: Maybe<Scalars['timestamptz']>;
  deleted_at?: Maybe<Scalars['timestamptz']>;
  edit_hash?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['uuid']>;
  interview_notes?: Maybe<Scalars['String']>;
  job_id?: Maybe<Scalars['uuid']>;
  location?: Maybe<Scalars['String']>;
  meeting_link?: Maybe<Scalars['String']>;
  mode?: Maybe<Scalars['interview_mode_enums']>;
  nylas_event_id?: Maybe<Scalars['String']>;
  outcome?: Maybe<Scalars['interview_outcome_enums']>;
  owner_id?: Maybe<Scalars['String']>;
  phone?: Maybe<Scalars['String']>;
  scheduled_on?: Maybe<Scalars['timestamptz']>;
  status?: Maybe<Scalars['interview_status_enums']>;
  time_zone?: Maybe<Scalars['String']>;
  type?: Maybe<Scalars['interview_type_enums']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** order by max() on columns of table "interviews" */
export type Interviews_Max_Order_By = {
  created_at?: Maybe<Order_By>;
  deleted_at?: Maybe<Order_By>;
  edit_hash?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  interview_notes?: Maybe<Order_By>;
  job_id?: Maybe<Order_By>;
  location?: Maybe<Order_By>;
  meeting_link?: Maybe<Order_By>;
  mode?: Maybe<Order_By>;
  nylas_event_id?: Maybe<Order_By>;
  outcome?: Maybe<Order_By>;
  owner_id?: Maybe<Order_By>;
  phone?: Maybe<Order_By>;
  scheduled_on?: Maybe<Order_By>;
  status?: Maybe<Order_By>;
  time_zone?: Maybe<Order_By>;
  type?: Maybe<Order_By>;
  updated_at?: Maybe<Order_By>;
};

/** aggregate min on columns */
export type Interviews_Min_Fields = {
  __typename?: 'interviews_min_fields';
  created_at?: Maybe<Scalars['timestamptz']>;
  deleted_at?: Maybe<Scalars['timestamptz']>;
  edit_hash?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['uuid']>;
  interview_notes?: Maybe<Scalars['String']>;
  job_id?: Maybe<Scalars['uuid']>;
  location?: Maybe<Scalars['String']>;
  meeting_link?: Maybe<Scalars['String']>;
  mode?: Maybe<Scalars['interview_mode_enums']>;
  nylas_event_id?: Maybe<Scalars['String']>;
  outcome?: Maybe<Scalars['interview_outcome_enums']>;
  owner_id?: Maybe<Scalars['String']>;
  phone?: Maybe<Scalars['String']>;
  scheduled_on?: Maybe<Scalars['timestamptz']>;
  status?: Maybe<Scalars['interview_status_enums']>;
  time_zone?: Maybe<Scalars['String']>;
  type?: Maybe<Scalars['interview_type_enums']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** order by min() on columns of table "interviews" */
export type Interviews_Min_Order_By = {
  created_at?: Maybe<Order_By>;
  deleted_at?: Maybe<Order_By>;
  edit_hash?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  interview_notes?: Maybe<Order_By>;
  job_id?: Maybe<Order_By>;
  location?: Maybe<Order_By>;
  meeting_link?: Maybe<Order_By>;
  mode?: Maybe<Order_By>;
  nylas_event_id?: Maybe<Order_By>;
  outcome?: Maybe<Order_By>;
  owner_id?: Maybe<Order_By>;
  phone?: Maybe<Order_By>;
  scheduled_on?: Maybe<Order_By>;
  status?: Maybe<Order_By>;
  time_zone?: Maybe<Order_By>;
  type?: Maybe<Order_By>;
  updated_at?: Maybe<Order_By>;
};

/** response of any mutation on the table "interviews" */
export type Interviews_Mutation_Response = {
  __typename?: 'interviews_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Interviews>;
};

/** input type for inserting object relation for remote table "interviews" */
export type Interviews_Obj_Rel_Insert_Input = {
  data: Interviews_Insert_Input;
  /** upsert condition */
  on_conflict?: Maybe<Interviews_On_Conflict>;
};

/** on_conflict condition type for table "interviews" */
export type Interviews_On_Conflict = {
  constraint: Interviews_Constraint;
  update_columns?: Array<Interviews_Update_Column>;
  where?: Maybe<Interviews_Bool_Exp>;
};

/** Ordering options when selecting data from "interviews". */
export type Interviews_Order_By = {
  created_at?: Maybe<Order_By>;
  deleted_at?: Maybe<Order_By>;
  edit_hash?: Maybe<Order_By>;
  events_aggregate?: Maybe<Events_Aggregate_Order_By>;
  id?: Maybe<Order_By>;
  interview_notes?: Maybe<Order_By>;
  interview_participants_aggregate?: Maybe<Interview_Participants_Aggregate_Order_By>;
  job?: Maybe<Jobs_Order_By>;
  job_id?: Maybe<Order_By>;
  location?: Maybe<Order_By>;
  meeting_link?: Maybe<Order_By>;
  metadata?: Maybe<Order_By>;
  mode?: Maybe<Order_By>;
  nylas_event_id?: Maybe<Order_By>;
  outcome?: Maybe<Order_By>;
  owner?: Maybe<Users_Order_By>;
  owner_id?: Maybe<Order_By>;
  phone?: Maybe<Order_By>;
  rescheduled?: Maybe<Order_By>;
  scheduled_on?: Maybe<Order_By>;
  status?: Maybe<Order_By>;
  time_zone?: Maybe<Order_By>;
  type?: Maybe<Order_By>;
  updated_at?: Maybe<Order_By>;
};

/** primary key columns input for table: interviews */
export type Interviews_Pk_Columns_Input = {
  id: Scalars['uuid'];
};

/** prepend existing jsonb value of filtered columns with new jsonb value */
export type Interviews_Prepend_Input = {
  metadata?: Maybe<Scalars['jsonb']>;
};

/** select columns of table "interviews" */
export enum Interviews_Select_Column {
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  DeletedAt = 'deleted_at',
  /** column name */
  EditHash = 'edit_hash',
  /** column name */
  Id = 'id',
  /** column name */
  InterviewNotes = 'interview_notes',
  /** column name */
  JobId = 'job_id',
  /** column name */
  Location = 'location',
  /** column name */
  MeetingLink = 'meeting_link',
  /** column name */
  Metadata = 'metadata',
  /** column name */
  Mode = 'mode',
  /** column name */
  NylasEventId = 'nylas_event_id',
  /** column name */
  Outcome = 'outcome',
  /** column name */
  OwnerId = 'owner_id',
  /** column name */
  Phone = 'phone',
  /** column name */
  Rescheduled = 'rescheduled',
  /** column name */
  ScheduledOn = 'scheduled_on',
  /** column name */
  Status = 'status',
  /** column name */
  TimeZone = 'time_zone',
  /** column name */
  Type = 'type',
  /** column name */
  UpdatedAt = 'updated_at'
}

/** select "interviews_aggregate_bool_exp_bool_and_arguments_columns" columns of table "interviews" */
export enum Interviews_Select_Column_Interviews_Aggregate_Bool_Exp_Bool_And_Arguments_Columns {
  /** column name */
  Rescheduled = 'rescheduled'
}

/** select "interviews_aggregate_bool_exp_bool_or_arguments_columns" columns of table "interviews" */
export enum Interviews_Select_Column_Interviews_Aggregate_Bool_Exp_Bool_Or_Arguments_Columns {
  /** column name */
  Rescheduled = 'rescheduled'
}

/** input type for updating data in table "interviews" */
export type Interviews_Set_Input = {
  created_at?: Maybe<Scalars['timestamptz']>;
  deleted_at?: Maybe<Scalars['timestamptz']>;
  edit_hash?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['uuid']>;
  interview_notes?: Maybe<Scalars['String']>;
  job_id?: Maybe<Scalars['uuid']>;
  location?: Maybe<Scalars['String']>;
  meeting_link?: Maybe<Scalars['String']>;
  metadata?: Maybe<Scalars['jsonb']>;
  mode?: Maybe<Scalars['interview_mode_enums']>;
  nylas_event_id?: Maybe<Scalars['String']>;
  outcome?: Maybe<Scalars['interview_outcome_enums']>;
  owner_id?: Maybe<Scalars['String']>;
  phone?: Maybe<Scalars['String']>;
  rescheduled?: Maybe<Scalars['Boolean']>;
  scheduled_on?: Maybe<Scalars['timestamptz']>;
  status?: Maybe<Scalars['interview_status_enums']>;
  time_zone?: Maybe<Scalars['String']>;
  type?: Maybe<Scalars['interview_type_enums']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** Streaming cursor of the table "interviews" */
export type Interviews_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Interviews_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: Maybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Interviews_Stream_Cursor_Value_Input = {
  created_at?: Maybe<Scalars['timestamptz']>;
  deleted_at?: Maybe<Scalars['timestamptz']>;
  edit_hash?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['uuid']>;
  interview_notes?: Maybe<Scalars['String']>;
  job_id?: Maybe<Scalars['uuid']>;
  location?: Maybe<Scalars['String']>;
  meeting_link?: Maybe<Scalars['String']>;
  metadata?: Maybe<Scalars['jsonb']>;
  mode?: Maybe<Scalars['interview_mode_enums']>;
  nylas_event_id?: Maybe<Scalars['String']>;
  outcome?: Maybe<Scalars['interview_outcome_enums']>;
  owner_id?: Maybe<Scalars['String']>;
  phone?: Maybe<Scalars['String']>;
  rescheduled?: Maybe<Scalars['Boolean']>;
  scheduled_on?: Maybe<Scalars['timestamptz']>;
  status?: Maybe<Scalars['interview_status_enums']>;
  time_zone?: Maybe<Scalars['String']>;
  type?: Maybe<Scalars['interview_type_enums']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** update columns of table "interviews" */
export enum Interviews_Update_Column {
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  DeletedAt = 'deleted_at',
  /** column name */
  EditHash = 'edit_hash',
  /** column name */
  Id = 'id',
  /** column name */
  InterviewNotes = 'interview_notes',
  /** column name */
  JobId = 'job_id',
  /** column name */
  Location = 'location',
  /** column name */
  MeetingLink = 'meeting_link',
  /** column name */
  Metadata = 'metadata',
  /** column name */
  Mode = 'mode',
  /** column name */
  NylasEventId = 'nylas_event_id',
  /** column name */
  Outcome = 'outcome',
  /** column name */
  OwnerId = 'owner_id',
  /** column name */
  Phone = 'phone',
  /** column name */
  Rescheduled = 'rescheduled',
  /** column name */
  ScheduledOn = 'scheduled_on',
  /** column name */
  Status = 'status',
  /** column name */
  TimeZone = 'time_zone',
  /** column name */
  Type = 'type',
  /** column name */
  UpdatedAt = 'updated_at'
}

export type Interviews_Updates = {
  /** append existing jsonb value of filtered columns with new jsonb value */
  _append?: Maybe<Interviews_Append_Input>;
  /** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
  _delete_at_path?: Maybe<Interviews_Delete_At_Path_Input>;
  /** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
  _delete_elem?: Maybe<Interviews_Delete_Elem_Input>;
  /** delete key/value pair or string element. key/value pairs are matched based on their key value */
  _delete_key?: Maybe<Interviews_Delete_Key_Input>;
  /** prepend existing jsonb value of filtered columns with new jsonb value */
  _prepend?: Maybe<Interviews_Prepend_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: Maybe<Interviews_Set_Input>;
  /** filter the rows which have to be updated */
  where: Interviews_Bool_Exp;
};

/** columns and relationships of "job_candidates" */
export type Job_Candidates = {
  __typename?: 'job_candidates';
  /** An object relationship */
  candidate: Candidates;
  candidate_id: Scalars['uuid'];
  created_at: Scalars['timestamptz'];
  deleted_at?: Maybe<Scalars['timestamptz']>;
  id: Scalars['uuid'];
  /** An object relationship */
  job: Jobs;
  job_id: Scalars['uuid'];
  updated_at: Scalars['timestamptz'];
};

/** aggregated selection of "job_candidates" */
export type Job_Candidates_Aggregate = {
  __typename?: 'job_candidates_aggregate';
  aggregate?: Maybe<Job_Candidates_Aggregate_Fields>;
  nodes: Array<Job_Candidates>;
};

export type Job_Candidates_Aggregate_Bool_Exp = {
  count?: Maybe<Job_Candidates_Aggregate_Bool_Exp_Count>;
};

export type Job_Candidates_Aggregate_Bool_Exp_Count = {
  arguments?: Maybe<Array<Job_Candidates_Select_Column>>;
  distinct?: Maybe<Scalars['Boolean']>;
  filter?: Maybe<Job_Candidates_Bool_Exp>;
  predicate: Int_Comparison_Exp;
};

/** aggregate fields of "job_candidates" */
export type Job_Candidates_Aggregate_Fields = {
  __typename?: 'job_candidates_aggregate_fields';
  count: Scalars['Int'];
  max?: Maybe<Job_Candidates_Max_Fields>;
  min?: Maybe<Job_Candidates_Min_Fields>;
};


/** aggregate fields of "job_candidates" */
export type Job_Candidates_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<Job_Candidates_Select_Column>>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "job_candidates" */
export type Job_Candidates_Aggregate_Order_By = {
  count?: Maybe<Order_By>;
  max?: Maybe<Job_Candidates_Max_Order_By>;
  min?: Maybe<Job_Candidates_Min_Order_By>;
};

/** input type for inserting array relation for remote table "job_candidates" */
export type Job_Candidates_Arr_Rel_Insert_Input = {
  data: Array<Job_Candidates_Insert_Input>;
  /** upsert condition */
  on_conflict?: Maybe<Job_Candidates_On_Conflict>;
};

/** Boolean expression to filter rows from the table "job_candidates". All fields are combined with a logical 'AND'. */
export type Job_Candidates_Bool_Exp = {
  _and?: Maybe<Array<Job_Candidates_Bool_Exp>>;
  _not?: Maybe<Job_Candidates_Bool_Exp>;
  _or?: Maybe<Array<Job_Candidates_Bool_Exp>>;
  candidate?: Maybe<Candidates_Bool_Exp>;
  candidate_id?: Maybe<Uuid_Comparison_Exp>;
  created_at?: Maybe<Timestamptz_Comparison_Exp>;
  deleted_at?: Maybe<Timestamptz_Comparison_Exp>;
  id?: Maybe<Uuid_Comparison_Exp>;
  job?: Maybe<Jobs_Bool_Exp>;
  job_id?: Maybe<Uuid_Comparison_Exp>;
  updated_at?: Maybe<Timestamptz_Comparison_Exp>;
};

/** unique or primary key constraints on table "job_candidates" */
export enum Job_Candidates_Constraint {
  /** unique or primary key constraint on columns "id" */
  JobCandidatesPkey = 'job_candidates_pkey'
}

/** input type for inserting data into table "job_candidates" */
export type Job_Candidates_Insert_Input = {
  candidate?: Maybe<Candidates_Obj_Rel_Insert_Input>;
  candidate_id?: Maybe<Scalars['uuid']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  deleted_at?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['uuid']>;
  job?: Maybe<Jobs_Obj_Rel_Insert_Input>;
  job_id?: Maybe<Scalars['uuid']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** aggregate max on columns */
export type Job_Candidates_Max_Fields = {
  __typename?: 'job_candidates_max_fields';
  candidate_id?: Maybe<Scalars['uuid']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  deleted_at?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['uuid']>;
  job_id?: Maybe<Scalars['uuid']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** order by max() on columns of table "job_candidates" */
export type Job_Candidates_Max_Order_By = {
  candidate_id?: Maybe<Order_By>;
  created_at?: Maybe<Order_By>;
  deleted_at?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  job_id?: Maybe<Order_By>;
  updated_at?: Maybe<Order_By>;
};

/** aggregate min on columns */
export type Job_Candidates_Min_Fields = {
  __typename?: 'job_candidates_min_fields';
  candidate_id?: Maybe<Scalars['uuid']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  deleted_at?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['uuid']>;
  job_id?: Maybe<Scalars['uuid']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** order by min() on columns of table "job_candidates" */
export type Job_Candidates_Min_Order_By = {
  candidate_id?: Maybe<Order_By>;
  created_at?: Maybe<Order_By>;
  deleted_at?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  job_id?: Maybe<Order_By>;
  updated_at?: Maybe<Order_By>;
};

/** response of any mutation on the table "job_candidates" */
export type Job_Candidates_Mutation_Response = {
  __typename?: 'job_candidates_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Job_Candidates>;
};

/** on_conflict condition type for table "job_candidates" */
export type Job_Candidates_On_Conflict = {
  constraint: Job_Candidates_Constraint;
  update_columns?: Array<Job_Candidates_Update_Column>;
  where?: Maybe<Job_Candidates_Bool_Exp>;
};

/** Ordering options when selecting data from "job_candidates". */
export type Job_Candidates_Order_By = {
  candidate?: Maybe<Candidates_Order_By>;
  candidate_id?: Maybe<Order_By>;
  created_at?: Maybe<Order_By>;
  deleted_at?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  job?: Maybe<Jobs_Order_By>;
  job_id?: Maybe<Order_By>;
  updated_at?: Maybe<Order_By>;
};

/** primary key columns input for table: job_candidates */
export type Job_Candidates_Pk_Columns_Input = {
  id: Scalars['uuid'];
};

/** select columns of table "job_candidates" */
export enum Job_Candidates_Select_Column {
  /** column name */
  CandidateId = 'candidate_id',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  DeletedAt = 'deleted_at',
  /** column name */
  Id = 'id',
  /** column name */
  JobId = 'job_id',
  /** column name */
  UpdatedAt = 'updated_at'
}

/** input type for updating data in table "job_candidates" */
export type Job_Candidates_Set_Input = {
  candidate_id?: Maybe<Scalars['uuid']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  deleted_at?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['uuid']>;
  job_id?: Maybe<Scalars['uuid']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** Streaming cursor of the table "job_candidates" */
export type Job_Candidates_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Job_Candidates_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: Maybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Job_Candidates_Stream_Cursor_Value_Input = {
  candidate_id?: Maybe<Scalars['uuid']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  deleted_at?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['uuid']>;
  job_id?: Maybe<Scalars['uuid']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** update columns of table "job_candidates" */
export enum Job_Candidates_Update_Column {
  /** column name */
  CandidateId = 'candidate_id',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  DeletedAt = 'deleted_at',
  /** column name */
  Id = 'id',
  /** column name */
  JobId = 'job_id',
  /** column name */
  UpdatedAt = 'updated_at'
}

export type Job_Candidates_Updates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: Maybe<Job_Candidates_Set_Input>;
  /** filter the rows which have to be updated */
  where: Job_Candidates_Bool_Exp;
};

/** columns and relationships of "job_locations" */
export type Job_Locations = {
  __typename?: 'job_locations';
  created_at: Scalars['timestamptz'];
  deleted_at?: Maybe<Scalars['timestamptz']>;
  id: Scalars['uuid'];
  /** An object relationship */
  job: Jobs;
  job_id: Scalars['uuid'];
  locality: Scalars['String'];
  /** An object relationship */
  location?: Maybe<Locations>;
  location_description?: Maybe<Scalars['location_name_enums']>;
  postcode: Scalars['String'];
  state: Scalars['String'];
  updated_at: Scalars['timestamptz'];
};

/** aggregated selection of "job_locations" */
export type Job_Locations_Aggregate = {
  __typename?: 'job_locations_aggregate';
  aggregate?: Maybe<Job_Locations_Aggregate_Fields>;
  nodes: Array<Job_Locations>;
};

export type Job_Locations_Aggregate_Bool_Exp = {
  count?: Maybe<Job_Locations_Aggregate_Bool_Exp_Count>;
};

export type Job_Locations_Aggregate_Bool_Exp_Count = {
  arguments?: Maybe<Array<Job_Locations_Select_Column>>;
  distinct?: Maybe<Scalars['Boolean']>;
  filter?: Maybe<Job_Locations_Bool_Exp>;
  predicate: Int_Comparison_Exp;
};

/** aggregate fields of "job_locations" */
export type Job_Locations_Aggregate_Fields = {
  __typename?: 'job_locations_aggregate_fields';
  count: Scalars['Int'];
  max?: Maybe<Job_Locations_Max_Fields>;
  min?: Maybe<Job_Locations_Min_Fields>;
};


/** aggregate fields of "job_locations" */
export type Job_Locations_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<Job_Locations_Select_Column>>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "job_locations" */
export type Job_Locations_Aggregate_Order_By = {
  count?: Maybe<Order_By>;
  max?: Maybe<Job_Locations_Max_Order_By>;
  min?: Maybe<Job_Locations_Min_Order_By>;
};

/** input type for inserting array relation for remote table "job_locations" */
export type Job_Locations_Arr_Rel_Insert_Input = {
  data: Array<Job_Locations_Insert_Input>;
  /** upsert condition */
  on_conflict?: Maybe<Job_Locations_On_Conflict>;
};

/** Boolean expression to filter rows from the table "job_locations". All fields are combined with a logical 'AND'. */
export type Job_Locations_Bool_Exp = {
  _and?: Maybe<Array<Job_Locations_Bool_Exp>>;
  _not?: Maybe<Job_Locations_Bool_Exp>;
  _or?: Maybe<Array<Job_Locations_Bool_Exp>>;
  created_at?: Maybe<Timestamptz_Comparison_Exp>;
  deleted_at?: Maybe<Timestamptz_Comparison_Exp>;
  id?: Maybe<Uuid_Comparison_Exp>;
  job?: Maybe<Jobs_Bool_Exp>;
  job_id?: Maybe<Uuid_Comparison_Exp>;
  locality?: Maybe<String_Comparison_Exp>;
  location?: Maybe<Locations_Bool_Exp>;
  location_description?: Maybe<Location_Name_Enums_Comparison_Exp>;
  postcode?: Maybe<String_Comparison_Exp>;
  state?: Maybe<String_Comparison_Exp>;
  updated_at?: Maybe<Timestamptz_Comparison_Exp>;
};

/** unique or primary key constraints on table "job_locations" */
export enum Job_Locations_Constraint {
  /** unique or primary key constraint on columns "id" */
  JobLocationsPkey = 'job_locations_pkey'
}

/** input type for inserting data into table "job_locations" */
export type Job_Locations_Insert_Input = {
  created_at?: Maybe<Scalars['timestamptz']>;
  deleted_at?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['uuid']>;
  job?: Maybe<Jobs_Obj_Rel_Insert_Input>;
  job_id?: Maybe<Scalars['uuid']>;
  locality?: Maybe<Scalars['String']>;
  location?: Maybe<Locations_Obj_Rel_Insert_Input>;
  location_description?: Maybe<Scalars['location_name_enums']>;
  postcode?: Maybe<Scalars['String']>;
  state?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** aggregate max on columns */
export type Job_Locations_Max_Fields = {
  __typename?: 'job_locations_max_fields';
  created_at?: Maybe<Scalars['timestamptz']>;
  deleted_at?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['uuid']>;
  job_id?: Maybe<Scalars['uuid']>;
  locality?: Maybe<Scalars['String']>;
  location_description?: Maybe<Scalars['location_name_enums']>;
  postcode?: Maybe<Scalars['String']>;
  state?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** order by max() on columns of table "job_locations" */
export type Job_Locations_Max_Order_By = {
  created_at?: Maybe<Order_By>;
  deleted_at?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  job_id?: Maybe<Order_By>;
  locality?: Maybe<Order_By>;
  location_description?: Maybe<Order_By>;
  postcode?: Maybe<Order_By>;
  state?: Maybe<Order_By>;
  updated_at?: Maybe<Order_By>;
};

/** aggregate min on columns */
export type Job_Locations_Min_Fields = {
  __typename?: 'job_locations_min_fields';
  created_at?: Maybe<Scalars['timestamptz']>;
  deleted_at?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['uuid']>;
  job_id?: Maybe<Scalars['uuid']>;
  locality?: Maybe<Scalars['String']>;
  location_description?: Maybe<Scalars['location_name_enums']>;
  postcode?: Maybe<Scalars['String']>;
  state?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** order by min() on columns of table "job_locations" */
export type Job_Locations_Min_Order_By = {
  created_at?: Maybe<Order_By>;
  deleted_at?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  job_id?: Maybe<Order_By>;
  locality?: Maybe<Order_By>;
  location_description?: Maybe<Order_By>;
  postcode?: Maybe<Order_By>;
  state?: Maybe<Order_By>;
  updated_at?: Maybe<Order_By>;
};

/** response of any mutation on the table "job_locations" */
export type Job_Locations_Mutation_Response = {
  __typename?: 'job_locations_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Job_Locations>;
};

/** on_conflict condition type for table "job_locations" */
export type Job_Locations_On_Conflict = {
  constraint: Job_Locations_Constraint;
  update_columns?: Array<Job_Locations_Update_Column>;
  where?: Maybe<Job_Locations_Bool_Exp>;
};

/** Ordering options when selecting data from "job_locations". */
export type Job_Locations_Order_By = {
  created_at?: Maybe<Order_By>;
  deleted_at?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  job?: Maybe<Jobs_Order_By>;
  job_id?: Maybe<Order_By>;
  locality?: Maybe<Order_By>;
  location?: Maybe<Locations_Order_By>;
  location_description?: Maybe<Order_By>;
  postcode?: Maybe<Order_By>;
  state?: Maybe<Order_By>;
  updated_at?: Maybe<Order_By>;
};

/** primary key columns input for table: job_locations */
export type Job_Locations_Pk_Columns_Input = {
  id: Scalars['uuid'];
};

/** select columns of table "job_locations" */
export enum Job_Locations_Select_Column {
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  DeletedAt = 'deleted_at',
  /** column name */
  Id = 'id',
  /** column name */
  JobId = 'job_id',
  /** column name */
  Locality = 'locality',
  /** column name */
  LocationDescription = 'location_description',
  /** column name */
  Postcode = 'postcode',
  /** column name */
  State = 'state',
  /** column name */
  UpdatedAt = 'updated_at'
}

/** input type for updating data in table "job_locations" */
export type Job_Locations_Set_Input = {
  created_at?: Maybe<Scalars['timestamptz']>;
  deleted_at?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['uuid']>;
  job_id?: Maybe<Scalars['uuid']>;
  locality?: Maybe<Scalars['String']>;
  location_description?: Maybe<Scalars['location_name_enums']>;
  postcode?: Maybe<Scalars['String']>;
  state?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** Streaming cursor of the table "job_locations" */
export type Job_Locations_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Job_Locations_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: Maybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Job_Locations_Stream_Cursor_Value_Input = {
  created_at?: Maybe<Scalars['timestamptz']>;
  deleted_at?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['uuid']>;
  job_id?: Maybe<Scalars['uuid']>;
  locality?: Maybe<Scalars['String']>;
  location_description?: Maybe<Scalars['location_name_enums']>;
  postcode?: Maybe<Scalars['String']>;
  state?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** update columns of table "job_locations" */
export enum Job_Locations_Update_Column {
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  DeletedAt = 'deleted_at',
  /** column name */
  Id = 'id',
  /** column name */
  JobId = 'job_id',
  /** column name */
  Locality = 'locality',
  /** column name */
  LocationDescription = 'location_description',
  /** column name */
  Postcode = 'postcode',
  /** column name */
  State = 'state',
  /** column name */
  UpdatedAt = 'updated_at'
}

export type Job_Locations_Updates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: Maybe<Job_Locations_Set_Input>;
  /** filter the rows which have to be updated */
  where: Job_Locations_Bool_Exp;
};


/** Boolean expression to compare columns of type "job_status_enums". All fields are combined with logical 'AND'. */
export type Job_Status_Enums_Comparison_Exp = {
  _eq?: Maybe<Scalars['job_status_enums']>;
  _gt?: Maybe<Scalars['job_status_enums']>;
  _gte?: Maybe<Scalars['job_status_enums']>;
  _in?: Maybe<Array<Scalars['job_status_enums']>>;
  _is_null?: Maybe<Scalars['Boolean']>;
  _lt?: Maybe<Scalars['job_status_enums']>;
  _lte?: Maybe<Scalars['job_status_enums']>;
  _neq?: Maybe<Scalars['job_status_enums']>;
  _nin?: Maybe<Array<Scalars['job_status_enums']>>;
};

/** columns and relationships of "jobs" */
export type Jobs = {
  __typename?: 'jobs';
  available_part_time_days_from?: Maybe<Scalars['Int']>;
  available_part_time_days_to?: Maybe<Scalars['Int']>;
  /** An array relationship */
  candidate_placements: Array<Candidate_Placements>;
  /** An aggregate relationship */
  candidate_placements_aggregate: Candidate_Placements_Aggregate;
  /** An array relationship */
  client_jobs: Array<Client_Jobs>;
  /** An aggregate relationship */
  client_jobs_aggregate: Client_Jobs_Aggregate;
  contract_type?: Maybe<Scalars['jobs_contract_type_enums']>;
  created_at: Scalars['timestamptz'];
  deleted_at?: Maybe<Scalars['timestamptz']>;
  description?: Maybe<Scalars['String']>;
  /** An array relationship */
  events: Array<Events>;
  /** An aggregate relationship */
  events_aggregate: Events_Aggregate;
  graduation_timeframe?: Maybe<Scalars['graduation_timeframe_enums']>;
  id: Scalars['uuid'];
  /** An array relationship */
  interviews: Array<Interviews>;
  /** An aggregate relationship */
  interviews_aggregate: Interviews_Aggregate;
  /** An array relationship */
  job_candidates: Array<Job_Candidates>;
  /** An aggregate relationship */
  job_candidates_aggregate: Job_Candidates_Aggregate;
  /** An array relationship */
  job_locations: Array<Job_Locations>;
  /** An aggregate relationship */
  job_locations_aggregate: Job_Locations_Aggregate;
  /** An array relationship */
  offers: Array<Offers>;
  /** An aggregate relationship */
  offers_aggregate: Offers_Aggregate;
  open_to_part_time_prior_to_graduation?: Maybe<Scalars['Boolean']>;
  /** An object relationship */
  owner?: Maybe<Users>;
  owner_id?: Maybe<Scalars['String']>;
  public?: Maybe<Scalars['Boolean']>;
  role_commences_on?: Maybe<Scalars['timestamptz']>;
  /** An array relationship */
  shortlists: Array<Shortlists>;
  /** An aggregate relationship */
  shortlists_aggregate: Shortlists_Aggregate;
  source?: Maybe<Scalars['String']>;
  status: Scalars['job_status_enums'];
  test?: Maybe<Scalars['Boolean']>;
  title?: Maybe<Scalars['String']>;
  updated_at: Scalars['timestamptz'];
  work_arrangement?: Maybe<Scalars['work_arrangement_enums']>;
};


/** columns and relationships of "jobs" */
export type JobsCandidate_PlacementsArgs = {
  distinct_on?: Maybe<Array<Candidate_Placements_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Candidate_Placements_Order_By>>;
  where?: Maybe<Candidate_Placements_Bool_Exp>;
};


/** columns and relationships of "jobs" */
export type JobsCandidate_Placements_AggregateArgs = {
  distinct_on?: Maybe<Array<Candidate_Placements_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Candidate_Placements_Order_By>>;
  where?: Maybe<Candidate_Placements_Bool_Exp>;
};


/** columns and relationships of "jobs" */
export type JobsClient_JobsArgs = {
  distinct_on?: Maybe<Array<Client_Jobs_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Client_Jobs_Order_By>>;
  where?: Maybe<Client_Jobs_Bool_Exp>;
};


/** columns and relationships of "jobs" */
export type JobsClient_Jobs_AggregateArgs = {
  distinct_on?: Maybe<Array<Client_Jobs_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Client_Jobs_Order_By>>;
  where?: Maybe<Client_Jobs_Bool_Exp>;
};


/** columns and relationships of "jobs" */
export type JobsEventsArgs = {
  distinct_on?: Maybe<Array<Events_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Events_Order_By>>;
  where?: Maybe<Events_Bool_Exp>;
};


/** columns and relationships of "jobs" */
export type JobsEvents_AggregateArgs = {
  distinct_on?: Maybe<Array<Events_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Events_Order_By>>;
  where?: Maybe<Events_Bool_Exp>;
};


/** columns and relationships of "jobs" */
export type JobsInterviewsArgs = {
  distinct_on?: Maybe<Array<Interviews_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Interviews_Order_By>>;
  where?: Maybe<Interviews_Bool_Exp>;
};


/** columns and relationships of "jobs" */
export type JobsInterviews_AggregateArgs = {
  distinct_on?: Maybe<Array<Interviews_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Interviews_Order_By>>;
  where?: Maybe<Interviews_Bool_Exp>;
};


/** columns and relationships of "jobs" */
export type JobsJob_CandidatesArgs = {
  distinct_on?: Maybe<Array<Job_Candidates_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Job_Candidates_Order_By>>;
  where?: Maybe<Job_Candidates_Bool_Exp>;
};


/** columns and relationships of "jobs" */
export type JobsJob_Candidates_AggregateArgs = {
  distinct_on?: Maybe<Array<Job_Candidates_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Job_Candidates_Order_By>>;
  where?: Maybe<Job_Candidates_Bool_Exp>;
};


/** columns and relationships of "jobs" */
export type JobsJob_LocationsArgs = {
  distinct_on?: Maybe<Array<Job_Locations_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Job_Locations_Order_By>>;
  where?: Maybe<Job_Locations_Bool_Exp>;
};


/** columns and relationships of "jobs" */
export type JobsJob_Locations_AggregateArgs = {
  distinct_on?: Maybe<Array<Job_Locations_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Job_Locations_Order_By>>;
  where?: Maybe<Job_Locations_Bool_Exp>;
};


/** columns and relationships of "jobs" */
export type JobsOffersArgs = {
  distinct_on?: Maybe<Array<Offers_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Offers_Order_By>>;
  where?: Maybe<Offers_Bool_Exp>;
};


/** columns and relationships of "jobs" */
export type JobsOffers_AggregateArgs = {
  distinct_on?: Maybe<Array<Offers_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Offers_Order_By>>;
  where?: Maybe<Offers_Bool_Exp>;
};


/** columns and relationships of "jobs" */
export type JobsShortlistsArgs = {
  distinct_on?: Maybe<Array<Shortlists_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Shortlists_Order_By>>;
  where?: Maybe<Shortlists_Bool_Exp>;
};


/** columns and relationships of "jobs" */
export type JobsShortlists_AggregateArgs = {
  distinct_on?: Maybe<Array<Shortlists_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Shortlists_Order_By>>;
  where?: Maybe<Shortlists_Bool_Exp>;
};

/** aggregated selection of "jobs" */
export type Jobs_Aggregate = {
  __typename?: 'jobs_aggregate';
  aggregate?: Maybe<Jobs_Aggregate_Fields>;
  nodes: Array<Jobs>;
};

export type Jobs_Aggregate_Bool_Exp = {
  bool_and?: Maybe<Jobs_Aggregate_Bool_Exp_Bool_And>;
  bool_or?: Maybe<Jobs_Aggregate_Bool_Exp_Bool_Or>;
  count?: Maybe<Jobs_Aggregate_Bool_Exp_Count>;
};

export type Jobs_Aggregate_Bool_Exp_Bool_And = {
  arguments: Jobs_Select_Column_Jobs_Aggregate_Bool_Exp_Bool_And_Arguments_Columns;
  distinct?: Maybe<Scalars['Boolean']>;
  filter?: Maybe<Jobs_Bool_Exp>;
  predicate: Boolean_Comparison_Exp;
};

export type Jobs_Aggregate_Bool_Exp_Bool_Or = {
  arguments: Jobs_Select_Column_Jobs_Aggregate_Bool_Exp_Bool_Or_Arguments_Columns;
  distinct?: Maybe<Scalars['Boolean']>;
  filter?: Maybe<Jobs_Bool_Exp>;
  predicate: Boolean_Comparison_Exp;
};

export type Jobs_Aggregate_Bool_Exp_Count = {
  arguments?: Maybe<Array<Jobs_Select_Column>>;
  distinct?: Maybe<Scalars['Boolean']>;
  filter?: Maybe<Jobs_Bool_Exp>;
  predicate: Int_Comparison_Exp;
};

/** aggregate fields of "jobs" */
export type Jobs_Aggregate_Fields = {
  __typename?: 'jobs_aggregate_fields';
  avg?: Maybe<Jobs_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<Jobs_Max_Fields>;
  min?: Maybe<Jobs_Min_Fields>;
  stddev?: Maybe<Jobs_Stddev_Fields>;
  stddev_pop?: Maybe<Jobs_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Jobs_Stddev_Samp_Fields>;
  sum?: Maybe<Jobs_Sum_Fields>;
  var_pop?: Maybe<Jobs_Var_Pop_Fields>;
  var_samp?: Maybe<Jobs_Var_Samp_Fields>;
  variance?: Maybe<Jobs_Variance_Fields>;
};


/** aggregate fields of "jobs" */
export type Jobs_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<Jobs_Select_Column>>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "jobs" */
export type Jobs_Aggregate_Order_By = {
  avg?: Maybe<Jobs_Avg_Order_By>;
  count?: Maybe<Order_By>;
  max?: Maybe<Jobs_Max_Order_By>;
  min?: Maybe<Jobs_Min_Order_By>;
  stddev?: Maybe<Jobs_Stddev_Order_By>;
  stddev_pop?: Maybe<Jobs_Stddev_Pop_Order_By>;
  stddev_samp?: Maybe<Jobs_Stddev_Samp_Order_By>;
  sum?: Maybe<Jobs_Sum_Order_By>;
  var_pop?: Maybe<Jobs_Var_Pop_Order_By>;
  var_samp?: Maybe<Jobs_Var_Samp_Order_By>;
  variance?: Maybe<Jobs_Variance_Order_By>;
};

/** input type for inserting array relation for remote table "jobs" */
export type Jobs_Arr_Rel_Insert_Input = {
  data: Array<Jobs_Insert_Input>;
  /** upsert condition */
  on_conflict?: Maybe<Jobs_On_Conflict>;
};

/** aggregate avg on columns */
export type Jobs_Avg_Fields = {
  __typename?: 'jobs_avg_fields';
  available_part_time_days_from?: Maybe<Scalars['Float']>;
  available_part_time_days_to?: Maybe<Scalars['Float']>;
};

/** order by avg() on columns of table "jobs" */
export type Jobs_Avg_Order_By = {
  available_part_time_days_from?: Maybe<Order_By>;
  available_part_time_days_to?: Maybe<Order_By>;
};

/** Boolean expression to filter rows from the table "jobs". All fields are combined with a logical 'AND'. */
export type Jobs_Bool_Exp = {
  _and?: Maybe<Array<Jobs_Bool_Exp>>;
  _not?: Maybe<Jobs_Bool_Exp>;
  _or?: Maybe<Array<Jobs_Bool_Exp>>;
  available_part_time_days_from?: Maybe<Int_Comparison_Exp>;
  available_part_time_days_to?: Maybe<Int_Comparison_Exp>;
  candidate_placements?: Maybe<Candidate_Placements_Bool_Exp>;
  candidate_placements_aggregate?: Maybe<Candidate_Placements_Aggregate_Bool_Exp>;
  client_jobs?: Maybe<Client_Jobs_Bool_Exp>;
  client_jobs_aggregate?: Maybe<Client_Jobs_Aggregate_Bool_Exp>;
  contract_type?: Maybe<Jobs_Contract_Type_Enums_Comparison_Exp>;
  created_at?: Maybe<Timestamptz_Comparison_Exp>;
  deleted_at?: Maybe<Timestamptz_Comparison_Exp>;
  description?: Maybe<String_Comparison_Exp>;
  events?: Maybe<Events_Bool_Exp>;
  events_aggregate?: Maybe<Events_Aggregate_Bool_Exp>;
  graduation_timeframe?: Maybe<Graduation_Timeframe_Enums_Comparison_Exp>;
  id?: Maybe<Uuid_Comparison_Exp>;
  interviews?: Maybe<Interviews_Bool_Exp>;
  interviews_aggregate?: Maybe<Interviews_Aggregate_Bool_Exp>;
  job_candidates?: Maybe<Job_Candidates_Bool_Exp>;
  job_candidates_aggregate?: Maybe<Job_Candidates_Aggregate_Bool_Exp>;
  job_locations?: Maybe<Job_Locations_Bool_Exp>;
  job_locations_aggregate?: Maybe<Job_Locations_Aggregate_Bool_Exp>;
  offers?: Maybe<Offers_Bool_Exp>;
  offers_aggregate?: Maybe<Offers_Aggregate_Bool_Exp>;
  open_to_part_time_prior_to_graduation?: Maybe<Boolean_Comparison_Exp>;
  owner?: Maybe<Users_Bool_Exp>;
  owner_id?: Maybe<String_Comparison_Exp>;
  public?: Maybe<Boolean_Comparison_Exp>;
  role_commences_on?: Maybe<Timestamptz_Comparison_Exp>;
  shortlists?: Maybe<Shortlists_Bool_Exp>;
  shortlists_aggregate?: Maybe<Shortlists_Aggregate_Bool_Exp>;
  source?: Maybe<String_Comparison_Exp>;
  status?: Maybe<Job_Status_Enums_Comparison_Exp>;
  test?: Maybe<Boolean_Comparison_Exp>;
  title?: Maybe<String_Comparison_Exp>;
  updated_at?: Maybe<Timestamptz_Comparison_Exp>;
  work_arrangement?: Maybe<Work_Arrangement_Enums_Comparison_Exp>;
};

/** unique or primary key constraints on table "jobs" */
export enum Jobs_Constraint {
  /** unique or primary key constraint on columns "id" */
  JobsPkey = 'jobs_pkey'
}


/** Boolean expression to compare columns of type "jobs_contract_type_enums". All fields are combined with logical 'AND'. */
export type Jobs_Contract_Type_Enums_Comparison_Exp = {
  _eq?: Maybe<Scalars['jobs_contract_type_enums']>;
  _gt?: Maybe<Scalars['jobs_contract_type_enums']>;
  _gte?: Maybe<Scalars['jobs_contract_type_enums']>;
  _in?: Maybe<Array<Scalars['jobs_contract_type_enums']>>;
  _is_null?: Maybe<Scalars['Boolean']>;
  _lt?: Maybe<Scalars['jobs_contract_type_enums']>;
  _lte?: Maybe<Scalars['jobs_contract_type_enums']>;
  _neq?: Maybe<Scalars['jobs_contract_type_enums']>;
  _nin?: Maybe<Array<Scalars['jobs_contract_type_enums']>>;
};

/** input type for incrementing numeric columns in table "jobs" */
export type Jobs_Inc_Input = {
  available_part_time_days_from?: Maybe<Scalars['Int']>;
  available_part_time_days_to?: Maybe<Scalars['Int']>;
};

/** input type for inserting data into table "jobs" */
export type Jobs_Insert_Input = {
  available_part_time_days_from?: Maybe<Scalars['Int']>;
  available_part_time_days_to?: Maybe<Scalars['Int']>;
  candidate_placements?: Maybe<Candidate_Placements_Arr_Rel_Insert_Input>;
  client_jobs?: Maybe<Client_Jobs_Arr_Rel_Insert_Input>;
  contract_type?: Maybe<Scalars['jobs_contract_type_enums']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  deleted_at?: Maybe<Scalars['timestamptz']>;
  description?: Maybe<Scalars['String']>;
  events?: Maybe<Events_Arr_Rel_Insert_Input>;
  graduation_timeframe?: Maybe<Scalars['graduation_timeframe_enums']>;
  id?: Maybe<Scalars['uuid']>;
  interviews?: Maybe<Interviews_Arr_Rel_Insert_Input>;
  job_candidates?: Maybe<Job_Candidates_Arr_Rel_Insert_Input>;
  job_locations?: Maybe<Job_Locations_Arr_Rel_Insert_Input>;
  offers?: Maybe<Offers_Arr_Rel_Insert_Input>;
  open_to_part_time_prior_to_graduation?: Maybe<Scalars['Boolean']>;
  owner?: Maybe<Users_Obj_Rel_Insert_Input>;
  owner_id?: Maybe<Scalars['String']>;
  public?: Maybe<Scalars['Boolean']>;
  role_commences_on?: Maybe<Scalars['timestamptz']>;
  shortlists?: Maybe<Shortlists_Arr_Rel_Insert_Input>;
  source?: Maybe<Scalars['String']>;
  status?: Maybe<Scalars['job_status_enums']>;
  test?: Maybe<Scalars['Boolean']>;
  title?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
  work_arrangement?: Maybe<Scalars['work_arrangement_enums']>;
};

/** aggregate max on columns */
export type Jobs_Max_Fields = {
  __typename?: 'jobs_max_fields';
  available_part_time_days_from?: Maybe<Scalars['Int']>;
  available_part_time_days_to?: Maybe<Scalars['Int']>;
  contract_type?: Maybe<Scalars['jobs_contract_type_enums']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  deleted_at?: Maybe<Scalars['timestamptz']>;
  description?: Maybe<Scalars['String']>;
  graduation_timeframe?: Maybe<Scalars['graduation_timeframe_enums']>;
  id?: Maybe<Scalars['uuid']>;
  owner_id?: Maybe<Scalars['String']>;
  role_commences_on?: Maybe<Scalars['timestamptz']>;
  source?: Maybe<Scalars['String']>;
  status?: Maybe<Scalars['job_status_enums']>;
  title?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
  work_arrangement?: Maybe<Scalars['work_arrangement_enums']>;
};

/** order by max() on columns of table "jobs" */
export type Jobs_Max_Order_By = {
  available_part_time_days_from?: Maybe<Order_By>;
  available_part_time_days_to?: Maybe<Order_By>;
  contract_type?: Maybe<Order_By>;
  created_at?: Maybe<Order_By>;
  deleted_at?: Maybe<Order_By>;
  description?: Maybe<Order_By>;
  graduation_timeframe?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  owner_id?: Maybe<Order_By>;
  role_commences_on?: Maybe<Order_By>;
  source?: Maybe<Order_By>;
  status?: Maybe<Order_By>;
  title?: Maybe<Order_By>;
  updated_at?: Maybe<Order_By>;
  work_arrangement?: Maybe<Order_By>;
};

/** aggregate min on columns */
export type Jobs_Min_Fields = {
  __typename?: 'jobs_min_fields';
  available_part_time_days_from?: Maybe<Scalars['Int']>;
  available_part_time_days_to?: Maybe<Scalars['Int']>;
  contract_type?: Maybe<Scalars['jobs_contract_type_enums']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  deleted_at?: Maybe<Scalars['timestamptz']>;
  description?: Maybe<Scalars['String']>;
  graduation_timeframe?: Maybe<Scalars['graduation_timeframe_enums']>;
  id?: Maybe<Scalars['uuid']>;
  owner_id?: Maybe<Scalars['String']>;
  role_commences_on?: Maybe<Scalars['timestamptz']>;
  source?: Maybe<Scalars['String']>;
  status?: Maybe<Scalars['job_status_enums']>;
  title?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
  work_arrangement?: Maybe<Scalars['work_arrangement_enums']>;
};

/** order by min() on columns of table "jobs" */
export type Jobs_Min_Order_By = {
  available_part_time_days_from?: Maybe<Order_By>;
  available_part_time_days_to?: Maybe<Order_By>;
  contract_type?: Maybe<Order_By>;
  created_at?: Maybe<Order_By>;
  deleted_at?: Maybe<Order_By>;
  description?: Maybe<Order_By>;
  graduation_timeframe?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  owner_id?: Maybe<Order_By>;
  role_commences_on?: Maybe<Order_By>;
  source?: Maybe<Order_By>;
  status?: Maybe<Order_By>;
  title?: Maybe<Order_By>;
  updated_at?: Maybe<Order_By>;
  work_arrangement?: Maybe<Order_By>;
};

/** response of any mutation on the table "jobs" */
export type Jobs_Mutation_Response = {
  __typename?: 'jobs_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Jobs>;
};

/** input type for inserting object relation for remote table "jobs" */
export type Jobs_Obj_Rel_Insert_Input = {
  data: Jobs_Insert_Input;
  /** upsert condition */
  on_conflict?: Maybe<Jobs_On_Conflict>;
};

/** on_conflict condition type for table "jobs" */
export type Jobs_On_Conflict = {
  constraint: Jobs_Constraint;
  update_columns?: Array<Jobs_Update_Column>;
  where?: Maybe<Jobs_Bool_Exp>;
};

/** Ordering options when selecting data from "jobs". */
export type Jobs_Order_By = {
  available_part_time_days_from?: Maybe<Order_By>;
  available_part_time_days_to?: Maybe<Order_By>;
  candidate_placements_aggregate?: Maybe<Candidate_Placements_Aggregate_Order_By>;
  client_jobs_aggregate?: Maybe<Client_Jobs_Aggregate_Order_By>;
  contract_type?: Maybe<Order_By>;
  created_at?: Maybe<Order_By>;
  deleted_at?: Maybe<Order_By>;
  description?: Maybe<Order_By>;
  events_aggregate?: Maybe<Events_Aggregate_Order_By>;
  graduation_timeframe?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  interviews_aggregate?: Maybe<Interviews_Aggregate_Order_By>;
  job_candidates_aggregate?: Maybe<Job_Candidates_Aggregate_Order_By>;
  job_locations_aggregate?: Maybe<Job_Locations_Aggregate_Order_By>;
  offers_aggregate?: Maybe<Offers_Aggregate_Order_By>;
  open_to_part_time_prior_to_graduation?: Maybe<Order_By>;
  owner?: Maybe<Users_Order_By>;
  owner_id?: Maybe<Order_By>;
  public?: Maybe<Order_By>;
  role_commences_on?: Maybe<Order_By>;
  shortlists_aggregate?: Maybe<Shortlists_Aggregate_Order_By>;
  source?: Maybe<Order_By>;
  status?: Maybe<Order_By>;
  test?: Maybe<Order_By>;
  title?: Maybe<Order_By>;
  updated_at?: Maybe<Order_By>;
  work_arrangement?: Maybe<Order_By>;
};

/** primary key columns input for table: jobs */
export type Jobs_Pk_Columns_Input = {
  id: Scalars['uuid'];
};

/** select columns of table "jobs" */
export enum Jobs_Select_Column {
  /** column name */
  AvailablePartTimeDaysFrom = 'available_part_time_days_from',
  /** column name */
  AvailablePartTimeDaysTo = 'available_part_time_days_to',
  /** column name */
  ContractType = 'contract_type',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  DeletedAt = 'deleted_at',
  /** column name */
  Description = 'description',
  /** column name */
  GraduationTimeframe = 'graduation_timeframe',
  /** column name */
  Id = 'id',
  /** column name */
  OpenToPartTimePriorToGraduation = 'open_to_part_time_prior_to_graduation',
  /** column name */
  OwnerId = 'owner_id',
  /** column name */
  Public = 'public',
  /** column name */
  RoleCommencesOn = 'role_commences_on',
  /** column name */
  Source = 'source',
  /** column name */
  Status = 'status',
  /** column name */
  Test = 'test',
  /** column name */
  Title = 'title',
  /** column name */
  UpdatedAt = 'updated_at',
  /** column name */
  WorkArrangement = 'work_arrangement'
}

/** select "jobs_aggregate_bool_exp_bool_and_arguments_columns" columns of table "jobs" */
export enum Jobs_Select_Column_Jobs_Aggregate_Bool_Exp_Bool_And_Arguments_Columns {
  /** column name */
  OpenToPartTimePriorToGraduation = 'open_to_part_time_prior_to_graduation',
  /** column name */
  Public = 'public',
  /** column name */
  Test = 'test'
}

/** select "jobs_aggregate_bool_exp_bool_or_arguments_columns" columns of table "jobs" */
export enum Jobs_Select_Column_Jobs_Aggregate_Bool_Exp_Bool_Or_Arguments_Columns {
  /** column name */
  OpenToPartTimePriorToGraduation = 'open_to_part_time_prior_to_graduation',
  /** column name */
  Public = 'public',
  /** column name */
  Test = 'test'
}

/** input type for updating data in table "jobs" */
export type Jobs_Set_Input = {
  available_part_time_days_from?: Maybe<Scalars['Int']>;
  available_part_time_days_to?: Maybe<Scalars['Int']>;
  contract_type?: Maybe<Scalars['jobs_contract_type_enums']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  deleted_at?: Maybe<Scalars['timestamptz']>;
  description?: Maybe<Scalars['String']>;
  graduation_timeframe?: Maybe<Scalars['graduation_timeframe_enums']>;
  id?: Maybe<Scalars['uuid']>;
  open_to_part_time_prior_to_graduation?: Maybe<Scalars['Boolean']>;
  owner_id?: Maybe<Scalars['String']>;
  public?: Maybe<Scalars['Boolean']>;
  role_commences_on?: Maybe<Scalars['timestamptz']>;
  source?: Maybe<Scalars['String']>;
  status?: Maybe<Scalars['job_status_enums']>;
  test?: Maybe<Scalars['Boolean']>;
  title?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
  work_arrangement?: Maybe<Scalars['work_arrangement_enums']>;
};

/** aggregate stddev on columns */
export type Jobs_Stddev_Fields = {
  __typename?: 'jobs_stddev_fields';
  available_part_time_days_from?: Maybe<Scalars['Float']>;
  available_part_time_days_to?: Maybe<Scalars['Float']>;
};

/** order by stddev() on columns of table "jobs" */
export type Jobs_Stddev_Order_By = {
  available_part_time_days_from?: Maybe<Order_By>;
  available_part_time_days_to?: Maybe<Order_By>;
};

/** aggregate stddev_pop on columns */
export type Jobs_Stddev_Pop_Fields = {
  __typename?: 'jobs_stddev_pop_fields';
  available_part_time_days_from?: Maybe<Scalars['Float']>;
  available_part_time_days_to?: Maybe<Scalars['Float']>;
};

/** order by stddev_pop() on columns of table "jobs" */
export type Jobs_Stddev_Pop_Order_By = {
  available_part_time_days_from?: Maybe<Order_By>;
  available_part_time_days_to?: Maybe<Order_By>;
};

/** aggregate stddev_samp on columns */
export type Jobs_Stddev_Samp_Fields = {
  __typename?: 'jobs_stddev_samp_fields';
  available_part_time_days_from?: Maybe<Scalars['Float']>;
  available_part_time_days_to?: Maybe<Scalars['Float']>;
};

/** order by stddev_samp() on columns of table "jobs" */
export type Jobs_Stddev_Samp_Order_By = {
  available_part_time_days_from?: Maybe<Order_By>;
  available_part_time_days_to?: Maybe<Order_By>;
};

/** Streaming cursor of the table "jobs" */
export type Jobs_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Jobs_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: Maybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Jobs_Stream_Cursor_Value_Input = {
  available_part_time_days_from?: Maybe<Scalars['Int']>;
  available_part_time_days_to?: Maybe<Scalars['Int']>;
  contract_type?: Maybe<Scalars['jobs_contract_type_enums']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  deleted_at?: Maybe<Scalars['timestamptz']>;
  description?: Maybe<Scalars['String']>;
  graduation_timeframe?: Maybe<Scalars['graduation_timeframe_enums']>;
  id?: Maybe<Scalars['uuid']>;
  open_to_part_time_prior_to_graduation?: Maybe<Scalars['Boolean']>;
  owner_id?: Maybe<Scalars['String']>;
  public?: Maybe<Scalars['Boolean']>;
  role_commences_on?: Maybe<Scalars['timestamptz']>;
  source?: Maybe<Scalars['String']>;
  status?: Maybe<Scalars['job_status_enums']>;
  test?: Maybe<Scalars['Boolean']>;
  title?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
  work_arrangement?: Maybe<Scalars['work_arrangement_enums']>;
};

/** aggregate sum on columns */
export type Jobs_Sum_Fields = {
  __typename?: 'jobs_sum_fields';
  available_part_time_days_from?: Maybe<Scalars['Int']>;
  available_part_time_days_to?: Maybe<Scalars['Int']>;
};

/** order by sum() on columns of table "jobs" */
export type Jobs_Sum_Order_By = {
  available_part_time_days_from?: Maybe<Order_By>;
  available_part_time_days_to?: Maybe<Order_By>;
};

/** update columns of table "jobs" */
export enum Jobs_Update_Column {
  /** column name */
  AvailablePartTimeDaysFrom = 'available_part_time_days_from',
  /** column name */
  AvailablePartTimeDaysTo = 'available_part_time_days_to',
  /** column name */
  ContractType = 'contract_type',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  DeletedAt = 'deleted_at',
  /** column name */
  Description = 'description',
  /** column name */
  GraduationTimeframe = 'graduation_timeframe',
  /** column name */
  Id = 'id',
  /** column name */
  OpenToPartTimePriorToGraduation = 'open_to_part_time_prior_to_graduation',
  /** column name */
  OwnerId = 'owner_id',
  /** column name */
  Public = 'public',
  /** column name */
  RoleCommencesOn = 'role_commences_on',
  /** column name */
  Source = 'source',
  /** column name */
  Status = 'status',
  /** column name */
  Test = 'test',
  /** column name */
  Title = 'title',
  /** column name */
  UpdatedAt = 'updated_at',
  /** column name */
  WorkArrangement = 'work_arrangement'
}

export type Jobs_Updates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: Maybe<Jobs_Inc_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: Maybe<Jobs_Set_Input>;
  /** filter the rows which have to be updated */
  where: Jobs_Bool_Exp;
};

/** aggregate var_pop on columns */
export type Jobs_Var_Pop_Fields = {
  __typename?: 'jobs_var_pop_fields';
  available_part_time_days_from?: Maybe<Scalars['Float']>;
  available_part_time_days_to?: Maybe<Scalars['Float']>;
};

/** order by var_pop() on columns of table "jobs" */
export type Jobs_Var_Pop_Order_By = {
  available_part_time_days_from?: Maybe<Order_By>;
  available_part_time_days_to?: Maybe<Order_By>;
};

/** aggregate var_samp on columns */
export type Jobs_Var_Samp_Fields = {
  __typename?: 'jobs_var_samp_fields';
  available_part_time_days_from?: Maybe<Scalars['Float']>;
  available_part_time_days_to?: Maybe<Scalars['Float']>;
};

/** order by var_samp() on columns of table "jobs" */
export type Jobs_Var_Samp_Order_By = {
  available_part_time_days_from?: Maybe<Order_By>;
  available_part_time_days_to?: Maybe<Order_By>;
};

/** aggregate variance on columns */
export type Jobs_Variance_Fields = {
  __typename?: 'jobs_variance_fields';
  available_part_time_days_from?: Maybe<Scalars['Float']>;
  available_part_time_days_to?: Maybe<Scalars['Float']>;
};

/** order by variance() on columns of table "jobs" */
export type Jobs_Variance_Order_By = {
  available_part_time_days_from?: Maybe<Order_By>;
  available_part_time_days_to?: Maybe<Order_By>;
};



export type Jsonb_Cast_Exp = {
  String?: Maybe<String_Comparison_Exp>;
};

/** Boolean expression to compare columns of type "jsonb". All fields are combined with logical 'AND'. */
export type Jsonb_Comparison_Exp = {
  _cast?: Maybe<Jsonb_Cast_Exp>;
  /** is the column contained in the given json value */
  _contained_in?: Maybe<Scalars['jsonb']>;
  /** does the column contain the given json value at the top level */
  _contains?: Maybe<Scalars['jsonb']>;
  _eq?: Maybe<Scalars['jsonb']>;
  _gt?: Maybe<Scalars['jsonb']>;
  _gte?: Maybe<Scalars['jsonb']>;
  /** does the string exist as a top-level key in the column */
  _has_key?: Maybe<Scalars['String']>;
  /** do all of these strings exist as top-level keys in the column */
  _has_keys_all?: Maybe<Array<Scalars['String']>>;
  /** do any of these strings exist as top-level keys in the column */
  _has_keys_any?: Maybe<Array<Scalars['String']>>;
  _in?: Maybe<Array<Scalars['jsonb']>>;
  _is_null?: Maybe<Scalars['Boolean']>;
  _lt?: Maybe<Scalars['jsonb']>;
  _lte?: Maybe<Scalars['jsonb']>;
  _neq?: Maybe<Scalars['jsonb']>;
  _nin?: Maybe<Array<Scalars['jsonb']>>;
};


/** Boolean expression to compare columns of type "location_name_enums". All fields are combined with logical 'AND'. */
export type Location_Name_Enums_Comparison_Exp = {
  _eq?: Maybe<Scalars['location_name_enums']>;
  _gt?: Maybe<Scalars['location_name_enums']>;
  _gte?: Maybe<Scalars['location_name_enums']>;
  _in?: Maybe<Array<Scalars['location_name_enums']>>;
  _is_null?: Maybe<Scalars['Boolean']>;
  _lt?: Maybe<Scalars['location_name_enums']>;
  _lte?: Maybe<Scalars['location_name_enums']>;
  _neq?: Maybe<Scalars['location_name_enums']>;
  _nin?: Maybe<Array<Scalars['location_name_enums']>>;
};

/** columns and relationships of "locations" */
export type Locations = {
  __typename?: 'locations';
  /** An array relationship */
  candidate_locations: Array<Candidate_Locations>;
  /** An aggregate relationship */
  candidate_locations_aggregate: Candidate_Locations_Aggregate;
  /** An array relationship */
  client_locations: Array<Client_Locations>;
  /** An aggregate relationship */
  client_locations_aggregate: Client_Locations_Aggregate;
  coordinate: Scalars['geometry'];
  /** An array relationship */
  enterprise_locations: Array<Enterprise_Locations>;
  /** An aggregate relationship */
  enterprise_locations_aggregate: Enterprise_Locations_Aggregate;
  id: Scalars['uuid'];
  /** An array relationship */
  job_locations: Array<Job_Locations>;
  /** An aggregate relationship */
  job_locations_aggregate: Job_Locations_Aggregate;
  locality: Scalars['String'];
  postcode: Scalars['String'];
  state: Scalars['String'];
  /** An array relationship */
  tenant_locations: Array<Tenant_Locations>;
  /** An aggregate relationship */
  tenant_locations_aggregate: Tenant_Locations_Aggregate;
};


/** columns and relationships of "locations" */
export type LocationsCandidate_LocationsArgs = {
  distinct_on?: Maybe<Array<Candidate_Locations_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Candidate_Locations_Order_By>>;
  where?: Maybe<Candidate_Locations_Bool_Exp>;
};


/** columns and relationships of "locations" */
export type LocationsCandidate_Locations_AggregateArgs = {
  distinct_on?: Maybe<Array<Candidate_Locations_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Candidate_Locations_Order_By>>;
  where?: Maybe<Candidate_Locations_Bool_Exp>;
};


/** columns and relationships of "locations" */
export type LocationsClient_LocationsArgs = {
  distinct_on?: Maybe<Array<Client_Locations_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Client_Locations_Order_By>>;
  where?: Maybe<Client_Locations_Bool_Exp>;
};


/** columns and relationships of "locations" */
export type LocationsClient_Locations_AggregateArgs = {
  distinct_on?: Maybe<Array<Client_Locations_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Client_Locations_Order_By>>;
  where?: Maybe<Client_Locations_Bool_Exp>;
};


/** columns and relationships of "locations" */
export type LocationsEnterprise_LocationsArgs = {
  distinct_on?: Maybe<Array<Enterprise_Locations_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Enterprise_Locations_Order_By>>;
  where?: Maybe<Enterprise_Locations_Bool_Exp>;
};


/** columns and relationships of "locations" */
export type LocationsEnterprise_Locations_AggregateArgs = {
  distinct_on?: Maybe<Array<Enterprise_Locations_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Enterprise_Locations_Order_By>>;
  where?: Maybe<Enterprise_Locations_Bool_Exp>;
};


/** columns and relationships of "locations" */
export type LocationsJob_LocationsArgs = {
  distinct_on?: Maybe<Array<Job_Locations_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Job_Locations_Order_By>>;
  where?: Maybe<Job_Locations_Bool_Exp>;
};


/** columns and relationships of "locations" */
export type LocationsJob_Locations_AggregateArgs = {
  distinct_on?: Maybe<Array<Job_Locations_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Job_Locations_Order_By>>;
  where?: Maybe<Job_Locations_Bool_Exp>;
};


/** columns and relationships of "locations" */
export type LocationsTenant_LocationsArgs = {
  distinct_on?: Maybe<Array<Tenant_Locations_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Tenant_Locations_Order_By>>;
  where?: Maybe<Tenant_Locations_Bool_Exp>;
};


/** columns and relationships of "locations" */
export type LocationsTenant_Locations_AggregateArgs = {
  distinct_on?: Maybe<Array<Tenant_Locations_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Tenant_Locations_Order_By>>;
  where?: Maybe<Tenant_Locations_Bool_Exp>;
};

/** aggregated selection of "locations" */
export type Locations_Aggregate = {
  __typename?: 'locations_aggregate';
  aggregate?: Maybe<Locations_Aggregate_Fields>;
  nodes: Array<Locations>;
};

/** aggregate fields of "locations" */
export type Locations_Aggregate_Fields = {
  __typename?: 'locations_aggregate_fields';
  count: Scalars['Int'];
  max?: Maybe<Locations_Max_Fields>;
  min?: Maybe<Locations_Min_Fields>;
};


/** aggregate fields of "locations" */
export type Locations_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<Locations_Select_Column>>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** Boolean expression to filter rows from the table "locations". All fields are combined with a logical 'AND'. */
export type Locations_Bool_Exp = {
  _and?: Maybe<Array<Locations_Bool_Exp>>;
  _not?: Maybe<Locations_Bool_Exp>;
  _or?: Maybe<Array<Locations_Bool_Exp>>;
  candidate_locations?: Maybe<Candidate_Locations_Bool_Exp>;
  candidate_locations_aggregate?: Maybe<Candidate_Locations_Aggregate_Bool_Exp>;
  client_locations?: Maybe<Client_Locations_Bool_Exp>;
  client_locations_aggregate?: Maybe<Client_Locations_Aggregate_Bool_Exp>;
  coordinate?: Maybe<Geometry_Comparison_Exp>;
  enterprise_locations?: Maybe<Enterprise_Locations_Bool_Exp>;
  enterprise_locations_aggregate?: Maybe<Enterprise_Locations_Aggregate_Bool_Exp>;
  id?: Maybe<Uuid_Comparison_Exp>;
  job_locations?: Maybe<Job_Locations_Bool_Exp>;
  job_locations_aggregate?: Maybe<Job_Locations_Aggregate_Bool_Exp>;
  locality?: Maybe<String_Comparison_Exp>;
  postcode?: Maybe<String_Comparison_Exp>;
  state?: Maybe<String_Comparison_Exp>;
  tenant_locations?: Maybe<Tenant_Locations_Bool_Exp>;
  tenant_locations_aggregate?: Maybe<Tenant_Locations_Aggregate_Bool_Exp>;
};

/** unique or primary key constraints on table "locations" */
export enum Locations_Constraint {
  /** unique or primary key constraint on columns "id" */
  LocationsPkey = 'locations_pkey',
  /** unique or primary key constraint on columns "state", "locality", "postcode" */
  LocationsPostcodeLocalityStateKey = 'locations_postcode_locality_state_key'
}

/** input type for inserting data into table "locations" */
export type Locations_Insert_Input = {
  candidate_locations?: Maybe<Candidate_Locations_Arr_Rel_Insert_Input>;
  client_locations?: Maybe<Client_Locations_Arr_Rel_Insert_Input>;
  coordinate?: Maybe<Scalars['geometry']>;
  enterprise_locations?: Maybe<Enterprise_Locations_Arr_Rel_Insert_Input>;
  id?: Maybe<Scalars['uuid']>;
  job_locations?: Maybe<Job_Locations_Arr_Rel_Insert_Input>;
  locality?: Maybe<Scalars['String']>;
  postcode?: Maybe<Scalars['String']>;
  state?: Maybe<Scalars['String']>;
  tenant_locations?: Maybe<Tenant_Locations_Arr_Rel_Insert_Input>;
};

/** aggregate max on columns */
export type Locations_Max_Fields = {
  __typename?: 'locations_max_fields';
  id?: Maybe<Scalars['uuid']>;
  locality?: Maybe<Scalars['String']>;
  postcode?: Maybe<Scalars['String']>;
  state?: Maybe<Scalars['String']>;
};

/** aggregate min on columns */
export type Locations_Min_Fields = {
  __typename?: 'locations_min_fields';
  id?: Maybe<Scalars['uuid']>;
  locality?: Maybe<Scalars['String']>;
  postcode?: Maybe<Scalars['String']>;
  state?: Maybe<Scalars['String']>;
};

/** response of any mutation on the table "locations" */
export type Locations_Mutation_Response = {
  __typename?: 'locations_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Locations>;
};

/** input type for inserting object relation for remote table "locations" */
export type Locations_Obj_Rel_Insert_Input = {
  data: Locations_Insert_Input;
  /** upsert condition */
  on_conflict?: Maybe<Locations_On_Conflict>;
};

/** on_conflict condition type for table "locations" */
export type Locations_On_Conflict = {
  constraint: Locations_Constraint;
  update_columns?: Array<Locations_Update_Column>;
  where?: Maybe<Locations_Bool_Exp>;
};

/** Ordering options when selecting data from "locations". */
export type Locations_Order_By = {
  candidate_locations_aggregate?: Maybe<Candidate_Locations_Aggregate_Order_By>;
  client_locations_aggregate?: Maybe<Client_Locations_Aggregate_Order_By>;
  coordinate?: Maybe<Order_By>;
  enterprise_locations_aggregate?: Maybe<Enterprise_Locations_Aggregate_Order_By>;
  id?: Maybe<Order_By>;
  job_locations_aggregate?: Maybe<Job_Locations_Aggregate_Order_By>;
  locality?: Maybe<Order_By>;
  postcode?: Maybe<Order_By>;
  state?: Maybe<Order_By>;
  tenant_locations_aggregate?: Maybe<Tenant_Locations_Aggregate_Order_By>;
};

/** primary key columns input for table: locations */
export type Locations_Pk_Columns_Input = {
  id: Scalars['uuid'];
};

/** select columns of table "locations" */
export enum Locations_Select_Column {
  /** column name */
  Coordinate = 'coordinate',
  /** column name */
  Id = 'id',
  /** column name */
  Locality = 'locality',
  /** column name */
  Postcode = 'postcode',
  /** column name */
  State = 'state'
}

/** input type for updating data in table "locations" */
export type Locations_Set_Input = {
  coordinate?: Maybe<Scalars['geometry']>;
  id?: Maybe<Scalars['uuid']>;
  locality?: Maybe<Scalars['String']>;
  postcode?: Maybe<Scalars['String']>;
  state?: Maybe<Scalars['String']>;
};

/** Streaming cursor of the table "locations" */
export type Locations_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Locations_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: Maybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Locations_Stream_Cursor_Value_Input = {
  coordinate?: Maybe<Scalars['geometry']>;
  id?: Maybe<Scalars['uuid']>;
  locality?: Maybe<Scalars['String']>;
  postcode?: Maybe<Scalars['String']>;
  state?: Maybe<Scalars['String']>;
};

/** update columns of table "locations" */
export enum Locations_Update_Column {
  /** column name */
  Coordinate = 'coordinate',
  /** column name */
  Id = 'id',
  /** column name */
  Locality = 'locality',
  /** column name */
  Postcode = 'postcode',
  /** column name */
  State = 'state'
}

export type Locations_Updates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: Maybe<Locations_Set_Input>;
  /** filter the rows which have to be updated */
  where: Locations_Bool_Exp;
};

/** columns and relationships of "majors" */
export type Majors = {
  __typename?: 'majors';
  /** An array relationship */
  candidate_majors: Array<Candidate_Majors>;
  /** An aggregate relationship */
  candidate_majors_aggregate: Candidate_Majors_Aggregate;
  created_at: Scalars['timestamptz'];
  deleted_at?: Maybe<Scalars['timestamptz']>;
  description?: Maybe<Scalars['String']>;
  id: Scalars['uuid'];
  title: Scalars['String'];
  updated_at: Scalars['timestamptz'];
};


/** columns and relationships of "majors" */
export type MajorsCandidate_MajorsArgs = {
  distinct_on?: Maybe<Array<Candidate_Majors_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Candidate_Majors_Order_By>>;
  where?: Maybe<Candidate_Majors_Bool_Exp>;
};


/** columns and relationships of "majors" */
export type MajorsCandidate_Majors_AggregateArgs = {
  distinct_on?: Maybe<Array<Candidate_Majors_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Candidate_Majors_Order_By>>;
  where?: Maybe<Candidate_Majors_Bool_Exp>;
};

/** aggregated selection of "majors" */
export type Majors_Aggregate = {
  __typename?: 'majors_aggregate';
  aggregate?: Maybe<Majors_Aggregate_Fields>;
  nodes: Array<Majors>;
};

/** aggregate fields of "majors" */
export type Majors_Aggregate_Fields = {
  __typename?: 'majors_aggregate_fields';
  count: Scalars['Int'];
  max?: Maybe<Majors_Max_Fields>;
  min?: Maybe<Majors_Min_Fields>;
};


/** aggregate fields of "majors" */
export type Majors_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<Majors_Select_Column>>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** Boolean expression to filter rows from the table "majors". All fields are combined with a logical 'AND'. */
export type Majors_Bool_Exp = {
  _and?: Maybe<Array<Majors_Bool_Exp>>;
  _not?: Maybe<Majors_Bool_Exp>;
  _or?: Maybe<Array<Majors_Bool_Exp>>;
  candidate_majors?: Maybe<Candidate_Majors_Bool_Exp>;
  candidate_majors_aggregate?: Maybe<Candidate_Majors_Aggregate_Bool_Exp>;
  created_at?: Maybe<Timestamptz_Comparison_Exp>;
  deleted_at?: Maybe<Timestamptz_Comparison_Exp>;
  description?: Maybe<String_Comparison_Exp>;
  id?: Maybe<Uuid_Comparison_Exp>;
  title?: Maybe<String_Comparison_Exp>;
  updated_at?: Maybe<Timestamptz_Comparison_Exp>;
};

/** unique or primary key constraints on table "majors" */
export enum Majors_Constraint {
  /** unique or primary key constraint on columns "id" */
  MajorsPkey = 'majors_pkey',
  /** unique or primary key constraint on columns "title" */
  MajorsTitleKey = 'majors_title_key'
}

/** input type for inserting data into table "majors" */
export type Majors_Insert_Input = {
  candidate_majors?: Maybe<Candidate_Majors_Arr_Rel_Insert_Input>;
  created_at?: Maybe<Scalars['timestamptz']>;
  deleted_at?: Maybe<Scalars['timestamptz']>;
  description?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['uuid']>;
  title?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** aggregate max on columns */
export type Majors_Max_Fields = {
  __typename?: 'majors_max_fields';
  created_at?: Maybe<Scalars['timestamptz']>;
  deleted_at?: Maybe<Scalars['timestamptz']>;
  description?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['uuid']>;
  title?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** aggregate min on columns */
export type Majors_Min_Fields = {
  __typename?: 'majors_min_fields';
  created_at?: Maybe<Scalars['timestamptz']>;
  deleted_at?: Maybe<Scalars['timestamptz']>;
  description?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['uuid']>;
  title?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** response of any mutation on the table "majors" */
export type Majors_Mutation_Response = {
  __typename?: 'majors_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Majors>;
};

/** input type for inserting object relation for remote table "majors" */
export type Majors_Obj_Rel_Insert_Input = {
  data: Majors_Insert_Input;
  /** upsert condition */
  on_conflict?: Maybe<Majors_On_Conflict>;
};

/** on_conflict condition type for table "majors" */
export type Majors_On_Conflict = {
  constraint: Majors_Constraint;
  update_columns?: Array<Majors_Update_Column>;
  where?: Maybe<Majors_Bool_Exp>;
};

/** Ordering options when selecting data from "majors". */
export type Majors_Order_By = {
  candidate_majors_aggregate?: Maybe<Candidate_Majors_Aggregate_Order_By>;
  created_at?: Maybe<Order_By>;
  deleted_at?: Maybe<Order_By>;
  description?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  title?: Maybe<Order_By>;
  updated_at?: Maybe<Order_By>;
};

/** primary key columns input for table: majors */
export type Majors_Pk_Columns_Input = {
  id: Scalars['uuid'];
};

/** select columns of table "majors" */
export enum Majors_Select_Column {
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  DeletedAt = 'deleted_at',
  /** column name */
  Description = 'description',
  /** column name */
  Id = 'id',
  /** column name */
  Title = 'title',
  /** column name */
  UpdatedAt = 'updated_at'
}

/** input type for updating data in table "majors" */
export type Majors_Set_Input = {
  created_at?: Maybe<Scalars['timestamptz']>;
  deleted_at?: Maybe<Scalars['timestamptz']>;
  description?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['uuid']>;
  title?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** Streaming cursor of the table "majors" */
export type Majors_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Majors_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: Maybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Majors_Stream_Cursor_Value_Input = {
  created_at?: Maybe<Scalars['timestamptz']>;
  deleted_at?: Maybe<Scalars['timestamptz']>;
  description?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['uuid']>;
  title?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** update columns of table "majors" */
export enum Majors_Update_Column {
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  DeletedAt = 'deleted_at',
  /** column name */
  Description = 'description',
  /** column name */
  Id = 'id',
  /** column name */
  Title = 'title',
  /** column name */
  UpdatedAt = 'updated_at'
}

export type Majors_Updates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: Maybe<Majors_Set_Input>;
  /** filter the rows which have to be updated */
  where: Majors_Bool_Exp;
};

/** mutation root */
export type Mutation_Root = {
  __typename?: 'mutation_root';
  /** delete data from the table: "brimstone_event_feedback" */
  delete_brimstone_event_feedback?: Maybe<Brimstone_Event_Feedback_Mutation_Response>;
  /** delete single row from the table: "brimstone_event_feedback" */
  delete_brimstone_event_feedback_by_pk?: Maybe<Brimstone_Event_Feedback>;
  /** delete data from the table: "brimstone_event_speakers" */
  delete_brimstone_event_speakers?: Maybe<Brimstone_Event_Speakers_Mutation_Response>;
  /** delete single row from the table: "brimstone_event_speakers" */
  delete_brimstone_event_speakers_by_pk?: Maybe<Brimstone_Event_Speakers>;
  /** delete data from the table: "brimstone_events" */
  delete_brimstone_events?: Maybe<Brimstone_Events_Mutation_Response>;
  /** delete single row from the table: "brimstone_events" */
  delete_brimstone_events_by_pk?: Maybe<Brimstone_Events>;
  /** delete data from the table: "brimstone_speakers" */
  delete_brimstone_speakers?: Maybe<Brimstone_Speakers_Mutation_Response>;
  /** delete single row from the table: "brimstone_speakers" */
  delete_brimstone_speakers_by_pk?: Maybe<Brimstone_Speakers>;
  /** delete data from the table: "candidate_courses" */
  delete_candidate_courses?: Maybe<Candidate_Courses_Mutation_Response>;
  /** delete single row from the table: "candidate_courses" */
  delete_candidate_courses_by_pk?: Maybe<Candidate_Courses>;
  /** delete data from the table: "candidate_degrees" */
  delete_candidate_degrees?: Maybe<Candidate_Degrees_Mutation_Response>;
  /** delete single row from the table: "candidate_degrees" */
  delete_candidate_degrees_by_pk?: Maybe<Candidate_Degrees>;
  /** delete data from the table: "candidate_follows" */
  delete_candidate_follows?: Maybe<Candidate_Follows_Mutation_Response>;
  /** delete single row from the table: "candidate_follows" */
  delete_candidate_follows_by_pk?: Maybe<Candidate_Follows>;
  /** delete data from the table: "candidate_industries" */
  delete_candidate_industries?: Maybe<Candidate_Industries_Mutation_Response>;
  /** delete single row from the table: "candidate_industries" */
  delete_candidate_industries_by_pk?: Maybe<Candidate_Industries>;
  /** delete data from the table: "candidate_locations" */
  delete_candidate_locations?: Maybe<Candidate_Locations_Mutation_Response>;
  /** delete single row from the table: "candidate_locations" */
  delete_candidate_locations_by_pk?: Maybe<Candidate_Locations>;
  /** delete data from the table: "candidate_majors" */
  delete_candidate_majors?: Maybe<Candidate_Majors_Mutation_Response>;
  /** delete single row from the table: "candidate_majors" */
  delete_candidate_majors_by_pk?: Maybe<Candidate_Majors>;
  /** delete data from the table: "candidate_placements" */
  delete_candidate_placements?: Maybe<Candidate_Placements_Mutation_Response>;
  /** delete single row from the table: "candidate_placements" */
  delete_candidate_placements_by_pk?: Maybe<Candidate_Placements>;
  /** delete data from the table: "candidate_skills" */
  delete_candidate_skills?: Maybe<Candidate_Skills_Mutation_Response>;
  /** delete single row from the table: "candidate_skills" */
  delete_candidate_skills_by_pk?: Maybe<Candidate_Skills>;
  /** delete data from the table: "candidates" */
  delete_candidates?: Maybe<Candidates_Mutation_Response>;
  /** delete single row from the table: "candidates" */
  delete_candidates_by_pk?: Maybe<Candidates>;
  /** delete data from the table: "client_industries" */
  delete_client_industries?: Maybe<Client_Industries_Mutation_Response>;
  /** delete single row from the table: "client_industries" */
  delete_client_industries_by_pk?: Maybe<Client_Industries>;
  /** delete data from the table: "client_jobs" */
  delete_client_jobs?: Maybe<Client_Jobs_Mutation_Response>;
  /** delete single row from the table: "client_jobs" */
  delete_client_jobs_by_pk?: Maybe<Client_Jobs>;
  /** delete data from the table: "client_locations" */
  delete_client_locations?: Maybe<Client_Locations_Mutation_Response>;
  /** delete single row from the table: "client_locations" */
  delete_client_locations_by_pk?: Maybe<Client_Locations>;
  /** delete data from the table: "client_users" */
  delete_client_users?: Maybe<Client_Users_Mutation_Response>;
  /** delete single row from the table: "client_users" */
  delete_client_users_by_pk?: Maybe<Client_Users>;
  /** delete data from the table: "clients" */
  delete_clients?: Maybe<Clients_Mutation_Response>;
  /** delete single row from the table: "clients" */
  delete_clients_by_pk?: Maybe<Clients>;
  /** delete data from the table: "contacts" */
  delete_contacts?: Maybe<Contacts_Mutation_Response>;
  /** delete single row from the table: "contacts" */
  delete_contacts_by_pk?: Maybe<Contacts>;
  /** delete data from the table: "courses" */
  delete_courses?: Maybe<Courses_Mutation_Response>;
  /** delete single row from the table: "courses" */
  delete_courses_by_pk?: Maybe<Courses>;
  /** delete data from the table: "degrees" */
  delete_degrees?: Maybe<Degrees_Mutation_Response>;
  /** delete single row from the table: "degrees" */
  delete_degrees_by_pk?: Maybe<Degrees>;
  /** delete data from the table: "enterprise_clients" */
  delete_enterprise_clients?: Maybe<Enterprise_Clients_Mutation_Response>;
  /** delete single row from the table: "enterprise_clients" */
  delete_enterprise_clients_by_pk?: Maybe<Enterprise_Clients>;
  /** delete data from the table: "enterprise_locations" */
  delete_enterprise_locations?: Maybe<Enterprise_Locations_Mutation_Response>;
  /** delete single row from the table: "enterprise_locations" */
  delete_enterprise_locations_by_pk?: Maybe<Enterprise_Locations>;
  /** delete data from the table: "enterprises" */
  delete_enterprises?: Maybe<Enterprises_Mutation_Response>;
  /** delete single row from the table: "enterprises" */
  delete_enterprises_by_pk?: Maybe<Enterprises>;
  /** delete data from the table: "events" */
  delete_events?: Maybe<Events_Mutation_Response>;
  /** delete single row from the table: "events" */
  delete_events_by_pk?: Maybe<Events>;
  /** delete data from the table: "exclusive_candidates" */
  delete_exclusive_candidates?: Maybe<Exclusive_Candidates_Mutation_Response>;
  /** delete single row from the table: "exclusive_candidates" */
  delete_exclusive_candidates_by_pk?: Maybe<Exclusive_Candidates>;
  /** delete data from the table: "feature_popups" */
  delete_feature_popups?: Maybe<Feature_Popups_Mutation_Response>;
  /** delete single row from the table: "feature_popups" */
  delete_feature_popups_by_pk?: Maybe<Feature_Popups>;
  /** delete data from the table: "geometry_columns" */
  delete_geometry_columns?: Maybe<Geometry_Columns_Mutation_Response>;
  /** delete data from the table: "industries" */
  delete_industries?: Maybe<Industries_Mutation_Response>;
  /** delete single row from the table: "industries" */
  delete_industries_by_pk?: Maybe<Industries>;
  /** delete data from the table: "interview_participants" */
  delete_interview_participants?: Maybe<Interview_Participants_Mutation_Response>;
  /** delete single row from the table: "interview_participants" */
  delete_interview_participants_by_pk?: Maybe<Interview_Participants>;
  /** delete data from the table: "interviews" */
  delete_interviews?: Maybe<Interviews_Mutation_Response>;
  /** delete single row from the table: "interviews" */
  delete_interviews_by_pk?: Maybe<Interviews>;
  /** delete data from the table: "job_candidates" */
  delete_job_candidates?: Maybe<Job_Candidates_Mutation_Response>;
  /** delete single row from the table: "job_candidates" */
  delete_job_candidates_by_pk?: Maybe<Job_Candidates>;
  /** delete data from the table: "job_locations" */
  delete_job_locations?: Maybe<Job_Locations_Mutation_Response>;
  /** delete single row from the table: "job_locations" */
  delete_job_locations_by_pk?: Maybe<Job_Locations>;
  /** delete data from the table: "jobs" */
  delete_jobs?: Maybe<Jobs_Mutation_Response>;
  /** delete single row from the table: "jobs" */
  delete_jobs_by_pk?: Maybe<Jobs>;
  /** delete data from the table: "locations" */
  delete_locations?: Maybe<Locations_Mutation_Response>;
  /** delete single row from the table: "locations" */
  delete_locations_by_pk?: Maybe<Locations>;
  /** delete data from the table: "majors" */
  delete_majors?: Maybe<Majors_Mutation_Response>;
  /** delete single row from the table: "majors" */
  delete_majors_by_pk?: Maybe<Majors>;
  /** delete data from the table: "offers" */
  delete_offers?: Maybe<Offers_Mutation_Response>;
  /** delete single row from the table: "offers" */
  delete_offers_by_pk?: Maybe<Offers>;
  /** delete data from the table: "report_targets" */
  delete_report_targets?: Maybe<Report_Targets_Mutation_Response>;
  /** delete single row from the table: "report_targets" */
  delete_report_targets_by_pk?: Maybe<Report_Targets>;
  /** delete data from the table: "short_urls" */
  delete_short_urls?: Maybe<Short_Urls_Mutation_Response>;
  /** delete single row from the table: "short_urls" */
  delete_short_urls_by_pk?: Maybe<Short_Urls>;
  /** delete data from the table: "shortlists" */
  delete_shortlists?: Maybe<Shortlists_Mutation_Response>;
  /** delete single row from the table: "shortlists" */
  delete_shortlists_by_pk?: Maybe<Shortlists>;
  /** delete data from the table: "skills" */
  delete_skills?: Maybe<Skills_Mutation_Response>;
  /** delete single row from the table: "skills" */
  delete_skills_by_pk?: Maybe<Skills>;
  /** delete data from the table: "spatial_ref_sys" */
  delete_spatial_ref_sys?: Maybe<Spatial_Ref_Sys_Mutation_Response>;
  /** delete single row from the table: "spatial_ref_sys" */
  delete_spatial_ref_sys_by_pk?: Maybe<Spatial_Ref_Sys>;
  /** delete data from the table: "tenant_candidates" */
  delete_tenant_candidates?: Maybe<Tenant_Candidates_Mutation_Response>;
  /** delete single row from the table: "tenant_candidates" */
  delete_tenant_candidates_by_pk?: Maybe<Tenant_Candidates>;
  /** delete data from the table: "tenant_locations" */
  delete_tenant_locations?: Maybe<Tenant_Locations_Mutation_Response>;
  /** delete single row from the table: "tenant_locations" */
  delete_tenant_locations_by_pk?: Maybe<Tenant_Locations>;
  /** delete data from the table: "tenant_users" */
  delete_tenant_users?: Maybe<Tenant_Users_Mutation_Response>;
  /** delete single row from the table: "tenant_users" */
  delete_tenant_users_by_pk?: Maybe<Tenant_Users>;
  /** delete data from the table: "tenants" */
  delete_tenants?: Maybe<Tenants_Mutation_Response>;
  /** delete single row from the table: "tenants" */
  delete_tenants_by_pk?: Maybe<Tenants>;
  /** delete data from the table: "universities" */
  delete_universities?: Maybe<Universities_Mutation_Response>;
  /** delete single row from the table: "universities" */
  delete_universities_by_pk?: Maybe<Universities>;
  /** delete data from the table: "users" */
  delete_users?: Maybe<Users_Mutation_Response>;
  /** delete single row from the table: "users" */
  delete_users_by_pk?: Maybe<Users>;
  /** fb_server_event */
  fb_server_event: Scalars['uuid'];
  /** insert data into the table: "brimstone_event_feedback" */
  insert_brimstone_event_feedback?: Maybe<Brimstone_Event_Feedback_Mutation_Response>;
  /** insert a single row into the table: "brimstone_event_feedback" */
  insert_brimstone_event_feedback_one?: Maybe<Brimstone_Event_Feedback>;
  /** insert data into the table: "brimstone_event_speakers" */
  insert_brimstone_event_speakers?: Maybe<Brimstone_Event_Speakers_Mutation_Response>;
  /** insert a single row into the table: "brimstone_event_speakers" */
  insert_brimstone_event_speakers_one?: Maybe<Brimstone_Event_Speakers>;
  /** insert data into the table: "brimstone_events" */
  insert_brimstone_events?: Maybe<Brimstone_Events_Mutation_Response>;
  /** insert a single row into the table: "brimstone_events" */
  insert_brimstone_events_one?: Maybe<Brimstone_Events>;
  /** insert data into the table: "brimstone_speakers" */
  insert_brimstone_speakers?: Maybe<Brimstone_Speakers_Mutation_Response>;
  /** insert a single row into the table: "brimstone_speakers" */
  insert_brimstone_speakers_one?: Maybe<Brimstone_Speakers>;
  /** insert data into the table: "candidate_courses" */
  insert_candidate_courses?: Maybe<Candidate_Courses_Mutation_Response>;
  /** insert a single row into the table: "candidate_courses" */
  insert_candidate_courses_one?: Maybe<Candidate_Courses>;
  /** insert data into the table: "candidate_degrees" */
  insert_candidate_degrees?: Maybe<Candidate_Degrees_Mutation_Response>;
  /** insert a single row into the table: "candidate_degrees" */
  insert_candidate_degrees_one?: Maybe<Candidate_Degrees>;
  /** insert data into the table: "candidate_follows" */
  insert_candidate_follows?: Maybe<Candidate_Follows_Mutation_Response>;
  /** insert a single row into the table: "candidate_follows" */
  insert_candidate_follows_one?: Maybe<Candidate_Follows>;
  /** insert data into the table: "candidate_industries" */
  insert_candidate_industries?: Maybe<Candidate_Industries_Mutation_Response>;
  /** insert a single row into the table: "candidate_industries" */
  insert_candidate_industries_one?: Maybe<Candidate_Industries>;
  /** insert data into the table: "candidate_locations" */
  insert_candidate_locations?: Maybe<Candidate_Locations_Mutation_Response>;
  /** insert a single row into the table: "candidate_locations" */
  insert_candidate_locations_one?: Maybe<Candidate_Locations>;
  /** insert data into the table: "candidate_majors" */
  insert_candidate_majors?: Maybe<Candidate_Majors_Mutation_Response>;
  /** insert a single row into the table: "candidate_majors" */
  insert_candidate_majors_one?: Maybe<Candidate_Majors>;
  /** insert data into the table: "candidate_placements" */
  insert_candidate_placements?: Maybe<Candidate_Placements_Mutation_Response>;
  /** insert a single row into the table: "candidate_placements" */
  insert_candidate_placements_one?: Maybe<Candidate_Placements>;
  /** insert data into the table: "candidate_skills" */
  insert_candidate_skills?: Maybe<Candidate_Skills_Mutation_Response>;
  /** insert a single row into the table: "candidate_skills" */
  insert_candidate_skills_one?: Maybe<Candidate_Skills>;
  /** insert data into the table: "candidates" */
  insert_candidates?: Maybe<Candidates_Mutation_Response>;
  /** insert a single row into the table: "candidates" */
  insert_candidates_one?: Maybe<Candidates>;
  /** insert data into the table: "client_industries" */
  insert_client_industries?: Maybe<Client_Industries_Mutation_Response>;
  /** insert a single row into the table: "client_industries" */
  insert_client_industries_one?: Maybe<Client_Industries>;
  /** insert data into the table: "client_jobs" */
  insert_client_jobs?: Maybe<Client_Jobs_Mutation_Response>;
  /** insert a single row into the table: "client_jobs" */
  insert_client_jobs_one?: Maybe<Client_Jobs>;
  /** insert data into the table: "client_locations" */
  insert_client_locations?: Maybe<Client_Locations_Mutation_Response>;
  /** insert a single row into the table: "client_locations" */
  insert_client_locations_one?: Maybe<Client_Locations>;
  /** insert data into the table: "client_users" */
  insert_client_users?: Maybe<Client_Users_Mutation_Response>;
  /** insert a single row into the table: "client_users" */
  insert_client_users_one?: Maybe<Client_Users>;
  /** insert data into the table: "clients" */
  insert_clients?: Maybe<Clients_Mutation_Response>;
  /** insert a single row into the table: "clients" */
  insert_clients_one?: Maybe<Clients>;
  /** insert data into the table: "contacts" */
  insert_contacts?: Maybe<Contacts_Mutation_Response>;
  /** insert a single row into the table: "contacts" */
  insert_contacts_one?: Maybe<Contacts>;
  /** insert data into the table: "courses" */
  insert_courses?: Maybe<Courses_Mutation_Response>;
  /** insert a single row into the table: "courses" */
  insert_courses_one?: Maybe<Courses>;
  /** insert data into the table: "degrees" */
  insert_degrees?: Maybe<Degrees_Mutation_Response>;
  /** insert a single row into the table: "degrees" */
  insert_degrees_one?: Maybe<Degrees>;
  /** insert data into the table: "enterprise_clients" */
  insert_enterprise_clients?: Maybe<Enterprise_Clients_Mutation_Response>;
  /** insert a single row into the table: "enterprise_clients" */
  insert_enterprise_clients_one?: Maybe<Enterprise_Clients>;
  /** insert data into the table: "enterprise_locations" */
  insert_enterprise_locations?: Maybe<Enterprise_Locations_Mutation_Response>;
  /** insert a single row into the table: "enterprise_locations" */
  insert_enterprise_locations_one?: Maybe<Enterprise_Locations>;
  /** insert data into the table: "enterprises" */
  insert_enterprises?: Maybe<Enterprises_Mutation_Response>;
  /** insert a single row into the table: "enterprises" */
  insert_enterprises_one?: Maybe<Enterprises>;
  /** insert data into the table: "events" */
  insert_events?: Maybe<Events_Mutation_Response>;
  /** insert a single row into the table: "events" */
  insert_events_one?: Maybe<Events>;
  /** insert data into the table: "exclusive_candidates" */
  insert_exclusive_candidates?: Maybe<Exclusive_Candidates_Mutation_Response>;
  /** insert a single row into the table: "exclusive_candidates" */
  insert_exclusive_candidates_one?: Maybe<Exclusive_Candidates>;
  /** insert data into the table: "feature_popups" */
  insert_feature_popups?: Maybe<Feature_Popups_Mutation_Response>;
  /** insert a single row into the table: "feature_popups" */
  insert_feature_popups_one?: Maybe<Feature_Popups>;
  /** insert data into the table: "geometry_columns" */
  insert_geometry_columns?: Maybe<Geometry_Columns_Mutation_Response>;
  /** insert a single row into the table: "geometry_columns" */
  insert_geometry_columns_one?: Maybe<Geometry_Columns>;
  /** insert data into the table: "industries" */
  insert_industries?: Maybe<Industries_Mutation_Response>;
  /** insert a single row into the table: "industries" */
  insert_industries_one?: Maybe<Industries>;
  /** insert data into the table: "interview_participants" */
  insert_interview_participants?: Maybe<Interview_Participants_Mutation_Response>;
  /** insert a single row into the table: "interview_participants" */
  insert_interview_participants_one?: Maybe<Interview_Participants>;
  /** insert data into the table: "interviews" */
  insert_interviews?: Maybe<Interviews_Mutation_Response>;
  /** insert a single row into the table: "interviews" */
  insert_interviews_one?: Maybe<Interviews>;
  /** insert data into the table: "job_candidates" */
  insert_job_candidates?: Maybe<Job_Candidates_Mutation_Response>;
  /** insert a single row into the table: "job_candidates" */
  insert_job_candidates_one?: Maybe<Job_Candidates>;
  /** insert data into the table: "job_locations" */
  insert_job_locations?: Maybe<Job_Locations_Mutation_Response>;
  /** insert a single row into the table: "job_locations" */
  insert_job_locations_one?: Maybe<Job_Locations>;
  /** insert data into the table: "jobs" */
  insert_jobs?: Maybe<Jobs_Mutation_Response>;
  /** insert a single row into the table: "jobs" */
  insert_jobs_one?: Maybe<Jobs>;
  /** insert data into the table: "locations" */
  insert_locations?: Maybe<Locations_Mutation_Response>;
  /** insert a single row into the table: "locations" */
  insert_locations_one?: Maybe<Locations>;
  /** insert data into the table: "majors" */
  insert_majors?: Maybe<Majors_Mutation_Response>;
  /** insert a single row into the table: "majors" */
  insert_majors_one?: Maybe<Majors>;
  /** insert data into the table: "offers" */
  insert_offers?: Maybe<Offers_Mutation_Response>;
  /** insert a single row into the table: "offers" */
  insert_offers_one?: Maybe<Offers>;
  /** insert data into the table: "report_targets" */
  insert_report_targets?: Maybe<Report_Targets_Mutation_Response>;
  /** insert a single row into the table: "report_targets" */
  insert_report_targets_one?: Maybe<Report_Targets>;
  /** insert data into the table: "short_urls" */
  insert_short_urls?: Maybe<Short_Urls_Mutation_Response>;
  /** insert a single row into the table: "short_urls" */
  insert_short_urls_one?: Maybe<Short_Urls>;
  /** insert data into the table: "shortlists" */
  insert_shortlists?: Maybe<Shortlists_Mutation_Response>;
  /** insert a single row into the table: "shortlists" */
  insert_shortlists_one?: Maybe<Shortlists>;
  /** insert data into the table: "skills" */
  insert_skills?: Maybe<Skills_Mutation_Response>;
  /** insert a single row into the table: "skills" */
  insert_skills_one?: Maybe<Skills>;
  /** insert data into the table: "spatial_ref_sys" */
  insert_spatial_ref_sys?: Maybe<Spatial_Ref_Sys_Mutation_Response>;
  /** insert a single row into the table: "spatial_ref_sys" */
  insert_spatial_ref_sys_one?: Maybe<Spatial_Ref_Sys>;
  /** insert data into the table: "tenant_candidates" */
  insert_tenant_candidates?: Maybe<Tenant_Candidates_Mutation_Response>;
  /** insert a single row into the table: "tenant_candidates" */
  insert_tenant_candidates_one?: Maybe<Tenant_Candidates>;
  /** insert data into the table: "tenant_locations" */
  insert_tenant_locations?: Maybe<Tenant_Locations_Mutation_Response>;
  /** insert a single row into the table: "tenant_locations" */
  insert_tenant_locations_one?: Maybe<Tenant_Locations>;
  /** insert data into the table: "tenant_users" */
  insert_tenant_users?: Maybe<Tenant_Users_Mutation_Response>;
  /** insert a single row into the table: "tenant_users" */
  insert_tenant_users_one?: Maybe<Tenant_Users>;
  /** insert data into the table: "tenants" */
  insert_tenants?: Maybe<Tenants_Mutation_Response>;
  /** insert a single row into the table: "tenants" */
  insert_tenants_one?: Maybe<Tenants>;
  /** insert data into the table: "universities" */
  insert_universities?: Maybe<Universities_Mutation_Response>;
  /** insert a single row into the table: "universities" */
  insert_universities_one?: Maybe<Universities>;
  /** insert data into the table: "users" */
  insert_users?: Maybe<Users_Mutation_Response>;
  /** insert a single row into the table: "users" */
  insert_users_one?: Maybe<Users>;
  /** Nylas - Availability for a Single Meeting */
  nylas_post_calendar_availability?: Maybe<Nylas_PostCalendarAvailabilityOutput>;
  /** Nylas - Create an Event */
  nylas_post_event?: Maybe<Nylas_PostEventOutput>;
  /** send email to a candidate who was viewed for the first time by a client */
  send_candidate_first_viewed_email: Scalars['Boolean'];
  /** send_candidate_no_show_email */
  send_candidate_no_show_email: Scalars['Boolean'];
  track: Scalars['uuid'];
  /** update data of the table: "brimstone_event_feedback" */
  update_brimstone_event_feedback?: Maybe<Brimstone_Event_Feedback_Mutation_Response>;
  /** update single row of the table: "brimstone_event_feedback" */
  update_brimstone_event_feedback_by_pk?: Maybe<Brimstone_Event_Feedback>;
  /** update multiples rows of table: "brimstone_event_feedback" */
  update_brimstone_event_feedback_many?: Maybe<Array<Maybe<Brimstone_Event_Feedback_Mutation_Response>>>;
  /** update data of the table: "brimstone_event_speakers" */
  update_brimstone_event_speakers?: Maybe<Brimstone_Event_Speakers_Mutation_Response>;
  /** update single row of the table: "brimstone_event_speakers" */
  update_brimstone_event_speakers_by_pk?: Maybe<Brimstone_Event_Speakers>;
  /** update multiples rows of table: "brimstone_event_speakers" */
  update_brimstone_event_speakers_many?: Maybe<Array<Maybe<Brimstone_Event_Speakers_Mutation_Response>>>;
  /** update data of the table: "brimstone_events" */
  update_brimstone_events?: Maybe<Brimstone_Events_Mutation_Response>;
  /** update single row of the table: "brimstone_events" */
  update_brimstone_events_by_pk?: Maybe<Brimstone_Events>;
  /** update multiples rows of table: "brimstone_events" */
  update_brimstone_events_many?: Maybe<Array<Maybe<Brimstone_Events_Mutation_Response>>>;
  /** update data of the table: "brimstone_speakers" */
  update_brimstone_speakers?: Maybe<Brimstone_Speakers_Mutation_Response>;
  /** update single row of the table: "brimstone_speakers" */
  update_brimstone_speakers_by_pk?: Maybe<Brimstone_Speakers>;
  /** update multiples rows of table: "brimstone_speakers" */
  update_brimstone_speakers_many?: Maybe<Array<Maybe<Brimstone_Speakers_Mutation_Response>>>;
  /** update data of the table: "candidate_courses" */
  update_candidate_courses?: Maybe<Candidate_Courses_Mutation_Response>;
  /** update single row of the table: "candidate_courses" */
  update_candidate_courses_by_pk?: Maybe<Candidate_Courses>;
  /** update multiples rows of table: "candidate_courses" */
  update_candidate_courses_many?: Maybe<Array<Maybe<Candidate_Courses_Mutation_Response>>>;
  /** update data of the table: "candidate_degrees" */
  update_candidate_degrees?: Maybe<Candidate_Degrees_Mutation_Response>;
  /** update single row of the table: "candidate_degrees" */
  update_candidate_degrees_by_pk?: Maybe<Candidate_Degrees>;
  /** update multiples rows of table: "candidate_degrees" */
  update_candidate_degrees_many?: Maybe<Array<Maybe<Candidate_Degrees_Mutation_Response>>>;
  /** update data of the table: "candidate_follows" */
  update_candidate_follows?: Maybe<Candidate_Follows_Mutation_Response>;
  /** update single row of the table: "candidate_follows" */
  update_candidate_follows_by_pk?: Maybe<Candidate_Follows>;
  /** update multiples rows of table: "candidate_follows" */
  update_candidate_follows_many?: Maybe<Array<Maybe<Candidate_Follows_Mutation_Response>>>;
  /** update data of the table: "candidate_industries" */
  update_candidate_industries?: Maybe<Candidate_Industries_Mutation_Response>;
  /** update single row of the table: "candidate_industries" */
  update_candidate_industries_by_pk?: Maybe<Candidate_Industries>;
  /** update multiples rows of table: "candidate_industries" */
  update_candidate_industries_many?: Maybe<Array<Maybe<Candidate_Industries_Mutation_Response>>>;
  /** update data of the table: "candidate_locations" */
  update_candidate_locations?: Maybe<Candidate_Locations_Mutation_Response>;
  /** update single row of the table: "candidate_locations" */
  update_candidate_locations_by_pk?: Maybe<Candidate_Locations>;
  /** update multiples rows of table: "candidate_locations" */
  update_candidate_locations_many?: Maybe<Array<Maybe<Candidate_Locations_Mutation_Response>>>;
  /** update data of the table: "candidate_majors" */
  update_candidate_majors?: Maybe<Candidate_Majors_Mutation_Response>;
  /** update single row of the table: "candidate_majors" */
  update_candidate_majors_by_pk?: Maybe<Candidate_Majors>;
  /** update multiples rows of table: "candidate_majors" */
  update_candidate_majors_many?: Maybe<Array<Maybe<Candidate_Majors_Mutation_Response>>>;
  /** update data of the table: "candidate_placements" */
  update_candidate_placements?: Maybe<Candidate_Placements_Mutation_Response>;
  /** update single row of the table: "candidate_placements" */
  update_candidate_placements_by_pk?: Maybe<Candidate_Placements>;
  /** update multiples rows of table: "candidate_placements" */
  update_candidate_placements_many?: Maybe<Array<Maybe<Candidate_Placements_Mutation_Response>>>;
  /** update data of the table: "candidate_skills" */
  update_candidate_skills?: Maybe<Candidate_Skills_Mutation_Response>;
  /** update single row of the table: "candidate_skills" */
  update_candidate_skills_by_pk?: Maybe<Candidate_Skills>;
  /** update multiples rows of table: "candidate_skills" */
  update_candidate_skills_many?: Maybe<Array<Maybe<Candidate_Skills_Mutation_Response>>>;
  /** update data of the table: "candidates" */
  update_candidates?: Maybe<Candidates_Mutation_Response>;
  /** update single row of the table: "candidates" */
  update_candidates_by_pk?: Maybe<Candidates>;
  /** update multiples rows of table: "candidates" */
  update_candidates_many?: Maybe<Array<Maybe<Candidates_Mutation_Response>>>;
  /** update data of the table: "client_industries" */
  update_client_industries?: Maybe<Client_Industries_Mutation_Response>;
  /** update single row of the table: "client_industries" */
  update_client_industries_by_pk?: Maybe<Client_Industries>;
  /** update multiples rows of table: "client_industries" */
  update_client_industries_many?: Maybe<Array<Maybe<Client_Industries_Mutation_Response>>>;
  /** update data of the table: "client_jobs" */
  update_client_jobs?: Maybe<Client_Jobs_Mutation_Response>;
  /** update single row of the table: "client_jobs" */
  update_client_jobs_by_pk?: Maybe<Client_Jobs>;
  /** update multiples rows of table: "client_jobs" */
  update_client_jobs_many?: Maybe<Array<Maybe<Client_Jobs_Mutation_Response>>>;
  /** update data of the table: "client_locations" */
  update_client_locations?: Maybe<Client_Locations_Mutation_Response>;
  /** update single row of the table: "client_locations" */
  update_client_locations_by_pk?: Maybe<Client_Locations>;
  /** update multiples rows of table: "client_locations" */
  update_client_locations_many?: Maybe<Array<Maybe<Client_Locations_Mutation_Response>>>;
  /** update data of the table: "client_users" */
  update_client_users?: Maybe<Client_Users_Mutation_Response>;
  /** update single row of the table: "client_users" */
  update_client_users_by_pk?: Maybe<Client_Users>;
  /** update multiples rows of table: "client_users" */
  update_client_users_many?: Maybe<Array<Maybe<Client_Users_Mutation_Response>>>;
  /** update data of the table: "clients" */
  update_clients?: Maybe<Clients_Mutation_Response>;
  /** update single row of the table: "clients" */
  update_clients_by_pk?: Maybe<Clients>;
  /** update multiples rows of table: "clients" */
  update_clients_many?: Maybe<Array<Maybe<Clients_Mutation_Response>>>;
  /** update data of the table: "contacts" */
  update_contacts?: Maybe<Contacts_Mutation_Response>;
  /** update single row of the table: "contacts" */
  update_contacts_by_pk?: Maybe<Contacts>;
  /** update multiples rows of table: "contacts" */
  update_contacts_many?: Maybe<Array<Maybe<Contacts_Mutation_Response>>>;
  /** update data of the table: "courses" */
  update_courses?: Maybe<Courses_Mutation_Response>;
  /** update single row of the table: "courses" */
  update_courses_by_pk?: Maybe<Courses>;
  /** update multiples rows of table: "courses" */
  update_courses_many?: Maybe<Array<Maybe<Courses_Mutation_Response>>>;
  /** update data of the table: "degrees" */
  update_degrees?: Maybe<Degrees_Mutation_Response>;
  /** update single row of the table: "degrees" */
  update_degrees_by_pk?: Maybe<Degrees>;
  /** update multiples rows of table: "degrees" */
  update_degrees_many?: Maybe<Array<Maybe<Degrees_Mutation_Response>>>;
  /** update data of the table: "enterprise_clients" */
  update_enterprise_clients?: Maybe<Enterprise_Clients_Mutation_Response>;
  /** update single row of the table: "enterprise_clients" */
  update_enterprise_clients_by_pk?: Maybe<Enterprise_Clients>;
  /** update multiples rows of table: "enterprise_clients" */
  update_enterprise_clients_many?: Maybe<Array<Maybe<Enterprise_Clients_Mutation_Response>>>;
  /** update data of the table: "enterprise_locations" */
  update_enterprise_locations?: Maybe<Enterprise_Locations_Mutation_Response>;
  /** update single row of the table: "enterprise_locations" */
  update_enterprise_locations_by_pk?: Maybe<Enterprise_Locations>;
  /** update multiples rows of table: "enterprise_locations" */
  update_enterprise_locations_many?: Maybe<Array<Maybe<Enterprise_Locations_Mutation_Response>>>;
  /** update data of the table: "enterprises" */
  update_enterprises?: Maybe<Enterprises_Mutation_Response>;
  /** update single row of the table: "enterprises" */
  update_enterprises_by_pk?: Maybe<Enterprises>;
  /** update multiples rows of table: "enterprises" */
  update_enterprises_many?: Maybe<Array<Maybe<Enterprises_Mutation_Response>>>;
  /** update data of the table: "events" */
  update_events?: Maybe<Events_Mutation_Response>;
  /** update single row of the table: "events" */
  update_events_by_pk?: Maybe<Events>;
  /** update multiples rows of table: "events" */
  update_events_many?: Maybe<Array<Maybe<Events_Mutation_Response>>>;
  /** update data of the table: "exclusive_candidates" */
  update_exclusive_candidates?: Maybe<Exclusive_Candidates_Mutation_Response>;
  /** update single row of the table: "exclusive_candidates" */
  update_exclusive_candidates_by_pk?: Maybe<Exclusive_Candidates>;
  /** update multiples rows of table: "exclusive_candidates" */
  update_exclusive_candidates_many?: Maybe<Array<Maybe<Exclusive_Candidates_Mutation_Response>>>;
  /** update data of the table: "feature_popups" */
  update_feature_popups?: Maybe<Feature_Popups_Mutation_Response>;
  /** update single row of the table: "feature_popups" */
  update_feature_popups_by_pk?: Maybe<Feature_Popups>;
  /** update multiples rows of table: "feature_popups" */
  update_feature_popups_many?: Maybe<Array<Maybe<Feature_Popups_Mutation_Response>>>;
  /** update data of the table: "geometry_columns" */
  update_geometry_columns?: Maybe<Geometry_Columns_Mutation_Response>;
  /** update multiples rows of table: "geometry_columns" */
  update_geometry_columns_many?: Maybe<Array<Maybe<Geometry_Columns_Mutation_Response>>>;
  /** update data of the table: "industries" */
  update_industries?: Maybe<Industries_Mutation_Response>;
  /** update single row of the table: "industries" */
  update_industries_by_pk?: Maybe<Industries>;
  /** update multiples rows of table: "industries" */
  update_industries_many?: Maybe<Array<Maybe<Industries_Mutation_Response>>>;
  /** update data of the table: "interview_participants" */
  update_interview_participants?: Maybe<Interview_Participants_Mutation_Response>;
  /** update single row of the table: "interview_participants" */
  update_interview_participants_by_pk?: Maybe<Interview_Participants>;
  /** update multiples rows of table: "interview_participants" */
  update_interview_participants_many?: Maybe<Array<Maybe<Interview_Participants_Mutation_Response>>>;
  /** update data of the table: "interviews" */
  update_interviews?: Maybe<Interviews_Mutation_Response>;
  /** update single row of the table: "interviews" */
  update_interviews_by_pk?: Maybe<Interviews>;
  /** update multiples rows of table: "interviews" */
  update_interviews_many?: Maybe<Array<Maybe<Interviews_Mutation_Response>>>;
  /** update data of the table: "job_candidates" */
  update_job_candidates?: Maybe<Job_Candidates_Mutation_Response>;
  /** update single row of the table: "job_candidates" */
  update_job_candidates_by_pk?: Maybe<Job_Candidates>;
  /** update multiples rows of table: "job_candidates" */
  update_job_candidates_many?: Maybe<Array<Maybe<Job_Candidates_Mutation_Response>>>;
  /** update data of the table: "job_locations" */
  update_job_locations?: Maybe<Job_Locations_Mutation_Response>;
  /** update single row of the table: "job_locations" */
  update_job_locations_by_pk?: Maybe<Job_Locations>;
  /** update multiples rows of table: "job_locations" */
  update_job_locations_many?: Maybe<Array<Maybe<Job_Locations_Mutation_Response>>>;
  /** update data of the table: "jobs" */
  update_jobs?: Maybe<Jobs_Mutation_Response>;
  /** update single row of the table: "jobs" */
  update_jobs_by_pk?: Maybe<Jobs>;
  /** update multiples rows of table: "jobs" */
  update_jobs_many?: Maybe<Array<Maybe<Jobs_Mutation_Response>>>;
  /** update data of the table: "locations" */
  update_locations?: Maybe<Locations_Mutation_Response>;
  /** update single row of the table: "locations" */
  update_locations_by_pk?: Maybe<Locations>;
  /** update multiples rows of table: "locations" */
  update_locations_many?: Maybe<Array<Maybe<Locations_Mutation_Response>>>;
  /** update data of the table: "majors" */
  update_majors?: Maybe<Majors_Mutation_Response>;
  /** update single row of the table: "majors" */
  update_majors_by_pk?: Maybe<Majors>;
  /** update multiples rows of table: "majors" */
  update_majors_many?: Maybe<Array<Maybe<Majors_Mutation_Response>>>;
  /** update data of the table: "offers" */
  update_offers?: Maybe<Offers_Mutation_Response>;
  /** update single row of the table: "offers" */
  update_offers_by_pk?: Maybe<Offers>;
  /** update multiples rows of table: "offers" */
  update_offers_many?: Maybe<Array<Maybe<Offers_Mutation_Response>>>;
  /** update data of the table: "report_targets" */
  update_report_targets?: Maybe<Report_Targets_Mutation_Response>;
  /** update single row of the table: "report_targets" */
  update_report_targets_by_pk?: Maybe<Report_Targets>;
  /** update multiples rows of table: "report_targets" */
  update_report_targets_many?: Maybe<Array<Maybe<Report_Targets_Mutation_Response>>>;
  /** update data of the table: "short_urls" */
  update_short_urls?: Maybe<Short_Urls_Mutation_Response>;
  /** update single row of the table: "short_urls" */
  update_short_urls_by_pk?: Maybe<Short_Urls>;
  /** update multiples rows of table: "short_urls" */
  update_short_urls_many?: Maybe<Array<Maybe<Short_Urls_Mutation_Response>>>;
  /** update data of the table: "shortlists" */
  update_shortlists?: Maybe<Shortlists_Mutation_Response>;
  /** update single row of the table: "shortlists" */
  update_shortlists_by_pk?: Maybe<Shortlists>;
  /** update multiples rows of table: "shortlists" */
  update_shortlists_many?: Maybe<Array<Maybe<Shortlists_Mutation_Response>>>;
  /** update data of the table: "skills" */
  update_skills?: Maybe<Skills_Mutation_Response>;
  /** update single row of the table: "skills" */
  update_skills_by_pk?: Maybe<Skills>;
  /** update multiples rows of table: "skills" */
  update_skills_many?: Maybe<Array<Maybe<Skills_Mutation_Response>>>;
  /** update data of the table: "spatial_ref_sys" */
  update_spatial_ref_sys?: Maybe<Spatial_Ref_Sys_Mutation_Response>;
  /** update single row of the table: "spatial_ref_sys" */
  update_spatial_ref_sys_by_pk?: Maybe<Spatial_Ref_Sys>;
  /** update multiples rows of table: "spatial_ref_sys" */
  update_spatial_ref_sys_many?: Maybe<Array<Maybe<Spatial_Ref_Sys_Mutation_Response>>>;
  /** update data of the table: "tenant_candidates" */
  update_tenant_candidates?: Maybe<Tenant_Candidates_Mutation_Response>;
  /** update single row of the table: "tenant_candidates" */
  update_tenant_candidates_by_pk?: Maybe<Tenant_Candidates>;
  /** update multiples rows of table: "tenant_candidates" */
  update_tenant_candidates_many?: Maybe<Array<Maybe<Tenant_Candidates_Mutation_Response>>>;
  /** update data of the table: "tenant_locations" */
  update_tenant_locations?: Maybe<Tenant_Locations_Mutation_Response>;
  /** update single row of the table: "tenant_locations" */
  update_tenant_locations_by_pk?: Maybe<Tenant_Locations>;
  /** update multiples rows of table: "tenant_locations" */
  update_tenant_locations_many?: Maybe<Array<Maybe<Tenant_Locations_Mutation_Response>>>;
  /** update data of the table: "tenant_users" */
  update_tenant_users?: Maybe<Tenant_Users_Mutation_Response>;
  /** update single row of the table: "tenant_users" */
  update_tenant_users_by_pk?: Maybe<Tenant_Users>;
  /** update multiples rows of table: "tenant_users" */
  update_tenant_users_many?: Maybe<Array<Maybe<Tenant_Users_Mutation_Response>>>;
  /** update data of the table: "tenants" */
  update_tenants?: Maybe<Tenants_Mutation_Response>;
  /** update single row of the table: "tenants" */
  update_tenants_by_pk?: Maybe<Tenants>;
  /** update multiples rows of table: "tenants" */
  update_tenants_many?: Maybe<Array<Maybe<Tenants_Mutation_Response>>>;
  /** update data of the table: "universities" */
  update_universities?: Maybe<Universities_Mutation_Response>;
  /** update single row of the table: "universities" */
  update_universities_by_pk?: Maybe<Universities>;
  /** update multiples rows of table: "universities" */
  update_universities_many?: Maybe<Array<Maybe<Universities_Mutation_Response>>>;
  /** update data of the table: "users" */
  update_users?: Maybe<Users_Mutation_Response>;
  /** update single row of the table: "users" */
  update_users_by_pk?: Maybe<Users>;
  /** update multiples rows of table: "users" */
  update_users_many?: Maybe<Array<Maybe<Users_Mutation_Response>>>;
};


/** mutation root */
export type Mutation_RootDelete_Brimstone_Event_FeedbackArgs = {
  where: Brimstone_Event_Feedback_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Brimstone_Event_Feedback_By_PkArgs = {
  id: Scalars['uuid'];
};


/** mutation root */
export type Mutation_RootDelete_Brimstone_Event_SpeakersArgs = {
  where: Brimstone_Event_Speakers_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Brimstone_Event_Speakers_By_PkArgs = {
  id: Scalars['uuid'];
};


/** mutation root */
export type Mutation_RootDelete_Brimstone_EventsArgs = {
  where: Brimstone_Events_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Brimstone_Events_By_PkArgs = {
  id: Scalars['uuid'];
};


/** mutation root */
export type Mutation_RootDelete_Brimstone_SpeakersArgs = {
  where: Brimstone_Speakers_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Brimstone_Speakers_By_PkArgs = {
  id: Scalars['uuid'];
};


/** mutation root */
export type Mutation_RootDelete_Candidate_CoursesArgs = {
  where: Candidate_Courses_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Candidate_Courses_By_PkArgs = {
  candidate_id: Scalars['uuid'];
  course_id: Scalars['uuid'];
};


/** mutation root */
export type Mutation_RootDelete_Candidate_DegreesArgs = {
  where: Candidate_Degrees_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Candidate_Degrees_By_PkArgs = {
  id: Scalars['uuid'];
};


/** mutation root */
export type Mutation_RootDelete_Candidate_FollowsArgs = {
  where: Candidate_Follows_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Candidate_Follows_By_PkArgs = {
  candidate_id: Scalars['uuid'];
  enterprise_id: Scalars['uuid'];
};


/** mutation root */
export type Mutation_RootDelete_Candidate_IndustriesArgs = {
  where: Candidate_Industries_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Candidate_Industries_By_PkArgs = {
  id: Scalars['uuid'];
};


/** mutation root */
export type Mutation_RootDelete_Candidate_LocationsArgs = {
  where: Candidate_Locations_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Candidate_Locations_By_PkArgs = {
  id: Scalars['uuid'];
};


/** mutation root */
export type Mutation_RootDelete_Candidate_MajorsArgs = {
  where: Candidate_Majors_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Candidate_Majors_By_PkArgs = {
  id: Scalars['uuid'];
};


/** mutation root */
export type Mutation_RootDelete_Candidate_PlacementsArgs = {
  where: Candidate_Placements_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Candidate_Placements_By_PkArgs = {
  id: Scalars['uuid'];
};


/** mutation root */
export type Mutation_RootDelete_Candidate_SkillsArgs = {
  where: Candidate_Skills_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Candidate_Skills_By_PkArgs = {
  id: Scalars['uuid'];
};


/** mutation root */
export type Mutation_RootDelete_CandidatesArgs = {
  where: Candidates_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Candidates_By_PkArgs = {
  id: Scalars['uuid'];
};


/** mutation root */
export type Mutation_RootDelete_Client_IndustriesArgs = {
  where: Client_Industries_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Client_Industries_By_PkArgs = {
  id: Scalars['uuid'];
};


/** mutation root */
export type Mutation_RootDelete_Client_JobsArgs = {
  where: Client_Jobs_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Client_Jobs_By_PkArgs = {
  id: Scalars['uuid'];
};


/** mutation root */
export type Mutation_RootDelete_Client_LocationsArgs = {
  where: Client_Locations_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Client_Locations_By_PkArgs = {
  id: Scalars['uuid'];
};


/** mutation root */
export type Mutation_RootDelete_Client_UsersArgs = {
  where: Client_Users_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Client_Users_By_PkArgs = {
  id: Scalars['uuid'];
};


/** mutation root */
export type Mutation_RootDelete_ClientsArgs = {
  where: Clients_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Clients_By_PkArgs = {
  id: Scalars['uuid'];
};


/** mutation root */
export type Mutation_RootDelete_ContactsArgs = {
  where: Contacts_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Contacts_By_PkArgs = {
  id: Scalars['uuid'];
};


/** mutation root */
export type Mutation_RootDelete_CoursesArgs = {
  where: Courses_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Courses_By_PkArgs = {
  id: Scalars['uuid'];
};


/** mutation root */
export type Mutation_RootDelete_DegreesArgs = {
  where: Degrees_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Degrees_By_PkArgs = {
  id: Scalars['uuid'];
};


/** mutation root */
export type Mutation_RootDelete_Enterprise_ClientsArgs = {
  where: Enterprise_Clients_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Enterprise_Clients_By_PkArgs = {
  client_id: Scalars['uuid'];
  enterprise_id: Scalars['uuid'];
};


/** mutation root */
export type Mutation_RootDelete_Enterprise_LocationsArgs = {
  where: Enterprise_Locations_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Enterprise_Locations_By_PkArgs = {
  id: Scalars['uuid'];
};


/** mutation root */
export type Mutation_RootDelete_EnterprisesArgs = {
  where: Enterprises_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Enterprises_By_PkArgs = {
  id: Scalars['uuid'];
};


/** mutation root */
export type Mutation_RootDelete_EventsArgs = {
  where: Events_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Events_By_PkArgs = {
  id: Scalars['uuid'];
};


/** mutation root */
export type Mutation_RootDelete_Exclusive_CandidatesArgs = {
  where: Exclusive_Candidates_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Exclusive_Candidates_By_PkArgs = {
  id: Scalars['uuid'];
};


/** mutation root */
export type Mutation_RootDelete_Feature_PopupsArgs = {
  where: Feature_Popups_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Feature_Popups_By_PkArgs = {
  id: Scalars['uuid'];
};


/** mutation root */
export type Mutation_RootDelete_Geometry_ColumnsArgs = {
  where: Geometry_Columns_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_IndustriesArgs = {
  where: Industries_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Industries_By_PkArgs = {
  id: Scalars['uuid'];
};


/** mutation root */
export type Mutation_RootDelete_Interview_ParticipantsArgs = {
  where: Interview_Participants_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Interview_Participants_By_PkArgs = {
  id: Scalars['uuid'];
};


/** mutation root */
export type Mutation_RootDelete_InterviewsArgs = {
  where: Interviews_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Interviews_By_PkArgs = {
  id: Scalars['uuid'];
};


/** mutation root */
export type Mutation_RootDelete_Job_CandidatesArgs = {
  where: Job_Candidates_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Job_Candidates_By_PkArgs = {
  id: Scalars['uuid'];
};


/** mutation root */
export type Mutation_RootDelete_Job_LocationsArgs = {
  where: Job_Locations_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Job_Locations_By_PkArgs = {
  id: Scalars['uuid'];
};


/** mutation root */
export type Mutation_RootDelete_JobsArgs = {
  where: Jobs_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Jobs_By_PkArgs = {
  id: Scalars['uuid'];
};


/** mutation root */
export type Mutation_RootDelete_LocationsArgs = {
  where: Locations_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Locations_By_PkArgs = {
  id: Scalars['uuid'];
};


/** mutation root */
export type Mutation_RootDelete_MajorsArgs = {
  where: Majors_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Majors_By_PkArgs = {
  id: Scalars['uuid'];
};


/** mutation root */
export type Mutation_RootDelete_OffersArgs = {
  where: Offers_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Offers_By_PkArgs = {
  id: Scalars['uuid'];
};


/** mutation root */
export type Mutation_RootDelete_Report_TargetsArgs = {
  where: Report_Targets_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Report_Targets_By_PkArgs = {
  id: Scalars['uuid'];
};


/** mutation root */
export type Mutation_RootDelete_Short_UrlsArgs = {
  where: Short_Urls_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Short_Urls_By_PkArgs = {
  id: Scalars['uuid'];
};


/** mutation root */
export type Mutation_RootDelete_ShortlistsArgs = {
  where: Shortlists_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Shortlists_By_PkArgs = {
  id: Scalars['uuid'];
};


/** mutation root */
export type Mutation_RootDelete_SkillsArgs = {
  where: Skills_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Skills_By_PkArgs = {
  id: Scalars['uuid'];
};


/** mutation root */
export type Mutation_RootDelete_Spatial_Ref_SysArgs = {
  where: Spatial_Ref_Sys_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Spatial_Ref_Sys_By_PkArgs = {
  srid: Scalars['Int'];
};


/** mutation root */
export type Mutation_RootDelete_Tenant_CandidatesArgs = {
  where: Tenant_Candidates_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Tenant_Candidates_By_PkArgs = {
  id: Scalars['uuid'];
};


/** mutation root */
export type Mutation_RootDelete_Tenant_LocationsArgs = {
  where: Tenant_Locations_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Tenant_Locations_By_PkArgs = {
  id: Scalars['uuid'];
};


/** mutation root */
export type Mutation_RootDelete_Tenant_UsersArgs = {
  where: Tenant_Users_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Tenant_Users_By_PkArgs = {
  id: Scalars['uuid'];
};


/** mutation root */
export type Mutation_RootDelete_TenantsArgs = {
  where: Tenants_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Tenants_By_PkArgs = {
  id: Scalars['uuid'];
};


/** mutation root */
export type Mutation_RootDelete_UniversitiesArgs = {
  where: Universities_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Universities_By_PkArgs = {
  id: Scalars['uuid'];
};


/** mutation root */
export type Mutation_RootDelete_UsersArgs = {
  where: Users_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Users_By_PkArgs = {
  id: Scalars['uuid'];
};


/** mutation root */
export type Mutation_RootFb_Server_EventArgs = {
  payload: FbServerEventInput;
};


/** mutation root */
export type Mutation_RootInsert_Brimstone_Event_FeedbackArgs = {
  objects: Array<Brimstone_Event_Feedback_Insert_Input>;
  on_conflict?: Maybe<Brimstone_Event_Feedback_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Brimstone_Event_Feedback_OneArgs = {
  object: Brimstone_Event_Feedback_Insert_Input;
  on_conflict?: Maybe<Brimstone_Event_Feedback_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Brimstone_Event_SpeakersArgs = {
  objects: Array<Brimstone_Event_Speakers_Insert_Input>;
  on_conflict?: Maybe<Brimstone_Event_Speakers_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Brimstone_Event_Speakers_OneArgs = {
  object: Brimstone_Event_Speakers_Insert_Input;
  on_conflict?: Maybe<Brimstone_Event_Speakers_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Brimstone_EventsArgs = {
  objects: Array<Brimstone_Events_Insert_Input>;
  on_conflict?: Maybe<Brimstone_Events_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Brimstone_Events_OneArgs = {
  object: Brimstone_Events_Insert_Input;
  on_conflict?: Maybe<Brimstone_Events_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Brimstone_SpeakersArgs = {
  objects: Array<Brimstone_Speakers_Insert_Input>;
  on_conflict?: Maybe<Brimstone_Speakers_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Brimstone_Speakers_OneArgs = {
  object: Brimstone_Speakers_Insert_Input;
  on_conflict?: Maybe<Brimstone_Speakers_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Candidate_CoursesArgs = {
  objects: Array<Candidate_Courses_Insert_Input>;
  on_conflict?: Maybe<Candidate_Courses_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Candidate_Courses_OneArgs = {
  object: Candidate_Courses_Insert_Input;
  on_conflict?: Maybe<Candidate_Courses_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Candidate_DegreesArgs = {
  objects: Array<Candidate_Degrees_Insert_Input>;
  on_conflict?: Maybe<Candidate_Degrees_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Candidate_Degrees_OneArgs = {
  object: Candidate_Degrees_Insert_Input;
  on_conflict?: Maybe<Candidate_Degrees_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Candidate_FollowsArgs = {
  objects: Array<Candidate_Follows_Insert_Input>;
  on_conflict?: Maybe<Candidate_Follows_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Candidate_Follows_OneArgs = {
  object: Candidate_Follows_Insert_Input;
  on_conflict?: Maybe<Candidate_Follows_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Candidate_IndustriesArgs = {
  objects: Array<Candidate_Industries_Insert_Input>;
  on_conflict?: Maybe<Candidate_Industries_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Candidate_Industries_OneArgs = {
  object: Candidate_Industries_Insert_Input;
  on_conflict?: Maybe<Candidate_Industries_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Candidate_LocationsArgs = {
  objects: Array<Candidate_Locations_Insert_Input>;
  on_conflict?: Maybe<Candidate_Locations_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Candidate_Locations_OneArgs = {
  object: Candidate_Locations_Insert_Input;
  on_conflict?: Maybe<Candidate_Locations_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Candidate_MajorsArgs = {
  objects: Array<Candidate_Majors_Insert_Input>;
  on_conflict?: Maybe<Candidate_Majors_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Candidate_Majors_OneArgs = {
  object: Candidate_Majors_Insert_Input;
  on_conflict?: Maybe<Candidate_Majors_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Candidate_PlacementsArgs = {
  objects: Array<Candidate_Placements_Insert_Input>;
  on_conflict?: Maybe<Candidate_Placements_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Candidate_Placements_OneArgs = {
  object: Candidate_Placements_Insert_Input;
  on_conflict?: Maybe<Candidate_Placements_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Candidate_SkillsArgs = {
  objects: Array<Candidate_Skills_Insert_Input>;
  on_conflict?: Maybe<Candidate_Skills_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Candidate_Skills_OneArgs = {
  object: Candidate_Skills_Insert_Input;
  on_conflict?: Maybe<Candidate_Skills_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_CandidatesArgs = {
  objects: Array<Candidates_Insert_Input>;
  on_conflict?: Maybe<Candidates_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Candidates_OneArgs = {
  object: Candidates_Insert_Input;
  on_conflict?: Maybe<Candidates_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Client_IndustriesArgs = {
  objects: Array<Client_Industries_Insert_Input>;
  on_conflict?: Maybe<Client_Industries_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Client_Industries_OneArgs = {
  object: Client_Industries_Insert_Input;
  on_conflict?: Maybe<Client_Industries_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Client_JobsArgs = {
  objects: Array<Client_Jobs_Insert_Input>;
  on_conflict?: Maybe<Client_Jobs_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Client_Jobs_OneArgs = {
  object: Client_Jobs_Insert_Input;
  on_conflict?: Maybe<Client_Jobs_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Client_LocationsArgs = {
  objects: Array<Client_Locations_Insert_Input>;
  on_conflict?: Maybe<Client_Locations_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Client_Locations_OneArgs = {
  object: Client_Locations_Insert_Input;
  on_conflict?: Maybe<Client_Locations_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Client_UsersArgs = {
  objects: Array<Client_Users_Insert_Input>;
  on_conflict?: Maybe<Client_Users_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Client_Users_OneArgs = {
  object: Client_Users_Insert_Input;
  on_conflict?: Maybe<Client_Users_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_ClientsArgs = {
  objects: Array<Clients_Insert_Input>;
  on_conflict?: Maybe<Clients_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Clients_OneArgs = {
  object: Clients_Insert_Input;
  on_conflict?: Maybe<Clients_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_ContactsArgs = {
  objects: Array<Contacts_Insert_Input>;
  on_conflict?: Maybe<Contacts_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Contacts_OneArgs = {
  object: Contacts_Insert_Input;
  on_conflict?: Maybe<Contacts_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_CoursesArgs = {
  objects: Array<Courses_Insert_Input>;
  on_conflict?: Maybe<Courses_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Courses_OneArgs = {
  object: Courses_Insert_Input;
  on_conflict?: Maybe<Courses_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_DegreesArgs = {
  objects: Array<Degrees_Insert_Input>;
  on_conflict?: Maybe<Degrees_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Degrees_OneArgs = {
  object: Degrees_Insert_Input;
  on_conflict?: Maybe<Degrees_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Enterprise_ClientsArgs = {
  objects: Array<Enterprise_Clients_Insert_Input>;
  on_conflict?: Maybe<Enterprise_Clients_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Enterprise_Clients_OneArgs = {
  object: Enterprise_Clients_Insert_Input;
  on_conflict?: Maybe<Enterprise_Clients_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Enterprise_LocationsArgs = {
  objects: Array<Enterprise_Locations_Insert_Input>;
  on_conflict?: Maybe<Enterprise_Locations_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Enterprise_Locations_OneArgs = {
  object: Enterprise_Locations_Insert_Input;
  on_conflict?: Maybe<Enterprise_Locations_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_EnterprisesArgs = {
  objects: Array<Enterprises_Insert_Input>;
  on_conflict?: Maybe<Enterprises_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Enterprises_OneArgs = {
  object: Enterprises_Insert_Input;
  on_conflict?: Maybe<Enterprises_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_EventsArgs = {
  objects: Array<Events_Insert_Input>;
  on_conflict?: Maybe<Events_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Events_OneArgs = {
  object: Events_Insert_Input;
  on_conflict?: Maybe<Events_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Exclusive_CandidatesArgs = {
  objects: Array<Exclusive_Candidates_Insert_Input>;
  on_conflict?: Maybe<Exclusive_Candidates_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Exclusive_Candidates_OneArgs = {
  object: Exclusive_Candidates_Insert_Input;
  on_conflict?: Maybe<Exclusive_Candidates_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Feature_PopupsArgs = {
  objects: Array<Feature_Popups_Insert_Input>;
  on_conflict?: Maybe<Feature_Popups_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Feature_Popups_OneArgs = {
  object: Feature_Popups_Insert_Input;
  on_conflict?: Maybe<Feature_Popups_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Geometry_ColumnsArgs = {
  objects: Array<Geometry_Columns_Insert_Input>;
};


/** mutation root */
export type Mutation_RootInsert_Geometry_Columns_OneArgs = {
  object: Geometry_Columns_Insert_Input;
};


/** mutation root */
export type Mutation_RootInsert_IndustriesArgs = {
  objects: Array<Industries_Insert_Input>;
  on_conflict?: Maybe<Industries_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Industries_OneArgs = {
  object: Industries_Insert_Input;
  on_conflict?: Maybe<Industries_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Interview_ParticipantsArgs = {
  objects: Array<Interview_Participants_Insert_Input>;
  on_conflict?: Maybe<Interview_Participants_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Interview_Participants_OneArgs = {
  object: Interview_Participants_Insert_Input;
  on_conflict?: Maybe<Interview_Participants_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_InterviewsArgs = {
  objects: Array<Interviews_Insert_Input>;
  on_conflict?: Maybe<Interviews_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Interviews_OneArgs = {
  object: Interviews_Insert_Input;
  on_conflict?: Maybe<Interviews_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Job_CandidatesArgs = {
  objects: Array<Job_Candidates_Insert_Input>;
  on_conflict?: Maybe<Job_Candidates_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Job_Candidates_OneArgs = {
  object: Job_Candidates_Insert_Input;
  on_conflict?: Maybe<Job_Candidates_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Job_LocationsArgs = {
  objects: Array<Job_Locations_Insert_Input>;
  on_conflict?: Maybe<Job_Locations_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Job_Locations_OneArgs = {
  object: Job_Locations_Insert_Input;
  on_conflict?: Maybe<Job_Locations_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_JobsArgs = {
  objects: Array<Jobs_Insert_Input>;
  on_conflict?: Maybe<Jobs_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Jobs_OneArgs = {
  object: Jobs_Insert_Input;
  on_conflict?: Maybe<Jobs_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_LocationsArgs = {
  objects: Array<Locations_Insert_Input>;
  on_conflict?: Maybe<Locations_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Locations_OneArgs = {
  object: Locations_Insert_Input;
  on_conflict?: Maybe<Locations_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_MajorsArgs = {
  objects: Array<Majors_Insert_Input>;
  on_conflict?: Maybe<Majors_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Majors_OneArgs = {
  object: Majors_Insert_Input;
  on_conflict?: Maybe<Majors_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_OffersArgs = {
  objects: Array<Offers_Insert_Input>;
  on_conflict?: Maybe<Offers_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Offers_OneArgs = {
  object: Offers_Insert_Input;
  on_conflict?: Maybe<Offers_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Report_TargetsArgs = {
  objects: Array<Report_Targets_Insert_Input>;
  on_conflict?: Maybe<Report_Targets_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Report_Targets_OneArgs = {
  object: Report_Targets_Insert_Input;
  on_conflict?: Maybe<Report_Targets_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Short_UrlsArgs = {
  objects: Array<Short_Urls_Insert_Input>;
  on_conflict?: Maybe<Short_Urls_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Short_Urls_OneArgs = {
  object: Short_Urls_Insert_Input;
  on_conflict?: Maybe<Short_Urls_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_ShortlistsArgs = {
  objects: Array<Shortlists_Insert_Input>;
  on_conflict?: Maybe<Shortlists_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Shortlists_OneArgs = {
  object: Shortlists_Insert_Input;
  on_conflict?: Maybe<Shortlists_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_SkillsArgs = {
  objects: Array<Skills_Insert_Input>;
  on_conflict?: Maybe<Skills_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Skills_OneArgs = {
  object: Skills_Insert_Input;
  on_conflict?: Maybe<Skills_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Spatial_Ref_SysArgs = {
  objects: Array<Spatial_Ref_Sys_Insert_Input>;
  on_conflict?: Maybe<Spatial_Ref_Sys_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Spatial_Ref_Sys_OneArgs = {
  object: Spatial_Ref_Sys_Insert_Input;
  on_conflict?: Maybe<Spatial_Ref_Sys_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Tenant_CandidatesArgs = {
  objects: Array<Tenant_Candidates_Insert_Input>;
  on_conflict?: Maybe<Tenant_Candidates_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Tenant_Candidates_OneArgs = {
  object: Tenant_Candidates_Insert_Input;
  on_conflict?: Maybe<Tenant_Candidates_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Tenant_LocationsArgs = {
  objects: Array<Tenant_Locations_Insert_Input>;
  on_conflict?: Maybe<Tenant_Locations_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Tenant_Locations_OneArgs = {
  object: Tenant_Locations_Insert_Input;
  on_conflict?: Maybe<Tenant_Locations_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Tenant_UsersArgs = {
  objects: Array<Tenant_Users_Insert_Input>;
  on_conflict?: Maybe<Tenant_Users_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Tenant_Users_OneArgs = {
  object: Tenant_Users_Insert_Input;
  on_conflict?: Maybe<Tenant_Users_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_TenantsArgs = {
  objects: Array<Tenants_Insert_Input>;
  on_conflict?: Maybe<Tenants_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Tenants_OneArgs = {
  object: Tenants_Insert_Input;
  on_conflict?: Maybe<Tenants_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_UniversitiesArgs = {
  objects: Array<Universities_Insert_Input>;
  on_conflict?: Maybe<Universities_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Universities_OneArgs = {
  object: Universities_Insert_Input;
  on_conflict?: Maybe<Universities_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_UsersArgs = {
  objects: Array<Users_Insert_Input>;
  on_conflict?: Maybe<Users_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Users_OneArgs = {
  object: Users_Insert_Input;
  on_conflict?: Maybe<Users_On_Conflict>;
};


/** mutation root */
export type Mutation_RootNylas_Post_Calendar_AvailabilityArgs = {
  payload: Nylas_PostCalendarAvailabilityInput;
};


/** mutation root */
export type Mutation_RootNylas_Post_EventArgs = {
  payload?: Maybe<Nylas_PostEventInput>;
};


/** mutation root */
export type Mutation_RootSend_Candidate_First_Viewed_EmailArgs = {
  payload?: Maybe<SendCandidateFirstTimeViewEmail>;
};


/** mutation root */
export type Mutation_RootSend_Candidate_No_Show_EmailArgs = {
  payload?: Maybe<SendCandidateNoShowEmail>;
};


/** mutation root */
export type Mutation_RootTrackArgs = {
  payload: TrackInput;
};


/** mutation root */
export type Mutation_RootUpdate_Brimstone_Event_FeedbackArgs = {
  _inc?: Maybe<Brimstone_Event_Feedback_Inc_Input>;
  _set?: Maybe<Brimstone_Event_Feedback_Set_Input>;
  where: Brimstone_Event_Feedback_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Brimstone_Event_Feedback_By_PkArgs = {
  _inc?: Maybe<Brimstone_Event_Feedback_Inc_Input>;
  _set?: Maybe<Brimstone_Event_Feedback_Set_Input>;
  pk_columns: Brimstone_Event_Feedback_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Brimstone_Event_Feedback_ManyArgs = {
  updates: Array<Brimstone_Event_Feedback_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_Brimstone_Event_SpeakersArgs = {
  _set?: Maybe<Brimstone_Event_Speakers_Set_Input>;
  where: Brimstone_Event_Speakers_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Brimstone_Event_Speakers_By_PkArgs = {
  _set?: Maybe<Brimstone_Event_Speakers_Set_Input>;
  pk_columns: Brimstone_Event_Speakers_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Brimstone_Event_Speakers_ManyArgs = {
  updates: Array<Brimstone_Event_Speakers_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_Brimstone_EventsArgs = {
  _set?: Maybe<Brimstone_Events_Set_Input>;
  where: Brimstone_Events_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Brimstone_Events_By_PkArgs = {
  _set?: Maybe<Brimstone_Events_Set_Input>;
  pk_columns: Brimstone_Events_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Brimstone_Events_ManyArgs = {
  updates: Array<Brimstone_Events_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_Brimstone_SpeakersArgs = {
  _set?: Maybe<Brimstone_Speakers_Set_Input>;
  where: Brimstone_Speakers_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Brimstone_Speakers_By_PkArgs = {
  _set?: Maybe<Brimstone_Speakers_Set_Input>;
  pk_columns: Brimstone_Speakers_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Brimstone_Speakers_ManyArgs = {
  updates: Array<Brimstone_Speakers_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_Candidate_CoursesArgs = {
  _set?: Maybe<Candidate_Courses_Set_Input>;
  where: Candidate_Courses_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Candidate_Courses_By_PkArgs = {
  _set?: Maybe<Candidate_Courses_Set_Input>;
  pk_columns: Candidate_Courses_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Candidate_Courses_ManyArgs = {
  updates: Array<Candidate_Courses_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_Candidate_DegreesArgs = {
  _set?: Maybe<Candidate_Degrees_Set_Input>;
  where: Candidate_Degrees_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Candidate_Degrees_By_PkArgs = {
  _set?: Maybe<Candidate_Degrees_Set_Input>;
  pk_columns: Candidate_Degrees_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Candidate_Degrees_ManyArgs = {
  updates: Array<Candidate_Degrees_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_Candidate_FollowsArgs = {
  _set?: Maybe<Candidate_Follows_Set_Input>;
  where: Candidate_Follows_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Candidate_Follows_By_PkArgs = {
  _set?: Maybe<Candidate_Follows_Set_Input>;
  pk_columns: Candidate_Follows_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Candidate_Follows_ManyArgs = {
  updates: Array<Candidate_Follows_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_Candidate_IndustriesArgs = {
  _set?: Maybe<Candidate_Industries_Set_Input>;
  where: Candidate_Industries_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Candidate_Industries_By_PkArgs = {
  _set?: Maybe<Candidate_Industries_Set_Input>;
  pk_columns: Candidate_Industries_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Candidate_Industries_ManyArgs = {
  updates: Array<Candidate_Industries_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_Candidate_LocationsArgs = {
  _set?: Maybe<Candidate_Locations_Set_Input>;
  where: Candidate_Locations_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Candidate_Locations_By_PkArgs = {
  _set?: Maybe<Candidate_Locations_Set_Input>;
  pk_columns: Candidate_Locations_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Candidate_Locations_ManyArgs = {
  updates: Array<Candidate_Locations_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_Candidate_MajorsArgs = {
  _set?: Maybe<Candidate_Majors_Set_Input>;
  where: Candidate_Majors_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Candidate_Majors_By_PkArgs = {
  _set?: Maybe<Candidate_Majors_Set_Input>;
  pk_columns: Candidate_Majors_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Candidate_Majors_ManyArgs = {
  updates: Array<Candidate_Majors_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_Candidate_PlacementsArgs = {
  _set?: Maybe<Candidate_Placements_Set_Input>;
  where: Candidate_Placements_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Candidate_Placements_By_PkArgs = {
  _set?: Maybe<Candidate_Placements_Set_Input>;
  pk_columns: Candidate_Placements_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Candidate_Placements_ManyArgs = {
  updates: Array<Candidate_Placements_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_Candidate_SkillsArgs = {
  _set?: Maybe<Candidate_Skills_Set_Input>;
  where: Candidate_Skills_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Candidate_Skills_By_PkArgs = {
  _set?: Maybe<Candidate_Skills_Set_Input>;
  pk_columns: Candidate_Skills_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Candidate_Skills_ManyArgs = {
  updates: Array<Candidate_Skills_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_CandidatesArgs = {
  _inc?: Maybe<Candidates_Inc_Input>;
  _set?: Maybe<Candidates_Set_Input>;
  where: Candidates_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Candidates_By_PkArgs = {
  _inc?: Maybe<Candidates_Inc_Input>;
  _set?: Maybe<Candidates_Set_Input>;
  pk_columns: Candidates_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Candidates_ManyArgs = {
  updates: Array<Candidates_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_Client_IndustriesArgs = {
  _set?: Maybe<Client_Industries_Set_Input>;
  where: Client_Industries_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Client_Industries_By_PkArgs = {
  _set?: Maybe<Client_Industries_Set_Input>;
  pk_columns: Client_Industries_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Client_Industries_ManyArgs = {
  updates: Array<Client_Industries_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_Client_JobsArgs = {
  _set?: Maybe<Client_Jobs_Set_Input>;
  where: Client_Jobs_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Client_Jobs_By_PkArgs = {
  _set?: Maybe<Client_Jobs_Set_Input>;
  pk_columns: Client_Jobs_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Client_Jobs_ManyArgs = {
  updates: Array<Client_Jobs_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_Client_LocationsArgs = {
  _set?: Maybe<Client_Locations_Set_Input>;
  where: Client_Locations_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Client_Locations_By_PkArgs = {
  _set?: Maybe<Client_Locations_Set_Input>;
  pk_columns: Client_Locations_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Client_Locations_ManyArgs = {
  updates: Array<Client_Locations_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_Client_UsersArgs = {
  _set?: Maybe<Client_Users_Set_Input>;
  where: Client_Users_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Client_Users_By_PkArgs = {
  _set?: Maybe<Client_Users_Set_Input>;
  pk_columns: Client_Users_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Client_Users_ManyArgs = {
  updates: Array<Client_Users_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_ClientsArgs = {
  _set?: Maybe<Clients_Set_Input>;
  where: Clients_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Clients_By_PkArgs = {
  _set?: Maybe<Clients_Set_Input>;
  pk_columns: Clients_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Clients_ManyArgs = {
  updates: Array<Clients_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_ContactsArgs = {
  _set?: Maybe<Contacts_Set_Input>;
  where: Contacts_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Contacts_By_PkArgs = {
  _set?: Maybe<Contacts_Set_Input>;
  pk_columns: Contacts_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Contacts_ManyArgs = {
  updates: Array<Contacts_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_CoursesArgs = {
  _inc?: Maybe<Courses_Inc_Input>;
  _set?: Maybe<Courses_Set_Input>;
  where: Courses_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Courses_By_PkArgs = {
  _inc?: Maybe<Courses_Inc_Input>;
  _set?: Maybe<Courses_Set_Input>;
  pk_columns: Courses_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Courses_ManyArgs = {
  updates: Array<Courses_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_DegreesArgs = {
  _set?: Maybe<Degrees_Set_Input>;
  where: Degrees_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Degrees_By_PkArgs = {
  _set?: Maybe<Degrees_Set_Input>;
  pk_columns: Degrees_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Degrees_ManyArgs = {
  updates: Array<Degrees_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_Enterprise_ClientsArgs = {
  _set?: Maybe<Enterprise_Clients_Set_Input>;
  where: Enterprise_Clients_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Enterprise_Clients_By_PkArgs = {
  _set?: Maybe<Enterprise_Clients_Set_Input>;
  pk_columns: Enterprise_Clients_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Enterprise_Clients_ManyArgs = {
  updates: Array<Enterprise_Clients_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_Enterprise_LocationsArgs = {
  _set?: Maybe<Enterprise_Locations_Set_Input>;
  where: Enterprise_Locations_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Enterprise_Locations_By_PkArgs = {
  _set?: Maybe<Enterprise_Locations_Set_Input>;
  pk_columns: Enterprise_Locations_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Enterprise_Locations_ManyArgs = {
  updates: Array<Enterprise_Locations_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_EnterprisesArgs = {
  _inc?: Maybe<Enterprises_Inc_Input>;
  _set?: Maybe<Enterprises_Set_Input>;
  where: Enterprises_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Enterprises_By_PkArgs = {
  _inc?: Maybe<Enterprises_Inc_Input>;
  _set?: Maybe<Enterprises_Set_Input>;
  pk_columns: Enterprises_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Enterprises_ManyArgs = {
  updates: Array<Enterprises_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_EventsArgs = {
  _set?: Maybe<Events_Set_Input>;
  where: Events_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Events_By_PkArgs = {
  _set?: Maybe<Events_Set_Input>;
  pk_columns: Events_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Events_ManyArgs = {
  updates: Array<Events_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_Exclusive_CandidatesArgs = {
  _set?: Maybe<Exclusive_Candidates_Set_Input>;
  where: Exclusive_Candidates_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Exclusive_Candidates_By_PkArgs = {
  _set?: Maybe<Exclusive_Candidates_Set_Input>;
  pk_columns: Exclusive_Candidates_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Exclusive_Candidates_ManyArgs = {
  updates: Array<Exclusive_Candidates_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_Feature_PopupsArgs = {
  _set?: Maybe<Feature_Popups_Set_Input>;
  where: Feature_Popups_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Feature_Popups_By_PkArgs = {
  _set?: Maybe<Feature_Popups_Set_Input>;
  pk_columns: Feature_Popups_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Feature_Popups_ManyArgs = {
  updates: Array<Feature_Popups_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_Geometry_ColumnsArgs = {
  _inc?: Maybe<Geometry_Columns_Inc_Input>;
  _set?: Maybe<Geometry_Columns_Set_Input>;
  where: Geometry_Columns_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Geometry_Columns_ManyArgs = {
  updates: Array<Geometry_Columns_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_IndustriesArgs = {
  _set?: Maybe<Industries_Set_Input>;
  where: Industries_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Industries_By_PkArgs = {
  _set?: Maybe<Industries_Set_Input>;
  pk_columns: Industries_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Industries_ManyArgs = {
  updates: Array<Industries_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_Interview_ParticipantsArgs = {
  _set?: Maybe<Interview_Participants_Set_Input>;
  where: Interview_Participants_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Interview_Participants_By_PkArgs = {
  _set?: Maybe<Interview_Participants_Set_Input>;
  pk_columns: Interview_Participants_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Interview_Participants_ManyArgs = {
  updates: Array<Interview_Participants_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_InterviewsArgs = {
  _append?: Maybe<Interviews_Append_Input>;
  _delete_at_path?: Maybe<Interviews_Delete_At_Path_Input>;
  _delete_elem?: Maybe<Interviews_Delete_Elem_Input>;
  _delete_key?: Maybe<Interviews_Delete_Key_Input>;
  _prepend?: Maybe<Interviews_Prepend_Input>;
  _set?: Maybe<Interviews_Set_Input>;
  where: Interviews_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Interviews_By_PkArgs = {
  _append?: Maybe<Interviews_Append_Input>;
  _delete_at_path?: Maybe<Interviews_Delete_At_Path_Input>;
  _delete_elem?: Maybe<Interviews_Delete_Elem_Input>;
  _delete_key?: Maybe<Interviews_Delete_Key_Input>;
  _prepend?: Maybe<Interviews_Prepend_Input>;
  _set?: Maybe<Interviews_Set_Input>;
  pk_columns: Interviews_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Interviews_ManyArgs = {
  updates: Array<Interviews_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_Job_CandidatesArgs = {
  _set?: Maybe<Job_Candidates_Set_Input>;
  where: Job_Candidates_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Job_Candidates_By_PkArgs = {
  _set?: Maybe<Job_Candidates_Set_Input>;
  pk_columns: Job_Candidates_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Job_Candidates_ManyArgs = {
  updates: Array<Job_Candidates_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_Job_LocationsArgs = {
  _set?: Maybe<Job_Locations_Set_Input>;
  where: Job_Locations_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Job_Locations_By_PkArgs = {
  _set?: Maybe<Job_Locations_Set_Input>;
  pk_columns: Job_Locations_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Job_Locations_ManyArgs = {
  updates: Array<Job_Locations_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_JobsArgs = {
  _inc?: Maybe<Jobs_Inc_Input>;
  _set?: Maybe<Jobs_Set_Input>;
  where: Jobs_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Jobs_By_PkArgs = {
  _inc?: Maybe<Jobs_Inc_Input>;
  _set?: Maybe<Jobs_Set_Input>;
  pk_columns: Jobs_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Jobs_ManyArgs = {
  updates: Array<Jobs_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_LocationsArgs = {
  _set?: Maybe<Locations_Set_Input>;
  where: Locations_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Locations_By_PkArgs = {
  _set?: Maybe<Locations_Set_Input>;
  pk_columns: Locations_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Locations_ManyArgs = {
  updates: Array<Locations_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_MajorsArgs = {
  _set?: Maybe<Majors_Set_Input>;
  where: Majors_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Majors_By_PkArgs = {
  _set?: Maybe<Majors_Set_Input>;
  pk_columns: Majors_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Majors_ManyArgs = {
  updates: Array<Majors_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_OffersArgs = {
  _inc?: Maybe<Offers_Inc_Input>;
  _set?: Maybe<Offers_Set_Input>;
  where: Offers_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Offers_By_PkArgs = {
  _inc?: Maybe<Offers_Inc_Input>;
  _set?: Maybe<Offers_Set_Input>;
  pk_columns: Offers_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Offers_ManyArgs = {
  updates: Array<Offers_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_Report_TargetsArgs = {
  _inc?: Maybe<Report_Targets_Inc_Input>;
  _set?: Maybe<Report_Targets_Set_Input>;
  where: Report_Targets_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Report_Targets_By_PkArgs = {
  _inc?: Maybe<Report_Targets_Inc_Input>;
  _set?: Maybe<Report_Targets_Set_Input>;
  pk_columns: Report_Targets_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Report_Targets_ManyArgs = {
  updates: Array<Report_Targets_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_Short_UrlsArgs = {
  _append?: Maybe<Short_Urls_Append_Input>;
  _delete_at_path?: Maybe<Short_Urls_Delete_At_Path_Input>;
  _delete_elem?: Maybe<Short_Urls_Delete_Elem_Input>;
  _delete_key?: Maybe<Short_Urls_Delete_Key_Input>;
  _prepend?: Maybe<Short_Urls_Prepend_Input>;
  _set?: Maybe<Short_Urls_Set_Input>;
  where: Short_Urls_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Short_Urls_By_PkArgs = {
  _append?: Maybe<Short_Urls_Append_Input>;
  _delete_at_path?: Maybe<Short_Urls_Delete_At_Path_Input>;
  _delete_elem?: Maybe<Short_Urls_Delete_Elem_Input>;
  _delete_key?: Maybe<Short_Urls_Delete_Key_Input>;
  _prepend?: Maybe<Short_Urls_Prepend_Input>;
  _set?: Maybe<Short_Urls_Set_Input>;
  pk_columns: Short_Urls_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Short_Urls_ManyArgs = {
  updates: Array<Short_Urls_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_ShortlistsArgs = {
  _set?: Maybe<Shortlists_Set_Input>;
  where: Shortlists_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Shortlists_By_PkArgs = {
  _set?: Maybe<Shortlists_Set_Input>;
  pk_columns: Shortlists_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Shortlists_ManyArgs = {
  updates: Array<Shortlists_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_SkillsArgs = {
  _set?: Maybe<Skills_Set_Input>;
  where: Skills_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Skills_By_PkArgs = {
  _set?: Maybe<Skills_Set_Input>;
  pk_columns: Skills_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Skills_ManyArgs = {
  updates: Array<Skills_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_Spatial_Ref_SysArgs = {
  _inc?: Maybe<Spatial_Ref_Sys_Inc_Input>;
  _set?: Maybe<Spatial_Ref_Sys_Set_Input>;
  where: Spatial_Ref_Sys_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Spatial_Ref_Sys_By_PkArgs = {
  _inc?: Maybe<Spatial_Ref_Sys_Inc_Input>;
  _set?: Maybe<Spatial_Ref_Sys_Set_Input>;
  pk_columns: Spatial_Ref_Sys_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Spatial_Ref_Sys_ManyArgs = {
  updates: Array<Spatial_Ref_Sys_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_Tenant_CandidatesArgs = {
  _set?: Maybe<Tenant_Candidates_Set_Input>;
  where: Tenant_Candidates_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Tenant_Candidates_By_PkArgs = {
  _set?: Maybe<Tenant_Candidates_Set_Input>;
  pk_columns: Tenant_Candidates_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Tenant_Candidates_ManyArgs = {
  updates: Array<Tenant_Candidates_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_Tenant_LocationsArgs = {
  _set?: Maybe<Tenant_Locations_Set_Input>;
  where: Tenant_Locations_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Tenant_Locations_By_PkArgs = {
  _set?: Maybe<Tenant_Locations_Set_Input>;
  pk_columns: Tenant_Locations_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Tenant_Locations_ManyArgs = {
  updates: Array<Tenant_Locations_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_Tenant_UsersArgs = {
  _set?: Maybe<Tenant_Users_Set_Input>;
  where: Tenant_Users_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Tenant_Users_By_PkArgs = {
  _set?: Maybe<Tenant_Users_Set_Input>;
  pk_columns: Tenant_Users_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Tenant_Users_ManyArgs = {
  updates: Array<Tenant_Users_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_TenantsArgs = {
  _inc?: Maybe<Tenants_Inc_Input>;
  _set?: Maybe<Tenants_Set_Input>;
  where: Tenants_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Tenants_By_PkArgs = {
  _inc?: Maybe<Tenants_Inc_Input>;
  _set?: Maybe<Tenants_Set_Input>;
  pk_columns: Tenants_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Tenants_ManyArgs = {
  updates: Array<Tenants_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_UniversitiesArgs = {
  _set?: Maybe<Universities_Set_Input>;
  where: Universities_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Universities_By_PkArgs = {
  _set?: Maybe<Universities_Set_Input>;
  pk_columns: Universities_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Universities_ManyArgs = {
  updates: Array<Universities_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_UsersArgs = {
  _set?: Maybe<Users_Set_Input>;
  where: Users_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Users_By_PkArgs = {
  _set?: Maybe<Users_Set_Input>;
  pk_columns: Users_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Users_ManyArgs = {
  updates: Array<Users_Updates>;
};


/** Boolean expression to compare columns of type "name". All fields are combined with logical 'AND'. */
export type Name_Comparison_Exp = {
  _eq?: Maybe<Scalars['name']>;
  _gt?: Maybe<Scalars['name']>;
  _gte?: Maybe<Scalars['name']>;
  _in?: Maybe<Array<Scalars['name']>>;
  _is_null?: Maybe<Scalars['Boolean']>;
  _lt?: Maybe<Scalars['name']>;
  _lte?: Maybe<Scalars['name']>;
  _neq?: Maybe<Scalars['name']>;
  _nin?: Maybe<Array<Scalars['name']>>;
};


/** Boolean expression to compare columns of type "offer_status_enums". All fields are combined with logical 'AND'. */
export type Offer_Status_Enums_Comparison_Exp = {
  _eq?: Maybe<Scalars['offer_status_enums']>;
  _gt?: Maybe<Scalars['offer_status_enums']>;
  _gte?: Maybe<Scalars['offer_status_enums']>;
  _in?: Maybe<Array<Scalars['offer_status_enums']>>;
  _is_null?: Maybe<Scalars['Boolean']>;
  _lt?: Maybe<Scalars['offer_status_enums']>;
  _lte?: Maybe<Scalars['offer_status_enums']>;
  _neq?: Maybe<Scalars['offer_status_enums']>;
  _nin?: Maybe<Array<Scalars['offer_status_enums']>>;
};

/** columns and relationships of "offers" */
export type Offers = {
  __typename?: 'offers';
  /** An object relationship */
  candidate: Candidates;
  candidate_id: Scalars['uuid'];
  /** An object relationship */
  client: Clients;
  client_id: Scalars['uuid'];
  created_at: Scalars['timestamptz'];
  deleted_at?: Maybe<Scalars['timestamptz']>;
  id: Scalars['uuid'];
  /** An object relationship */
  job: Jobs;
  job_id: Scalars['uuid'];
  offer_url?: Maybe<Scalars['String']>;
  owner_id?: Maybe<Scalars['String']>;
  rem?: Maybe<Scalars['Int']>;
  status: Scalars['offer_status_enums'];
  updated_at?: Maybe<Scalars['timestamptz']>;
  /** An object relationship */
  user?: Maybe<Users>;
};

/** aggregated selection of "offers" */
export type Offers_Aggregate = {
  __typename?: 'offers_aggregate';
  aggregate?: Maybe<Offers_Aggregate_Fields>;
  nodes: Array<Offers>;
};

export type Offers_Aggregate_Bool_Exp = {
  count?: Maybe<Offers_Aggregate_Bool_Exp_Count>;
};

export type Offers_Aggregate_Bool_Exp_Count = {
  arguments?: Maybe<Array<Offers_Select_Column>>;
  distinct?: Maybe<Scalars['Boolean']>;
  filter?: Maybe<Offers_Bool_Exp>;
  predicate: Int_Comparison_Exp;
};

/** aggregate fields of "offers" */
export type Offers_Aggregate_Fields = {
  __typename?: 'offers_aggregate_fields';
  avg?: Maybe<Offers_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<Offers_Max_Fields>;
  min?: Maybe<Offers_Min_Fields>;
  stddev?: Maybe<Offers_Stddev_Fields>;
  stddev_pop?: Maybe<Offers_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Offers_Stddev_Samp_Fields>;
  sum?: Maybe<Offers_Sum_Fields>;
  var_pop?: Maybe<Offers_Var_Pop_Fields>;
  var_samp?: Maybe<Offers_Var_Samp_Fields>;
  variance?: Maybe<Offers_Variance_Fields>;
};


/** aggregate fields of "offers" */
export type Offers_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<Offers_Select_Column>>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "offers" */
export type Offers_Aggregate_Order_By = {
  avg?: Maybe<Offers_Avg_Order_By>;
  count?: Maybe<Order_By>;
  max?: Maybe<Offers_Max_Order_By>;
  min?: Maybe<Offers_Min_Order_By>;
  stddev?: Maybe<Offers_Stddev_Order_By>;
  stddev_pop?: Maybe<Offers_Stddev_Pop_Order_By>;
  stddev_samp?: Maybe<Offers_Stddev_Samp_Order_By>;
  sum?: Maybe<Offers_Sum_Order_By>;
  var_pop?: Maybe<Offers_Var_Pop_Order_By>;
  var_samp?: Maybe<Offers_Var_Samp_Order_By>;
  variance?: Maybe<Offers_Variance_Order_By>;
};

/** input type for inserting array relation for remote table "offers" */
export type Offers_Arr_Rel_Insert_Input = {
  data: Array<Offers_Insert_Input>;
  /** upsert condition */
  on_conflict?: Maybe<Offers_On_Conflict>;
};

/** aggregate avg on columns */
export type Offers_Avg_Fields = {
  __typename?: 'offers_avg_fields';
  rem?: Maybe<Scalars['Float']>;
};

/** order by avg() on columns of table "offers" */
export type Offers_Avg_Order_By = {
  rem?: Maybe<Order_By>;
};

/** Boolean expression to filter rows from the table "offers". All fields are combined with a logical 'AND'. */
export type Offers_Bool_Exp = {
  _and?: Maybe<Array<Offers_Bool_Exp>>;
  _not?: Maybe<Offers_Bool_Exp>;
  _or?: Maybe<Array<Offers_Bool_Exp>>;
  candidate?: Maybe<Candidates_Bool_Exp>;
  candidate_id?: Maybe<Uuid_Comparison_Exp>;
  client?: Maybe<Clients_Bool_Exp>;
  client_id?: Maybe<Uuid_Comparison_Exp>;
  created_at?: Maybe<Timestamptz_Comparison_Exp>;
  deleted_at?: Maybe<Timestamptz_Comparison_Exp>;
  id?: Maybe<Uuid_Comparison_Exp>;
  job?: Maybe<Jobs_Bool_Exp>;
  job_id?: Maybe<Uuid_Comparison_Exp>;
  offer_url?: Maybe<String_Comparison_Exp>;
  owner_id?: Maybe<String_Comparison_Exp>;
  rem?: Maybe<Int_Comparison_Exp>;
  status?: Maybe<Offer_Status_Enums_Comparison_Exp>;
  updated_at?: Maybe<Timestamptz_Comparison_Exp>;
  user?: Maybe<Users_Bool_Exp>;
};

/** unique or primary key constraints on table "offers" */
export enum Offers_Constraint {
  /** unique or primary key constraint on columns "candidate_id", "client_id", "job_id" */
  OffersJobIdClientIdCandidateIdKey = 'offers_job_id_client_id_candidate_id_key',
  /** unique or primary key constraint on columns "id" */
  OffersPkey = 'offers_pkey'
}

/** input type for incrementing numeric columns in table "offers" */
export type Offers_Inc_Input = {
  rem?: Maybe<Scalars['Int']>;
};

/** input type for inserting data into table "offers" */
export type Offers_Insert_Input = {
  candidate?: Maybe<Candidates_Obj_Rel_Insert_Input>;
  candidate_id?: Maybe<Scalars['uuid']>;
  client?: Maybe<Clients_Obj_Rel_Insert_Input>;
  client_id?: Maybe<Scalars['uuid']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  deleted_at?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['uuid']>;
  job?: Maybe<Jobs_Obj_Rel_Insert_Input>;
  job_id?: Maybe<Scalars['uuid']>;
  offer_url?: Maybe<Scalars['String']>;
  owner_id?: Maybe<Scalars['String']>;
  rem?: Maybe<Scalars['Int']>;
  status?: Maybe<Scalars['offer_status_enums']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
  user?: Maybe<Users_Obj_Rel_Insert_Input>;
};

/** aggregate max on columns */
export type Offers_Max_Fields = {
  __typename?: 'offers_max_fields';
  candidate_id?: Maybe<Scalars['uuid']>;
  client_id?: Maybe<Scalars['uuid']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  deleted_at?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['uuid']>;
  job_id?: Maybe<Scalars['uuid']>;
  offer_url?: Maybe<Scalars['String']>;
  owner_id?: Maybe<Scalars['String']>;
  rem?: Maybe<Scalars['Int']>;
  status?: Maybe<Scalars['offer_status_enums']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** order by max() on columns of table "offers" */
export type Offers_Max_Order_By = {
  candidate_id?: Maybe<Order_By>;
  client_id?: Maybe<Order_By>;
  created_at?: Maybe<Order_By>;
  deleted_at?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  job_id?: Maybe<Order_By>;
  offer_url?: Maybe<Order_By>;
  owner_id?: Maybe<Order_By>;
  rem?: Maybe<Order_By>;
  status?: Maybe<Order_By>;
  updated_at?: Maybe<Order_By>;
};

/** aggregate min on columns */
export type Offers_Min_Fields = {
  __typename?: 'offers_min_fields';
  candidate_id?: Maybe<Scalars['uuid']>;
  client_id?: Maybe<Scalars['uuid']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  deleted_at?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['uuid']>;
  job_id?: Maybe<Scalars['uuid']>;
  offer_url?: Maybe<Scalars['String']>;
  owner_id?: Maybe<Scalars['String']>;
  rem?: Maybe<Scalars['Int']>;
  status?: Maybe<Scalars['offer_status_enums']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** order by min() on columns of table "offers" */
export type Offers_Min_Order_By = {
  candidate_id?: Maybe<Order_By>;
  client_id?: Maybe<Order_By>;
  created_at?: Maybe<Order_By>;
  deleted_at?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  job_id?: Maybe<Order_By>;
  offer_url?: Maybe<Order_By>;
  owner_id?: Maybe<Order_By>;
  rem?: Maybe<Order_By>;
  status?: Maybe<Order_By>;
  updated_at?: Maybe<Order_By>;
};

/** response of any mutation on the table "offers" */
export type Offers_Mutation_Response = {
  __typename?: 'offers_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Offers>;
};

/** on_conflict condition type for table "offers" */
export type Offers_On_Conflict = {
  constraint: Offers_Constraint;
  update_columns?: Array<Offers_Update_Column>;
  where?: Maybe<Offers_Bool_Exp>;
};

/** Ordering options when selecting data from "offers". */
export type Offers_Order_By = {
  candidate?: Maybe<Candidates_Order_By>;
  candidate_id?: Maybe<Order_By>;
  client?: Maybe<Clients_Order_By>;
  client_id?: Maybe<Order_By>;
  created_at?: Maybe<Order_By>;
  deleted_at?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  job?: Maybe<Jobs_Order_By>;
  job_id?: Maybe<Order_By>;
  offer_url?: Maybe<Order_By>;
  owner_id?: Maybe<Order_By>;
  rem?: Maybe<Order_By>;
  status?: Maybe<Order_By>;
  updated_at?: Maybe<Order_By>;
  user?: Maybe<Users_Order_By>;
};

/** primary key columns input for table: offers */
export type Offers_Pk_Columns_Input = {
  id: Scalars['uuid'];
};

/** select columns of table "offers" */
export enum Offers_Select_Column {
  /** column name */
  CandidateId = 'candidate_id',
  /** column name */
  ClientId = 'client_id',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  DeletedAt = 'deleted_at',
  /** column name */
  Id = 'id',
  /** column name */
  JobId = 'job_id',
  /** column name */
  OfferUrl = 'offer_url',
  /** column name */
  OwnerId = 'owner_id',
  /** column name */
  Rem = 'rem',
  /** column name */
  Status = 'status',
  /** column name */
  UpdatedAt = 'updated_at'
}

/** input type for updating data in table "offers" */
export type Offers_Set_Input = {
  candidate_id?: Maybe<Scalars['uuid']>;
  client_id?: Maybe<Scalars['uuid']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  deleted_at?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['uuid']>;
  job_id?: Maybe<Scalars['uuid']>;
  offer_url?: Maybe<Scalars['String']>;
  owner_id?: Maybe<Scalars['String']>;
  rem?: Maybe<Scalars['Int']>;
  status?: Maybe<Scalars['offer_status_enums']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** aggregate stddev on columns */
export type Offers_Stddev_Fields = {
  __typename?: 'offers_stddev_fields';
  rem?: Maybe<Scalars['Float']>;
};

/** order by stddev() on columns of table "offers" */
export type Offers_Stddev_Order_By = {
  rem?: Maybe<Order_By>;
};

/** aggregate stddev_pop on columns */
export type Offers_Stddev_Pop_Fields = {
  __typename?: 'offers_stddev_pop_fields';
  rem?: Maybe<Scalars['Float']>;
};

/** order by stddev_pop() on columns of table "offers" */
export type Offers_Stddev_Pop_Order_By = {
  rem?: Maybe<Order_By>;
};

/** aggregate stddev_samp on columns */
export type Offers_Stddev_Samp_Fields = {
  __typename?: 'offers_stddev_samp_fields';
  rem?: Maybe<Scalars['Float']>;
};

/** order by stddev_samp() on columns of table "offers" */
export type Offers_Stddev_Samp_Order_By = {
  rem?: Maybe<Order_By>;
};

/** Streaming cursor of the table "offers" */
export type Offers_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Offers_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: Maybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Offers_Stream_Cursor_Value_Input = {
  candidate_id?: Maybe<Scalars['uuid']>;
  client_id?: Maybe<Scalars['uuid']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  deleted_at?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['uuid']>;
  job_id?: Maybe<Scalars['uuid']>;
  offer_url?: Maybe<Scalars['String']>;
  owner_id?: Maybe<Scalars['String']>;
  rem?: Maybe<Scalars['Int']>;
  status?: Maybe<Scalars['offer_status_enums']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** aggregate sum on columns */
export type Offers_Sum_Fields = {
  __typename?: 'offers_sum_fields';
  rem?: Maybe<Scalars['Int']>;
};

/** order by sum() on columns of table "offers" */
export type Offers_Sum_Order_By = {
  rem?: Maybe<Order_By>;
};

/** update columns of table "offers" */
export enum Offers_Update_Column {
  /** column name */
  CandidateId = 'candidate_id',
  /** column name */
  ClientId = 'client_id',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  DeletedAt = 'deleted_at',
  /** column name */
  Id = 'id',
  /** column name */
  JobId = 'job_id',
  /** column name */
  OfferUrl = 'offer_url',
  /** column name */
  OwnerId = 'owner_id',
  /** column name */
  Rem = 'rem',
  /** column name */
  Status = 'status',
  /** column name */
  UpdatedAt = 'updated_at'
}

export type Offers_Updates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: Maybe<Offers_Inc_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: Maybe<Offers_Set_Input>;
  /** filter the rows which have to be updated */
  where: Offers_Bool_Exp;
};

/** aggregate var_pop on columns */
export type Offers_Var_Pop_Fields = {
  __typename?: 'offers_var_pop_fields';
  rem?: Maybe<Scalars['Float']>;
};

/** order by var_pop() on columns of table "offers" */
export type Offers_Var_Pop_Order_By = {
  rem?: Maybe<Order_By>;
};

/** aggregate var_samp on columns */
export type Offers_Var_Samp_Fields = {
  __typename?: 'offers_var_samp_fields';
  rem?: Maybe<Scalars['Float']>;
};

/** order by var_samp() on columns of table "offers" */
export type Offers_Var_Samp_Order_By = {
  rem?: Maybe<Order_By>;
};

/** aggregate variance on columns */
export type Offers_Variance_Fields = {
  __typename?: 'offers_variance_fields';
  rem?: Maybe<Scalars['Float']>;
};

/** order by variance() on columns of table "offers" */
export type Offers_Variance_Order_By = {
  rem?: Maybe<Order_By>;
};

/** column ordering options */
export enum Order_By {
  /** in ascending order, nulls last */
  Asc = 'asc',
  /** in ascending order, nulls first */
  AscNullsFirst = 'asc_nulls_first',
  /** in ascending order, nulls last */
  AscNullsLast = 'asc_nulls_last',
  /** in descending order, nulls first */
  Desc = 'desc',
  /** in descending order, nulls first */
  DescNullsFirst = 'desc_nulls_first',
  /** in descending order, nulls last */
  DescNullsLast = 'desc_nulls_last'
}

export type Query_Root = {
  __typename?: 'query_root';
  /** fetch data from the table: "brimstone_event_feedback" */
  brimstone_event_feedback: Array<Brimstone_Event_Feedback>;
  /** fetch aggregated fields from the table: "brimstone_event_feedback" */
  brimstone_event_feedback_aggregate: Brimstone_Event_Feedback_Aggregate;
  /** fetch data from the table: "brimstone_event_feedback" using primary key columns */
  brimstone_event_feedback_by_pk?: Maybe<Brimstone_Event_Feedback>;
  /** An array relationship */
  brimstone_event_speakers: Array<Brimstone_Event_Speakers>;
  /** An aggregate relationship */
  brimstone_event_speakers_aggregate: Brimstone_Event_Speakers_Aggregate;
  /** fetch data from the table: "brimstone_event_speakers" using primary key columns */
  brimstone_event_speakers_by_pk?: Maybe<Brimstone_Event_Speakers>;
  /** fetch data from the table: "brimstone_events" */
  brimstone_events: Array<Brimstone_Events>;
  /** fetch aggregated fields from the table: "brimstone_events" */
  brimstone_events_aggregate: Brimstone_Events_Aggregate;
  /** fetch data from the table: "brimstone_events" using primary key columns */
  brimstone_events_by_pk?: Maybe<Brimstone_Events>;
  /** fetch data from the table: "brimstone_speakers" */
  brimstone_speakers: Array<Brimstone_Speakers>;
  /** fetch aggregated fields from the table: "brimstone_speakers" */
  brimstone_speakers_aggregate: Brimstone_Speakers_Aggregate;
  /** fetch data from the table: "brimstone_speakers" using primary key columns */
  brimstone_speakers_by_pk?: Maybe<Brimstone_Speakers>;
  /** An array relationship */
  candidate_courses: Array<Candidate_Courses>;
  /** An aggregate relationship */
  candidate_courses_aggregate: Candidate_Courses_Aggregate;
  /** fetch data from the table: "candidate_courses" using primary key columns */
  candidate_courses_by_pk?: Maybe<Candidate_Courses>;
  /** An array relationship */
  candidate_degrees: Array<Candidate_Degrees>;
  /** An aggregate relationship */
  candidate_degrees_aggregate: Candidate_Degrees_Aggregate;
  /** fetch data from the table: "candidate_degrees" using primary key columns */
  candidate_degrees_by_pk?: Maybe<Candidate_Degrees>;
  /** An array relationship */
  candidate_follows: Array<Candidate_Follows>;
  /** An aggregate relationship */
  candidate_follows_aggregate: Candidate_Follows_Aggregate;
  /** fetch data from the table: "candidate_follows" using primary key columns */
  candidate_follows_by_pk?: Maybe<Candidate_Follows>;
  /** An array relationship */
  candidate_industries: Array<Candidate_Industries>;
  /** An aggregate relationship */
  candidate_industries_aggregate: Candidate_Industries_Aggregate;
  /** fetch data from the table: "candidate_industries" using primary key columns */
  candidate_industries_by_pk?: Maybe<Candidate_Industries>;
  /** An array relationship */
  candidate_locations: Array<Candidate_Locations>;
  /** An aggregate relationship */
  candidate_locations_aggregate: Candidate_Locations_Aggregate;
  /** fetch data from the table: "candidate_locations" using primary key columns */
  candidate_locations_by_pk?: Maybe<Candidate_Locations>;
  /** An array relationship */
  candidate_majors: Array<Candidate_Majors>;
  /** An aggregate relationship */
  candidate_majors_aggregate: Candidate_Majors_Aggregate;
  /** fetch data from the table: "candidate_majors" using primary key columns */
  candidate_majors_by_pk?: Maybe<Candidate_Majors>;
  /** An array relationship */
  candidate_placements: Array<Candidate_Placements>;
  /** An aggregate relationship */
  candidate_placements_aggregate: Candidate_Placements_Aggregate;
  /** fetch data from the table: "candidate_placements" using primary key columns */
  candidate_placements_by_pk?: Maybe<Candidate_Placements>;
  /** An array relationship */
  candidate_skills: Array<Candidate_Skills>;
  /** An aggregate relationship */
  candidate_skills_aggregate: Candidate_Skills_Aggregate;
  /** fetch data from the table: "candidate_skills" using primary key columns */
  candidate_skills_by_pk?: Maybe<Candidate_Skills>;
  /** An array relationship */
  candidates: Array<Candidates>;
  /** An aggregate relationship */
  candidates_aggregate: Candidates_Aggregate;
  /** fetch data from the table: "candidates" using primary key columns */
  candidates_by_pk?: Maybe<Candidates>;
  /** An array relationship */
  client_industries: Array<Client_Industries>;
  /** An aggregate relationship */
  client_industries_aggregate: Client_Industries_Aggregate;
  /** fetch data from the table: "client_industries" using primary key columns */
  client_industries_by_pk?: Maybe<Client_Industries>;
  /** An array relationship */
  client_jobs: Array<Client_Jobs>;
  /** An aggregate relationship */
  client_jobs_aggregate: Client_Jobs_Aggregate;
  /** fetch data from the table: "client_jobs" using primary key columns */
  client_jobs_by_pk?: Maybe<Client_Jobs>;
  /** An array relationship */
  client_locations: Array<Client_Locations>;
  /** An aggregate relationship */
  client_locations_aggregate: Client_Locations_Aggregate;
  /** fetch data from the table: "client_locations" using primary key columns */
  client_locations_by_pk?: Maybe<Client_Locations>;
  /** An array relationship */
  client_users: Array<Client_Users>;
  /** An aggregate relationship */
  client_users_aggregate: Client_Users_Aggregate;
  /** fetch data from the table: "client_users" using primary key columns */
  client_users_by_pk?: Maybe<Client_Users>;
  /** An array relationship */
  clients: Array<Clients>;
  /** An aggregate relationship */
  clients_aggregate: Clients_Aggregate;
  /** fetch data from the table: "clients" using primary key columns */
  clients_by_pk?: Maybe<Clients>;
  /** An array relationship */
  contacts: Array<Contacts>;
  /** An aggregate relationship */
  contacts_aggregate: Contacts_Aggregate;
  /** fetch data from the table: "contacts" using primary key columns */
  contacts_by_pk?: Maybe<Contacts>;
  /** fetch data from the table: "courses" */
  courses: Array<Courses>;
  /** fetch aggregated fields from the table: "courses" */
  courses_aggregate: Courses_Aggregate;
  /** fetch data from the table: "courses" using primary key columns */
  courses_by_pk?: Maybe<Courses>;
  /** fetch data from the table: "degrees" */
  degrees: Array<Degrees>;
  /** fetch aggregated fields from the table: "degrees" */
  degrees_aggregate: Degrees_Aggregate;
  /** fetch data from the table: "degrees" using primary key columns */
  degrees_by_pk?: Maybe<Degrees>;
  /** An array relationship */
  enterprise_clients: Array<Enterprise_Clients>;
  /** An aggregate relationship */
  enterprise_clients_aggregate: Enterprise_Clients_Aggregate;
  /** fetch data from the table: "enterprise_clients" using primary key columns */
  enterprise_clients_by_pk?: Maybe<Enterprise_Clients>;
  /** An array relationship */
  enterprise_locations: Array<Enterprise_Locations>;
  /** An aggregate relationship */
  enterprise_locations_aggregate: Enterprise_Locations_Aggregate;
  /** fetch data from the table: "enterprise_locations" using primary key columns */
  enterprise_locations_by_pk?: Maybe<Enterprise_Locations>;
  /** An array relationship */
  enterprises: Array<Enterprises>;
  /** An aggregate relationship */
  enterprises_aggregate: Enterprises_Aggregate;
  /** fetch data from the table: "enterprises" using primary key columns */
  enterprises_by_pk?: Maybe<Enterprises>;
  /** An array relationship */
  events: Array<Events>;
  /** An aggregate relationship */
  events_aggregate: Events_Aggregate;
  /** fetch data from the table: "events" using primary key columns */
  events_by_pk?: Maybe<Events>;
  /** An array relationship */
  exclusive_candidates: Array<Exclusive_Candidates>;
  /** An aggregate relationship */
  exclusive_candidates_aggregate: Exclusive_Candidates_Aggregate;
  /** fetch data from the table: "exclusive_candidates" using primary key columns */
  exclusive_candidates_by_pk?: Maybe<Exclusive_Candidates>;
  /** fb_server_event */
  fb_server_event: Scalars['Boolean'];
  /** An array relationship */
  feature_popups: Array<Feature_Popups>;
  /** An aggregate relationship */
  feature_popups_aggregate: Feature_Popups_Aggregate;
  /** fetch data from the table: "feature_popups" using primary key columns */
  feature_popups_by_pk?: Maybe<Feature_Popups>;
  /** form_options */
  form_options?: Maybe<FormOptionsOutput>;
  /** fetch data from the table: "geography_columns" */
  geography_columns: Array<Geography_Columns>;
  /** fetch aggregated fields from the table: "geography_columns" */
  geography_columns_aggregate: Geography_Columns_Aggregate;
  /** fetch data from the table: "geometry_columns" */
  geometry_columns: Array<Geometry_Columns>;
  /** fetch aggregated fields from the table: "geometry_columns" */
  geometry_columns_aggregate: Geometry_Columns_Aggregate;
  /** fetch data from the table: "industries" */
  industries: Array<Industries>;
  /** fetch aggregated fields from the table: "industries" */
  industries_aggregate: Industries_Aggregate;
  /** fetch data from the table: "industries" using primary key columns */
  industries_by_pk?: Maybe<Industries>;
  /** An array relationship */
  interview_participants: Array<Interview_Participants>;
  /** An aggregate relationship */
  interview_participants_aggregate: Interview_Participants_Aggregate;
  /** fetch data from the table: "interview_participants" using primary key columns */
  interview_participants_by_pk?: Maybe<Interview_Participants>;
  /** An array relationship */
  interviews: Array<Interviews>;
  /** An aggregate relationship */
  interviews_aggregate: Interviews_Aggregate;
  /** fetch data from the table: "interviews" using primary key columns */
  interviews_by_pk?: Maybe<Interviews>;
  /** An array relationship */
  job_candidates: Array<Job_Candidates>;
  /** An aggregate relationship */
  job_candidates_aggregate: Job_Candidates_Aggregate;
  /** fetch data from the table: "job_candidates" using primary key columns */
  job_candidates_by_pk?: Maybe<Job_Candidates>;
  /** An array relationship */
  job_locations: Array<Job_Locations>;
  /** An aggregate relationship */
  job_locations_aggregate: Job_Locations_Aggregate;
  /** fetch data from the table: "job_locations" using primary key columns */
  job_locations_by_pk?: Maybe<Job_Locations>;
  /** An array relationship */
  jobs: Array<Jobs>;
  /** An aggregate relationship */
  jobs_aggregate: Jobs_Aggregate;
  /** fetch data from the table: "jobs" using primary key columns */
  jobs_by_pk?: Maybe<Jobs>;
  /** fetch data from the table: "locations" */
  locations: Array<Locations>;
  /** fetch aggregated fields from the table: "locations" */
  locations_aggregate: Locations_Aggregate;
  /** fetch data from the table: "locations" using primary key columns */
  locations_by_pk?: Maybe<Locations>;
  /** fetch data from the table: "majors" */
  majors: Array<Majors>;
  /** fetch aggregated fields from the table: "majors" */
  majors_aggregate: Majors_Aggregate;
  /** fetch data from the table: "majors" using primary key columns */
  majors_by_pk?: Maybe<Majors>;
  /** Nylas - Return all Calendars */
  nylas_get_calendars?: Maybe<Nylas_GetCalendarsOutput>;
  /** Nylas - Return an event */
  nylas_get_event_by_id?: Maybe<Nylas_GetEventByIdOutput>;
  /** An array relationship */
  offers: Array<Offers>;
  /** An aggregate relationship */
  offers_aggregate: Offers_Aggregate;
  /** fetch data from the table: "offers" using primary key columns */
  offers_by_pk?: Maybe<Offers>;
  /** fetch data from the table: "report_targets" */
  report_targets: Array<Report_Targets>;
  /** fetch aggregated fields from the table: "report_targets" */
  report_targets_aggregate: Report_Targets_Aggregate;
  /** fetch data from the table: "report_targets" using primary key columns */
  report_targets_by_pk?: Maybe<Report_Targets>;
  /** An array relationship */
  short_urls: Array<Short_Urls>;
  /** An aggregate relationship */
  short_urls_aggregate: Short_Urls_Aggregate;
  /** fetch data from the table: "short_urls" using primary key columns */
  short_urls_by_pk?: Maybe<Short_Urls>;
  /** An array relationship */
  shortlists: Array<Shortlists>;
  /** An aggregate relationship */
  shortlists_aggregate: Shortlists_Aggregate;
  /** fetch data from the table: "shortlists" using primary key columns */
  shortlists_by_pk?: Maybe<Shortlists>;
  /** fetch data from the table: "skills" */
  skills: Array<Skills>;
  /** fetch aggregated fields from the table: "skills" */
  skills_aggregate: Skills_Aggregate;
  /** fetch data from the table: "skills" using primary key columns */
  skills_by_pk?: Maybe<Skills>;
  /** fetch data from the table: "spatial_ref_sys" */
  spatial_ref_sys: Array<Spatial_Ref_Sys>;
  /** fetch aggregated fields from the table: "spatial_ref_sys" */
  spatial_ref_sys_aggregate: Spatial_Ref_Sys_Aggregate;
  /** fetch data from the table: "spatial_ref_sys" using primary key columns */
  spatial_ref_sys_by_pk?: Maybe<Spatial_Ref_Sys>;
  /** An array relationship */
  tenant_candidates: Array<Tenant_Candidates>;
  /** An aggregate relationship */
  tenant_candidates_aggregate: Tenant_Candidates_Aggregate;
  /** fetch data from the table: "tenant_candidates" using primary key columns */
  tenant_candidates_by_pk?: Maybe<Tenant_Candidates>;
  /** An array relationship */
  tenant_locations: Array<Tenant_Locations>;
  /** An aggregate relationship */
  tenant_locations_aggregate: Tenant_Locations_Aggregate;
  /** fetch data from the table: "tenant_locations" using primary key columns */
  tenant_locations_by_pk?: Maybe<Tenant_Locations>;
  /** An array relationship */
  tenant_users: Array<Tenant_Users>;
  /** An aggregate relationship */
  tenant_users_aggregate: Tenant_Users_Aggregate;
  /** fetch data from the table: "tenant_users" using primary key columns */
  tenant_users_by_pk?: Maybe<Tenant_Users>;
  /** An array relationship */
  tenants: Array<Tenants>;
  /** An aggregate relationship */
  tenants_aggregate: Tenants_Aggregate;
  /** fetch data from the table: "tenants" using primary key columns */
  tenants_by_pk?: Maybe<Tenants>;
  track: Scalars['Boolean'];
  /** unique-candidate-default-values */
  unique_candidate_default_values?: Maybe<UniqueCandidateOutputAlt>;
  /** fetch data from the table: "universities" */
  universities: Array<Universities>;
  /** fetch aggregated fields from the table: "universities" */
  universities_aggregate: Universities_Aggregate;
  /** fetch data from the table: "universities" using primary key columns */
  universities_by_pk?: Maybe<Universities>;
  /** fetch data from the table: "users" */
  users: Array<Users>;
  /** fetch aggregated fields from the table: "users" */
  users_aggregate: Users_Aggregate;
  /** fetch data from the table: "users" using primary key columns */
  users_by_pk?: Maybe<Users>;
};


export type Query_RootBrimstone_Event_FeedbackArgs = {
  distinct_on?: Maybe<Array<Brimstone_Event_Feedback_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Brimstone_Event_Feedback_Order_By>>;
  where?: Maybe<Brimstone_Event_Feedback_Bool_Exp>;
};


export type Query_RootBrimstone_Event_Feedback_AggregateArgs = {
  distinct_on?: Maybe<Array<Brimstone_Event_Feedback_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Brimstone_Event_Feedback_Order_By>>;
  where?: Maybe<Brimstone_Event_Feedback_Bool_Exp>;
};


export type Query_RootBrimstone_Event_Feedback_By_PkArgs = {
  id: Scalars['uuid'];
};


export type Query_RootBrimstone_Event_SpeakersArgs = {
  distinct_on?: Maybe<Array<Brimstone_Event_Speakers_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Brimstone_Event_Speakers_Order_By>>;
  where?: Maybe<Brimstone_Event_Speakers_Bool_Exp>;
};


export type Query_RootBrimstone_Event_Speakers_AggregateArgs = {
  distinct_on?: Maybe<Array<Brimstone_Event_Speakers_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Brimstone_Event_Speakers_Order_By>>;
  where?: Maybe<Brimstone_Event_Speakers_Bool_Exp>;
};


export type Query_RootBrimstone_Event_Speakers_By_PkArgs = {
  id: Scalars['uuid'];
};


export type Query_RootBrimstone_EventsArgs = {
  distinct_on?: Maybe<Array<Brimstone_Events_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Brimstone_Events_Order_By>>;
  where?: Maybe<Brimstone_Events_Bool_Exp>;
};


export type Query_RootBrimstone_Events_AggregateArgs = {
  distinct_on?: Maybe<Array<Brimstone_Events_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Brimstone_Events_Order_By>>;
  where?: Maybe<Brimstone_Events_Bool_Exp>;
};


export type Query_RootBrimstone_Events_By_PkArgs = {
  id: Scalars['uuid'];
};


export type Query_RootBrimstone_SpeakersArgs = {
  distinct_on?: Maybe<Array<Brimstone_Speakers_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Brimstone_Speakers_Order_By>>;
  where?: Maybe<Brimstone_Speakers_Bool_Exp>;
};


export type Query_RootBrimstone_Speakers_AggregateArgs = {
  distinct_on?: Maybe<Array<Brimstone_Speakers_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Brimstone_Speakers_Order_By>>;
  where?: Maybe<Brimstone_Speakers_Bool_Exp>;
};


export type Query_RootBrimstone_Speakers_By_PkArgs = {
  id: Scalars['uuid'];
};


export type Query_RootCandidate_CoursesArgs = {
  distinct_on?: Maybe<Array<Candidate_Courses_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Candidate_Courses_Order_By>>;
  where?: Maybe<Candidate_Courses_Bool_Exp>;
};


export type Query_RootCandidate_Courses_AggregateArgs = {
  distinct_on?: Maybe<Array<Candidate_Courses_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Candidate_Courses_Order_By>>;
  where?: Maybe<Candidate_Courses_Bool_Exp>;
};


export type Query_RootCandidate_Courses_By_PkArgs = {
  candidate_id: Scalars['uuid'];
  course_id: Scalars['uuid'];
};


export type Query_RootCandidate_DegreesArgs = {
  distinct_on?: Maybe<Array<Candidate_Degrees_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Candidate_Degrees_Order_By>>;
  where?: Maybe<Candidate_Degrees_Bool_Exp>;
};


export type Query_RootCandidate_Degrees_AggregateArgs = {
  distinct_on?: Maybe<Array<Candidate_Degrees_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Candidate_Degrees_Order_By>>;
  where?: Maybe<Candidate_Degrees_Bool_Exp>;
};


export type Query_RootCandidate_Degrees_By_PkArgs = {
  id: Scalars['uuid'];
};


export type Query_RootCandidate_FollowsArgs = {
  distinct_on?: Maybe<Array<Candidate_Follows_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Candidate_Follows_Order_By>>;
  where?: Maybe<Candidate_Follows_Bool_Exp>;
};


export type Query_RootCandidate_Follows_AggregateArgs = {
  distinct_on?: Maybe<Array<Candidate_Follows_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Candidate_Follows_Order_By>>;
  where?: Maybe<Candidate_Follows_Bool_Exp>;
};


export type Query_RootCandidate_Follows_By_PkArgs = {
  candidate_id: Scalars['uuid'];
  enterprise_id: Scalars['uuid'];
};


export type Query_RootCandidate_IndustriesArgs = {
  distinct_on?: Maybe<Array<Candidate_Industries_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Candidate_Industries_Order_By>>;
  where?: Maybe<Candidate_Industries_Bool_Exp>;
};


export type Query_RootCandidate_Industries_AggregateArgs = {
  distinct_on?: Maybe<Array<Candidate_Industries_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Candidate_Industries_Order_By>>;
  where?: Maybe<Candidate_Industries_Bool_Exp>;
};


export type Query_RootCandidate_Industries_By_PkArgs = {
  id: Scalars['uuid'];
};


export type Query_RootCandidate_LocationsArgs = {
  distinct_on?: Maybe<Array<Candidate_Locations_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Candidate_Locations_Order_By>>;
  where?: Maybe<Candidate_Locations_Bool_Exp>;
};


export type Query_RootCandidate_Locations_AggregateArgs = {
  distinct_on?: Maybe<Array<Candidate_Locations_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Candidate_Locations_Order_By>>;
  where?: Maybe<Candidate_Locations_Bool_Exp>;
};


export type Query_RootCandidate_Locations_By_PkArgs = {
  id: Scalars['uuid'];
};


export type Query_RootCandidate_MajorsArgs = {
  distinct_on?: Maybe<Array<Candidate_Majors_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Candidate_Majors_Order_By>>;
  where?: Maybe<Candidate_Majors_Bool_Exp>;
};


export type Query_RootCandidate_Majors_AggregateArgs = {
  distinct_on?: Maybe<Array<Candidate_Majors_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Candidate_Majors_Order_By>>;
  where?: Maybe<Candidate_Majors_Bool_Exp>;
};


export type Query_RootCandidate_Majors_By_PkArgs = {
  id: Scalars['uuid'];
};


export type Query_RootCandidate_PlacementsArgs = {
  distinct_on?: Maybe<Array<Candidate_Placements_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Candidate_Placements_Order_By>>;
  where?: Maybe<Candidate_Placements_Bool_Exp>;
};


export type Query_RootCandidate_Placements_AggregateArgs = {
  distinct_on?: Maybe<Array<Candidate_Placements_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Candidate_Placements_Order_By>>;
  where?: Maybe<Candidate_Placements_Bool_Exp>;
};


export type Query_RootCandidate_Placements_By_PkArgs = {
  id: Scalars['uuid'];
};


export type Query_RootCandidate_SkillsArgs = {
  distinct_on?: Maybe<Array<Candidate_Skills_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Candidate_Skills_Order_By>>;
  where?: Maybe<Candidate_Skills_Bool_Exp>;
};


export type Query_RootCandidate_Skills_AggregateArgs = {
  distinct_on?: Maybe<Array<Candidate_Skills_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Candidate_Skills_Order_By>>;
  where?: Maybe<Candidate_Skills_Bool_Exp>;
};


export type Query_RootCandidate_Skills_By_PkArgs = {
  id: Scalars['uuid'];
};


export type Query_RootCandidatesArgs = {
  distinct_on?: Maybe<Array<Candidates_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Candidates_Order_By>>;
  where?: Maybe<Candidates_Bool_Exp>;
};


export type Query_RootCandidates_AggregateArgs = {
  distinct_on?: Maybe<Array<Candidates_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Candidates_Order_By>>;
  where?: Maybe<Candidates_Bool_Exp>;
};


export type Query_RootCandidates_By_PkArgs = {
  id: Scalars['uuid'];
};


export type Query_RootClient_IndustriesArgs = {
  distinct_on?: Maybe<Array<Client_Industries_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Client_Industries_Order_By>>;
  where?: Maybe<Client_Industries_Bool_Exp>;
};


export type Query_RootClient_Industries_AggregateArgs = {
  distinct_on?: Maybe<Array<Client_Industries_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Client_Industries_Order_By>>;
  where?: Maybe<Client_Industries_Bool_Exp>;
};


export type Query_RootClient_Industries_By_PkArgs = {
  id: Scalars['uuid'];
};


export type Query_RootClient_JobsArgs = {
  distinct_on?: Maybe<Array<Client_Jobs_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Client_Jobs_Order_By>>;
  where?: Maybe<Client_Jobs_Bool_Exp>;
};


export type Query_RootClient_Jobs_AggregateArgs = {
  distinct_on?: Maybe<Array<Client_Jobs_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Client_Jobs_Order_By>>;
  where?: Maybe<Client_Jobs_Bool_Exp>;
};


export type Query_RootClient_Jobs_By_PkArgs = {
  id: Scalars['uuid'];
};


export type Query_RootClient_LocationsArgs = {
  distinct_on?: Maybe<Array<Client_Locations_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Client_Locations_Order_By>>;
  where?: Maybe<Client_Locations_Bool_Exp>;
};


export type Query_RootClient_Locations_AggregateArgs = {
  distinct_on?: Maybe<Array<Client_Locations_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Client_Locations_Order_By>>;
  where?: Maybe<Client_Locations_Bool_Exp>;
};


export type Query_RootClient_Locations_By_PkArgs = {
  id: Scalars['uuid'];
};


export type Query_RootClient_UsersArgs = {
  distinct_on?: Maybe<Array<Client_Users_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Client_Users_Order_By>>;
  where?: Maybe<Client_Users_Bool_Exp>;
};


export type Query_RootClient_Users_AggregateArgs = {
  distinct_on?: Maybe<Array<Client_Users_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Client_Users_Order_By>>;
  where?: Maybe<Client_Users_Bool_Exp>;
};


export type Query_RootClient_Users_By_PkArgs = {
  id: Scalars['uuid'];
};


export type Query_RootClientsArgs = {
  distinct_on?: Maybe<Array<Clients_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Clients_Order_By>>;
  where?: Maybe<Clients_Bool_Exp>;
};


export type Query_RootClients_AggregateArgs = {
  distinct_on?: Maybe<Array<Clients_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Clients_Order_By>>;
  where?: Maybe<Clients_Bool_Exp>;
};


export type Query_RootClients_By_PkArgs = {
  id: Scalars['uuid'];
};


export type Query_RootContactsArgs = {
  distinct_on?: Maybe<Array<Contacts_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Contacts_Order_By>>;
  where?: Maybe<Contacts_Bool_Exp>;
};


export type Query_RootContacts_AggregateArgs = {
  distinct_on?: Maybe<Array<Contacts_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Contacts_Order_By>>;
  where?: Maybe<Contacts_Bool_Exp>;
};


export type Query_RootContacts_By_PkArgs = {
  id: Scalars['uuid'];
};


export type Query_RootCoursesArgs = {
  distinct_on?: Maybe<Array<Courses_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Courses_Order_By>>;
  where?: Maybe<Courses_Bool_Exp>;
};


export type Query_RootCourses_AggregateArgs = {
  distinct_on?: Maybe<Array<Courses_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Courses_Order_By>>;
  where?: Maybe<Courses_Bool_Exp>;
};


export type Query_RootCourses_By_PkArgs = {
  id: Scalars['uuid'];
};


export type Query_RootDegreesArgs = {
  distinct_on?: Maybe<Array<Degrees_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Degrees_Order_By>>;
  where?: Maybe<Degrees_Bool_Exp>;
};


export type Query_RootDegrees_AggregateArgs = {
  distinct_on?: Maybe<Array<Degrees_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Degrees_Order_By>>;
  where?: Maybe<Degrees_Bool_Exp>;
};


export type Query_RootDegrees_By_PkArgs = {
  id: Scalars['uuid'];
};


export type Query_RootEnterprise_ClientsArgs = {
  distinct_on?: Maybe<Array<Enterprise_Clients_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Enterprise_Clients_Order_By>>;
  where?: Maybe<Enterprise_Clients_Bool_Exp>;
};


export type Query_RootEnterprise_Clients_AggregateArgs = {
  distinct_on?: Maybe<Array<Enterprise_Clients_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Enterprise_Clients_Order_By>>;
  where?: Maybe<Enterprise_Clients_Bool_Exp>;
};


export type Query_RootEnterprise_Clients_By_PkArgs = {
  client_id: Scalars['uuid'];
  enterprise_id: Scalars['uuid'];
};


export type Query_RootEnterprise_LocationsArgs = {
  distinct_on?: Maybe<Array<Enterprise_Locations_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Enterprise_Locations_Order_By>>;
  where?: Maybe<Enterprise_Locations_Bool_Exp>;
};


export type Query_RootEnterprise_Locations_AggregateArgs = {
  distinct_on?: Maybe<Array<Enterprise_Locations_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Enterprise_Locations_Order_By>>;
  where?: Maybe<Enterprise_Locations_Bool_Exp>;
};


export type Query_RootEnterprise_Locations_By_PkArgs = {
  id: Scalars['uuid'];
};


export type Query_RootEnterprisesArgs = {
  distinct_on?: Maybe<Array<Enterprises_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Enterprises_Order_By>>;
  where?: Maybe<Enterprises_Bool_Exp>;
};


export type Query_RootEnterprises_AggregateArgs = {
  distinct_on?: Maybe<Array<Enterprises_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Enterprises_Order_By>>;
  where?: Maybe<Enterprises_Bool_Exp>;
};


export type Query_RootEnterprises_By_PkArgs = {
  id: Scalars['uuid'];
};


export type Query_RootEventsArgs = {
  distinct_on?: Maybe<Array<Events_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Events_Order_By>>;
  where?: Maybe<Events_Bool_Exp>;
};


export type Query_RootEvents_AggregateArgs = {
  distinct_on?: Maybe<Array<Events_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Events_Order_By>>;
  where?: Maybe<Events_Bool_Exp>;
};


export type Query_RootEvents_By_PkArgs = {
  id: Scalars['uuid'];
};


export type Query_RootExclusive_CandidatesArgs = {
  distinct_on?: Maybe<Array<Exclusive_Candidates_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Exclusive_Candidates_Order_By>>;
  where?: Maybe<Exclusive_Candidates_Bool_Exp>;
};


export type Query_RootExclusive_Candidates_AggregateArgs = {
  distinct_on?: Maybe<Array<Exclusive_Candidates_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Exclusive_Candidates_Order_By>>;
  where?: Maybe<Exclusive_Candidates_Bool_Exp>;
};


export type Query_RootExclusive_Candidates_By_PkArgs = {
  id: Scalars['uuid'];
};


export type Query_RootFb_Server_EventArgs = {
  id: Scalars['uuid'];
};


export type Query_RootFeature_PopupsArgs = {
  distinct_on?: Maybe<Array<Feature_Popups_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Feature_Popups_Order_By>>;
  where?: Maybe<Feature_Popups_Bool_Exp>;
};


export type Query_RootFeature_Popups_AggregateArgs = {
  distinct_on?: Maybe<Array<Feature_Popups_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Feature_Popups_Order_By>>;
  where?: Maybe<Feature_Popups_Bool_Exp>;
};


export type Query_RootFeature_Popups_By_PkArgs = {
  id: Scalars['uuid'];
};


export type Query_RootGeography_ColumnsArgs = {
  distinct_on?: Maybe<Array<Geography_Columns_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Geography_Columns_Order_By>>;
  where?: Maybe<Geography_Columns_Bool_Exp>;
};


export type Query_RootGeography_Columns_AggregateArgs = {
  distinct_on?: Maybe<Array<Geography_Columns_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Geography_Columns_Order_By>>;
  where?: Maybe<Geography_Columns_Bool_Exp>;
};


export type Query_RootGeometry_ColumnsArgs = {
  distinct_on?: Maybe<Array<Geometry_Columns_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Geometry_Columns_Order_By>>;
  where?: Maybe<Geometry_Columns_Bool_Exp>;
};


export type Query_RootGeometry_Columns_AggregateArgs = {
  distinct_on?: Maybe<Array<Geometry_Columns_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Geometry_Columns_Order_By>>;
  where?: Maybe<Geometry_Columns_Bool_Exp>;
};


export type Query_RootIndustriesArgs = {
  distinct_on?: Maybe<Array<Industries_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Industries_Order_By>>;
  where?: Maybe<Industries_Bool_Exp>;
};


export type Query_RootIndustries_AggregateArgs = {
  distinct_on?: Maybe<Array<Industries_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Industries_Order_By>>;
  where?: Maybe<Industries_Bool_Exp>;
};


export type Query_RootIndustries_By_PkArgs = {
  id: Scalars['uuid'];
};


export type Query_RootInterview_ParticipantsArgs = {
  distinct_on?: Maybe<Array<Interview_Participants_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Interview_Participants_Order_By>>;
  where?: Maybe<Interview_Participants_Bool_Exp>;
};


export type Query_RootInterview_Participants_AggregateArgs = {
  distinct_on?: Maybe<Array<Interview_Participants_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Interview_Participants_Order_By>>;
  where?: Maybe<Interview_Participants_Bool_Exp>;
};


export type Query_RootInterview_Participants_By_PkArgs = {
  id: Scalars['uuid'];
};


export type Query_RootInterviewsArgs = {
  distinct_on?: Maybe<Array<Interviews_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Interviews_Order_By>>;
  where?: Maybe<Interviews_Bool_Exp>;
};


export type Query_RootInterviews_AggregateArgs = {
  distinct_on?: Maybe<Array<Interviews_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Interviews_Order_By>>;
  where?: Maybe<Interviews_Bool_Exp>;
};


export type Query_RootInterviews_By_PkArgs = {
  id: Scalars['uuid'];
};


export type Query_RootJob_CandidatesArgs = {
  distinct_on?: Maybe<Array<Job_Candidates_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Job_Candidates_Order_By>>;
  where?: Maybe<Job_Candidates_Bool_Exp>;
};


export type Query_RootJob_Candidates_AggregateArgs = {
  distinct_on?: Maybe<Array<Job_Candidates_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Job_Candidates_Order_By>>;
  where?: Maybe<Job_Candidates_Bool_Exp>;
};


export type Query_RootJob_Candidates_By_PkArgs = {
  id: Scalars['uuid'];
};


export type Query_RootJob_LocationsArgs = {
  distinct_on?: Maybe<Array<Job_Locations_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Job_Locations_Order_By>>;
  where?: Maybe<Job_Locations_Bool_Exp>;
};


export type Query_RootJob_Locations_AggregateArgs = {
  distinct_on?: Maybe<Array<Job_Locations_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Job_Locations_Order_By>>;
  where?: Maybe<Job_Locations_Bool_Exp>;
};


export type Query_RootJob_Locations_By_PkArgs = {
  id: Scalars['uuid'];
};


export type Query_RootJobsArgs = {
  distinct_on?: Maybe<Array<Jobs_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Jobs_Order_By>>;
  where?: Maybe<Jobs_Bool_Exp>;
};


export type Query_RootJobs_AggregateArgs = {
  distinct_on?: Maybe<Array<Jobs_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Jobs_Order_By>>;
  where?: Maybe<Jobs_Bool_Exp>;
};


export type Query_RootJobs_By_PkArgs = {
  id: Scalars['uuid'];
};


export type Query_RootLocationsArgs = {
  distinct_on?: Maybe<Array<Locations_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Locations_Order_By>>;
  where?: Maybe<Locations_Bool_Exp>;
};


export type Query_RootLocations_AggregateArgs = {
  distinct_on?: Maybe<Array<Locations_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Locations_Order_By>>;
  where?: Maybe<Locations_Bool_Exp>;
};


export type Query_RootLocations_By_PkArgs = {
  id: Scalars['uuid'];
};


export type Query_RootMajorsArgs = {
  distinct_on?: Maybe<Array<Majors_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Majors_Order_By>>;
  where?: Maybe<Majors_Bool_Exp>;
};


export type Query_RootMajors_AggregateArgs = {
  distinct_on?: Maybe<Array<Majors_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Majors_Order_By>>;
  where?: Maybe<Majors_Bool_Exp>;
};


export type Query_RootMajors_By_PkArgs = {
  id: Scalars['uuid'];
};


export type Query_RootNylas_Get_Event_By_IdArgs = {
  payload: Nylas_GetEventByIdInput;
};


export type Query_RootOffersArgs = {
  distinct_on?: Maybe<Array<Offers_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Offers_Order_By>>;
  where?: Maybe<Offers_Bool_Exp>;
};


export type Query_RootOffers_AggregateArgs = {
  distinct_on?: Maybe<Array<Offers_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Offers_Order_By>>;
  where?: Maybe<Offers_Bool_Exp>;
};


export type Query_RootOffers_By_PkArgs = {
  id: Scalars['uuid'];
};


export type Query_RootReport_TargetsArgs = {
  distinct_on?: Maybe<Array<Report_Targets_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Report_Targets_Order_By>>;
  where?: Maybe<Report_Targets_Bool_Exp>;
};


export type Query_RootReport_Targets_AggregateArgs = {
  distinct_on?: Maybe<Array<Report_Targets_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Report_Targets_Order_By>>;
  where?: Maybe<Report_Targets_Bool_Exp>;
};


export type Query_RootReport_Targets_By_PkArgs = {
  id: Scalars['uuid'];
};


export type Query_RootShort_UrlsArgs = {
  distinct_on?: Maybe<Array<Short_Urls_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Short_Urls_Order_By>>;
  where?: Maybe<Short_Urls_Bool_Exp>;
};


export type Query_RootShort_Urls_AggregateArgs = {
  distinct_on?: Maybe<Array<Short_Urls_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Short_Urls_Order_By>>;
  where?: Maybe<Short_Urls_Bool_Exp>;
};


export type Query_RootShort_Urls_By_PkArgs = {
  id: Scalars['uuid'];
};


export type Query_RootShortlistsArgs = {
  distinct_on?: Maybe<Array<Shortlists_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Shortlists_Order_By>>;
  where?: Maybe<Shortlists_Bool_Exp>;
};


export type Query_RootShortlists_AggregateArgs = {
  distinct_on?: Maybe<Array<Shortlists_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Shortlists_Order_By>>;
  where?: Maybe<Shortlists_Bool_Exp>;
};


export type Query_RootShortlists_By_PkArgs = {
  id: Scalars['uuid'];
};


export type Query_RootSkillsArgs = {
  distinct_on?: Maybe<Array<Skills_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Skills_Order_By>>;
  where?: Maybe<Skills_Bool_Exp>;
};


export type Query_RootSkills_AggregateArgs = {
  distinct_on?: Maybe<Array<Skills_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Skills_Order_By>>;
  where?: Maybe<Skills_Bool_Exp>;
};


export type Query_RootSkills_By_PkArgs = {
  id: Scalars['uuid'];
};


export type Query_RootSpatial_Ref_SysArgs = {
  distinct_on?: Maybe<Array<Spatial_Ref_Sys_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Spatial_Ref_Sys_Order_By>>;
  where?: Maybe<Spatial_Ref_Sys_Bool_Exp>;
};


export type Query_RootSpatial_Ref_Sys_AggregateArgs = {
  distinct_on?: Maybe<Array<Spatial_Ref_Sys_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Spatial_Ref_Sys_Order_By>>;
  where?: Maybe<Spatial_Ref_Sys_Bool_Exp>;
};


export type Query_RootSpatial_Ref_Sys_By_PkArgs = {
  srid: Scalars['Int'];
};


export type Query_RootTenant_CandidatesArgs = {
  distinct_on?: Maybe<Array<Tenant_Candidates_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Tenant_Candidates_Order_By>>;
  where?: Maybe<Tenant_Candidates_Bool_Exp>;
};


export type Query_RootTenant_Candidates_AggregateArgs = {
  distinct_on?: Maybe<Array<Tenant_Candidates_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Tenant_Candidates_Order_By>>;
  where?: Maybe<Tenant_Candidates_Bool_Exp>;
};


export type Query_RootTenant_Candidates_By_PkArgs = {
  id: Scalars['uuid'];
};


export type Query_RootTenant_LocationsArgs = {
  distinct_on?: Maybe<Array<Tenant_Locations_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Tenant_Locations_Order_By>>;
  where?: Maybe<Tenant_Locations_Bool_Exp>;
};


export type Query_RootTenant_Locations_AggregateArgs = {
  distinct_on?: Maybe<Array<Tenant_Locations_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Tenant_Locations_Order_By>>;
  where?: Maybe<Tenant_Locations_Bool_Exp>;
};


export type Query_RootTenant_Locations_By_PkArgs = {
  id: Scalars['uuid'];
};


export type Query_RootTenant_UsersArgs = {
  distinct_on?: Maybe<Array<Tenant_Users_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Tenant_Users_Order_By>>;
  where?: Maybe<Tenant_Users_Bool_Exp>;
};


export type Query_RootTenant_Users_AggregateArgs = {
  distinct_on?: Maybe<Array<Tenant_Users_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Tenant_Users_Order_By>>;
  where?: Maybe<Tenant_Users_Bool_Exp>;
};


export type Query_RootTenant_Users_By_PkArgs = {
  id: Scalars['uuid'];
};


export type Query_RootTenantsArgs = {
  distinct_on?: Maybe<Array<Tenants_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Tenants_Order_By>>;
  where?: Maybe<Tenants_Bool_Exp>;
};


export type Query_RootTenants_AggregateArgs = {
  distinct_on?: Maybe<Array<Tenants_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Tenants_Order_By>>;
  where?: Maybe<Tenants_Bool_Exp>;
};


export type Query_RootTenants_By_PkArgs = {
  id: Scalars['uuid'];
};


export type Query_RootTrackArgs = {
  id: Scalars['uuid'];
};


export type Query_RootUnique_Candidate_Default_ValuesArgs = {
  candidateId: Scalars['uuid'];
};


export type Query_RootUniversitiesArgs = {
  distinct_on?: Maybe<Array<Universities_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Universities_Order_By>>;
  where?: Maybe<Universities_Bool_Exp>;
};


export type Query_RootUniversities_AggregateArgs = {
  distinct_on?: Maybe<Array<Universities_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Universities_Order_By>>;
  where?: Maybe<Universities_Bool_Exp>;
};


export type Query_RootUniversities_By_PkArgs = {
  id: Scalars['uuid'];
};


export type Query_RootUsersArgs = {
  distinct_on?: Maybe<Array<Users_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Users_Order_By>>;
  where?: Maybe<Users_Bool_Exp>;
};


export type Query_RootUsers_AggregateArgs = {
  distinct_on?: Maybe<Array<Users_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Users_Order_By>>;
  where?: Maybe<Users_Bool_Exp>;
};


export type Query_RootUsers_By_PkArgs = {
  id: Scalars['uuid'];
};

/** columns and relationships of "report_targets" */
export type Report_Targets = {
  __typename?: 'report_targets';
  created_at: Scalars['timestamptz'];
  deleted_at?: Maybe<Scalars['timestamptz']>;
  id: Scalars['uuid'];
  name: Scalars['String'];
  target: Scalars['Int'];
  updated_at: Scalars['timestamptz'];
};

/** aggregated selection of "report_targets" */
export type Report_Targets_Aggregate = {
  __typename?: 'report_targets_aggregate';
  aggregate?: Maybe<Report_Targets_Aggregate_Fields>;
  nodes: Array<Report_Targets>;
};

/** aggregate fields of "report_targets" */
export type Report_Targets_Aggregate_Fields = {
  __typename?: 'report_targets_aggregate_fields';
  avg?: Maybe<Report_Targets_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<Report_Targets_Max_Fields>;
  min?: Maybe<Report_Targets_Min_Fields>;
  stddev?: Maybe<Report_Targets_Stddev_Fields>;
  stddev_pop?: Maybe<Report_Targets_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Report_Targets_Stddev_Samp_Fields>;
  sum?: Maybe<Report_Targets_Sum_Fields>;
  var_pop?: Maybe<Report_Targets_Var_Pop_Fields>;
  var_samp?: Maybe<Report_Targets_Var_Samp_Fields>;
  variance?: Maybe<Report_Targets_Variance_Fields>;
};


/** aggregate fields of "report_targets" */
export type Report_Targets_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<Report_Targets_Select_Column>>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** aggregate avg on columns */
export type Report_Targets_Avg_Fields = {
  __typename?: 'report_targets_avg_fields';
  target?: Maybe<Scalars['Float']>;
};

/** Boolean expression to filter rows from the table "report_targets". All fields are combined with a logical 'AND'. */
export type Report_Targets_Bool_Exp = {
  _and?: Maybe<Array<Report_Targets_Bool_Exp>>;
  _not?: Maybe<Report_Targets_Bool_Exp>;
  _or?: Maybe<Array<Report_Targets_Bool_Exp>>;
  created_at?: Maybe<Timestamptz_Comparison_Exp>;
  deleted_at?: Maybe<Timestamptz_Comparison_Exp>;
  id?: Maybe<Uuid_Comparison_Exp>;
  name?: Maybe<String_Comparison_Exp>;
  target?: Maybe<Int_Comparison_Exp>;
  updated_at?: Maybe<Timestamptz_Comparison_Exp>;
};

/** unique or primary key constraints on table "report_targets" */
export enum Report_Targets_Constraint {
  /** unique or primary key constraint on columns "id" */
  ReportTargetsPkey = 'report_targets_pkey'
}

/** input type for incrementing numeric columns in table "report_targets" */
export type Report_Targets_Inc_Input = {
  target?: Maybe<Scalars['Int']>;
};

/** input type for inserting data into table "report_targets" */
export type Report_Targets_Insert_Input = {
  created_at?: Maybe<Scalars['timestamptz']>;
  deleted_at?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['uuid']>;
  name?: Maybe<Scalars['String']>;
  target?: Maybe<Scalars['Int']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** aggregate max on columns */
export type Report_Targets_Max_Fields = {
  __typename?: 'report_targets_max_fields';
  created_at?: Maybe<Scalars['timestamptz']>;
  deleted_at?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['uuid']>;
  name?: Maybe<Scalars['String']>;
  target?: Maybe<Scalars['Int']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** aggregate min on columns */
export type Report_Targets_Min_Fields = {
  __typename?: 'report_targets_min_fields';
  created_at?: Maybe<Scalars['timestamptz']>;
  deleted_at?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['uuid']>;
  name?: Maybe<Scalars['String']>;
  target?: Maybe<Scalars['Int']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** response of any mutation on the table "report_targets" */
export type Report_Targets_Mutation_Response = {
  __typename?: 'report_targets_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Report_Targets>;
};

/** on_conflict condition type for table "report_targets" */
export type Report_Targets_On_Conflict = {
  constraint: Report_Targets_Constraint;
  update_columns?: Array<Report_Targets_Update_Column>;
  where?: Maybe<Report_Targets_Bool_Exp>;
};

/** Ordering options when selecting data from "report_targets". */
export type Report_Targets_Order_By = {
  created_at?: Maybe<Order_By>;
  deleted_at?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  name?: Maybe<Order_By>;
  target?: Maybe<Order_By>;
  updated_at?: Maybe<Order_By>;
};

/** primary key columns input for table: report_targets */
export type Report_Targets_Pk_Columns_Input = {
  id: Scalars['uuid'];
};

/** select columns of table "report_targets" */
export enum Report_Targets_Select_Column {
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  DeletedAt = 'deleted_at',
  /** column name */
  Id = 'id',
  /** column name */
  Name = 'name',
  /** column name */
  Target = 'target',
  /** column name */
  UpdatedAt = 'updated_at'
}

/** input type for updating data in table "report_targets" */
export type Report_Targets_Set_Input = {
  created_at?: Maybe<Scalars['timestamptz']>;
  deleted_at?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['uuid']>;
  name?: Maybe<Scalars['String']>;
  target?: Maybe<Scalars['Int']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** aggregate stddev on columns */
export type Report_Targets_Stddev_Fields = {
  __typename?: 'report_targets_stddev_fields';
  target?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_pop on columns */
export type Report_Targets_Stddev_Pop_Fields = {
  __typename?: 'report_targets_stddev_pop_fields';
  target?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_samp on columns */
export type Report_Targets_Stddev_Samp_Fields = {
  __typename?: 'report_targets_stddev_samp_fields';
  target?: Maybe<Scalars['Float']>;
};

/** Streaming cursor of the table "report_targets" */
export type Report_Targets_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Report_Targets_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: Maybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Report_Targets_Stream_Cursor_Value_Input = {
  created_at?: Maybe<Scalars['timestamptz']>;
  deleted_at?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['uuid']>;
  name?: Maybe<Scalars['String']>;
  target?: Maybe<Scalars['Int']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** aggregate sum on columns */
export type Report_Targets_Sum_Fields = {
  __typename?: 'report_targets_sum_fields';
  target?: Maybe<Scalars['Int']>;
};

/** update columns of table "report_targets" */
export enum Report_Targets_Update_Column {
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  DeletedAt = 'deleted_at',
  /** column name */
  Id = 'id',
  /** column name */
  Name = 'name',
  /** column name */
  Target = 'target',
  /** column name */
  UpdatedAt = 'updated_at'
}

export type Report_Targets_Updates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: Maybe<Report_Targets_Inc_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: Maybe<Report_Targets_Set_Input>;
  /** filter the rows which have to be updated */
  where: Report_Targets_Bool_Exp;
};

/** aggregate var_pop on columns */
export type Report_Targets_Var_Pop_Fields = {
  __typename?: 'report_targets_var_pop_fields';
  target?: Maybe<Scalars['Float']>;
};

/** aggregate var_samp on columns */
export type Report_Targets_Var_Samp_Fields = {
  __typename?: 'report_targets_var_samp_fields';
  target?: Maybe<Scalars['Float']>;
};

/** aggregate variance on columns */
export type Report_Targets_Variance_Fields = {
  __typename?: 'report_targets_variance_fields';
  target?: Maybe<Scalars['Float']>;
};


/** Boolean expression to compare columns of type "residency_status_enums". All fields are combined with logical 'AND'. */
export type Residency_Status_Enums_Comparison_Exp = {
  _eq?: Maybe<Scalars['residency_status_enums']>;
  _gt?: Maybe<Scalars['residency_status_enums']>;
  _gte?: Maybe<Scalars['residency_status_enums']>;
  _in?: Maybe<Array<Scalars['residency_status_enums']>>;
  _is_null?: Maybe<Scalars['Boolean']>;
  _lt?: Maybe<Scalars['residency_status_enums']>;
  _lte?: Maybe<Scalars['residency_status_enums']>;
  _neq?: Maybe<Scalars['residency_status_enums']>;
  _nin?: Maybe<Array<Scalars['residency_status_enums']>>;
};


/** Boolean expression to compare columns of type "short_url_action_enums". All fields are combined with logical 'AND'. */
export type Short_Url_Action_Enums_Comparison_Exp = {
  _eq?: Maybe<Scalars['short_url_action_enums']>;
  _gt?: Maybe<Scalars['short_url_action_enums']>;
  _gte?: Maybe<Scalars['short_url_action_enums']>;
  _in?: Maybe<Array<Scalars['short_url_action_enums']>>;
  _is_null?: Maybe<Scalars['Boolean']>;
  _lt?: Maybe<Scalars['short_url_action_enums']>;
  _lte?: Maybe<Scalars['short_url_action_enums']>;
  _neq?: Maybe<Scalars['short_url_action_enums']>;
  _nin?: Maybe<Array<Scalars['short_url_action_enums']>>;
};

/** columns and relationships of "short_urls" */
export type Short_Urls = {
  __typename?: 'short_urls';
  action: Scalars['short_url_action_enums'];
  created_at: Scalars['timestamptz'];
  deleted_at?: Maybe<Scalars['timestamptz']>;
  /** An array relationship */
  events: Array<Events>;
  /** An aggregate relationship */
  events_aggregate: Events_Aggregate;
  expires_on?: Maybe<Scalars['timestamptz']>;
  id: Scalars['uuid'];
  long_url: Scalars['String'];
  metadata?: Maybe<Scalars['jsonb']>;
  owner_id?: Maybe<Scalars['String']>;
  short_url: Scalars['String'];
  updated_at?: Maybe<Scalars['timestamptz']>;
  /** An object relationship */
  user?: Maybe<Users>;
};


/** columns and relationships of "short_urls" */
export type Short_UrlsEventsArgs = {
  distinct_on?: Maybe<Array<Events_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Events_Order_By>>;
  where?: Maybe<Events_Bool_Exp>;
};


/** columns and relationships of "short_urls" */
export type Short_UrlsEvents_AggregateArgs = {
  distinct_on?: Maybe<Array<Events_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Events_Order_By>>;
  where?: Maybe<Events_Bool_Exp>;
};


/** columns and relationships of "short_urls" */
export type Short_UrlsMetadataArgs = {
  path?: Maybe<Scalars['String']>;
};

/** aggregated selection of "short_urls" */
export type Short_Urls_Aggregate = {
  __typename?: 'short_urls_aggregate';
  aggregate?: Maybe<Short_Urls_Aggregate_Fields>;
  nodes: Array<Short_Urls>;
};

export type Short_Urls_Aggregate_Bool_Exp = {
  count?: Maybe<Short_Urls_Aggregate_Bool_Exp_Count>;
};

export type Short_Urls_Aggregate_Bool_Exp_Count = {
  arguments?: Maybe<Array<Short_Urls_Select_Column>>;
  distinct?: Maybe<Scalars['Boolean']>;
  filter?: Maybe<Short_Urls_Bool_Exp>;
  predicate: Int_Comparison_Exp;
};

/** aggregate fields of "short_urls" */
export type Short_Urls_Aggregate_Fields = {
  __typename?: 'short_urls_aggregate_fields';
  count: Scalars['Int'];
  max?: Maybe<Short_Urls_Max_Fields>;
  min?: Maybe<Short_Urls_Min_Fields>;
};


/** aggregate fields of "short_urls" */
export type Short_Urls_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<Short_Urls_Select_Column>>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "short_urls" */
export type Short_Urls_Aggregate_Order_By = {
  count?: Maybe<Order_By>;
  max?: Maybe<Short_Urls_Max_Order_By>;
  min?: Maybe<Short_Urls_Min_Order_By>;
};

/** append existing jsonb value of filtered columns with new jsonb value */
export type Short_Urls_Append_Input = {
  metadata?: Maybe<Scalars['jsonb']>;
};

/** input type for inserting array relation for remote table "short_urls" */
export type Short_Urls_Arr_Rel_Insert_Input = {
  data: Array<Short_Urls_Insert_Input>;
  /** upsert condition */
  on_conflict?: Maybe<Short_Urls_On_Conflict>;
};

/** Boolean expression to filter rows from the table "short_urls". All fields are combined with a logical 'AND'. */
export type Short_Urls_Bool_Exp = {
  _and?: Maybe<Array<Short_Urls_Bool_Exp>>;
  _not?: Maybe<Short_Urls_Bool_Exp>;
  _or?: Maybe<Array<Short_Urls_Bool_Exp>>;
  action?: Maybe<Short_Url_Action_Enums_Comparison_Exp>;
  created_at?: Maybe<Timestamptz_Comparison_Exp>;
  deleted_at?: Maybe<Timestamptz_Comparison_Exp>;
  events?: Maybe<Events_Bool_Exp>;
  events_aggregate?: Maybe<Events_Aggregate_Bool_Exp>;
  expires_on?: Maybe<Timestamptz_Comparison_Exp>;
  id?: Maybe<Uuid_Comparison_Exp>;
  long_url?: Maybe<String_Comparison_Exp>;
  metadata?: Maybe<Jsonb_Comparison_Exp>;
  owner_id?: Maybe<String_Comparison_Exp>;
  short_url?: Maybe<String_Comparison_Exp>;
  updated_at?: Maybe<Timestamptz_Comparison_Exp>;
  user?: Maybe<Users_Bool_Exp>;
};

/** unique or primary key constraints on table "short_urls" */
export enum Short_Urls_Constraint {
  /** unique or primary key constraint on columns "id" */
  ShortUrlsPkey = 'short_urls_pkey',
  /** unique or primary key constraint on columns "short_url" */
  ShortUrlsShortUrlKey = 'short_urls_short_url_key'
}

/** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
export type Short_Urls_Delete_At_Path_Input = {
  metadata?: Maybe<Array<Scalars['String']>>;
};

/** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
export type Short_Urls_Delete_Elem_Input = {
  metadata?: Maybe<Scalars['Int']>;
};

/** delete key/value pair or string element. key/value pairs are matched based on their key value */
export type Short_Urls_Delete_Key_Input = {
  metadata?: Maybe<Scalars['String']>;
};

/** input type for inserting data into table "short_urls" */
export type Short_Urls_Insert_Input = {
  action?: Maybe<Scalars['short_url_action_enums']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  deleted_at?: Maybe<Scalars['timestamptz']>;
  events?: Maybe<Events_Arr_Rel_Insert_Input>;
  expires_on?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['uuid']>;
  long_url?: Maybe<Scalars['String']>;
  metadata?: Maybe<Scalars['jsonb']>;
  owner_id?: Maybe<Scalars['String']>;
  short_url?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
  user?: Maybe<Users_Obj_Rel_Insert_Input>;
};

/** aggregate max on columns */
export type Short_Urls_Max_Fields = {
  __typename?: 'short_urls_max_fields';
  action?: Maybe<Scalars['short_url_action_enums']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  deleted_at?: Maybe<Scalars['timestamptz']>;
  expires_on?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['uuid']>;
  long_url?: Maybe<Scalars['String']>;
  owner_id?: Maybe<Scalars['String']>;
  short_url?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** order by max() on columns of table "short_urls" */
export type Short_Urls_Max_Order_By = {
  action?: Maybe<Order_By>;
  created_at?: Maybe<Order_By>;
  deleted_at?: Maybe<Order_By>;
  expires_on?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  long_url?: Maybe<Order_By>;
  owner_id?: Maybe<Order_By>;
  short_url?: Maybe<Order_By>;
  updated_at?: Maybe<Order_By>;
};

/** aggregate min on columns */
export type Short_Urls_Min_Fields = {
  __typename?: 'short_urls_min_fields';
  action?: Maybe<Scalars['short_url_action_enums']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  deleted_at?: Maybe<Scalars['timestamptz']>;
  expires_on?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['uuid']>;
  long_url?: Maybe<Scalars['String']>;
  owner_id?: Maybe<Scalars['String']>;
  short_url?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** order by min() on columns of table "short_urls" */
export type Short_Urls_Min_Order_By = {
  action?: Maybe<Order_By>;
  created_at?: Maybe<Order_By>;
  deleted_at?: Maybe<Order_By>;
  expires_on?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  long_url?: Maybe<Order_By>;
  owner_id?: Maybe<Order_By>;
  short_url?: Maybe<Order_By>;
  updated_at?: Maybe<Order_By>;
};

/** response of any mutation on the table "short_urls" */
export type Short_Urls_Mutation_Response = {
  __typename?: 'short_urls_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Short_Urls>;
};

/** input type for inserting object relation for remote table "short_urls" */
export type Short_Urls_Obj_Rel_Insert_Input = {
  data: Short_Urls_Insert_Input;
  /** upsert condition */
  on_conflict?: Maybe<Short_Urls_On_Conflict>;
};

/** on_conflict condition type for table "short_urls" */
export type Short_Urls_On_Conflict = {
  constraint: Short_Urls_Constraint;
  update_columns?: Array<Short_Urls_Update_Column>;
  where?: Maybe<Short_Urls_Bool_Exp>;
};

/** Ordering options when selecting data from "short_urls". */
export type Short_Urls_Order_By = {
  action?: Maybe<Order_By>;
  created_at?: Maybe<Order_By>;
  deleted_at?: Maybe<Order_By>;
  events_aggregate?: Maybe<Events_Aggregate_Order_By>;
  expires_on?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  long_url?: Maybe<Order_By>;
  metadata?: Maybe<Order_By>;
  owner_id?: Maybe<Order_By>;
  short_url?: Maybe<Order_By>;
  updated_at?: Maybe<Order_By>;
  user?: Maybe<Users_Order_By>;
};

/** primary key columns input for table: short_urls */
export type Short_Urls_Pk_Columns_Input = {
  id: Scalars['uuid'];
};

/** prepend existing jsonb value of filtered columns with new jsonb value */
export type Short_Urls_Prepend_Input = {
  metadata?: Maybe<Scalars['jsonb']>;
};

/** select columns of table "short_urls" */
export enum Short_Urls_Select_Column {
  /** column name */
  Action = 'action',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  DeletedAt = 'deleted_at',
  /** column name */
  ExpiresOn = 'expires_on',
  /** column name */
  Id = 'id',
  /** column name */
  LongUrl = 'long_url',
  /** column name */
  Metadata = 'metadata',
  /** column name */
  OwnerId = 'owner_id',
  /** column name */
  ShortUrl = 'short_url',
  /** column name */
  UpdatedAt = 'updated_at'
}

/** input type for updating data in table "short_urls" */
export type Short_Urls_Set_Input = {
  action?: Maybe<Scalars['short_url_action_enums']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  deleted_at?: Maybe<Scalars['timestamptz']>;
  expires_on?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['uuid']>;
  long_url?: Maybe<Scalars['String']>;
  metadata?: Maybe<Scalars['jsonb']>;
  owner_id?: Maybe<Scalars['String']>;
  short_url?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** Streaming cursor of the table "short_urls" */
export type Short_Urls_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Short_Urls_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: Maybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Short_Urls_Stream_Cursor_Value_Input = {
  action?: Maybe<Scalars['short_url_action_enums']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  deleted_at?: Maybe<Scalars['timestamptz']>;
  expires_on?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['uuid']>;
  long_url?: Maybe<Scalars['String']>;
  metadata?: Maybe<Scalars['jsonb']>;
  owner_id?: Maybe<Scalars['String']>;
  short_url?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** update columns of table "short_urls" */
export enum Short_Urls_Update_Column {
  /** column name */
  Action = 'action',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  DeletedAt = 'deleted_at',
  /** column name */
  ExpiresOn = 'expires_on',
  /** column name */
  Id = 'id',
  /** column name */
  LongUrl = 'long_url',
  /** column name */
  Metadata = 'metadata',
  /** column name */
  OwnerId = 'owner_id',
  /** column name */
  ShortUrl = 'short_url',
  /** column name */
  UpdatedAt = 'updated_at'
}

export type Short_Urls_Updates = {
  /** append existing jsonb value of filtered columns with new jsonb value */
  _append?: Maybe<Short_Urls_Append_Input>;
  /** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
  _delete_at_path?: Maybe<Short_Urls_Delete_At_Path_Input>;
  /** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
  _delete_elem?: Maybe<Short_Urls_Delete_Elem_Input>;
  /** delete key/value pair or string element. key/value pairs are matched based on their key value */
  _delete_key?: Maybe<Short_Urls_Delete_Key_Input>;
  /** prepend existing jsonb value of filtered columns with new jsonb value */
  _prepend?: Maybe<Short_Urls_Prepend_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: Maybe<Short_Urls_Set_Input>;
  /** filter the rows which have to be updated */
  where: Short_Urls_Bool_Exp;
};

/** columns and relationships of "shortlists" */
export type Shortlists = {
  __typename?: 'shortlists';
  /** An object relationship */
  candidate?: Maybe<Candidates>;
  candidate_id?: Maybe<Scalars['uuid']>;
  /** An object relationship */
  client?: Maybe<Clients>;
  client_id?: Maybe<Scalars['uuid']>;
  created_at: Scalars['timestamptz'];
  deleted_at?: Maybe<Scalars['timestamptz']>;
  /** An array relationship */
  events: Array<Events>;
  /** An aggregate relationship */
  events_aggregate: Events_Aggregate;
  id: Scalars['uuid'];
  /** An object relationship */
  job?: Maybe<Jobs>;
  job_id?: Maybe<Scalars['uuid']>;
  owner_id?: Maybe<Scalars['String']>;
  updated_at: Scalars['timestamptz'];
  /** An object relationship */
  user?: Maybe<Users>;
};


/** columns and relationships of "shortlists" */
export type ShortlistsEventsArgs = {
  distinct_on?: Maybe<Array<Events_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Events_Order_By>>;
  where?: Maybe<Events_Bool_Exp>;
};


/** columns and relationships of "shortlists" */
export type ShortlistsEvents_AggregateArgs = {
  distinct_on?: Maybe<Array<Events_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Events_Order_By>>;
  where?: Maybe<Events_Bool_Exp>;
};

/** aggregated selection of "shortlists" */
export type Shortlists_Aggregate = {
  __typename?: 'shortlists_aggregate';
  aggregate?: Maybe<Shortlists_Aggregate_Fields>;
  nodes: Array<Shortlists>;
};

export type Shortlists_Aggregate_Bool_Exp = {
  count?: Maybe<Shortlists_Aggregate_Bool_Exp_Count>;
};

export type Shortlists_Aggregate_Bool_Exp_Count = {
  arguments?: Maybe<Array<Shortlists_Select_Column>>;
  distinct?: Maybe<Scalars['Boolean']>;
  filter?: Maybe<Shortlists_Bool_Exp>;
  predicate: Int_Comparison_Exp;
};

/** aggregate fields of "shortlists" */
export type Shortlists_Aggregate_Fields = {
  __typename?: 'shortlists_aggregate_fields';
  count: Scalars['Int'];
  max?: Maybe<Shortlists_Max_Fields>;
  min?: Maybe<Shortlists_Min_Fields>;
};


/** aggregate fields of "shortlists" */
export type Shortlists_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<Shortlists_Select_Column>>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "shortlists" */
export type Shortlists_Aggregate_Order_By = {
  count?: Maybe<Order_By>;
  max?: Maybe<Shortlists_Max_Order_By>;
  min?: Maybe<Shortlists_Min_Order_By>;
};

/** input type for inserting array relation for remote table "shortlists" */
export type Shortlists_Arr_Rel_Insert_Input = {
  data: Array<Shortlists_Insert_Input>;
  /** upsert condition */
  on_conflict?: Maybe<Shortlists_On_Conflict>;
};

/** Boolean expression to filter rows from the table "shortlists". All fields are combined with a logical 'AND'. */
export type Shortlists_Bool_Exp = {
  _and?: Maybe<Array<Shortlists_Bool_Exp>>;
  _not?: Maybe<Shortlists_Bool_Exp>;
  _or?: Maybe<Array<Shortlists_Bool_Exp>>;
  candidate?: Maybe<Candidates_Bool_Exp>;
  candidate_id?: Maybe<Uuid_Comparison_Exp>;
  client?: Maybe<Clients_Bool_Exp>;
  client_id?: Maybe<Uuid_Comparison_Exp>;
  created_at?: Maybe<Timestamptz_Comparison_Exp>;
  deleted_at?: Maybe<Timestamptz_Comparison_Exp>;
  events?: Maybe<Events_Bool_Exp>;
  events_aggregate?: Maybe<Events_Aggregate_Bool_Exp>;
  id?: Maybe<Uuid_Comparison_Exp>;
  job?: Maybe<Jobs_Bool_Exp>;
  job_id?: Maybe<Uuid_Comparison_Exp>;
  owner_id?: Maybe<String_Comparison_Exp>;
  updated_at?: Maybe<Timestamptz_Comparison_Exp>;
  user?: Maybe<Users_Bool_Exp>;
};

/** unique or primary key constraints on table "shortlists" */
export enum Shortlists_Constraint {
  /** unique or primary key constraint on columns "candidate_id", "job_id" */
  ShortlistsJobIdCandidateIdKey = 'shortlists_job_id_candidate_id_key',
  /** unique or primary key constraint on columns "id" */
  ShortlistsPkey = 'shortlists_pkey'
}

/** input type for inserting data into table "shortlists" */
export type Shortlists_Insert_Input = {
  candidate?: Maybe<Candidates_Obj_Rel_Insert_Input>;
  candidate_id?: Maybe<Scalars['uuid']>;
  client?: Maybe<Clients_Obj_Rel_Insert_Input>;
  client_id?: Maybe<Scalars['uuid']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  deleted_at?: Maybe<Scalars['timestamptz']>;
  events?: Maybe<Events_Arr_Rel_Insert_Input>;
  id?: Maybe<Scalars['uuid']>;
  job?: Maybe<Jobs_Obj_Rel_Insert_Input>;
  job_id?: Maybe<Scalars['uuid']>;
  owner_id?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
  user?: Maybe<Users_Obj_Rel_Insert_Input>;
};

/** aggregate max on columns */
export type Shortlists_Max_Fields = {
  __typename?: 'shortlists_max_fields';
  candidate_id?: Maybe<Scalars['uuid']>;
  client_id?: Maybe<Scalars['uuid']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  deleted_at?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['uuid']>;
  job_id?: Maybe<Scalars['uuid']>;
  owner_id?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** order by max() on columns of table "shortlists" */
export type Shortlists_Max_Order_By = {
  candidate_id?: Maybe<Order_By>;
  client_id?: Maybe<Order_By>;
  created_at?: Maybe<Order_By>;
  deleted_at?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  job_id?: Maybe<Order_By>;
  owner_id?: Maybe<Order_By>;
  updated_at?: Maybe<Order_By>;
};

/** aggregate min on columns */
export type Shortlists_Min_Fields = {
  __typename?: 'shortlists_min_fields';
  candidate_id?: Maybe<Scalars['uuid']>;
  client_id?: Maybe<Scalars['uuid']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  deleted_at?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['uuid']>;
  job_id?: Maybe<Scalars['uuid']>;
  owner_id?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** order by min() on columns of table "shortlists" */
export type Shortlists_Min_Order_By = {
  candidate_id?: Maybe<Order_By>;
  client_id?: Maybe<Order_By>;
  created_at?: Maybe<Order_By>;
  deleted_at?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  job_id?: Maybe<Order_By>;
  owner_id?: Maybe<Order_By>;
  updated_at?: Maybe<Order_By>;
};

/** response of any mutation on the table "shortlists" */
export type Shortlists_Mutation_Response = {
  __typename?: 'shortlists_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Shortlists>;
};

/** input type for inserting object relation for remote table "shortlists" */
export type Shortlists_Obj_Rel_Insert_Input = {
  data: Shortlists_Insert_Input;
  /** upsert condition */
  on_conflict?: Maybe<Shortlists_On_Conflict>;
};

/** on_conflict condition type for table "shortlists" */
export type Shortlists_On_Conflict = {
  constraint: Shortlists_Constraint;
  update_columns?: Array<Shortlists_Update_Column>;
  where?: Maybe<Shortlists_Bool_Exp>;
};

/** Ordering options when selecting data from "shortlists". */
export type Shortlists_Order_By = {
  candidate?: Maybe<Candidates_Order_By>;
  candidate_id?: Maybe<Order_By>;
  client?: Maybe<Clients_Order_By>;
  client_id?: Maybe<Order_By>;
  created_at?: Maybe<Order_By>;
  deleted_at?: Maybe<Order_By>;
  events_aggregate?: Maybe<Events_Aggregate_Order_By>;
  id?: Maybe<Order_By>;
  job?: Maybe<Jobs_Order_By>;
  job_id?: Maybe<Order_By>;
  owner_id?: Maybe<Order_By>;
  updated_at?: Maybe<Order_By>;
  user?: Maybe<Users_Order_By>;
};

/** primary key columns input for table: shortlists */
export type Shortlists_Pk_Columns_Input = {
  id: Scalars['uuid'];
};

/** select columns of table "shortlists" */
export enum Shortlists_Select_Column {
  /** column name */
  CandidateId = 'candidate_id',
  /** column name */
  ClientId = 'client_id',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  DeletedAt = 'deleted_at',
  /** column name */
  Id = 'id',
  /** column name */
  JobId = 'job_id',
  /** column name */
  OwnerId = 'owner_id',
  /** column name */
  UpdatedAt = 'updated_at'
}

/** input type for updating data in table "shortlists" */
export type Shortlists_Set_Input = {
  candidate_id?: Maybe<Scalars['uuid']>;
  client_id?: Maybe<Scalars['uuid']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  deleted_at?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['uuid']>;
  job_id?: Maybe<Scalars['uuid']>;
  owner_id?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** Streaming cursor of the table "shortlists" */
export type Shortlists_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Shortlists_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: Maybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Shortlists_Stream_Cursor_Value_Input = {
  candidate_id?: Maybe<Scalars['uuid']>;
  client_id?: Maybe<Scalars['uuid']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  deleted_at?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['uuid']>;
  job_id?: Maybe<Scalars['uuid']>;
  owner_id?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** update columns of table "shortlists" */
export enum Shortlists_Update_Column {
  /** column name */
  CandidateId = 'candidate_id',
  /** column name */
  ClientId = 'client_id',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  DeletedAt = 'deleted_at',
  /** column name */
  Id = 'id',
  /** column name */
  JobId = 'job_id',
  /** column name */
  OwnerId = 'owner_id',
  /** column name */
  UpdatedAt = 'updated_at'
}

export type Shortlists_Updates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: Maybe<Shortlists_Set_Input>;
  /** filter the rows which have to be updated */
  where: Shortlists_Bool_Exp;
};


/** Boolean expression to compare columns of type "skill_score_enums". All fields are combined with logical 'AND'. */
export type Skill_Score_Enums_Comparison_Exp = {
  _eq?: Maybe<Scalars['skill_score_enums']>;
  _gt?: Maybe<Scalars['skill_score_enums']>;
  _gte?: Maybe<Scalars['skill_score_enums']>;
  _in?: Maybe<Array<Scalars['skill_score_enums']>>;
  _is_null?: Maybe<Scalars['Boolean']>;
  _lt?: Maybe<Scalars['skill_score_enums']>;
  _lte?: Maybe<Scalars['skill_score_enums']>;
  _neq?: Maybe<Scalars['skill_score_enums']>;
  _nin?: Maybe<Array<Scalars['skill_score_enums']>>;
};

/** columns and relationships of "skills" */
export type Skills = {
  __typename?: 'skills';
  /** An array relationship */
  candidate_skills: Array<Candidate_Skills>;
  /** An aggregate relationship */
  candidate_skills_aggregate: Candidate_Skills_Aggregate;
  created_at: Scalars['timestamptz'];
  deleted_at?: Maybe<Scalars['timestamptz']>;
  description?: Maybe<Scalars['String']>;
  id: Scalars['uuid'];
  title: Scalars['String'];
  updated_at: Scalars['timestamptz'];
};


/** columns and relationships of "skills" */
export type SkillsCandidate_SkillsArgs = {
  distinct_on?: Maybe<Array<Candidate_Skills_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Candidate_Skills_Order_By>>;
  where?: Maybe<Candidate_Skills_Bool_Exp>;
};


/** columns and relationships of "skills" */
export type SkillsCandidate_Skills_AggregateArgs = {
  distinct_on?: Maybe<Array<Candidate_Skills_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Candidate_Skills_Order_By>>;
  where?: Maybe<Candidate_Skills_Bool_Exp>;
};

/** aggregated selection of "skills" */
export type Skills_Aggregate = {
  __typename?: 'skills_aggregate';
  aggregate?: Maybe<Skills_Aggregate_Fields>;
  nodes: Array<Skills>;
};

/** aggregate fields of "skills" */
export type Skills_Aggregate_Fields = {
  __typename?: 'skills_aggregate_fields';
  count: Scalars['Int'];
  max?: Maybe<Skills_Max_Fields>;
  min?: Maybe<Skills_Min_Fields>;
};


/** aggregate fields of "skills" */
export type Skills_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<Skills_Select_Column>>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** Boolean expression to filter rows from the table "skills". All fields are combined with a logical 'AND'. */
export type Skills_Bool_Exp = {
  _and?: Maybe<Array<Skills_Bool_Exp>>;
  _not?: Maybe<Skills_Bool_Exp>;
  _or?: Maybe<Array<Skills_Bool_Exp>>;
  candidate_skills?: Maybe<Candidate_Skills_Bool_Exp>;
  candidate_skills_aggregate?: Maybe<Candidate_Skills_Aggregate_Bool_Exp>;
  created_at?: Maybe<Timestamptz_Comparison_Exp>;
  deleted_at?: Maybe<Timestamptz_Comparison_Exp>;
  description?: Maybe<String_Comparison_Exp>;
  id?: Maybe<Uuid_Comparison_Exp>;
  title?: Maybe<String_Comparison_Exp>;
  updated_at?: Maybe<Timestamptz_Comparison_Exp>;
};

/** unique or primary key constraints on table "skills" */
export enum Skills_Constraint {
  /** unique or primary key constraint on columns "id" */
  SkillsPkey = 'skills_pkey'
}

/** input type for inserting data into table "skills" */
export type Skills_Insert_Input = {
  candidate_skills?: Maybe<Candidate_Skills_Arr_Rel_Insert_Input>;
  created_at?: Maybe<Scalars['timestamptz']>;
  deleted_at?: Maybe<Scalars['timestamptz']>;
  description?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['uuid']>;
  title?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** aggregate max on columns */
export type Skills_Max_Fields = {
  __typename?: 'skills_max_fields';
  created_at?: Maybe<Scalars['timestamptz']>;
  deleted_at?: Maybe<Scalars['timestamptz']>;
  description?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['uuid']>;
  title?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** aggregate min on columns */
export type Skills_Min_Fields = {
  __typename?: 'skills_min_fields';
  created_at?: Maybe<Scalars['timestamptz']>;
  deleted_at?: Maybe<Scalars['timestamptz']>;
  description?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['uuid']>;
  title?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** response of any mutation on the table "skills" */
export type Skills_Mutation_Response = {
  __typename?: 'skills_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Skills>;
};

/** input type for inserting object relation for remote table "skills" */
export type Skills_Obj_Rel_Insert_Input = {
  data: Skills_Insert_Input;
  /** upsert condition */
  on_conflict?: Maybe<Skills_On_Conflict>;
};

/** on_conflict condition type for table "skills" */
export type Skills_On_Conflict = {
  constraint: Skills_Constraint;
  update_columns?: Array<Skills_Update_Column>;
  where?: Maybe<Skills_Bool_Exp>;
};

/** Ordering options when selecting data from "skills". */
export type Skills_Order_By = {
  candidate_skills_aggregate?: Maybe<Candidate_Skills_Aggregate_Order_By>;
  created_at?: Maybe<Order_By>;
  deleted_at?: Maybe<Order_By>;
  description?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  title?: Maybe<Order_By>;
  updated_at?: Maybe<Order_By>;
};

/** primary key columns input for table: skills */
export type Skills_Pk_Columns_Input = {
  id: Scalars['uuid'];
};

/** select columns of table "skills" */
export enum Skills_Select_Column {
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  DeletedAt = 'deleted_at',
  /** column name */
  Description = 'description',
  /** column name */
  Id = 'id',
  /** column name */
  Title = 'title',
  /** column name */
  UpdatedAt = 'updated_at'
}

/** input type for updating data in table "skills" */
export type Skills_Set_Input = {
  created_at?: Maybe<Scalars['timestamptz']>;
  deleted_at?: Maybe<Scalars['timestamptz']>;
  description?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['uuid']>;
  title?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** Streaming cursor of the table "skills" */
export type Skills_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Skills_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: Maybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Skills_Stream_Cursor_Value_Input = {
  created_at?: Maybe<Scalars['timestamptz']>;
  deleted_at?: Maybe<Scalars['timestamptz']>;
  description?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['uuid']>;
  title?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** update columns of table "skills" */
export enum Skills_Update_Column {
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  DeletedAt = 'deleted_at',
  /** column name */
  Description = 'description',
  /** column name */
  Id = 'id',
  /** column name */
  Title = 'title',
  /** column name */
  UpdatedAt = 'updated_at'
}

export type Skills_Updates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: Maybe<Skills_Set_Input>;
  /** filter the rows which have to be updated */
  where: Skills_Bool_Exp;
};

/** columns and relationships of "spatial_ref_sys" */
export type Spatial_Ref_Sys = {
  __typename?: 'spatial_ref_sys';
  auth_name?: Maybe<Scalars['String']>;
  auth_srid?: Maybe<Scalars['Int']>;
  proj4text?: Maybe<Scalars['String']>;
  srid: Scalars['Int'];
  srtext?: Maybe<Scalars['String']>;
};

/** aggregated selection of "spatial_ref_sys" */
export type Spatial_Ref_Sys_Aggregate = {
  __typename?: 'spatial_ref_sys_aggregate';
  aggregate?: Maybe<Spatial_Ref_Sys_Aggregate_Fields>;
  nodes: Array<Spatial_Ref_Sys>;
};

/** aggregate fields of "spatial_ref_sys" */
export type Spatial_Ref_Sys_Aggregate_Fields = {
  __typename?: 'spatial_ref_sys_aggregate_fields';
  avg?: Maybe<Spatial_Ref_Sys_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<Spatial_Ref_Sys_Max_Fields>;
  min?: Maybe<Spatial_Ref_Sys_Min_Fields>;
  stddev?: Maybe<Spatial_Ref_Sys_Stddev_Fields>;
  stddev_pop?: Maybe<Spatial_Ref_Sys_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Spatial_Ref_Sys_Stddev_Samp_Fields>;
  sum?: Maybe<Spatial_Ref_Sys_Sum_Fields>;
  var_pop?: Maybe<Spatial_Ref_Sys_Var_Pop_Fields>;
  var_samp?: Maybe<Spatial_Ref_Sys_Var_Samp_Fields>;
  variance?: Maybe<Spatial_Ref_Sys_Variance_Fields>;
};


/** aggregate fields of "spatial_ref_sys" */
export type Spatial_Ref_Sys_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<Spatial_Ref_Sys_Select_Column>>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** aggregate avg on columns */
export type Spatial_Ref_Sys_Avg_Fields = {
  __typename?: 'spatial_ref_sys_avg_fields';
  auth_srid?: Maybe<Scalars['Float']>;
  srid?: Maybe<Scalars['Float']>;
};

/** Boolean expression to filter rows from the table "spatial_ref_sys". All fields are combined with a logical 'AND'. */
export type Spatial_Ref_Sys_Bool_Exp = {
  _and?: Maybe<Array<Spatial_Ref_Sys_Bool_Exp>>;
  _not?: Maybe<Spatial_Ref_Sys_Bool_Exp>;
  _or?: Maybe<Array<Spatial_Ref_Sys_Bool_Exp>>;
  auth_name?: Maybe<String_Comparison_Exp>;
  auth_srid?: Maybe<Int_Comparison_Exp>;
  proj4text?: Maybe<String_Comparison_Exp>;
  srid?: Maybe<Int_Comparison_Exp>;
  srtext?: Maybe<String_Comparison_Exp>;
};

/** unique or primary key constraints on table "spatial_ref_sys" */
export enum Spatial_Ref_Sys_Constraint {
  /** unique or primary key constraint on columns "srid" */
  SpatialRefSysPkey = 'spatial_ref_sys_pkey'
}

/** input type for incrementing numeric columns in table "spatial_ref_sys" */
export type Spatial_Ref_Sys_Inc_Input = {
  auth_srid?: Maybe<Scalars['Int']>;
  srid?: Maybe<Scalars['Int']>;
};

/** input type for inserting data into table "spatial_ref_sys" */
export type Spatial_Ref_Sys_Insert_Input = {
  auth_name?: Maybe<Scalars['String']>;
  auth_srid?: Maybe<Scalars['Int']>;
  proj4text?: Maybe<Scalars['String']>;
  srid?: Maybe<Scalars['Int']>;
  srtext?: Maybe<Scalars['String']>;
};

/** aggregate max on columns */
export type Spatial_Ref_Sys_Max_Fields = {
  __typename?: 'spatial_ref_sys_max_fields';
  auth_name?: Maybe<Scalars['String']>;
  auth_srid?: Maybe<Scalars['Int']>;
  proj4text?: Maybe<Scalars['String']>;
  srid?: Maybe<Scalars['Int']>;
  srtext?: Maybe<Scalars['String']>;
};

/** aggregate min on columns */
export type Spatial_Ref_Sys_Min_Fields = {
  __typename?: 'spatial_ref_sys_min_fields';
  auth_name?: Maybe<Scalars['String']>;
  auth_srid?: Maybe<Scalars['Int']>;
  proj4text?: Maybe<Scalars['String']>;
  srid?: Maybe<Scalars['Int']>;
  srtext?: Maybe<Scalars['String']>;
};

/** response of any mutation on the table "spatial_ref_sys" */
export type Spatial_Ref_Sys_Mutation_Response = {
  __typename?: 'spatial_ref_sys_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Spatial_Ref_Sys>;
};

/** on_conflict condition type for table "spatial_ref_sys" */
export type Spatial_Ref_Sys_On_Conflict = {
  constraint: Spatial_Ref_Sys_Constraint;
  update_columns?: Array<Spatial_Ref_Sys_Update_Column>;
  where?: Maybe<Spatial_Ref_Sys_Bool_Exp>;
};

/** Ordering options when selecting data from "spatial_ref_sys". */
export type Spatial_Ref_Sys_Order_By = {
  auth_name?: Maybe<Order_By>;
  auth_srid?: Maybe<Order_By>;
  proj4text?: Maybe<Order_By>;
  srid?: Maybe<Order_By>;
  srtext?: Maybe<Order_By>;
};

/** primary key columns input for table: spatial_ref_sys */
export type Spatial_Ref_Sys_Pk_Columns_Input = {
  srid: Scalars['Int'];
};

/** select columns of table "spatial_ref_sys" */
export enum Spatial_Ref_Sys_Select_Column {
  /** column name */
  AuthName = 'auth_name',
  /** column name */
  AuthSrid = 'auth_srid',
  /** column name */
  Proj4text = 'proj4text',
  /** column name */
  Srid = 'srid',
  /** column name */
  Srtext = 'srtext'
}

/** input type for updating data in table "spatial_ref_sys" */
export type Spatial_Ref_Sys_Set_Input = {
  auth_name?: Maybe<Scalars['String']>;
  auth_srid?: Maybe<Scalars['Int']>;
  proj4text?: Maybe<Scalars['String']>;
  srid?: Maybe<Scalars['Int']>;
  srtext?: Maybe<Scalars['String']>;
};

/** aggregate stddev on columns */
export type Spatial_Ref_Sys_Stddev_Fields = {
  __typename?: 'spatial_ref_sys_stddev_fields';
  auth_srid?: Maybe<Scalars['Float']>;
  srid?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_pop on columns */
export type Spatial_Ref_Sys_Stddev_Pop_Fields = {
  __typename?: 'spatial_ref_sys_stddev_pop_fields';
  auth_srid?: Maybe<Scalars['Float']>;
  srid?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_samp on columns */
export type Spatial_Ref_Sys_Stddev_Samp_Fields = {
  __typename?: 'spatial_ref_sys_stddev_samp_fields';
  auth_srid?: Maybe<Scalars['Float']>;
  srid?: Maybe<Scalars['Float']>;
};

/** Streaming cursor of the table "spatial_ref_sys" */
export type Spatial_Ref_Sys_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Spatial_Ref_Sys_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: Maybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Spatial_Ref_Sys_Stream_Cursor_Value_Input = {
  auth_name?: Maybe<Scalars['String']>;
  auth_srid?: Maybe<Scalars['Int']>;
  proj4text?: Maybe<Scalars['String']>;
  srid?: Maybe<Scalars['Int']>;
  srtext?: Maybe<Scalars['String']>;
};

/** aggregate sum on columns */
export type Spatial_Ref_Sys_Sum_Fields = {
  __typename?: 'spatial_ref_sys_sum_fields';
  auth_srid?: Maybe<Scalars['Int']>;
  srid?: Maybe<Scalars['Int']>;
};

/** update columns of table "spatial_ref_sys" */
export enum Spatial_Ref_Sys_Update_Column {
  /** column name */
  AuthName = 'auth_name',
  /** column name */
  AuthSrid = 'auth_srid',
  /** column name */
  Proj4text = 'proj4text',
  /** column name */
  Srid = 'srid',
  /** column name */
  Srtext = 'srtext'
}

export type Spatial_Ref_Sys_Updates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: Maybe<Spatial_Ref_Sys_Inc_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: Maybe<Spatial_Ref_Sys_Set_Input>;
  /** filter the rows which have to be updated */
  where: Spatial_Ref_Sys_Bool_Exp;
};

/** aggregate var_pop on columns */
export type Spatial_Ref_Sys_Var_Pop_Fields = {
  __typename?: 'spatial_ref_sys_var_pop_fields';
  auth_srid?: Maybe<Scalars['Float']>;
  srid?: Maybe<Scalars['Float']>;
};

/** aggregate var_samp on columns */
export type Spatial_Ref_Sys_Var_Samp_Fields = {
  __typename?: 'spatial_ref_sys_var_samp_fields';
  auth_srid?: Maybe<Scalars['Float']>;
  srid?: Maybe<Scalars['Float']>;
};

/** aggregate variance on columns */
export type Spatial_Ref_Sys_Variance_Fields = {
  __typename?: 'spatial_ref_sys_variance_fields';
  auth_srid?: Maybe<Scalars['Float']>;
  srid?: Maybe<Scalars['Float']>;
};

export type St_D_Within_Geography_Input = {
  distance: Scalars['Float'];
  from: Scalars['geography'];
  use_spheroid?: Maybe<Scalars['Boolean']>;
};

export type St_D_Within_Input = {
  distance: Scalars['Float'];
  from: Scalars['geometry'];
};

export type Subscription_Root = {
  __typename?: 'subscription_root';
  /** fetch data from the table: "brimstone_event_feedback" */
  brimstone_event_feedback: Array<Brimstone_Event_Feedback>;
  /** fetch aggregated fields from the table: "brimstone_event_feedback" */
  brimstone_event_feedback_aggregate: Brimstone_Event_Feedback_Aggregate;
  /** fetch data from the table: "brimstone_event_feedback" using primary key columns */
  brimstone_event_feedback_by_pk?: Maybe<Brimstone_Event_Feedback>;
  /** fetch data from the table in a streaming manner: "brimstone_event_feedback" */
  brimstone_event_feedback_stream: Array<Brimstone_Event_Feedback>;
  /** An array relationship */
  brimstone_event_speakers: Array<Brimstone_Event_Speakers>;
  /** An aggregate relationship */
  brimstone_event_speakers_aggregate: Brimstone_Event_Speakers_Aggregate;
  /** fetch data from the table: "brimstone_event_speakers" using primary key columns */
  brimstone_event_speakers_by_pk?: Maybe<Brimstone_Event_Speakers>;
  /** fetch data from the table in a streaming manner: "brimstone_event_speakers" */
  brimstone_event_speakers_stream: Array<Brimstone_Event_Speakers>;
  /** fetch data from the table: "brimstone_events" */
  brimstone_events: Array<Brimstone_Events>;
  /** fetch aggregated fields from the table: "brimstone_events" */
  brimstone_events_aggregate: Brimstone_Events_Aggregate;
  /** fetch data from the table: "brimstone_events" using primary key columns */
  brimstone_events_by_pk?: Maybe<Brimstone_Events>;
  /** fetch data from the table in a streaming manner: "brimstone_events" */
  brimstone_events_stream: Array<Brimstone_Events>;
  /** fetch data from the table: "brimstone_speakers" */
  brimstone_speakers: Array<Brimstone_Speakers>;
  /** fetch aggregated fields from the table: "brimstone_speakers" */
  brimstone_speakers_aggregate: Brimstone_Speakers_Aggregate;
  /** fetch data from the table: "brimstone_speakers" using primary key columns */
  brimstone_speakers_by_pk?: Maybe<Brimstone_Speakers>;
  /** fetch data from the table in a streaming manner: "brimstone_speakers" */
  brimstone_speakers_stream: Array<Brimstone_Speakers>;
  /** An array relationship */
  candidate_courses: Array<Candidate_Courses>;
  /** An aggregate relationship */
  candidate_courses_aggregate: Candidate_Courses_Aggregate;
  /** fetch data from the table: "candidate_courses" using primary key columns */
  candidate_courses_by_pk?: Maybe<Candidate_Courses>;
  /** fetch data from the table in a streaming manner: "candidate_courses" */
  candidate_courses_stream: Array<Candidate_Courses>;
  /** An array relationship */
  candidate_degrees: Array<Candidate_Degrees>;
  /** An aggregate relationship */
  candidate_degrees_aggregate: Candidate_Degrees_Aggregate;
  /** fetch data from the table: "candidate_degrees" using primary key columns */
  candidate_degrees_by_pk?: Maybe<Candidate_Degrees>;
  /** fetch data from the table in a streaming manner: "candidate_degrees" */
  candidate_degrees_stream: Array<Candidate_Degrees>;
  /** An array relationship */
  candidate_follows: Array<Candidate_Follows>;
  /** An aggregate relationship */
  candidate_follows_aggregate: Candidate_Follows_Aggregate;
  /** fetch data from the table: "candidate_follows" using primary key columns */
  candidate_follows_by_pk?: Maybe<Candidate_Follows>;
  /** fetch data from the table in a streaming manner: "candidate_follows" */
  candidate_follows_stream: Array<Candidate_Follows>;
  /** An array relationship */
  candidate_industries: Array<Candidate_Industries>;
  /** An aggregate relationship */
  candidate_industries_aggregate: Candidate_Industries_Aggregate;
  /** fetch data from the table: "candidate_industries" using primary key columns */
  candidate_industries_by_pk?: Maybe<Candidate_Industries>;
  /** fetch data from the table in a streaming manner: "candidate_industries" */
  candidate_industries_stream: Array<Candidate_Industries>;
  /** An array relationship */
  candidate_locations: Array<Candidate_Locations>;
  /** An aggregate relationship */
  candidate_locations_aggregate: Candidate_Locations_Aggregate;
  /** fetch data from the table: "candidate_locations" using primary key columns */
  candidate_locations_by_pk?: Maybe<Candidate_Locations>;
  /** fetch data from the table in a streaming manner: "candidate_locations" */
  candidate_locations_stream: Array<Candidate_Locations>;
  /** An array relationship */
  candidate_majors: Array<Candidate_Majors>;
  /** An aggregate relationship */
  candidate_majors_aggregate: Candidate_Majors_Aggregate;
  /** fetch data from the table: "candidate_majors" using primary key columns */
  candidate_majors_by_pk?: Maybe<Candidate_Majors>;
  /** fetch data from the table in a streaming manner: "candidate_majors" */
  candidate_majors_stream: Array<Candidate_Majors>;
  /** An array relationship */
  candidate_placements: Array<Candidate_Placements>;
  /** An aggregate relationship */
  candidate_placements_aggregate: Candidate_Placements_Aggregate;
  /** fetch data from the table: "candidate_placements" using primary key columns */
  candidate_placements_by_pk?: Maybe<Candidate_Placements>;
  /** fetch data from the table in a streaming manner: "candidate_placements" */
  candidate_placements_stream: Array<Candidate_Placements>;
  /** An array relationship */
  candidate_skills: Array<Candidate_Skills>;
  /** An aggregate relationship */
  candidate_skills_aggregate: Candidate_Skills_Aggregate;
  /** fetch data from the table: "candidate_skills" using primary key columns */
  candidate_skills_by_pk?: Maybe<Candidate_Skills>;
  /** fetch data from the table in a streaming manner: "candidate_skills" */
  candidate_skills_stream: Array<Candidate_Skills>;
  /** An array relationship */
  candidates: Array<Candidates>;
  /** An aggregate relationship */
  candidates_aggregate: Candidates_Aggregate;
  /** fetch data from the table: "candidates" using primary key columns */
  candidates_by_pk?: Maybe<Candidates>;
  /** fetch data from the table in a streaming manner: "candidates" */
  candidates_stream: Array<Candidates>;
  /** An array relationship */
  client_industries: Array<Client_Industries>;
  /** An aggregate relationship */
  client_industries_aggregate: Client_Industries_Aggregate;
  /** fetch data from the table: "client_industries" using primary key columns */
  client_industries_by_pk?: Maybe<Client_Industries>;
  /** fetch data from the table in a streaming manner: "client_industries" */
  client_industries_stream: Array<Client_Industries>;
  /** An array relationship */
  client_jobs: Array<Client_Jobs>;
  /** An aggregate relationship */
  client_jobs_aggregate: Client_Jobs_Aggregate;
  /** fetch data from the table: "client_jobs" using primary key columns */
  client_jobs_by_pk?: Maybe<Client_Jobs>;
  /** fetch data from the table in a streaming manner: "client_jobs" */
  client_jobs_stream: Array<Client_Jobs>;
  /** An array relationship */
  client_locations: Array<Client_Locations>;
  /** An aggregate relationship */
  client_locations_aggregate: Client_Locations_Aggregate;
  /** fetch data from the table: "client_locations" using primary key columns */
  client_locations_by_pk?: Maybe<Client_Locations>;
  /** fetch data from the table in a streaming manner: "client_locations" */
  client_locations_stream: Array<Client_Locations>;
  /** An array relationship */
  client_users: Array<Client_Users>;
  /** An aggregate relationship */
  client_users_aggregate: Client_Users_Aggregate;
  /** fetch data from the table: "client_users" using primary key columns */
  client_users_by_pk?: Maybe<Client_Users>;
  /** fetch data from the table in a streaming manner: "client_users" */
  client_users_stream: Array<Client_Users>;
  /** An array relationship */
  clients: Array<Clients>;
  /** An aggregate relationship */
  clients_aggregate: Clients_Aggregate;
  /** fetch data from the table: "clients" using primary key columns */
  clients_by_pk?: Maybe<Clients>;
  /** fetch data from the table in a streaming manner: "clients" */
  clients_stream: Array<Clients>;
  /** An array relationship */
  contacts: Array<Contacts>;
  /** An aggregate relationship */
  contacts_aggregate: Contacts_Aggregate;
  /** fetch data from the table: "contacts" using primary key columns */
  contacts_by_pk?: Maybe<Contacts>;
  /** fetch data from the table in a streaming manner: "contacts" */
  contacts_stream: Array<Contacts>;
  /** fetch data from the table: "courses" */
  courses: Array<Courses>;
  /** fetch aggregated fields from the table: "courses" */
  courses_aggregate: Courses_Aggregate;
  /** fetch data from the table: "courses" using primary key columns */
  courses_by_pk?: Maybe<Courses>;
  /** fetch data from the table in a streaming manner: "courses" */
  courses_stream: Array<Courses>;
  /** fetch data from the table: "degrees" */
  degrees: Array<Degrees>;
  /** fetch aggregated fields from the table: "degrees" */
  degrees_aggregate: Degrees_Aggregate;
  /** fetch data from the table: "degrees" using primary key columns */
  degrees_by_pk?: Maybe<Degrees>;
  /** fetch data from the table in a streaming manner: "degrees" */
  degrees_stream: Array<Degrees>;
  /** An array relationship */
  enterprise_clients: Array<Enterprise_Clients>;
  /** An aggregate relationship */
  enterprise_clients_aggregate: Enterprise_Clients_Aggregate;
  /** fetch data from the table: "enterprise_clients" using primary key columns */
  enterprise_clients_by_pk?: Maybe<Enterprise_Clients>;
  /** fetch data from the table in a streaming manner: "enterprise_clients" */
  enterprise_clients_stream: Array<Enterprise_Clients>;
  /** An array relationship */
  enterprise_locations: Array<Enterprise_Locations>;
  /** An aggregate relationship */
  enterprise_locations_aggregate: Enterprise_Locations_Aggregate;
  /** fetch data from the table: "enterprise_locations" using primary key columns */
  enterprise_locations_by_pk?: Maybe<Enterprise_Locations>;
  /** fetch data from the table in a streaming manner: "enterprise_locations" */
  enterprise_locations_stream: Array<Enterprise_Locations>;
  /** An array relationship */
  enterprises: Array<Enterprises>;
  /** An aggregate relationship */
  enterprises_aggregate: Enterprises_Aggregate;
  /** fetch data from the table: "enterprises" using primary key columns */
  enterprises_by_pk?: Maybe<Enterprises>;
  /** fetch data from the table in a streaming manner: "enterprises" */
  enterprises_stream: Array<Enterprises>;
  /** An array relationship */
  events: Array<Events>;
  /** An aggregate relationship */
  events_aggregate: Events_Aggregate;
  /** fetch data from the table: "events" using primary key columns */
  events_by_pk?: Maybe<Events>;
  /** fetch data from the table in a streaming manner: "events" */
  events_stream: Array<Events>;
  /** An array relationship */
  exclusive_candidates: Array<Exclusive_Candidates>;
  /** An aggregate relationship */
  exclusive_candidates_aggregate: Exclusive_Candidates_Aggregate;
  /** fetch data from the table: "exclusive_candidates" using primary key columns */
  exclusive_candidates_by_pk?: Maybe<Exclusive_Candidates>;
  /** fetch data from the table in a streaming manner: "exclusive_candidates" */
  exclusive_candidates_stream: Array<Exclusive_Candidates>;
  /** fb_server_event */
  fb_server_event: Scalars['Boolean'];
  /** An array relationship */
  feature_popups: Array<Feature_Popups>;
  /** An aggregate relationship */
  feature_popups_aggregate: Feature_Popups_Aggregate;
  /** fetch data from the table: "feature_popups" using primary key columns */
  feature_popups_by_pk?: Maybe<Feature_Popups>;
  /** fetch data from the table in a streaming manner: "feature_popups" */
  feature_popups_stream: Array<Feature_Popups>;
  /** fetch data from the table: "geography_columns" */
  geography_columns: Array<Geography_Columns>;
  /** fetch aggregated fields from the table: "geography_columns" */
  geography_columns_aggregate: Geography_Columns_Aggregate;
  /** fetch data from the table in a streaming manner: "geography_columns" */
  geography_columns_stream: Array<Geography_Columns>;
  /** fetch data from the table: "geometry_columns" */
  geometry_columns: Array<Geometry_Columns>;
  /** fetch aggregated fields from the table: "geometry_columns" */
  geometry_columns_aggregate: Geometry_Columns_Aggregate;
  /** fetch data from the table in a streaming manner: "geometry_columns" */
  geometry_columns_stream: Array<Geometry_Columns>;
  /** fetch data from the table: "industries" */
  industries: Array<Industries>;
  /** fetch aggregated fields from the table: "industries" */
  industries_aggregate: Industries_Aggregate;
  /** fetch data from the table: "industries" using primary key columns */
  industries_by_pk?: Maybe<Industries>;
  /** fetch data from the table in a streaming manner: "industries" */
  industries_stream: Array<Industries>;
  /** An array relationship */
  interview_participants: Array<Interview_Participants>;
  /** An aggregate relationship */
  interview_participants_aggregate: Interview_Participants_Aggregate;
  /** fetch data from the table: "interview_participants" using primary key columns */
  interview_participants_by_pk?: Maybe<Interview_Participants>;
  /** fetch data from the table in a streaming manner: "interview_participants" */
  interview_participants_stream: Array<Interview_Participants>;
  /** An array relationship */
  interviews: Array<Interviews>;
  /** An aggregate relationship */
  interviews_aggregate: Interviews_Aggregate;
  /** fetch data from the table: "interviews" using primary key columns */
  interviews_by_pk?: Maybe<Interviews>;
  /** fetch data from the table in a streaming manner: "interviews" */
  interviews_stream: Array<Interviews>;
  /** An array relationship */
  job_candidates: Array<Job_Candidates>;
  /** An aggregate relationship */
  job_candidates_aggregate: Job_Candidates_Aggregate;
  /** fetch data from the table: "job_candidates" using primary key columns */
  job_candidates_by_pk?: Maybe<Job_Candidates>;
  /** fetch data from the table in a streaming manner: "job_candidates" */
  job_candidates_stream: Array<Job_Candidates>;
  /** An array relationship */
  job_locations: Array<Job_Locations>;
  /** An aggregate relationship */
  job_locations_aggregate: Job_Locations_Aggregate;
  /** fetch data from the table: "job_locations" using primary key columns */
  job_locations_by_pk?: Maybe<Job_Locations>;
  /** fetch data from the table in a streaming manner: "job_locations" */
  job_locations_stream: Array<Job_Locations>;
  /** An array relationship */
  jobs: Array<Jobs>;
  /** An aggregate relationship */
  jobs_aggregate: Jobs_Aggregate;
  /** fetch data from the table: "jobs" using primary key columns */
  jobs_by_pk?: Maybe<Jobs>;
  /** fetch data from the table in a streaming manner: "jobs" */
  jobs_stream: Array<Jobs>;
  /** fetch data from the table: "locations" */
  locations: Array<Locations>;
  /** fetch aggregated fields from the table: "locations" */
  locations_aggregate: Locations_Aggregate;
  /** fetch data from the table: "locations" using primary key columns */
  locations_by_pk?: Maybe<Locations>;
  /** fetch data from the table in a streaming manner: "locations" */
  locations_stream: Array<Locations>;
  /** fetch data from the table: "majors" */
  majors: Array<Majors>;
  /** fetch aggregated fields from the table: "majors" */
  majors_aggregate: Majors_Aggregate;
  /** fetch data from the table: "majors" using primary key columns */
  majors_by_pk?: Maybe<Majors>;
  /** fetch data from the table in a streaming manner: "majors" */
  majors_stream: Array<Majors>;
  /** An array relationship */
  offers: Array<Offers>;
  /** An aggregate relationship */
  offers_aggregate: Offers_Aggregate;
  /** fetch data from the table: "offers" using primary key columns */
  offers_by_pk?: Maybe<Offers>;
  /** fetch data from the table in a streaming manner: "offers" */
  offers_stream: Array<Offers>;
  /** fetch data from the table: "report_targets" */
  report_targets: Array<Report_Targets>;
  /** fetch aggregated fields from the table: "report_targets" */
  report_targets_aggregate: Report_Targets_Aggregate;
  /** fetch data from the table: "report_targets" using primary key columns */
  report_targets_by_pk?: Maybe<Report_Targets>;
  /** fetch data from the table in a streaming manner: "report_targets" */
  report_targets_stream: Array<Report_Targets>;
  /** An array relationship */
  short_urls: Array<Short_Urls>;
  /** An aggregate relationship */
  short_urls_aggregate: Short_Urls_Aggregate;
  /** fetch data from the table: "short_urls" using primary key columns */
  short_urls_by_pk?: Maybe<Short_Urls>;
  /** fetch data from the table in a streaming manner: "short_urls" */
  short_urls_stream: Array<Short_Urls>;
  /** An array relationship */
  shortlists: Array<Shortlists>;
  /** An aggregate relationship */
  shortlists_aggregate: Shortlists_Aggregate;
  /** fetch data from the table: "shortlists" using primary key columns */
  shortlists_by_pk?: Maybe<Shortlists>;
  /** fetch data from the table in a streaming manner: "shortlists" */
  shortlists_stream: Array<Shortlists>;
  /** fetch data from the table: "skills" */
  skills: Array<Skills>;
  /** fetch aggregated fields from the table: "skills" */
  skills_aggregate: Skills_Aggregate;
  /** fetch data from the table: "skills" using primary key columns */
  skills_by_pk?: Maybe<Skills>;
  /** fetch data from the table in a streaming manner: "skills" */
  skills_stream: Array<Skills>;
  /** fetch data from the table: "spatial_ref_sys" */
  spatial_ref_sys: Array<Spatial_Ref_Sys>;
  /** fetch aggregated fields from the table: "spatial_ref_sys" */
  spatial_ref_sys_aggregate: Spatial_Ref_Sys_Aggregate;
  /** fetch data from the table: "spatial_ref_sys" using primary key columns */
  spatial_ref_sys_by_pk?: Maybe<Spatial_Ref_Sys>;
  /** fetch data from the table in a streaming manner: "spatial_ref_sys" */
  spatial_ref_sys_stream: Array<Spatial_Ref_Sys>;
  /** An array relationship */
  tenant_candidates: Array<Tenant_Candidates>;
  /** An aggregate relationship */
  tenant_candidates_aggregate: Tenant_Candidates_Aggregate;
  /** fetch data from the table: "tenant_candidates" using primary key columns */
  tenant_candidates_by_pk?: Maybe<Tenant_Candidates>;
  /** fetch data from the table in a streaming manner: "tenant_candidates" */
  tenant_candidates_stream: Array<Tenant_Candidates>;
  /** An array relationship */
  tenant_locations: Array<Tenant_Locations>;
  /** An aggregate relationship */
  tenant_locations_aggregate: Tenant_Locations_Aggregate;
  /** fetch data from the table: "tenant_locations" using primary key columns */
  tenant_locations_by_pk?: Maybe<Tenant_Locations>;
  /** fetch data from the table in a streaming manner: "tenant_locations" */
  tenant_locations_stream: Array<Tenant_Locations>;
  /** An array relationship */
  tenant_users: Array<Tenant_Users>;
  /** An aggregate relationship */
  tenant_users_aggregate: Tenant_Users_Aggregate;
  /** fetch data from the table: "tenant_users" using primary key columns */
  tenant_users_by_pk?: Maybe<Tenant_Users>;
  /** fetch data from the table in a streaming manner: "tenant_users" */
  tenant_users_stream: Array<Tenant_Users>;
  /** An array relationship */
  tenants: Array<Tenants>;
  /** An aggregate relationship */
  tenants_aggregate: Tenants_Aggregate;
  /** fetch data from the table: "tenants" using primary key columns */
  tenants_by_pk?: Maybe<Tenants>;
  /** fetch data from the table in a streaming manner: "tenants" */
  tenants_stream: Array<Tenants>;
  track: Scalars['Boolean'];
  /** fetch data from the table: "universities" */
  universities: Array<Universities>;
  /** fetch aggregated fields from the table: "universities" */
  universities_aggregate: Universities_Aggregate;
  /** fetch data from the table: "universities" using primary key columns */
  universities_by_pk?: Maybe<Universities>;
  /** fetch data from the table in a streaming manner: "universities" */
  universities_stream: Array<Universities>;
  /** fetch data from the table: "users" */
  users: Array<Users>;
  /** fetch aggregated fields from the table: "users" */
  users_aggregate: Users_Aggregate;
  /** fetch data from the table: "users" using primary key columns */
  users_by_pk?: Maybe<Users>;
  /** fetch data from the table in a streaming manner: "users" */
  users_stream: Array<Users>;
};


export type Subscription_RootBrimstone_Event_FeedbackArgs = {
  distinct_on?: Maybe<Array<Brimstone_Event_Feedback_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Brimstone_Event_Feedback_Order_By>>;
  where?: Maybe<Brimstone_Event_Feedback_Bool_Exp>;
};


export type Subscription_RootBrimstone_Event_Feedback_AggregateArgs = {
  distinct_on?: Maybe<Array<Brimstone_Event_Feedback_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Brimstone_Event_Feedback_Order_By>>;
  where?: Maybe<Brimstone_Event_Feedback_Bool_Exp>;
};


export type Subscription_RootBrimstone_Event_Feedback_By_PkArgs = {
  id: Scalars['uuid'];
};


export type Subscription_RootBrimstone_Event_Feedback_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<Maybe<Brimstone_Event_Feedback_Stream_Cursor_Input>>;
  where?: Maybe<Brimstone_Event_Feedback_Bool_Exp>;
};


export type Subscription_RootBrimstone_Event_SpeakersArgs = {
  distinct_on?: Maybe<Array<Brimstone_Event_Speakers_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Brimstone_Event_Speakers_Order_By>>;
  where?: Maybe<Brimstone_Event_Speakers_Bool_Exp>;
};


export type Subscription_RootBrimstone_Event_Speakers_AggregateArgs = {
  distinct_on?: Maybe<Array<Brimstone_Event_Speakers_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Brimstone_Event_Speakers_Order_By>>;
  where?: Maybe<Brimstone_Event_Speakers_Bool_Exp>;
};


export type Subscription_RootBrimstone_Event_Speakers_By_PkArgs = {
  id: Scalars['uuid'];
};


export type Subscription_RootBrimstone_Event_Speakers_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<Maybe<Brimstone_Event_Speakers_Stream_Cursor_Input>>;
  where?: Maybe<Brimstone_Event_Speakers_Bool_Exp>;
};


export type Subscription_RootBrimstone_EventsArgs = {
  distinct_on?: Maybe<Array<Brimstone_Events_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Brimstone_Events_Order_By>>;
  where?: Maybe<Brimstone_Events_Bool_Exp>;
};


export type Subscription_RootBrimstone_Events_AggregateArgs = {
  distinct_on?: Maybe<Array<Brimstone_Events_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Brimstone_Events_Order_By>>;
  where?: Maybe<Brimstone_Events_Bool_Exp>;
};


export type Subscription_RootBrimstone_Events_By_PkArgs = {
  id: Scalars['uuid'];
};


export type Subscription_RootBrimstone_Events_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<Maybe<Brimstone_Events_Stream_Cursor_Input>>;
  where?: Maybe<Brimstone_Events_Bool_Exp>;
};


export type Subscription_RootBrimstone_SpeakersArgs = {
  distinct_on?: Maybe<Array<Brimstone_Speakers_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Brimstone_Speakers_Order_By>>;
  where?: Maybe<Brimstone_Speakers_Bool_Exp>;
};


export type Subscription_RootBrimstone_Speakers_AggregateArgs = {
  distinct_on?: Maybe<Array<Brimstone_Speakers_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Brimstone_Speakers_Order_By>>;
  where?: Maybe<Brimstone_Speakers_Bool_Exp>;
};


export type Subscription_RootBrimstone_Speakers_By_PkArgs = {
  id: Scalars['uuid'];
};


export type Subscription_RootBrimstone_Speakers_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<Maybe<Brimstone_Speakers_Stream_Cursor_Input>>;
  where?: Maybe<Brimstone_Speakers_Bool_Exp>;
};


export type Subscription_RootCandidate_CoursesArgs = {
  distinct_on?: Maybe<Array<Candidate_Courses_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Candidate_Courses_Order_By>>;
  where?: Maybe<Candidate_Courses_Bool_Exp>;
};


export type Subscription_RootCandidate_Courses_AggregateArgs = {
  distinct_on?: Maybe<Array<Candidate_Courses_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Candidate_Courses_Order_By>>;
  where?: Maybe<Candidate_Courses_Bool_Exp>;
};


export type Subscription_RootCandidate_Courses_By_PkArgs = {
  candidate_id: Scalars['uuid'];
  course_id: Scalars['uuid'];
};


export type Subscription_RootCandidate_Courses_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<Maybe<Candidate_Courses_Stream_Cursor_Input>>;
  where?: Maybe<Candidate_Courses_Bool_Exp>;
};


export type Subscription_RootCandidate_DegreesArgs = {
  distinct_on?: Maybe<Array<Candidate_Degrees_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Candidate_Degrees_Order_By>>;
  where?: Maybe<Candidate_Degrees_Bool_Exp>;
};


export type Subscription_RootCandidate_Degrees_AggregateArgs = {
  distinct_on?: Maybe<Array<Candidate_Degrees_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Candidate_Degrees_Order_By>>;
  where?: Maybe<Candidate_Degrees_Bool_Exp>;
};


export type Subscription_RootCandidate_Degrees_By_PkArgs = {
  id: Scalars['uuid'];
};


export type Subscription_RootCandidate_Degrees_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<Maybe<Candidate_Degrees_Stream_Cursor_Input>>;
  where?: Maybe<Candidate_Degrees_Bool_Exp>;
};


export type Subscription_RootCandidate_FollowsArgs = {
  distinct_on?: Maybe<Array<Candidate_Follows_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Candidate_Follows_Order_By>>;
  where?: Maybe<Candidate_Follows_Bool_Exp>;
};


export type Subscription_RootCandidate_Follows_AggregateArgs = {
  distinct_on?: Maybe<Array<Candidate_Follows_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Candidate_Follows_Order_By>>;
  where?: Maybe<Candidate_Follows_Bool_Exp>;
};


export type Subscription_RootCandidate_Follows_By_PkArgs = {
  candidate_id: Scalars['uuid'];
  enterprise_id: Scalars['uuid'];
};


export type Subscription_RootCandidate_Follows_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<Maybe<Candidate_Follows_Stream_Cursor_Input>>;
  where?: Maybe<Candidate_Follows_Bool_Exp>;
};


export type Subscription_RootCandidate_IndustriesArgs = {
  distinct_on?: Maybe<Array<Candidate_Industries_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Candidate_Industries_Order_By>>;
  where?: Maybe<Candidate_Industries_Bool_Exp>;
};


export type Subscription_RootCandidate_Industries_AggregateArgs = {
  distinct_on?: Maybe<Array<Candidate_Industries_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Candidate_Industries_Order_By>>;
  where?: Maybe<Candidate_Industries_Bool_Exp>;
};


export type Subscription_RootCandidate_Industries_By_PkArgs = {
  id: Scalars['uuid'];
};


export type Subscription_RootCandidate_Industries_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<Maybe<Candidate_Industries_Stream_Cursor_Input>>;
  where?: Maybe<Candidate_Industries_Bool_Exp>;
};


export type Subscription_RootCandidate_LocationsArgs = {
  distinct_on?: Maybe<Array<Candidate_Locations_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Candidate_Locations_Order_By>>;
  where?: Maybe<Candidate_Locations_Bool_Exp>;
};


export type Subscription_RootCandidate_Locations_AggregateArgs = {
  distinct_on?: Maybe<Array<Candidate_Locations_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Candidate_Locations_Order_By>>;
  where?: Maybe<Candidate_Locations_Bool_Exp>;
};


export type Subscription_RootCandidate_Locations_By_PkArgs = {
  id: Scalars['uuid'];
};


export type Subscription_RootCandidate_Locations_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<Maybe<Candidate_Locations_Stream_Cursor_Input>>;
  where?: Maybe<Candidate_Locations_Bool_Exp>;
};


export type Subscription_RootCandidate_MajorsArgs = {
  distinct_on?: Maybe<Array<Candidate_Majors_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Candidate_Majors_Order_By>>;
  where?: Maybe<Candidate_Majors_Bool_Exp>;
};


export type Subscription_RootCandidate_Majors_AggregateArgs = {
  distinct_on?: Maybe<Array<Candidate_Majors_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Candidate_Majors_Order_By>>;
  where?: Maybe<Candidate_Majors_Bool_Exp>;
};


export type Subscription_RootCandidate_Majors_By_PkArgs = {
  id: Scalars['uuid'];
};


export type Subscription_RootCandidate_Majors_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<Maybe<Candidate_Majors_Stream_Cursor_Input>>;
  where?: Maybe<Candidate_Majors_Bool_Exp>;
};


export type Subscription_RootCandidate_PlacementsArgs = {
  distinct_on?: Maybe<Array<Candidate_Placements_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Candidate_Placements_Order_By>>;
  where?: Maybe<Candidate_Placements_Bool_Exp>;
};


export type Subscription_RootCandidate_Placements_AggregateArgs = {
  distinct_on?: Maybe<Array<Candidate_Placements_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Candidate_Placements_Order_By>>;
  where?: Maybe<Candidate_Placements_Bool_Exp>;
};


export type Subscription_RootCandidate_Placements_By_PkArgs = {
  id: Scalars['uuid'];
};


export type Subscription_RootCandidate_Placements_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<Maybe<Candidate_Placements_Stream_Cursor_Input>>;
  where?: Maybe<Candidate_Placements_Bool_Exp>;
};


export type Subscription_RootCandidate_SkillsArgs = {
  distinct_on?: Maybe<Array<Candidate_Skills_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Candidate_Skills_Order_By>>;
  where?: Maybe<Candidate_Skills_Bool_Exp>;
};


export type Subscription_RootCandidate_Skills_AggregateArgs = {
  distinct_on?: Maybe<Array<Candidate_Skills_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Candidate_Skills_Order_By>>;
  where?: Maybe<Candidate_Skills_Bool_Exp>;
};


export type Subscription_RootCandidate_Skills_By_PkArgs = {
  id: Scalars['uuid'];
};


export type Subscription_RootCandidate_Skills_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<Maybe<Candidate_Skills_Stream_Cursor_Input>>;
  where?: Maybe<Candidate_Skills_Bool_Exp>;
};


export type Subscription_RootCandidatesArgs = {
  distinct_on?: Maybe<Array<Candidates_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Candidates_Order_By>>;
  where?: Maybe<Candidates_Bool_Exp>;
};


export type Subscription_RootCandidates_AggregateArgs = {
  distinct_on?: Maybe<Array<Candidates_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Candidates_Order_By>>;
  where?: Maybe<Candidates_Bool_Exp>;
};


export type Subscription_RootCandidates_By_PkArgs = {
  id: Scalars['uuid'];
};


export type Subscription_RootCandidates_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<Maybe<Candidates_Stream_Cursor_Input>>;
  where?: Maybe<Candidates_Bool_Exp>;
};


export type Subscription_RootClient_IndustriesArgs = {
  distinct_on?: Maybe<Array<Client_Industries_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Client_Industries_Order_By>>;
  where?: Maybe<Client_Industries_Bool_Exp>;
};


export type Subscription_RootClient_Industries_AggregateArgs = {
  distinct_on?: Maybe<Array<Client_Industries_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Client_Industries_Order_By>>;
  where?: Maybe<Client_Industries_Bool_Exp>;
};


export type Subscription_RootClient_Industries_By_PkArgs = {
  id: Scalars['uuid'];
};


export type Subscription_RootClient_Industries_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<Maybe<Client_Industries_Stream_Cursor_Input>>;
  where?: Maybe<Client_Industries_Bool_Exp>;
};


export type Subscription_RootClient_JobsArgs = {
  distinct_on?: Maybe<Array<Client_Jobs_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Client_Jobs_Order_By>>;
  where?: Maybe<Client_Jobs_Bool_Exp>;
};


export type Subscription_RootClient_Jobs_AggregateArgs = {
  distinct_on?: Maybe<Array<Client_Jobs_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Client_Jobs_Order_By>>;
  where?: Maybe<Client_Jobs_Bool_Exp>;
};


export type Subscription_RootClient_Jobs_By_PkArgs = {
  id: Scalars['uuid'];
};


export type Subscription_RootClient_Jobs_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<Maybe<Client_Jobs_Stream_Cursor_Input>>;
  where?: Maybe<Client_Jobs_Bool_Exp>;
};


export type Subscription_RootClient_LocationsArgs = {
  distinct_on?: Maybe<Array<Client_Locations_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Client_Locations_Order_By>>;
  where?: Maybe<Client_Locations_Bool_Exp>;
};


export type Subscription_RootClient_Locations_AggregateArgs = {
  distinct_on?: Maybe<Array<Client_Locations_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Client_Locations_Order_By>>;
  where?: Maybe<Client_Locations_Bool_Exp>;
};


export type Subscription_RootClient_Locations_By_PkArgs = {
  id: Scalars['uuid'];
};


export type Subscription_RootClient_Locations_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<Maybe<Client_Locations_Stream_Cursor_Input>>;
  where?: Maybe<Client_Locations_Bool_Exp>;
};


export type Subscription_RootClient_UsersArgs = {
  distinct_on?: Maybe<Array<Client_Users_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Client_Users_Order_By>>;
  where?: Maybe<Client_Users_Bool_Exp>;
};


export type Subscription_RootClient_Users_AggregateArgs = {
  distinct_on?: Maybe<Array<Client_Users_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Client_Users_Order_By>>;
  where?: Maybe<Client_Users_Bool_Exp>;
};


export type Subscription_RootClient_Users_By_PkArgs = {
  id: Scalars['uuid'];
};


export type Subscription_RootClient_Users_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<Maybe<Client_Users_Stream_Cursor_Input>>;
  where?: Maybe<Client_Users_Bool_Exp>;
};


export type Subscription_RootClientsArgs = {
  distinct_on?: Maybe<Array<Clients_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Clients_Order_By>>;
  where?: Maybe<Clients_Bool_Exp>;
};


export type Subscription_RootClients_AggregateArgs = {
  distinct_on?: Maybe<Array<Clients_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Clients_Order_By>>;
  where?: Maybe<Clients_Bool_Exp>;
};


export type Subscription_RootClients_By_PkArgs = {
  id: Scalars['uuid'];
};


export type Subscription_RootClients_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<Maybe<Clients_Stream_Cursor_Input>>;
  where?: Maybe<Clients_Bool_Exp>;
};


export type Subscription_RootContactsArgs = {
  distinct_on?: Maybe<Array<Contacts_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Contacts_Order_By>>;
  where?: Maybe<Contacts_Bool_Exp>;
};


export type Subscription_RootContacts_AggregateArgs = {
  distinct_on?: Maybe<Array<Contacts_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Contacts_Order_By>>;
  where?: Maybe<Contacts_Bool_Exp>;
};


export type Subscription_RootContacts_By_PkArgs = {
  id: Scalars['uuid'];
};


export type Subscription_RootContacts_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<Maybe<Contacts_Stream_Cursor_Input>>;
  where?: Maybe<Contacts_Bool_Exp>;
};


export type Subscription_RootCoursesArgs = {
  distinct_on?: Maybe<Array<Courses_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Courses_Order_By>>;
  where?: Maybe<Courses_Bool_Exp>;
};


export type Subscription_RootCourses_AggregateArgs = {
  distinct_on?: Maybe<Array<Courses_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Courses_Order_By>>;
  where?: Maybe<Courses_Bool_Exp>;
};


export type Subscription_RootCourses_By_PkArgs = {
  id: Scalars['uuid'];
};


export type Subscription_RootCourses_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<Maybe<Courses_Stream_Cursor_Input>>;
  where?: Maybe<Courses_Bool_Exp>;
};


export type Subscription_RootDegreesArgs = {
  distinct_on?: Maybe<Array<Degrees_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Degrees_Order_By>>;
  where?: Maybe<Degrees_Bool_Exp>;
};


export type Subscription_RootDegrees_AggregateArgs = {
  distinct_on?: Maybe<Array<Degrees_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Degrees_Order_By>>;
  where?: Maybe<Degrees_Bool_Exp>;
};


export type Subscription_RootDegrees_By_PkArgs = {
  id: Scalars['uuid'];
};


export type Subscription_RootDegrees_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<Maybe<Degrees_Stream_Cursor_Input>>;
  where?: Maybe<Degrees_Bool_Exp>;
};


export type Subscription_RootEnterprise_ClientsArgs = {
  distinct_on?: Maybe<Array<Enterprise_Clients_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Enterprise_Clients_Order_By>>;
  where?: Maybe<Enterprise_Clients_Bool_Exp>;
};


export type Subscription_RootEnterprise_Clients_AggregateArgs = {
  distinct_on?: Maybe<Array<Enterprise_Clients_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Enterprise_Clients_Order_By>>;
  where?: Maybe<Enterprise_Clients_Bool_Exp>;
};


export type Subscription_RootEnterprise_Clients_By_PkArgs = {
  client_id: Scalars['uuid'];
  enterprise_id: Scalars['uuid'];
};


export type Subscription_RootEnterprise_Clients_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<Maybe<Enterprise_Clients_Stream_Cursor_Input>>;
  where?: Maybe<Enterprise_Clients_Bool_Exp>;
};


export type Subscription_RootEnterprise_LocationsArgs = {
  distinct_on?: Maybe<Array<Enterprise_Locations_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Enterprise_Locations_Order_By>>;
  where?: Maybe<Enterprise_Locations_Bool_Exp>;
};


export type Subscription_RootEnterprise_Locations_AggregateArgs = {
  distinct_on?: Maybe<Array<Enterprise_Locations_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Enterprise_Locations_Order_By>>;
  where?: Maybe<Enterprise_Locations_Bool_Exp>;
};


export type Subscription_RootEnterprise_Locations_By_PkArgs = {
  id: Scalars['uuid'];
};


export type Subscription_RootEnterprise_Locations_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<Maybe<Enterprise_Locations_Stream_Cursor_Input>>;
  where?: Maybe<Enterprise_Locations_Bool_Exp>;
};


export type Subscription_RootEnterprisesArgs = {
  distinct_on?: Maybe<Array<Enterprises_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Enterprises_Order_By>>;
  where?: Maybe<Enterprises_Bool_Exp>;
};


export type Subscription_RootEnterprises_AggregateArgs = {
  distinct_on?: Maybe<Array<Enterprises_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Enterprises_Order_By>>;
  where?: Maybe<Enterprises_Bool_Exp>;
};


export type Subscription_RootEnterprises_By_PkArgs = {
  id: Scalars['uuid'];
};


export type Subscription_RootEnterprises_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<Maybe<Enterprises_Stream_Cursor_Input>>;
  where?: Maybe<Enterprises_Bool_Exp>;
};


export type Subscription_RootEventsArgs = {
  distinct_on?: Maybe<Array<Events_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Events_Order_By>>;
  where?: Maybe<Events_Bool_Exp>;
};


export type Subscription_RootEvents_AggregateArgs = {
  distinct_on?: Maybe<Array<Events_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Events_Order_By>>;
  where?: Maybe<Events_Bool_Exp>;
};


export type Subscription_RootEvents_By_PkArgs = {
  id: Scalars['uuid'];
};


export type Subscription_RootEvents_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<Maybe<Events_Stream_Cursor_Input>>;
  where?: Maybe<Events_Bool_Exp>;
};


export type Subscription_RootExclusive_CandidatesArgs = {
  distinct_on?: Maybe<Array<Exclusive_Candidates_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Exclusive_Candidates_Order_By>>;
  where?: Maybe<Exclusive_Candidates_Bool_Exp>;
};


export type Subscription_RootExclusive_Candidates_AggregateArgs = {
  distinct_on?: Maybe<Array<Exclusive_Candidates_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Exclusive_Candidates_Order_By>>;
  where?: Maybe<Exclusive_Candidates_Bool_Exp>;
};


export type Subscription_RootExclusive_Candidates_By_PkArgs = {
  id: Scalars['uuid'];
};


export type Subscription_RootExclusive_Candidates_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<Maybe<Exclusive_Candidates_Stream_Cursor_Input>>;
  where?: Maybe<Exclusive_Candidates_Bool_Exp>;
};


export type Subscription_RootFb_Server_EventArgs = {
  id: Scalars['uuid'];
};


export type Subscription_RootFeature_PopupsArgs = {
  distinct_on?: Maybe<Array<Feature_Popups_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Feature_Popups_Order_By>>;
  where?: Maybe<Feature_Popups_Bool_Exp>;
};


export type Subscription_RootFeature_Popups_AggregateArgs = {
  distinct_on?: Maybe<Array<Feature_Popups_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Feature_Popups_Order_By>>;
  where?: Maybe<Feature_Popups_Bool_Exp>;
};


export type Subscription_RootFeature_Popups_By_PkArgs = {
  id: Scalars['uuid'];
};


export type Subscription_RootFeature_Popups_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<Maybe<Feature_Popups_Stream_Cursor_Input>>;
  where?: Maybe<Feature_Popups_Bool_Exp>;
};


export type Subscription_RootGeography_ColumnsArgs = {
  distinct_on?: Maybe<Array<Geography_Columns_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Geography_Columns_Order_By>>;
  where?: Maybe<Geography_Columns_Bool_Exp>;
};


export type Subscription_RootGeography_Columns_AggregateArgs = {
  distinct_on?: Maybe<Array<Geography_Columns_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Geography_Columns_Order_By>>;
  where?: Maybe<Geography_Columns_Bool_Exp>;
};


export type Subscription_RootGeography_Columns_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<Maybe<Geography_Columns_Stream_Cursor_Input>>;
  where?: Maybe<Geography_Columns_Bool_Exp>;
};


export type Subscription_RootGeometry_ColumnsArgs = {
  distinct_on?: Maybe<Array<Geometry_Columns_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Geometry_Columns_Order_By>>;
  where?: Maybe<Geometry_Columns_Bool_Exp>;
};


export type Subscription_RootGeometry_Columns_AggregateArgs = {
  distinct_on?: Maybe<Array<Geometry_Columns_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Geometry_Columns_Order_By>>;
  where?: Maybe<Geometry_Columns_Bool_Exp>;
};


export type Subscription_RootGeometry_Columns_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<Maybe<Geometry_Columns_Stream_Cursor_Input>>;
  where?: Maybe<Geometry_Columns_Bool_Exp>;
};


export type Subscription_RootIndustriesArgs = {
  distinct_on?: Maybe<Array<Industries_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Industries_Order_By>>;
  where?: Maybe<Industries_Bool_Exp>;
};


export type Subscription_RootIndustries_AggregateArgs = {
  distinct_on?: Maybe<Array<Industries_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Industries_Order_By>>;
  where?: Maybe<Industries_Bool_Exp>;
};


export type Subscription_RootIndustries_By_PkArgs = {
  id: Scalars['uuid'];
};


export type Subscription_RootIndustries_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<Maybe<Industries_Stream_Cursor_Input>>;
  where?: Maybe<Industries_Bool_Exp>;
};


export type Subscription_RootInterview_ParticipantsArgs = {
  distinct_on?: Maybe<Array<Interview_Participants_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Interview_Participants_Order_By>>;
  where?: Maybe<Interview_Participants_Bool_Exp>;
};


export type Subscription_RootInterview_Participants_AggregateArgs = {
  distinct_on?: Maybe<Array<Interview_Participants_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Interview_Participants_Order_By>>;
  where?: Maybe<Interview_Participants_Bool_Exp>;
};


export type Subscription_RootInterview_Participants_By_PkArgs = {
  id: Scalars['uuid'];
};


export type Subscription_RootInterview_Participants_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<Maybe<Interview_Participants_Stream_Cursor_Input>>;
  where?: Maybe<Interview_Participants_Bool_Exp>;
};


export type Subscription_RootInterviewsArgs = {
  distinct_on?: Maybe<Array<Interviews_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Interviews_Order_By>>;
  where?: Maybe<Interviews_Bool_Exp>;
};


export type Subscription_RootInterviews_AggregateArgs = {
  distinct_on?: Maybe<Array<Interviews_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Interviews_Order_By>>;
  where?: Maybe<Interviews_Bool_Exp>;
};


export type Subscription_RootInterviews_By_PkArgs = {
  id: Scalars['uuid'];
};


export type Subscription_RootInterviews_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<Maybe<Interviews_Stream_Cursor_Input>>;
  where?: Maybe<Interviews_Bool_Exp>;
};


export type Subscription_RootJob_CandidatesArgs = {
  distinct_on?: Maybe<Array<Job_Candidates_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Job_Candidates_Order_By>>;
  where?: Maybe<Job_Candidates_Bool_Exp>;
};


export type Subscription_RootJob_Candidates_AggregateArgs = {
  distinct_on?: Maybe<Array<Job_Candidates_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Job_Candidates_Order_By>>;
  where?: Maybe<Job_Candidates_Bool_Exp>;
};


export type Subscription_RootJob_Candidates_By_PkArgs = {
  id: Scalars['uuid'];
};


export type Subscription_RootJob_Candidates_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<Maybe<Job_Candidates_Stream_Cursor_Input>>;
  where?: Maybe<Job_Candidates_Bool_Exp>;
};


export type Subscription_RootJob_LocationsArgs = {
  distinct_on?: Maybe<Array<Job_Locations_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Job_Locations_Order_By>>;
  where?: Maybe<Job_Locations_Bool_Exp>;
};


export type Subscription_RootJob_Locations_AggregateArgs = {
  distinct_on?: Maybe<Array<Job_Locations_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Job_Locations_Order_By>>;
  where?: Maybe<Job_Locations_Bool_Exp>;
};


export type Subscription_RootJob_Locations_By_PkArgs = {
  id: Scalars['uuid'];
};


export type Subscription_RootJob_Locations_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<Maybe<Job_Locations_Stream_Cursor_Input>>;
  where?: Maybe<Job_Locations_Bool_Exp>;
};


export type Subscription_RootJobsArgs = {
  distinct_on?: Maybe<Array<Jobs_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Jobs_Order_By>>;
  where?: Maybe<Jobs_Bool_Exp>;
};


export type Subscription_RootJobs_AggregateArgs = {
  distinct_on?: Maybe<Array<Jobs_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Jobs_Order_By>>;
  where?: Maybe<Jobs_Bool_Exp>;
};


export type Subscription_RootJobs_By_PkArgs = {
  id: Scalars['uuid'];
};


export type Subscription_RootJobs_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<Maybe<Jobs_Stream_Cursor_Input>>;
  where?: Maybe<Jobs_Bool_Exp>;
};


export type Subscription_RootLocationsArgs = {
  distinct_on?: Maybe<Array<Locations_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Locations_Order_By>>;
  where?: Maybe<Locations_Bool_Exp>;
};


export type Subscription_RootLocations_AggregateArgs = {
  distinct_on?: Maybe<Array<Locations_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Locations_Order_By>>;
  where?: Maybe<Locations_Bool_Exp>;
};


export type Subscription_RootLocations_By_PkArgs = {
  id: Scalars['uuid'];
};


export type Subscription_RootLocations_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<Maybe<Locations_Stream_Cursor_Input>>;
  where?: Maybe<Locations_Bool_Exp>;
};


export type Subscription_RootMajorsArgs = {
  distinct_on?: Maybe<Array<Majors_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Majors_Order_By>>;
  where?: Maybe<Majors_Bool_Exp>;
};


export type Subscription_RootMajors_AggregateArgs = {
  distinct_on?: Maybe<Array<Majors_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Majors_Order_By>>;
  where?: Maybe<Majors_Bool_Exp>;
};


export type Subscription_RootMajors_By_PkArgs = {
  id: Scalars['uuid'];
};


export type Subscription_RootMajors_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<Maybe<Majors_Stream_Cursor_Input>>;
  where?: Maybe<Majors_Bool_Exp>;
};


export type Subscription_RootOffersArgs = {
  distinct_on?: Maybe<Array<Offers_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Offers_Order_By>>;
  where?: Maybe<Offers_Bool_Exp>;
};


export type Subscription_RootOffers_AggregateArgs = {
  distinct_on?: Maybe<Array<Offers_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Offers_Order_By>>;
  where?: Maybe<Offers_Bool_Exp>;
};


export type Subscription_RootOffers_By_PkArgs = {
  id: Scalars['uuid'];
};


export type Subscription_RootOffers_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<Maybe<Offers_Stream_Cursor_Input>>;
  where?: Maybe<Offers_Bool_Exp>;
};


export type Subscription_RootReport_TargetsArgs = {
  distinct_on?: Maybe<Array<Report_Targets_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Report_Targets_Order_By>>;
  where?: Maybe<Report_Targets_Bool_Exp>;
};


export type Subscription_RootReport_Targets_AggregateArgs = {
  distinct_on?: Maybe<Array<Report_Targets_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Report_Targets_Order_By>>;
  where?: Maybe<Report_Targets_Bool_Exp>;
};


export type Subscription_RootReport_Targets_By_PkArgs = {
  id: Scalars['uuid'];
};


export type Subscription_RootReport_Targets_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<Maybe<Report_Targets_Stream_Cursor_Input>>;
  where?: Maybe<Report_Targets_Bool_Exp>;
};


export type Subscription_RootShort_UrlsArgs = {
  distinct_on?: Maybe<Array<Short_Urls_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Short_Urls_Order_By>>;
  where?: Maybe<Short_Urls_Bool_Exp>;
};


export type Subscription_RootShort_Urls_AggregateArgs = {
  distinct_on?: Maybe<Array<Short_Urls_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Short_Urls_Order_By>>;
  where?: Maybe<Short_Urls_Bool_Exp>;
};


export type Subscription_RootShort_Urls_By_PkArgs = {
  id: Scalars['uuid'];
};


export type Subscription_RootShort_Urls_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<Maybe<Short_Urls_Stream_Cursor_Input>>;
  where?: Maybe<Short_Urls_Bool_Exp>;
};


export type Subscription_RootShortlistsArgs = {
  distinct_on?: Maybe<Array<Shortlists_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Shortlists_Order_By>>;
  where?: Maybe<Shortlists_Bool_Exp>;
};


export type Subscription_RootShortlists_AggregateArgs = {
  distinct_on?: Maybe<Array<Shortlists_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Shortlists_Order_By>>;
  where?: Maybe<Shortlists_Bool_Exp>;
};


export type Subscription_RootShortlists_By_PkArgs = {
  id: Scalars['uuid'];
};


export type Subscription_RootShortlists_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<Maybe<Shortlists_Stream_Cursor_Input>>;
  where?: Maybe<Shortlists_Bool_Exp>;
};


export type Subscription_RootSkillsArgs = {
  distinct_on?: Maybe<Array<Skills_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Skills_Order_By>>;
  where?: Maybe<Skills_Bool_Exp>;
};


export type Subscription_RootSkills_AggregateArgs = {
  distinct_on?: Maybe<Array<Skills_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Skills_Order_By>>;
  where?: Maybe<Skills_Bool_Exp>;
};


export type Subscription_RootSkills_By_PkArgs = {
  id: Scalars['uuid'];
};


export type Subscription_RootSkills_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<Maybe<Skills_Stream_Cursor_Input>>;
  where?: Maybe<Skills_Bool_Exp>;
};


export type Subscription_RootSpatial_Ref_SysArgs = {
  distinct_on?: Maybe<Array<Spatial_Ref_Sys_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Spatial_Ref_Sys_Order_By>>;
  where?: Maybe<Spatial_Ref_Sys_Bool_Exp>;
};


export type Subscription_RootSpatial_Ref_Sys_AggregateArgs = {
  distinct_on?: Maybe<Array<Spatial_Ref_Sys_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Spatial_Ref_Sys_Order_By>>;
  where?: Maybe<Spatial_Ref_Sys_Bool_Exp>;
};


export type Subscription_RootSpatial_Ref_Sys_By_PkArgs = {
  srid: Scalars['Int'];
};


export type Subscription_RootSpatial_Ref_Sys_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<Maybe<Spatial_Ref_Sys_Stream_Cursor_Input>>;
  where?: Maybe<Spatial_Ref_Sys_Bool_Exp>;
};


export type Subscription_RootTenant_CandidatesArgs = {
  distinct_on?: Maybe<Array<Tenant_Candidates_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Tenant_Candidates_Order_By>>;
  where?: Maybe<Tenant_Candidates_Bool_Exp>;
};


export type Subscription_RootTenant_Candidates_AggregateArgs = {
  distinct_on?: Maybe<Array<Tenant_Candidates_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Tenant_Candidates_Order_By>>;
  where?: Maybe<Tenant_Candidates_Bool_Exp>;
};


export type Subscription_RootTenant_Candidates_By_PkArgs = {
  id: Scalars['uuid'];
};


export type Subscription_RootTenant_Candidates_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<Maybe<Tenant_Candidates_Stream_Cursor_Input>>;
  where?: Maybe<Tenant_Candidates_Bool_Exp>;
};


export type Subscription_RootTenant_LocationsArgs = {
  distinct_on?: Maybe<Array<Tenant_Locations_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Tenant_Locations_Order_By>>;
  where?: Maybe<Tenant_Locations_Bool_Exp>;
};


export type Subscription_RootTenant_Locations_AggregateArgs = {
  distinct_on?: Maybe<Array<Tenant_Locations_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Tenant_Locations_Order_By>>;
  where?: Maybe<Tenant_Locations_Bool_Exp>;
};


export type Subscription_RootTenant_Locations_By_PkArgs = {
  id: Scalars['uuid'];
};


export type Subscription_RootTenant_Locations_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<Maybe<Tenant_Locations_Stream_Cursor_Input>>;
  where?: Maybe<Tenant_Locations_Bool_Exp>;
};


export type Subscription_RootTenant_UsersArgs = {
  distinct_on?: Maybe<Array<Tenant_Users_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Tenant_Users_Order_By>>;
  where?: Maybe<Tenant_Users_Bool_Exp>;
};


export type Subscription_RootTenant_Users_AggregateArgs = {
  distinct_on?: Maybe<Array<Tenant_Users_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Tenant_Users_Order_By>>;
  where?: Maybe<Tenant_Users_Bool_Exp>;
};


export type Subscription_RootTenant_Users_By_PkArgs = {
  id: Scalars['uuid'];
};


export type Subscription_RootTenant_Users_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<Maybe<Tenant_Users_Stream_Cursor_Input>>;
  where?: Maybe<Tenant_Users_Bool_Exp>;
};


export type Subscription_RootTenantsArgs = {
  distinct_on?: Maybe<Array<Tenants_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Tenants_Order_By>>;
  where?: Maybe<Tenants_Bool_Exp>;
};


export type Subscription_RootTenants_AggregateArgs = {
  distinct_on?: Maybe<Array<Tenants_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Tenants_Order_By>>;
  where?: Maybe<Tenants_Bool_Exp>;
};


export type Subscription_RootTenants_By_PkArgs = {
  id: Scalars['uuid'];
};


export type Subscription_RootTenants_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<Maybe<Tenants_Stream_Cursor_Input>>;
  where?: Maybe<Tenants_Bool_Exp>;
};


export type Subscription_RootTrackArgs = {
  id: Scalars['uuid'];
};


export type Subscription_RootUniversitiesArgs = {
  distinct_on?: Maybe<Array<Universities_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Universities_Order_By>>;
  where?: Maybe<Universities_Bool_Exp>;
};


export type Subscription_RootUniversities_AggregateArgs = {
  distinct_on?: Maybe<Array<Universities_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Universities_Order_By>>;
  where?: Maybe<Universities_Bool_Exp>;
};


export type Subscription_RootUniversities_By_PkArgs = {
  id: Scalars['uuid'];
};


export type Subscription_RootUniversities_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<Maybe<Universities_Stream_Cursor_Input>>;
  where?: Maybe<Universities_Bool_Exp>;
};


export type Subscription_RootUsersArgs = {
  distinct_on?: Maybe<Array<Users_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Users_Order_By>>;
  where?: Maybe<Users_Bool_Exp>;
};


export type Subscription_RootUsers_AggregateArgs = {
  distinct_on?: Maybe<Array<Users_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Users_Order_By>>;
  where?: Maybe<Users_Bool_Exp>;
};


export type Subscription_RootUsers_By_PkArgs = {
  id: Scalars['uuid'];
};


export type Subscription_RootUsers_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<Maybe<Users_Stream_Cursor_Input>>;
  where?: Maybe<Users_Bool_Exp>;
};

/** columns and relationships of "tenant_candidates" */
export type Tenant_Candidates = {
  __typename?: 'tenant_candidates';
  /** An object relationship */
  candidate: Candidates;
  candidate_id: Scalars['uuid'];
  created_at: Scalars['timestamptz'];
  deleted_at?: Maybe<Scalars['timestamptz']>;
  id: Scalars['uuid'];
  /** An object relationship */
  tenant: Tenants;
  tenant_id: Scalars['uuid'];
  updated_at: Scalars['timestamptz'];
};

/** aggregated selection of "tenant_candidates" */
export type Tenant_Candidates_Aggregate = {
  __typename?: 'tenant_candidates_aggregate';
  aggregate?: Maybe<Tenant_Candidates_Aggregate_Fields>;
  nodes: Array<Tenant_Candidates>;
};

export type Tenant_Candidates_Aggregate_Bool_Exp = {
  count?: Maybe<Tenant_Candidates_Aggregate_Bool_Exp_Count>;
};

export type Tenant_Candidates_Aggregate_Bool_Exp_Count = {
  arguments?: Maybe<Array<Tenant_Candidates_Select_Column>>;
  distinct?: Maybe<Scalars['Boolean']>;
  filter?: Maybe<Tenant_Candidates_Bool_Exp>;
  predicate: Int_Comparison_Exp;
};

/** aggregate fields of "tenant_candidates" */
export type Tenant_Candidates_Aggregate_Fields = {
  __typename?: 'tenant_candidates_aggregate_fields';
  count: Scalars['Int'];
  max?: Maybe<Tenant_Candidates_Max_Fields>;
  min?: Maybe<Tenant_Candidates_Min_Fields>;
};


/** aggregate fields of "tenant_candidates" */
export type Tenant_Candidates_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<Tenant_Candidates_Select_Column>>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "tenant_candidates" */
export type Tenant_Candidates_Aggregate_Order_By = {
  count?: Maybe<Order_By>;
  max?: Maybe<Tenant_Candidates_Max_Order_By>;
  min?: Maybe<Tenant_Candidates_Min_Order_By>;
};

/** input type for inserting array relation for remote table "tenant_candidates" */
export type Tenant_Candidates_Arr_Rel_Insert_Input = {
  data: Array<Tenant_Candidates_Insert_Input>;
  /** upsert condition */
  on_conflict?: Maybe<Tenant_Candidates_On_Conflict>;
};

/** Boolean expression to filter rows from the table "tenant_candidates". All fields are combined with a logical 'AND'. */
export type Tenant_Candidates_Bool_Exp = {
  _and?: Maybe<Array<Tenant_Candidates_Bool_Exp>>;
  _not?: Maybe<Tenant_Candidates_Bool_Exp>;
  _or?: Maybe<Array<Tenant_Candidates_Bool_Exp>>;
  candidate?: Maybe<Candidates_Bool_Exp>;
  candidate_id?: Maybe<Uuid_Comparison_Exp>;
  created_at?: Maybe<Timestamptz_Comparison_Exp>;
  deleted_at?: Maybe<Timestamptz_Comparison_Exp>;
  id?: Maybe<Uuid_Comparison_Exp>;
  tenant?: Maybe<Tenants_Bool_Exp>;
  tenant_id?: Maybe<Uuid_Comparison_Exp>;
  updated_at?: Maybe<Timestamptz_Comparison_Exp>;
};

/** unique or primary key constraints on table "tenant_candidates" */
export enum Tenant_Candidates_Constraint {
  /** unique or primary key constraint on columns "id" */
  TenantCandidatesPkey = 'tenant_candidates_pkey'
}

/** input type for inserting data into table "tenant_candidates" */
export type Tenant_Candidates_Insert_Input = {
  candidate?: Maybe<Candidates_Obj_Rel_Insert_Input>;
  candidate_id?: Maybe<Scalars['uuid']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  deleted_at?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['uuid']>;
  tenant?: Maybe<Tenants_Obj_Rel_Insert_Input>;
  tenant_id?: Maybe<Scalars['uuid']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** aggregate max on columns */
export type Tenant_Candidates_Max_Fields = {
  __typename?: 'tenant_candidates_max_fields';
  candidate_id?: Maybe<Scalars['uuid']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  deleted_at?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['uuid']>;
  tenant_id?: Maybe<Scalars['uuid']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** order by max() on columns of table "tenant_candidates" */
export type Tenant_Candidates_Max_Order_By = {
  candidate_id?: Maybe<Order_By>;
  created_at?: Maybe<Order_By>;
  deleted_at?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  tenant_id?: Maybe<Order_By>;
  updated_at?: Maybe<Order_By>;
};

/** aggregate min on columns */
export type Tenant_Candidates_Min_Fields = {
  __typename?: 'tenant_candidates_min_fields';
  candidate_id?: Maybe<Scalars['uuid']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  deleted_at?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['uuid']>;
  tenant_id?: Maybe<Scalars['uuid']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** order by min() on columns of table "tenant_candidates" */
export type Tenant_Candidates_Min_Order_By = {
  candidate_id?: Maybe<Order_By>;
  created_at?: Maybe<Order_By>;
  deleted_at?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  tenant_id?: Maybe<Order_By>;
  updated_at?: Maybe<Order_By>;
};

/** response of any mutation on the table "tenant_candidates" */
export type Tenant_Candidates_Mutation_Response = {
  __typename?: 'tenant_candidates_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Tenant_Candidates>;
};

/** on_conflict condition type for table "tenant_candidates" */
export type Tenant_Candidates_On_Conflict = {
  constraint: Tenant_Candidates_Constraint;
  update_columns?: Array<Tenant_Candidates_Update_Column>;
  where?: Maybe<Tenant_Candidates_Bool_Exp>;
};

/** Ordering options when selecting data from "tenant_candidates". */
export type Tenant_Candidates_Order_By = {
  candidate?: Maybe<Candidates_Order_By>;
  candidate_id?: Maybe<Order_By>;
  created_at?: Maybe<Order_By>;
  deleted_at?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  tenant?: Maybe<Tenants_Order_By>;
  tenant_id?: Maybe<Order_By>;
  updated_at?: Maybe<Order_By>;
};

/** primary key columns input for table: tenant_candidates */
export type Tenant_Candidates_Pk_Columns_Input = {
  id: Scalars['uuid'];
};

/** select columns of table "tenant_candidates" */
export enum Tenant_Candidates_Select_Column {
  /** column name */
  CandidateId = 'candidate_id',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  DeletedAt = 'deleted_at',
  /** column name */
  Id = 'id',
  /** column name */
  TenantId = 'tenant_id',
  /** column name */
  UpdatedAt = 'updated_at'
}

/** input type for updating data in table "tenant_candidates" */
export type Tenant_Candidates_Set_Input = {
  candidate_id?: Maybe<Scalars['uuid']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  deleted_at?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['uuid']>;
  tenant_id?: Maybe<Scalars['uuid']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** Streaming cursor of the table "tenant_candidates" */
export type Tenant_Candidates_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Tenant_Candidates_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: Maybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Tenant_Candidates_Stream_Cursor_Value_Input = {
  candidate_id?: Maybe<Scalars['uuid']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  deleted_at?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['uuid']>;
  tenant_id?: Maybe<Scalars['uuid']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** update columns of table "tenant_candidates" */
export enum Tenant_Candidates_Update_Column {
  /** column name */
  CandidateId = 'candidate_id',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  DeletedAt = 'deleted_at',
  /** column name */
  Id = 'id',
  /** column name */
  TenantId = 'tenant_id',
  /** column name */
  UpdatedAt = 'updated_at'
}

export type Tenant_Candidates_Updates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: Maybe<Tenant_Candidates_Set_Input>;
  /** filter the rows which have to be updated */
  where: Tenant_Candidates_Bool_Exp;
};

/** columns and relationships of "tenant_locations" */
export type Tenant_Locations = {
  __typename?: 'tenant_locations';
  created_at: Scalars['timestamptz'];
  deleted_at?: Maybe<Scalars['timestamptz']>;
  id: Scalars['uuid'];
  locality: Scalars['String'];
  /** An object relationship */
  location?: Maybe<Locations>;
  location_description?: Maybe<Scalars['location_name_enums']>;
  postcode: Scalars['String'];
  state: Scalars['String'];
  /** An object relationship */
  tenant: Tenants;
  tenant_id: Scalars['uuid'];
  updated_at: Scalars['timestamptz'];
};

/** aggregated selection of "tenant_locations" */
export type Tenant_Locations_Aggregate = {
  __typename?: 'tenant_locations_aggregate';
  aggregate?: Maybe<Tenant_Locations_Aggregate_Fields>;
  nodes: Array<Tenant_Locations>;
};

export type Tenant_Locations_Aggregate_Bool_Exp = {
  count?: Maybe<Tenant_Locations_Aggregate_Bool_Exp_Count>;
};

export type Tenant_Locations_Aggregate_Bool_Exp_Count = {
  arguments?: Maybe<Array<Tenant_Locations_Select_Column>>;
  distinct?: Maybe<Scalars['Boolean']>;
  filter?: Maybe<Tenant_Locations_Bool_Exp>;
  predicate: Int_Comparison_Exp;
};

/** aggregate fields of "tenant_locations" */
export type Tenant_Locations_Aggregate_Fields = {
  __typename?: 'tenant_locations_aggregate_fields';
  count: Scalars['Int'];
  max?: Maybe<Tenant_Locations_Max_Fields>;
  min?: Maybe<Tenant_Locations_Min_Fields>;
};


/** aggregate fields of "tenant_locations" */
export type Tenant_Locations_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<Tenant_Locations_Select_Column>>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "tenant_locations" */
export type Tenant_Locations_Aggregate_Order_By = {
  count?: Maybe<Order_By>;
  max?: Maybe<Tenant_Locations_Max_Order_By>;
  min?: Maybe<Tenant_Locations_Min_Order_By>;
};

/** input type for inserting array relation for remote table "tenant_locations" */
export type Tenant_Locations_Arr_Rel_Insert_Input = {
  data: Array<Tenant_Locations_Insert_Input>;
  /** upsert condition */
  on_conflict?: Maybe<Tenant_Locations_On_Conflict>;
};

/** Boolean expression to filter rows from the table "tenant_locations". All fields are combined with a logical 'AND'. */
export type Tenant_Locations_Bool_Exp = {
  _and?: Maybe<Array<Tenant_Locations_Bool_Exp>>;
  _not?: Maybe<Tenant_Locations_Bool_Exp>;
  _or?: Maybe<Array<Tenant_Locations_Bool_Exp>>;
  created_at?: Maybe<Timestamptz_Comparison_Exp>;
  deleted_at?: Maybe<Timestamptz_Comparison_Exp>;
  id?: Maybe<Uuid_Comparison_Exp>;
  locality?: Maybe<String_Comparison_Exp>;
  location?: Maybe<Locations_Bool_Exp>;
  location_description?: Maybe<Location_Name_Enums_Comparison_Exp>;
  postcode?: Maybe<String_Comparison_Exp>;
  state?: Maybe<String_Comparison_Exp>;
  tenant?: Maybe<Tenants_Bool_Exp>;
  tenant_id?: Maybe<Uuid_Comparison_Exp>;
  updated_at?: Maybe<Timestamptz_Comparison_Exp>;
};

/** unique or primary key constraints on table "tenant_locations" */
export enum Tenant_Locations_Constraint {
  /** unique or primary key constraint on columns "id" */
  TenantLocationsPkey = 'tenant_locations_pkey',
  /** unique or primary key constraint on columns "state", "locality", "postcode", "tenant_id" */
  TenantLocationsTenantIdPostcodeLocalityStateKey = 'tenant_locations_tenant_id_postcode_locality_state_key'
}

/** input type for inserting data into table "tenant_locations" */
export type Tenant_Locations_Insert_Input = {
  created_at?: Maybe<Scalars['timestamptz']>;
  deleted_at?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['uuid']>;
  locality?: Maybe<Scalars['String']>;
  location?: Maybe<Locations_Obj_Rel_Insert_Input>;
  location_description?: Maybe<Scalars['location_name_enums']>;
  postcode?: Maybe<Scalars['String']>;
  state?: Maybe<Scalars['String']>;
  tenant?: Maybe<Tenants_Obj_Rel_Insert_Input>;
  tenant_id?: Maybe<Scalars['uuid']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** aggregate max on columns */
export type Tenant_Locations_Max_Fields = {
  __typename?: 'tenant_locations_max_fields';
  created_at?: Maybe<Scalars['timestamptz']>;
  deleted_at?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['uuid']>;
  locality?: Maybe<Scalars['String']>;
  location_description?: Maybe<Scalars['location_name_enums']>;
  postcode?: Maybe<Scalars['String']>;
  state?: Maybe<Scalars['String']>;
  tenant_id?: Maybe<Scalars['uuid']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** order by max() on columns of table "tenant_locations" */
export type Tenant_Locations_Max_Order_By = {
  created_at?: Maybe<Order_By>;
  deleted_at?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  locality?: Maybe<Order_By>;
  location_description?: Maybe<Order_By>;
  postcode?: Maybe<Order_By>;
  state?: Maybe<Order_By>;
  tenant_id?: Maybe<Order_By>;
  updated_at?: Maybe<Order_By>;
};

/** aggregate min on columns */
export type Tenant_Locations_Min_Fields = {
  __typename?: 'tenant_locations_min_fields';
  created_at?: Maybe<Scalars['timestamptz']>;
  deleted_at?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['uuid']>;
  locality?: Maybe<Scalars['String']>;
  location_description?: Maybe<Scalars['location_name_enums']>;
  postcode?: Maybe<Scalars['String']>;
  state?: Maybe<Scalars['String']>;
  tenant_id?: Maybe<Scalars['uuid']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** order by min() on columns of table "tenant_locations" */
export type Tenant_Locations_Min_Order_By = {
  created_at?: Maybe<Order_By>;
  deleted_at?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  locality?: Maybe<Order_By>;
  location_description?: Maybe<Order_By>;
  postcode?: Maybe<Order_By>;
  state?: Maybe<Order_By>;
  tenant_id?: Maybe<Order_By>;
  updated_at?: Maybe<Order_By>;
};

/** response of any mutation on the table "tenant_locations" */
export type Tenant_Locations_Mutation_Response = {
  __typename?: 'tenant_locations_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Tenant_Locations>;
};

/** on_conflict condition type for table "tenant_locations" */
export type Tenant_Locations_On_Conflict = {
  constraint: Tenant_Locations_Constraint;
  update_columns?: Array<Tenant_Locations_Update_Column>;
  where?: Maybe<Tenant_Locations_Bool_Exp>;
};

/** Ordering options when selecting data from "tenant_locations". */
export type Tenant_Locations_Order_By = {
  created_at?: Maybe<Order_By>;
  deleted_at?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  locality?: Maybe<Order_By>;
  location?: Maybe<Locations_Order_By>;
  location_description?: Maybe<Order_By>;
  postcode?: Maybe<Order_By>;
  state?: Maybe<Order_By>;
  tenant?: Maybe<Tenants_Order_By>;
  tenant_id?: Maybe<Order_By>;
  updated_at?: Maybe<Order_By>;
};

/** primary key columns input for table: tenant_locations */
export type Tenant_Locations_Pk_Columns_Input = {
  id: Scalars['uuid'];
};

/** select columns of table "tenant_locations" */
export enum Tenant_Locations_Select_Column {
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  DeletedAt = 'deleted_at',
  /** column name */
  Id = 'id',
  /** column name */
  Locality = 'locality',
  /** column name */
  LocationDescription = 'location_description',
  /** column name */
  Postcode = 'postcode',
  /** column name */
  State = 'state',
  /** column name */
  TenantId = 'tenant_id',
  /** column name */
  UpdatedAt = 'updated_at'
}

/** input type for updating data in table "tenant_locations" */
export type Tenant_Locations_Set_Input = {
  created_at?: Maybe<Scalars['timestamptz']>;
  deleted_at?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['uuid']>;
  locality?: Maybe<Scalars['String']>;
  location_description?: Maybe<Scalars['location_name_enums']>;
  postcode?: Maybe<Scalars['String']>;
  state?: Maybe<Scalars['String']>;
  tenant_id?: Maybe<Scalars['uuid']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** Streaming cursor of the table "tenant_locations" */
export type Tenant_Locations_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Tenant_Locations_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: Maybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Tenant_Locations_Stream_Cursor_Value_Input = {
  created_at?: Maybe<Scalars['timestamptz']>;
  deleted_at?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['uuid']>;
  locality?: Maybe<Scalars['String']>;
  location_description?: Maybe<Scalars['location_name_enums']>;
  postcode?: Maybe<Scalars['String']>;
  state?: Maybe<Scalars['String']>;
  tenant_id?: Maybe<Scalars['uuid']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** update columns of table "tenant_locations" */
export enum Tenant_Locations_Update_Column {
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  DeletedAt = 'deleted_at',
  /** column name */
  Id = 'id',
  /** column name */
  Locality = 'locality',
  /** column name */
  LocationDescription = 'location_description',
  /** column name */
  Postcode = 'postcode',
  /** column name */
  State = 'state',
  /** column name */
  TenantId = 'tenant_id',
  /** column name */
  UpdatedAt = 'updated_at'
}

export type Tenant_Locations_Updates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: Maybe<Tenant_Locations_Set_Input>;
  /** filter the rows which have to be updated */
  where: Tenant_Locations_Bool_Exp;
};

/** columns and relationships of "tenant_users" */
export type Tenant_Users = {
  __typename?: 'tenant_users';
  created_at: Scalars['timestamptz'];
  deleted_at?: Maybe<Scalars['timestamptz']>;
  id: Scalars['uuid'];
  /** An object relationship */
  tenant: Tenants;
  tenant_id: Scalars['uuid'];
  updated_at: Scalars['timestamptz'];
  /** An object relationship */
  user: Users;
  user_id: Scalars['uuid'];
};

/** aggregated selection of "tenant_users" */
export type Tenant_Users_Aggregate = {
  __typename?: 'tenant_users_aggregate';
  aggregate?: Maybe<Tenant_Users_Aggregate_Fields>;
  nodes: Array<Tenant_Users>;
};

export type Tenant_Users_Aggregate_Bool_Exp = {
  count?: Maybe<Tenant_Users_Aggregate_Bool_Exp_Count>;
};

export type Tenant_Users_Aggregate_Bool_Exp_Count = {
  arguments?: Maybe<Array<Tenant_Users_Select_Column>>;
  distinct?: Maybe<Scalars['Boolean']>;
  filter?: Maybe<Tenant_Users_Bool_Exp>;
  predicate: Int_Comparison_Exp;
};

/** aggregate fields of "tenant_users" */
export type Tenant_Users_Aggregate_Fields = {
  __typename?: 'tenant_users_aggregate_fields';
  count: Scalars['Int'];
  max?: Maybe<Tenant_Users_Max_Fields>;
  min?: Maybe<Tenant_Users_Min_Fields>;
};


/** aggregate fields of "tenant_users" */
export type Tenant_Users_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<Tenant_Users_Select_Column>>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "tenant_users" */
export type Tenant_Users_Aggregate_Order_By = {
  count?: Maybe<Order_By>;
  max?: Maybe<Tenant_Users_Max_Order_By>;
  min?: Maybe<Tenant_Users_Min_Order_By>;
};

/** input type for inserting array relation for remote table "tenant_users" */
export type Tenant_Users_Arr_Rel_Insert_Input = {
  data: Array<Tenant_Users_Insert_Input>;
  /** upsert condition */
  on_conflict?: Maybe<Tenant_Users_On_Conflict>;
};

/** Boolean expression to filter rows from the table "tenant_users". All fields are combined with a logical 'AND'. */
export type Tenant_Users_Bool_Exp = {
  _and?: Maybe<Array<Tenant_Users_Bool_Exp>>;
  _not?: Maybe<Tenant_Users_Bool_Exp>;
  _or?: Maybe<Array<Tenant_Users_Bool_Exp>>;
  created_at?: Maybe<Timestamptz_Comparison_Exp>;
  deleted_at?: Maybe<Timestamptz_Comparison_Exp>;
  id?: Maybe<Uuid_Comparison_Exp>;
  tenant?: Maybe<Tenants_Bool_Exp>;
  tenant_id?: Maybe<Uuid_Comparison_Exp>;
  updated_at?: Maybe<Timestamptz_Comparison_Exp>;
  user?: Maybe<Users_Bool_Exp>;
  user_id?: Maybe<Uuid_Comparison_Exp>;
};

/** unique or primary key constraints on table "tenant_users" */
export enum Tenant_Users_Constraint {
  /** unique or primary key constraint on columns "id" */
  TenantUsersPkey = 'tenant_users_pkey',
  /** unique or primary key constraint on columns "user_id", "tenant_id" */
  TenantUsersTenantIdUserIdKey = 'tenant_users_tenant_id_user_id_key'
}

/** input type for inserting data into table "tenant_users" */
export type Tenant_Users_Insert_Input = {
  created_at?: Maybe<Scalars['timestamptz']>;
  deleted_at?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['uuid']>;
  tenant?: Maybe<Tenants_Obj_Rel_Insert_Input>;
  tenant_id?: Maybe<Scalars['uuid']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
  user?: Maybe<Users_Obj_Rel_Insert_Input>;
  user_id?: Maybe<Scalars['uuid']>;
};

/** aggregate max on columns */
export type Tenant_Users_Max_Fields = {
  __typename?: 'tenant_users_max_fields';
  created_at?: Maybe<Scalars['timestamptz']>;
  deleted_at?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['uuid']>;
  tenant_id?: Maybe<Scalars['uuid']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
  user_id?: Maybe<Scalars['uuid']>;
};

/** order by max() on columns of table "tenant_users" */
export type Tenant_Users_Max_Order_By = {
  created_at?: Maybe<Order_By>;
  deleted_at?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  tenant_id?: Maybe<Order_By>;
  updated_at?: Maybe<Order_By>;
  user_id?: Maybe<Order_By>;
};

/** aggregate min on columns */
export type Tenant_Users_Min_Fields = {
  __typename?: 'tenant_users_min_fields';
  created_at?: Maybe<Scalars['timestamptz']>;
  deleted_at?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['uuid']>;
  tenant_id?: Maybe<Scalars['uuid']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
  user_id?: Maybe<Scalars['uuid']>;
};

/** order by min() on columns of table "tenant_users" */
export type Tenant_Users_Min_Order_By = {
  created_at?: Maybe<Order_By>;
  deleted_at?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  tenant_id?: Maybe<Order_By>;
  updated_at?: Maybe<Order_By>;
  user_id?: Maybe<Order_By>;
};

/** response of any mutation on the table "tenant_users" */
export type Tenant_Users_Mutation_Response = {
  __typename?: 'tenant_users_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Tenant_Users>;
};

/** on_conflict condition type for table "tenant_users" */
export type Tenant_Users_On_Conflict = {
  constraint: Tenant_Users_Constraint;
  update_columns?: Array<Tenant_Users_Update_Column>;
  where?: Maybe<Tenant_Users_Bool_Exp>;
};

/** Ordering options when selecting data from "tenant_users". */
export type Tenant_Users_Order_By = {
  created_at?: Maybe<Order_By>;
  deleted_at?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  tenant?: Maybe<Tenants_Order_By>;
  tenant_id?: Maybe<Order_By>;
  updated_at?: Maybe<Order_By>;
  user?: Maybe<Users_Order_By>;
  user_id?: Maybe<Order_By>;
};

/** primary key columns input for table: tenant_users */
export type Tenant_Users_Pk_Columns_Input = {
  id: Scalars['uuid'];
};

/** select columns of table "tenant_users" */
export enum Tenant_Users_Select_Column {
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  DeletedAt = 'deleted_at',
  /** column name */
  Id = 'id',
  /** column name */
  TenantId = 'tenant_id',
  /** column name */
  UpdatedAt = 'updated_at',
  /** column name */
  UserId = 'user_id'
}

/** input type for updating data in table "tenant_users" */
export type Tenant_Users_Set_Input = {
  created_at?: Maybe<Scalars['timestamptz']>;
  deleted_at?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['uuid']>;
  tenant_id?: Maybe<Scalars['uuid']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
  user_id?: Maybe<Scalars['uuid']>;
};

/** Streaming cursor of the table "tenant_users" */
export type Tenant_Users_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Tenant_Users_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: Maybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Tenant_Users_Stream_Cursor_Value_Input = {
  created_at?: Maybe<Scalars['timestamptz']>;
  deleted_at?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['uuid']>;
  tenant_id?: Maybe<Scalars['uuid']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
  user_id?: Maybe<Scalars['uuid']>;
};

/** update columns of table "tenant_users" */
export enum Tenant_Users_Update_Column {
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  DeletedAt = 'deleted_at',
  /** column name */
  Id = 'id',
  /** column name */
  TenantId = 'tenant_id',
  /** column name */
  UpdatedAt = 'updated_at',
  /** column name */
  UserId = 'user_id'
}

export type Tenant_Users_Updates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: Maybe<Tenant_Users_Set_Input>;
  /** filter the rows which have to be updated */
  where: Tenant_Users_Bool_Exp;
};

/** columns and relationships of "tenants" */
export type Tenants = {
  __typename?: 'tenants';
  /** An array relationship */
  candidates: Array<Candidates>;
  /** An aggregate relationship */
  candidates_aggregate: Candidates_Aggregate;
  /** An array relationship */
  clients: Array<Clients>;
  /** An aggregate relationship */
  clients_aggregate: Clients_Aggregate;
  created_at: Scalars['timestamptz'];
  deleted_at?: Maybe<Scalars['timestamptz']>;
  email?: Maybe<Scalars['String']>;
  enabled: Scalars['Boolean'];
  /** An array relationship */
  events: Array<Events>;
  /** An aggregate relationship */
  events_aggregate: Events_Aggregate;
  id: Scalars['uuid'];
  logo?: Maybe<Scalars['String']>;
  logo_inverse?: Maybe<Scalars['String']>;
  name: Scalars['String'];
  overview?: Maybe<Scalars['String']>;
  overview_extended?: Maybe<Scalars['String']>;
  /** An object relationship */
  owner?: Maybe<Users>;
  owner_id?: Maybe<Scalars['String']>;
  phone?: Maybe<Scalars['String']>;
  public?: Maybe<Scalars['Boolean']>;
  shortname: Scalars['String'];
  staff_count?: Maybe<Scalars['Int']>;
  staff_count_max?: Maybe<Scalars['Int']>;
  /** An array relationship */
  tenant_candidates: Array<Tenant_Candidates>;
  /** An aggregate relationship */
  tenant_candidates_aggregate: Tenant_Candidates_Aggregate;
  /** An array relationship */
  tenant_locations: Array<Tenant_Locations>;
  /** An aggregate relationship */
  tenant_locations_aggregate: Tenant_Locations_Aggregate;
  /** An array relationship */
  tenant_users: Array<Tenant_Users>;
  /** An aggregate relationship */
  tenant_users_aggregate: Tenant_Users_Aggregate;
  terms_url?: Maybe<Scalars['String']>;
  test?: Maybe<Scalars['Boolean']>;
  theme_bg_color?: Maybe<Scalars['String']>;
  updated_at: Scalars['timestamptz'];
  website?: Maybe<Scalars['String']>;
  year_founded: Scalars['timestamptz'];
};


/** columns and relationships of "tenants" */
export type TenantsCandidatesArgs = {
  distinct_on?: Maybe<Array<Candidates_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Candidates_Order_By>>;
  where?: Maybe<Candidates_Bool_Exp>;
};


/** columns and relationships of "tenants" */
export type TenantsCandidates_AggregateArgs = {
  distinct_on?: Maybe<Array<Candidates_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Candidates_Order_By>>;
  where?: Maybe<Candidates_Bool_Exp>;
};


/** columns and relationships of "tenants" */
export type TenantsClientsArgs = {
  distinct_on?: Maybe<Array<Clients_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Clients_Order_By>>;
  where?: Maybe<Clients_Bool_Exp>;
};


/** columns and relationships of "tenants" */
export type TenantsClients_AggregateArgs = {
  distinct_on?: Maybe<Array<Clients_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Clients_Order_By>>;
  where?: Maybe<Clients_Bool_Exp>;
};


/** columns and relationships of "tenants" */
export type TenantsEventsArgs = {
  distinct_on?: Maybe<Array<Events_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Events_Order_By>>;
  where?: Maybe<Events_Bool_Exp>;
};


/** columns and relationships of "tenants" */
export type TenantsEvents_AggregateArgs = {
  distinct_on?: Maybe<Array<Events_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Events_Order_By>>;
  where?: Maybe<Events_Bool_Exp>;
};


/** columns and relationships of "tenants" */
export type TenantsTenant_CandidatesArgs = {
  distinct_on?: Maybe<Array<Tenant_Candidates_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Tenant_Candidates_Order_By>>;
  where?: Maybe<Tenant_Candidates_Bool_Exp>;
};


/** columns and relationships of "tenants" */
export type TenantsTenant_Candidates_AggregateArgs = {
  distinct_on?: Maybe<Array<Tenant_Candidates_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Tenant_Candidates_Order_By>>;
  where?: Maybe<Tenant_Candidates_Bool_Exp>;
};


/** columns and relationships of "tenants" */
export type TenantsTenant_LocationsArgs = {
  distinct_on?: Maybe<Array<Tenant_Locations_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Tenant_Locations_Order_By>>;
  where?: Maybe<Tenant_Locations_Bool_Exp>;
};


/** columns and relationships of "tenants" */
export type TenantsTenant_Locations_AggregateArgs = {
  distinct_on?: Maybe<Array<Tenant_Locations_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Tenant_Locations_Order_By>>;
  where?: Maybe<Tenant_Locations_Bool_Exp>;
};


/** columns and relationships of "tenants" */
export type TenantsTenant_UsersArgs = {
  distinct_on?: Maybe<Array<Tenant_Users_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Tenant_Users_Order_By>>;
  where?: Maybe<Tenant_Users_Bool_Exp>;
};


/** columns and relationships of "tenants" */
export type TenantsTenant_Users_AggregateArgs = {
  distinct_on?: Maybe<Array<Tenant_Users_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Tenant_Users_Order_By>>;
  where?: Maybe<Tenant_Users_Bool_Exp>;
};

/** aggregated selection of "tenants" */
export type Tenants_Aggregate = {
  __typename?: 'tenants_aggregate';
  aggregate?: Maybe<Tenants_Aggregate_Fields>;
  nodes: Array<Tenants>;
};

export type Tenants_Aggregate_Bool_Exp = {
  bool_and?: Maybe<Tenants_Aggregate_Bool_Exp_Bool_And>;
  bool_or?: Maybe<Tenants_Aggregate_Bool_Exp_Bool_Or>;
  count?: Maybe<Tenants_Aggregate_Bool_Exp_Count>;
};

export type Tenants_Aggregate_Bool_Exp_Bool_And = {
  arguments: Tenants_Select_Column_Tenants_Aggregate_Bool_Exp_Bool_And_Arguments_Columns;
  distinct?: Maybe<Scalars['Boolean']>;
  filter?: Maybe<Tenants_Bool_Exp>;
  predicate: Boolean_Comparison_Exp;
};

export type Tenants_Aggregate_Bool_Exp_Bool_Or = {
  arguments: Tenants_Select_Column_Tenants_Aggregate_Bool_Exp_Bool_Or_Arguments_Columns;
  distinct?: Maybe<Scalars['Boolean']>;
  filter?: Maybe<Tenants_Bool_Exp>;
  predicate: Boolean_Comparison_Exp;
};

export type Tenants_Aggregate_Bool_Exp_Count = {
  arguments?: Maybe<Array<Tenants_Select_Column>>;
  distinct?: Maybe<Scalars['Boolean']>;
  filter?: Maybe<Tenants_Bool_Exp>;
  predicate: Int_Comparison_Exp;
};

/** aggregate fields of "tenants" */
export type Tenants_Aggregate_Fields = {
  __typename?: 'tenants_aggregate_fields';
  avg?: Maybe<Tenants_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<Tenants_Max_Fields>;
  min?: Maybe<Tenants_Min_Fields>;
  stddev?: Maybe<Tenants_Stddev_Fields>;
  stddev_pop?: Maybe<Tenants_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Tenants_Stddev_Samp_Fields>;
  sum?: Maybe<Tenants_Sum_Fields>;
  var_pop?: Maybe<Tenants_Var_Pop_Fields>;
  var_samp?: Maybe<Tenants_Var_Samp_Fields>;
  variance?: Maybe<Tenants_Variance_Fields>;
};


/** aggregate fields of "tenants" */
export type Tenants_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<Tenants_Select_Column>>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "tenants" */
export type Tenants_Aggregate_Order_By = {
  avg?: Maybe<Tenants_Avg_Order_By>;
  count?: Maybe<Order_By>;
  max?: Maybe<Tenants_Max_Order_By>;
  min?: Maybe<Tenants_Min_Order_By>;
  stddev?: Maybe<Tenants_Stddev_Order_By>;
  stddev_pop?: Maybe<Tenants_Stddev_Pop_Order_By>;
  stddev_samp?: Maybe<Tenants_Stddev_Samp_Order_By>;
  sum?: Maybe<Tenants_Sum_Order_By>;
  var_pop?: Maybe<Tenants_Var_Pop_Order_By>;
  var_samp?: Maybe<Tenants_Var_Samp_Order_By>;
  variance?: Maybe<Tenants_Variance_Order_By>;
};

/** input type for inserting array relation for remote table "tenants" */
export type Tenants_Arr_Rel_Insert_Input = {
  data: Array<Tenants_Insert_Input>;
  /** upsert condition */
  on_conflict?: Maybe<Tenants_On_Conflict>;
};

/** aggregate avg on columns */
export type Tenants_Avg_Fields = {
  __typename?: 'tenants_avg_fields';
  staff_count?: Maybe<Scalars['Float']>;
  staff_count_max?: Maybe<Scalars['Float']>;
};

/** order by avg() on columns of table "tenants" */
export type Tenants_Avg_Order_By = {
  staff_count?: Maybe<Order_By>;
  staff_count_max?: Maybe<Order_By>;
};

/** Boolean expression to filter rows from the table "tenants". All fields are combined with a logical 'AND'. */
export type Tenants_Bool_Exp = {
  _and?: Maybe<Array<Tenants_Bool_Exp>>;
  _not?: Maybe<Tenants_Bool_Exp>;
  _or?: Maybe<Array<Tenants_Bool_Exp>>;
  candidates?: Maybe<Candidates_Bool_Exp>;
  candidates_aggregate?: Maybe<Candidates_Aggregate_Bool_Exp>;
  clients?: Maybe<Clients_Bool_Exp>;
  clients_aggregate?: Maybe<Clients_Aggregate_Bool_Exp>;
  created_at?: Maybe<Timestamptz_Comparison_Exp>;
  deleted_at?: Maybe<Timestamptz_Comparison_Exp>;
  email?: Maybe<String_Comparison_Exp>;
  enabled?: Maybe<Boolean_Comparison_Exp>;
  events?: Maybe<Events_Bool_Exp>;
  events_aggregate?: Maybe<Events_Aggregate_Bool_Exp>;
  id?: Maybe<Uuid_Comparison_Exp>;
  logo?: Maybe<String_Comparison_Exp>;
  logo_inverse?: Maybe<String_Comparison_Exp>;
  name?: Maybe<String_Comparison_Exp>;
  overview?: Maybe<String_Comparison_Exp>;
  overview_extended?: Maybe<String_Comparison_Exp>;
  owner?: Maybe<Users_Bool_Exp>;
  owner_id?: Maybe<String_Comparison_Exp>;
  phone?: Maybe<String_Comparison_Exp>;
  public?: Maybe<Boolean_Comparison_Exp>;
  shortname?: Maybe<String_Comparison_Exp>;
  staff_count?: Maybe<Int_Comparison_Exp>;
  staff_count_max?: Maybe<Int_Comparison_Exp>;
  tenant_candidates?: Maybe<Tenant_Candidates_Bool_Exp>;
  tenant_candidates_aggregate?: Maybe<Tenant_Candidates_Aggregate_Bool_Exp>;
  tenant_locations?: Maybe<Tenant_Locations_Bool_Exp>;
  tenant_locations_aggregate?: Maybe<Tenant_Locations_Aggregate_Bool_Exp>;
  tenant_users?: Maybe<Tenant_Users_Bool_Exp>;
  tenant_users_aggregate?: Maybe<Tenant_Users_Aggregate_Bool_Exp>;
  terms_url?: Maybe<String_Comparison_Exp>;
  test?: Maybe<Boolean_Comparison_Exp>;
  theme_bg_color?: Maybe<String_Comparison_Exp>;
  updated_at?: Maybe<Timestamptz_Comparison_Exp>;
  website?: Maybe<String_Comparison_Exp>;
  year_founded?: Maybe<Timestamptz_Comparison_Exp>;
};

/** unique or primary key constraints on table "tenants" */
export enum Tenants_Constraint {
  /** unique or primary key constraint on columns "id" */
  TenantsPkey = 'tenants_pkey',
  /** unique or primary key constraint on columns "shortname" */
  TenantsShortnameKey = 'tenants_shortname_key'
}

/** input type for incrementing numeric columns in table "tenants" */
export type Tenants_Inc_Input = {
  staff_count?: Maybe<Scalars['Int']>;
  staff_count_max?: Maybe<Scalars['Int']>;
};

/** input type for inserting data into table "tenants" */
export type Tenants_Insert_Input = {
  candidates?: Maybe<Candidates_Arr_Rel_Insert_Input>;
  clients?: Maybe<Clients_Arr_Rel_Insert_Input>;
  created_at?: Maybe<Scalars['timestamptz']>;
  deleted_at?: Maybe<Scalars['timestamptz']>;
  email?: Maybe<Scalars['String']>;
  enabled?: Maybe<Scalars['Boolean']>;
  events?: Maybe<Events_Arr_Rel_Insert_Input>;
  id?: Maybe<Scalars['uuid']>;
  logo?: Maybe<Scalars['String']>;
  logo_inverse?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  overview?: Maybe<Scalars['String']>;
  overview_extended?: Maybe<Scalars['String']>;
  owner?: Maybe<Users_Obj_Rel_Insert_Input>;
  owner_id?: Maybe<Scalars['String']>;
  phone?: Maybe<Scalars['String']>;
  public?: Maybe<Scalars['Boolean']>;
  shortname?: Maybe<Scalars['String']>;
  staff_count?: Maybe<Scalars['Int']>;
  staff_count_max?: Maybe<Scalars['Int']>;
  tenant_candidates?: Maybe<Tenant_Candidates_Arr_Rel_Insert_Input>;
  tenant_locations?: Maybe<Tenant_Locations_Arr_Rel_Insert_Input>;
  tenant_users?: Maybe<Tenant_Users_Arr_Rel_Insert_Input>;
  terms_url?: Maybe<Scalars['String']>;
  test?: Maybe<Scalars['Boolean']>;
  theme_bg_color?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
  website?: Maybe<Scalars['String']>;
  year_founded?: Maybe<Scalars['timestamptz']>;
};

/** aggregate max on columns */
export type Tenants_Max_Fields = {
  __typename?: 'tenants_max_fields';
  created_at?: Maybe<Scalars['timestamptz']>;
  deleted_at?: Maybe<Scalars['timestamptz']>;
  email?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['uuid']>;
  logo?: Maybe<Scalars['String']>;
  logo_inverse?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  overview?: Maybe<Scalars['String']>;
  overview_extended?: Maybe<Scalars['String']>;
  owner_id?: Maybe<Scalars['String']>;
  phone?: Maybe<Scalars['String']>;
  shortname?: Maybe<Scalars['String']>;
  staff_count?: Maybe<Scalars['Int']>;
  staff_count_max?: Maybe<Scalars['Int']>;
  terms_url?: Maybe<Scalars['String']>;
  theme_bg_color?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
  website?: Maybe<Scalars['String']>;
  year_founded?: Maybe<Scalars['timestamptz']>;
};

/** order by max() on columns of table "tenants" */
export type Tenants_Max_Order_By = {
  created_at?: Maybe<Order_By>;
  deleted_at?: Maybe<Order_By>;
  email?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  logo?: Maybe<Order_By>;
  logo_inverse?: Maybe<Order_By>;
  name?: Maybe<Order_By>;
  overview?: Maybe<Order_By>;
  overview_extended?: Maybe<Order_By>;
  owner_id?: Maybe<Order_By>;
  phone?: Maybe<Order_By>;
  shortname?: Maybe<Order_By>;
  staff_count?: Maybe<Order_By>;
  staff_count_max?: Maybe<Order_By>;
  terms_url?: Maybe<Order_By>;
  theme_bg_color?: Maybe<Order_By>;
  updated_at?: Maybe<Order_By>;
  website?: Maybe<Order_By>;
  year_founded?: Maybe<Order_By>;
};

/** aggregate min on columns */
export type Tenants_Min_Fields = {
  __typename?: 'tenants_min_fields';
  created_at?: Maybe<Scalars['timestamptz']>;
  deleted_at?: Maybe<Scalars['timestamptz']>;
  email?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['uuid']>;
  logo?: Maybe<Scalars['String']>;
  logo_inverse?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  overview?: Maybe<Scalars['String']>;
  overview_extended?: Maybe<Scalars['String']>;
  owner_id?: Maybe<Scalars['String']>;
  phone?: Maybe<Scalars['String']>;
  shortname?: Maybe<Scalars['String']>;
  staff_count?: Maybe<Scalars['Int']>;
  staff_count_max?: Maybe<Scalars['Int']>;
  terms_url?: Maybe<Scalars['String']>;
  theme_bg_color?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
  website?: Maybe<Scalars['String']>;
  year_founded?: Maybe<Scalars['timestamptz']>;
};

/** order by min() on columns of table "tenants" */
export type Tenants_Min_Order_By = {
  created_at?: Maybe<Order_By>;
  deleted_at?: Maybe<Order_By>;
  email?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  logo?: Maybe<Order_By>;
  logo_inverse?: Maybe<Order_By>;
  name?: Maybe<Order_By>;
  overview?: Maybe<Order_By>;
  overview_extended?: Maybe<Order_By>;
  owner_id?: Maybe<Order_By>;
  phone?: Maybe<Order_By>;
  shortname?: Maybe<Order_By>;
  staff_count?: Maybe<Order_By>;
  staff_count_max?: Maybe<Order_By>;
  terms_url?: Maybe<Order_By>;
  theme_bg_color?: Maybe<Order_By>;
  updated_at?: Maybe<Order_By>;
  website?: Maybe<Order_By>;
  year_founded?: Maybe<Order_By>;
};

/** response of any mutation on the table "tenants" */
export type Tenants_Mutation_Response = {
  __typename?: 'tenants_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Tenants>;
};

/** input type for inserting object relation for remote table "tenants" */
export type Tenants_Obj_Rel_Insert_Input = {
  data: Tenants_Insert_Input;
  /** upsert condition */
  on_conflict?: Maybe<Tenants_On_Conflict>;
};

/** on_conflict condition type for table "tenants" */
export type Tenants_On_Conflict = {
  constraint: Tenants_Constraint;
  update_columns?: Array<Tenants_Update_Column>;
  where?: Maybe<Tenants_Bool_Exp>;
};

/** Ordering options when selecting data from "tenants". */
export type Tenants_Order_By = {
  candidates_aggregate?: Maybe<Candidates_Aggregate_Order_By>;
  clients_aggregate?: Maybe<Clients_Aggregate_Order_By>;
  created_at?: Maybe<Order_By>;
  deleted_at?: Maybe<Order_By>;
  email?: Maybe<Order_By>;
  enabled?: Maybe<Order_By>;
  events_aggregate?: Maybe<Events_Aggregate_Order_By>;
  id?: Maybe<Order_By>;
  logo?: Maybe<Order_By>;
  logo_inverse?: Maybe<Order_By>;
  name?: Maybe<Order_By>;
  overview?: Maybe<Order_By>;
  overview_extended?: Maybe<Order_By>;
  owner?: Maybe<Users_Order_By>;
  owner_id?: Maybe<Order_By>;
  phone?: Maybe<Order_By>;
  public?: Maybe<Order_By>;
  shortname?: Maybe<Order_By>;
  staff_count?: Maybe<Order_By>;
  staff_count_max?: Maybe<Order_By>;
  tenant_candidates_aggregate?: Maybe<Tenant_Candidates_Aggregate_Order_By>;
  tenant_locations_aggregate?: Maybe<Tenant_Locations_Aggregate_Order_By>;
  tenant_users_aggregate?: Maybe<Tenant_Users_Aggregate_Order_By>;
  terms_url?: Maybe<Order_By>;
  test?: Maybe<Order_By>;
  theme_bg_color?: Maybe<Order_By>;
  updated_at?: Maybe<Order_By>;
  website?: Maybe<Order_By>;
  year_founded?: Maybe<Order_By>;
};

/** primary key columns input for table: tenants */
export type Tenants_Pk_Columns_Input = {
  id: Scalars['uuid'];
};

/** select columns of table "tenants" */
export enum Tenants_Select_Column {
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  DeletedAt = 'deleted_at',
  /** column name */
  Email = 'email',
  /** column name */
  Enabled = 'enabled',
  /** column name */
  Id = 'id',
  /** column name */
  Logo = 'logo',
  /** column name */
  LogoInverse = 'logo_inverse',
  /** column name */
  Name = 'name',
  /** column name */
  Overview = 'overview',
  /** column name */
  OverviewExtended = 'overview_extended',
  /** column name */
  OwnerId = 'owner_id',
  /** column name */
  Phone = 'phone',
  /** column name */
  Public = 'public',
  /** column name */
  Shortname = 'shortname',
  /** column name */
  StaffCount = 'staff_count',
  /** column name */
  StaffCountMax = 'staff_count_max',
  /** column name */
  TermsUrl = 'terms_url',
  /** column name */
  Test = 'test',
  /** column name */
  ThemeBgColor = 'theme_bg_color',
  /** column name */
  UpdatedAt = 'updated_at',
  /** column name */
  Website = 'website',
  /** column name */
  YearFounded = 'year_founded'
}

/** select "tenants_aggregate_bool_exp_bool_and_arguments_columns" columns of table "tenants" */
export enum Tenants_Select_Column_Tenants_Aggregate_Bool_Exp_Bool_And_Arguments_Columns {
  /** column name */
  Enabled = 'enabled',
  /** column name */
  Public = 'public',
  /** column name */
  Test = 'test'
}

/** select "tenants_aggregate_bool_exp_bool_or_arguments_columns" columns of table "tenants" */
export enum Tenants_Select_Column_Tenants_Aggregate_Bool_Exp_Bool_Or_Arguments_Columns {
  /** column name */
  Enabled = 'enabled',
  /** column name */
  Public = 'public',
  /** column name */
  Test = 'test'
}

/** input type for updating data in table "tenants" */
export type Tenants_Set_Input = {
  created_at?: Maybe<Scalars['timestamptz']>;
  deleted_at?: Maybe<Scalars['timestamptz']>;
  email?: Maybe<Scalars['String']>;
  enabled?: Maybe<Scalars['Boolean']>;
  id?: Maybe<Scalars['uuid']>;
  logo?: Maybe<Scalars['String']>;
  logo_inverse?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  overview?: Maybe<Scalars['String']>;
  overview_extended?: Maybe<Scalars['String']>;
  owner_id?: Maybe<Scalars['String']>;
  phone?: Maybe<Scalars['String']>;
  public?: Maybe<Scalars['Boolean']>;
  shortname?: Maybe<Scalars['String']>;
  staff_count?: Maybe<Scalars['Int']>;
  staff_count_max?: Maybe<Scalars['Int']>;
  terms_url?: Maybe<Scalars['String']>;
  test?: Maybe<Scalars['Boolean']>;
  theme_bg_color?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
  website?: Maybe<Scalars['String']>;
  year_founded?: Maybe<Scalars['timestamptz']>;
};

/** aggregate stddev on columns */
export type Tenants_Stddev_Fields = {
  __typename?: 'tenants_stddev_fields';
  staff_count?: Maybe<Scalars['Float']>;
  staff_count_max?: Maybe<Scalars['Float']>;
};

/** order by stddev() on columns of table "tenants" */
export type Tenants_Stddev_Order_By = {
  staff_count?: Maybe<Order_By>;
  staff_count_max?: Maybe<Order_By>;
};

/** aggregate stddev_pop on columns */
export type Tenants_Stddev_Pop_Fields = {
  __typename?: 'tenants_stddev_pop_fields';
  staff_count?: Maybe<Scalars['Float']>;
  staff_count_max?: Maybe<Scalars['Float']>;
};

/** order by stddev_pop() on columns of table "tenants" */
export type Tenants_Stddev_Pop_Order_By = {
  staff_count?: Maybe<Order_By>;
  staff_count_max?: Maybe<Order_By>;
};

/** aggregate stddev_samp on columns */
export type Tenants_Stddev_Samp_Fields = {
  __typename?: 'tenants_stddev_samp_fields';
  staff_count?: Maybe<Scalars['Float']>;
  staff_count_max?: Maybe<Scalars['Float']>;
};

/** order by stddev_samp() on columns of table "tenants" */
export type Tenants_Stddev_Samp_Order_By = {
  staff_count?: Maybe<Order_By>;
  staff_count_max?: Maybe<Order_By>;
};

/** Streaming cursor of the table "tenants" */
export type Tenants_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Tenants_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: Maybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Tenants_Stream_Cursor_Value_Input = {
  created_at?: Maybe<Scalars['timestamptz']>;
  deleted_at?: Maybe<Scalars['timestamptz']>;
  email?: Maybe<Scalars['String']>;
  enabled?: Maybe<Scalars['Boolean']>;
  id?: Maybe<Scalars['uuid']>;
  logo?: Maybe<Scalars['String']>;
  logo_inverse?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  overview?: Maybe<Scalars['String']>;
  overview_extended?: Maybe<Scalars['String']>;
  owner_id?: Maybe<Scalars['String']>;
  phone?: Maybe<Scalars['String']>;
  public?: Maybe<Scalars['Boolean']>;
  shortname?: Maybe<Scalars['String']>;
  staff_count?: Maybe<Scalars['Int']>;
  staff_count_max?: Maybe<Scalars['Int']>;
  terms_url?: Maybe<Scalars['String']>;
  test?: Maybe<Scalars['Boolean']>;
  theme_bg_color?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
  website?: Maybe<Scalars['String']>;
  year_founded?: Maybe<Scalars['timestamptz']>;
};

/** aggregate sum on columns */
export type Tenants_Sum_Fields = {
  __typename?: 'tenants_sum_fields';
  staff_count?: Maybe<Scalars['Int']>;
  staff_count_max?: Maybe<Scalars['Int']>;
};

/** order by sum() on columns of table "tenants" */
export type Tenants_Sum_Order_By = {
  staff_count?: Maybe<Order_By>;
  staff_count_max?: Maybe<Order_By>;
};

/** update columns of table "tenants" */
export enum Tenants_Update_Column {
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  DeletedAt = 'deleted_at',
  /** column name */
  Email = 'email',
  /** column name */
  Enabled = 'enabled',
  /** column name */
  Id = 'id',
  /** column name */
  Logo = 'logo',
  /** column name */
  LogoInverse = 'logo_inverse',
  /** column name */
  Name = 'name',
  /** column name */
  Overview = 'overview',
  /** column name */
  OverviewExtended = 'overview_extended',
  /** column name */
  OwnerId = 'owner_id',
  /** column name */
  Phone = 'phone',
  /** column name */
  Public = 'public',
  /** column name */
  Shortname = 'shortname',
  /** column name */
  StaffCount = 'staff_count',
  /** column name */
  StaffCountMax = 'staff_count_max',
  /** column name */
  TermsUrl = 'terms_url',
  /** column name */
  Test = 'test',
  /** column name */
  ThemeBgColor = 'theme_bg_color',
  /** column name */
  UpdatedAt = 'updated_at',
  /** column name */
  Website = 'website',
  /** column name */
  YearFounded = 'year_founded'
}

export type Tenants_Updates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: Maybe<Tenants_Inc_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: Maybe<Tenants_Set_Input>;
  /** filter the rows which have to be updated */
  where: Tenants_Bool_Exp;
};

/** aggregate var_pop on columns */
export type Tenants_Var_Pop_Fields = {
  __typename?: 'tenants_var_pop_fields';
  staff_count?: Maybe<Scalars['Float']>;
  staff_count_max?: Maybe<Scalars['Float']>;
};

/** order by var_pop() on columns of table "tenants" */
export type Tenants_Var_Pop_Order_By = {
  staff_count?: Maybe<Order_By>;
  staff_count_max?: Maybe<Order_By>;
};

/** aggregate var_samp on columns */
export type Tenants_Var_Samp_Fields = {
  __typename?: 'tenants_var_samp_fields';
  staff_count?: Maybe<Scalars['Float']>;
  staff_count_max?: Maybe<Scalars['Float']>;
};

/** order by var_samp() on columns of table "tenants" */
export type Tenants_Var_Samp_Order_By = {
  staff_count?: Maybe<Order_By>;
  staff_count_max?: Maybe<Order_By>;
};

/** aggregate variance on columns */
export type Tenants_Variance_Fields = {
  __typename?: 'tenants_variance_fields';
  staff_count?: Maybe<Scalars['Float']>;
  staff_count_max?: Maybe<Scalars['Float']>;
};

/** order by variance() on columns of table "tenants" */
export type Tenants_Variance_Order_By = {
  staff_count?: Maybe<Order_By>;
  staff_count_max?: Maybe<Order_By>;
};


/** Boolean expression to compare columns of type "timestamp". All fields are combined with logical 'AND'. */
export type Timestamp_Comparison_Exp = {
  _eq?: Maybe<Scalars['timestamp']>;
  _gt?: Maybe<Scalars['timestamp']>;
  _gte?: Maybe<Scalars['timestamp']>;
  _in?: Maybe<Array<Scalars['timestamp']>>;
  _is_null?: Maybe<Scalars['Boolean']>;
  _lt?: Maybe<Scalars['timestamp']>;
  _lte?: Maybe<Scalars['timestamp']>;
  _neq?: Maybe<Scalars['timestamp']>;
  _nin?: Maybe<Array<Scalars['timestamp']>>;
};


/** Boolean expression to compare columns of type "timestamptz". All fields are combined with logical 'AND'. */
export type Timestamptz_Comparison_Exp = {
  _eq?: Maybe<Scalars['timestamptz']>;
  _gt?: Maybe<Scalars['timestamptz']>;
  _gte?: Maybe<Scalars['timestamptz']>;
  _in?: Maybe<Array<Scalars['timestamptz']>>;
  _is_null?: Maybe<Scalars['Boolean']>;
  _lt?: Maybe<Scalars['timestamptz']>;
  _lte?: Maybe<Scalars['timestamptz']>;
  _neq?: Maybe<Scalars['timestamptz']>;
  _nin?: Maybe<Array<Scalars['timestamptz']>>;
};

/** columns and relationships of "universities" */
export type Universities = {
  __typename?: 'universities';
  /** An array relationship */
  candidates: Array<Candidates>;
  /** An aggregate relationship */
  candidates_aggregate: Candidates_Aggregate;
  country?: Maybe<Scalars['String']>;
  created_at: Scalars['timestamptz'];
  deleted_at?: Maybe<Scalars['timestamptz']>;
  id: Scalars['uuid'];
  name: Scalars['String'];
  short_name?: Maybe<Scalars['String']>;
  state?: Maybe<Scalars['String']>;
  updated_at: Scalars['timestamptz'];
};


/** columns and relationships of "universities" */
export type UniversitiesCandidatesArgs = {
  distinct_on?: Maybe<Array<Candidates_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Candidates_Order_By>>;
  where?: Maybe<Candidates_Bool_Exp>;
};


/** columns and relationships of "universities" */
export type UniversitiesCandidates_AggregateArgs = {
  distinct_on?: Maybe<Array<Candidates_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Candidates_Order_By>>;
  where?: Maybe<Candidates_Bool_Exp>;
};

/** aggregated selection of "universities" */
export type Universities_Aggregate = {
  __typename?: 'universities_aggregate';
  aggregate?: Maybe<Universities_Aggregate_Fields>;
  nodes: Array<Universities>;
};

/** aggregate fields of "universities" */
export type Universities_Aggregate_Fields = {
  __typename?: 'universities_aggregate_fields';
  count: Scalars['Int'];
  max?: Maybe<Universities_Max_Fields>;
  min?: Maybe<Universities_Min_Fields>;
};


/** aggregate fields of "universities" */
export type Universities_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<Universities_Select_Column>>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** Boolean expression to filter rows from the table "universities". All fields are combined with a logical 'AND'. */
export type Universities_Bool_Exp = {
  _and?: Maybe<Array<Universities_Bool_Exp>>;
  _not?: Maybe<Universities_Bool_Exp>;
  _or?: Maybe<Array<Universities_Bool_Exp>>;
  candidates?: Maybe<Candidates_Bool_Exp>;
  candidates_aggregate?: Maybe<Candidates_Aggregate_Bool_Exp>;
  country?: Maybe<String_Comparison_Exp>;
  created_at?: Maybe<Timestamptz_Comparison_Exp>;
  deleted_at?: Maybe<Timestamptz_Comparison_Exp>;
  id?: Maybe<Uuid_Comparison_Exp>;
  name?: Maybe<String_Comparison_Exp>;
  short_name?: Maybe<String_Comparison_Exp>;
  state?: Maybe<String_Comparison_Exp>;
  updated_at?: Maybe<Timestamptz_Comparison_Exp>;
};

/** unique or primary key constraints on table "universities" */
export enum Universities_Constraint {
  /** unique or primary key constraint on columns "id" */
  UniversitiesPkey = 'universities_pkey'
}

/** input type for inserting data into table "universities" */
export type Universities_Insert_Input = {
  candidates?: Maybe<Candidates_Arr_Rel_Insert_Input>;
  country?: Maybe<Scalars['String']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  deleted_at?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['uuid']>;
  name?: Maybe<Scalars['String']>;
  short_name?: Maybe<Scalars['String']>;
  state?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** aggregate max on columns */
export type Universities_Max_Fields = {
  __typename?: 'universities_max_fields';
  country?: Maybe<Scalars['String']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  deleted_at?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['uuid']>;
  name?: Maybe<Scalars['String']>;
  short_name?: Maybe<Scalars['String']>;
  state?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** aggregate min on columns */
export type Universities_Min_Fields = {
  __typename?: 'universities_min_fields';
  country?: Maybe<Scalars['String']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  deleted_at?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['uuid']>;
  name?: Maybe<Scalars['String']>;
  short_name?: Maybe<Scalars['String']>;
  state?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** response of any mutation on the table "universities" */
export type Universities_Mutation_Response = {
  __typename?: 'universities_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Universities>;
};

/** input type for inserting object relation for remote table "universities" */
export type Universities_Obj_Rel_Insert_Input = {
  data: Universities_Insert_Input;
  /** upsert condition */
  on_conflict?: Maybe<Universities_On_Conflict>;
};

/** on_conflict condition type for table "universities" */
export type Universities_On_Conflict = {
  constraint: Universities_Constraint;
  update_columns?: Array<Universities_Update_Column>;
  where?: Maybe<Universities_Bool_Exp>;
};

/** Ordering options when selecting data from "universities". */
export type Universities_Order_By = {
  candidates_aggregate?: Maybe<Candidates_Aggregate_Order_By>;
  country?: Maybe<Order_By>;
  created_at?: Maybe<Order_By>;
  deleted_at?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  name?: Maybe<Order_By>;
  short_name?: Maybe<Order_By>;
  state?: Maybe<Order_By>;
  updated_at?: Maybe<Order_By>;
};

/** primary key columns input for table: universities */
export type Universities_Pk_Columns_Input = {
  id: Scalars['uuid'];
};

/** select columns of table "universities" */
export enum Universities_Select_Column {
  /** column name */
  Country = 'country',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  DeletedAt = 'deleted_at',
  /** column name */
  Id = 'id',
  /** column name */
  Name = 'name',
  /** column name */
  ShortName = 'short_name',
  /** column name */
  State = 'state',
  /** column name */
  UpdatedAt = 'updated_at'
}

/** input type for updating data in table "universities" */
export type Universities_Set_Input = {
  country?: Maybe<Scalars['String']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  deleted_at?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['uuid']>;
  name?: Maybe<Scalars['String']>;
  short_name?: Maybe<Scalars['String']>;
  state?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** Streaming cursor of the table "universities" */
export type Universities_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Universities_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: Maybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Universities_Stream_Cursor_Value_Input = {
  country?: Maybe<Scalars['String']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  deleted_at?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['uuid']>;
  name?: Maybe<Scalars['String']>;
  short_name?: Maybe<Scalars['String']>;
  state?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** update columns of table "universities" */
export enum Universities_Update_Column {
  /** column name */
  Country = 'country',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  DeletedAt = 'deleted_at',
  /** column name */
  Id = 'id',
  /** column name */
  Name = 'name',
  /** column name */
  ShortName = 'short_name',
  /** column name */
  State = 'state',
  /** column name */
  UpdatedAt = 'updated_at'
}

export type Universities_Updates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: Maybe<Universities_Set_Input>;
  /** filter the rows which have to be updated */
  where: Universities_Bool_Exp;
};


/** Boolean expression to compare columns of type "user_role_enums". All fields are combined with logical 'AND'. */
export type User_Role_Enums_Comparison_Exp = {
  _eq?: Maybe<Scalars['user_role_enums']>;
  _gt?: Maybe<Scalars['user_role_enums']>;
  _gte?: Maybe<Scalars['user_role_enums']>;
  _in?: Maybe<Array<Scalars['user_role_enums']>>;
  _is_null?: Maybe<Scalars['Boolean']>;
  _lt?: Maybe<Scalars['user_role_enums']>;
  _lte?: Maybe<Scalars['user_role_enums']>;
  _neq?: Maybe<Scalars['user_role_enums']>;
  _nin?: Maybe<Array<Scalars['user_role_enums']>>;
};

/** columns and relationships of "users" */
export type Users = {
  __typename?: 'users';
  auth0_uid: Scalars['String'];
  batch_id?: Maybe<Scalars['timestamp']>;
  /** An array relationship */
  brimstone_event_feedbacks: Array<Brimstone_Event_Feedback>;
  /** An aggregate relationship */
  brimstone_event_feedbacks_aggregate: Brimstone_Event_Feedback_Aggregate;
  /** An array relationship */
  candidates: Array<Candidates>;
  /** An aggregate relationship */
  candidates_aggregate: Candidates_Aggregate;
  /** An array relationship */
  client_users: Array<Client_Users>;
  /** An aggregate relationship */
  client_users_aggregate: Client_Users_Aggregate;
  /** An array relationship */
  clients: Array<Clients>;
  /** An aggregate relationship */
  clients_aggregate: Clients_Aggregate;
  /** An array relationship */
  contacts: Array<Contacts>;
  /** An aggregate relationship */
  contacts_aggregate: Contacts_Aggregate;
  created_at: Scalars['timestamptz'];
  deleted_at?: Maybe<Scalars['timestamptz']>;
  email?: Maybe<Scalars['String']>;
  /** An array relationship */
  enterprises: Array<Enterprises>;
  /** An aggregate relationship */
  enterprises_aggregate: Enterprises_Aggregate;
  /** An array relationship */
  events: Array<Events>;
  /** An aggregate relationship */
  events_aggregate: Events_Aggregate;
  first_name?: Maybe<Scalars['String']>;
  has_synced?: Maybe<Scalars['Boolean']>;
  id: Scalars['uuid'];
  /** An array relationship */
  interviews: Array<Interviews>;
  /** An aggregate relationship */
  interviews_aggregate: Interviews_Aggregate;
  /** An array relationship */
  jobs: Array<Jobs>;
  /** An aggregate relationship */
  jobs_aggregate: Jobs_Aggregate;
  last_name?: Maybe<Scalars['String']>;
  nylas_access_token?: Maybe<Scalars['String']>;
  nylas_account_id?: Maybe<Scalars['String']>;
  nylas_calendar_id?: Maybe<Scalars['String']>;
  /** An array relationship */
  offers: Array<Offers>;
  /** An aggregate relationship */
  offers_aggregate: Offers_Aggregate;
  phone?: Maybe<Scalars['String']>;
  role: Scalars['user_role_enums'];
  /** An array relationship */
  short_urls: Array<Short_Urls>;
  /** An aggregate relationship */
  short_urls_aggregate: Short_Urls_Aggregate;
  /** An array relationship */
  shortlists: Array<Shortlists>;
  /** An aggregate relationship */
  shortlists_aggregate: Shortlists_Aggregate;
  /** An array relationship */
  tenant_users: Array<Tenant_Users>;
  /** An aggregate relationship */
  tenant_users_aggregate: Tenant_Users_Aggregate;
  /** An array relationship */
  tenants: Array<Tenants>;
  /** An aggregate relationship */
  tenants_aggregate: Tenants_Aggregate;
  test?: Maybe<Scalars['Boolean']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};


/** columns and relationships of "users" */
export type UsersBrimstone_Event_FeedbacksArgs = {
  distinct_on?: Maybe<Array<Brimstone_Event_Feedback_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Brimstone_Event_Feedback_Order_By>>;
  where?: Maybe<Brimstone_Event_Feedback_Bool_Exp>;
};


/** columns and relationships of "users" */
export type UsersBrimstone_Event_Feedbacks_AggregateArgs = {
  distinct_on?: Maybe<Array<Brimstone_Event_Feedback_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Brimstone_Event_Feedback_Order_By>>;
  where?: Maybe<Brimstone_Event_Feedback_Bool_Exp>;
};


/** columns and relationships of "users" */
export type UsersCandidatesArgs = {
  distinct_on?: Maybe<Array<Candidates_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Candidates_Order_By>>;
  where?: Maybe<Candidates_Bool_Exp>;
};


/** columns and relationships of "users" */
export type UsersCandidates_AggregateArgs = {
  distinct_on?: Maybe<Array<Candidates_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Candidates_Order_By>>;
  where?: Maybe<Candidates_Bool_Exp>;
};


/** columns and relationships of "users" */
export type UsersClient_UsersArgs = {
  distinct_on?: Maybe<Array<Client_Users_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Client_Users_Order_By>>;
  where?: Maybe<Client_Users_Bool_Exp>;
};


/** columns and relationships of "users" */
export type UsersClient_Users_AggregateArgs = {
  distinct_on?: Maybe<Array<Client_Users_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Client_Users_Order_By>>;
  where?: Maybe<Client_Users_Bool_Exp>;
};


/** columns and relationships of "users" */
export type UsersClientsArgs = {
  distinct_on?: Maybe<Array<Clients_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Clients_Order_By>>;
  where?: Maybe<Clients_Bool_Exp>;
};


/** columns and relationships of "users" */
export type UsersClients_AggregateArgs = {
  distinct_on?: Maybe<Array<Clients_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Clients_Order_By>>;
  where?: Maybe<Clients_Bool_Exp>;
};


/** columns and relationships of "users" */
export type UsersContactsArgs = {
  distinct_on?: Maybe<Array<Contacts_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Contacts_Order_By>>;
  where?: Maybe<Contacts_Bool_Exp>;
};


/** columns and relationships of "users" */
export type UsersContacts_AggregateArgs = {
  distinct_on?: Maybe<Array<Contacts_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Contacts_Order_By>>;
  where?: Maybe<Contacts_Bool_Exp>;
};


/** columns and relationships of "users" */
export type UsersEnterprisesArgs = {
  distinct_on?: Maybe<Array<Enterprises_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Enterprises_Order_By>>;
  where?: Maybe<Enterprises_Bool_Exp>;
};


/** columns and relationships of "users" */
export type UsersEnterprises_AggregateArgs = {
  distinct_on?: Maybe<Array<Enterprises_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Enterprises_Order_By>>;
  where?: Maybe<Enterprises_Bool_Exp>;
};


/** columns and relationships of "users" */
export type UsersEventsArgs = {
  distinct_on?: Maybe<Array<Events_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Events_Order_By>>;
  where?: Maybe<Events_Bool_Exp>;
};


/** columns and relationships of "users" */
export type UsersEvents_AggregateArgs = {
  distinct_on?: Maybe<Array<Events_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Events_Order_By>>;
  where?: Maybe<Events_Bool_Exp>;
};


/** columns and relationships of "users" */
export type UsersInterviewsArgs = {
  distinct_on?: Maybe<Array<Interviews_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Interviews_Order_By>>;
  where?: Maybe<Interviews_Bool_Exp>;
};


/** columns and relationships of "users" */
export type UsersInterviews_AggregateArgs = {
  distinct_on?: Maybe<Array<Interviews_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Interviews_Order_By>>;
  where?: Maybe<Interviews_Bool_Exp>;
};


/** columns and relationships of "users" */
export type UsersJobsArgs = {
  distinct_on?: Maybe<Array<Jobs_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Jobs_Order_By>>;
  where?: Maybe<Jobs_Bool_Exp>;
};


/** columns and relationships of "users" */
export type UsersJobs_AggregateArgs = {
  distinct_on?: Maybe<Array<Jobs_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Jobs_Order_By>>;
  where?: Maybe<Jobs_Bool_Exp>;
};


/** columns and relationships of "users" */
export type UsersOffersArgs = {
  distinct_on?: Maybe<Array<Offers_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Offers_Order_By>>;
  where?: Maybe<Offers_Bool_Exp>;
};


/** columns and relationships of "users" */
export type UsersOffers_AggregateArgs = {
  distinct_on?: Maybe<Array<Offers_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Offers_Order_By>>;
  where?: Maybe<Offers_Bool_Exp>;
};


/** columns and relationships of "users" */
export type UsersShort_UrlsArgs = {
  distinct_on?: Maybe<Array<Short_Urls_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Short_Urls_Order_By>>;
  where?: Maybe<Short_Urls_Bool_Exp>;
};


/** columns and relationships of "users" */
export type UsersShort_Urls_AggregateArgs = {
  distinct_on?: Maybe<Array<Short_Urls_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Short_Urls_Order_By>>;
  where?: Maybe<Short_Urls_Bool_Exp>;
};


/** columns and relationships of "users" */
export type UsersShortlistsArgs = {
  distinct_on?: Maybe<Array<Shortlists_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Shortlists_Order_By>>;
  where?: Maybe<Shortlists_Bool_Exp>;
};


/** columns and relationships of "users" */
export type UsersShortlists_AggregateArgs = {
  distinct_on?: Maybe<Array<Shortlists_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Shortlists_Order_By>>;
  where?: Maybe<Shortlists_Bool_Exp>;
};


/** columns and relationships of "users" */
export type UsersTenant_UsersArgs = {
  distinct_on?: Maybe<Array<Tenant_Users_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Tenant_Users_Order_By>>;
  where?: Maybe<Tenant_Users_Bool_Exp>;
};


/** columns and relationships of "users" */
export type UsersTenant_Users_AggregateArgs = {
  distinct_on?: Maybe<Array<Tenant_Users_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Tenant_Users_Order_By>>;
  where?: Maybe<Tenant_Users_Bool_Exp>;
};


/** columns and relationships of "users" */
export type UsersTenantsArgs = {
  distinct_on?: Maybe<Array<Tenants_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Tenants_Order_By>>;
  where?: Maybe<Tenants_Bool_Exp>;
};


/** columns and relationships of "users" */
export type UsersTenants_AggregateArgs = {
  distinct_on?: Maybe<Array<Tenants_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Tenants_Order_By>>;
  where?: Maybe<Tenants_Bool_Exp>;
};

/** aggregated selection of "users" */
export type Users_Aggregate = {
  __typename?: 'users_aggregate';
  aggregate?: Maybe<Users_Aggregate_Fields>;
  nodes: Array<Users>;
};

/** aggregate fields of "users" */
export type Users_Aggregate_Fields = {
  __typename?: 'users_aggregate_fields';
  count: Scalars['Int'];
  max?: Maybe<Users_Max_Fields>;
  min?: Maybe<Users_Min_Fields>;
};


/** aggregate fields of "users" */
export type Users_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<Users_Select_Column>>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** Boolean expression to filter rows from the table "users". All fields are combined with a logical 'AND'. */
export type Users_Bool_Exp = {
  _and?: Maybe<Array<Users_Bool_Exp>>;
  _not?: Maybe<Users_Bool_Exp>;
  _or?: Maybe<Array<Users_Bool_Exp>>;
  auth0_uid?: Maybe<String_Comparison_Exp>;
  batch_id?: Maybe<Timestamp_Comparison_Exp>;
  brimstone_event_feedbacks?: Maybe<Brimstone_Event_Feedback_Bool_Exp>;
  brimstone_event_feedbacks_aggregate?: Maybe<Brimstone_Event_Feedback_Aggregate_Bool_Exp>;
  candidates?: Maybe<Candidates_Bool_Exp>;
  candidates_aggregate?: Maybe<Candidates_Aggregate_Bool_Exp>;
  client_users?: Maybe<Client_Users_Bool_Exp>;
  client_users_aggregate?: Maybe<Client_Users_Aggregate_Bool_Exp>;
  clients?: Maybe<Clients_Bool_Exp>;
  clients_aggregate?: Maybe<Clients_Aggregate_Bool_Exp>;
  contacts?: Maybe<Contacts_Bool_Exp>;
  contacts_aggregate?: Maybe<Contacts_Aggregate_Bool_Exp>;
  created_at?: Maybe<Timestamptz_Comparison_Exp>;
  deleted_at?: Maybe<Timestamptz_Comparison_Exp>;
  email?: Maybe<String_Comparison_Exp>;
  enterprises?: Maybe<Enterprises_Bool_Exp>;
  enterprises_aggregate?: Maybe<Enterprises_Aggregate_Bool_Exp>;
  events?: Maybe<Events_Bool_Exp>;
  events_aggregate?: Maybe<Events_Aggregate_Bool_Exp>;
  first_name?: Maybe<String_Comparison_Exp>;
  has_synced?: Maybe<Boolean_Comparison_Exp>;
  id?: Maybe<Uuid_Comparison_Exp>;
  interviews?: Maybe<Interviews_Bool_Exp>;
  interviews_aggregate?: Maybe<Interviews_Aggregate_Bool_Exp>;
  jobs?: Maybe<Jobs_Bool_Exp>;
  jobs_aggregate?: Maybe<Jobs_Aggregate_Bool_Exp>;
  last_name?: Maybe<String_Comparison_Exp>;
  nylas_access_token?: Maybe<String_Comparison_Exp>;
  nylas_account_id?: Maybe<String_Comparison_Exp>;
  nylas_calendar_id?: Maybe<String_Comparison_Exp>;
  offers?: Maybe<Offers_Bool_Exp>;
  offers_aggregate?: Maybe<Offers_Aggregate_Bool_Exp>;
  phone?: Maybe<String_Comparison_Exp>;
  role?: Maybe<User_Role_Enums_Comparison_Exp>;
  short_urls?: Maybe<Short_Urls_Bool_Exp>;
  short_urls_aggregate?: Maybe<Short_Urls_Aggregate_Bool_Exp>;
  shortlists?: Maybe<Shortlists_Bool_Exp>;
  shortlists_aggregate?: Maybe<Shortlists_Aggregate_Bool_Exp>;
  tenant_users?: Maybe<Tenant_Users_Bool_Exp>;
  tenant_users_aggregate?: Maybe<Tenant_Users_Aggregate_Bool_Exp>;
  tenants?: Maybe<Tenants_Bool_Exp>;
  tenants_aggregate?: Maybe<Tenants_Aggregate_Bool_Exp>;
  test?: Maybe<Boolean_Comparison_Exp>;
  updated_at?: Maybe<Timestamptz_Comparison_Exp>;
};

/** unique or primary key constraints on table "users" */
export enum Users_Constraint {
  /** unique or primary key constraint on columns "auth0_uid" */
  UsersAuth0UidKey = 'users_auth0_uid_key',
  /** unique or primary key constraint on columns "id" */
  UsersPkey = 'users_pkey'
}

/** input type for inserting data into table "users" */
export type Users_Insert_Input = {
  auth0_uid?: Maybe<Scalars['String']>;
  batch_id?: Maybe<Scalars['timestamp']>;
  brimstone_event_feedbacks?: Maybe<Brimstone_Event_Feedback_Arr_Rel_Insert_Input>;
  candidates?: Maybe<Candidates_Arr_Rel_Insert_Input>;
  client_users?: Maybe<Client_Users_Arr_Rel_Insert_Input>;
  clients?: Maybe<Clients_Arr_Rel_Insert_Input>;
  contacts?: Maybe<Contacts_Arr_Rel_Insert_Input>;
  created_at?: Maybe<Scalars['timestamptz']>;
  deleted_at?: Maybe<Scalars['timestamptz']>;
  email?: Maybe<Scalars['String']>;
  enterprises?: Maybe<Enterprises_Arr_Rel_Insert_Input>;
  events?: Maybe<Events_Arr_Rel_Insert_Input>;
  first_name?: Maybe<Scalars['String']>;
  has_synced?: Maybe<Scalars['Boolean']>;
  id?: Maybe<Scalars['uuid']>;
  interviews?: Maybe<Interviews_Arr_Rel_Insert_Input>;
  jobs?: Maybe<Jobs_Arr_Rel_Insert_Input>;
  last_name?: Maybe<Scalars['String']>;
  nylas_access_token?: Maybe<Scalars['String']>;
  nylas_account_id?: Maybe<Scalars['String']>;
  nylas_calendar_id?: Maybe<Scalars['String']>;
  offers?: Maybe<Offers_Arr_Rel_Insert_Input>;
  phone?: Maybe<Scalars['String']>;
  role?: Maybe<Scalars['user_role_enums']>;
  short_urls?: Maybe<Short_Urls_Arr_Rel_Insert_Input>;
  shortlists?: Maybe<Shortlists_Arr_Rel_Insert_Input>;
  tenant_users?: Maybe<Tenant_Users_Arr_Rel_Insert_Input>;
  tenants?: Maybe<Tenants_Arr_Rel_Insert_Input>;
  test?: Maybe<Scalars['Boolean']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** aggregate max on columns */
export type Users_Max_Fields = {
  __typename?: 'users_max_fields';
  auth0_uid?: Maybe<Scalars['String']>;
  batch_id?: Maybe<Scalars['timestamp']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  deleted_at?: Maybe<Scalars['timestamptz']>;
  email?: Maybe<Scalars['String']>;
  first_name?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['uuid']>;
  last_name?: Maybe<Scalars['String']>;
  nylas_access_token?: Maybe<Scalars['String']>;
  nylas_account_id?: Maybe<Scalars['String']>;
  nylas_calendar_id?: Maybe<Scalars['String']>;
  phone?: Maybe<Scalars['String']>;
  role?: Maybe<Scalars['user_role_enums']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** aggregate min on columns */
export type Users_Min_Fields = {
  __typename?: 'users_min_fields';
  auth0_uid?: Maybe<Scalars['String']>;
  batch_id?: Maybe<Scalars['timestamp']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  deleted_at?: Maybe<Scalars['timestamptz']>;
  email?: Maybe<Scalars['String']>;
  first_name?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['uuid']>;
  last_name?: Maybe<Scalars['String']>;
  nylas_access_token?: Maybe<Scalars['String']>;
  nylas_account_id?: Maybe<Scalars['String']>;
  nylas_calendar_id?: Maybe<Scalars['String']>;
  phone?: Maybe<Scalars['String']>;
  role?: Maybe<Scalars['user_role_enums']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** response of any mutation on the table "users" */
export type Users_Mutation_Response = {
  __typename?: 'users_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Users>;
};

/** input type for inserting object relation for remote table "users" */
export type Users_Obj_Rel_Insert_Input = {
  data: Users_Insert_Input;
  /** upsert condition */
  on_conflict?: Maybe<Users_On_Conflict>;
};

/** on_conflict condition type for table "users" */
export type Users_On_Conflict = {
  constraint: Users_Constraint;
  update_columns?: Array<Users_Update_Column>;
  where?: Maybe<Users_Bool_Exp>;
};

/** Ordering options when selecting data from "users". */
export type Users_Order_By = {
  auth0_uid?: Maybe<Order_By>;
  batch_id?: Maybe<Order_By>;
  brimstone_event_feedbacks_aggregate?: Maybe<Brimstone_Event_Feedback_Aggregate_Order_By>;
  candidates_aggregate?: Maybe<Candidates_Aggregate_Order_By>;
  client_users_aggregate?: Maybe<Client_Users_Aggregate_Order_By>;
  clients_aggregate?: Maybe<Clients_Aggregate_Order_By>;
  contacts_aggregate?: Maybe<Contacts_Aggregate_Order_By>;
  created_at?: Maybe<Order_By>;
  deleted_at?: Maybe<Order_By>;
  email?: Maybe<Order_By>;
  enterprises_aggregate?: Maybe<Enterprises_Aggregate_Order_By>;
  events_aggregate?: Maybe<Events_Aggregate_Order_By>;
  first_name?: Maybe<Order_By>;
  has_synced?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  interviews_aggregate?: Maybe<Interviews_Aggregate_Order_By>;
  jobs_aggregate?: Maybe<Jobs_Aggregate_Order_By>;
  last_name?: Maybe<Order_By>;
  nylas_access_token?: Maybe<Order_By>;
  nylas_account_id?: Maybe<Order_By>;
  nylas_calendar_id?: Maybe<Order_By>;
  offers_aggregate?: Maybe<Offers_Aggregate_Order_By>;
  phone?: Maybe<Order_By>;
  role?: Maybe<Order_By>;
  short_urls_aggregate?: Maybe<Short_Urls_Aggregate_Order_By>;
  shortlists_aggregate?: Maybe<Shortlists_Aggregate_Order_By>;
  tenant_users_aggregate?: Maybe<Tenant_Users_Aggregate_Order_By>;
  tenants_aggregate?: Maybe<Tenants_Aggregate_Order_By>;
  test?: Maybe<Order_By>;
  updated_at?: Maybe<Order_By>;
};

/** primary key columns input for table: users */
export type Users_Pk_Columns_Input = {
  id: Scalars['uuid'];
};

/** select columns of table "users" */
export enum Users_Select_Column {
  /** column name */
  Auth0Uid = 'auth0_uid',
  /** column name */
  BatchId = 'batch_id',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  DeletedAt = 'deleted_at',
  /** column name */
  Email = 'email',
  /** column name */
  FirstName = 'first_name',
  /** column name */
  HasSynced = 'has_synced',
  /** column name */
  Id = 'id',
  /** column name */
  LastName = 'last_name',
  /** column name */
  NylasAccessToken = 'nylas_access_token',
  /** column name */
  NylasAccountId = 'nylas_account_id',
  /** column name */
  NylasCalendarId = 'nylas_calendar_id',
  /** column name */
  Phone = 'phone',
  /** column name */
  Role = 'role',
  /** column name */
  Test = 'test',
  /** column name */
  UpdatedAt = 'updated_at'
}

/** input type for updating data in table "users" */
export type Users_Set_Input = {
  auth0_uid?: Maybe<Scalars['String']>;
  batch_id?: Maybe<Scalars['timestamp']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  deleted_at?: Maybe<Scalars['timestamptz']>;
  email?: Maybe<Scalars['String']>;
  first_name?: Maybe<Scalars['String']>;
  has_synced?: Maybe<Scalars['Boolean']>;
  id?: Maybe<Scalars['uuid']>;
  last_name?: Maybe<Scalars['String']>;
  nylas_access_token?: Maybe<Scalars['String']>;
  nylas_account_id?: Maybe<Scalars['String']>;
  nylas_calendar_id?: Maybe<Scalars['String']>;
  phone?: Maybe<Scalars['String']>;
  role?: Maybe<Scalars['user_role_enums']>;
  test?: Maybe<Scalars['Boolean']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** Streaming cursor of the table "users" */
export type Users_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Users_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: Maybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Users_Stream_Cursor_Value_Input = {
  auth0_uid?: Maybe<Scalars['String']>;
  batch_id?: Maybe<Scalars['timestamp']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  deleted_at?: Maybe<Scalars['timestamptz']>;
  email?: Maybe<Scalars['String']>;
  first_name?: Maybe<Scalars['String']>;
  has_synced?: Maybe<Scalars['Boolean']>;
  id?: Maybe<Scalars['uuid']>;
  last_name?: Maybe<Scalars['String']>;
  nylas_access_token?: Maybe<Scalars['String']>;
  nylas_account_id?: Maybe<Scalars['String']>;
  nylas_calendar_id?: Maybe<Scalars['String']>;
  phone?: Maybe<Scalars['String']>;
  role?: Maybe<Scalars['user_role_enums']>;
  test?: Maybe<Scalars['Boolean']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** update columns of table "users" */
export enum Users_Update_Column {
  /** column name */
  Auth0Uid = 'auth0_uid',
  /** column name */
  BatchId = 'batch_id',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  DeletedAt = 'deleted_at',
  /** column name */
  Email = 'email',
  /** column name */
  FirstName = 'first_name',
  /** column name */
  HasSynced = 'has_synced',
  /** column name */
  Id = 'id',
  /** column name */
  LastName = 'last_name',
  /** column name */
  NylasAccessToken = 'nylas_access_token',
  /** column name */
  NylasAccountId = 'nylas_account_id',
  /** column name */
  NylasCalendarId = 'nylas_calendar_id',
  /** column name */
  Phone = 'phone',
  /** column name */
  Role = 'role',
  /** column name */
  Test = 'test',
  /** column name */
  UpdatedAt = 'updated_at'
}

export type Users_Updates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: Maybe<Users_Set_Input>;
  /** filter the rows which have to be updated */
  where: Users_Bool_Exp;
};


/** Boolean expression to compare columns of type "uuid". All fields are combined with logical 'AND'. */
export type Uuid_Comparison_Exp = {
  _eq?: Maybe<Scalars['uuid']>;
  _gt?: Maybe<Scalars['uuid']>;
  _gte?: Maybe<Scalars['uuid']>;
  _in?: Maybe<Array<Scalars['uuid']>>;
  _is_null?: Maybe<Scalars['Boolean']>;
  _lt?: Maybe<Scalars['uuid']>;
  _lte?: Maybe<Scalars['uuid']>;
  _neq?: Maybe<Scalars['uuid']>;
  _nin?: Maybe<Array<Scalars['uuid']>>;
};


/** Boolean expression to compare columns of type "work_arrangement_enums". All fields are combined with logical 'AND'. */
export type Work_Arrangement_Enums_Comparison_Exp = {
  _eq?: Maybe<Scalars['work_arrangement_enums']>;
  _gt?: Maybe<Scalars['work_arrangement_enums']>;
  _gte?: Maybe<Scalars['work_arrangement_enums']>;
  _in?: Maybe<Array<Scalars['work_arrangement_enums']>>;
  _is_null?: Maybe<Scalars['Boolean']>;
  _lt?: Maybe<Scalars['work_arrangement_enums']>;
  _lte?: Maybe<Scalars['work_arrangement_enums']>;
  _neq?: Maybe<Scalars['work_arrangement_enums']>;
  _nin?: Maybe<Array<Scalars['work_arrangement_enums']>>;
};

export type Candidate_ActivityQueryVariables = Exact<{
  object?: Maybe<Candidates_Bool_Exp>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
}>;


export type Candidate_ActivityQuery = (
  { __typename?: 'query_root' }
  & { candidates: Array<(
    { __typename?: 'candidates' }
    & Pick<Candidates, 'id' | 'test' | 'owner_id' | 'created_at' | 'deleted_at' | 'first_name' | 'last_name' | 'email' | 'photo_url' | 'status' | 'graduates_on' | 'published' | 'published_on'>
    & { candidate_industries: Array<(
      { __typename?: 'candidate_industries' }
      & { industry: (
        { __typename?: 'industries' }
        & Pick<Industries, 'id' | 'title'>
      ) }
    )>, candidate_locations: Array<(
      { __typename?: 'candidate_locations' }
      & { location?: Maybe<(
        { __typename?: 'locations' }
        & Pick<Locations, 'postcode' | 'state' | 'locality'>
      )> }
    )> }
  )>, candidates_aggregate: (
    { __typename?: 'candidates_aggregate' }
    & { aggregate?: Maybe<(
      { __typename?: 'candidates_aggregate_fields' }
      & Pick<Candidates_Aggregate_Fields, 'count'>
    )> }
  ) }
);

export type Client_ActivityQueryVariables = Exact<{
  created_at_range: Timestamptz_Comparison_Exp;
}>;


export type Client_ActivityQuery = (
  { __typename?: 'query_root' }
  & { jobs_aggregate: (
    { __typename?: 'jobs_aggregate' }
    & { aggregate?: Maybe<(
      { __typename?: 'jobs_aggregate_fields' }
      & Pick<Jobs_Aggregate_Fields, 'count'>
    )> }
  ), users: Array<(
    { __typename?: 'users' }
    & Pick<Users, 'id' | 'email' | 'auth0_uid' | 'created_at' | 'updated_at'>
    & { contacts: Array<(
      { __typename?: 'contacts' }
      & Pick<Contacts, 'id' | 'created_at' | 'first_name' | 'last_name'>
    )>, contacts_aggregate: (
      { __typename?: 'contacts_aggregate' }
      & { aggregate?: Maybe<(
        { __typename?: 'contacts_aggregate_fields' }
        & Pick<Contacts_Aggregate_Fields, 'count'>
      )> }
    ), clients: Array<(
      { __typename?: 'clients' }
      & Pick<Clients, 'id' | 'business_name' | 'created_at' | 'terms_accepted_on'>
    )>, clients_aggregate: (
      { __typename?: 'clients_aggregate' }
      & { aggregate?: Maybe<(
        { __typename?: 'clients_aggregate_fields' }
        & Pick<Clients_Aggregate_Fields, 'count'>
      )> }
    ) }
  )> }
);

export type TrackMutationVariables = Exact<{
  event: Scalars['String'];
  ownerId: Scalars['String'];
  tenantId?: Maybe<Scalars['String']>;
}>;


export type TrackMutation = (
  { __typename?: 'mutation_root' }
  & Pick<Mutation_Root, 'track'>
);

export type TrackResultQueryVariables = Exact<{
  id: Scalars['uuid'];
}>;


export type TrackResultQuery = (
  { __typename?: 'query_root' }
  & Pick<Query_Root, 'track'>
);

export type Create_EventMutationVariables = Exact<{
  object: Events_Insert_Input;
}>;


export type Create_EventMutation = (
  { __typename?: 'mutation_root' }
  & { insert_events_one?: Maybe<(
    { __typename?: 'events' }
    & Pick<Events, 'id' | 'created_at'>
  )> }
);

export type Delete_EventsMutationVariables = Exact<{
  object: Events_Bool_Exp;
}>;


export type Delete_EventsMutation = (
  { __typename?: 'mutation_root' }
  & { delete_events?: Maybe<(
    { __typename?: 'events_mutation_response' }
    & { returning: Array<(
      { __typename?: 'events' }
      & Pick<Events, 'candidate_id' | 'client_id' | 'contact_id' | 'id' | 'interview_id' | 'owner_id' | 'job_id' | 'tenant_id'>
    )> }
  )> }
);

export type Unique_EventQueryVariables = Exact<{
  eventId: Scalars['uuid'];
}>;


export type Unique_EventQuery = (
  { __typename?: 'query_root' }
  & { events_by_pk?: Maybe<(
    { __typename?: 'events' }
    & Pick<Events, 'id' | 'created_at' | 'event' | 'source' | 'context' | 'owner_id' | 'tenant_id'>
    & { user: (
      { __typename?: 'users' }
      & Pick<Users, 'id' | 'auth0_uid' | 'email' | 'role'>
    ), client?: Maybe<(
      { __typename?: 'clients' }
      & Pick<Clients, 'id'>
    )>, contact?: Maybe<(
      { __typename?: 'contacts' }
      & Pick<Contacts, 'id'>
    )>, job?: Maybe<(
      { __typename?: 'jobs' }
      & Pick<Jobs, 'id'>
    )>, candidate?: Maybe<(
      { __typename?: 'candidates' }
      & Pick<Candidates, 'id' | 'email' | 'first_name' | 'last_name'>
    )>, shortlist?: Maybe<(
      { __typename?: 'shortlists' }
      & Pick<Shortlists, 'id'>
      & { candidate?: Maybe<(
        { __typename?: 'candidates' }
        & Pick<Candidates, 'id' | 'first_name' | 'last_name' | 'photo_url'>
      )>, job?: Maybe<(
        { __typename?: 'jobs' }
        & Pick<Jobs, 'id' | 'title'>
      )>, client?: Maybe<(
        { __typename?: 'clients' }
        & Pick<Clients, 'id'>
      )> }
    )>, short_url?: Maybe<(
      { __typename?: 'short_urls' }
      & Pick<Short_Urls, 'id'>
    )>, tenant?: Maybe<(
      { __typename?: 'tenants' }
      & Pick<Tenants, 'id' | 'shortname' | 'name'>
    )>, interview?: Maybe<(
      { __typename?: 'interviews' }
      & Pick<Interviews, 'id'>
    )> }
  )> }
);

export type All_Events_By_ClientQueryVariables = Exact<{
  ownerId?: Maybe<Scalars['String']>;
  event?: Maybe<String_Comparison_Exp>;
}>;


export type All_Events_By_ClientQuery = (
  { __typename?: 'query_root' }
  & { events: Array<(
    { __typename?: 'events' }
    & Pick<Events, 'id' | 'created_at' | 'event' | 'source' | 'context' | 'owner_id'>
    & { user: (
      { __typename?: 'users' }
      & Pick<Users, 'id' | 'auth0_uid' | 'email' | 'role'>
      & { clients: Array<(
        { __typename?: 'clients' }
        & Pick<Clients, 'id' | 'business_name'>
      )>, candidates: Array<(
        { __typename?: 'candidates' }
        & Pick<Candidates, 'id' | 'first_name' | 'last_name' | 'photo_url'>
      )> }
    ), client?: Maybe<(
      { __typename?: 'clients' }
      & Pick<Clients, 'id'>
    )>, contact?: Maybe<(
      { __typename?: 'contacts' }
      & Pick<Contacts, 'id'>
    )>, job?: Maybe<(
      { __typename?: 'jobs' }
      & Pick<Jobs, 'id'>
    )>, candidate?: Maybe<(
      { __typename?: 'candidates' }
      & Pick<Candidates, 'id'>
    )>, shortlist?: Maybe<(
      { __typename?: 'shortlists' }
      & Pick<Shortlists, 'id'>
      & { candidate?: Maybe<(
        { __typename?: 'candidates' }
        & Pick<Candidates, 'id' | 'first_name' | 'last_name' | 'photo_url'>
      )>, job?: Maybe<(
        { __typename?: 'jobs' }
        & Pick<Jobs, 'id' | 'title'>
      )>, client?: Maybe<(
        { __typename?: 'clients' }
        & Pick<Clients, 'id'>
      )> }
    )>, short_url?: Maybe<(
      { __typename?: 'short_urls' }
      & Pick<Short_Urls, 'id'>
    )>, tenant?: Maybe<(
      { __typename?: 'tenants' }
      & Pick<Tenants, 'id' | 'shortname' | 'name'>
    )>, interview?: Maybe<(
      { __typename?: 'interviews' }
      & Pick<Interviews, 'id'>
    )>, feature_popup?: Maybe<(
      { __typename?: 'feature_popups' }
      & Pick<Feature_Popups, 'id' | 'name'>
    )> }
  )> }
);

export type All_EventsQueryVariables = Exact<{
  event?: Maybe<String_Comparison_Exp>;
  email?: Maybe<String_Comparison_Exp>;
  feature_popups_id?: Maybe<Uuid_Comparison_Exp>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
}>;


export type All_EventsQuery = (
  { __typename?: 'query_root' }
  & { events: Array<(
    { __typename?: 'events' }
    & Pick<Events, 'id' | 'created_at' | 'event' | 'source' | 'context' | 'owner_id'>
    & { user: (
      { __typename?: 'users' }
      & Pick<Users, 'id' | 'auth0_uid' | 'email' | 'role'>
      & { clients: Array<(
        { __typename?: 'clients' }
        & Pick<Clients, 'id' | 'business_name'>
      )>, candidates: Array<(
        { __typename?: 'candidates' }
        & Pick<Candidates, 'id' | 'first_name' | 'last_name' | 'photo_url'>
      )> }
    ), client?: Maybe<(
      { __typename?: 'clients' }
      & Pick<Clients, 'id'>
    )>, contact?: Maybe<(
      { __typename?: 'contacts' }
      & Pick<Contacts, 'id'>
    )>, job?: Maybe<(
      { __typename?: 'jobs' }
      & Pick<Jobs, 'id'>
    )>, candidate?: Maybe<(
      { __typename?: 'candidates' }
      & Pick<Candidates, 'id'>
    )>, shortlist?: Maybe<(
      { __typename?: 'shortlists' }
      & Pick<Shortlists, 'id'>
      & { candidate?: Maybe<(
        { __typename?: 'candidates' }
        & Pick<Candidates, 'id' | 'first_name' | 'last_name' | 'photo_url'>
      )>, job?: Maybe<(
        { __typename?: 'jobs' }
        & Pick<Jobs, 'id' | 'title'>
      )>, client?: Maybe<(
        { __typename?: 'clients' }
        & Pick<Clients, 'id'>
      )> }
    )>, short_url?: Maybe<(
      { __typename?: 'short_urls' }
      & Pick<Short_Urls, 'id'>
    )>, tenant?: Maybe<(
      { __typename?: 'tenants' }
      & Pick<Tenants, 'id' | 'shortname' | 'name'>
    )>, interview?: Maybe<(
      { __typename?: 'interviews' }
      & Pick<Interviews, 'id'>
    )>, feature_popup?: Maybe<(
      { __typename?: 'feature_popups' }
      & Pick<Feature_Popups, 'id' | 'name'>
    )> }
  )>, events_aggregate: (
    { __typename?: 'events_aggregate' }
    & { aggregate?: Maybe<(
      { __typename?: 'events_aggregate_fields' }
      & Pick<Events_Aggregate_Fields, 'count'>
    )> }
  ) }
);

export type Search_EventsQueryVariables = Exact<{
  object?: Maybe<Events_Bool_Exp>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
}>;


export type Search_EventsQuery = (
  { __typename?: 'query_root' }
  & { events: Array<(
    { __typename?: 'events' }
    & Pick<Events, 'id' | 'created_at' | 'event' | 'source' | 'context' | 'owner_id'>
    & { user: (
      { __typename?: 'users' }
      & Pick<Users, 'id' | 'auth0_uid' | 'email' | 'role'>
      & { clients: Array<(
        { __typename?: 'clients' }
        & Pick<Clients, 'id' | 'business_name'>
      )>, candidates: Array<(
        { __typename?: 'candidates' }
        & Pick<Candidates, 'id' | 'first_name' | 'last_name' | 'photo_url'>
      )> }
    ), client?: Maybe<(
      { __typename?: 'clients' }
      & Pick<Clients, 'id'>
    )>, contact?: Maybe<(
      { __typename?: 'contacts' }
      & Pick<Contacts, 'id'>
    )>, job?: Maybe<(
      { __typename?: 'jobs' }
      & Pick<Jobs, 'id'>
    )>, candidate?: Maybe<(
      { __typename?: 'candidates' }
      & Pick<Candidates, 'id'>
    )>, shortlist?: Maybe<(
      { __typename?: 'shortlists' }
      & Pick<Shortlists, 'id'>
      & { candidate?: Maybe<(
        { __typename?: 'candidates' }
        & Pick<Candidates, 'id' | 'first_name' | 'last_name' | 'photo_url'>
      )>, job?: Maybe<(
        { __typename?: 'jobs' }
        & Pick<Jobs, 'id' | 'title'>
      )>, client?: Maybe<(
        { __typename?: 'clients' }
        & Pick<Clients, 'id'>
      )> }
    )>, short_url?: Maybe<(
      { __typename?: 'short_urls' }
      & Pick<Short_Urls, 'id'>
    )>, tenant?: Maybe<(
      { __typename?: 'tenants' }
      & Pick<Tenants, 'id' | 'shortname' | 'name'>
    )>, interview?: Maybe<(
      { __typename?: 'interviews' }
      & Pick<Interviews, 'id'>
    )>, feature_popup?: Maybe<(
      { __typename?: 'feature_popups' }
      & Pick<Feature_Popups, 'id' | 'name'>
    )> }
  )>, events_aggregate: (
    { __typename?: 'events_aggregate' }
    & { aggregate?: Maybe<(
      { __typename?: 'events_aggregate_fields' }
      & Pick<Events_Aggregate_Fields, 'count'>
    )> }
  ) }
);

export type Search_All_EventsQueryVariables = Exact<{
  object?: Maybe<Events_Bool_Exp>;
}>;


export type Search_All_EventsQuery = (
  { __typename?: 'query_root' }
  & { events: Array<(
    { __typename?: 'events' }
    & Pick<Events, 'id' | 'created_at' | 'event' | 'source' | 'context' | 'owner_id'>
    & { user: (
      { __typename?: 'users' }
      & Pick<Users, 'id' | 'auth0_uid' | 'email' | 'role'>
    ), client?: Maybe<(
      { __typename?: 'clients' }
      & Pick<Clients, 'id'>
    )>, contact?: Maybe<(
      { __typename?: 'contacts' }
      & Pick<Contacts, 'id'>
    )>, job?: Maybe<(
      { __typename?: 'jobs' }
      & Pick<Jobs, 'id'>
    )>, candidate?: Maybe<(
      { __typename?: 'candidates' }
      & Pick<Candidates, 'id'>
    )>, shortlist?: Maybe<(
      { __typename?: 'shortlists' }
      & Pick<Shortlists, 'id'>
      & { candidate?: Maybe<(
        { __typename?: 'candidates' }
        & Pick<Candidates, 'id' | 'first_name' | 'last_name' | 'photo_url'>
      )>, job?: Maybe<(
        { __typename?: 'jobs' }
        & Pick<Jobs, 'id' | 'title'>
      )>, client?: Maybe<(
        { __typename?: 'clients' }
        & Pick<Clients, 'id'>
      )> }
    )>, short_url?: Maybe<(
      { __typename?: 'short_urls' }
      & Pick<Short_Urls, 'id'>
    )>, tenant?: Maybe<(
      { __typename?: 'tenants' }
      & Pick<Tenants, 'id' | 'shortname' | 'name'>
    )>, interview?: Maybe<(
      { __typename?: 'interviews' }
      & Pick<Interviews, 'id'>
    )> }
  )>, events_aggregate: (
    { __typename?: 'events_aggregate' }
    & { aggregate?: Maybe<(
      { __typename?: 'events_aggregate_fields' }
      & Pick<Events_Aggregate_Fields, 'count'>
    )> }
  ) }
);

export type Date_Range_EventsQueryVariables = Exact<{
  created_at_range: Timestamptz_Comparison_Exp;
  event?: Maybe<String_Comparison_Exp>;
  candidate?: Maybe<Candidates_Bool_Exp>;
  ownerId?: Maybe<String_Comparison_Exp>;
}>;


export type Date_Range_EventsQuery = (
  { __typename?: 'query_root' }
  & { events: Array<(
    { __typename?: 'events' }
    & Pick<Events, 'id' | 'created_at' | 'event' | 'owner_id'>
    & { user: (
      { __typename?: 'users' }
      & Pick<Users, 'id' | 'auth0_uid' | 'email' | 'role'>
    ), client?: Maybe<(
      { __typename?: 'clients' }
      & Pick<Clients, 'id'>
    )>, contact?: Maybe<(
      { __typename?: 'contacts' }
      & Pick<Contacts, 'id'>
    )>, job?: Maybe<(
      { __typename?: 'jobs' }
      & Pick<Jobs, 'id'>
    )>, candidate?: Maybe<(
      { __typename?: 'candidates' }
      & Pick<Candidates, 'id'>
    )>, shortlist?: Maybe<(
      { __typename?: 'shortlists' }
      & Pick<Shortlists, 'id'>
      & { candidate?: Maybe<(
        { __typename?: 'candidates' }
        & Pick<Candidates, 'id' | 'first_name' | 'last_name' | 'photo_url'>
      )>, job?: Maybe<(
        { __typename?: 'jobs' }
        & Pick<Jobs, 'id' | 'title'>
      )>, client?: Maybe<(
        { __typename?: 'clients' }
        & Pick<Clients, 'id'>
      )> }
    )>, short_url?: Maybe<(
      { __typename?: 'short_urls' }
      & Pick<Short_Urls, 'id'>
    )>, tenant?: Maybe<(
      { __typename?: 'tenants' }
      & Pick<Tenants, 'id' | 'shortname' | 'name'>
    )>, interview?: Maybe<(
      { __typename?: 'interviews' }
      & Pick<Interviews, 'id'>
    )> }
  )> }
);

export type Aggregate_EventsQueryVariables = Exact<{
  event?: Maybe<String_Comparison_Exp>;
  candidate?: Maybe<Candidates_Bool_Exp>;
  user?: Maybe<Users_Bool_Exp>;
}>;


export type Aggregate_EventsQuery = (
  { __typename?: 'query_root' }
  & { events_aggregate: (
    { __typename?: 'events_aggregate' }
    & { aggregate?: Maybe<(
      { __typename?: 'events_aggregate_fields' }
      & Pick<Events_Aggregate_Fields, 'count'>
    )> }
  ) }
);

export type Enterprise_ViewsQueryVariables = Exact<{
  ownerId?: Maybe<Scalars['String']>;
  event?: Maybe<Scalars['String']>;
}>;


export type Enterprise_ViewsQuery = (
  { __typename?: 'query_root' }
  & { events: Array<(
    { __typename?: 'events' }
    & Pick<Events, 'candidate_id' | 'enterprise_id' | 'created_at'>
  )> }
);

export type Enterprise_EventsQueryVariables = Exact<{
  event?: Maybe<Scalars['String']>;
  enterpriseId?: Maybe<Scalars['uuid']>;
}>;


export type Enterprise_EventsQuery = (
  { __typename?: 'query_root' }
  & { events: Array<(
    { __typename?: 'events' }
    & Pick<Events, 'candidate_id' | 'enterprise_id' | 'created_at'>
  )> }
);

export type Unique_Event_By_Auth0_UidQueryVariables = Exact<{
  auth0_uid?: Maybe<Scalars['String']>;
  event?: Maybe<Scalars['String']>;
}>;


export type Unique_Event_By_Auth0_UidQuery = (
  { __typename?: 'query_root' }
  & { events: Array<(
    { __typename?: 'events' }
    & Pick<Events, 'created_at'>
  )> }
);

export type Upsert_Unique_CandidateMutationVariables = Exact<{
  candidate: Candidates_Insert_Input;
  columns?: Maybe<Array<Candidates_Update_Column> | Candidates_Update_Column>;
}>;


export type Upsert_Unique_CandidateMutation = (
  { __typename?: 'mutation_root' }
  & { insert_candidates_one?: Maybe<(
    { __typename?: 'candidates' }
    & Pick<Candidates, 'id' | 'status'>
  )> }
);

export type All_CandidatesQueryVariables = Exact<{ [key: string]: never; }>;


export type All_CandidatesQuery = (
  { __typename?: 'query_root' }
  & { candidates: Array<(
    { __typename?: 'candidates' }
    & Pick<Candidates, 'id' | 'owner_id' | 'created_at' | 'first_name' | 'last_name' | 'email' | 'photo_url' | 'status' | 'published'>
    & { candidate_locations: Array<(
      { __typename?: 'candidate_locations' }
      & { location?: Maybe<(
        { __typename?: 'locations' }
        & Pick<Locations, 'postcode' | 'state' | 'locality'>
      )> }
    )>, tenant_candidates: Array<(
      { __typename?: 'tenant_candidates' }
      & { tenant: (
        { __typename?: 'tenants' }
        & Pick<Tenants, 'id' | 'shortname'>
      ) }
    )> }
  )> }
);

export type Create_CandidateMutationVariables = Exact<{
  object: Candidates_Insert_Input;
}>;


export type Create_CandidateMutation = (
  { __typename?: 'mutation_root' }
  & { insert_candidates_one?: Maybe<(
    { __typename?: 'candidates' }
    & Pick<Candidates, 'id' | 'first_name' | 'last_name' | 'email' | 'phone' | 'owner_id'>
  )> }
);

export type Create_Unique_Tenant_CandidateMutationVariables = Exact<{
  candidate: Tenant_Candidates_Insert_Input;
}>;


export type Create_Unique_Tenant_CandidateMutation = (
  { __typename?: 'mutation_root' }
  & { insert_tenant_candidates_one?: Maybe<(
    { __typename?: 'tenant_candidates' }
    & Pick<Tenant_Candidates, 'id'>
  )> }
);

export type Search_Candidates_By_ObjectQueryVariables = Exact<{
  object?: Maybe<Candidates_Bool_Exp>;
  views_date_range?: Maybe<Timestamptz_Comparison_Exp>;
}>;


export type Search_Candidates_By_ObjectQuery = (
  { __typename?: 'query_root' }
  & { candidates: Array<(
    { __typename?: 'candidates' }
    & Pick<Candidates, 'id' | 'owner_id' | 'published_on' | 'published' | 'first_name' | 'last_name' | 'photo_url' | 'video_url' | 'cv_url' | 'test' | 'graduates_on' | 'status' | 'open_to_part_time_prior_to_graduation' | 'available_part_time_days_from' | 'available_part_time_days_to' | 'work_arrangement'>
    & { views_counter: (
      { __typename?: 'events_aggregate' }
      & { aggregate?: Maybe<(
        { __typename?: 'events_aggregate_fields' }
        & Pick<Events_Aggregate_Fields, 'count'>
      )> }
    ), candidate_industries: Array<(
      { __typename?: 'candidate_industries' }
      & { industry: (
        { __typename?: 'industries' }
        & Pick<Industries, 'id'>
      ) }
    )>, candidate_degrees: Array<(
      { __typename?: 'candidate_degrees' }
      & { degree: (
        { __typename?: 'degrees' }
        & Pick<Degrees, 'id'>
      ) }
    )>, candidate_majors: Array<(
      { __typename?: 'candidate_majors' }
      & { major: (
        { __typename?: 'majors' }
        & Pick<Majors, 'id'>
      ) }
    )>, candidate_skills: Array<(
      { __typename?: 'candidate_skills' }
      & { skill: (
        { __typename?: 'skills' }
        & Pick<Skills, 'id'>
      ) }
    )>, candidate_locations: Array<(
      { __typename?: 'candidate_locations' }
      & { location?: Maybe<(
        { __typename?: 'locations' }
        & Pick<Locations, 'coordinate' | 'postcode' | 'locality' | 'state'>
      )> }
    )> }
  )> }
);

export type Search_CandidatesQueryVariables = Exact<{
  status?: Maybe<Candidate_Status_Enums_Comparison_Exp>;
  work_arrangement?: Maybe<Work_Arrangement_Enums_Comparison_Exp>;
  available_part_time_days_from?: Maybe<Int_Comparison_Exp>;
  available_part_time_days_to?: Maybe<Int_Comparison_Exp>;
  can_commence_work_on: Timestamptz_Comparison_Exp;
  open_to_part_time_prior_to_graduation?: Maybe<Boolean_Comparison_Exp>;
  skills?: Maybe<Candidate_Skills_Bool_Exp>;
  majors?: Maybe<Candidate_Majors_Bool_Exp>;
  degrees?: Maybe<Candidate_Degrees_Bool_Exp>;
  industries?: Maybe<Candidate_Industries_Bool_Exp>;
  location?: Maybe<Candidate_Locations_Bool_Exp>;
  published?: Maybe<Boolean_Comparison_Exp>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
}>;


export type Search_CandidatesQuery = (
  { __typename?: 'query_root' }
  & { filtered: Array<(
    { __typename?: 'candidates' }
    & Pick<Candidates, 'id' | 'owner_id' | 'published_on' | 'published' | 'first_name' | 'last_name' | 'photo_url' | 'video_url' | 'cv_url' | 'test' | 'graduates_on' | 'status' | 'open_to_part_time_prior_to_graduation' | 'available_part_time_days_from' | 'available_part_time_days_to' | 'work_arrangement'>
    & { candidate_industries: Array<(
      { __typename?: 'candidate_industries' }
      & { industry: (
        { __typename?: 'industries' }
        & Pick<Industries, 'id'>
      ) }
    )>, candidate_degrees: Array<(
      { __typename?: 'candidate_degrees' }
      & { degree: (
        { __typename?: 'degrees' }
        & Pick<Degrees, 'id'>
      ) }
    )>, candidate_majors: Array<(
      { __typename?: 'candidate_majors' }
      & { major: (
        { __typename?: 'majors' }
        & Pick<Majors, 'id'>
      ) }
    )>, candidate_skills: Array<(
      { __typename?: 'candidate_skills' }
      & { skill: (
        { __typename?: 'skills' }
        & Pick<Skills, 'id'>
      ) }
    )>, candidate_locations: Array<(
      { __typename?: 'candidate_locations' }
      & { location?: Maybe<(
        { __typename?: 'locations' }
        & Pick<Locations, 'coordinate' | 'postcode' | 'locality' | 'state'>
      )> }
    )> }
  )>, latest: Array<(
    { __typename?: 'candidates' }
    & Pick<Candidates, 'id' | 'owner_id' | 'published' | 'test' | 'first_name' | 'last_name' | 'photo_url' | 'cv_url' | 'graduates_on' | 'status' | 'open_to_part_time_prior_to_graduation' | 'available_part_time_days_from' | 'available_part_time_days_to' | 'work_arrangement' | 'university_id'>
    & { university?: Maybe<(
      { __typename?: 'universities' }
      & Pick<Universities, 'name' | 'short_name'>
    )>, candidate_industries: Array<(
      { __typename?: 'candidate_industries' }
      & { industry: (
        { __typename?: 'industries' }
        & Pick<Industries, 'id'>
      ) }
    )>, candidate_degrees: Array<(
      { __typename?: 'candidate_degrees' }
      & { degree: (
        { __typename?: 'degrees' }
        & Pick<Degrees, 'id'>
      ) }
    )>, candidate_majors: Array<(
      { __typename?: 'candidate_majors' }
      & { major: (
        { __typename?: 'majors' }
        & Pick<Majors, 'id'>
      ) }
    )>, candidate_skills: Array<(
      { __typename?: 'candidate_skills' }
      & { skill: (
        { __typename?: 'skills' }
        & Pick<Skills, 'id'>
      ) }
    )>, candidate_locations: Array<(
      { __typename?: 'candidate_locations' }
      & { location?: Maybe<(
        { __typename?: 'locations' }
        & Pick<Locations, 'coordinate' | 'postcode' | 'locality' | 'state'>
      )> }
    )> }
  )>, default: Array<(
    { __typename?: 'candidates' }
    & Pick<Candidates, 'id' | 'owner_id' | 'published' | 'test' | 'first_name' | 'last_name' | 'photo_url' | 'cv_url' | 'graduates_on' | 'status' | 'open_to_part_time_prior_to_graduation' | 'available_part_time_days_from' | 'available_part_time_days_to' | 'work_arrangement' | 'university_id'>
    & { university?: Maybe<(
      { __typename?: 'universities' }
      & Pick<Universities, 'name' | 'short_name'>
    )>, candidate_industries: Array<(
      { __typename?: 'candidate_industries' }
      & { industry: (
        { __typename?: 'industries' }
        & Pick<Industries, 'id'>
      ) }
    )>, candidate_degrees: Array<(
      { __typename?: 'candidate_degrees' }
      & { degree: (
        { __typename?: 'degrees' }
        & Pick<Degrees, 'id'>
      ) }
    )>, candidate_majors: Array<(
      { __typename?: 'candidate_majors' }
      & { major: (
        { __typename?: 'majors' }
        & Pick<Majors, 'id'>
      ) }
    )>, candidate_skills: Array<(
      { __typename?: 'candidate_skills' }
      & { skill: (
        { __typename?: 'skills' }
        & Pick<Skills, 'id'>
      ) }
    )>, candidate_locations: Array<(
      { __typename?: 'candidate_locations' }
      & { location?: Maybe<(
        { __typename?: 'locations' }
        & Pick<Locations, 'coordinate' | 'postcode' | 'locality' | 'state'>
      )> }
    )> }
  )>, candidates_aggregate: (
    { __typename?: 'candidates_aggregate' }
    & { aggregate?: Maybe<(
      { __typename?: 'candidates_aggregate_fields' }
      & Pick<Candidates_Aggregate_Fields, 'count'>
    )> }
  ) }
);

export type Aggregate_CandidatesQueryVariables = Exact<{
  published?: Maybe<Boolean_Comparison_Exp>;
}>;


export type Aggregate_CandidatesQuery = (
  { __typename?: 'query_root' }
  & { candidates_aggregate: (
    { __typename?: 'candidates_aggregate' }
    & { aggregate?: Maybe<(
      { __typename?: 'candidates_aggregate_fields' }
      & Pick<Candidates_Aggregate_Fields, 'count'>
    )> }
  ) }
);

export type Search_All_CandidatesQueryVariables = Exact<{
  object?: Maybe<Candidates_Bool_Exp>;
}>;


export type Search_All_CandidatesQuery = (
  { __typename?: 'query_root' }
  & { candidates: Array<(
    { __typename?: 'candidates' }
    & Pick<Candidates, 'id' | 'test' | 'owner_id' | 'created_at' | 'deleted_at' | 'first_name' | 'last_name' | 'email' | 'phone' | 'photo_url' | 'status' | 'graduates_on' | 'published' | 'published_on' | 'notes'>
    & { candidate_industries: Array<(
      { __typename?: 'candidate_industries' }
      & { industry: (
        { __typename?: 'industries' }
        & Pick<Industries, 'id' | 'title'>
      ) }
    )>, candidate_degrees: Array<(
      { __typename?: 'candidate_degrees' }
      & { degree: (
        { __typename?: 'degrees' }
        & Pick<Degrees, 'id' | 'title'>
      ) }
    )>, candidate_majors: Array<(
      { __typename?: 'candidate_majors' }
      & { major: (
        { __typename?: 'majors' }
        & Pick<Majors, 'id' | 'title'>
      ) }
    )>, candidate_locations: Array<(
      { __typename?: 'candidate_locations' }
      & { location?: Maybe<(
        { __typename?: 'locations' }
        & Pick<Locations, 'postcode' | 'state' | 'locality'>
      )> }
    )> }
  )> }
);

export type Unique_Candidate_AdminQueryVariables = Exact<{
  candidateId: Scalars['uuid'];
}>;


export type Unique_Candidate_AdminQuery = (
  { __typename?: 'query_root' }
  & { candidates_by_pk?: Maybe<(
    { __typename?: 'candidates' }
    & Pick<Candidates, 'id' | 'owner_id' | 'created_at' | 'updated_at' | 'deleted_at' | 'test' | 'first_name' | 'last_name' | 'residency_status' | 'has_work_experience' | 'bio' | 'relocate' | 'notes' | 'cv_url' | 'photo_url' | 'video_url' | 'transcripts_url' | 'psych_url' | 'phone' | 'published' | 'published_on' | 'email' | 'profile_progress' | 'work_arrangement' | 'graduates_on' | 'status' | 'open_to_part_time_prior_to_graduation' | 'available_part_time_days_from' | 'available_part_time_days_to' | 'university_id'>
    & { university?: Maybe<(
      { __typename?: 'universities' }
      & Pick<Universities, 'name' | 'short_name'>
    )>, candidate_skills: Array<(
      { __typename?: 'candidate_skills' }
      & Pick<Candidate_Skills, 'score'>
      & { skill: (
        { __typename?: 'skills' }
        & Pick<Skills, 'id' | 'title'>
      ) }
    )>, candidate_majors: Array<(
      { __typename?: 'candidate_majors' }
      & { major: (
        { __typename?: 'majors' }
        & Pick<Majors, 'id' | 'title'>
      ) }
    )>, candidate_degrees: Array<(
      { __typename?: 'candidate_degrees' }
      & { degree: (
        { __typename?: 'degrees' }
        & Pick<Degrees, 'id' | 'title'>
      ) }
    )>, candidate_industries: Array<(
      { __typename?: 'candidate_industries' }
      & { industry: (
        { __typename?: 'industries' }
        & Pick<Industries, 'id' | 'title'>
      ) }
    )>, candidate_locations: Array<(
      { __typename?: 'candidate_locations' }
      & { location?: Maybe<(
        { __typename?: 'locations' }
        & Pick<Locations, 'locality' | 'state' | 'postcode' | 'coordinate'>
      )> }
    )>, candidate_courses: Array<(
      { __typename?: 'candidate_courses' }
      & Pick<Candidate_Courses, 'result' | 'course_id' | 'candidate_id'>
      & { course: (
        { __typename?: 'courses' }
        & Pick<Courses, 'id' | 'img' | 'name' | 'overview'>
      ), candidate: (
        { __typename?: 'candidates' }
        & Pick<Candidates, 'id'>
      ) }
    )> }
  )> }
);

export type Unique_CandidateQueryVariables = Exact<{
  candidateId: Scalars['uuid'];
}>;


export type Unique_CandidateQuery = (
  { __typename?: 'query_root' }
  & { candidates_by_pk?: Maybe<(
    { __typename?: 'candidates' }
    & Pick<Candidates, 'id' | 'owner_id' | 'created_at' | 'updated_at' | 'first_name' | 'last_name' | 'residency_status' | 'has_work_experience' | 'bio' | 'cv_url' | 'photo_url' | 'video_url' | 'transcripts_url' | 'psych_url' | 'phone' | 'published' | 'published_on' | 'email' | 'profile_progress' | 'work_arrangement' | 'graduates_on' | 'status' | 'open_to_part_time_prior_to_graduation' | 'available_part_time_days_from' | 'available_part_time_days_to' | 'university_id'>
    & { tenant_candidates: Array<(
      { __typename?: 'tenant_candidates' }
      & Pick<Tenant_Candidates, 'tenant_id'>
    )>, university?: Maybe<(
      { __typename?: 'universities' }
      & Pick<Universities, 'name' | 'short_name'>
    )>, candidate_skills: Array<(
      { __typename?: 'candidate_skills' }
      & Pick<Candidate_Skills, 'score'>
      & { skill: (
        { __typename?: 'skills' }
        & Pick<Skills, 'id' | 'title'>
      ) }
    )>, candidate_majors: Array<(
      { __typename?: 'candidate_majors' }
      & { major: (
        { __typename?: 'majors' }
        & Pick<Majors, 'id' | 'title'>
      ) }
    )>, candidate_degrees: Array<(
      { __typename?: 'candidate_degrees' }
      & { degree: (
        { __typename?: 'degrees' }
        & Pick<Degrees, 'id' | 'title'>
      ) }
    )>, candidate_industries: Array<(
      { __typename?: 'candidate_industries' }
      & { industry: (
        { __typename?: 'industries' }
        & Pick<Industries, 'id' | 'title'>
      ) }
    )>, candidate_locations: Array<(
      { __typename?: 'candidate_locations' }
      & { location?: Maybe<(
        { __typename?: 'locations' }
        & Pick<Locations, 'locality' | 'state' | 'postcode' | 'coordinate'>
      )> }
    )>, candidate_courses: Array<(
      { __typename?: 'candidate_courses' }
      & Pick<Candidate_Courses, 'result' | 'course_id' | 'candidate_id'>
      & { course: (
        { __typename?: 'courses' }
        & Pick<Courses, 'id' | 'img' | 'name' | 'overview'>
      ), candidate: (
        { __typename?: 'candidates' }
        & Pick<Candidates, 'id'>
      ) }
    )> }
  )> }
);

export type Unique_Candidate_PreviewQueryVariables = Exact<{
  candidateId: Scalars['uuid'];
}>;


export type Unique_Candidate_PreviewQuery = (
  { __typename?: 'query_root' }
  & { candidates_by_pk?: Maybe<(
    { __typename?: 'candidates' }
    & Pick<Candidates, 'id' | 'created_at' | 'updated_at' | 'first_name' | 'bio' | 'graduates_on' | 'available_part_time_days_from' | 'available_part_time_days_to' | 'open_to_part_time_prior_to_graduation' | 'photo_url' | 'video_url' | 'university_id' | 'published'>
    & { candidate_skills: Array<(
      { __typename?: 'candidate_skills' }
      & { skill: (
        { __typename?: 'skills' }
        & Pick<Skills, 'id' | 'title'>
      ) }
    )>, candidate_majors: Array<(
      { __typename?: 'candidate_majors' }
      & { major: (
        { __typename?: 'majors' }
        & Pick<Majors, 'id' | 'title'>
      ) }
    )>, candidate_degrees: Array<(
      { __typename?: 'candidate_degrees' }
      & { degree: (
        { __typename?: 'degrees' }
        & Pick<Degrees, 'id' | 'title'>
      ) }
    )>, candidate_industries: Array<(
      { __typename?: 'candidate_industries' }
      & { industry: (
        { __typename?: 'industries' }
        & Pick<Industries, 'id' | 'title'>
      ) }
    )>, candidate_locations: Array<(
      { __typename?: 'candidate_locations' }
      & { location?: Maybe<(
        { __typename?: 'locations' }
        & Pick<Locations, 'locality' | 'state' | 'postcode'>
      )> }
    )> }
  )> }
);

export type Candidate_StatsQueryVariables = Exact<{
  clientId: Scalars['uuid'];
  candidateId: Scalars['uuid'];
}>;


export type Candidate_StatsQuery = (
  { __typename?: 'query_root' }
  & { shortlisted_by_client_count: (
    { __typename?: 'shortlists_aggregate' }
    & { aggregate?: Maybe<(
      { __typename?: 'shortlists_aggregate_fields' }
      & { count: Shortlists_Aggregate_Fields['count'] }
    )> }
  ), shortlisted_by_all_count: (
    { __typename?: 'shortlists_aggregate' }
    & { aggregate?: Maybe<(
      { __typename?: 'shortlists_aggregate_fields' }
      & { count: Shortlists_Aggregate_Fields['count'] }
    )> }
  ), liked_by_client_count: (
    { __typename?: 'shortlists_aggregate' }
    & { aggregate?: Maybe<(
      { __typename?: 'shortlists_aggregate_fields' }
      & { count: Shortlists_Aggregate_Fields['count'] }
    )> }
  ), liked_by_all_count: (
    { __typename?: 'shortlists_aggregate' }
    & { aggregate?: Maybe<(
      { __typename?: 'shortlists_aggregate_fields' }
      & { count: Shortlists_Aggregate_Fields['count'] }
    )> }
  ) }
);

export type Unique_Candidate_EmailQueryVariables = Exact<{
  email?: Maybe<Scalars['String']>;
  limit?: Maybe<Scalars['Int']>;
  published?: Maybe<Boolean_Comparison_Exp>;
}>;


export type Unique_Candidate_EmailQuery = (
  { __typename?: 'query_root' }
  & { candidates: Array<(
    { __typename?: 'candidates' }
    & Pick<Candidates, 'id' | 'email' | 'photo_url' | 'first_name' | 'last_name' | 'phone'>
  )> }
);

export type Unique_Candidate_By_Owner_IdQueryVariables = Exact<{
  owner_id?: Maybe<Scalars['String']>;
}>;


export type Unique_Candidate_By_Owner_IdQuery = (
  { __typename?: 'query_root' }
  & { candidates: Array<(
    { __typename?: 'candidates' }
    & Pick<Candidates, 'id' | 'residency_status' | 'status' | 'published'>
    & { candidate_locations: Array<(
      { __typename?: 'candidate_locations' }
      & { location?: Maybe<(
        { __typename?: 'locations' }
        & Pick<Locations, 'coordinate' | 'postcode' | 'locality' | 'state'>
      )> }
    )> }
  )> }
);

export type Delete_Candidate_IndustriesMutationVariables = Exact<{
  candidateId: Scalars['uuid'];
}>;


export type Delete_Candidate_IndustriesMutation = (
  { __typename?: 'mutation_root' }
  & { delete_candidate_industries?: Maybe<(
    { __typename?: 'candidate_industries_mutation_response' }
    & { returning: Array<(
      { __typename?: 'candidate_industries' }
      & Pick<Candidate_Industries, 'candidate_id' | 'industry_id'>
    )> }
  )> }
);

export type Delete_Candidate_SkillsMutationVariables = Exact<{
  candidateId: Scalars['uuid'];
}>;


export type Delete_Candidate_SkillsMutation = (
  { __typename?: 'mutation_root' }
  & { delete_candidate_skills?: Maybe<(
    { __typename?: 'candidate_skills_mutation_response' }
    & { returning: Array<(
      { __typename?: 'candidate_skills' }
      & Pick<Candidate_Skills, 'candidate_id' | 'skill_id'>
    )> }
  )> }
);

export type Delete_Candidate_LocationsMutationVariables = Exact<{
  candidateId: Scalars['uuid'];
}>;


export type Delete_Candidate_LocationsMutation = (
  { __typename?: 'mutation_root' }
  & { delete_candidate_locations?: Maybe<(
    { __typename?: 'candidate_locations_mutation_response' }
    & { returning: Array<(
      { __typename?: 'candidate_locations' }
      & Pick<Candidate_Locations, 'candidate_id'>
      & { location?: Maybe<(
        { __typename?: 'locations' }
        & Pick<Locations, 'id'>
      )> }
    )> }
  )> }
);

export type Delete_Candidate_DegreesMutationVariables = Exact<{
  candidateId: Scalars['uuid'];
}>;


export type Delete_Candidate_DegreesMutation = (
  { __typename?: 'mutation_root' }
  & { delete_candidate_degrees?: Maybe<(
    { __typename?: 'candidate_degrees_mutation_response' }
    & { returning: Array<(
      { __typename?: 'candidate_degrees' }
      & Pick<Candidate_Degrees, 'candidate_id' | 'degree_id'>
    )> }
  )> }
);

export type Delete_Candidate_MajorsMutationVariables = Exact<{
  candidateId: Scalars['uuid'];
}>;


export type Delete_Candidate_MajorsMutation = (
  { __typename?: 'mutation_root' }
  & { delete_candidate_majors?: Maybe<(
    { __typename?: 'candidate_majors_mutation_response' }
    & { returning: Array<(
      { __typename?: 'candidate_majors' }
      & Pick<Candidate_Majors, 'candidate_id' | 'major_id'>
    )> }
  )> }
);

export type Delete_Test_Tenant_CandidateMutationVariables = Exact<{
  candidateId: Scalars['uuid'];
}>;


export type Delete_Test_Tenant_CandidateMutation = (
  { __typename?: 'mutation_root' }
  & { delete_tenant_candidates?: Maybe<(
    { __typename?: 'tenant_candidates_mutation_response' }
    & { returning: Array<(
      { __typename?: 'tenant_candidates' }
      & Pick<Tenant_Candidates, 'id' | 'candidate_id' | 'tenant_id'>
    )> }
  )> }
);

export type Delete_Test_CandidateMutationVariables = Exact<{
  candidateId: Scalars['uuid'];
}>;


export type Delete_Test_CandidateMutation = (
  { __typename?: 'mutation_root' }
  & { delete_candidates_by_pk?: Maybe<(
    { __typename?: 'candidates' }
    & Pick<Candidates, 'id' | 'first_name' | 'email' | 'last_name' | 'owner_id'>
  )> }
);

export type Send_Candidate_First_Viewed_EmailMutationVariables = Exact<{
  candidateId?: Maybe<Scalars['uuid']>;
}>;


export type Send_Candidate_First_Viewed_EmailMutation = (
  { __typename?: 'mutation_root' }
  & Pick<Mutation_Root, 'send_candidate_first_viewed_email'>
);

export type All_Candidates_By_StatusQueryVariables = Exact<{
  residency_status?: Maybe<Residency_Status_Enums_Comparison_Exp>;
  graduates_on?: Maybe<Timestamptz_Comparison_Exp>;
  status?: Maybe<Candidate_Status_Enums_Comparison_Exp>;
  notes?: Maybe<String_Comparison_Exp>;
}>;


export type All_Candidates_By_StatusQuery = (
  { __typename?: 'query_root' }
  & { candidates: Array<(
    { __typename?: 'candidates' }
    & Pick<Candidates, 'id' | 'notes'>
  )> }
);

export type Send_Candidate_No_Show_EmailMutationVariables = Exact<{
  candidateId?: Maybe<Scalars['uuid']>;
}>;


export type Send_Candidate_No_Show_EmailMutation = (
  { __typename?: 'mutation_root' }
  & Pick<Mutation_Root, 'send_candidate_no_show_email'>
);

export type Upsert_Unique_ClientMutationVariables = Exact<{
  client: Clients_Insert_Input;
  columns?: Maybe<Array<Clients_Update_Column> | Clients_Update_Column>;
}>;


export type Upsert_Unique_ClientMutation = (
  { __typename?: 'mutation_root' }
  & { insert_clients_one?: Maybe<(
    { __typename?: 'clients' }
    & Pick<Clients, 'id'>
  )> }
);

export type Update_Unique_Client_ContactMutationVariables = Exact<{
  contact: Contacts_Set_Input;
  columns: Contacts_Pk_Columns_Input;
}>;


export type Update_Unique_Client_ContactMutation = (
  { __typename?: 'mutation_root' }
  & { update_contacts_by_pk?: Maybe<(
    { __typename?: 'contacts' }
    & Pick<Contacts, 'id'>
  )> }
);

export type Create_ClientMutationVariables = Exact<{
  object: Clients_Insert_Input;
}>;


export type Create_ClientMutation = (
  { __typename?: 'mutation_root' }
  & { insert_clients_one?: Maybe<(
    { __typename?: 'clients' }
    & Pick<Clients, 'id' | 'owner_id' | 'business_name'>
  )> }
);

export type Delete_Test_ClientMutationVariables = Exact<{
  clientId: Scalars['uuid'];
}>;


export type Delete_Test_ClientMutation = (
  { __typename?: 'mutation_root' }
  & { delete_clients_by_pk?: Maybe<(
    { __typename?: 'clients' }
    & Pick<Clients, 'business_name' | 'id'>
    & { owner?: Maybe<(
      { __typename?: 'users' }
      & Pick<Users, 'auth0_uid' | 'first_name' | 'id'>
    )> }
  )> }
);

export type Delete_Test_Client_EventsMutationVariables = Exact<{
  clientId: Scalars['uuid'];
}>;


export type Delete_Test_Client_EventsMutation = (
  { __typename?: 'mutation_root' }
  & { delete_events?: Maybe<(
    { __typename?: 'events_mutation_response' }
    & { returning: Array<(
      { __typename?: 'events' }
      & Pick<Events, 'id' | 'client_id'>
    )> }
  )> }
);

export type Delete_Test_Client_JobsMutationVariables = Exact<{
  clientId: Scalars['uuid'];
}>;


export type Delete_Test_Client_JobsMutation = (
  { __typename?: 'mutation_root' }
  & { delete_client_jobs?: Maybe<(
    { __typename?: 'client_jobs_mutation_response' }
    & { returning: Array<(
      { __typename?: 'client_jobs' }
      & Pick<Client_Jobs, 'id' | 'client_id'>
    )> }
  )> }
);

export type Create_Client_LocationMutationVariables = Exact<{
  object: Client_Locations_Insert_Input;
}>;


export type Create_Client_LocationMutation = (
  { __typename?: 'mutation_root' }
  & { insert_client_locations_one?: Maybe<(
    { __typename?: 'client_locations' }
    & Pick<Client_Locations, 'id'>
  )> }
);

export type Delete_Test_Client_LocationMutationVariables = Exact<{
  clientId: Scalars['uuid'];
}>;


export type Delete_Test_Client_LocationMutation = (
  { __typename?: 'mutation_root' }
  & { delete_client_locations?: Maybe<(
    { __typename?: 'client_locations_mutation_response' }
    & Pick<Client_Locations_Mutation_Response, 'affected_rows'>
  )> }
);

export type Create_Client_IndustriesMutationVariables = Exact<{
  objects: Array<Client_Industries_Insert_Input> | Client_Industries_Insert_Input;
}>;


export type Create_Client_IndustriesMutation = (
  { __typename?: 'mutation_root' }
  & { insert_client_industries?: Maybe<(
    { __typename?: 'client_industries_mutation_response' }
    & { returning: Array<(
      { __typename?: 'client_industries' }
      & Pick<Client_Industries, 'id'>
    )> }
  )> }
);

export type Delete_Test_Client_IndustriesMutationVariables = Exact<{
  clientId: Scalars['uuid'];
}>;


export type Delete_Test_Client_IndustriesMutation = (
  { __typename?: 'mutation_root' }
  & { delete_client_industries?: Maybe<(
    { __typename?: 'client_industries_mutation_response' }
    & Pick<Client_Industries_Mutation_Response, 'affected_rows'>
  )> }
);

export type All_ClientsQueryVariables = Exact<{ [key: string]: never; }>;


export type All_ClientsQuery = (
  { __typename?: 'query_root' }
  & { clients: Array<(
    { __typename?: 'clients' }
    & Pick<Clients, 'id' | 'created_at' | 'business_name' | 'terms_accepted_on'>
    & { tenant: (
      { __typename?: 'tenants' }
      & Pick<Tenants, 'id'>
    ), client_locations: Array<(
      { __typename?: 'client_locations' }
      & { location?: Maybe<(
        { __typename?: 'locations' }
        & Pick<Locations, 'coordinate' | 'locality' | 'state' | 'postcode'>
      )> }
    )>, contacts: Array<(
      { __typename?: 'contacts' }
      & Pick<Contacts, 'id' | 'email' | 'phone' | 'first_name' | 'last_name'>
    )>, client_industries: Array<(
      { __typename?: 'client_industries' }
      & { industry: (
        { __typename?: 'industries' }
        & Pick<Industries, 'id' | 'title'>
      ) }
    )>, client_jobs: Array<(
      { __typename?: 'client_jobs' }
      & { job: (
        { __typename?: 'jobs' }
        & Pick<Jobs, 'id' | 'title' | 'created_at'>
      ) }
    )> }
  )> }
);

export type Search_ClientsQueryVariables = Exact<{
  test?: Maybe<Boolean_Comparison_Exp>;
  business_name?: Maybe<String_Comparison_Exp>;
  terms_accepted_on?: Maybe<Timestamptz_Comparison_Exp>;
  contacts?: Maybe<Contacts_Bool_Exp>;
  updated_at?: Maybe<Timestamptz_Comparison_Exp>;
  client_industries?: Maybe<Client_Industries_Bool_Exp>;
  client_locations?: Maybe<Client_Locations_Bool_Exp>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Clients_Order_By> | Clients_Order_By>;
}>;


export type Search_ClientsQuery = (
  { __typename?: 'query_root' }
  & { clients: Array<(
    { __typename?: 'clients' }
    & Pick<Clients, 'id' | 'created_at' | 'updated_at' | 'business_name' | 'terms_accepted_on'>
    & { owner?: Maybe<(
      { __typename?: 'users' }
      & Pick<Users, 'updated_at'>
    )>, tenant: (
      { __typename?: 'tenants' }
      & Pick<Tenants, 'id' | 'name'>
    ), client_locations: Array<(
      { __typename?: 'client_locations' }
      & { location?: Maybe<(
        { __typename?: 'locations' }
        & Pick<Locations, 'coordinate' | 'locality' | 'state' | 'postcode'>
      )> }
    )>, contacts: Array<(
      { __typename?: 'contacts' }
      & Pick<Contacts, 'id' | 'email' | 'phone' | 'first_name' | 'last_name'>
    )>, client_industries: Array<(
      { __typename?: 'client_industries' }
      & { industry: (
        { __typename?: 'industries' }
        & Pick<Industries, 'id' | 'title'>
      ) }
    )>, client_jobs: Array<(
      { __typename?: 'client_jobs' }
      & { job: (
        { __typename?: 'jobs' }
        & Pick<Jobs, 'id' | 'title' | 'created_at'>
      ) }
    )> }
  )>, clients_aggregate: (
    { __typename?: 'clients_aggregate' }
    & { aggregate?: Maybe<(
      { __typename?: 'clients_aggregate_fields' }
      & Pick<Clients_Aggregate_Fields, 'count'>
    )> }
  ) }
);

export type Search_All_ClientsQueryVariables = Exact<{
  test?: Maybe<Boolean_Comparison_Exp>;
  business_name?: Maybe<String_Comparison_Exp>;
  terms_accepted_on?: Maybe<Timestamptz_Comparison_Exp>;
  contacts?: Maybe<Contacts_Bool_Exp>;
  updated_at?: Maybe<Timestamptz_Comparison_Exp>;
  client_industries?: Maybe<Client_Industries_Bool_Exp>;
  client_locations?: Maybe<Client_Locations_Bool_Exp>;
  order_by?: Maybe<Array<Clients_Order_By> | Clients_Order_By>;
}>;


export type Search_All_ClientsQuery = (
  { __typename?: 'query_root' }
  & { clients: Array<(
    { __typename?: 'clients' }
    & Pick<Clients, 'created_at' | 'updated_at' | 'business_name'>
    & { owner?: Maybe<(
      { __typename?: 'users' }
      & Pick<Users, 'updated_at'>
    )>, client_locations: Array<(
      { __typename?: 'client_locations' }
      & { location?: Maybe<(
        { __typename?: 'locations' }
        & Pick<Locations, 'coordinate' | 'locality' | 'state' | 'postcode'>
      )> }
    )>, contacts: Array<(
      { __typename?: 'contacts' }
      & Pick<Contacts, 'email' | 'first_name' | 'last_name'>
    )> }
  )> }
);

export type Unique_Client_By_OwnerIdQueryVariables = Exact<{
  ownerId: Scalars['String'];
}>;


export type Unique_Client_By_OwnerIdQuery = (
  { __typename?: 'query_root' }
  & { clients: Array<(
    { __typename?: 'clients' }
    & Pick<Clients, 'id' | 'business_name' | 'owner_id' | 'terms_accepted_on' | 'notes' | 'test'>
    & { tenant: (
      { __typename?: 'tenants' }
      & Pick<Tenants, 'id'>
    ), client_locations: Array<(
      { __typename?: 'client_locations' }
      & { location?: Maybe<(
        { __typename?: 'locations' }
        & Pick<Locations, 'coordinate' | 'locality' | 'state' | 'postcode'>
      )> }
    )>, contacts: Array<(
      { __typename?: 'contacts' }
      & Pick<Contacts, 'id' | 'email' | 'phone' | 'first_name' | 'last_name'>
    )>, client_industries: Array<(
      { __typename?: 'client_industries' }
      & { industry: (
        { __typename?: 'industries' }
        & Pick<Industries, 'id' | 'title'>
      ) }
    )>, client_jobs: Array<(
      { __typename?: 'client_jobs' }
      & { job: (
        { __typename?: 'jobs' }
        & Pick<Jobs, 'id' | 'title' | 'created_at'>
      ) }
    )>, owner?: Maybe<(
      { __typename?: 'users' }
      & Pick<Users, 'nylas_access_token' | 'nylas_calendar_id'>
    )> }
  )> }
);

export type Unique_ClientQueryVariables = Exact<{
  clientId: Scalars['uuid'];
}>;


export type Unique_ClientQuery = (
  { __typename?: 'query_root' }
  & { clients_by_pk?: Maybe<(
    { __typename?: 'clients' }
    & Pick<Clients, 'id' | 'business_name' | 'owner_id' | 'terms_accepted_on' | 'notes' | 'domain' | 'test' | 'created_at'>
    & { tenant: (
      { __typename?: 'tenants' }
      & Pick<Tenants, 'id' | 'name' | 'terms_url'>
    ), client_locations: Array<(
      { __typename?: 'client_locations' }
      & { location?: Maybe<(
        { __typename?: 'locations' }
        & Pick<Locations, 'coordinate' | 'locality' | 'state' | 'postcode'>
      )> }
    )>, contacts: Array<(
      { __typename?: 'contacts' }
      & Pick<Contacts, 'id' | 'email' | 'phone' | 'first_name' | 'last_name'>
    )>, client_industries: Array<(
      { __typename?: 'client_industries' }
      & { industry: (
        { __typename?: 'industries' }
        & Pick<Industries, 'id' | 'title'>
      ) }
    )>, client_jobs: Array<(
      { __typename?: 'client_jobs' }
      & { job: (
        { __typename?: 'jobs' }
        & Pick<Jobs, 'id' | 'title' | 'created_at'>
      ) }
    )>, owner?: Maybe<(
      { __typename?: 'users' }
      & Pick<Users, 'created_at' | 'nylas_access_token' | 'nylas_calendar_id'>
    )>, enterprise_clients: Array<(
      { __typename?: 'enterprise_clients' }
      & Pick<Enterprise_Clients, 'enterprise_id'>
    )> }
  )> }
);

export type All_Client_JobsQueryVariables = Exact<{
  clientId?: Maybe<Scalars['uuid']>;
}>;


export type All_Client_JobsQuery = (
  { __typename?: 'query_root' }
  & { jobs: Array<(
    { __typename?: 'jobs' }
    & Pick<Jobs, 'id' | 'title'>
  )> }
);

export type Client_SelectQueryVariables = Exact<{ [key: string]: never; }>;


export type Client_SelectQuery = (
  { __typename?: 'query_root' }
  & { clients: Array<(
    { __typename?: 'clients' }
    & Pick<Clients, 'id' | 'business_name'>
  )> }
);

export type Attach_Client_To_JobMutationVariables = Exact<{
  jobId: Scalars['uuid'];
  object: Array<Client_Jobs_Insert_Input> | Client_Jobs_Insert_Input;
}>;


export type Attach_Client_To_JobMutation = (
  { __typename?: 'mutation_root' }
  & { remove_unused?: Maybe<(
    { __typename?: 'client_jobs_mutation_response' }
    & Pick<Client_Jobs_Mutation_Response, 'affected_rows'>
  )>, insert_client_jobs?: Maybe<(
    { __typename?: 'client_jobs_mutation_response' }
    & Pick<Client_Jobs_Mutation_Response, 'affected_rows'>
  )> }
);

export type Unique_Client_By_EmailQueryVariables = Exact<{
  email?: Maybe<Scalars['String']>;
  limit?: Maybe<Scalars['Int']>;
}>;


export type Unique_Client_By_EmailQuery = (
  { __typename?: 'query_root' }
  & { clients: Array<(
    { __typename?: 'clients' }
    & Pick<Clients, 'id' | 'business_name'>
    & { contacts: Array<(
      { __typename?: 'contacts' }
      & Pick<Contacts, 'first_name' | 'last_name' | 'email' | 'phone'>
    )> }
  )> }
);

export type Create_ContactMutationVariables = Exact<{
  object: Contacts_Insert_Input;
}>;


export type Create_ContactMutation = (
  { __typename?: 'mutation_root' }
  & { insert_contacts_one?: Maybe<(
    { __typename?: 'contacts' }
    & Pick<Contacts, 'id' | 'first_name' | 'last_name' | 'email' | 'phone' | 'owner_id'>
  )> }
);

export type Delete_Test_ContactMutationVariables = Exact<{
  clientId: Scalars['uuid'];
}>;


export type Delete_Test_ContactMutation = (
  { __typename?: 'mutation_root' }
  & { delete_contacts?: Maybe<(
    { __typename?: 'contacts_mutation_response' }
    & Pick<Contacts_Mutation_Response, 'affected_rows'>
  )> }
);

export type Update_Contact_By_PkMutationVariables = Exact<{
  id: Scalars['uuid'];
  object: Contacts_Set_Input;
}>;


export type Update_Contact_By_PkMutation = (
  { __typename?: 'mutation_root' }
  & { update_contacts_by_pk?: Maybe<(
    { __typename?: 'contacts' }
    & Pick<Contacts, 'id' | 'email' | 'last_name' | 'first_name' | 'domain'>
  )> }
);

export type Unique_ContactQueryVariables = Exact<{
  contactId: Scalars['uuid'];
}>;


export type Unique_ContactQuery = (
  { __typename?: 'query_root' }
  & { contacts_by_pk?: Maybe<(
    { __typename?: 'contacts' }
    & Pick<Contacts, 'id' | 'first_name' | 'last_name' | 'email' | 'phone' | 'owner_id'>
    & { client: (
      { __typename?: 'clients' }
      & Pick<Clients, 'id'>
      & { tenant: (
        { __typename?: 'tenants' }
        & Pick<Tenants, 'id' | 'shortname'>
      ) }
    ) }
  )> }
);

export type Upsert_Unique_Candidate_DegreesMutationVariables = Exact<{
  objects: Array<Candidate_Degrees_Insert_Input> | Candidate_Degrees_Insert_Input;
  candidateId: Scalars['uuid'];
  degreeIds?: Maybe<Array<Scalars['uuid']> | Scalars['uuid']>;
}>;


export type Upsert_Unique_Candidate_DegreesMutation = (
  { __typename?: 'mutation_root' }
  & { insert_candidate_degrees?: Maybe<(
    { __typename?: 'candidate_degrees_mutation_response' }
    & Pick<Candidate_Degrees_Mutation_Response, 'affected_rows'>
  )>, remove_unused?: Maybe<(
    { __typename?: 'candidate_degrees_mutation_response' }
    & Pick<Candidate_Degrees_Mutation_Response, 'affected_rows'>
  )>, reinstate_used?: Maybe<(
    { __typename?: 'candidate_degrees_mutation_response' }
    & Pick<Candidate_Degrees_Mutation_Response, 'affected_rows'>
  )> }
);

export type All_EnterprisesQueryVariables = Exact<{ [key: string]: never; }>;


export type All_EnterprisesQuery = (
  { __typename?: 'query_root' }
  & { enterprises: Array<(
    { __typename?: 'enterprises' }
    & Pick<Enterprises, 'id' | 'name' | 'shortname' | 'year_founded' | 'staff_count' | 'staff_count_max' | 'website' | 'logo' | 'logo_inverse' | 'overview' | 'overview_extended' | 'theme_bg_color' | 'email' | 'enabled' | 'created_at' | 'lp_job' | 'lp_img' | 'public'>
    & { enterprise_locations: Array<(
      { __typename?: 'enterprise_locations' }
      & { location?: Maybe<(
        { __typename?: 'locations' }
        & Pick<Locations, 'locality' | 'state' | 'postcode'>
      )> }
    )>, candidate_follows_aggregate: (
      { __typename?: 'candidate_follows_aggregate' }
      & { aggregate?: Maybe<(
        { __typename?: 'candidate_follows_aggregate_fields' }
        & Pick<Candidate_Follows_Aggregate_Fields, 'count'>
      )> }
    ) }
  )> }
);

export type All_Public_EnterprisesQueryVariables = Exact<{ [key: string]: never; }>;


export type All_Public_EnterprisesQuery = (
  { __typename?: 'query_root' }
  & { enterprises: Array<(
    { __typename?: 'enterprises' }
    & Pick<Enterprises, 'id' | 'name' | 'shortname' | 'year_founded' | 'staff_count' | 'staff_count_max' | 'website' | 'logo' | 'logo_inverse' | 'overview' | 'overview_extended' | 'theme_bg_color' | 'email' | 'enabled' | 'created_at' | 'lp_job' | 'lp_img' | 'public'>
    & { enterprise_locations: Array<(
      { __typename?: 'enterprise_locations' }
      & { location?: Maybe<(
        { __typename?: 'locations' }
        & Pick<Locations, 'locality' | 'state' | 'postcode'>
      )> }
    )> }
  )> }
);

export type Unique_Enterprise_By_ShortnameQueryVariables = Exact<{
  shortname: Scalars['String'];
}>;


export type Unique_Enterprise_By_ShortnameQuery = (
  { __typename?: 'query_root' }
  & { enterprises: Array<(
    { __typename?: 'enterprises' }
    & Pick<Enterprises, 'id' | 'name' | 'shortname' | 'year_founded' | 'staff_count' | 'staff_count_max' | 'website' | 'logo' | 'overview' | 'overview_extended' | 'theme_bg_color' | 'lp_job'>
    & { enterprise_locations: Array<(
      { __typename?: 'enterprise_locations' }
      & { location?: Maybe<(
        { __typename?: 'locations' }
        & Pick<Locations, 'coordinate' | 'postcode' | 'state' | 'locality'>
      )> }
    )> }
  )> }
);

export type Unique_EnterpriseQueryVariables = Exact<{
  id?: Maybe<Scalars['uuid']>;
}>;


export type Unique_EnterpriseQuery = (
  { __typename?: 'query_root' }
  & { enterprises_by_pk?: Maybe<(
    { __typename?: 'enterprises' }
    & Pick<Enterprises, 'created_at' | 'deleted_at' | 'email' | 'enabled' | 'id' | 'logo' | 'logo_inverse' | 'lp_img' | 'name' | 'overview' | 'lp_job' | 'overview_extended' | 'phone' | 'public' | 'shortname' | 'staff_count' | 'staff_count_max' | 'website' | 'year_founded'>
    & { enterprise_locations: Array<(
      { __typename?: 'enterprise_locations' }
      & Pick<Enterprise_Locations, 'locality' | 'postcode' | 'state'>
    )>, candidate_follows_aggregate: (
      { __typename?: 'candidate_follows_aggregate' }
      & { aggregate?: Maybe<(
        { __typename?: 'candidate_follows_aggregate_fields' }
        & Pick<Candidate_Follows_Aggregate_Fields, 'count'>
      )> }
    ), candidate_follows: Array<(
      { __typename?: 'candidate_follows' }
      & Pick<Candidate_Follows, 'created_at' | 'deleted_at'>
      & { candidate: (
        { __typename?: 'candidates' }
        & Pick<Candidates, 'email' | 'id' | 'first_name' | 'last_name' | 'status' | 'photo_url'>
      ) }
    )> }
  )> }
);

export type Unique_Enterprise_Clients_By_Owner_IdQueryVariables = Exact<{
  ownerId?: Maybe<Scalars['String']>;
}>;


export type Unique_Enterprise_Clients_By_Owner_IdQuery = (
  { __typename?: 'query_root' }
  & { enterprise_clients: Array<(
    { __typename?: 'enterprise_clients' }
    & Pick<Enterprise_Clients, 'enterprise_id' | 'client_id' | 'deleted_at' | 'created_at'>
    & { enterprise: (
      { __typename?: 'enterprises' }
      & Pick<Enterprises, 'logo' | 'logo_inverse' | 'email' | 'staff_count' | 'staff_count_max' | 'name' | 'overview' | 'overview_extended' | 'theme_bg_color' | 'website' | 'shortname'>
    ) }
  )> }
);

export type Upsert_Unique_EnterpriseMutationVariables = Exact<{
  enterprise: Enterprises_Insert_Input;
  columns?: Maybe<Array<Enterprises_Update_Column> | Enterprises_Update_Column>;
}>;


export type Upsert_Unique_EnterpriseMutation = (
  { __typename?: 'mutation_root' }
  & { insert_enterprises_one?: Maybe<(
    { __typename?: 'enterprises' }
    & Pick<Enterprises, 'created_at' | 'id'>
  )> }
);

export type Upsert_Enterprise_ClientMutationVariables = Exact<{
  clientId?: Maybe<Scalars['uuid']>;
  enterpriseId?: Maybe<Scalars['uuid']>;
}>;


export type Upsert_Enterprise_ClientMutation = (
  { __typename?: 'mutation_root' }
  & { insert_enterprise_clients?: Maybe<(
    { __typename?: 'enterprise_clients_mutation_response' }
    & Pick<Enterprise_Clients_Mutation_Response, 'affected_rows'>
  )>, remove_unused?: Maybe<(
    { __typename?: 'enterprise_clients_mutation_response' }
    & Pick<Enterprise_Clients_Mutation_Response, 'affected_rows'>
  )> }
);

export type Remove_Enterprise_Client_By_Client_IdMutationVariables = Exact<{
  clientId?: Maybe<Scalars['uuid']>;
}>;


export type Remove_Enterprise_Client_By_Client_IdMutation = (
  { __typename?: 'mutation_root' }
  & { update_enterprise_clients_many?: Maybe<Array<Maybe<(
    { __typename?: 'enterprise_clients_mutation_response' }
    & Pick<Enterprise_Clients_Mutation_Response, 'affected_rows'>
  )>>> }
);

export type Upsert_Exclusive_CandidateMutationVariables = Exact<{
  candidateId?: Maybe<Scalars['uuid']>;
  enterpriseId?: Maybe<Scalars['uuid']>;
  published?: Maybe<Scalars['Boolean']>;
}>;


export type Upsert_Exclusive_CandidateMutation = (
  { __typename?: 'mutation_root' }
  & { insert_exclusive_candidates?: Maybe<(
    { __typename?: 'exclusive_candidates_mutation_response' }
    & Pick<Exclusive_Candidates_Mutation_Response, 'affected_rows'>
  )> }
);

export type All_Exclusive_By_Candidate_IdQueryVariables = Exact<{
  candidateId?: Maybe<Scalars['uuid']>;
}>;


export type All_Exclusive_By_Candidate_IdQuery = (
  { __typename?: 'query_root' }
  & { exclusive_candidates: Array<(
    { __typename?: 'exclusive_candidates' }
    & Pick<Exclusive_Candidates, 'enterprise_id' | 'published'>
  )> }
);

export type All_Exclusive_By_Enterprise_IdQueryVariables = Exact<{
  enterpriseId?: Maybe<Scalars['uuid']>;
}>;


export type All_Exclusive_By_Enterprise_IdQuery = (
  { __typename?: 'query_root' }
  & { exclusive_candidates: Array<(
    { __typename?: 'exclusive_candidates' }
    & Pick<Exclusive_Candidates, 'enterprise_id' | 'candidate_id' | 'published'>
    & { enterprise: (
      { __typename?: 'enterprises' }
      & Pick<Enterprises, 'name'>
    ), candidate: (
      { __typename?: 'candidates' }
      & Pick<Candidates, 'id' | 'owner_id' | 'published_on' | 'published' | 'first_name' | 'last_name' | 'photo_url' | 'cv_url' | 'test' | 'graduates_on' | 'status' | 'open_to_part_time_prior_to_graduation' | 'available_part_time_days_from' | 'available_part_time_days_to' | 'work_arrangement'>
      & { candidate_industries: Array<(
        { __typename?: 'candidate_industries' }
        & { industry: (
          { __typename?: 'industries' }
          & Pick<Industries, 'id'>
        ) }
      )>, candidate_degrees: Array<(
        { __typename?: 'candidate_degrees' }
        & { degree: (
          { __typename?: 'degrees' }
          & Pick<Degrees, 'id'>
        ) }
      )>, candidate_majors: Array<(
        { __typename?: 'candidate_majors' }
        & { major: (
          { __typename?: 'majors' }
          & Pick<Majors, 'id'>
        ) }
      )>, candidate_skills: Array<(
        { __typename?: 'candidate_skills' }
        & { skill: (
          { __typename?: 'skills' }
          & Pick<Skills, 'id'>
        ) }
      )>, candidate_locations: Array<(
        { __typename?: 'candidate_locations' }
        & { location?: Maybe<(
          { __typename?: 'locations' }
          & Pick<Locations, 'coordinate' | 'postcode' | 'locality' | 'state'>
        )> }
      )> }
    ) }
  )> }
);

export type Fb_Server_EventMutationVariables = Exact<{
  event: Scalars['String'];
  emails?: Maybe<Array<Maybe<Scalars['String']>> | Maybe<Scalars['String']>>;
  phones?: Maybe<Array<Maybe<Scalars['String']>> | Maybe<Scalars['String']>>;
  sourceUrl: Scalars['String'];
  ownerId: Scalars['String'];
  tenantId: Scalars['String'];
  candidateId: Scalars['String'];
}>;


export type Fb_Server_EventMutation = (
  { __typename?: 'mutation_root' }
  & Pick<Mutation_Root, 'fb_server_event'>
);

export type Feature_Popups_By_RoleQueryVariables = Exact<{
  role?: Maybe<User_Role_Enums_Comparison_Exp>;
}>;


export type Feature_Popups_By_RoleQuery = (
  { __typename?: 'query_root' }
  & { feature_popups: Array<(
    { __typename?: 'feature_popups' }
    & Pick<Feature_Popups, 'goes_after' | 'id' | 'location' | 'location_css_selector' | 'photo_url' | 'primary_button_text' | 'primary_button_url' | 'secondary_button_text' | 'show_until' | 'title' | 'body'>
    & { events: Array<(
      { __typename?: 'events' }
      & Pick<Events, 'id' | 'created_at' | 'event' | 'owner_id'>
    )> }
  )> }
);

export type Follows_By_Candidate_IdQueryVariables = Exact<{
  candidate_id?: Maybe<Scalars['uuid']>;
}>;


export type Follows_By_Candidate_IdQuery = (
  { __typename?: 'query_root' }
  & { candidate_follows: Array<(
    { __typename?: 'candidate_follows' }
    & Pick<Candidate_Follows, 'enterprise_id'>
  )> }
);

export type Follows_By_Owner_IdQueryVariables = Exact<{
  owner_id?: Maybe<Scalars['String']>;
}>;


export type Follows_By_Owner_IdQuery = (
  { __typename?: 'query_root' }
  & { candidate_follows: Array<(
    { __typename?: 'candidate_follows' }
    & Pick<Candidate_Follows, 'enterprise_id'>
  )> }
);

export type Upsert_Unique_FollowMutationVariables = Exact<{
  enterprise_id?: Maybe<Scalars['uuid']>;
  candidate_id?: Maybe<Scalars['uuid']>;
}>;


export type Upsert_Unique_FollowMutation = (
  { __typename?: 'mutation_root' }
  & { insert_candidate_follows?: Maybe<(
    { __typename?: 'candidate_follows_mutation_response' }
    & Pick<Candidate_Follows_Mutation_Response, 'affected_rows'>
  )> }
);

export type Upsert_Unique_UnfollowMutationVariables = Exact<{
  candidate_id?: Maybe<Scalars['uuid']>;
  enterprise_id?: Maybe<Scalars['uuid']>;
}>;


export type Upsert_Unique_UnfollowMutation = (
  { __typename?: 'mutation_root' }
  & { update_candidate_follows?: Maybe<(
    { __typename?: 'candidate_follows_mutation_response' }
    & Pick<Candidate_Follows_Mutation_Response, 'affected_rows'>
  )> }
);

export type All_Candidate_FollowsQueryVariables = Exact<{ [key: string]: never; }>;


export type All_Candidate_FollowsQuery = (
  { __typename?: 'query_root' }
  & { candidate_follows: Array<(
    { __typename?: 'candidate_follows' }
    & Pick<Candidate_Follows, 'created_at' | 'deleted_at'>
    & { enterprise: (
      { __typename?: 'enterprises' }
      & Pick<Enterprises, 'logo' | 'id' | 'name'>
    ), candidate: (
      { __typename?: 'candidates' }
      & Pick<Candidates, 'last_name' | 'id' | 'first_name' | 'status' | 'photo_url'>
    ) }
  )> }
);

export type All_Follows_By_Enterprise_IdQueryVariables = Exact<{
  enterpriseId?: Maybe<Scalars['uuid']>;
}>;


export type All_Follows_By_Enterprise_IdQuery = (
  { __typename?: 'query_root' }
  & { candidate_follows: Array<(
    { __typename?: 'candidate_follows' }
    & Pick<Candidate_Follows, 'created_at' | 'deleted_at' | 'updated_at'>
    & { enterprise: (
      { __typename?: 'enterprises' }
      & Pick<Enterprises, 'logo' | 'id' | 'name' | 'shortname'>
    ), candidate: (
      { __typename?: 'candidates' }
      & Pick<Candidates, 'last_name' | 'id' | 'first_name' | 'status' | 'photo_url' | 'published'>
      & { candidate_courses: Array<(
        { __typename?: 'candidate_courses' }
        & Pick<Candidate_Courses, 'result' | 'completed' | 'course_id'>
        & { course: (
          { __typename?: 'courses' }
          & Pick<Courses, 'deleted_at' | 'name' | 'img' | 'overview'>
        ) }
      )>, interview_participants_aggregate: (
        { __typename?: 'interview_participants_aggregate' }
        & { aggregate?: Maybe<(
          { __typename?: 'interview_participants_aggregate_fields' }
          & Pick<Interview_Participants_Aggregate_Fields, 'count'>
        )> }
      ) }
    ) }
  )> }
);

export type All_Sign_Ups_By_Ec_ShortnameQueryVariables = Exact<{
  source?: Maybe<Scalars['String']>;
}>;


export type All_Sign_Ups_By_Ec_ShortnameQuery = (
  { __typename?: 'query_root' }
  & { candidates: Array<(
    { __typename?: 'candidates' }
    & Pick<Candidates, 'id' | 'created_at'>
  )> }
);

export type Unique_Candidate_Default_ValuesQueryVariables = Exact<{
  candidateId: Scalars['uuid'];
}>;


export type Unique_Candidate_Default_ValuesQuery = (
  { __typename?: 'query_root' }
  & { unique_candidate_default_values?: Maybe<(
    { __typename?: 'UniqueCandidateOutputAlt' }
    & Pick<UniqueCandidateOutputAlt, 'id' | 'created_at' | 'updated_at' | 'first_name' | 'bio' | 'status' | 'published' | 'relocate' | 'graduates_on' | 'graduates_on_month' | 'graduates_on_year' | 'open_to_part_time_prior_to_graduation' | 'work_arrangement' | 'has_work_experience' | 'photo_url' | 'university_id' | 'residency_status' | 'candidate_skills' | 'candidate_industries'>
    & { part_time_availability?: Maybe<(
      { __typename?: 'PartTimeAvailability' }
      & Pick<PartTimeAvailability, 'available_part_time_days_from' | 'available_part_time_days_to'>
    )>, candidate_degrees?: Maybe<Array<Maybe<(
      { __typename?: 'FormOption' }
      & Pick<FormOption, 'id' | 'title'>
    )>>>, candidate_majors?: Maybe<Array<Maybe<(
      { __typename?: 'FormOption' }
      & Pick<FormOption, 'id' | 'title'>
    )>>>, candidate_locations?: Maybe<Array<Maybe<(
      { __typename?: 'CandidateLocation' }
      & { location?: Maybe<(
        { __typename?: 'Location' }
        & Pick<Location, 'locality' | 'state' | 'postcode'>
      )> }
    )>>> }
  )> }
);

export type Form_OptionsQueryVariables = Exact<{ [key: string]: never; }>;


export type Form_OptionsQuery = (
  { __typename?: 'query_root' }
  & { form_options?: Maybe<(
    { __typename?: 'FormOptionsOutput' }
    & { skills?: Maybe<Array<Maybe<(
      { __typename?: 'KV' }
      & Pick<Kv, 'id' | 'title'>
    )>>>, degrees?: Maybe<Array<Maybe<(
      { __typename?: 'KV' }
      & Pick<Kv, 'id' | 'title'>
    )>>>, majors?: Maybe<Array<Maybe<(
      { __typename?: 'KV' }
      & Pick<Kv, 'id' | 'title'>
    )>>>, industries?: Maybe<Array<Maybe<(
      { __typename?: 'KV' }
      & Pick<Kv, 'id' | 'title'>
    )>>>, work_arrangement?: Maybe<Array<Maybe<(
      { __typename?: 'KV' }
      & Pick<Kv, 'id' | 'title'>
    )>>>, contract_type?: Maybe<Array<Maybe<(
      { __typename?: 'KV' }
      & Pick<Kv, 'id' | 'title'>
    )>>>, candidate_status?: Maybe<Array<Maybe<(
      { __typename?: 'KV' }
      & Pick<Kv, 'id' | 'title'>
    )>>>, job_status?: Maybe<Array<Maybe<(
      { __typename?: 'KV' }
      & Pick<Kv, 'id' | 'title'>
    )>>>, universities?: Maybe<Array<Maybe<(
      { __typename?: 'KV' }
      & Pick<Kv, 'id' | 'title'>
    )>>> }
  )> }
);

export type Upsert_Unique_Candidate_IndustriesMutationVariables = Exact<{
  objects: Array<Candidate_Industries_Insert_Input> | Candidate_Industries_Insert_Input;
  candidateId: Scalars['uuid'];
  industryIds?: Maybe<Array<Scalars['uuid']> | Scalars['uuid']>;
}>;


export type Upsert_Unique_Candidate_IndustriesMutation = (
  { __typename?: 'mutation_root' }
  & { insert_candidate_industries?: Maybe<(
    { __typename?: 'candidate_industries_mutation_response' }
    & Pick<Candidate_Industries_Mutation_Response, 'affected_rows'>
  )>, remove_unused?: Maybe<(
    { __typename?: 'candidate_industries_mutation_response' }
    & Pick<Candidate_Industries_Mutation_Response, 'affected_rows'>
  )>, reinstate_used?: Maybe<(
    { __typename?: 'candidate_industries_mutation_response' }
    & Pick<Candidate_Industries_Mutation_Response, 'affected_rows'>
  )> }
);

export type Upsert_Unique_Client_IndustriesMutationVariables = Exact<{
  objects: Array<Client_Industries_Insert_Input> | Client_Industries_Insert_Input;
  clientId: Scalars['uuid'];
  industryIds?: Maybe<Array<Scalars['uuid']> | Scalars['uuid']>;
}>;


export type Upsert_Unique_Client_IndustriesMutation = (
  { __typename?: 'mutation_root' }
  & { insert_client_industries?: Maybe<(
    { __typename?: 'client_industries_mutation_response' }
    & Pick<Client_Industries_Mutation_Response, 'affected_rows'>
  )>, remove_unused?: Maybe<(
    { __typename?: 'client_industries_mutation_response' }
    & Pick<Client_Industries_Mutation_Response, 'affected_rows'>
  )>, reinstate_used?: Maybe<(
    { __typename?: 'client_industries_mutation_response' }
    & Pick<Client_Industries_Mutation_Response, 'affected_rows'>
  )> }
);

export type Unique_InterviewQueryVariables = Exact<{
  interviewId: Scalars['uuid'];
}>;


export type Unique_InterviewQuery = (
  { __typename?: 'query_root' }
  & { interviews_by_pk?: Maybe<(
    { __typename?: 'interviews' }
    & Pick<Interviews, 'mode' | 'id' | 'job_id' | 'nylas_event_id' | 'location' | 'outcome' | 'owner_id' | 'phone' | 'status' | 'meeting_link' | 'type' | 'updated_at' | 'metadata' | 'created_at' | 'deleted_at' | 'scheduled_on' | 'time_zone'>
    & { interview_participants: Array<(
      { __typename?: 'interview_participants' }
      & Pick<Interview_Participants, 'id' | 'created_at' | 'updated_at' | 'deleted_at' | 'interview_id' | 'accepted' | 'candidate_id' | 'reason_to_decline'>
      & { candidate?: Maybe<(
        { __typename?: 'candidates' }
        & Pick<Candidates, 'id' | 'first_name' | 'last_name' | 'photo_url' | 'email' | 'phone'>
      )>, client?: Maybe<(
        { __typename?: 'clients' }
        & Pick<Clients, 'id' | 'business_name'>
        & { tenant: (
          { __typename?: 'tenants' }
          & Pick<Tenants, 'logo' | 'name' | 'id'>
        ), contacts: Array<(
          { __typename?: 'contacts' }
          & Pick<Contacts, 'email' | 'first_name' | 'last_name' | 'phone'>
        )> }
      )> }
    )>, job?: Maybe<(
      { __typename?: 'jobs' }
      & Pick<Jobs, 'title' | 'description' | 'id'>
    )> }
  )> }
);

export type Upsert_Unique_InterviewMutationVariables = Exact<{
  interview: Interviews_Insert_Input;
}>;


export type Upsert_Unique_InterviewMutation = (
  { __typename?: 'mutation_root' }
  & { insert_interviews_one?: Maybe<(
    { __typename?: 'interviews' }
    & Pick<Interviews, 'id'>
  )> }
);

export type Upsert_Unique_Interview_ParticipantsMutationVariables = Exact<{
  objects?: Maybe<Array<Interview_Participants_Insert_Input> | Interview_Participants_Insert_Input>;
}>;


export type Upsert_Unique_Interview_ParticipantsMutation = (
  { __typename?: 'mutation_root' }
  & { insert_interview_participants?: Maybe<(
    { __typename?: 'interview_participants_mutation_response' }
    & Pick<Interview_Participants_Mutation_Response, 'affected_rows'>
  )> }
);

export type All_InterviewsQueryVariables = Exact<{ [key: string]: never; }>;


export type All_InterviewsQuery = (
  { __typename?: 'query_root' }
  & { interviews: Array<(
    { __typename?: 'interviews' }
    & Pick<Interviews, 'mode' | 'id' | 'job_id' | 'location' | 'nylas_event_id' | 'outcome' | 'owner_id' | 'phone' | 'status' | 'meeting_link' | 'type' | 'updated_at' | 'metadata' | 'created_at' | 'deleted_at' | 'scheduled_on'>
    & { interview_participants: Array<(
      { __typename?: 'interview_participants' }
      & Pick<Interview_Participants, 'id' | 'created_at' | 'updated_at' | 'deleted_at' | 'interview_id' | 'accepted' | 'candidate_id'>
      & { candidate?: Maybe<(
        { __typename?: 'candidates' }
        & Pick<Candidates, 'id' | 'first_name' | 'last_name' | 'photo_url' | 'email' | 'phone'>
      )>, client?: Maybe<(
        { __typename?: 'clients' }
        & Pick<Clients, 'id' | 'business_name'>
        & { tenant: (
          { __typename?: 'tenants' }
          & Pick<Tenants, 'logo' | 'name' | 'id'>
        ), contacts: Array<(
          { __typename?: 'contacts' }
          & Pick<Contacts, 'email' | 'first_name' | 'last_name' | 'phone'>
        )> }
      )> }
    )>, job?: Maybe<(
      { __typename?: 'jobs' }
      & Pick<Jobs, 'title' | 'description'>
    )> }
  )> }
);

export type Update_InterviewMutationVariables = Exact<{
  id: Scalars['uuid'];
  _set?: Maybe<Interviews_Set_Input>;
}>;


export type Update_InterviewMutation = (
  { __typename?: 'mutation_root' }
  & { update_interviews?: Maybe<(
    { __typename?: 'interviews_mutation_response' }
    & Pick<Interviews_Mutation_Response, 'affected_rows'>
  )> }
);

export type Update_Interview_ParticipantsMutationVariables = Exact<{
  id: Scalars['uuid'];
  _set?: Maybe<Interview_Participants_Set_Input>;
}>;


export type Update_Interview_ParticipantsMutation = (
  { __typename?: 'mutation_root' }
  & { update_interview_participants?: Maybe<(
    { __typename?: 'interview_participants_mutation_response' }
    & Pick<Interview_Participants_Mutation_Response, 'affected_rows'>
  )> }
);

export type All_Accepted_Scheduled_InterviewsQueryVariables = Exact<{ [key: string]: never; }>;


export type All_Accepted_Scheduled_InterviewsQuery = (
  { __typename?: 'query_root' }
  & { interviews: Array<(
    { __typename?: 'interviews' }
    & Pick<Interviews, 'mode' | 'id' | 'job_id' | 'location' | 'nylas_event_id' | 'outcome' | 'owner_id' | 'phone' | 'status' | 'meeting_link' | 'type' | 'updated_at' | 'metadata' | 'created_at' | 'deleted_at' | 'scheduled_on'>
    & { job?: Maybe<(
      { __typename?: 'jobs' }
      & Pick<Jobs, 'title'>
    )>, interview_participants: Array<(
      { __typename?: 'interview_participants' }
      & Pick<Interview_Participants, 'id' | 'created_at' | 'updated_at' | 'deleted_at' | 'interview_id' | 'accepted' | 'candidate_id'>
      & { candidate?: Maybe<(
        { __typename?: 'candidates' }
        & Pick<Candidates, 'id' | 'first_name' | 'last_name' | 'photo_url' | 'email' | 'phone'>
      )>, client?: Maybe<(
        { __typename?: 'clients' }
        & Pick<Clients, 'id' | 'business_name'>
        & { tenant: (
          { __typename?: 'tenants' }
          & Pick<Tenants, 'logo' | 'name' | 'id'>
        ), contacts: Array<(
          { __typename?: 'contacts' }
          & Pick<Contacts, 'email' | 'first_name' | 'last_name' | 'phone'>
        )> }
      )> }
    )> }
  )> }
);

export type All_Pending_Unaccepted_InterviewsQueryVariables = Exact<{ [key: string]: never; }>;


export type All_Pending_Unaccepted_InterviewsQuery = (
  { __typename?: 'query_root' }
  & { interviews: Array<(
    { __typename?: 'interviews' }
    & Pick<Interviews, 'mode' | 'id' | 'job_id' | 'location' | 'nylas_event_id' | 'outcome' | 'owner_id' | 'phone' | 'status' | 'meeting_link' | 'type' | 'updated_at' | 'metadata' | 'created_at' | 'deleted_at' | 'scheduled_on'>
    & { job?: Maybe<(
      { __typename?: 'jobs' }
      & Pick<Jobs, 'title'>
    )>, interview_participants: Array<(
      { __typename?: 'interview_participants' }
      & Pick<Interview_Participants, 'id' | 'created_at' | 'updated_at' | 'deleted_at' | 'interview_id' | 'accepted' | 'candidate_id'>
      & { candidate?: Maybe<(
        { __typename?: 'candidates' }
        & Pick<Candidates, 'id' | 'first_name' | 'last_name' | 'photo_url' | 'email' | 'phone'>
      )>, client?: Maybe<(
        { __typename?: 'clients' }
        & Pick<Clients, 'id' | 'business_name'>
        & { tenant: (
          { __typename?: 'tenants' }
          & Pick<Tenants, 'logo' | 'name' | 'id'>
        ), contacts: Array<(
          { __typename?: 'contacts' }
          & Pick<Contacts, 'email' | 'first_name' | 'last_name' | 'phone'>
        )> }
      )> }
    )> }
  )> }
);

export type Unique_Candidate_InterviewsQueryVariables = Exact<{
  id?: Maybe<Scalars['uuid']>;
  accepted?: Maybe<Scalars['Boolean']>;
}>;


export type Unique_Candidate_InterviewsQuery = (
  { __typename?: 'query_root' }
  & { interviews: Array<(
    { __typename?: 'interviews' }
    & Pick<Interviews, 'id' | 'mode' | 'phone' | 'location' | 'scheduled_on' | 'type' | 'edit_hash' | 'created_at'>
    & { job?: Maybe<(
      { __typename?: 'jobs' }
      & Pick<Jobs, 'title' | 'description'>
    )>, interview_participants: Array<(
      { __typename?: 'interview_participants' }
      & Pick<Interview_Participants, 'id' | 'accepted' | 'declined'>
      & { client?: Maybe<(
        { __typename?: 'clients' }
        & Pick<Clients, 'id' | 'business_name'>
        & { tenant: (
          { __typename?: 'tenants' }
          & Pick<Tenants, 'logo' | 'name' | 'id'>
        ), contacts: Array<(
          { __typename?: 'contacts' }
          & Pick<Contacts, 'email' | 'first_name' | 'last_name' | 'phone'>
        )> }
      )> }
    )> }
  )> }
);

export type Interview_SearchQueryVariables = Exact<{
  interview?: Maybe<Interviews_Bool_Exp>;
  order_by?: Maybe<Array<Interviews_Order_By> | Interviews_Order_By>;
}>;


export type Interview_SearchQuery = (
  { __typename?: 'query_root' }
  & { interviews: Array<(
    { __typename?: 'interviews' }
    & Pick<Interviews, 'mode' | 'id' | 'job_id' | 'location' | 'nylas_event_id' | 'outcome' | 'owner_id' | 'phone' | 'status' | 'meeting_link' | 'type' | 'updated_at' | 'metadata' | 'created_at' | 'deleted_at' | 'scheduled_on'>
    & { interview_participants: Array<(
      { __typename?: 'interview_participants' }
      & Pick<Interview_Participants, 'id' | 'created_at' | 'updated_at' | 'deleted_at' | 'interview_id' | 'accepted' | 'candidate_id'>
      & { candidate?: Maybe<(
        { __typename?: 'candidates' }
        & Pick<Candidates, 'id' | 'first_name' | 'last_name' | 'photo_url' | 'email' | 'phone'>
      )>, client?: Maybe<(
        { __typename?: 'clients' }
        & Pick<Clients, 'id' | 'business_name'>
        & { tenant: (
          { __typename?: 'tenants' }
          & Pick<Tenants, 'logo' | 'name' | 'id'>
        ), contacts: Array<(
          { __typename?: 'contacts' }
          & Pick<Contacts, 'email' | 'first_name' | 'last_name' | 'phone'>
        )> }
      )> }
    )>, job?: Maybe<(
      { __typename?: 'jobs' }
      & Pick<Jobs, 'title' | 'description'>
    )> }
  )> }
);

export type Interview_Search_OptionsQueryVariables = Exact<{ [key: string]: never; }>;


export type Interview_Search_OptionsQuery = (
  { __typename?: 'query_root' }
  & { interviews: Array<(
    { __typename?: 'interviews' }
    & { interview_participants: Array<(
      { __typename?: 'interview_participants' }
      & { client?: Maybe<(
        { __typename?: 'clients' }
        & Pick<Clients, 'business_name'>
        & { contacts: Array<(
          { __typename?: 'contacts' }
          & Pick<Contacts, 'first_name' | 'last_name'>
        )> }
      )> }
    )> }
  )> }
);

export type Delete_Test_InterviewMutationVariables = Exact<{
  interviewId: Scalars['uuid'];
}>;


export type Delete_Test_InterviewMutation = (
  { __typename?: 'mutation_root' }
  & { delete_interviews_by_pk?: Maybe<(
    { __typename?: 'interviews' }
    & Pick<Interviews, 'id' | 'owner_id' | 'meeting_link' | 'outcome'>
  )> }
);

export type Delete_Test_Interview_ParticipantsMutationVariables = Exact<{
  interviewId: Scalars['uuid'];
}>;


export type Delete_Test_Interview_ParticipantsMutation = (
  { __typename?: 'mutation_root' }
  & { delete_interview_participants?: Maybe<(
    { __typename?: 'interview_participants_mutation_response' }
    & { returning: Array<(
      { __typename?: 'interview_participants' }
      & Pick<Interview_Participants, 'id' | 'client_id' | 'interview_id' | 'candidate_id'>
    )> }
  )> }
);

export type Unique_Tenant_JobsQueryVariables = Exact<{
  shortname: Scalars['String'];
}>;


export type Unique_Tenant_JobsQuery = (
  { __typename?: 'query_root' }
  & { tenants: Array<(
    { __typename?: 'tenants' }
    & Pick<Tenants, 'name' | 'shortname'>
    & { clients: Array<(
      { __typename?: 'clients' }
      & Pick<Clients, 'id' | 'business_name'>
      & { client_jobs: Array<(
        { __typename?: 'client_jobs' }
        & Pick<Client_Jobs, 'id'>
        & { job: (
          { __typename?: 'jobs' }
          & Pick<Jobs, 'created_at' | 'id' | 'title'>
          & { client_jobs: Array<(
            { __typename?: 'client_jobs' }
            & { client: (
              { __typename?: 'clients' }
              & Pick<Clients, 'business_name'>
            ) }
          )> }
        ) }
      )> }
    )> }
  )> }
);

export type All_Tenant_JobsQueryVariables = Exact<{ [key: string]: never; }>;


export type All_Tenant_JobsQuery = (
  { __typename?: 'query_root' }
  & { tenants: Array<(
    { __typename?: 'tenants' }
    & Pick<Tenants, 'id' | 'name' | 'shortname' | 'year_founded' | 'staff_count' | 'staff_count_max' | 'website' | 'logo' | 'overview' | 'overview_extended' | 'email' | 'enabled' | 'theme_bg_color'>
    & { tenant_locations: Array<(
      { __typename?: 'tenant_locations' }
      & { location?: Maybe<(
        { __typename?: 'locations' }
        & Pick<Locations, 'coordinate' | 'postcode' | 'state' | 'locality'>
      )> }
    )>, clients: Array<(
      { __typename?: 'clients' }
      & Pick<Clients, 'id' | 'business_name'>
      & { client_jobs: Array<(
        { __typename?: 'client_jobs' }
        & Pick<Client_Jobs, 'id'>
        & { job: (
          { __typename?: 'jobs' }
          & Pick<Jobs, 'created_at' | 'id' | 'title'>
          & { client_jobs: Array<(
            { __typename?: 'client_jobs' }
            & { client: (
              { __typename?: 'clients' }
              & Pick<Clients, 'business_name'>
            ) }
          )> }
        ) }
      )> }
    )> }
  )> }
);

export type Search_JobsQueryVariables = Exact<{
  status?: Maybe<Job_Status_Enums_Comparison_Exp>;
  work_arrangement?: Maybe<Work_Arrangement_Enums_Comparison_Exp>;
  contract_type?: Maybe<Jobs_Contract_Type_Enums_Comparison_Exp>;
}>;


export type Search_JobsQuery = (
  { __typename?: 'query_root' }
  & { filtered: Array<(
    { __typename?: 'jobs' }
    & Pick<Jobs, 'id' | 'title' | 'contract_type' | 'work_arrangement' | 'status' | 'created_at' | 'updated_at'>
    & { client_jobs: Array<(
      { __typename?: 'client_jobs' }
      & { client: (
        { __typename?: 'clients' }
        & Pick<Clients, 'id' | 'business_name'>
      ) }
    )> }
  )>, default: Array<(
    { __typename?: 'jobs' }
    & Pick<Jobs, 'id' | 'title' | 'contract_type' | 'work_arrangement' | 'status' | 'created_at' | 'updated_at'>
    & { client_jobs: Array<(
      { __typename?: 'client_jobs' }
      & { client: (
        { __typename?: 'clients' }
        & Pick<Clients, 'id' | 'business_name'>
      ) }
    )> }
  )> }
);

export type Upsert_Unique_JobMutationVariables = Exact<{
  job: Jobs_Insert_Input;
  columns?: Maybe<Array<Jobs_Update_Column> | Jobs_Update_Column>;
}>;


export type Upsert_Unique_JobMutation = (
  { __typename?: 'mutation_root' }
  & { insert_jobs_one?: Maybe<(
    { __typename?: 'jobs' }
    & Pick<Jobs, 'id'>
  )> }
);

export type All_JobsQueryVariables = Exact<{
  object?: Maybe<Jobs_Bool_Exp>;
}>;


export type All_JobsQuery = (
  { __typename?: 'query_root' }
  & { jobs: Array<(
    { __typename?: 'jobs' }
    & Pick<Jobs, 'id' | 'title' | 'description' | 'status' | 'created_at' | 'deleted_at' | 'contract_type'>
    & { job_candidates: Array<(
      { __typename?: 'job_candidates' }
      & { candidate: (
        { __typename?: 'candidates' }
        & Pick<Candidates, 'id' | 'owner_id' | 'first_name' | 'last_name'>
      ) }
    )>, interviews: Array<(
      { __typename?: 'interviews' }
      & Pick<Interviews, 'id'>
    )>, client_jobs: Array<(
      { __typename?: 'client_jobs' }
      & { client: (
        { __typename?: 'clients' }
        & Pick<Clients, 'id' | 'business_name'>
        & { contacts: Array<(
          { __typename?: 'contacts' }
          & Pick<Contacts, 'id' | 'first_name' | 'last_name' | 'email' | 'phone'>
        )>, tenant: (
          { __typename?: 'tenants' }
          & Pick<Tenants, 'name' | 'shortname'>
        ), client_locations: Array<(
          { __typename?: 'client_locations' }
          & { location?: Maybe<(
            { __typename?: 'locations' }
            & Pick<Locations, 'coordinate' | 'locality' | 'state' | 'postcode'>
          )> }
        )> }
      ) }
    )> }
  )> }
);

export type Recent_JobsQueryVariables = Exact<{ [key: string]: never; }>;


export type Recent_JobsQuery = (
  { __typename?: 'query_root' }
  & { jobs: Array<(
    { __typename?: 'jobs' }
    & Pick<Jobs, 'id' | 'title' | 'status' | 'created_at' | 'contract_type'>
  )> }
);

export type Unique_JobQueryVariables = Exact<{
  jobId: Scalars['uuid'];
}>;


export type Unique_JobQuery = (
  { __typename?: 'query_root' }
  & { jobs_by_pk?: Maybe<(
    { __typename?: 'jobs' }
    & Pick<Jobs, 'id' | 'created_at' | 'updated_at' | 'deleted_at' | 'title' | 'owner_id' | 'description' | 'work_arrangement' | 'graduation_timeframe' | 'open_to_part_time_prior_to_graduation' | 'available_part_time_days_from' | 'available_part_time_days_to' | 'role_commences_on' | 'contract_type' | 'status'>
    & { interviews: Array<(
      { __typename?: 'interviews' }
      & Pick<Interviews, 'id'>
    )>, job_locations: Array<(
      { __typename?: 'job_locations' }
      & { location?: Maybe<(
        { __typename?: 'locations' }
        & Pick<Locations, 'locality' | 'postcode' | 'state' | 'coordinate'>
      )> }
    )>, job_candidates: Array<(
      { __typename?: 'job_candidates' }
      & { candidate: (
        { __typename?: 'candidates' }
        & Pick<Candidates, 'id' | 'owner_id' | 'first_name' | 'last_name'>
      ) }
    )>, shortlists: Array<(
      { __typename?: 'shortlists' }
      & Pick<Shortlists, 'id' | 'created_at' | 'updated_at' | 'deleted_at'>
      & { candidate?: Maybe<(
        { __typename?: 'candidates' }
        & Pick<Candidates, 'id' | 'first_name' | 'last_name'>
      )> }
    )>, client_jobs: Array<(
      { __typename?: 'client_jobs' }
      & { client: (
        { __typename?: 'clients' }
        & Pick<Clients, 'id' | 'business_name'>
        & { client_locations: Array<(
          { __typename?: 'client_locations' }
          & { location?: Maybe<(
            { __typename?: 'locations' }
            & Pick<Locations, 'coordinate' | 'locality' | 'state' | 'postcode'>
          )> }
        )>, contacts: Array<(
          { __typename?: 'contacts' }
          & Pick<Contacts, 'id' | 'first_name' | 'last_name' | 'email' | 'phone' | 'domain'>
        )>, client_industries: Array<(
          { __typename?: 'client_industries' }
          & { industry: (
            { __typename?: 'industries' }
            & Pick<Industries, 'id' | 'title'>
          ) }
        )> }
      ) }
    )> }
  )> }
);

export type Unique_Job_PublicQueryVariables = Exact<{
  jobId: Scalars['uuid'];
}>;


export type Unique_Job_PublicQuery = (
  { __typename?: 'query_root' }
  & { jobs_by_pk?: Maybe<(
    { __typename?: 'jobs' }
    & Pick<Jobs, 'id' | 'created_at' | 'updated_at' | 'deleted_at' | 'title' | 'description' | 'work_arrangement' | 'graduation_timeframe' | 'open_to_part_time_prior_to_graduation' | 'available_part_time_days_from' | 'available_part_time_days_to' | 'role_commences_on' | 'contract_type' | 'status'>
    & { job_locations: Array<(
      { __typename?: 'job_locations' }
      & { location?: Maybe<(
        { __typename?: 'locations' }
        & Pick<Locations, 'coordinate' | 'locality' | 'state' | 'postcode'>
      )> }
    )> }
  )> }
);

export type Job_ShortlistQueryVariables = Exact<{
  jobId?: Maybe<Scalars['uuid']>;
}>;


export type Job_ShortlistQuery = (
  { __typename?: 'query_root' }
  & { shortlists: Array<(
    { __typename?: 'shortlists' }
    & Pick<Shortlists, 'id' | 'created_at' | 'deleted_at'>
    & { client?: Maybe<(
      { __typename?: 'clients' }
      & Pick<Clients, 'id' | 'business_name'>
    )>, job?: Maybe<(
      { __typename?: 'jobs' }
      & Pick<Jobs, 'id' | 'title'>
    )>, candidate?: Maybe<(
      { __typename?: 'candidates' }
      & Pick<Candidates, 'id' | 'first_name' | 'last_name'>
    )> }
  )> }
);

export type Upsert_Job_From_Proposed_InterviewMutationVariables = Exact<{
  objects?: Maybe<Array<Jobs_Insert_Input> | Jobs_Insert_Input>;
}>;


export type Upsert_Job_From_Proposed_InterviewMutation = (
  { __typename?: 'mutation_root' }
  & { insert_jobs?: Maybe<(
    { __typename?: 'jobs_mutation_response' }
    & Pick<Jobs_Mutation_Response, 'affected_rows'>
    & { returning: Array<(
      { __typename?: 'jobs' }
      & Pick<Jobs, 'id' | 'graduation_timeframe' | 'description' | 'deleted_at' | 'created_at' | 'available_part_time_days_to' | 'available_part_time_days_from' | 'contract_type' | 'owner_id' | 'role_commences_on' | 'open_to_part_time_prior_to_graduation' | 'source' | 'status' | 'title' | 'updated_at' | 'work_arrangement'>
    )> }
  )> }
);

export type Upsert_Client_JobMutationVariables = Exact<{
  objects?: Maybe<Array<Client_Jobs_Insert_Input> | Client_Jobs_Insert_Input>;
}>;


export type Upsert_Client_JobMutation = (
  { __typename?: 'mutation_root' }
  & { insert_client_jobs?: Maybe<(
    { __typename?: 'client_jobs_mutation_response' }
    & Pick<Client_Jobs_Mutation_Response, 'affected_rows'>
    & { returning: Array<(
      { __typename?: 'client_jobs' }
      & Pick<Client_Jobs, 'job_id' | 'client_id' | 'created_at' | 'deleted_at' | 'updated_at'>
    )> }
  )> }
);

export type Unique_Client_Jobs_By_ClientQueryVariables = Exact<{
  client_id?: Maybe<Scalars['uuid']>;
}>;


export type Unique_Client_Jobs_By_ClientQuery = (
  { __typename?: 'query_root' }
  & { client_jobs: Array<(
    { __typename?: 'client_jobs' }
    & Pick<Client_Jobs, 'client_id' | 'created_at' | 'deleted_at' | 'id' | 'job_id' | 'updated_at'>
    & { job: (
      { __typename?: 'jobs' }
      & Pick<Jobs, 'available_part_time_days_from' | 'available_part_time_days_to' | 'contract_type' | 'created_at' | 'deleted_at' | 'description' | 'graduation_timeframe' | 'id' | 'open_to_part_time_prior_to_graduation' | 'owner_id' | 'role_commences_on' | 'source' | 'status' | 'title' | 'updated_at' | 'work_arrangement'>
      & { interviews: Array<(
        { __typename?: 'interviews' }
        & Pick<Interviews, 'mode' | 'location' | 'phone' | 'nylas_event_id' | 'meeting_link'>
      )> }
    ) }
  )> }
);

export type Unique_Job_Candidates_By_PkQueryVariables = Exact<{
  id: Scalars['uuid'];
}>;


export type Unique_Job_Candidates_By_PkQuery = (
  { __typename?: 'query_root' }
  & { job_candidates_by_pk?: Maybe<(
    { __typename?: 'job_candidates' }
    & Pick<Job_Candidates, 'id' | 'candidate_id' | 'job_id' | 'created_at' | 'updated_at' | 'deleted_at'>
    & { job: (
      { __typename?: 'jobs' }
      & Pick<Jobs, 'id' | 'title'>
      & { client_jobs: Array<(
        { __typename?: 'client_jobs' }
        & { client: (
          { __typename?: 'clients' }
          & Pick<Clients, 'id' | 'business_name'>
          & { contacts: Array<(
            { __typename?: 'contacts' }
            & Pick<Contacts, 'id' | 'first_name' | 'last_name' | 'email' | 'phone'>
          )> }
        ) }
      )> }
    ), candidate: (
      { __typename?: 'candidates' }
      & Pick<Candidates, 'id' | 'first_name' | 'last_name' | 'email' | 'phone' | 'owner_id' | 'created_at' | 'updated_at' | 'deleted_at'>
      & { candidate_locations: Array<(
        { __typename?: 'candidate_locations' }
        & { location?: Maybe<(
          { __typename?: 'locations' }
          & Pick<Locations, 'coordinate' | 'locality' | 'state' | 'postcode'>
        )> }
      )>, candidate_industries: Array<(
        { __typename?: 'candidate_industries' }
        & { industry: (
          { __typename?: 'industries' }
          & Pick<Industries, 'id' | 'title'>
        ) }
      )> }
    ) }
  )> }
);

export type Job_Candidates_AggregateQueryVariables = Exact<{
  job_id: Scalars['uuid'];
}>;


export type Job_Candidates_AggregateQuery = (
  { __typename?: 'query_root' }
  & { job_candidates_aggregate: (
    { __typename?: 'job_candidates_aggregate' }
    & { aggregate?: Maybe<(
      { __typename?: 'job_candidates_aggregate_fields' }
      & Pick<Job_Candidates_Aggregate_Fields, 'count'>
    )> }
  ) }
);

export type Delete_Test_JobsMutationVariables = Exact<{
  owner_id: Scalars['String'];
}>;


export type Delete_Test_JobsMutation = (
  { __typename?: 'mutation_root' }
  & { delete_jobs?: Maybe<(
    { __typename?: 'jobs_mutation_response' }
    & { returning: Array<(
      { __typename?: 'jobs' }
      & Pick<Jobs, 'id' | 'owner_id' | 'title'>
    )> }
  )> }
);

export type Update_JobMutationVariables = Exact<{
  jobId: Scalars['uuid'];
  job?: Maybe<Jobs_Set_Input>;
}>;


export type Update_JobMutation = (
  { __typename?: 'mutation_root' }
  & { update_jobs_by_pk?: Maybe<(
    { __typename?: 'jobs' }
    & Pick<Jobs, 'id' | 'title'>
  )> }
);

export type Insert_Job_Candidates_OneMutationVariables = Exact<{
  object: Job_Candidates_Insert_Input;
}>;


export type Insert_Job_Candidates_OneMutation = (
  { __typename?: 'mutation_root' }
  & { insert_job_candidates_one?: Maybe<(
    { __typename?: 'job_candidates' }
    & Pick<Job_Candidates, 'id'>
  )> }
);

export type Attach_Location_To_ClientMutationVariables = Exact<{
  clientId: Scalars['uuid'];
  object: Array<Client_Locations_Insert_Input> | Client_Locations_Insert_Input;
}>;


export type Attach_Location_To_ClientMutation = (
  { __typename?: 'mutation_root' }
  & { remove_unused?: Maybe<(
    { __typename?: 'client_locations_mutation_response' }
    & Pick<Client_Locations_Mutation_Response, 'affected_rows'>
  )>, insert_client_locations?: Maybe<(
    { __typename?: 'client_locations_mutation_response' }
    & Pick<Client_Locations_Mutation_Response, 'affected_rows'>
  )> }
);

export type Attach_Location_To_CandidateMutationVariables = Exact<{
  candidateId: Scalars['uuid'];
  object: Array<Candidate_Locations_Insert_Input> | Candidate_Locations_Insert_Input;
}>;


export type Attach_Location_To_CandidateMutation = (
  { __typename?: 'mutation_root' }
  & { remove_unused?: Maybe<(
    { __typename?: 'candidate_locations_mutation_response' }
    & Pick<Candidate_Locations_Mutation_Response, 'affected_rows'>
  )>, insert_candidate_locations?: Maybe<(
    { __typename?: 'candidate_locations_mutation_response' }
    & Pick<Candidate_Locations_Mutation_Response, 'affected_rows'>
  )> }
);

export type Attach_Location_To_JobMutationVariables = Exact<{
  jobId: Scalars['uuid'];
  object: Array<Job_Locations_Insert_Input> | Job_Locations_Insert_Input;
}>;


export type Attach_Location_To_JobMutation = (
  { __typename?: 'mutation_root' }
  & { remove_unused?: Maybe<(
    { __typename?: 'job_locations_mutation_response' }
    & Pick<Job_Locations_Mutation_Response, 'affected_rows'>
  )>, insert_job_locations?: Maybe<(
    { __typename?: 'job_locations_mutation_response' }
    & Pick<Job_Locations_Mutation_Response, 'affected_rows'>
  )> }
);

export type Upsert_Unique_Candidate_MajorsMutationVariables = Exact<{
  objects: Array<Candidate_Majors_Insert_Input> | Candidate_Majors_Insert_Input;
  candidateId: Scalars['uuid'];
  majorIds?: Maybe<Array<Scalars['uuid']> | Scalars['uuid']>;
}>;


export type Upsert_Unique_Candidate_MajorsMutation = (
  { __typename?: 'mutation_root' }
  & { insert_candidate_majors?: Maybe<(
    { __typename?: 'candidate_majors_mutation_response' }
    & Pick<Candidate_Majors_Mutation_Response, 'affected_rows'>
  )>, remove_unused?: Maybe<(
    { __typename?: 'candidate_majors_mutation_response' }
    & Pick<Candidate_Majors_Mutation_Response, 'affected_rows'>
  )>, reinstate_used?: Maybe<(
    { __typename?: 'candidate_majors_mutation_response' }
    & Pick<Candidate_Majors_Mutation_Response, 'affected_rows'>
  )> }
);

export type All_CoursesQueryVariables = Exact<{ [key: string]: never; }>;


export type All_CoursesQuery = (
  { __typename?: 'query_root' }
  & { courses: Array<(
    { __typename?: 'courses' }
    & Pick<Courses, 'id' | 'name'>
    & { candidate_courses: Array<(
      { __typename?: 'candidate_courses' }
      & { candidate: (
        { __typename?: 'candidates' }
        & Pick<Candidates, 'id' | 'first_name' | 'last_name'>
      ) }
    )> }
  )> }
);

export type Unique_CourseQueryVariables = Exact<{
  id: Scalars['uuid'];
}>;


export type Unique_CourseQuery = (
  { __typename?: 'query_root' }
  & { courses_by_pk?: Maybe<(
    { __typename?: 'courses' }
    & Pick<Courses, 'id' | 'content_url' | 'created_at' | 'deleted_at' | 'details' | 'instruction' | 'img' | 'name' | 'overview' | 'priority' | 'provider' | 'quiz_url' | 'updated_at' | 'live' | 'form_id'>
    & { candidate_courses: Array<(
      { __typename?: 'candidate_courses' }
      & Pick<Candidate_Courses, 'created_at' | 'deleted_at' | 'result' | 'completed'>
      & { course: (
        { __typename?: 'courses' }
        & Pick<Courses, 'id'>
      ), candidate: (
        { __typename?: 'candidates' }
        & Pick<Candidates, 'id' | 'first_name' | 'last_name'>
      ) }
    )> }
  )> }
);

export type Upsert_Unique_Candidate_CoursesMutationVariables = Exact<{
  objects: Array<Candidate_Courses_Insert_Input> | Candidate_Courses_Insert_Input;
}>;


export type Upsert_Unique_Candidate_CoursesMutation = (
  { __typename?: 'mutation_root' }
  & { insert_candidate_courses?: Maybe<(
    { __typename?: 'candidate_courses_mutation_response' }
    & Pick<Candidate_Courses_Mutation_Response, 'affected_rows'>
  )> }
);

export type Unique_Course_Form_IdQueryVariables = Exact<{
  form_id?: Maybe<Scalars['String']>;
}>;


export type Unique_Course_Form_IdQuery = (
  { __typename?: 'query_root' }
  & { courses: Array<(
    { __typename?: 'courses' }
    & Pick<Courses, 'id'>
  )> }
);

export type Update_Unique_Candidate_Courses_As_PassedMutationVariables = Exact<{
  candidate_id?: Maybe<Scalars['uuid']>;
  course_id?: Maybe<Scalars['uuid']>;
}>;


export type Update_Unique_Candidate_Courses_As_PassedMutation = (
  { __typename?: 'mutation_root' }
  & { update_candidate_courses?: Maybe<(
    { __typename?: 'candidate_courses_mutation_response' }
    & Pick<Candidate_Courses_Mutation_Response, 'affected_rows'>
  )> }
);

export type All_Completed_Courses_By_Candidate_IdQueryVariables = Exact<{
  candidateId?: Maybe<Scalars['uuid']>;
}>;


export type All_Completed_Courses_By_Candidate_IdQuery = (
  { __typename?: 'query_root' }
  & { candidate_courses: Array<(
    { __typename?: 'candidate_courses' }
    & { course: (
      { __typename?: 'courses' }
      & Pick<Courses, 'img' | 'name' | 'overview'>
    ) }
  )> }
);

export type Nylas_Post_Calendar_AvailabilityMutationVariables = Exact<{
  duration_minutes?: Maybe<Scalars['Int']>;
  start_time?: Maybe<Scalars['Int']>;
  end_time?: Maybe<Scalars['Int']>;
  interval_minutes?: Maybe<Scalars['Int']>;
  emails?: Maybe<Array<Maybe<Scalars['String']>> | Maybe<Scalars['String']>>;
  timezone?: Maybe<Scalars['String']>;
  access_token?: Maybe<Scalars['String']>;
}>;


export type Nylas_Post_Calendar_AvailabilityMutation = (
  { __typename?: 'mutation_root' }
  & { nylas_post_calendar_availability?: Maybe<(
    { __typename?: 'Nylas_PostCalendarAvailabilityOutput' }
    & { time_slots?: Maybe<Array<Maybe<(
      { __typename?: 'Nylas_TimeSlot' }
      & Pick<Nylas_TimeSlot, 'status' | 'start_time' | 'end_time'>
    )>>> }
  )> }
);

export type Nylas_Post_EventMutationVariables = Exact<{
  interview_id?: Maybe<Scalars['uuid']>;
  calendar_id?: Maybe<Scalars['String']>;
  access_token?: Maybe<Scalars['String']>;
  title?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  participants: Scalars['json'];
  location?: Maybe<Scalars['String']>;
  start_time?: Maybe<Scalars['Int']>;
  end_time?: Maybe<Scalars['Int']>;
  time_zone?: Maybe<Scalars['String']>;
}>;


export type Nylas_Post_EventMutation = (
  { __typename?: 'mutation_root' }
  & { nylas_post_event?: Maybe<(
    { __typename?: 'Nylas_PostEventOutput' }
    & Pick<Nylas_PostEventOutput, 'id'>
  )> }
);

export type Nylas_Get_Event_By_IdQueryVariables = Exact<{
  event_id?: Maybe<Scalars['String']>;
}>;


export type Nylas_Get_Event_By_IdQuery = (
  { __typename?: 'query_root' }
  & { nylas_get_event_by_id?: Maybe<(
    { __typename?: 'Nylas_GetEventByIdOutput' }
    & Pick<Nylas_GetEventByIdOutput, 'id' | 'status'>
  )> }
);

export type Create_PlacementMutationVariables = Exact<{
  candidate_id?: Maybe<Scalars['uuid']>;
  client_id?: Maybe<Scalars['uuid']>;
  job_id?: Maybe<Scalars['uuid']>;
  commences_on?: Maybe<Scalars['timestamptz']>;
}>;


export type Create_PlacementMutation = (
  { __typename?: 'mutation_root' }
  & { insert_candidate_placements_one?: Maybe<(
    { __typename?: 'candidate_placements' }
    & Pick<Candidate_Placements, 'id'>
  )> }
);

export type Placements_By_Candidate_IdQueryVariables = Exact<{
  candidate_id?: Maybe<Scalars['uuid']>;
}>;


export type Placements_By_Candidate_IdQuery = (
  { __typename?: 'query_root' }
  & { candidate_placements: Array<(
    { __typename?: 'candidate_placements' }
    & Pick<Candidate_Placements, 'commences_on' | 'desisted_on' | 'created_at' | 'deleted_at' | 'id' | 'updated_at'>
    & { candidate: (
      { __typename?: 'candidates' }
      & Pick<Candidates, 'first_name' | 'last_name'>
    ), client: (
      { __typename?: 'clients' }
      & Pick<Clients, 'id' | 'business_name'>
      & { contacts: Array<(
        { __typename?: 'contacts' }
        & Pick<Contacts, 'email' | 'first_name' | 'last_name'>
      )> }
    ) }
  )> }
);

export type Unique_PlacementQueryVariables = Exact<{
  placement_id?: Maybe<Scalars['uuid']>;
}>;


export type Unique_PlacementQuery = (
  { __typename?: 'query_root' }
  & { candidate_placements_by_pk?: Maybe<(
    { __typename?: 'candidate_placements' }
    & Pick<Candidate_Placements, 'commences_on' | 'created_at' | 'deleted_at' | 'id' | 'updated_at'>
    & { candidate: (
      { __typename?: 'candidates' }
      & Pick<Candidates, 'first_name' | 'last_name'>
    ), client: (
      { __typename?: 'clients' }
      & Pick<Clients, 'id' | 'business_name'>
      & { contacts: Array<(
        { __typename?: 'contacts' }
        & Pick<Contacts, 'email' | 'first_name' | 'last_name'>
      )> }
    ) }
  )> }
);

export type All_PlacementsQueryVariables = Exact<{ [key: string]: never; }>;


export type All_PlacementsQuery = (
  { __typename?: 'query_root' }
  & { candidate_placements: Array<(
    { __typename?: 'candidate_placements' }
    & Pick<Candidate_Placements, 'commences_on' | 'desisted_on'>
    & { candidate: (
      { __typename?: 'candidates' }
      & Pick<Candidates, 'id' | 'first_name' | 'last_name' | 'photo_url'>
    ), client: (
      { __typename?: 'clients' }
      & Pick<Clients, 'id' | 'business_name'>
    ) }
  )> }
);

export type Search_PlacementsQueryVariables = Exact<{
  object?: Maybe<Candidate_Placements_Bool_Exp>;
  order_by?: Maybe<Array<Candidate_Placements_Order_By> | Candidate_Placements_Order_By>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
}>;


export type Search_PlacementsQuery = (
  { __typename?: 'query_root' }
  & { candidate_placements: Array<(
    { __typename?: 'candidate_placements' }
    & Pick<Candidate_Placements, 'commences_on' | 'desisted_on'>
    & { candidate: (
      { __typename?: 'candidates' }
      & Pick<Candidates, 'id' | 'first_name' | 'last_name' | 'photo_url'>
    ), client: (
      { __typename?: 'clients' }
      & Pick<Clients, 'id' | 'business_name'>
    ) }
  )>, candidate_placements_aggregate: (
    { __typename?: 'candidate_placements_aggregate' }
    & { aggregate?: Maybe<(
      { __typename?: 'candidate_placements_aggregate_fields' }
      & Pick<Candidate_Placements_Aggregate_Fields, 'count'>
    )> }
  ) }
);

export type Search_All_PlacementsQueryVariables = Exact<{
  object?: Maybe<Candidate_Placements_Bool_Exp>;
  order_by?: Maybe<Array<Candidate_Placements_Order_By> | Candidate_Placements_Order_By>;
}>;


export type Search_All_PlacementsQuery = (
  { __typename?: 'query_root' }
  & { candidate_placements: Array<(
    { __typename?: 'candidate_placements' }
    & Pick<Candidate_Placements, 'commences_on'>
    & { candidate: (
      { __typename?: 'candidates' }
      & Pick<Candidates, 'id' | 'first_name' | 'last_name' | 'photo_url'>
      & { candidate_locations: Array<(
        { __typename?: 'candidate_locations' }
        & { location?: Maybe<(
          { __typename?: 'locations' }
          & Pick<Locations, 'postcode' | 'state' | 'locality'>
        )> }
      )> }
    ), client: (
      { __typename?: 'clients' }
      & Pick<Clients, 'id' | 'business_name'>
    ) }
  )> }
);

export type Update_Placement_By_PkMutationVariables = Exact<{
  id: Scalars['uuid'];
  _set: Candidate_Placements_Set_Input;
}>;


export type Update_Placement_By_PkMutation = (
  { __typename?: 'mutation_root' }
  & { update_candidate_placements_by_pk?: Maybe<(
    { __typename?: 'candidate_placements' }
    & Pick<Candidate_Placements, 'id'>
  )> }
);

export type All_IndustriesQueryVariables = Exact<{ [key: string]: never; }>;


export type All_IndustriesQuery = (
  { __typename?: 'query_root' }
  & { industries: Array<(
    { __typename?: 'industries' }
    & Pick<Industries, 'id' | 'title' | 'description'>
  )> }
);

export type All_SkillsQueryVariables = Exact<{ [key: string]: never; }>;


export type All_SkillsQuery = (
  { __typename?: 'query_root' }
  & { skills: Array<(
    { __typename?: 'skills' }
    & Pick<Skills, 'id' | 'title' | 'description'>
  )> }
);

export type All_DegreesQueryVariables = Exact<{ [key: string]: never; }>;


export type All_DegreesQuery = (
  { __typename?: 'query_root' }
  & { degrees: Array<(
    { __typename?: 'degrees' }
    & Pick<Degrees, 'id' | 'title' | 'description'>
  )> }
);

export type All_MajorsQueryVariables = Exact<{ [key: string]: never; }>;


export type All_MajorsQuery = (
  { __typename?: 'query_root' }
  & { majors: Array<(
    { __typename?: 'majors' }
    & Pick<Majors, 'id' | 'title' | 'description'>
  )> }
);

export type All_UniversitiesQueryVariables = Exact<{ [key: string]: never; }>;


export type All_UniversitiesQuery = (
  { __typename?: 'query_root' }
  & { universities: Array<(
    { __typename?: 'universities' }
    & Pick<Universities, 'id' | 'name' | 'short_name' | 'country' | 'state'>
  )> }
);

export type Get_Job_By_IdQueryVariables = Exact<{
  id?: Maybe<Scalars['uuid']>;
}>;


export type Get_Job_By_IdQuery = (
  { __typename?: 'query_root' }
  & { jobs: Array<(
    { __typename?: 'jobs' }
    & Pick<Jobs, 'id' | 'title' | 'owner_id'>
    & { client_jobs: Array<(
      { __typename?: 'client_jobs' }
      & { client: (
        { __typename?: 'clients' }
        & Pick<Clients, 'id' | 'business_name'>
        & { tenant: (
          { __typename?: 'tenants' }
          & Pick<Tenants, 'name'>
        ) }
      ) }
    )> }
  )> }
);

export type Admin_ReportQueryVariables = Exact<{ [key: string]: never; }>;


export type Admin_ReportQuery = (
  { __typename?: 'query_root' }
  & { users: Array<(
    { __typename?: 'users' }
    & Pick<Users, 'email' | 'id' | 'auth0_uid' | 'created_at' | 'updated_at'>
    & { tenants: Array<(
      { __typename?: 'tenants' }
      & Pick<Tenants, 'created_at' | 'id' | 'name' | 'email' | 'phone'>
      & { clients: Array<(
        { __typename?: 'clients' }
        & Pick<Clients, 'id' | 'created_at' | 'updated_at' | 'business_name'>
        & { client_locations: Array<(
          { __typename?: 'client_locations' }
          & { location?: Maybe<(
            { __typename?: 'locations' }
            & Pick<Locations, 'state' | 'locality' | 'postcode'>
          )> }
        )>, client_jobs: Array<(
          { __typename?: 'client_jobs' }
          & { job: (
            { __typename?: 'jobs' }
            & Pick<Jobs, 'id' | 'title' | 'created_at'>
            & { client_jobs: Array<(
              { __typename?: 'client_jobs' }
              & { client: (
                { __typename?: 'clients' }
                & Pick<Clients, 'business_name'>
                & { client_locations: Array<(
                  { __typename?: 'client_locations' }
                  & { location?: Maybe<(
                    { __typename?: 'locations' }
                    & Pick<Locations, 'state' | 'locality' | 'postcode'>
                  )> }
                )> }
              ) }
            )>, shortlists: Array<(
              { __typename?: 'shortlists' }
              & { candidate?: Maybe<(
                { __typename?: 'candidates' }
                & Pick<Candidates, 'id' | 'photo_url' | 'deleted_at' | 'first_name' | 'last_name'>
              )> }
            )> }
          ) }
        )> }
      )> }
    )> }
  )> }
);

export type All_The_Things_UserQueryVariables = Exact<{
  auth0_uid?: Maybe<Scalars['String']>;
}>;


export type All_The_Things_UserQuery = (
  { __typename?: 'query_root' }
  & { users: Array<(
    { __typename?: 'users' }
    & Pick<Users, 'auth0_uid' | 'first_name' | 'last_name' | 'created_at' | 'updated_at' | 'email' | 'role'>
    & { tenants: Array<(
      { __typename?: 'tenants' }
      & Pick<Tenants, 'id' | 'created_at' | 'name' | 'email' | 'phone' | 'enabled'>
      & { clients: Array<(
        { __typename?: 'clients' }
        & Pick<Clients, 'id' | 'business_name' | 'created_at' | 'terms_accepted_on'>
        & { client_locations: Array<(
          { __typename?: 'client_locations' }
          & { location?: Maybe<(
            { __typename?: 'locations' }
            & Pick<Locations, 'state' | 'locality' | 'postcode'>
          )> }
        )>, client_jobs: Array<(
          { __typename?: 'client_jobs' }
          & { job: (
            { __typename?: 'jobs' }
            & Pick<Jobs, 'id' | 'title' | 'created_at'>
            & { client_jobs: Array<(
              { __typename?: 'client_jobs' }
              & { client: (
                { __typename?: 'clients' }
                & Pick<Clients, 'business_name' | 'terms_accepted_on'>
                & { client_locations: Array<(
                  { __typename?: 'client_locations' }
                  & { location?: Maybe<(
                    { __typename?: 'locations' }
                    & Pick<Locations, 'state' | 'locality' | 'postcode'>
                  )> }
                )> }
              ) }
            )>, shortlists: Array<(
              { __typename?: 'shortlists' }
              & { candidate?: Maybe<(
                { __typename?: 'candidates' }
                & Pick<Candidates, 'first_name' | 'last_name'>
              )> }
            )> }
          ) }
        )> }
      )> }
    )> }
  )> }
);

export type Create_New_JobMutationVariables = Exact<{
  title?: Maybe<Scalars['String']>;
  owner_id?: Maybe<Scalars['String']>;
  contract_type?: Maybe<Scalars['jobs_contract_type_enums']>;
  client_id?: Maybe<Scalars['uuid']>;
  source?: Maybe<Scalars['String']>;
}>;


export type Create_New_JobMutation = (
  { __typename?: 'mutation_root' }
  & { insert_jobs?: Maybe<(
    { __typename?: 'jobs_mutation_response' }
    & { returning: Array<(
      { __typename?: 'jobs' }
      & Pick<Jobs, 'id' | 'title' | 'owner_id'>
      & { client_jobs: Array<(
        { __typename?: 'client_jobs' }
        & { job: (
          { __typename?: 'jobs' }
          & Pick<Jobs, 'id' | 'title' | 'source'>
        ), client: (
          { __typename?: 'clients' }
          & Pick<Clients, 'id' | 'business_name'>
          & { tenant: (
            { __typename?: 'tenants' }
            & Pick<Tenants, 'name'>
          ) }
        ) }
      )> }
    )> }
  )> }
);

export type Add_Client_LocationMutationVariables = Exact<{
  client_id: Scalars['uuid'];
  postcode?: Maybe<Scalars['String']>;
  locality?: Maybe<Scalars['String']>;
  state?: Maybe<Scalars['String']>;
}>;


export type Add_Client_LocationMutation = (
  { __typename?: 'mutation_root' }
  & { insert_client_locations_one?: Maybe<(
    { __typename?: 'client_locations' }
    & Pick<Client_Locations, 'id'>
  )> }
);

export type Insert_Multiple_TenantsMutationVariables = Exact<{
  objects: Array<Tenants_Insert_Input> | Tenants_Insert_Input;
}>;


export type Insert_Multiple_TenantsMutation = (
  { __typename?: 'mutation_root' }
  & { insert_tenants?: Maybe<(
    { __typename?: 'tenants_mutation_response' }
    & { returning: Array<(
      { __typename?: 'tenants' }
      & Pick<Tenants, 'id' | 'name'>
    )> }
  )> }
);

export type Insert_Multiple_ClientsMutationVariables = Exact<{
  objects: Array<Clients_Insert_Input> | Clients_Insert_Input;
}>;


export type Insert_Multiple_ClientsMutation = (
  { __typename?: 'mutation_root' }
  & { insert_clients?: Maybe<(
    { __typename?: 'clients_mutation_response' }
    & { returning: Array<(
      { __typename?: 'clients' }
      & Pick<Clients, 'id' | 'business_name'>
    )> }
  )> }
);

export type All_Report_TargetsQueryVariables = Exact<{ [key: string]: never; }>;


export type All_Report_TargetsQuery = (
  { __typename?: 'query_root' }
  & { report_targets: Array<(
    { __typename?: 'report_targets' }
    & Pick<Report_Targets, 'name' | 'target'>
  )> }
);

export type Update_TargetMutationVariables = Exact<{
  target?: Maybe<Scalars['Int']>;
  name?: Maybe<Scalars['String']>;
}>;


export type Update_TargetMutation = (
  { __typename?: 'mutation_root' }
  & { update_report_targets?: Maybe<(
    { __typename?: 'report_targets_mutation_response' }
    & Pick<Report_Targets_Mutation_Response, 'affected_rows'>
  )> }
);

export type All_Active_UsersQueryVariables = Exact<{
  updated_at?: Maybe<Timestamptz_Comparison_Exp>;
}>;


export type All_Active_UsersQuery = (
  { __typename?: 'query_root' }
  & { users: Array<(
    { __typename?: 'users' }
    & Pick<Users, 'role' | 'email' | 'updated_at'>
    & { clients: Array<(
      { __typename?: 'clients' }
      & Pick<Clients, 'id'>
      & { contacts: Array<(
        { __typename?: 'contacts' }
        & Pick<Contacts, 'first_name' | 'last_name' | 'email'>
      )> }
    )> }
  )> }
);

export type All_Events_Reporting_By_Created_AtQueryVariables = Exact<{
  created_at?: Maybe<Timestamptz_Comparison_Exp>;
  tenant?: Maybe<Tenants_Bool_Exp>;
}>;


export type All_Events_Reporting_By_Created_AtQuery = (
  { __typename?: 'query_root' }
  & { events: Array<(
    { __typename?: 'events' }
    & Pick<Events, 'created_at' | 'event' | 'owner_id'>
    & { user: (
      { __typename?: 'users' }
      & Pick<Users, 'email' | 'role'>
      & { clients: Array<(
        { __typename?: 'clients' }
        & Pick<Clients, 'id'>
        & { contacts: Array<(
          { __typename?: 'contacts' }
          & Pick<Contacts, 'first_name' | 'last_name'>
        )> }
      )> }
    ), candidate?: Maybe<(
      { __typename?: 'candidates' }
      & Pick<Candidates, 'photo_url' | 'first_name' | 'last_name' | 'id'>
    )> }
  )> }
);

export type All_Events_Reporting_By_Event_NameQueryVariables = Exact<{
  tenant?: Maybe<Tenants_Bool_Exp>;
  event?: Maybe<Scalars['String']>;
}>;


export type All_Events_Reporting_By_Event_NameQuery = (
  { __typename?: 'query_root' }
  & { events: Array<(
    { __typename?: 'events' }
    & Pick<Events, 'created_at' | 'event'>
  )> }
);

export type Search_Facet_ValuesQueryVariables = Exact<{ [key: string]: never; }>;


export type Search_Facet_ValuesQuery = (
  { __typename?: 'query_root' }
  & { skills: Array<(
    { __typename?: 'skills' }
    & Pick<Skills, 'id' | 'title'>
  )>, degrees: Array<(
    { __typename?: 'degrees' }
    & Pick<Degrees, 'id' | 'title'>
  )>, majors: Array<(
    { __typename?: 'majors' }
    & Pick<Majors, 'id' | 'title'>
  )>, industries: Array<(
    { __typename?: 'industries' }
    & Pick<Industries, 'id' | 'title'>
  )>, universities: Array<(
    { __typename?: 'universities' }
    & Pick<Universities, 'id'>
    & { title: Universities['name'] }
  )> }
);

export type Search_LocationsQueryVariables = Exact<{
  locality?: Maybe<Scalars['String']>;
}>;


export type Search_LocationsQuery = (
  { __typename?: 'query_root' }
  & { locations: Array<(
    { __typename?: 'locations' }
    & Pick<Locations, 'id' | 'postcode' | 'state' | 'locality' | 'coordinate'>
  )> }
);

export type Search_Clients_By_Business_NameQueryVariables = Exact<{
  business_name?: Maybe<Scalars['String']>;
}>;


export type Search_Clients_By_Business_NameQuery = (
  { __typename?: 'query_root' }
  & { clients: Array<(
    { __typename?: 'clients' }
    & Pick<Clients, 'id' | 'business_name'>
    & { contacts: Array<(
      { __typename?: 'contacts' }
      & Pick<Contacts, 'first_name' | 'last_name'>
    )>, client_jobs: Array<(
      { __typename?: 'client_jobs' }
      & { job: (
        { __typename?: 'jobs' }
        & Pick<Jobs, 'id' | 'title' | 'description'>
      ) }
    )> }
  )> }
);

export type Search_Jobs_PreviewQueryVariables = Exact<{
  title?: Maybe<Scalars['String']>;
}>;


export type Search_Jobs_PreviewQuery = (
  { __typename?: 'query_root' }
  & { jobs: Array<(
    { __typename?: 'jobs' }
    & Pick<Jobs, 'id' | 'title'>
  )> }
);

export type Unique_Short_UrlQueryVariables = Exact<{
  shortUrlId: Scalars['uuid'];
}>;


export type Unique_Short_UrlQuery = (
  { __typename?: 'query_root' }
  & { short_urls_by_pk?: Maybe<(
    { __typename?: 'short_urls' }
    & Pick<Short_Urls, 'id' | 'owner_id' | 'expires_on' | 'short_url' | 'long_url'>
  )> }
);

export type Unique_Short_Url_By_Short_IdQueryVariables = Exact<{
  shortUrl: Scalars['String'];
}>;


export type Unique_Short_Url_By_Short_IdQuery = (
  { __typename?: 'query_root' }
  & { short_urls: Array<(
    { __typename?: 'short_urls' }
    & Pick<Short_Urls, 'id' | 'expires_on' | 'short_url' | 'long_url'>
  )> }
);

export type Upsert_Unique_Short_UrlMutationVariables = Exact<{
  short_url: Short_Urls_Insert_Input;
  columns?: Maybe<Array<Short_Urls_Update_Column> | Short_Urls_Update_Column>;
}>;


export type Upsert_Unique_Short_UrlMutation = (
  { __typename?: 'mutation_root' }
  & { insert_short_urls_one?: Maybe<(
    { __typename?: 'short_urls' }
    & Pick<Short_Urls, 'id'>
  )> }
);

export type Shortlists_By_Candidate_IdQueryVariables = Exact<{
  candidateId: Scalars['uuid'];
}>;


export type Shortlists_By_Candidate_IdQuery = (
  { __typename?: 'query_root' }
  & { shortlists: Array<(
    { __typename?: 'shortlists' }
    & Pick<Shortlists, 'id' | 'created_at' | 'deleted_at' | 'updated_at'>
    & { job?: Maybe<(
      { __typename?: 'jobs' }
      & Pick<Jobs, 'id' | 'status'>
    )>, client?: Maybe<(
      { __typename?: 'clients' }
      & Pick<Clients, 'id'>
    )>, candidate?: Maybe<(
      { __typename?: 'candidates' }
      & Pick<Candidates, 'id' | 'status'>
    )> }
  )> }
);

export type Unique_ShortlistQueryVariables = Exact<{
  shortlistId: Scalars['uuid'];
}>;


export type Unique_ShortlistQuery = (
  { __typename?: 'query_root' }
  & { shortlists_by_pk?: Maybe<(
    { __typename?: 'shortlists' }
    & Pick<Shortlists, 'id' | 'created_at' | 'updated_at' | 'deleted_at' | 'owner_id'>
    & { candidate?: Maybe<(
      { __typename?: 'candidates' }
      & Pick<Candidates, 'id' | 'first_name' | 'last_name' | 'photo_url'>
    )>, job?: Maybe<(
      { __typename?: 'jobs' }
      & Pick<Jobs, 'id' | 'title'>
      & { client_jobs: Array<(
        { __typename?: 'client_jobs' }
        & { client: (
          { __typename?: 'clients' }
          & Pick<Clients, 'id'>
          & { tenant: (
            { __typename?: 'tenants' }
            & Pick<Tenants, 'id'>
          ) }
        ) }
      )> }
    )> }
  )> }
);

export type Shortlist_Job_CandidatesMutationVariables = Exact<{
  objects: Array<Shortlists_Insert_Input> | Shortlists_Insert_Input;
}>;


export type Shortlist_Job_CandidatesMutation = (
  { __typename?: 'mutation_root' }
  & { insert_shortlists?: Maybe<(
    { __typename?: 'shortlists_mutation_response' }
    & { returning: Array<(
      { __typename?: 'shortlists' }
      & Pick<Shortlists, 'id' | 'created_at' | 'updated_at' | 'deleted_at'>
      & { job?: Maybe<(
        { __typename?: 'jobs' }
        & Pick<Jobs, 'title'>
        & { client_jobs: Array<(
          { __typename?: 'client_jobs' }
          & { client: (
            { __typename?: 'clients' }
            & { client_locations: Array<(
              { __typename?: 'client_locations' }
              & { location?: Maybe<(
                { __typename?: 'locations' }
                & Pick<Locations, 'postcode' | 'state' | 'locality' | 'coordinate'>
              )> }
            )> }
          ) }
        )> }
      )>, candidate?: Maybe<(
        { __typename?: 'candidates' }
        & Pick<Candidates, 'first_name' | 'last_name'>
        & { candidate_locations: Array<(
          { __typename?: 'candidate_locations' }
          & { location?: Maybe<(
            { __typename?: 'locations' }
            & Pick<Locations, 'locality' | 'state' | 'postcode' | 'coordinate'>
          )> }
        )> }
      )> }
    )> }
  )> }
);

export type Upsert_Unique_ShortlistMutationVariables = Exact<{
  shortlist: Shortlists_Insert_Input;
  columns?: Maybe<Array<Shortlists_Update_Column> | Shortlists_Update_Column>;
}>;


export type Upsert_Unique_ShortlistMutation = (
  { __typename?: 'mutation_root' }
  & { insert_shortlists_one?: Maybe<(
    { __typename?: 'shortlists' }
    & Pick<Shortlists, 'id' | 'created_at' | 'updated_at' | 'deleted_at'>
  )> }
);

export type Upsert_Unique_Candidate_SkillsMutationVariables = Exact<{
  objects: Array<Candidate_Skills_Insert_Input> | Candidate_Skills_Insert_Input;
  candidateId: Scalars['uuid'];
  skillIds?: Maybe<Array<Scalars['uuid']> | Scalars['uuid']>;
}>;


export type Upsert_Unique_Candidate_SkillsMutation = (
  { __typename?: 'mutation_root' }
  & { insert_candidate_skills?: Maybe<(
    { __typename?: 'candidate_skills_mutation_response' }
    & Pick<Candidate_Skills_Mutation_Response, 'affected_rows'>
  )>, remove_unused?: Maybe<(
    { __typename?: 'candidate_skills_mutation_response' }
    & Pick<Candidate_Skills_Mutation_Response, 'affected_rows'>
  )>, reinstate_used?: Maybe<(
    { __typename?: 'candidate_skills_mutation_response' }
    & Pick<Candidate_Skills_Mutation_Response, 'affected_rows'>
  )> }
);

export type All_TenantsQueryVariables = Exact<{ [key: string]: never; }>;


export type All_TenantsQuery = (
  { __typename?: 'query_root' }
  & { tenants: Array<(
    { __typename?: 'tenants' }
    & Pick<Tenants, 'id' | 'name' | 'shortname' | 'year_founded' | 'staff_count' | 'staff_count_max' | 'website' | 'logo' | 'logo_inverse' | 'overview' | 'overview_extended' | 'theme_bg_color' | 'email' | 'enabled' | 'created_at' | 'deleted_at' | 'public'>
    & { tenant_locations: Array<(
      { __typename?: 'tenant_locations' }
      & { location?: Maybe<(
        { __typename?: 'locations' }
        & Pick<Locations, 'locality' | 'state' | 'postcode'>
      )> }
    )>, clients: Array<(
      { __typename?: 'clients' }
      & Pick<Clients, 'id' | 'business_name'>
      & { contacts: Array<(
        { __typename?: 'contacts' }
        & Pick<Contacts, 'email' | 'first_name' | 'last_name'>
      )> }
    )> }
  )> }
);

export type Unique_Tenant_By_ShortnameQueryVariables = Exact<{
  shortname: Scalars['String'];
}>;


export type Unique_Tenant_By_ShortnameQuery = (
  { __typename?: 'query_root' }
  & { tenants: Array<(
    { __typename?: 'tenants' }
    & Pick<Tenants, 'id' | 'name' | 'shortname' | 'year_founded' | 'staff_count' | 'staff_count_max' | 'website' | 'logo' | 'overview' | 'overview_extended' | 'theme_bg_color'>
    & { tenant_locations: Array<(
      { __typename?: 'tenant_locations' }
      & { location?: Maybe<(
        { __typename?: 'locations' }
        & Pick<Locations, 'coordinate' | 'postcode' | 'state' | 'locality'>
      )> }
    )> }
  )> }
);

export type Unique_TenantQueryVariables = Exact<{
  id: Scalars['uuid'];
}>;


export type Unique_TenantQuery = (
  { __typename?: 'query_root' }
  & { tenants_by_pk?: Maybe<(
    { __typename?: 'tenants' }
    & Pick<Tenants, 'id' | 'created_at' | 'deleted_at' | 'enabled' | 'public' | 'name' | 'shortname' | 'theme_bg_color' | 'logo'>
    & { tenant_locations: Array<(
      { __typename?: 'tenant_locations' }
      & Pick<Tenant_Locations, 'id' | 'locality' | 'postcode' | 'state'>
    )> }
  )> }
);

export type Unique_UserQueryVariables = Exact<{
  userId: Scalars['uuid'];
}>;


export type Unique_UserQuery = (
  { __typename?: 'query_root' }
  & { users_by_pk?: Maybe<(
    { __typename?: 'users' }
    & Pick<Users, 'id' | 'auth0_uid' | 'email'>
    & { tenant_users: Array<(
      { __typename?: 'tenant_users' }
      & Pick<Tenant_Users, 'tenant_id'>
    )> }
  )> }
);

export type User_By_Auth0_UidQueryVariables = Exact<{
  auth0_uid: Scalars['String'];
}>;


export type User_By_Auth0_UidQuery = (
  { __typename?: 'query_root' }
  & { users: Array<(
    { __typename?: 'users' }
    & Pick<Users, 'auth0_uid' | 'role' | 'nylas_access_token' | 'nylas_account_id' | 'nylas_calendar_id'>
    & { clients_aggregate: (
      { __typename?: 'clients_aggregate' }
      & { aggregate?: Maybe<(
        { __typename?: 'clients_aggregate_fields' }
        & Pick<Clients_Aggregate_Fields, 'count'>
      )> }
    ), contacts_aggregate: (
      { __typename?: 'contacts_aggregate' }
      & { aggregate?: Maybe<(
        { __typename?: 'contacts_aggregate_fields' }
        & Pick<Contacts_Aggregate_Fields, 'count'>
      )> }
    ), candidates_aggregate: (
      { __typename?: 'candidates_aggregate' }
      & { aggregate?: Maybe<(
        { __typename?: 'candidates_aggregate_fields' }
        & Pick<Candidates_Aggregate_Fields, 'count'>
      )> }
    ), clients: Array<(
      { __typename?: 'clients' }
      & Pick<Clients, 'id'>
      & { tenant: (
        { __typename?: 'tenants' }
        & Pick<Tenants, 'id' | 'shortname' | 'name'>
      ) }
    )>, contacts: Array<(
      { __typename?: 'contacts' }
      & Pick<Contacts, 'id' | 'owner_id' | 'first_name' | 'last_name' | 'domain' | 'email' | 'phone'>
    )>, candidates: Array<(
      { __typename?: 'candidates' }
      & Pick<Candidates, 'id'>
    )>, tenant_users: Array<(
      { __typename?: 'tenant_users' }
      & Pick<Tenant_Users, 'tenant_id'>
      & { tenant: (
        { __typename?: 'tenants' }
        & Pick<Tenants, 'name' | 'shortname'>
      ) }
    )> }
  )> }
);

export type Update_User_Set_Last_SeenMutationVariables = Exact<{
  userId?: Maybe<Scalars['String']>;
}>;


export type Update_User_Set_Last_SeenMutation = (
  { __typename?: 'mutation_root' }
  & { update_users?: Maybe<(
    { __typename?: 'users_mutation_response' }
    & Pick<Users_Mutation_Response, 'affected_rows'>
    & { returning: Array<(
      { __typename?: 'users' }
      & Pick<Users, 'id'>
    )> }
  )> }
);

export type Update_User_Set_Role_As_ClientMutationVariables = Exact<{
  userId?: Maybe<Scalars['String']>;
}>;


export type Update_User_Set_Role_As_ClientMutation = (
  { __typename?: 'mutation_root' }
  & { update_users?: Maybe<(
    { __typename?: 'users_mutation_response' }
    & Pick<Users_Mutation_Response, 'affected_rows'>
    & { returning: Array<(
      { __typename?: 'users' }
      & Pick<Users, 'id'>
    )> }
  )> }
);

export type Update_User_Set_Role_As_CandidateMutationVariables = Exact<{
  userId?: Maybe<Scalars['String']>;
}>;


export type Update_User_Set_Role_As_CandidateMutation = (
  { __typename?: 'mutation_root' }
  & { update_users?: Maybe<(
    { __typename?: 'users_mutation_response' }
    & Pick<Users_Mutation_Response, 'affected_rows'>
    & { returning: Array<(
      { __typename?: 'users' }
      & Pick<Users, 'id'>
    )> }
  )> }
);

export type Update_User_Nylas_CodeMutationVariables = Exact<{
  email?: Maybe<Scalars['String']>;
  nylas_code?: Maybe<Scalars['String']>;
}>;


export type Update_User_Nylas_CodeMutation = (
  { __typename?: 'mutation_root' }
  & { update_users?: Maybe<(
    { __typename?: 'users_mutation_response' }
    & Pick<Users_Mutation_Response, 'affected_rows'>
  )> }
);

export type Update_User_Calendar_IdMutationVariables = Exact<{
  access_token?: Maybe<Scalars['String']>;
  calendar_id?: Maybe<Scalars['String']>;
  account_id?: Maybe<Scalars['String']>;
}>;


export type Update_User_Calendar_IdMutation = (
  { __typename?: 'mutation_root' }
  & { update_users?: Maybe<(
    { __typename?: 'users_mutation_response' }
    & Pick<Users_Mutation_Response, 'affected_rows'>
  )> }
);

export type Unique_User_By_ClientQueryVariables = Exact<{
  id: Scalars['uuid'];
}>;


export type Unique_User_By_ClientQuery = (
  { __typename?: 'query_root' }
  & { users: Array<(
    { __typename?: 'users' }
    & Pick<Users, 'id' | 'email' | 'first_name' | 'last_name' | 'phone' | 'auth0_uid'>
  )> }
);

export type Unique_User_By_EmailQueryVariables = Exact<{
  email?: Maybe<Scalars['String']>;
}>;


export type Unique_User_By_EmailQuery = (
  { __typename?: 'query_root' }
  & { users: Array<(
    { __typename?: 'users' }
    & Pick<Users, 'nylas_access_token'>
  )> }
);

export type Insert_Test_UserMutationVariables = Exact<{
  auth0_uid: Scalars['String'];
  email: Scalars['String'];
  first_name: Scalars['String'];
}>;


export type Insert_Test_UserMutation = (
  { __typename?: 'mutation_root' }
  & { insert_users_one?: Maybe<(
    { __typename?: 'users' }
    & Pick<Users, 'id'>
  )> }
);

export type Delete_Test_UserMutationVariables = Exact<{
  id: Scalars['uuid'];
}>;


export type Delete_Test_UserMutation = (
  { __typename?: 'mutation_root' }
  & { delete_users_by_pk?: Maybe<(
    { __typename?: 'users' }
    & Pick<Users, 'id'>
  )> }
);

export type Delete_Client_UserMutationVariables = Exact<{
  auth0_uid?: Maybe<Scalars['String']>;
}>;


export type Delete_Client_UserMutation = (
  { __typename?: 'mutation_root' }
  & { update_users?: Maybe<(
    { __typename?: 'users_mutation_response' }
    & Pick<Users_Mutation_Response, 'affected_rows'>
  )>, update_clients?: Maybe<(
    { __typename?: 'clients_mutation_response' }
    & Pick<Clients_Mutation_Response, 'affected_rows'>
  )> }
);

export type Delete_Candidate_UserMutationVariables = Exact<{
  auth0_uid?: Maybe<Scalars['String']>;
}>;


export type Delete_Candidate_UserMutation = (
  { __typename?: 'mutation_root' }
  & { update_users?: Maybe<(
    { __typename?: 'users_mutation_response' }
    & Pick<Users_Mutation_Response, 'affected_rows'>
  )>, update_candidates?: Maybe<(
    { __typename?: 'candidates_mutation_response' }
    & Pick<Candidates_Mutation_Response, 'affected_rows'>
  )> }
);


export const Candidate_ActivityDocument = gql`
    query candidate_activity($object: candidates_bool_exp = {}, $limit: Int = 20, $offset: Int = 0) {
  candidates(
    where: $object
    order_by: {updated_at: desc}
    limit: $limit
    offset: $offset
  ) {
    id
    test
    owner_id
    created_at
    deleted_at
    first_name
    last_name
    email
    photo_url
    status
    graduates_on
    published
    published_on
    candidate_industries {
      industry {
        id
        title
      }
    }
    candidate_locations(where: {deleted_at: {_is_null: true}}) {
      location {
        postcode
        state
        locality
      }
    }
  }
  candidates_aggregate(where: $object, order_by: {updated_at: desc}) {
    aggregate {
      count
    }
  }
}
    `;
export const Client_ActivityDocument = gql`
    query client_activity($created_at_range: timestamptz_comparison_exp!) {
  jobs_aggregate(where: {created_at: $created_at_range, test: {_eq: false}}) {
    aggregate {
      count
    }
  }
  users(
    where: {created_at: $created_at_range, role: {_eq: "client"}, deleted_at: {_is_null: true}, test: {_eq: false}}
    order_by: {updated_at: desc}
  ) {
    id
    email
    auth0_uid
    created_at
    updated_at
    contacts {
      id
      created_at
      first_name
      last_name
    }
    contacts_aggregate {
      aggregate {
        count
      }
    }
    clients {
      id
      business_name
      created_at
      terms_accepted_on
    }
    clients_aggregate {
      aggregate {
        count
      }
    }
  }
}
    `;
export const TrackDocument = gql`
    mutation track($event: String!, $ownerId: String!, $tenantId: String) {
  track(payload: {event: $event, ownerId: $ownerId, tenantId: $tenantId})
}
    `;
export const TrackResultDocument = gql`
    query trackResult($id: uuid!) {
  track(id: $id)
}
    `;
export const Create_EventDocument = gql`
    mutation create_event($object: events_insert_input!) {
  insert_events_one(object: $object) {
    id
    created_at
  }
}
    `;
export const Delete_EventsDocument = gql`
    mutation delete_events($object: events_bool_exp!) {
  delete_events(where: $object) {
    returning {
      candidate_id
      client_id
      contact_id
      id
      interview_id
      owner_id
      job_id
      tenant_id
    }
  }
}
    `;
export const Unique_EventDocument = gql`
    query unique_event($eventId: uuid!) {
  events_by_pk(id: $eventId) {
    id
    created_at
    event
    source
    context
    owner_id
    tenant_id
    user {
      id
      auth0_uid
      email
      role
    }
    client {
      id
    }
    contact {
      id
    }
    job {
      id
    }
    candidate {
      id
      email
      first_name
      last_name
    }
    shortlist {
      id
      candidate {
        id
        first_name
        last_name
        photo_url
      }
      job {
        id
        title
      }
      client {
        id
      }
    }
    short_url {
      id
    }
    tenant {
      id
      shortname
      name
    }
    interview {
      id
    }
  }
}
    `;
export const All_Events_By_ClientDocument = gql`
    query all_events_by_client($ownerId: String = "", $event: String_comparison_exp = {_neq: "Identified"}) {
  events(
    order_by: {created_at: desc}
    where: {deleted_at: {_is_null: true}, owner_id: {_eq: $ownerId}, event: $event}
    limit: 100
  ) {
    id
    created_at
    event
    source
    context
    owner_id
    user {
      id
      auth0_uid
      email
      role
      clients {
        id
        business_name
      }
      candidates {
        id
        first_name
        last_name
        photo_url
      }
    }
    client {
      id
    }
    contact {
      id
    }
    job {
      id
    }
    candidate {
      id
    }
    shortlist {
      id
      candidate {
        id
        first_name
        last_name
        photo_url
      }
      job {
        id
        title
      }
      client {
        id
      }
    }
    short_url {
      id
    }
    tenant {
      id
      shortname
      name
    }
    interview {
      id
    }
    feature_popup {
      id
      name
    }
  }
}
    `;
export const All_EventsDocument = gql`
    query all_events($event: String_comparison_exp = {}, $email: String_comparison_exp = {}, $feature_popups_id: uuid_comparison_exp = {}, $limit: Int = 100, $offset: Int = 0) {
  events(
    order_by: {created_at: desc}
    where: {deleted_at: {_is_null: true}, event: $event, user: {email: $email}, feature_popups_id: $feature_popups_id}
    limit: $limit
    offset: $offset
  ) {
    id
    created_at
    event
    source
    context
    owner_id
    user {
      id
      auth0_uid
      email
      role
      clients {
        id
        business_name
      }
      candidates {
        id
        first_name
        last_name
        photo_url
      }
    }
    client {
      id
    }
    contact {
      id
    }
    job {
      id
    }
    candidate {
      id
    }
    shortlist {
      id
      candidate {
        id
        first_name
        last_name
        photo_url
      }
      job {
        id
        title
      }
      client {
        id
      }
    }
    short_url {
      id
    }
    tenant {
      id
      shortname
      name
    }
    interview {
      id
    }
    feature_popup {
      id
      name
    }
  }
  events_aggregate(
    order_by: {created_at: desc}
    where: {deleted_at: {_is_null: true}, event: $event, user: {email: $email}, feature_popups_id: $feature_popups_id}
  ) {
    aggregate {
      count
    }
  }
}
    `;
export const Search_EventsDocument = gql`
    query search_events($object: events_bool_exp = {deleted_at: {_is_null: true}}, $limit: Int = 100, $offset: Int = 0) {
  events(
    order_by: {created_at: desc}
    where: $object
    limit: $limit
    offset: $offset
  ) {
    id
    created_at
    event
    source
    context
    owner_id
    user {
      id
      auth0_uid
      email
      role
      clients {
        id
        business_name
      }
      candidates {
        id
        first_name
        last_name
        photo_url
      }
    }
    client {
      id
    }
    contact {
      id
    }
    job {
      id
    }
    candidate {
      id
    }
    shortlist {
      id
      candidate {
        id
        first_name
        last_name
        photo_url
      }
      job {
        id
        title
      }
      client {
        id
      }
    }
    short_url {
      id
    }
    tenant {
      id
      shortname
      name
    }
    interview {
      id
    }
    feature_popup {
      id
      name
    }
  }
  events_aggregate(order_by: {created_at: desc}, where: $object) {
    aggregate {
      count
    }
  }
}
    `;
export const Search_All_EventsDocument = gql`
    query search_all_events($object: events_bool_exp = {deleted_at: {_is_null: true}}) {
  events(order_by: {created_at: desc}, where: $object) {
    id
    created_at
    event
    source
    context
    owner_id
    user {
      id
      auth0_uid
      email
      role
    }
    client {
      id
    }
    contact {
      id
    }
    job {
      id
    }
    candidate {
      id
    }
    shortlist {
      id
      candidate {
        id
        first_name
        last_name
        photo_url
      }
      job {
        id
        title
      }
      client {
        id
      }
    }
    short_url {
      id
    }
    tenant {
      id
      shortname
      name
    }
    interview {
      id
    }
  }
  events_aggregate(order_by: {created_at: desc}, where: $object) {
    aggregate {
      count
    }
  }
}
    `;
export const Date_Range_EventsDocument = gql`
    query date_range_events($created_at_range: timestamptz_comparison_exp!, $event: String_comparison_exp, $candidate: candidates_bool_exp, $ownerId: String_comparison_exp = {}) {
  events(
    order_by: {created_at: desc}
    where: {deleted_at: {_is_null: true}, created_at: $created_at_range, event: $event, candidate: $candidate, owner_id: $ownerId}
  ) {
    id
    created_at
    event
    owner_id
    user {
      id
      auth0_uid
      email
      role
    }
    client {
      id
    }
    contact {
      id
    }
    job {
      id
    }
    candidate {
      id
    }
    shortlist {
      id
      candidate {
        id
        first_name
        last_name
        photo_url
      }
      job {
        id
        title
      }
      client {
        id
      }
    }
    short_url {
      id
    }
    tenant {
      id
      shortname
      name
    }
    interview {
      id
    }
  }
}
    `;
export const Aggregate_EventsDocument = gql`
    query aggregate_events($event: String_comparison_exp = {}, $candidate: candidates_bool_exp, $user: users_bool_exp = {}) {
  events_aggregate(
    where: {deleted_at: {_is_null: true}, event: $event, candidate: $candidate, user: $user}
  ) {
    aggregate {
      count
    }
  }
}
    `;
export const Enterprise_ViewsDocument = gql`
    query enterprise_views($ownerId: String = "", $event: String = "") {
  events(
    where: {enterprise: {enterprise_clients: {client: {owner_id: {_eq: $ownerId}}}}, event: {_eq: $event}}
  ) {
    candidate_id
    enterprise_id
    created_at
  }
}
    `;
export const Enterprise_EventsDocument = gql`
    query enterprise_events($event: String = "", $enterpriseId: uuid = "") {
  events(where: {enterprise: {id: {_eq: $enterpriseId}}, event: {_eq: $event}}) {
    candidate_id
    enterprise_id
    created_at
  }
}
    `;
export const Unique_Event_By_Auth0_UidDocument = gql`
    query unique_event_by_auth0_uid($auth0_uid: String = "", $event: String = "") {
  events(
    where: {owner_id: {_eq: $auth0_uid}, event: {_eq: $event}}
    limit: 1
    order_by: {created_at: desc}
  ) {
    created_at
  }
}
    `;
export const Upsert_Unique_CandidateDocument = gql`
    mutation upsert_unique_candidate($candidate: candidates_insert_input!, $columns: [candidates_update_column!]) {
  insert_candidates_one(
    object: $candidate
    on_conflict: {constraint: candidates_pkey, update_columns: $columns}
  ) {
    id
    status
  }
}
    `;
export const All_CandidatesDocument = gql`
    query all_candidates {
  candidates(
    order_by: {created_at: desc}
    where: {deleted_at: {_is_null: true}, test: {_eq: false}}
  ) {
    id
    owner_id
    created_at
    first_name
    last_name
    email
    photo_url
    status
    published
    candidate_locations {
      location {
        postcode
        state
        locality
      }
    }
    tenant_candidates {
      tenant {
        id
        shortname
      }
    }
  }
}
    `;
export const Create_CandidateDocument = gql`
    mutation create_candidate($object: candidates_insert_input!) {
  insert_candidates_one(object: $object) {
    id
    first_name
    last_name
    email
    phone
    owner_id
  }
}
    `;
export const Create_Unique_Tenant_CandidateDocument = gql`
    mutation create_unique_tenant_candidate($candidate: tenant_candidates_insert_input!) {
  insert_tenant_candidates_one(object: $candidate) {
    id
  }
}
    `;
export const Search_Candidates_By_ObjectDocument = gql`
    query search_candidates_by_object($object: candidates_bool_exp, $views_date_range: timestamptz_comparison_exp = {}) {
  candidates(where: $object) {
    id
    owner_id
    published_on
    published
    first_name
    last_name
    photo_url
    video_url
    cv_url
    test
    graduates_on
    status
    open_to_part_time_prior_to_graduation
    available_part_time_days_from
    available_part_time_days_to
    work_arrangement
    views_counter: events_aggregate(
      where: {event: {_eq: "Viewed candidate"}, created_at: $views_date_range, deleted_at: {_is_null: true}, user: {email: {_nilike: "%striver.careers%"}}}
    ) {
      aggregate {
        count
      }
    }
    candidate_industries(where: {deleted_at: {_is_null: true}}) {
      industry {
        id
      }
    }
    candidate_degrees(where: {deleted_at: {_is_null: true}}) {
      degree {
        id
      }
    }
    candidate_majors(where: {deleted_at: {_is_null: true}}) {
      major {
        id
      }
    }
    candidate_skills(where: {deleted_at: {_is_null: true}}) {
      skill {
        id
      }
    }
    candidate_locations(where: {deleted_at: {_is_null: true}}) {
      location {
        coordinate
        postcode
        locality
        state
      }
    }
  }
}
    `;
export const Search_CandidatesDocument = gql`
    query search_candidates($status: candidate_status_enums_comparison_exp, $work_arrangement: work_arrangement_enums_comparison_exp, $available_part_time_days_from: Int_comparison_exp, $available_part_time_days_to: Int_comparison_exp, $can_commence_work_on: timestamptz_comparison_exp!, $open_to_part_time_prior_to_graduation: Boolean_comparison_exp, $skills: candidate_skills_bool_exp, $majors: candidate_majors_bool_exp, $degrees: candidate_degrees_bool_exp, $industries: candidate_industries_bool_exp, $location: candidate_locations_bool_exp, $published: Boolean_comparison_exp = {_eq: true}, $limit: Int = 20, $offset: Int = 0) {
  filtered: candidates(
    where: {published: $published, status: $status, graduates_on: $can_commence_work_on, work_arrangement: $work_arrangement, available_part_time_days_from: $available_part_time_days_from, available_part_time_days_to: $available_part_time_days_to, open_to_part_time_prior_to_graduation: $open_to_part_time_prior_to_graduation, candidate_skills: $skills, candidate_majors: $majors, candidate_degrees: $degrees, candidate_industries: $industries, candidate_locations: $location, deleted_at: {_is_null: true}, test: {_eq: false}}
    order_by: {id: asc}
    limit: $limit
    offset: $offset
  ) {
    id
    owner_id
    published_on
    published
    first_name
    last_name
    photo_url
    video_url
    cv_url
    test
    graduates_on
    status
    open_to_part_time_prior_to_graduation
    available_part_time_days_from
    available_part_time_days_to
    work_arrangement
    candidate_industries(where: {deleted_at: {_is_null: true}}) {
      industry {
        id
      }
    }
    candidate_degrees(where: {deleted_at: {_is_null: true}}) {
      degree {
        id
      }
    }
    candidate_majors(where: {deleted_at: {_is_null: true}}) {
      major {
        id
      }
    }
    candidate_skills(where: {deleted_at: {_is_null: true}}) {
      skill {
        id
      }
    }
    candidate_locations(where: {deleted_at: {_is_null: true}}) {
      location {
        coordinate
        postcode
        locality
        state
      }
    }
  }
  latest: candidates(
    where: {published: $published, published_on: {_is_null: false}, deleted_at: {_is_null: true}, test: {_eq: false}}
    order_by: {published_on: desc}
    limit: 2
  ) {
    id
    owner_id
    published
    test
    first_name
    last_name
    photo_url
    cv_url
    graduates_on
    status
    open_to_part_time_prior_to_graduation
    available_part_time_days_from
    available_part_time_days_to
    work_arrangement
    university_id
    university {
      name
      short_name
    }
    candidate_industries(where: {deleted_at: {_is_null: true}}) {
      industry {
        id
      }
    }
    candidate_degrees(where: {deleted_at: {_is_null: true}}) {
      degree {
        id
      }
    }
    candidate_majors(where: {deleted_at: {_is_null: true}}) {
      major {
        id
      }
    }
    candidate_skills(where: {deleted_at: {_is_null: true}}) {
      skill {
        id
      }
    }
    candidate_locations(where: {deleted_at: {_is_null: true}}) {
      location {
        coordinate
        postcode
        locality
        state
      }
    }
  }
  default: candidates(
    where: {published: $published, status: $status, deleted_at: {_is_null: true}}
    order_by: {id: asc}
    limit: 3
  ) {
    id
    owner_id
    published
    test
    first_name
    last_name
    photo_url
    cv_url
    graduates_on
    status
    open_to_part_time_prior_to_graduation
    available_part_time_days_from
    available_part_time_days_to
    work_arrangement
    university_id
    university {
      name
      short_name
    }
    candidate_industries(where: {deleted_at: {_is_null: true}}) {
      industry {
        id
      }
    }
    candidate_degrees(where: {deleted_at: {_is_null: true}}) {
      degree {
        id
      }
    }
    candidate_majors(where: {deleted_at: {_is_null: true}}) {
      major {
        id
      }
    }
    candidate_skills(where: {deleted_at: {_is_null: true}}) {
      skill {
        id
      }
    }
    candidate_locations(where: {deleted_at: {_is_null: true}}) {
      location {
        coordinate
        postcode
        locality
        state
      }
    }
  }
  candidates_aggregate: candidates_aggregate(
    where: {published: $published, status: $status, graduates_on: $can_commence_work_on, work_arrangement: $work_arrangement, available_part_time_days_from: $available_part_time_days_from, available_part_time_days_to: $available_part_time_days_to, open_to_part_time_prior_to_graduation: $open_to_part_time_prior_to_graduation, candidate_skills: $skills, candidate_majors: $majors, candidate_degrees: $degrees, candidate_industries: $industries, candidate_locations: $location, deleted_at: {_is_null: true}, test: {_eq: false}}
  ) {
    aggregate {
      count
    }
  }
}
    `;
export const Aggregate_CandidatesDocument = gql`
    query aggregate_candidates($published: Boolean_comparison_exp = {}) {
  candidates_aggregate(where: {published: $published}) {
    aggregate {
      count
    }
  }
}
    `;
export const Search_All_CandidatesDocument = gql`
    query search_all_candidates($object: candidates_bool_exp = {}) {
  candidates(where: $object, order_by: {updated_at: desc}) {
    id
    test
    owner_id
    created_at
    deleted_at
    first_name
    last_name
    email
    phone
    photo_url
    status
    graduates_on
    published
    published_on
    notes
    candidate_industries {
      industry {
        id
        title
      }
    }
    candidate_degrees {
      degree {
        id
        title
      }
    }
    candidate_majors {
      major {
        id
        title
      }
    }
    candidate_locations(where: {deleted_at: {_is_null: true}}) {
      location {
        postcode
        state
        locality
      }
    }
  }
}
    `;
export const Unique_Candidate_AdminDocument = gql`
    query unique_candidate_admin($candidateId: uuid!) {
  candidates_by_pk(id: $candidateId) {
    id
    owner_id
    created_at
    updated_at
    deleted_at
    test
    first_name
    last_name
    residency_status
    has_work_experience
    bio
    relocate
    notes
    cv_url
    photo_url
    video_url
    transcripts_url
    psych_url
    phone
    published
    published_on
    email
    profile_progress
    work_arrangement
    graduates_on
    status
    open_to_part_time_prior_to_graduation
    available_part_time_days_from
    available_part_time_days_to
    university_id
    university {
      name
      short_name
    }
    candidate_skills(where: {deleted_at: {_is_null: true}}) {
      score
      skill {
        id
        title
      }
    }
    candidate_majors(where: {deleted_at: {_is_null: true}}) {
      major {
        id
        title
      }
    }
    candidate_degrees(where: {deleted_at: {_is_null: true}}) {
      degree {
        id
        title
      }
    }
    candidate_industries(where: {deleted_at: {_is_null: true}}) {
      industry {
        id
        title
      }
    }
    candidate_locations(where: {deleted_at: {_is_null: true}}) {
      location {
        locality
        state
        postcode
        coordinate
      }
    }
    candidate_courses {
      result
      course_id
      candidate_id
      course {
        id
        img
        name
        overview
      }
      candidate {
        id
      }
    }
  }
}
    `;
export const Unique_CandidateDocument = gql`
    query unique_candidate($candidateId: uuid!) {
  candidates_by_pk(id: $candidateId) {
    id
    owner_id
    created_at
    updated_at
    first_name
    last_name
    residency_status
    has_work_experience
    bio
    cv_url
    photo_url
    video_url
    transcripts_url
    psych_url
    phone
    published
    published_on
    email
    profile_progress
    work_arrangement
    graduates_on
    status
    open_to_part_time_prior_to_graduation
    available_part_time_days_from
    available_part_time_days_to
    university_id
    tenant_candidates {
      tenant_id
    }
    university {
      name
      short_name
    }
    candidate_skills(where: {deleted_at: {_is_null: true}}) {
      score
      skill {
        id
        title
      }
    }
    candidate_majors(where: {deleted_at: {_is_null: true}}) {
      major {
        id
        title
      }
    }
    candidate_degrees(where: {deleted_at: {_is_null: true}}) {
      degree {
        id
        title
      }
    }
    candidate_industries(where: {deleted_at: {_is_null: true}}) {
      industry {
        id
        title
      }
    }
    candidate_locations(where: {deleted_at: {_is_null: true}}) {
      location {
        locality
        state
        postcode
        coordinate
      }
    }
    candidate_courses {
      result
      course_id
      candidate_id
      course {
        id
        img
        name
        overview
      }
      candidate {
        id
      }
    }
  }
}
    `;
export const Unique_Candidate_PreviewDocument = gql`
    query unique_candidate_preview($candidateId: uuid!) {
  candidates_by_pk(id: $candidateId) {
    id
    created_at
    updated_at
    first_name
    bio
    graduates_on
    available_part_time_days_from
    available_part_time_days_to
    open_to_part_time_prior_to_graduation
    photo_url
    video_url
    university_id
    published
    candidate_skills(where: {score: {_is_null: false}}) {
      skill {
        id
        title
      }
    }
    candidate_majors {
      major {
        id
        title
      }
    }
    candidate_degrees {
      degree {
        id
        title
      }
    }
    candidate_industries(where: {verified: {_eq: true}}) {
      industry {
        id
        title
      }
    }
    candidate_locations(where: {deleted_at: {_is_null: true}}) {
      location {
        locality
        state
        postcode
      }
    }
  }
}
    `;
export const Candidate_StatsDocument = gql`
    query candidate_stats($clientId: uuid!, $candidateId: uuid!) {
  shortlisted_by_client_count: shortlists_aggregate(
    where: {client: {id: {_eq: $clientId}}, candidate: {id: {_eq: $candidateId}}}
  ) {
    aggregate {
      count: count
    }
  }
  shortlisted_by_all_count: shortlists_aggregate(
    where: {candidate: {id: {_eq: $candidateId}}}
  ) {
    aggregate {
      count: count
    }
  }
  liked_by_client_count: shortlists_aggregate(
    where: {client: {id: {_eq: $clientId}}, candidate: {id: {_eq: $candidateId}}}
  ) {
    aggregate {
      count: count
    }
  }
  liked_by_all_count: shortlists_aggregate(
    where: {job_id: {_is_null: true}, candidate: {id: {_eq: $candidateId}}}
  ) {
    aggregate {
      count: count
    }
  }
}
    `;
export const Unique_Candidate_EmailDocument = gql`
    query unique_candidate_email($email: String, $limit: Int = 100, $published: Boolean_comparison_exp = {}) {
  candidates(
    where: {email: {_ilike: $email}, published: $published}
    limit: $limit
  ) {
    id
    email
    photo_url
    first_name
    last_name
    phone
  }
}
    `;
export const Unique_Candidate_By_Owner_IdDocument = gql`
    query unique_candidate_by_owner_id($owner_id: String = "") {
  candidates(where: {owner_id: {_eq: $owner_id}, deleted_at: {_is_null: true}}) {
    id
    residency_status
    status
    published
    candidate_locations(where: {deleted_at: {_is_null: true}}) {
      location {
        coordinate
        postcode
        locality
        state
      }
    }
  }
}
    `;
export const Delete_Candidate_IndustriesDocument = gql`
    mutation delete_candidate_industries($candidateId: uuid!) {
  delete_candidate_industries(where: {candidate_id: {_eq: $candidateId}}) {
    returning {
      candidate_id
      industry_id
    }
  }
}
    `;
export const Delete_Candidate_SkillsDocument = gql`
    mutation delete_candidate_skills($candidateId: uuid!) {
  delete_candidate_skills(where: {candidate_id: {_eq: $candidateId}}) {
    returning {
      candidate_id
      skill_id
    }
  }
}
    `;
export const Delete_Candidate_LocationsDocument = gql`
    mutation delete_candidate_locations($candidateId: uuid!) {
  delete_candidate_locations(where: {candidate_id: {_eq: $candidateId}}) {
    returning {
      candidate_id
      location {
        id
      }
    }
  }
}
    `;
export const Delete_Candidate_DegreesDocument = gql`
    mutation delete_candidate_degrees($candidateId: uuid!) {
  delete_candidate_degrees(where: {candidate_id: {_eq: $candidateId}}) {
    returning {
      candidate_id
      degree_id
    }
  }
}
    `;
export const Delete_Candidate_MajorsDocument = gql`
    mutation delete_candidate_majors($candidateId: uuid!) {
  delete_candidate_majors(where: {candidate_id: {_eq: $candidateId}}) {
    returning {
      candidate_id
      major_id
    }
  }
}
    `;
export const Delete_Test_Tenant_CandidateDocument = gql`
    mutation delete_test_tenant_candidate($candidateId: uuid!) {
  delete_tenant_candidates(where: {candidate_id: {_eq: $candidateId}}) {
    returning {
      id
      candidate_id
      tenant_id
    }
  }
}
    `;
export const Delete_Test_CandidateDocument = gql`
    mutation delete_test_candidate($candidateId: uuid!) {
  delete_candidates_by_pk(id: $candidateId) {
    id
    first_name
    email
    last_name
    owner_id
  }
}
    `;
export const Send_Candidate_First_Viewed_EmailDocument = gql`
    mutation send_candidate_first_viewed_email($candidateId: uuid) {
  send_candidate_first_viewed_email(payload: {candidate_id: $candidateId})
}
    `;
export const All_Candidates_By_StatusDocument = gql`
    query all_candidates_by_status($residency_status: residency_status_enums_comparison_exp = {}, $graduates_on: timestamptz_comparison_exp = {}, $status: candidate_status_enums_comparison_exp = {}, $notes: String_comparison_exp = {}) {
  candidates(
    where: {graduates_on: $graduates_on, residency_status: $residency_status, status: $status}
  ) {
    id
    notes
  }
}
    `;
export const Send_Candidate_No_Show_EmailDocument = gql`
    mutation send_candidate_no_show_email($candidateId: uuid) {
  send_candidate_no_show_email(payload: {candidate_id: $candidateId})
}
    `;
export const Upsert_Unique_ClientDocument = gql`
    mutation upsert_unique_client($client: clients_insert_input!, $columns: [clients_update_column!]) {
  insert_clients_one(
    object: $client
    on_conflict: {constraint: clients_pkey, update_columns: $columns}
  ) {
    id
  }
}
    `;
export const Update_Unique_Client_ContactDocument = gql`
    mutation update_unique_client_contact($contact: contacts_set_input!, $columns: contacts_pk_columns_input!) {
  update_contacts_by_pk(_set: $contact, pk_columns: $columns) {
    id
  }
}
    `;
export const Create_ClientDocument = gql`
    mutation create_client($object: clients_insert_input!) {
  insert_clients_one(object: $object) {
    id
    owner_id
    business_name
  }
}
    `;
export const Delete_Test_ClientDocument = gql`
    mutation delete_test_client($clientId: uuid!) {
  delete_clients_by_pk(id: $clientId) {
    business_name
    id
    owner {
      auth0_uid
      first_name
      id
    }
  }
}
    `;
export const Delete_Test_Client_EventsDocument = gql`
    mutation delete_test_client_events($clientId: uuid!) {
  delete_events(where: {client_id: {_eq: $clientId}}) {
    returning {
      id
      client_id
    }
  }
}
    `;
export const Delete_Test_Client_JobsDocument = gql`
    mutation delete_test_client_jobs($clientId: uuid!) {
  delete_client_jobs(where: {client_id: {_eq: $clientId}}) {
    returning {
      id
      client_id
    }
  }
}
    `;
export const Create_Client_LocationDocument = gql`
    mutation create_client_location($object: client_locations_insert_input!) {
  insert_client_locations_one(object: $object) {
    id
  }
}
    `;
export const Delete_Test_Client_LocationDocument = gql`
    mutation delete_test_client_location($clientId: uuid!) {
  delete_client_locations(where: {client_id: {_eq: $clientId}}) {
    affected_rows
  }
}
    `;
export const Create_Client_IndustriesDocument = gql`
    mutation create_client_industries($objects: [client_industries_insert_input!]!) {
  insert_client_industries(
    objects: $objects
    on_conflict: {constraint: client_industries_pkey, update_columns: [industry_id, client_id]}
  ) {
    returning {
      id
    }
  }
}
    `;
export const Delete_Test_Client_IndustriesDocument = gql`
    mutation delete_test_client_industries($clientId: uuid!) {
  delete_client_industries(where: {client_id: {_eq: $clientId}}) {
    affected_rows
  }
}
    `;
export const All_ClientsDocument = gql`
    query all_clients {
  clients(
    order_by: {created_at: desc}
    where: {deleted_at: {_is_null: true}, test: {_eq: false}}
  ) {
    id
    created_at
    business_name
    terms_accepted_on
    tenant {
      id
    }
    client_locations {
      location {
        coordinate
        locality
        state
        postcode
      }
    }
    contacts {
      id
      email
      phone
      first_name
      last_name
    }
    client_industries {
      industry {
        id
        title
      }
    }
    client_jobs {
      job {
        id
        title
        created_at
      }
    }
  }
}
    `;
export const Search_ClientsDocument = gql`
    query search_clients($test: Boolean_comparison_exp = {}, $business_name: String_comparison_exp = {}, $terms_accepted_on: timestamptz_comparison_exp = {}, $contacts: contacts_bool_exp = {}, $updated_at: timestamptz_comparison_exp = {}, $client_industries: client_industries_bool_exp = {}, $client_locations: client_locations_bool_exp = {}, $limit: Int = 20, $offset: Int = 0, $order_by: [clients_order_by!] = {created_at: desc}) {
  clients(
    order_by: $order_by
    where: {deleted_at: {_is_null: true}, test: $test, business_name: $business_name, contacts: $contacts, owner: {updated_at: $updated_at}, client_industries: $client_industries, client_locations: $client_locations, terms_accepted_on: $terms_accepted_on}
    limit: $limit
    offset: $offset
  ) {
    id
    created_at
    updated_at
    business_name
    terms_accepted_on
    owner {
      updated_at
    }
    tenant {
      id
      name
    }
    client_locations {
      location {
        coordinate
        locality
        state
        postcode
        coordinate
      }
    }
    contacts {
      id
      email
      phone
      first_name
      last_name
    }
    client_industries(where: {deleted_at: {_is_null: true}}) {
      industry {
        id
        title
      }
    }
    client_jobs {
      job {
        id
        title
        created_at
      }
    }
  }
  clients_aggregate(
    order_by: $order_by
    where: {deleted_at: {_is_null: true}, test: $test, business_name: $business_name, contacts: $contacts, owner: {updated_at: $updated_at}, client_industries: $client_industries, client_locations: $client_locations, terms_accepted_on: $terms_accepted_on}
  ) {
    aggregate {
      count
    }
  }
}
    `;
export const Search_All_ClientsDocument = gql`
    query search_all_clients($test: Boolean_comparison_exp = {}, $business_name: String_comparison_exp = {}, $terms_accepted_on: timestamptz_comparison_exp = {}, $contacts: contacts_bool_exp = {}, $updated_at: timestamptz_comparison_exp = {}, $client_industries: client_industries_bool_exp = {}, $client_locations: client_locations_bool_exp = {}, $order_by: [clients_order_by!] = {created_at: desc}) {
  clients(
    order_by: $order_by
    where: {deleted_at: {_is_null: true}, test: $test, business_name: $business_name, contacts: $contacts, owner: {updated_at: $updated_at}, client_industries: $client_industries, client_locations: $client_locations, terms_accepted_on: $terms_accepted_on}
  ) {
    created_at
    updated_at
    business_name
    owner {
      updated_at
    }
    client_locations {
      location {
        coordinate
        locality
        state
        postcode
      }
    }
    contacts {
      email
      first_name
      last_name
    }
  }
}
    `;
export const Unique_Client_By_OwnerIdDocument = gql`
    query unique_client_by_ownerId($ownerId: String!) {
  clients(where: {owner_id: {_eq: $ownerId}}) {
    id
    business_name
    owner_id
    terms_accepted_on
    notes
    test
    tenant {
      id
    }
    client_locations {
      location {
        coordinate
        locality
        state
        postcode
      }
    }
    contacts {
      id
      email
      phone
      first_name
      last_name
    }
    client_industries(where: {deleted_at: {_is_null: true}}) {
      industry {
        id
        title
      }
    }
    client_jobs {
      job {
        id
        title
        created_at
      }
    }
    owner {
      nylas_access_token
      nylas_calendar_id
    }
  }
}
    `;
export const Unique_ClientDocument = gql`
    query unique_client($clientId: uuid!) {
  clients_by_pk(id: $clientId) {
    id
    business_name
    owner_id
    terms_accepted_on
    notes
    domain
    test
    created_at
    tenant {
      id
      name
      terms_url
    }
    client_locations(where: {deleted_at: {_is_null: true}}) {
      location {
        coordinate
        locality
        state
        postcode
      }
    }
    contacts {
      id
      email
      phone
      first_name
      last_name
    }
    client_industries(where: {deleted_at: {_is_null: true}}) {
      industry {
        id
        title
      }
    }
    client_jobs {
      job {
        id
        title
        created_at
      }
    }
    owner {
      created_at
      nylas_access_token
      nylas_calendar_id
    }
    enterprise_clients(where: {deleted_at: {_is_null: true}}) {
      enterprise_id
    }
  }
}
    `;
export const All_Client_JobsDocument = gql`
    query all_client_jobs($clientId: uuid) {
  jobs(where: {client_jobs: {client: {id: {_eq: $clientId}}}}) {
    id
    title
  }
}
    `;
export const Client_SelectDocument = gql`
    query client_select {
  clients(where: {deleted_at: {_is_null: true}}) {
    id
    business_name
  }
}
    `;
export const Attach_Client_To_JobDocument = gql`
    mutation attach_client_to_job($jobId: uuid!, $object: [client_jobs_insert_input!]!) {
  remove_unused: update_client_jobs(
    _set: {deleted_at: "now()"}
    where: {job_id: {_eq: $jobId}}
  ) {
    affected_rows
  }
  insert_client_jobs(
    objects: $object
    on_conflict: {constraint: client_jobs_pkey, update_columns: [updated_at]}
  ) {
    affected_rows
  }
}
    `;
export const Unique_Client_By_EmailDocument = gql`
    query unique_client_by_email($email: String, $limit: Int = 100) {
  clients(where: {contacts: {email: {_ilike: $email}}}, limit: $limit) {
    id
    business_name
    contacts {
      first_name
      last_name
      email
      phone
    }
  }
}
    `;
export const Create_ContactDocument = gql`
    mutation create_contact($object: contacts_insert_input!) {
  insert_contacts_one(object: $object) {
    id
    first_name
    last_name
    email
    phone
    owner_id
  }
}
    `;
export const Delete_Test_ContactDocument = gql`
    mutation delete_test_contact($clientId: uuid!) {
  delete_contacts(where: {client_id: {_eq: $clientId}}) {
    affected_rows
  }
}
    `;
export const Update_Contact_By_PkDocument = gql`
    mutation update_contact_by_pk($id: uuid!, $object: contacts_set_input!) {
  update_contacts_by_pk(pk_columns: {id: $id}, _set: $object) {
    id
    email
    last_name
    first_name
    domain
  }
}
    `;
export const Unique_ContactDocument = gql`
    query unique_contact($contactId: uuid!) {
  contacts_by_pk(id: $contactId) {
    id
    first_name
    last_name
    email
    phone
    owner_id
    client {
      id
      tenant {
        id
        shortname
      }
    }
  }
}
    `;
export const Upsert_Unique_Candidate_DegreesDocument = gql`
    mutation upsert_unique_candidate_degrees($objects: [candidate_degrees_insert_input!]!, $candidateId: uuid!, $degreeIds: [uuid!]) {
  insert_candidate_degrees(
    objects: $objects
    on_conflict: {constraint: candidate_degrees_candidate_id_degree_id_key, update_columns: [updated_at]}
  ) {
    affected_rows
  }
  remove_unused: update_candidate_degrees(
    _set: {deleted_at: "now()"}
    where: {candidate_id: {_eq: $candidateId}, degree_id: {_nin: $degreeIds}}
  ) {
    affected_rows
  }
  reinstate_used: update_candidate_degrees(
    _set: {deleted_at: null}
    where: {candidate_id: {_eq: $candidateId}, degree_id: {_in: $degreeIds}}
  ) {
    affected_rows
  }
}
    `;
export const All_EnterprisesDocument = gql`
    query all_enterprises @cached {
  enterprises(order_by: {created_at: asc}) {
    id
    name
    shortname
    year_founded
    staff_count
    staff_count_max
    website
    logo
    logo_inverse
    overview
    overview_extended
    theme_bg_color
    email
    enabled
    created_at
    lp_job
    lp_img
    public
    enterprise_locations {
      location {
        locality
        state
        postcode
      }
    }
    candidate_follows_aggregate(where: {deleted_at: {_is_null: true}}) {
      aggregate {
        count
      }
    }
  }
}
    `;
export const All_Public_EnterprisesDocument = gql`
    query all_public_enterprises @cached {
  enterprises(
    where: {enabled: {_eq: true}, public: {_eq: true}}
    order_by: {created_at: asc}
  ) {
    id
    name
    shortname
    year_founded
    staff_count
    staff_count_max
    website
    logo
    logo_inverse
    overview
    overview_extended
    theme_bg_color
    email
    enabled
    created_at
    lp_job
    lp_img
    public
    enterprise_locations {
      location {
        locality
        state
        postcode
      }
    }
  }
}
    `;
export const Unique_Enterprise_By_ShortnameDocument = gql`
    query unique_enterprise_by_shortname($shortname: String!) {
  enterprises(
    where: {shortname: {_eq: $shortname}, enabled: {_eq: true}, public: {_eq: true}}
  ) {
    id
    name
    shortname
    year_founded
    staff_count
    staff_count_max
    website
    logo
    overview
    overview_extended
    theme_bg_color
    lp_job
    enterprise_locations {
      location {
        coordinate
        postcode
        state
        locality
      }
    }
  }
}
    `;
export const Unique_EnterpriseDocument = gql`
    query unique_enterprise($id: uuid = "") {
  enterprises_by_pk(id: $id) {
    created_at
    deleted_at
    email
    enabled
    id
    logo
    logo_inverse
    lp_img
    name
    overview
    lp_job
    overview_extended
    phone
    public
    shortname
    staff_count
    staff_count_max
    website
    year_founded
    enterprise_locations {
      locality
      postcode
      state
    }
    candidate_follows_aggregate(where: {deleted_at: {_is_null: true}}) {
      aggregate {
        count
      }
    }
    candidate_follows(order_by: {created_at: desc}) {
      created_at
      deleted_at
      candidate {
        email
        id
        first_name
        last_name
        status
        photo_url
      }
    }
  }
}
    `;
export const Unique_Enterprise_Clients_By_Owner_IdDocument = gql`
    query unique_enterprise_clients_by_owner_id($ownerId: String = "") {
  enterprise_clients(
    where: {client: {owner_id: {_eq: $ownerId}}, deleted_at: {_is_null: true}}
  ) {
    enterprise_id
    client_id
    deleted_at
    created_at
    enterprise {
      logo
      logo_inverse
      email
      staff_count
      staff_count_max
      name
      overview
      overview_extended
      theme_bg_color
      website
      shortname
    }
  }
}
    `;
export const Upsert_Unique_EnterpriseDocument = gql`
    mutation upsert_unique_enterprise($enterprise: enterprises_insert_input!, $columns: [enterprises_update_column!]) {
  insert_enterprises_one(
    object: $enterprise
    on_conflict: {constraint: enterprises_pkey, update_columns: $columns}
  ) {
    created_at
    id
  }
}
    `;
export const Upsert_Enterprise_ClientDocument = gql`
    mutation upsert_enterprise_client($clientId: uuid = "", $enterpriseId: uuid = "") {
  insert_enterprise_clients(
    on_conflict: {constraint: enterprise_clients_pkey, update_columns: deleted_at}
    objects: {client_id: $clientId, enterprise_id: $enterpriseId}
  ) {
    affected_rows
  }
  remove_unused: update_enterprise_clients(
    _set: {deleted_at: "now()"}
    where: {client_id: {_eq: $clientId}, enterprise_id: {_neq: $enterpriseId}}
  ) {
    affected_rows
  }
}
    `;
export const Remove_Enterprise_Client_By_Client_IdDocument = gql`
    mutation remove_enterprise_client_by_client_id($clientId: uuid = "") {
  update_enterprise_clients_many(
    updates: {where: {client_id: {_eq: $clientId}}, _set: {deleted_at: "now()"}}
  ) {
    affected_rows
  }
}
    `;
export const Upsert_Exclusive_CandidateDocument = gql`
    mutation upsert_exclusive_candidate($candidateId: uuid = "", $enterpriseId: uuid = "", $published: Boolean = false) {
  insert_exclusive_candidates(
    objects: {candidate_id: $candidateId, enterprise_id: $enterpriseId, published: $published}
    on_conflict: {constraint: exclusive_candidates_candidate_id_enterprise_id_key, update_columns: [published, deleted_at]}
  ) {
    affected_rows
  }
}
    `;
export const All_Exclusive_By_Candidate_IdDocument = gql`
    query all_exclusive_by_candidate_id($candidateId: uuid = "") {
  exclusive_candidates(where: {candidate_id: {_eq: $candidateId}}) {
    enterprise_id
    published
  }
}
    `;
export const All_Exclusive_By_Enterprise_IdDocument = gql`
    query all_exclusive_by_enterprise_id($enterpriseId: uuid = "") {
  exclusive_candidates(
    where: {enterprise_id: {_eq: $enterpriseId}, published: {_eq: true}}
  ) {
    enterprise_id
    enterprise {
      name
    }
    candidate_id
    candidate {
      id
      owner_id
      published_on
      published
      first_name
      last_name
      photo_url
      cv_url
      test
      graduates_on
      status
      open_to_part_time_prior_to_graduation
      available_part_time_days_from
      available_part_time_days_to
      work_arrangement
      candidate_industries(where: {deleted_at: {_is_null: true}}) {
        industry {
          id
        }
      }
      candidate_degrees(where: {deleted_at: {_is_null: true}}) {
        degree {
          id
        }
      }
      candidate_majors(where: {deleted_at: {_is_null: true}}) {
        major {
          id
        }
      }
      candidate_skills(where: {deleted_at: {_is_null: true}}) {
        skill {
          id
        }
      }
      candidate_locations(where: {deleted_at: {_is_null: true}}) {
        location {
          coordinate
          postcode
          locality
          state
        }
      }
    }
    published
  }
}
    `;
export const Fb_Server_EventDocument = gql`
    mutation fb_server_event($event: String!, $emails: [String], $phones: [String], $sourceUrl: String!, $ownerId: String!, $tenantId: String!, $candidateId: String!) {
  fb_server_event(
    payload: {event: $event, emails: $emails, phones: $phones, sourceUrl: $sourceUrl, ownerId: $ownerId, tenantId: $tenantId, candidateId: $candidateId}
  )
}
    `;
export const Feature_Popups_By_RoleDocument = gql`
    query feature_popups_by_role($role: user_role_enums_comparison_exp) {
  feature_popups(where: {show_to_role: $role}) {
    goes_after
    id
    location
    location_css_selector
    photo_url
    primary_button_text
    primary_button_url
    secondary_button_text
    show_until
    title
    body
    events {
      id
      created_at
      event
      owner_id
    }
  }
}
    `;
export const Follows_By_Candidate_IdDocument = gql`
    query follows_by_candidate_id($candidate_id: uuid = "") {
  candidate_follows(
    where: {candidate_id: {_eq: $candidate_id}, deleted_at: {_is_null: true}}
  ) {
    enterprise_id
  }
}
    `;
export const Follows_By_Owner_IdDocument = gql`
    query follows_by_owner_id($owner_id: String = "") {
  candidate_follows(
    where: {deleted_at: {_is_null: true}, candidate: {owner_id: {_eq: $owner_id}}}
  ) {
    enterprise_id
  }
}
    `;
export const Upsert_Unique_FollowDocument = gql`
    mutation upsert_unique_follow($enterprise_id: uuid = "", $candidate_id: uuid = "") {
  insert_candidate_follows(
    objects: {enterprise_id: $enterprise_id, candidate_id: $candidate_id}
    on_conflict: {constraint: candidate_follows_pkey, update_columns: deleted_at}
  ) {
    affected_rows
  }
}
    `;
export const Upsert_Unique_UnfollowDocument = gql`
    mutation upsert_unique_unfollow($candidate_id: uuid = "", $enterprise_id: uuid = "") {
  update_candidate_follows(
    where: {candidate_id: {_eq: $candidate_id}, enterprise_id: {_eq: $enterprise_id}}
    _set: {deleted_at: now}
  ) {
    affected_rows
  }
}
    `;
export const All_Candidate_FollowsDocument = gql`
    query all_candidate_follows {
  candidate_follows(order_by: {created_at: desc}) {
    created_at
    deleted_at
    enterprise {
      logo
      id
      name
    }
    candidate {
      last_name
      id
      first_name
      status
      photo_url
    }
  }
}
    `;
export const All_Follows_By_Enterprise_IdDocument = gql`
    query all_follows_by_enterprise_id($enterpriseId: uuid = "") {
  candidate_follows(
    where: {deleted_at: {_is_null: true}, enterprise_id: {_eq: $enterpriseId}}
    order_by: {created_at: desc}
  ) {
    created_at
    deleted_at
    updated_at
    enterprise {
      logo
      id
      name
      shortname
    }
    candidate {
      last_name
      id
      first_name
      status
      photo_url
      published
      candidate_courses {
        result
        completed
        course_id
        course {
          deleted_at
          name
          img
          overview
        }
      }
      interview_participants_aggregate(
        where: {interview: {scheduled_on: {_gte: "now()"}, type: {_eq: "Client"}}}
      ) {
        aggregate {
          count
        }
      }
    }
  }
}
    `;
export const All_Sign_Ups_By_Ec_ShortnameDocument = gql`
    query all_sign_ups_by_ec_shortname($source: String = "") {
  candidates(where: {source: {_eq: $source}}) {
    id
    created_at
  }
}
    `;
export const Unique_Candidate_Default_ValuesDocument = gql`
    query unique_candidate_default_values($candidateId: uuid!) {
  unique_candidate_default_values(candidateId: $candidateId) {
    id
    created_at
    updated_at
    first_name
    bio
    status
    published
    relocate
    graduates_on
    graduates_on_month
    graduates_on_year
    part_time_availability {
      available_part_time_days_from
      available_part_time_days_to
    }
    open_to_part_time_prior_to_graduation
    work_arrangement
    has_work_experience
    photo_url
    university_id
    residency_status
    candidate_skills
    candidate_degrees {
      id
      title
    }
    candidate_majors {
      id
      title
    }
    candidate_industries
    candidate_locations {
      location {
        locality
        state
        postcode
      }
    }
  }
}
    `;
export const Form_OptionsDocument = gql`
    query form_options @cached {
  form_options {
    skills {
      id
      title
    }
    degrees {
      id
      title
    }
    majors {
      id
      title
    }
    industries {
      id
      title
    }
    work_arrangement {
      id
      title
    }
    contract_type {
      id
      title
    }
    candidate_status {
      id
      title
    }
    job_status {
      id
      title
    }
    universities {
      id
      title
    }
  }
}
    `;
export const Upsert_Unique_Candidate_IndustriesDocument = gql`
    mutation upsert_unique_candidate_industries($objects: [candidate_industries_insert_input!]!, $candidateId: uuid!, $industryIds: [uuid!]) {
  insert_candidate_industries(
    objects: $objects
    on_conflict: {constraint: candidate_industries_candidate_id_industry_id_key, update_columns: [updated_at]}
  ) {
    affected_rows
  }
  remove_unused: update_candidate_industries(
    _set: {deleted_at: "now()"}
    where: {candidate_id: {_eq: $candidateId}, industry_id: {_nin: $industryIds}}
  ) {
    affected_rows
  }
  reinstate_used: update_candidate_industries(
    _set: {deleted_at: null}
    where: {candidate_id: {_eq: $candidateId}, industry_id: {_in: $industryIds}}
  ) {
    affected_rows
  }
}
    `;
export const Upsert_Unique_Client_IndustriesDocument = gql`
    mutation upsert_unique_client_industries($objects: [client_industries_insert_input!]!, $clientId: uuid!, $industryIds: [uuid!]) {
  insert_client_industries(
    objects: $objects
    on_conflict: {constraint: client_industries_client_id_industry_id_key, update_columns: [updated_at]}
  ) {
    affected_rows
  }
  remove_unused: update_client_industries(
    _set: {deleted_at: "now()"}
    where: {client_id: {_eq: $clientId}, industry_id: {_nin: $industryIds}}
  ) {
    affected_rows
  }
  reinstate_used: update_client_industries(
    _set: {deleted_at: null}
    where: {client_id: {_eq: $clientId}, industry_id: {_in: $industryIds}}
  ) {
    affected_rows
  }
}
    `;
export const Unique_InterviewDocument = gql`
    query unique_interview($interviewId: uuid!) {
  interviews_by_pk(id: $interviewId) {
    mode
    id
    job_id
    nylas_event_id
    location
    outcome
    owner_id
    phone
    status
    meeting_link
    type
    updated_at
    metadata
    created_at
    deleted_at
    scheduled_on
    time_zone
    interview_participants {
      id
      created_at
      updated_at
      deleted_at
      interview_id
      accepted
      candidate_id
      reason_to_decline
      candidate {
        id
        first_name
        last_name
        photo_url
        email
        phone
      }
      client {
        id
        business_name
        tenant {
          logo
          name
          id
        }
        contacts {
          email
          first_name
          last_name
          phone
        }
      }
    }
    job {
      title
      description
      id
    }
  }
}
    `;
export const Upsert_Unique_InterviewDocument = gql`
    mutation upsert_unique_interview($interview: interviews_insert_input!) {
  insert_interviews_one(
    object: $interview
    on_conflict: {constraint: interviews_pkey}
  ) {
    id
  }
}
    `;
export const Upsert_Unique_Interview_ParticipantsDocument = gql`
    mutation upsert_unique_interview_participants($objects: [interview_participants_insert_input!] = {}) {
  insert_interview_participants(objects: $objects) {
    affected_rows
  }
}
    `;
export const All_InterviewsDocument = gql`
    query all_interviews {
  interviews(order_by: {created_at: desc}) {
    mode
    id
    job_id
    location
    nylas_event_id
    outcome
    owner_id
    phone
    status
    meeting_link
    type
    updated_at
    metadata
    created_at
    deleted_at
    scheduled_on
    interview_participants {
      id
      created_at
      updated_at
      deleted_at
      interview_id
      accepted
      candidate_id
      candidate {
        id
        first_name
        last_name
        photo_url
        email
        phone
      }
      client {
        id
        business_name
        tenant {
          logo
          name
          id
        }
        contacts {
          email
          first_name
          last_name
          phone
        }
      }
    }
    job {
      title
      description
    }
  }
}
    `;
export const Update_InterviewDocument = gql`
    mutation update_interview($id: uuid!, $_set: interviews_set_input = {}) {
  update_interviews(where: {id: {_eq: $id}}, _set: $_set) {
    affected_rows
  }
}
    `;
export const Update_Interview_ParticipantsDocument = gql`
    mutation update_interview_participants($id: uuid!, $_set: interview_participants_set_input = {}) {
  update_interview_participants(where: {id: {_eq: $id}}, _set: $_set) {
    affected_rows
  }
}
    `;
export const All_Accepted_Scheduled_InterviewsDocument = gql`
    query all_accepted_scheduled_interviews {
  interviews(where: {interview_participants: {accepted: {_eq: true}}}) {
    mode
    id
    job_id
    location
    nylas_event_id
    outcome
    owner_id
    phone
    status
    meeting_link
    type
    updated_at
    metadata
    created_at
    deleted_at
    scheduled_on
    job {
      title
    }
    interview_participants {
      id
      created_at
      updated_at
      deleted_at
      interview_id
      accepted
      candidate_id
      candidate {
        id
        first_name
        last_name
        photo_url
        email
        phone
      }
      client {
        id
        business_name
        tenant {
          logo
          name
          id
        }
        contacts {
          email
          first_name
          last_name
          phone
        }
      }
    }
  }
}
    `;
export const All_Pending_Unaccepted_InterviewsDocument = gql`
    query all_pending_unaccepted_interviews {
  interviews(where: {interview_participants: {accepted: {_eq: false}}}) {
    mode
    id
    job_id
    location
    nylas_event_id
    outcome
    owner_id
    phone
    status
    meeting_link
    type
    updated_at
    metadata
    created_at
    deleted_at
    scheduled_on
    job {
      title
    }
    interview_participants {
      id
      created_at
      updated_at
      deleted_at
      interview_id
      accepted
      candidate_id
      candidate {
        id
        first_name
        last_name
        photo_url
        email
        phone
      }
      client {
        id
        business_name
        tenant {
          logo
          name
          id
        }
        contacts {
          email
          first_name
          last_name
          phone
        }
      }
    }
  }
}
    `;
export const Unique_Candidate_InterviewsDocument = gql`
    query unique_candidate_interviews($id: uuid = "", $accepted: Boolean) {
  interviews(
    where: {interview_participants: {candidate: {id: {_eq: $id}}, accepted: {_eq: $accepted}, declined: {_eq: false}}, status: {_neq: "Cancelled"}}
  ) {
    job {
      title
      description
    }
    id
    mode
    phone
    location
    scheduled_on
    type
    edit_hash
    created_at
    interview_participants {
      id
      accepted
      declined
      client {
        id
        business_name
        tenant {
          logo
          name
          id
        }
        contacts {
          email
          first_name
          last_name
          phone
        }
      }
    }
  }
}
    `;
export const Interview_SearchDocument = gql`
    query interview_search($interview: interviews_bool_exp = {}, $order_by: [interviews_order_by!] = {scheduled_on: desc}) {
  interviews(where: $interview, order_by: $order_by) {
    mode
    id
    job_id
    location
    nylas_event_id
    outcome
    owner_id
    phone
    status
    meeting_link
    type
    updated_at
    metadata
    created_at
    deleted_at
    scheduled_on
    interview_participants {
      id
      created_at
      updated_at
      deleted_at
      interview_id
      accepted
      candidate_id
      candidate {
        id
        first_name
        last_name
        photo_url
        email
        phone
      }
      client {
        id
        business_name
        tenant {
          logo
          name
          id
        }
        contacts {
          email
          first_name
          last_name
          phone
        }
      }
    }
    job {
      title
      description
    }
  }
}
    `;
export const Interview_Search_OptionsDocument = gql`
    query interview_search_options {
  interviews {
    interview_participants {
      client {
        business_name
        contacts {
          first_name
          last_name
        }
      }
    }
  }
}
    `;
export const Delete_Test_InterviewDocument = gql`
    mutation delete_test_interview($interviewId: uuid!) {
  delete_interviews_by_pk(id: $interviewId) {
    id
    owner_id
    meeting_link
    outcome
  }
}
    `;
export const Delete_Test_Interview_ParticipantsDocument = gql`
    mutation delete_test_interview_participants($interviewId: uuid!) {
  delete_interview_participants(where: {interview_id: {_eq: $interviewId}}) {
    returning {
      id
      client_id
      interview_id
      candidate_id
    }
  }
}
    `;
export const Unique_Tenant_JobsDocument = gql`
    query unique_tenant_jobs($shortname: String!) {
  tenants(
    where: {enabled: {_eq: true}, shortname: {_eq: $shortname}}
    order_by: {created_at: desc}
  ) {
    name
    shortname
    clients {
      id
      business_name
      client_jobs(where: {deleted_at: {_is_null: true}}) {
        id
        job {
          client_jobs {
            client {
              business_name
            }
          }
          created_at
          id
          title
        }
      }
    }
  }
}
    `;
export const All_Tenant_JobsDocument = gql`
    query all_tenant_jobs {
  tenants(where: {enabled: {_eq: true}, public: {_eq: true}}) {
    id
    name
    shortname
    year_founded
    staff_count
    staff_count_max
    website
    logo
    overview
    overview_extended
    email
    enabled
    theme_bg_color
    tenant_locations {
      location {
        coordinate
        postcode
        state
        locality
      }
    }
    clients(where: {deleted_at: {_is_null: true}}) {
      id
      business_name
      client_jobs(where: {job: {deleted_at: {_is_null: true}, public: {_eq: true}}}) {
        id
        job {
          client_jobs {
            client {
              business_name
            }
          }
          created_at
          id
          title
        }
      }
    }
  }
}
    `;
export const Search_JobsDocument = gql`
    query search_jobs($status: job_status_enums_comparison_exp, $work_arrangement: work_arrangement_enums_comparison_exp, $contract_type: jobs_contract_type_enums_comparison_exp) {
  filtered: jobs(
    where: {status: $status, work_arrangement: $work_arrangement, contract_type: $contract_type}
    order_by: {id: asc}
  ) {
    id
    title
    contract_type
    work_arrangement
    status
    created_at
    updated_at
    client_jobs {
      client {
        id
        business_name
      }
    }
  }
  default: jobs(where: {deleted_at: null}, order_by: {id: asc}) {
    id
    title
    contract_type
    work_arrangement
    status
    created_at
    updated_at
    client_jobs {
      client {
        id
        business_name
      }
    }
  }
}
    `;
export const Upsert_Unique_JobDocument = gql`
    mutation upsert_unique_job($job: jobs_insert_input!, $columns: [jobs_update_column!]) {
  insert_jobs_one(
    object: $job
    on_conflict: {constraint: jobs_pkey, update_columns: $columns}
  ) {
    id
  }
}
    `;
export const All_JobsDocument = gql`
    query all_jobs($object: jobs_bool_exp = {deleted_at: {_is_null: true}}) {
  jobs(where: $object, order_by: {created_at: desc}) {
    id
    title
    description
    status
    created_at
    deleted_at
    contract_type
    job_candidates {
      candidate {
        id
        owner_id
        first_name
        last_name
      }
    }
    interviews {
      id
    }
    client_jobs {
      client {
        id
        business_name
        contacts {
          id
          first_name
          last_name
          email
          phone
        }
        tenant {
          name
          shortname
        }
        client_locations(where: {deleted_at: {_is_null: true}}) {
          location {
            coordinate
            locality
            state
            postcode
          }
        }
      }
    }
  }
}
    `;
export const Recent_JobsDocument = gql`
    query recent_jobs {
  jobs(
    where: {deleted_at: {_is_null: true}}
    order_by: {created_at: desc}
    limit: 10
  ) {
    id
    title
    status
    created_at
    contract_type
  }
}
    `;
export const Unique_JobDocument = gql`
    query unique_job($jobId: uuid!) {
  jobs_by_pk(id: $jobId) {
    id
    created_at
    updated_at
    deleted_at
    title
    owner_id
    description
    work_arrangement
    graduation_timeframe
    open_to_part_time_prior_to_graduation
    available_part_time_days_from
    available_part_time_days_to
    role_commences_on
    contract_type
    status
    interviews {
      id
    }
    job_locations {
      location {
        locality
        postcode
        state
        coordinate
      }
    }
    job_candidates {
      candidate {
        id
        owner_id
        first_name
        last_name
      }
    }
    shortlists(where: {deleted_at: {_is_null: true}}) {
      id
      created_at
      updated_at
      deleted_at
      candidate {
        id
        first_name
        last_name
      }
    }
    client_jobs {
      client {
        id
        business_name
        client_locations(where: {deleted_at: {_is_null: true}}) {
          location {
            coordinate
            locality
            state
            postcode
          }
        }
        contacts {
          id
          first_name
          last_name
          email
          phone
          domain
        }
        client_industries(where: {deleted_at: {_is_null: true}}) {
          industry {
            id
            title
          }
        }
      }
    }
  }
}
    `;
export const Unique_Job_PublicDocument = gql`
    query unique_job_public($jobId: uuid!) {
  jobs_by_pk(id: $jobId) {
    id
    created_at
    updated_at
    deleted_at
    title
    description
    work_arrangement
    graduation_timeframe
    open_to_part_time_prior_to_graduation
    available_part_time_days_from
    available_part_time_days_to
    role_commences_on
    contract_type
    status
    job_locations(where: {deleted_at: {_is_null: true}}) {
      location {
        coordinate
        locality
        state
        postcode
      }
    }
  }
}
    `;
export const Job_ShortlistDocument = gql`
    query job_shortlist($jobId: uuid) {
  shortlists(where: {job: {id: {_eq: $jobId}}}) {
    id
    created_at
    deleted_at
    client {
      id
      business_name
    }
    job {
      id
      title
    }
    candidate {
      id
      first_name
      last_name
    }
  }
}
    `;
export const Upsert_Job_From_Proposed_InterviewDocument = gql`
    mutation upsert_job_from_proposed_interview($objects: [jobs_insert_input!] = {}) {
  insert_jobs(objects: $objects) {
    affected_rows
    returning {
      id
      graduation_timeframe
      description
      deleted_at
      created_at
      available_part_time_days_to
      available_part_time_days_from
      contract_type
      owner_id
      role_commences_on
      open_to_part_time_prior_to_graduation
      source
      status
      title
      updated_at
      work_arrangement
    }
  }
}
    `;
export const Upsert_Client_JobDocument = gql`
    mutation upsert_client_job($objects: [client_jobs_insert_input!] = {}) {
  insert_client_jobs(objects: $objects) {
    affected_rows
    returning {
      job_id
      client_id
      created_at
      deleted_at
      updated_at
    }
  }
}
    `;
export const Unique_Client_Jobs_By_ClientDocument = gql`
    query unique_client_jobs_by_client($client_id: uuid) {
  client_jobs(where: {client_id: {_eq: $client_id}}) {
    client_id
    created_at
    deleted_at
    id
    job_id
    updated_at
    job {
      available_part_time_days_from
      available_part_time_days_to
      contract_type
      created_at
      deleted_at
      description
      graduation_timeframe
      id
      open_to_part_time_prior_to_graduation
      owner_id
      role_commences_on
      source
      status
      title
      updated_at
      work_arrangement
      interviews {
        mode
        location
        phone
        nylas_event_id
        meeting_link
      }
    }
  }
}
    `;
export const Unique_Job_Candidates_By_PkDocument = gql`
    query unique_job_candidates_by_pk($id: uuid!) {
  job_candidates_by_pk(id: $id) {
    id
    candidate_id
    job_id
    created_at
    updated_at
    deleted_at
    job {
      id
      title
      client_jobs {
        client {
          id
          business_name
          contacts {
            id
            first_name
            last_name
            email
            phone
          }
        }
      }
    }
    candidate {
      id
      first_name
      last_name
      email
      phone
      owner_id
      created_at
      updated_at
      deleted_at
      candidate_locations {
        location {
          coordinate
          locality
          state
          postcode
        }
      }
      candidate_industries {
        industry {
          id
          title
        }
      }
    }
  }
}
    `;
export const Job_Candidates_AggregateDocument = gql`
    query job_candidates_aggregate($job_id: uuid!) {
  job_candidates_aggregate(where: {job_id: {_eq: $job_id}}) {
    aggregate {
      count
    }
  }
}
    `;
export const Delete_Test_JobsDocument = gql`
    mutation delete_test_jobs($owner_id: String!) {
  delete_jobs(where: {owner_id: {_eq: $owner_id}}) {
    returning {
      id
      owner_id
      title
    }
  }
}
    `;
export const Update_JobDocument = gql`
    mutation update_job($jobId: uuid!, $job: jobs_set_input) {
  update_jobs_by_pk(pk_columns: {id: $jobId}, _set: $job) {
    id
    title
  }
}
    `;
export const Insert_Job_Candidates_OneDocument = gql`
    mutation insert_job_candidates_one($object: job_candidates_insert_input!) {
  insert_job_candidates_one(object: $object) {
    id
  }
}
    `;
export const Attach_Location_To_ClientDocument = gql`
    mutation attach_location_to_client($clientId: uuid!, $object: [client_locations_insert_input!]!) {
  remove_unused: update_client_locations(
    _set: {deleted_at: "now()"}
    where: {client_id: {_eq: $clientId}}
  ) {
    affected_rows
  }
  insert_client_locations(
    objects: $object
    on_conflict: {constraint: client_locations_pkey, update_columns: [updated_at]}
  ) {
    affected_rows
  }
}
    `;
export const Attach_Location_To_CandidateDocument = gql`
    mutation attach_location_to_candidate($candidateId: uuid!, $object: [candidate_locations_insert_input!]!) {
  remove_unused: update_candidate_locations(
    _set: {deleted_at: "now()"}
    where: {candidate_id: {_eq: $candidateId}}
  ) {
    affected_rows
  }
  insert_candidate_locations(
    objects: $object
    on_conflict: {constraint: candidate_locations_pkey, update_columns: [updated_at]}
  ) {
    affected_rows
  }
}
    `;
export const Attach_Location_To_JobDocument = gql`
    mutation attach_location_to_job($jobId: uuid!, $object: [job_locations_insert_input!]!) {
  remove_unused: update_job_locations(
    _set: {deleted_at: "now()"}
    where: {job_id: {_eq: $jobId}}
  ) {
    affected_rows
  }
  insert_job_locations(
    objects: $object
    on_conflict: {constraint: job_locations_pkey, update_columns: [updated_at]}
  ) {
    affected_rows
  }
}
    `;
export const Upsert_Unique_Candidate_MajorsDocument = gql`
    mutation upsert_unique_candidate_majors($objects: [candidate_majors_insert_input!]!, $candidateId: uuid!, $majorIds: [uuid!]) {
  insert_candidate_majors(
    objects: $objects
    on_conflict: {constraint: candidate_majors_candidate_id_major_id_key, update_columns: [updated_at]}
  ) {
    affected_rows
  }
  remove_unused: update_candidate_majors(
    _set: {deleted_at: "now()"}
    where: {candidate_id: {_eq: $candidateId}, major_id: {_nin: $majorIds}}
  ) {
    affected_rows
  }
  reinstate_used: update_candidate_majors(
    _set: {deleted_at: null}
    where: {candidate_id: {_eq: $candidateId}, major_id: {_in: $majorIds}}
  ) {
    affected_rows
  }
}
    `;
export const All_CoursesDocument = gql`
    query all_courses {
  courses(order_by: {priority: asc}, where: {deleted_at: {_is_null: true}}) {
    id
    name
    candidate_courses {
      candidate {
        id
        first_name
        last_name
      }
    }
  }
}
    `;
export const Unique_CourseDocument = gql`
    query unique_course($id: uuid!) {
  courses_by_pk(id: $id) {
    id
    content_url
    created_at
    deleted_at
    details
    instruction
    img
    name
    overview
    priority
    provider
    quiz_url
    updated_at
    live
    form_id
    candidate_courses {
      created_at
      deleted_at
      result
      completed
      course {
        id
      }
      candidate {
        id
        first_name
        last_name
      }
    }
  }
}
    `;
export const Upsert_Unique_Candidate_CoursesDocument = gql`
    mutation upsert_unique_candidate_courses($objects: [candidate_courses_insert_input!]!) {
  insert_candidate_courses(
    objects: $objects
    on_conflict: {constraint: candidate_courses_pkey, update_columns: [updated_at]}
  ) {
    affected_rows
  }
}
    `;
export const Unique_Course_Form_IdDocument = gql`
    query unique_course_form_id($form_id: String) {
  courses(where: {form_id: {_eq: $form_id}}) {
    id
  }
}
    `;
export const Update_Unique_Candidate_Courses_As_PassedDocument = gql`
    mutation update_unique_candidate_courses_as_passed($candidate_id: uuid, $course_id: uuid) {
  update_candidate_courses(
    where: {candidate_id: {_eq: $candidate_id}, course_id: {_eq: $course_id}}
    _set: {result: "Passed", completed: true}
  ) {
    affected_rows
  }
}
    `;
export const All_Completed_Courses_By_Candidate_IdDocument = gql`
    query all_completed_courses_by_candidate_id($candidateId: uuid = "") {
  candidate_courses(
    where: {completed: {_eq: true}, course: {deleted_at: {_is_null: true}}, candidate_id: {_eq: $candidateId}}
  ) {
    course {
      img
      name
      overview
    }
  }
}
    `;
export const Nylas_Post_Calendar_AvailabilityDocument = gql`
    mutation nylas_post_calendar_availability($duration_minutes: Int, $start_time: Int, $end_time: Int, $interval_minutes: Int, $emails: [String], $timezone: String, $access_token: String) {
  nylas_post_calendar_availability(
    payload: {duration_minutes: $duration_minutes, start_time: $start_time, end_time: $end_time, interval_minutes: $interval_minutes, emails: $emails, timezone: $timezone, access_token: $access_token}
  ) {
    time_slots {
      status
      start_time
      end_time
    }
  }
}
    `;
export const Nylas_Post_EventDocument = gql`
    mutation nylas_post_event($interview_id: uuid, $calendar_id: String, $access_token: String, $title: String, $description: String, $participants: json!, $location: String, $start_time: Int, $end_time: Int, $time_zone: String) {
  nylas_post_event(
    payload: {interview_id: $interview_id, calendar_id: $calendar_id, access_token: $access_token, title: $title, description: $description, participants: $participants, location: $location, start_time: $start_time, end_time: $end_time, time_zone: $time_zone}
  ) {
    id
  }
}
    `;
export const Nylas_Get_Event_By_IdDocument = gql`
    query nylas_get_event_by_id($event_id: String) {
  nylas_get_event_by_id(payload: {event_id: $event_id}) {
    id
    status
  }
}
    `;
export const Create_PlacementDocument = gql`
    mutation create_placement($candidate_id: uuid, $client_id: uuid, $job_id: uuid, $commences_on: timestamptz) {
  insert_candidate_placements_one(
    object: {candidate_id: $candidate_id, client_id: $client_id, job_id: $job_id, commences_on: $commences_on}
  ) {
    id
  }
}
    `;
export const Placements_By_Candidate_IdDocument = gql`
    query placements_by_candidate_id($candidate_id: uuid = "") {
  candidate_placements(
    where: {candidate_id: {_eq: $candidate_id}}
    order_by: {created_at: desc}
  ) {
    commences_on
    desisted_on
    created_at
    deleted_at
    id
    updated_at
    candidate {
      first_name
      last_name
    }
    client {
      id
      business_name
      contacts {
        email
        first_name
        last_name
      }
    }
  }
}
    `;
export const Unique_PlacementDocument = gql`
    query unique_placement($placement_id: uuid = "") {
  candidate_placements_by_pk(id: $placement_id) {
    commences_on
    created_at
    deleted_at
    id
    updated_at
    candidate {
      first_name
      last_name
    }
    client {
      id
      business_name
      contacts {
        email
        first_name
        last_name
      }
    }
  }
}
    `;
export const All_PlacementsDocument = gql`
    query all_placements {
  candidate_placements {
    commences_on
    desisted_on
    candidate {
      id
      first_name
      last_name
      photo_url
    }
    client {
      id
      business_name
    }
  }
}
    `;
export const Search_PlacementsDocument = gql`
    query search_placements($object: candidate_placements_bool_exp = {}, $order_by: [candidate_placements_order_by!] = {commences_on: desc}, $limit: Int = 20, $offset: Int = 0) {
  candidate_placements(
    where: $object
    order_by: $order_by
    limit: $limit
    offset: $offset
  ) {
    commences_on
    desisted_on
    candidate {
      id
      first_name
      last_name
      photo_url
    }
    client {
      id
      business_name
    }
  }
  candidate_placements_aggregate(where: $object) {
    aggregate {
      count
    }
  }
}
    `;
export const Search_All_PlacementsDocument = gql`
    query search_all_placements($object: candidate_placements_bool_exp = {}, $order_by: [candidate_placements_order_by!] = {commences_on: desc}) {
  candidate_placements(where: $object, order_by: $order_by) {
    commences_on
    candidate {
      id
      first_name
      last_name
      photo_url
      candidate_locations {
        location {
          postcode
          state
          locality
        }
      }
    }
    client {
      id
      business_name
    }
  }
}
    `;
export const Update_Placement_By_PkDocument = gql`
    mutation update_placement_by_pk($id: uuid!, $_set: candidate_placements_set_input!) {
  update_candidate_placements_by_pk(pk_columns: {id: $id}, _set: $_set) {
    id
  }
}
    `;
export const All_IndustriesDocument = gql`
    query all_industries {
  industries(where: {deleted_at: {_is_null: true}}, order_by: {title: asc}) {
    id
    id
    title
    description
  }
}
    `;
export const All_SkillsDocument = gql`
    query all_skills {
  skills(where: {deleted_at: {_is_null: true}}, order_by: {title: asc}) {
    id
    id
    title
    description
  }
}
    `;
export const All_DegreesDocument = gql`
    query all_degrees {
  degrees(where: {deleted_at: {_is_null: true}}, order_by: {title: asc}) {
    id
    title
    description
  }
}
    `;
export const All_MajorsDocument = gql`
    query all_majors {
  majors(where: {deleted_at: {_is_null: true}}, order_by: {title: asc}) {
    id
    title
    description
  }
}
    `;
export const All_UniversitiesDocument = gql`
    query all_universities {
  universities(where: {deleted_at: {_is_null: true}}, order_by: {name: asc}) {
    id
    name
    short_name
    country
    state
  }
}
    `;
export const Get_Job_By_IdDocument = gql`
    query get_job_by_id($id: uuid) {
  jobs(where: {id: {_eq: $id}}) {
    id
    title
    owner_id
    client_jobs {
      client {
        id
        business_name
        tenant {
          name
        }
      }
    }
  }
}
    `;
export const Admin_ReportDocument = gql`
    query admin_report {
  users(order_by: {updated_at: desc}, where: {role: {_eq: "Tenant"}}) {
    email
    id
    auth0_uid
    created_at
    updated_at
    tenants {
      created_at
      id
      name
      email
      phone
      clients(where: {deleted_at: {_is_null: true}}) {
        id
        created_at
        updated_at
        business_name
        created_at
        client_locations {
          location {
            state
            locality
            postcode
          }
        }
        client_jobs(order_by: {created_at: desc}, where: {deleted_at: {_is_null: true}}) {
          job {
            id
            title
            created_at
            client_jobs {
              client {
                business_name
                client_locations {
                  location {
                    state
                    locality
                    postcode
                  }
                }
              }
            }
            shortlists {
              candidate {
                id
                photo_url
                deleted_at
                first_name
                last_name
              }
            }
          }
        }
      }
    }
  }
}
    `;
export const All_The_Things_UserDocument = gql`
    query all_the_things_user($auth0_uid: String) {
  users(where: {deleted_at: {_is_null: true}, auth0_uid: {_eq: $auth0_uid}}) {
    auth0_uid
    first_name
    last_name
    created_at
    updated_at
    email
    role
    tenants(
      order_by: {created_at: desc}
      where: {deleted_at: {_is_null: true}, enabled: {_eq: true}}
    ) {
      id
      created_at
      name
      email
      phone
      enabled
      clients(where: {deleted_at: {_is_null: true}}) {
        id
        business_name
        created_at
        terms_accepted_on
        client_locations {
          location {
            state
            locality
            postcode
          }
        }
        client_jobs(where: {deleted_at: {_is_null: true}}) {
          job {
            id
            title
            created_at
            client_jobs {
              client {
                business_name
                terms_accepted_on
                client_locations {
                  location {
                    state
                    locality
                    postcode
                  }
                }
              }
            }
            shortlists {
              candidate {
                first_name
                last_name
              }
            }
          }
        }
      }
    }
  }
}
    `;
export const Create_New_JobDocument = gql`
    mutation create_new_job($title: String, $owner_id: String, $contract_type: jobs_contract_type_enums, $client_id: uuid, $source: String) {
  insert_jobs(
    objects: [{title: $title, owner_id: $owner_id, contract_type: $contract_type, client_jobs: {data: {client_id: $client_id}}, source: $source}]
  ) {
    returning {
      id
      title
      owner_id
      client_jobs {
        job {
          id
          title
          source
        }
        client {
          id
          business_name
          tenant {
            name
          }
        }
      }
    }
  }
}
    `;
export const Add_Client_LocationDocument = gql`
    mutation add_client_location($client_id: uuid!, $postcode: String, $locality: String, $state: String) {
  insert_client_locations_one(
    object: {client_id: $client_id, postcode: $postcode, locality: $locality, state: $state}
  ) {
    id
  }
}
    `;
export const Insert_Multiple_TenantsDocument = gql`
    mutation insert_multiple_tenants($objects: [tenants_insert_input!]!) {
  insert_tenants(objects: $objects) {
    returning {
      id
      name
    }
  }
}
    `;
export const Insert_Multiple_ClientsDocument = gql`
    mutation insert_multiple_clients($objects: [clients_insert_input!]!) {
  insert_clients(objects: $objects) {
    returning {
      id
      business_name
    }
  }
}
    `;
export const All_Report_TargetsDocument = gql`
    query all_report_targets {
  report_targets {
    name
    target
  }
}
    `;
export const Update_TargetDocument = gql`
    mutation update_target($target: Int = 10, $name: String = "") {
  update_report_targets(_set: {target: $target}, where: {name: {_eq: $name}}) {
    affected_rows
  }
}
    `;
export const All_Active_UsersDocument = gql`
    query all_active_users($updated_at: timestamptz_comparison_exp = {}) {
  users(where: {updated_at: $updated_at}) {
    role
    email
    updated_at
    clients {
      id
      contacts {
        first_name
        last_name
        email
      }
    }
  }
}
    `;
export const All_Events_Reporting_By_Created_AtDocument = gql`
    query all_events_reporting_by_created_at($created_at: timestamptz_comparison_exp = {}, $tenant: tenants_bool_exp = {}) {
  events(where: {created_at: $created_at, tenant: $tenant}) {
    created_at
    event
    owner_id
    user {
      email
      role
      clients {
        id
        contacts {
          first_name
          last_name
        }
      }
    }
    candidate {
      photo_url
      first_name
      last_name
      id
      photo_url
    }
  }
}
    `;
export const All_Events_Reporting_By_Event_NameDocument = gql`
    query all_events_reporting_by_event_name($tenant: tenants_bool_exp = {}, $event: String = "") {
  events(where: {tenant: $tenant, event: {_eq: $event}}) {
    created_at
    event
  }
}
    `;
export const Search_Facet_ValuesDocument = gql`
    query search_facet_values @cached {
  skills {
    id
    title
  }
  degrees {
    id
    title
  }
  majors {
    id
    title
  }
  industries {
    id
    title
  }
  universities(order_by: {name: asc}) {
    id
    title: name
  }
}
    `;
export const Search_LocationsDocument = gql`
    query search_locations($locality: String) @cached {
  locations(where: {locality: {_ilike: $locality}}, limit: 5) {
    id
    postcode
    state
    locality
    coordinate
  }
}
    `;
export const Search_Clients_By_Business_NameDocument = gql`
    query search_clients_by_business_name($business_name: String) {
  clients(
    where: {business_name: {_ilike: $business_name}, deleted_at: {_is_null: true}}
  ) {
    id
    business_name
    contacts {
      first_name
      last_name
    }
    client_jobs {
      job {
        id
        title
        description
      }
    }
  }
}
    `;
export const Search_Jobs_PreviewDocument = gql`
    query search_jobs_preview($title: String) {
  jobs(where: {title: {_ilike: $title}}, limit: 5) {
    id
    title
  }
}
    `;
export const Unique_Short_UrlDocument = gql`
    query unique_short_url($shortUrlId: uuid!) {
  short_urls_by_pk(id: $shortUrlId) {
    id
    owner_id
    expires_on
    short_url
    long_url
  }
}
    `;
export const Unique_Short_Url_By_Short_IdDocument = gql`
    query unique_short_url_by_short_id($shortUrl: String!) {
  short_urls(where: {short_url: {_eq: $shortUrl}}) {
    id
    expires_on
    short_url
    long_url
  }
}
    `;
export const Upsert_Unique_Short_UrlDocument = gql`
    mutation upsert_unique_short_url($short_url: short_urls_insert_input!, $columns: [short_urls_update_column!]) {
  insert_short_urls_one(
    object: $short_url
    on_conflict: {constraint: short_urls_pkey, update_columns: $columns}
  ) {
    id
  }
}
    `;
export const Shortlists_By_Candidate_IdDocument = gql`
    query shortlists_by_candidate_id($candidateId: uuid!) {
  shortlists(
    where: {candidate_id: {_eq: $candidateId}, deleted_at: {_is_null: true}}
  ) {
    id
    created_at
    deleted_at
    updated_at
    job {
      id
      status
    }
    client {
      id
    }
    candidate {
      id
      status
    }
  }
}
    `;
export const Unique_ShortlistDocument = gql`
    query unique_shortlist($shortlistId: uuid!) {
  shortlists_by_pk(id: $shortlistId) {
    id
    created_at
    updated_at
    deleted_at
    owner_id
    candidate {
      id
      first_name
      last_name
      photo_url
    }
    job {
      id
      title
      client_jobs {
        client {
          id
          tenant {
            id
          }
        }
      }
    }
  }
}
    `;
export const Shortlist_Job_CandidatesDocument = gql`
    mutation shortlist_job_candidates($objects: [shortlists_insert_input!]!) {
  insert_shortlists(
    objects: $objects
    on_conflict: {constraint: shortlists_job_id_candidate_id_key, update_columns: [updated_at, deleted_at]}
  ) {
    returning {
      id
      created_at
      updated_at
      deleted_at
      job {
        title
        client_jobs {
          client {
            client_locations {
              location {
                postcode
                state
                locality
                coordinate
              }
            }
          }
        }
      }
      candidate {
        first_name
        last_name
        candidate_locations {
          location {
            locality
            state
            postcode
            coordinate
          }
        }
      }
    }
  }
}
    `;
export const Upsert_Unique_ShortlistDocument = gql`
    mutation upsert_unique_shortlist($shortlist: shortlists_insert_input!, $columns: [shortlists_update_column!]) {
  insert_shortlists_one(
    object: $shortlist
    on_conflict: {constraint: shortlists_pkey, update_columns: $columns}
  ) {
    id
    created_at
    updated_at
    deleted_at
  }
}
    `;
export const Upsert_Unique_Candidate_SkillsDocument = gql`
    mutation upsert_unique_candidate_skills($objects: [candidate_skills_insert_input!]!, $candidateId: uuid!, $skillIds: [uuid!]) {
  insert_candidate_skills(
    objects: $objects
    on_conflict: {constraint: candidate_skills_candidate_id_skill_id_key, update_columns: [updated_at]}
  ) {
    affected_rows
  }
  remove_unused: update_candidate_skills(
    _set: {deleted_at: "now()"}
    where: {candidate_id: {_eq: $candidateId}, skill_id: {_nin: $skillIds}}
  ) {
    affected_rows
  }
  reinstate_used: update_candidate_skills(
    _set: {deleted_at: null}
    where: {candidate_id: {_eq: $candidateId}, skill_id: {_in: $skillIds}}
  ) {
    affected_rows
  }
}
    `;
export const All_TenantsDocument = gql`
    query all_tenants @cached {
  tenants(
    where: {enabled: {_eq: true}, public: {_eq: true}}
    order_by: {created_at: asc}
  ) {
    id
    name
    shortname
    year_founded
    staff_count
    staff_count_max
    website
    logo
    logo_inverse
    overview
    overview_extended
    theme_bg_color
    email
    enabled
    created_at
    deleted_at
    public
    tenant_locations {
      location {
        locality
        state
        postcode
      }
    }
    clients {
      id
      business_name
      contacts {
        email
        first_name
        last_name
      }
    }
  }
}
    `;
export const Unique_Tenant_By_ShortnameDocument = gql`
    query unique_tenant_by_shortname($shortname: String!) {
  tenants(
    where: {shortname: {_eq: $shortname}, enabled: {_eq: true}, public: {_eq: true}}
  ) {
    id
    name
    shortname
    year_founded
    staff_count
    staff_count_max
    website
    logo
    overview
    overview_extended
    theme_bg_color
    tenant_locations {
      location {
        coordinate
        postcode
        state
        locality
      }
    }
  }
}
    `;
export const Unique_TenantDocument = gql`
    query unique_tenant($id: uuid!) {
  tenants_by_pk(id: $id) {
    id
    created_at
    deleted_at
    enabled
    public
    name
    shortname
    theme_bg_color
    logo
    tenant_locations {
      id
      locality
      postcode
      state
    }
  }
}
    `;
export const Unique_UserDocument = gql`
    query unique_user($userId: uuid!) {
  users_by_pk(id: $userId) {
    id
    auth0_uid
    email
    tenant_users {
      tenant_id
    }
  }
}
    `;
export const User_By_Auth0_UidDocument = gql`
    query user_by_auth0_uid($auth0_uid: String!) {
  users(where: {auth0_uid: {_eq: $auth0_uid}}) {
    auth0_uid
    role
    clients_aggregate(where: {deleted_at: {_is_null: true}}) {
      aggregate {
        count
      }
    }
    contacts_aggregate(where: {deleted_at: {_is_null: true}}) {
      aggregate {
        count
      }
    }
    candidates_aggregate(where: {deleted_at: {_is_null: true}}) {
      aggregate {
        count
      }
    }
    clients(where: {deleted_at: {_is_null: true}}) {
      id
      tenant {
        id
        shortname
        name
      }
    }
    contacts(where: {deleted_at: {_is_null: true}}) {
      id
      owner_id
      first_name
      last_name
      domain
      email
      phone
    }
    candidates(where: {deleted_at: {_is_null: true}}) {
      id
    }
    nylas_access_token
    nylas_account_id
    nylas_calendar_id
    tenant_users {
      tenant_id
      tenant {
        name
        shortname
      }
    }
  }
}
    `;
export const Update_User_Set_Last_SeenDocument = gql`
    mutation update_user_set_last_seen($userId: String) {
  update_users(where: {auth0_uid: {_eq: $userId}}, _set: {updated_at: "NOW()"}) {
    affected_rows
    returning {
      id
    }
  }
}
    `;
export const Update_User_Set_Role_As_ClientDocument = gql`
    mutation update_user_set_role_as_client($userId: String) {
  update_users(
    where: {auth0_uid: {_eq: $userId}, role: {_nin: ["tenant", "candidate"]}}
    _set: {role: "client"}
  ) {
    affected_rows
    returning {
      id
    }
  }
}
    `;
export const Update_User_Set_Role_As_CandidateDocument = gql`
    mutation update_user_set_role_as_candidate($userId: String) {
  update_users(
    where: {auth0_uid: {_eq: $userId}, role: {_nin: ["tenant", "client"]}}
    _set: {role: "candidate"}
  ) {
    affected_rows
    returning {
      id
    }
  }
}
    `;
export const Update_User_Nylas_CodeDocument = gql`
    mutation update_user_nylas_code($email: String, $nylas_code: String) {
  update_users(
    where: {email: {_eq: $email}}
    _set: {nylas_access_token: $nylas_code}
  ) {
    affected_rows
  }
}
    `;
export const Update_User_Calendar_IdDocument = gql`
    mutation update_user_calendar_id($access_token: String, $calendar_id: String, $account_id: String) {
  update_users(
    where: {nylas_access_token: {_eq: $access_token}}
    _set: {nylas_calendar_id: $calendar_id, nylas_account_id: $account_id}
  ) {
    affected_rows
  }
}
    `;
export const Unique_User_By_ClientDocument = gql`
    query unique_user_by_client($id: uuid!) {
  users(where: {clients: {id: {_eq: $id}}}) {
    id
    email
    first_name
    last_name
    phone
    auth0_uid
  }
}
    `;
export const Unique_User_By_EmailDocument = gql`
    query unique_user_by_email($email: String) {
  users(where: {email: {_eq: $email}}) {
    nylas_access_token
  }
}
    `;
export const Insert_Test_UserDocument = gql`
    mutation insert_test_user($auth0_uid: String!, $email: String!, $first_name: String!) {
  insert_users_one(
    object: {auth0_uid: $auth0_uid, email: $email, first_name: $first_name, test: true}
  ) {
    id
  }
}
    `;
export const Delete_Test_UserDocument = gql`
    mutation delete_test_user($id: uuid!) {
  delete_users_by_pk(id: $id) {
    id
  }
}
    `;
export const Delete_Client_UserDocument = gql`
    mutation delete_client_user($auth0_uid: String = "") {
  update_users(where: {auth0_uid: {_eq: $auth0_uid}}, _set: {deleted_at: "NOW()"}) {
    affected_rows
  }
  update_clients(
    where: {owner_id: {_eq: $auth0_uid}}
    _set: {deleted_at: "NOW()"}
  ) {
    affected_rows
  }
}
    `;
export const Delete_Candidate_UserDocument = gql`
    mutation delete_candidate_user($auth0_uid: String = "") {
  update_users(where: {auth0_uid: {_eq: $auth0_uid}}, _set: {deleted_at: "NOW()"}) {
    affected_rows
  }
  update_candidates(
    where: {owner_id: {_eq: $auth0_uid}}
    _set: {deleted_at: "NOW()"}
  ) {
    affected_rows
  }
}
    `;

export type SdkFunctionWrapper = <T>(action: (requestHeaders?:Record<string, string>) => Promise<T>, operationName: string) => Promise<T>;


const defaultWrapper: SdkFunctionWrapper = (action, _operationName) => action();

export function getSdk(client: GraphQLClient, withWrapper: SdkFunctionWrapper = defaultWrapper) {
  return {
    candidate_activity(variables?: Candidate_ActivityQueryVariables, requestHeaders?: Dom.RequestInit["headers"]): Promise<Candidate_ActivityQuery> {
      return withWrapper((wrappedRequestHeaders) => client.request<Candidate_ActivityQuery>(Candidate_ActivityDocument, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'candidate_activity');
    },
    client_activity(variables: Client_ActivityQueryVariables, requestHeaders?: Dom.RequestInit["headers"]): Promise<Client_ActivityQuery> {
      return withWrapper((wrappedRequestHeaders) => client.request<Client_ActivityQuery>(Client_ActivityDocument, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'client_activity');
    },
    track(variables: TrackMutationVariables, requestHeaders?: Dom.RequestInit["headers"]): Promise<TrackMutation> {
      return withWrapper((wrappedRequestHeaders) => client.request<TrackMutation>(TrackDocument, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'track');
    },
    trackResult(variables: TrackResultQueryVariables, requestHeaders?: Dom.RequestInit["headers"]): Promise<TrackResultQuery> {
      return withWrapper((wrappedRequestHeaders) => client.request<TrackResultQuery>(TrackResultDocument, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'trackResult');
    },
    create_event(variables: Create_EventMutationVariables, requestHeaders?: Dom.RequestInit["headers"]): Promise<Create_EventMutation> {
      return withWrapper((wrappedRequestHeaders) => client.request<Create_EventMutation>(Create_EventDocument, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'create_event');
    },
    delete_events(variables: Delete_EventsMutationVariables, requestHeaders?: Dom.RequestInit["headers"]): Promise<Delete_EventsMutation> {
      return withWrapper((wrappedRequestHeaders) => client.request<Delete_EventsMutation>(Delete_EventsDocument, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'delete_events');
    },
    unique_event(variables: Unique_EventQueryVariables, requestHeaders?: Dom.RequestInit["headers"]): Promise<Unique_EventQuery> {
      return withWrapper((wrappedRequestHeaders) => client.request<Unique_EventQuery>(Unique_EventDocument, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'unique_event');
    },
    all_events_by_client(variables?: All_Events_By_ClientQueryVariables, requestHeaders?: Dom.RequestInit["headers"]): Promise<All_Events_By_ClientQuery> {
      return withWrapper((wrappedRequestHeaders) => client.request<All_Events_By_ClientQuery>(All_Events_By_ClientDocument, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'all_events_by_client');
    },
    all_events(variables?: All_EventsQueryVariables, requestHeaders?: Dom.RequestInit["headers"]): Promise<All_EventsQuery> {
      return withWrapper((wrappedRequestHeaders) => client.request<All_EventsQuery>(All_EventsDocument, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'all_events');
    },
    search_events(variables?: Search_EventsQueryVariables, requestHeaders?: Dom.RequestInit["headers"]): Promise<Search_EventsQuery> {
      return withWrapper((wrappedRequestHeaders) => client.request<Search_EventsQuery>(Search_EventsDocument, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'search_events');
    },
    search_all_events(variables?: Search_All_EventsQueryVariables, requestHeaders?: Dom.RequestInit["headers"]): Promise<Search_All_EventsQuery> {
      return withWrapper((wrappedRequestHeaders) => client.request<Search_All_EventsQuery>(Search_All_EventsDocument, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'search_all_events');
    },
    date_range_events(variables: Date_Range_EventsQueryVariables, requestHeaders?: Dom.RequestInit["headers"]): Promise<Date_Range_EventsQuery> {
      return withWrapper((wrappedRequestHeaders) => client.request<Date_Range_EventsQuery>(Date_Range_EventsDocument, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'date_range_events');
    },
    aggregate_events(variables?: Aggregate_EventsQueryVariables, requestHeaders?: Dom.RequestInit["headers"]): Promise<Aggregate_EventsQuery> {
      return withWrapper((wrappedRequestHeaders) => client.request<Aggregate_EventsQuery>(Aggregate_EventsDocument, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'aggregate_events');
    },
    enterprise_views(variables?: Enterprise_ViewsQueryVariables, requestHeaders?: Dom.RequestInit["headers"]): Promise<Enterprise_ViewsQuery> {
      return withWrapper((wrappedRequestHeaders) => client.request<Enterprise_ViewsQuery>(Enterprise_ViewsDocument, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'enterprise_views');
    },
    enterprise_events(variables?: Enterprise_EventsQueryVariables, requestHeaders?: Dom.RequestInit["headers"]): Promise<Enterprise_EventsQuery> {
      return withWrapper((wrappedRequestHeaders) => client.request<Enterprise_EventsQuery>(Enterprise_EventsDocument, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'enterprise_events');
    },
    unique_event_by_auth0_uid(variables?: Unique_Event_By_Auth0_UidQueryVariables, requestHeaders?: Dom.RequestInit["headers"]): Promise<Unique_Event_By_Auth0_UidQuery> {
      return withWrapper((wrappedRequestHeaders) => client.request<Unique_Event_By_Auth0_UidQuery>(Unique_Event_By_Auth0_UidDocument, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'unique_event_by_auth0_uid');
    },
    upsert_unique_candidate(variables: Upsert_Unique_CandidateMutationVariables, requestHeaders?: Dom.RequestInit["headers"]): Promise<Upsert_Unique_CandidateMutation> {
      return withWrapper((wrappedRequestHeaders) => client.request<Upsert_Unique_CandidateMutation>(Upsert_Unique_CandidateDocument, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'upsert_unique_candidate');
    },
    all_candidates(variables?: All_CandidatesQueryVariables, requestHeaders?: Dom.RequestInit["headers"]): Promise<All_CandidatesQuery> {
      return withWrapper((wrappedRequestHeaders) => client.request<All_CandidatesQuery>(All_CandidatesDocument, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'all_candidates');
    },
    create_candidate(variables: Create_CandidateMutationVariables, requestHeaders?: Dom.RequestInit["headers"]): Promise<Create_CandidateMutation> {
      return withWrapper((wrappedRequestHeaders) => client.request<Create_CandidateMutation>(Create_CandidateDocument, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'create_candidate');
    },
    create_unique_tenant_candidate(variables: Create_Unique_Tenant_CandidateMutationVariables, requestHeaders?: Dom.RequestInit["headers"]): Promise<Create_Unique_Tenant_CandidateMutation> {
      return withWrapper((wrappedRequestHeaders) => client.request<Create_Unique_Tenant_CandidateMutation>(Create_Unique_Tenant_CandidateDocument, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'create_unique_tenant_candidate');
    },
    search_candidates_by_object(variables?: Search_Candidates_By_ObjectQueryVariables, requestHeaders?: Dom.RequestInit["headers"]): Promise<Search_Candidates_By_ObjectQuery> {
      return withWrapper((wrappedRequestHeaders) => client.request<Search_Candidates_By_ObjectQuery>(Search_Candidates_By_ObjectDocument, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'search_candidates_by_object');
    },
    search_candidates(variables: Search_CandidatesQueryVariables, requestHeaders?: Dom.RequestInit["headers"]): Promise<Search_CandidatesQuery> {
      return withWrapper((wrappedRequestHeaders) => client.request<Search_CandidatesQuery>(Search_CandidatesDocument, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'search_candidates');
    },
    aggregate_candidates(variables?: Aggregate_CandidatesQueryVariables, requestHeaders?: Dom.RequestInit["headers"]): Promise<Aggregate_CandidatesQuery> {
      return withWrapper((wrappedRequestHeaders) => client.request<Aggregate_CandidatesQuery>(Aggregate_CandidatesDocument, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'aggregate_candidates');
    },
    search_all_candidates(variables?: Search_All_CandidatesQueryVariables, requestHeaders?: Dom.RequestInit["headers"]): Promise<Search_All_CandidatesQuery> {
      return withWrapper((wrappedRequestHeaders) => client.request<Search_All_CandidatesQuery>(Search_All_CandidatesDocument, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'search_all_candidates');
    },
    unique_candidate_admin(variables: Unique_Candidate_AdminQueryVariables, requestHeaders?: Dom.RequestInit["headers"]): Promise<Unique_Candidate_AdminQuery> {
      return withWrapper((wrappedRequestHeaders) => client.request<Unique_Candidate_AdminQuery>(Unique_Candidate_AdminDocument, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'unique_candidate_admin');
    },
    unique_candidate(variables: Unique_CandidateQueryVariables, requestHeaders?: Dom.RequestInit["headers"]): Promise<Unique_CandidateQuery> {
      return withWrapper((wrappedRequestHeaders) => client.request<Unique_CandidateQuery>(Unique_CandidateDocument, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'unique_candidate');
    },
    unique_candidate_preview(variables: Unique_Candidate_PreviewQueryVariables, requestHeaders?: Dom.RequestInit["headers"]): Promise<Unique_Candidate_PreviewQuery> {
      return withWrapper((wrappedRequestHeaders) => client.request<Unique_Candidate_PreviewQuery>(Unique_Candidate_PreviewDocument, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'unique_candidate_preview');
    },
    candidate_stats(variables: Candidate_StatsQueryVariables, requestHeaders?: Dom.RequestInit["headers"]): Promise<Candidate_StatsQuery> {
      return withWrapper((wrappedRequestHeaders) => client.request<Candidate_StatsQuery>(Candidate_StatsDocument, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'candidate_stats');
    },
    unique_candidate_email(variables?: Unique_Candidate_EmailQueryVariables, requestHeaders?: Dom.RequestInit["headers"]): Promise<Unique_Candidate_EmailQuery> {
      return withWrapper((wrappedRequestHeaders) => client.request<Unique_Candidate_EmailQuery>(Unique_Candidate_EmailDocument, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'unique_candidate_email');
    },
    unique_candidate_by_owner_id(variables?: Unique_Candidate_By_Owner_IdQueryVariables, requestHeaders?: Dom.RequestInit["headers"]): Promise<Unique_Candidate_By_Owner_IdQuery> {
      return withWrapper((wrappedRequestHeaders) => client.request<Unique_Candidate_By_Owner_IdQuery>(Unique_Candidate_By_Owner_IdDocument, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'unique_candidate_by_owner_id');
    },
    delete_candidate_industries(variables: Delete_Candidate_IndustriesMutationVariables, requestHeaders?: Dom.RequestInit["headers"]): Promise<Delete_Candidate_IndustriesMutation> {
      return withWrapper((wrappedRequestHeaders) => client.request<Delete_Candidate_IndustriesMutation>(Delete_Candidate_IndustriesDocument, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'delete_candidate_industries');
    },
    delete_candidate_skills(variables: Delete_Candidate_SkillsMutationVariables, requestHeaders?: Dom.RequestInit["headers"]): Promise<Delete_Candidate_SkillsMutation> {
      return withWrapper((wrappedRequestHeaders) => client.request<Delete_Candidate_SkillsMutation>(Delete_Candidate_SkillsDocument, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'delete_candidate_skills');
    },
    delete_candidate_locations(variables: Delete_Candidate_LocationsMutationVariables, requestHeaders?: Dom.RequestInit["headers"]): Promise<Delete_Candidate_LocationsMutation> {
      return withWrapper((wrappedRequestHeaders) => client.request<Delete_Candidate_LocationsMutation>(Delete_Candidate_LocationsDocument, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'delete_candidate_locations');
    },
    delete_candidate_degrees(variables: Delete_Candidate_DegreesMutationVariables, requestHeaders?: Dom.RequestInit["headers"]): Promise<Delete_Candidate_DegreesMutation> {
      return withWrapper((wrappedRequestHeaders) => client.request<Delete_Candidate_DegreesMutation>(Delete_Candidate_DegreesDocument, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'delete_candidate_degrees');
    },
    delete_candidate_majors(variables: Delete_Candidate_MajorsMutationVariables, requestHeaders?: Dom.RequestInit["headers"]): Promise<Delete_Candidate_MajorsMutation> {
      return withWrapper((wrappedRequestHeaders) => client.request<Delete_Candidate_MajorsMutation>(Delete_Candidate_MajorsDocument, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'delete_candidate_majors');
    },
    delete_test_tenant_candidate(variables: Delete_Test_Tenant_CandidateMutationVariables, requestHeaders?: Dom.RequestInit["headers"]): Promise<Delete_Test_Tenant_CandidateMutation> {
      return withWrapper((wrappedRequestHeaders) => client.request<Delete_Test_Tenant_CandidateMutation>(Delete_Test_Tenant_CandidateDocument, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'delete_test_tenant_candidate');
    },
    delete_test_candidate(variables: Delete_Test_CandidateMutationVariables, requestHeaders?: Dom.RequestInit["headers"]): Promise<Delete_Test_CandidateMutation> {
      return withWrapper((wrappedRequestHeaders) => client.request<Delete_Test_CandidateMutation>(Delete_Test_CandidateDocument, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'delete_test_candidate');
    },
    send_candidate_first_viewed_email(variables?: Send_Candidate_First_Viewed_EmailMutationVariables, requestHeaders?: Dom.RequestInit["headers"]): Promise<Send_Candidate_First_Viewed_EmailMutation> {
      return withWrapper((wrappedRequestHeaders) => client.request<Send_Candidate_First_Viewed_EmailMutation>(Send_Candidate_First_Viewed_EmailDocument, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'send_candidate_first_viewed_email');
    },
    all_candidates_by_status(variables?: All_Candidates_By_StatusQueryVariables, requestHeaders?: Dom.RequestInit["headers"]): Promise<All_Candidates_By_StatusQuery> {
      return withWrapper((wrappedRequestHeaders) => client.request<All_Candidates_By_StatusQuery>(All_Candidates_By_StatusDocument, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'all_candidates_by_status');
    },
    send_candidate_no_show_email(variables?: Send_Candidate_No_Show_EmailMutationVariables, requestHeaders?: Dom.RequestInit["headers"]): Promise<Send_Candidate_No_Show_EmailMutation> {
      return withWrapper((wrappedRequestHeaders) => client.request<Send_Candidate_No_Show_EmailMutation>(Send_Candidate_No_Show_EmailDocument, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'send_candidate_no_show_email');
    },
    upsert_unique_client(variables: Upsert_Unique_ClientMutationVariables, requestHeaders?: Dom.RequestInit["headers"]): Promise<Upsert_Unique_ClientMutation> {
      return withWrapper((wrappedRequestHeaders) => client.request<Upsert_Unique_ClientMutation>(Upsert_Unique_ClientDocument, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'upsert_unique_client');
    },
    update_unique_client_contact(variables: Update_Unique_Client_ContactMutationVariables, requestHeaders?: Dom.RequestInit["headers"]): Promise<Update_Unique_Client_ContactMutation> {
      return withWrapper((wrappedRequestHeaders) => client.request<Update_Unique_Client_ContactMutation>(Update_Unique_Client_ContactDocument, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'update_unique_client_contact');
    },
    create_client(variables: Create_ClientMutationVariables, requestHeaders?: Dom.RequestInit["headers"]): Promise<Create_ClientMutation> {
      return withWrapper((wrappedRequestHeaders) => client.request<Create_ClientMutation>(Create_ClientDocument, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'create_client');
    },
    delete_test_client(variables: Delete_Test_ClientMutationVariables, requestHeaders?: Dom.RequestInit["headers"]): Promise<Delete_Test_ClientMutation> {
      return withWrapper((wrappedRequestHeaders) => client.request<Delete_Test_ClientMutation>(Delete_Test_ClientDocument, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'delete_test_client');
    },
    delete_test_client_events(variables: Delete_Test_Client_EventsMutationVariables, requestHeaders?: Dom.RequestInit["headers"]): Promise<Delete_Test_Client_EventsMutation> {
      return withWrapper((wrappedRequestHeaders) => client.request<Delete_Test_Client_EventsMutation>(Delete_Test_Client_EventsDocument, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'delete_test_client_events');
    },
    delete_test_client_jobs(variables: Delete_Test_Client_JobsMutationVariables, requestHeaders?: Dom.RequestInit["headers"]): Promise<Delete_Test_Client_JobsMutation> {
      return withWrapper((wrappedRequestHeaders) => client.request<Delete_Test_Client_JobsMutation>(Delete_Test_Client_JobsDocument, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'delete_test_client_jobs');
    },
    create_client_location(variables: Create_Client_LocationMutationVariables, requestHeaders?: Dom.RequestInit["headers"]): Promise<Create_Client_LocationMutation> {
      return withWrapper((wrappedRequestHeaders) => client.request<Create_Client_LocationMutation>(Create_Client_LocationDocument, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'create_client_location');
    },
    delete_test_client_location(variables: Delete_Test_Client_LocationMutationVariables, requestHeaders?: Dom.RequestInit["headers"]): Promise<Delete_Test_Client_LocationMutation> {
      return withWrapper((wrappedRequestHeaders) => client.request<Delete_Test_Client_LocationMutation>(Delete_Test_Client_LocationDocument, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'delete_test_client_location');
    },
    create_client_industries(variables: Create_Client_IndustriesMutationVariables, requestHeaders?: Dom.RequestInit["headers"]): Promise<Create_Client_IndustriesMutation> {
      return withWrapper((wrappedRequestHeaders) => client.request<Create_Client_IndustriesMutation>(Create_Client_IndustriesDocument, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'create_client_industries');
    },
    delete_test_client_industries(variables: Delete_Test_Client_IndustriesMutationVariables, requestHeaders?: Dom.RequestInit["headers"]): Promise<Delete_Test_Client_IndustriesMutation> {
      return withWrapper((wrappedRequestHeaders) => client.request<Delete_Test_Client_IndustriesMutation>(Delete_Test_Client_IndustriesDocument, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'delete_test_client_industries');
    },
    all_clients(variables?: All_ClientsQueryVariables, requestHeaders?: Dom.RequestInit["headers"]): Promise<All_ClientsQuery> {
      return withWrapper((wrappedRequestHeaders) => client.request<All_ClientsQuery>(All_ClientsDocument, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'all_clients');
    },
    search_clients(variables?: Search_ClientsQueryVariables, requestHeaders?: Dom.RequestInit["headers"]): Promise<Search_ClientsQuery> {
      return withWrapper((wrappedRequestHeaders) => client.request<Search_ClientsQuery>(Search_ClientsDocument, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'search_clients');
    },
    search_all_clients(variables?: Search_All_ClientsQueryVariables, requestHeaders?: Dom.RequestInit["headers"]): Promise<Search_All_ClientsQuery> {
      return withWrapper((wrappedRequestHeaders) => client.request<Search_All_ClientsQuery>(Search_All_ClientsDocument, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'search_all_clients');
    },
    unique_client_by_ownerId(variables: Unique_Client_By_OwnerIdQueryVariables, requestHeaders?: Dom.RequestInit["headers"]): Promise<Unique_Client_By_OwnerIdQuery> {
      return withWrapper((wrappedRequestHeaders) => client.request<Unique_Client_By_OwnerIdQuery>(Unique_Client_By_OwnerIdDocument, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'unique_client_by_ownerId');
    },
    unique_client(variables: Unique_ClientQueryVariables, requestHeaders?: Dom.RequestInit["headers"]): Promise<Unique_ClientQuery> {
      return withWrapper((wrappedRequestHeaders) => client.request<Unique_ClientQuery>(Unique_ClientDocument, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'unique_client');
    },
    all_client_jobs(variables?: All_Client_JobsQueryVariables, requestHeaders?: Dom.RequestInit["headers"]): Promise<All_Client_JobsQuery> {
      return withWrapper((wrappedRequestHeaders) => client.request<All_Client_JobsQuery>(All_Client_JobsDocument, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'all_client_jobs');
    },
    client_select(variables?: Client_SelectQueryVariables, requestHeaders?: Dom.RequestInit["headers"]): Promise<Client_SelectQuery> {
      return withWrapper((wrappedRequestHeaders) => client.request<Client_SelectQuery>(Client_SelectDocument, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'client_select');
    },
    attach_client_to_job(variables: Attach_Client_To_JobMutationVariables, requestHeaders?: Dom.RequestInit["headers"]): Promise<Attach_Client_To_JobMutation> {
      return withWrapper((wrappedRequestHeaders) => client.request<Attach_Client_To_JobMutation>(Attach_Client_To_JobDocument, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'attach_client_to_job');
    },
    unique_client_by_email(variables?: Unique_Client_By_EmailQueryVariables, requestHeaders?: Dom.RequestInit["headers"]): Promise<Unique_Client_By_EmailQuery> {
      return withWrapper((wrappedRequestHeaders) => client.request<Unique_Client_By_EmailQuery>(Unique_Client_By_EmailDocument, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'unique_client_by_email');
    },
    create_contact(variables: Create_ContactMutationVariables, requestHeaders?: Dom.RequestInit["headers"]): Promise<Create_ContactMutation> {
      return withWrapper((wrappedRequestHeaders) => client.request<Create_ContactMutation>(Create_ContactDocument, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'create_contact');
    },
    delete_test_contact(variables: Delete_Test_ContactMutationVariables, requestHeaders?: Dom.RequestInit["headers"]): Promise<Delete_Test_ContactMutation> {
      return withWrapper((wrappedRequestHeaders) => client.request<Delete_Test_ContactMutation>(Delete_Test_ContactDocument, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'delete_test_contact');
    },
    update_contact_by_pk(variables: Update_Contact_By_PkMutationVariables, requestHeaders?: Dom.RequestInit["headers"]): Promise<Update_Contact_By_PkMutation> {
      return withWrapper((wrappedRequestHeaders) => client.request<Update_Contact_By_PkMutation>(Update_Contact_By_PkDocument, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'update_contact_by_pk');
    },
    unique_contact(variables: Unique_ContactQueryVariables, requestHeaders?: Dom.RequestInit["headers"]): Promise<Unique_ContactQuery> {
      return withWrapper((wrappedRequestHeaders) => client.request<Unique_ContactQuery>(Unique_ContactDocument, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'unique_contact');
    },
    upsert_unique_candidate_degrees(variables: Upsert_Unique_Candidate_DegreesMutationVariables, requestHeaders?: Dom.RequestInit["headers"]): Promise<Upsert_Unique_Candidate_DegreesMutation> {
      return withWrapper((wrappedRequestHeaders) => client.request<Upsert_Unique_Candidate_DegreesMutation>(Upsert_Unique_Candidate_DegreesDocument, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'upsert_unique_candidate_degrees');
    },
    all_enterprises(variables?: All_EnterprisesQueryVariables, requestHeaders?: Dom.RequestInit["headers"]): Promise<All_EnterprisesQuery> {
      return withWrapper((wrappedRequestHeaders) => client.request<All_EnterprisesQuery>(All_EnterprisesDocument, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'all_enterprises');
    },
    all_public_enterprises(variables?: All_Public_EnterprisesQueryVariables, requestHeaders?: Dom.RequestInit["headers"]): Promise<All_Public_EnterprisesQuery> {
      return withWrapper((wrappedRequestHeaders) => client.request<All_Public_EnterprisesQuery>(All_Public_EnterprisesDocument, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'all_public_enterprises');
    },
    unique_enterprise_by_shortname(variables: Unique_Enterprise_By_ShortnameQueryVariables, requestHeaders?: Dom.RequestInit["headers"]): Promise<Unique_Enterprise_By_ShortnameQuery> {
      return withWrapper((wrappedRequestHeaders) => client.request<Unique_Enterprise_By_ShortnameQuery>(Unique_Enterprise_By_ShortnameDocument, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'unique_enterprise_by_shortname');
    },
    unique_enterprise(variables?: Unique_EnterpriseQueryVariables, requestHeaders?: Dom.RequestInit["headers"]): Promise<Unique_EnterpriseQuery> {
      return withWrapper((wrappedRequestHeaders) => client.request<Unique_EnterpriseQuery>(Unique_EnterpriseDocument, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'unique_enterprise');
    },
    unique_enterprise_clients_by_owner_id(variables?: Unique_Enterprise_Clients_By_Owner_IdQueryVariables, requestHeaders?: Dom.RequestInit["headers"]): Promise<Unique_Enterprise_Clients_By_Owner_IdQuery> {
      return withWrapper((wrappedRequestHeaders) => client.request<Unique_Enterprise_Clients_By_Owner_IdQuery>(Unique_Enterprise_Clients_By_Owner_IdDocument, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'unique_enterprise_clients_by_owner_id');
    },
    upsert_unique_enterprise(variables: Upsert_Unique_EnterpriseMutationVariables, requestHeaders?: Dom.RequestInit["headers"]): Promise<Upsert_Unique_EnterpriseMutation> {
      return withWrapper((wrappedRequestHeaders) => client.request<Upsert_Unique_EnterpriseMutation>(Upsert_Unique_EnterpriseDocument, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'upsert_unique_enterprise');
    },
    upsert_enterprise_client(variables?: Upsert_Enterprise_ClientMutationVariables, requestHeaders?: Dom.RequestInit["headers"]): Promise<Upsert_Enterprise_ClientMutation> {
      return withWrapper((wrappedRequestHeaders) => client.request<Upsert_Enterprise_ClientMutation>(Upsert_Enterprise_ClientDocument, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'upsert_enterprise_client');
    },
    remove_enterprise_client_by_client_id(variables?: Remove_Enterprise_Client_By_Client_IdMutationVariables, requestHeaders?: Dom.RequestInit["headers"]): Promise<Remove_Enterprise_Client_By_Client_IdMutation> {
      return withWrapper((wrappedRequestHeaders) => client.request<Remove_Enterprise_Client_By_Client_IdMutation>(Remove_Enterprise_Client_By_Client_IdDocument, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'remove_enterprise_client_by_client_id');
    },
    upsert_exclusive_candidate(variables?: Upsert_Exclusive_CandidateMutationVariables, requestHeaders?: Dom.RequestInit["headers"]): Promise<Upsert_Exclusive_CandidateMutation> {
      return withWrapper((wrappedRequestHeaders) => client.request<Upsert_Exclusive_CandidateMutation>(Upsert_Exclusive_CandidateDocument, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'upsert_exclusive_candidate');
    },
    all_exclusive_by_candidate_id(variables?: All_Exclusive_By_Candidate_IdQueryVariables, requestHeaders?: Dom.RequestInit["headers"]): Promise<All_Exclusive_By_Candidate_IdQuery> {
      return withWrapper((wrappedRequestHeaders) => client.request<All_Exclusive_By_Candidate_IdQuery>(All_Exclusive_By_Candidate_IdDocument, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'all_exclusive_by_candidate_id');
    },
    all_exclusive_by_enterprise_id(variables?: All_Exclusive_By_Enterprise_IdQueryVariables, requestHeaders?: Dom.RequestInit["headers"]): Promise<All_Exclusive_By_Enterprise_IdQuery> {
      return withWrapper((wrappedRequestHeaders) => client.request<All_Exclusive_By_Enterprise_IdQuery>(All_Exclusive_By_Enterprise_IdDocument, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'all_exclusive_by_enterprise_id');
    },
    fb_server_event(variables: Fb_Server_EventMutationVariables, requestHeaders?: Dom.RequestInit["headers"]): Promise<Fb_Server_EventMutation> {
      return withWrapper((wrappedRequestHeaders) => client.request<Fb_Server_EventMutation>(Fb_Server_EventDocument, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'fb_server_event');
    },
    feature_popups_by_role(variables?: Feature_Popups_By_RoleQueryVariables, requestHeaders?: Dom.RequestInit["headers"]): Promise<Feature_Popups_By_RoleQuery> {
      return withWrapper((wrappedRequestHeaders) => client.request<Feature_Popups_By_RoleQuery>(Feature_Popups_By_RoleDocument, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'feature_popups_by_role');
    },
    follows_by_candidate_id(variables?: Follows_By_Candidate_IdQueryVariables, requestHeaders?: Dom.RequestInit["headers"]): Promise<Follows_By_Candidate_IdQuery> {
      return withWrapper((wrappedRequestHeaders) => client.request<Follows_By_Candidate_IdQuery>(Follows_By_Candidate_IdDocument, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'follows_by_candidate_id');
    },
    follows_by_owner_id(variables?: Follows_By_Owner_IdQueryVariables, requestHeaders?: Dom.RequestInit["headers"]): Promise<Follows_By_Owner_IdQuery> {
      return withWrapper((wrappedRequestHeaders) => client.request<Follows_By_Owner_IdQuery>(Follows_By_Owner_IdDocument, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'follows_by_owner_id');
    },
    upsert_unique_follow(variables?: Upsert_Unique_FollowMutationVariables, requestHeaders?: Dom.RequestInit["headers"]): Promise<Upsert_Unique_FollowMutation> {
      return withWrapper((wrappedRequestHeaders) => client.request<Upsert_Unique_FollowMutation>(Upsert_Unique_FollowDocument, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'upsert_unique_follow');
    },
    upsert_unique_unfollow(variables?: Upsert_Unique_UnfollowMutationVariables, requestHeaders?: Dom.RequestInit["headers"]): Promise<Upsert_Unique_UnfollowMutation> {
      return withWrapper((wrappedRequestHeaders) => client.request<Upsert_Unique_UnfollowMutation>(Upsert_Unique_UnfollowDocument, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'upsert_unique_unfollow');
    },
    all_candidate_follows(variables?: All_Candidate_FollowsQueryVariables, requestHeaders?: Dom.RequestInit["headers"]): Promise<All_Candidate_FollowsQuery> {
      return withWrapper((wrappedRequestHeaders) => client.request<All_Candidate_FollowsQuery>(All_Candidate_FollowsDocument, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'all_candidate_follows');
    },
    all_follows_by_enterprise_id(variables?: All_Follows_By_Enterprise_IdQueryVariables, requestHeaders?: Dom.RequestInit["headers"]): Promise<All_Follows_By_Enterprise_IdQuery> {
      return withWrapper((wrappedRequestHeaders) => client.request<All_Follows_By_Enterprise_IdQuery>(All_Follows_By_Enterprise_IdDocument, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'all_follows_by_enterprise_id');
    },
    all_sign_ups_by_ec_shortname(variables?: All_Sign_Ups_By_Ec_ShortnameQueryVariables, requestHeaders?: Dom.RequestInit["headers"]): Promise<All_Sign_Ups_By_Ec_ShortnameQuery> {
      return withWrapper((wrappedRequestHeaders) => client.request<All_Sign_Ups_By_Ec_ShortnameQuery>(All_Sign_Ups_By_Ec_ShortnameDocument, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'all_sign_ups_by_ec_shortname');
    },
    unique_candidate_default_values(variables: Unique_Candidate_Default_ValuesQueryVariables, requestHeaders?: Dom.RequestInit["headers"]): Promise<Unique_Candidate_Default_ValuesQuery> {
      return withWrapper((wrappedRequestHeaders) => client.request<Unique_Candidate_Default_ValuesQuery>(Unique_Candidate_Default_ValuesDocument, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'unique_candidate_default_values');
    },
    form_options(variables?: Form_OptionsQueryVariables, requestHeaders?: Dom.RequestInit["headers"]): Promise<Form_OptionsQuery> {
      return withWrapper((wrappedRequestHeaders) => client.request<Form_OptionsQuery>(Form_OptionsDocument, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'form_options');
    },
    upsert_unique_candidate_industries(variables: Upsert_Unique_Candidate_IndustriesMutationVariables, requestHeaders?: Dom.RequestInit["headers"]): Promise<Upsert_Unique_Candidate_IndustriesMutation> {
      return withWrapper((wrappedRequestHeaders) => client.request<Upsert_Unique_Candidate_IndustriesMutation>(Upsert_Unique_Candidate_IndustriesDocument, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'upsert_unique_candidate_industries');
    },
    upsert_unique_client_industries(variables: Upsert_Unique_Client_IndustriesMutationVariables, requestHeaders?: Dom.RequestInit["headers"]): Promise<Upsert_Unique_Client_IndustriesMutation> {
      return withWrapper((wrappedRequestHeaders) => client.request<Upsert_Unique_Client_IndustriesMutation>(Upsert_Unique_Client_IndustriesDocument, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'upsert_unique_client_industries');
    },
    unique_interview(variables: Unique_InterviewQueryVariables, requestHeaders?: Dom.RequestInit["headers"]): Promise<Unique_InterviewQuery> {
      return withWrapper((wrappedRequestHeaders) => client.request<Unique_InterviewQuery>(Unique_InterviewDocument, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'unique_interview');
    },
    upsert_unique_interview(variables: Upsert_Unique_InterviewMutationVariables, requestHeaders?: Dom.RequestInit["headers"]): Promise<Upsert_Unique_InterviewMutation> {
      return withWrapper((wrappedRequestHeaders) => client.request<Upsert_Unique_InterviewMutation>(Upsert_Unique_InterviewDocument, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'upsert_unique_interview');
    },
    upsert_unique_interview_participants(variables?: Upsert_Unique_Interview_ParticipantsMutationVariables, requestHeaders?: Dom.RequestInit["headers"]): Promise<Upsert_Unique_Interview_ParticipantsMutation> {
      return withWrapper((wrappedRequestHeaders) => client.request<Upsert_Unique_Interview_ParticipantsMutation>(Upsert_Unique_Interview_ParticipantsDocument, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'upsert_unique_interview_participants');
    },
    all_interviews(variables?: All_InterviewsQueryVariables, requestHeaders?: Dom.RequestInit["headers"]): Promise<All_InterviewsQuery> {
      return withWrapper((wrappedRequestHeaders) => client.request<All_InterviewsQuery>(All_InterviewsDocument, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'all_interviews');
    },
    update_interview(variables: Update_InterviewMutationVariables, requestHeaders?: Dom.RequestInit["headers"]): Promise<Update_InterviewMutation> {
      return withWrapper((wrappedRequestHeaders) => client.request<Update_InterviewMutation>(Update_InterviewDocument, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'update_interview');
    },
    update_interview_participants(variables: Update_Interview_ParticipantsMutationVariables, requestHeaders?: Dom.RequestInit["headers"]): Promise<Update_Interview_ParticipantsMutation> {
      return withWrapper((wrappedRequestHeaders) => client.request<Update_Interview_ParticipantsMutation>(Update_Interview_ParticipantsDocument, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'update_interview_participants');
    },
    all_accepted_scheduled_interviews(variables?: All_Accepted_Scheduled_InterviewsQueryVariables, requestHeaders?: Dom.RequestInit["headers"]): Promise<All_Accepted_Scheduled_InterviewsQuery> {
      return withWrapper((wrappedRequestHeaders) => client.request<All_Accepted_Scheduled_InterviewsQuery>(All_Accepted_Scheduled_InterviewsDocument, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'all_accepted_scheduled_interviews');
    },
    all_pending_unaccepted_interviews(variables?: All_Pending_Unaccepted_InterviewsQueryVariables, requestHeaders?: Dom.RequestInit["headers"]): Promise<All_Pending_Unaccepted_InterviewsQuery> {
      return withWrapper((wrappedRequestHeaders) => client.request<All_Pending_Unaccepted_InterviewsQuery>(All_Pending_Unaccepted_InterviewsDocument, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'all_pending_unaccepted_interviews');
    },
    unique_candidate_interviews(variables?: Unique_Candidate_InterviewsQueryVariables, requestHeaders?: Dom.RequestInit["headers"]): Promise<Unique_Candidate_InterviewsQuery> {
      return withWrapper((wrappedRequestHeaders) => client.request<Unique_Candidate_InterviewsQuery>(Unique_Candidate_InterviewsDocument, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'unique_candidate_interviews');
    },
    interview_search(variables?: Interview_SearchQueryVariables, requestHeaders?: Dom.RequestInit["headers"]): Promise<Interview_SearchQuery> {
      return withWrapper((wrappedRequestHeaders) => client.request<Interview_SearchQuery>(Interview_SearchDocument, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'interview_search');
    },
    interview_search_options(variables?: Interview_Search_OptionsQueryVariables, requestHeaders?: Dom.RequestInit["headers"]): Promise<Interview_Search_OptionsQuery> {
      return withWrapper((wrappedRequestHeaders) => client.request<Interview_Search_OptionsQuery>(Interview_Search_OptionsDocument, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'interview_search_options');
    },
    delete_test_interview(variables: Delete_Test_InterviewMutationVariables, requestHeaders?: Dom.RequestInit["headers"]): Promise<Delete_Test_InterviewMutation> {
      return withWrapper((wrappedRequestHeaders) => client.request<Delete_Test_InterviewMutation>(Delete_Test_InterviewDocument, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'delete_test_interview');
    },
    delete_test_interview_participants(variables: Delete_Test_Interview_ParticipantsMutationVariables, requestHeaders?: Dom.RequestInit["headers"]): Promise<Delete_Test_Interview_ParticipantsMutation> {
      return withWrapper((wrappedRequestHeaders) => client.request<Delete_Test_Interview_ParticipantsMutation>(Delete_Test_Interview_ParticipantsDocument, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'delete_test_interview_participants');
    },
    unique_tenant_jobs(variables: Unique_Tenant_JobsQueryVariables, requestHeaders?: Dom.RequestInit["headers"]): Promise<Unique_Tenant_JobsQuery> {
      return withWrapper((wrappedRequestHeaders) => client.request<Unique_Tenant_JobsQuery>(Unique_Tenant_JobsDocument, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'unique_tenant_jobs');
    },
    all_tenant_jobs(variables?: All_Tenant_JobsQueryVariables, requestHeaders?: Dom.RequestInit["headers"]): Promise<All_Tenant_JobsQuery> {
      return withWrapper((wrappedRequestHeaders) => client.request<All_Tenant_JobsQuery>(All_Tenant_JobsDocument, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'all_tenant_jobs');
    },
    search_jobs(variables?: Search_JobsQueryVariables, requestHeaders?: Dom.RequestInit["headers"]): Promise<Search_JobsQuery> {
      return withWrapper((wrappedRequestHeaders) => client.request<Search_JobsQuery>(Search_JobsDocument, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'search_jobs');
    },
    upsert_unique_job(variables: Upsert_Unique_JobMutationVariables, requestHeaders?: Dom.RequestInit["headers"]): Promise<Upsert_Unique_JobMutation> {
      return withWrapper((wrappedRequestHeaders) => client.request<Upsert_Unique_JobMutation>(Upsert_Unique_JobDocument, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'upsert_unique_job');
    },
    all_jobs(variables?: All_JobsQueryVariables, requestHeaders?: Dom.RequestInit["headers"]): Promise<All_JobsQuery> {
      return withWrapper((wrappedRequestHeaders) => client.request<All_JobsQuery>(All_JobsDocument, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'all_jobs');
    },
    recent_jobs(variables?: Recent_JobsQueryVariables, requestHeaders?: Dom.RequestInit["headers"]): Promise<Recent_JobsQuery> {
      return withWrapper((wrappedRequestHeaders) => client.request<Recent_JobsQuery>(Recent_JobsDocument, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'recent_jobs');
    },
    unique_job(variables: Unique_JobQueryVariables, requestHeaders?: Dom.RequestInit["headers"]): Promise<Unique_JobQuery> {
      return withWrapper((wrappedRequestHeaders) => client.request<Unique_JobQuery>(Unique_JobDocument, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'unique_job');
    },
    unique_job_public(variables: Unique_Job_PublicQueryVariables, requestHeaders?: Dom.RequestInit["headers"]): Promise<Unique_Job_PublicQuery> {
      return withWrapper((wrappedRequestHeaders) => client.request<Unique_Job_PublicQuery>(Unique_Job_PublicDocument, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'unique_job_public');
    },
    job_shortlist(variables?: Job_ShortlistQueryVariables, requestHeaders?: Dom.RequestInit["headers"]): Promise<Job_ShortlistQuery> {
      return withWrapper((wrappedRequestHeaders) => client.request<Job_ShortlistQuery>(Job_ShortlistDocument, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'job_shortlist');
    },
    upsert_job_from_proposed_interview(variables?: Upsert_Job_From_Proposed_InterviewMutationVariables, requestHeaders?: Dom.RequestInit["headers"]): Promise<Upsert_Job_From_Proposed_InterviewMutation> {
      return withWrapper((wrappedRequestHeaders) => client.request<Upsert_Job_From_Proposed_InterviewMutation>(Upsert_Job_From_Proposed_InterviewDocument, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'upsert_job_from_proposed_interview');
    },
    upsert_client_job(variables?: Upsert_Client_JobMutationVariables, requestHeaders?: Dom.RequestInit["headers"]): Promise<Upsert_Client_JobMutation> {
      return withWrapper((wrappedRequestHeaders) => client.request<Upsert_Client_JobMutation>(Upsert_Client_JobDocument, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'upsert_client_job');
    },
    unique_client_jobs_by_client(variables?: Unique_Client_Jobs_By_ClientQueryVariables, requestHeaders?: Dom.RequestInit["headers"]): Promise<Unique_Client_Jobs_By_ClientQuery> {
      return withWrapper((wrappedRequestHeaders) => client.request<Unique_Client_Jobs_By_ClientQuery>(Unique_Client_Jobs_By_ClientDocument, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'unique_client_jobs_by_client');
    },
    unique_job_candidates_by_pk(variables: Unique_Job_Candidates_By_PkQueryVariables, requestHeaders?: Dom.RequestInit["headers"]): Promise<Unique_Job_Candidates_By_PkQuery> {
      return withWrapper((wrappedRequestHeaders) => client.request<Unique_Job_Candidates_By_PkQuery>(Unique_Job_Candidates_By_PkDocument, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'unique_job_candidates_by_pk');
    },
    job_candidates_aggregate(variables: Job_Candidates_AggregateQueryVariables, requestHeaders?: Dom.RequestInit["headers"]): Promise<Job_Candidates_AggregateQuery> {
      return withWrapper((wrappedRequestHeaders) => client.request<Job_Candidates_AggregateQuery>(Job_Candidates_AggregateDocument, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'job_candidates_aggregate');
    },
    delete_test_jobs(variables: Delete_Test_JobsMutationVariables, requestHeaders?: Dom.RequestInit["headers"]): Promise<Delete_Test_JobsMutation> {
      return withWrapper((wrappedRequestHeaders) => client.request<Delete_Test_JobsMutation>(Delete_Test_JobsDocument, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'delete_test_jobs');
    },
    update_job(variables: Update_JobMutationVariables, requestHeaders?: Dom.RequestInit["headers"]): Promise<Update_JobMutation> {
      return withWrapper((wrappedRequestHeaders) => client.request<Update_JobMutation>(Update_JobDocument, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'update_job');
    },
    insert_job_candidates_one(variables: Insert_Job_Candidates_OneMutationVariables, requestHeaders?: Dom.RequestInit["headers"]): Promise<Insert_Job_Candidates_OneMutation> {
      return withWrapper((wrappedRequestHeaders) => client.request<Insert_Job_Candidates_OneMutation>(Insert_Job_Candidates_OneDocument, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'insert_job_candidates_one');
    },
    attach_location_to_client(variables: Attach_Location_To_ClientMutationVariables, requestHeaders?: Dom.RequestInit["headers"]): Promise<Attach_Location_To_ClientMutation> {
      return withWrapper((wrappedRequestHeaders) => client.request<Attach_Location_To_ClientMutation>(Attach_Location_To_ClientDocument, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'attach_location_to_client');
    },
    attach_location_to_candidate(variables: Attach_Location_To_CandidateMutationVariables, requestHeaders?: Dom.RequestInit["headers"]): Promise<Attach_Location_To_CandidateMutation> {
      return withWrapper((wrappedRequestHeaders) => client.request<Attach_Location_To_CandidateMutation>(Attach_Location_To_CandidateDocument, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'attach_location_to_candidate');
    },
    attach_location_to_job(variables: Attach_Location_To_JobMutationVariables, requestHeaders?: Dom.RequestInit["headers"]): Promise<Attach_Location_To_JobMutation> {
      return withWrapper((wrappedRequestHeaders) => client.request<Attach_Location_To_JobMutation>(Attach_Location_To_JobDocument, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'attach_location_to_job');
    },
    upsert_unique_candidate_majors(variables: Upsert_Unique_Candidate_MajorsMutationVariables, requestHeaders?: Dom.RequestInit["headers"]): Promise<Upsert_Unique_Candidate_MajorsMutation> {
      return withWrapper((wrappedRequestHeaders) => client.request<Upsert_Unique_Candidate_MajorsMutation>(Upsert_Unique_Candidate_MajorsDocument, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'upsert_unique_candidate_majors');
    },
    all_courses(variables?: All_CoursesQueryVariables, requestHeaders?: Dom.RequestInit["headers"]): Promise<All_CoursesQuery> {
      return withWrapper((wrappedRequestHeaders) => client.request<All_CoursesQuery>(All_CoursesDocument, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'all_courses');
    },
    unique_course(variables: Unique_CourseQueryVariables, requestHeaders?: Dom.RequestInit["headers"]): Promise<Unique_CourseQuery> {
      return withWrapper((wrappedRequestHeaders) => client.request<Unique_CourseQuery>(Unique_CourseDocument, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'unique_course');
    },
    upsert_unique_candidate_courses(variables: Upsert_Unique_Candidate_CoursesMutationVariables, requestHeaders?: Dom.RequestInit["headers"]): Promise<Upsert_Unique_Candidate_CoursesMutation> {
      return withWrapper((wrappedRequestHeaders) => client.request<Upsert_Unique_Candidate_CoursesMutation>(Upsert_Unique_Candidate_CoursesDocument, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'upsert_unique_candidate_courses');
    },
    unique_course_form_id(variables?: Unique_Course_Form_IdQueryVariables, requestHeaders?: Dom.RequestInit["headers"]): Promise<Unique_Course_Form_IdQuery> {
      return withWrapper((wrappedRequestHeaders) => client.request<Unique_Course_Form_IdQuery>(Unique_Course_Form_IdDocument, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'unique_course_form_id');
    },
    update_unique_candidate_courses_as_passed(variables?: Update_Unique_Candidate_Courses_As_PassedMutationVariables, requestHeaders?: Dom.RequestInit["headers"]): Promise<Update_Unique_Candidate_Courses_As_PassedMutation> {
      return withWrapper((wrappedRequestHeaders) => client.request<Update_Unique_Candidate_Courses_As_PassedMutation>(Update_Unique_Candidate_Courses_As_PassedDocument, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'update_unique_candidate_courses_as_passed');
    },
    all_completed_courses_by_candidate_id(variables?: All_Completed_Courses_By_Candidate_IdQueryVariables, requestHeaders?: Dom.RequestInit["headers"]): Promise<All_Completed_Courses_By_Candidate_IdQuery> {
      return withWrapper((wrappedRequestHeaders) => client.request<All_Completed_Courses_By_Candidate_IdQuery>(All_Completed_Courses_By_Candidate_IdDocument, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'all_completed_courses_by_candidate_id');
    },
    nylas_post_calendar_availability(variables?: Nylas_Post_Calendar_AvailabilityMutationVariables, requestHeaders?: Dom.RequestInit["headers"]): Promise<Nylas_Post_Calendar_AvailabilityMutation> {
      return withWrapper((wrappedRequestHeaders) => client.request<Nylas_Post_Calendar_AvailabilityMutation>(Nylas_Post_Calendar_AvailabilityDocument, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'nylas_post_calendar_availability');
    },
    nylas_post_event(variables: Nylas_Post_EventMutationVariables, requestHeaders?: Dom.RequestInit["headers"]): Promise<Nylas_Post_EventMutation> {
      return withWrapper((wrappedRequestHeaders) => client.request<Nylas_Post_EventMutation>(Nylas_Post_EventDocument, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'nylas_post_event');
    },
    nylas_get_event_by_id(variables?: Nylas_Get_Event_By_IdQueryVariables, requestHeaders?: Dom.RequestInit["headers"]): Promise<Nylas_Get_Event_By_IdQuery> {
      return withWrapper((wrappedRequestHeaders) => client.request<Nylas_Get_Event_By_IdQuery>(Nylas_Get_Event_By_IdDocument, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'nylas_get_event_by_id');
    },
    create_placement(variables?: Create_PlacementMutationVariables, requestHeaders?: Dom.RequestInit["headers"]): Promise<Create_PlacementMutation> {
      return withWrapper((wrappedRequestHeaders) => client.request<Create_PlacementMutation>(Create_PlacementDocument, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'create_placement');
    },
    placements_by_candidate_id(variables?: Placements_By_Candidate_IdQueryVariables, requestHeaders?: Dom.RequestInit["headers"]): Promise<Placements_By_Candidate_IdQuery> {
      return withWrapper((wrappedRequestHeaders) => client.request<Placements_By_Candidate_IdQuery>(Placements_By_Candidate_IdDocument, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'placements_by_candidate_id');
    },
    unique_placement(variables?: Unique_PlacementQueryVariables, requestHeaders?: Dom.RequestInit["headers"]): Promise<Unique_PlacementQuery> {
      return withWrapper((wrappedRequestHeaders) => client.request<Unique_PlacementQuery>(Unique_PlacementDocument, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'unique_placement');
    },
    all_placements(variables?: All_PlacementsQueryVariables, requestHeaders?: Dom.RequestInit["headers"]): Promise<All_PlacementsQuery> {
      return withWrapper((wrappedRequestHeaders) => client.request<All_PlacementsQuery>(All_PlacementsDocument, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'all_placements');
    },
    search_placements(variables?: Search_PlacementsQueryVariables, requestHeaders?: Dom.RequestInit["headers"]): Promise<Search_PlacementsQuery> {
      return withWrapper((wrappedRequestHeaders) => client.request<Search_PlacementsQuery>(Search_PlacementsDocument, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'search_placements');
    },
    search_all_placements(variables?: Search_All_PlacementsQueryVariables, requestHeaders?: Dom.RequestInit["headers"]): Promise<Search_All_PlacementsQuery> {
      return withWrapper((wrappedRequestHeaders) => client.request<Search_All_PlacementsQuery>(Search_All_PlacementsDocument, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'search_all_placements');
    },
    update_placement_by_pk(variables: Update_Placement_By_PkMutationVariables, requestHeaders?: Dom.RequestInit["headers"]): Promise<Update_Placement_By_PkMutation> {
      return withWrapper((wrappedRequestHeaders) => client.request<Update_Placement_By_PkMutation>(Update_Placement_By_PkDocument, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'update_placement_by_pk');
    },
    all_industries(variables?: All_IndustriesQueryVariables, requestHeaders?: Dom.RequestInit["headers"]): Promise<All_IndustriesQuery> {
      return withWrapper((wrappedRequestHeaders) => client.request<All_IndustriesQuery>(All_IndustriesDocument, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'all_industries');
    },
    all_skills(variables?: All_SkillsQueryVariables, requestHeaders?: Dom.RequestInit["headers"]): Promise<All_SkillsQuery> {
      return withWrapper((wrappedRequestHeaders) => client.request<All_SkillsQuery>(All_SkillsDocument, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'all_skills');
    },
    all_degrees(variables?: All_DegreesQueryVariables, requestHeaders?: Dom.RequestInit["headers"]): Promise<All_DegreesQuery> {
      return withWrapper((wrappedRequestHeaders) => client.request<All_DegreesQuery>(All_DegreesDocument, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'all_degrees');
    },
    all_majors(variables?: All_MajorsQueryVariables, requestHeaders?: Dom.RequestInit["headers"]): Promise<All_MajorsQuery> {
      return withWrapper((wrappedRequestHeaders) => client.request<All_MajorsQuery>(All_MajorsDocument, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'all_majors');
    },
    all_universities(variables?: All_UniversitiesQueryVariables, requestHeaders?: Dom.RequestInit["headers"]): Promise<All_UniversitiesQuery> {
      return withWrapper((wrappedRequestHeaders) => client.request<All_UniversitiesQuery>(All_UniversitiesDocument, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'all_universities');
    },
    get_job_by_id(variables?: Get_Job_By_IdQueryVariables, requestHeaders?: Dom.RequestInit["headers"]): Promise<Get_Job_By_IdQuery> {
      return withWrapper((wrappedRequestHeaders) => client.request<Get_Job_By_IdQuery>(Get_Job_By_IdDocument, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'get_job_by_id');
    },
    admin_report(variables?: Admin_ReportQueryVariables, requestHeaders?: Dom.RequestInit["headers"]): Promise<Admin_ReportQuery> {
      return withWrapper((wrappedRequestHeaders) => client.request<Admin_ReportQuery>(Admin_ReportDocument, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'admin_report');
    },
    all_the_things_user(variables?: All_The_Things_UserQueryVariables, requestHeaders?: Dom.RequestInit["headers"]): Promise<All_The_Things_UserQuery> {
      return withWrapper((wrappedRequestHeaders) => client.request<All_The_Things_UserQuery>(All_The_Things_UserDocument, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'all_the_things_user');
    },
    create_new_job(variables?: Create_New_JobMutationVariables, requestHeaders?: Dom.RequestInit["headers"]): Promise<Create_New_JobMutation> {
      return withWrapper((wrappedRequestHeaders) => client.request<Create_New_JobMutation>(Create_New_JobDocument, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'create_new_job');
    },
    add_client_location(variables: Add_Client_LocationMutationVariables, requestHeaders?: Dom.RequestInit["headers"]): Promise<Add_Client_LocationMutation> {
      return withWrapper((wrappedRequestHeaders) => client.request<Add_Client_LocationMutation>(Add_Client_LocationDocument, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'add_client_location');
    },
    insert_multiple_tenants(variables: Insert_Multiple_TenantsMutationVariables, requestHeaders?: Dom.RequestInit["headers"]): Promise<Insert_Multiple_TenantsMutation> {
      return withWrapper((wrappedRequestHeaders) => client.request<Insert_Multiple_TenantsMutation>(Insert_Multiple_TenantsDocument, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'insert_multiple_tenants');
    },
    insert_multiple_clients(variables: Insert_Multiple_ClientsMutationVariables, requestHeaders?: Dom.RequestInit["headers"]): Promise<Insert_Multiple_ClientsMutation> {
      return withWrapper((wrappedRequestHeaders) => client.request<Insert_Multiple_ClientsMutation>(Insert_Multiple_ClientsDocument, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'insert_multiple_clients');
    },
    all_report_targets(variables?: All_Report_TargetsQueryVariables, requestHeaders?: Dom.RequestInit["headers"]): Promise<All_Report_TargetsQuery> {
      return withWrapper((wrappedRequestHeaders) => client.request<All_Report_TargetsQuery>(All_Report_TargetsDocument, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'all_report_targets');
    },
    update_target(variables?: Update_TargetMutationVariables, requestHeaders?: Dom.RequestInit["headers"]): Promise<Update_TargetMutation> {
      return withWrapper((wrappedRequestHeaders) => client.request<Update_TargetMutation>(Update_TargetDocument, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'update_target');
    },
    all_active_users(variables?: All_Active_UsersQueryVariables, requestHeaders?: Dom.RequestInit["headers"]): Promise<All_Active_UsersQuery> {
      return withWrapper((wrappedRequestHeaders) => client.request<All_Active_UsersQuery>(All_Active_UsersDocument, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'all_active_users');
    },
    all_events_reporting_by_created_at(variables?: All_Events_Reporting_By_Created_AtQueryVariables, requestHeaders?: Dom.RequestInit["headers"]): Promise<All_Events_Reporting_By_Created_AtQuery> {
      return withWrapper((wrappedRequestHeaders) => client.request<All_Events_Reporting_By_Created_AtQuery>(All_Events_Reporting_By_Created_AtDocument, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'all_events_reporting_by_created_at');
    },
    all_events_reporting_by_event_name(variables?: All_Events_Reporting_By_Event_NameQueryVariables, requestHeaders?: Dom.RequestInit["headers"]): Promise<All_Events_Reporting_By_Event_NameQuery> {
      return withWrapper((wrappedRequestHeaders) => client.request<All_Events_Reporting_By_Event_NameQuery>(All_Events_Reporting_By_Event_NameDocument, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'all_events_reporting_by_event_name');
    },
    search_facet_values(variables?: Search_Facet_ValuesQueryVariables, requestHeaders?: Dom.RequestInit["headers"]): Promise<Search_Facet_ValuesQuery> {
      return withWrapper((wrappedRequestHeaders) => client.request<Search_Facet_ValuesQuery>(Search_Facet_ValuesDocument, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'search_facet_values');
    },
    search_locations(variables?: Search_LocationsQueryVariables, requestHeaders?: Dom.RequestInit["headers"]): Promise<Search_LocationsQuery> {
      return withWrapper((wrappedRequestHeaders) => client.request<Search_LocationsQuery>(Search_LocationsDocument, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'search_locations');
    },
    search_clients_by_business_name(variables?: Search_Clients_By_Business_NameQueryVariables, requestHeaders?: Dom.RequestInit["headers"]): Promise<Search_Clients_By_Business_NameQuery> {
      return withWrapper((wrappedRequestHeaders) => client.request<Search_Clients_By_Business_NameQuery>(Search_Clients_By_Business_NameDocument, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'search_clients_by_business_name');
    },
    search_jobs_preview(variables?: Search_Jobs_PreviewQueryVariables, requestHeaders?: Dom.RequestInit["headers"]): Promise<Search_Jobs_PreviewQuery> {
      return withWrapper((wrappedRequestHeaders) => client.request<Search_Jobs_PreviewQuery>(Search_Jobs_PreviewDocument, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'search_jobs_preview');
    },
    unique_short_url(variables: Unique_Short_UrlQueryVariables, requestHeaders?: Dom.RequestInit["headers"]): Promise<Unique_Short_UrlQuery> {
      return withWrapper((wrappedRequestHeaders) => client.request<Unique_Short_UrlQuery>(Unique_Short_UrlDocument, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'unique_short_url');
    },
    unique_short_url_by_short_id(variables: Unique_Short_Url_By_Short_IdQueryVariables, requestHeaders?: Dom.RequestInit["headers"]): Promise<Unique_Short_Url_By_Short_IdQuery> {
      return withWrapper((wrappedRequestHeaders) => client.request<Unique_Short_Url_By_Short_IdQuery>(Unique_Short_Url_By_Short_IdDocument, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'unique_short_url_by_short_id');
    },
    upsert_unique_short_url(variables: Upsert_Unique_Short_UrlMutationVariables, requestHeaders?: Dom.RequestInit["headers"]): Promise<Upsert_Unique_Short_UrlMutation> {
      return withWrapper((wrappedRequestHeaders) => client.request<Upsert_Unique_Short_UrlMutation>(Upsert_Unique_Short_UrlDocument, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'upsert_unique_short_url');
    },
    shortlists_by_candidate_id(variables: Shortlists_By_Candidate_IdQueryVariables, requestHeaders?: Dom.RequestInit["headers"]): Promise<Shortlists_By_Candidate_IdQuery> {
      return withWrapper((wrappedRequestHeaders) => client.request<Shortlists_By_Candidate_IdQuery>(Shortlists_By_Candidate_IdDocument, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'shortlists_by_candidate_id');
    },
    unique_shortlist(variables: Unique_ShortlistQueryVariables, requestHeaders?: Dom.RequestInit["headers"]): Promise<Unique_ShortlistQuery> {
      return withWrapper((wrappedRequestHeaders) => client.request<Unique_ShortlistQuery>(Unique_ShortlistDocument, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'unique_shortlist');
    },
    shortlist_job_candidates(variables: Shortlist_Job_CandidatesMutationVariables, requestHeaders?: Dom.RequestInit["headers"]): Promise<Shortlist_Job_CandidatesMutation> {
      return withWrapper((wrappedRequestHeaders) => client.request<Shortlist_Job_CandidatesMutation>(Shortlist_Job_CandidatesDocument, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'shortlist_job_candidates');
    },
    upsert_unique_shortlist(variables: Upsert_Unique_ShortlistMutationVariables, requestHeaders?: Dom.RequestInit["headers"]): Promise<Upsert_Unique_ShortlistMutation> {
      return withWrapper((wrappedRequestHeaders) => client.request<Upsert_Unique_ShortlistMutation>(Upsert_Unique_ShortlistDocument, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'upsert_unique_shortlist');
    },
    upsert_unique_candidate_skills(variables: Upsert_Unique_Candidate_SkillsMutationVariables, requestHeaders?: Dom.RequestInit["headers"]): Promise<Upsert_Unique_Candidate_SkillsMutation> {
      return withWrapper((wrappedRequestHeaders) => client.request<Upsert_Unique_Candidate_SkillsMutation>(Upsert_Unique_Candidate_SkillsDocument, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'upsert_unique_candidate_skills');
    },
    all_tenants(variables?: All_TenantsQueryVariables, requestHeaders?: Dom.RequestInit["headers"]): Promise<All_TenantsQuery> {
      return withWrapper((wrappedRequestHeaders) => client.request<All_TenantsQuery>(All_TenantsDocument, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'all_tenants');
    },
    unique_tenant_by_shortname(variables: Unique_Tenant_By_ShortnameQueryVariables, requestHeaders?: Dom.RequestInit["headers"]): Promise<Unique_Tenant_By_ShortnameQuery> {
      return withWrapper((wrappedRequestHeaders) => client.request<Unique_Tenant_By_ShortnameQuery>(Unique_Tenant_By_ShortnameDocument, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'unique_tenant_by_shortname');
    },
    unique_tenant(variables: Unique_TenantQueryVariables, requestHeaders?: Dom.RequestInit["headers"]): Promise<Unique_TenantQuery> {
      return withWrapper((wrappedRequestHeaders) => client.request<Unique_TenantQuery>(Unique_TenantDocument, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'unique_tenant');
    },
    unique_user(variables: Unique_UserQueryVariables, requestHeaders?: Dom.RequestInit["headers"]): Promise<Unique_UserQuery> {
      return withWrapper((wrappedRequestHeaders) => client.request<Unique_UserQuery>(Unique_UserDocument, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'unique_user');
    },
    user_by_auth0_uid(variables: User_By_Auth0_UidQueryVariables, requestHeaders?: Dom.RequestInit["headers"]): Promise<User_By_Auth0_UidQuery> {
      return withWrapper((wrappedRequestHeaders) => client.request<User_By_Auth0_UidQuery>(User_By_Auth0_UidDocument, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'user_by_auth0_uid');
    },
    update_user_set_last_seen(variables?: Update_User_Set_Last_SeenMutationVariables, requestHeaders?: Dom.RequestInit["headers"]): Promise<Update_User_Set_Last_SeenMutation> {
      return withWrapper((wrappedRequestHeaders) => client.request<Update_User_Set_Last_SeenMutation>(Update_User_Set_Last_SeenDocument, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'update_user_set_last_seen');
    },
    update_user_set_role_as_client(variables?: Update_User_Set_Role_As_ClientMutationVariables, requestHeaders?: Dom.RequestInit["headers"]): Promise<Update_User_Set_Role_As_ClientMutation> {
      return withWrapper((wrappedRequestHeaders) => client.request<Update_User_Set_Role_As_ClientMutation>(Update_User_Set_Role_As_ClientDocument, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'update_user_set_role_as_client');
    },
    update_user_set_role_as_candidate(variables?: Update_User_Set_Role_As_CandidateMutationVariables, requestHeaders?: Dom.RequestInit["headers"]): Promise<Update_User_Set_Role_As_CandidateMutation> {
      return withWrapper((wrappedRequestHeaders) => client.request<Update_User_Set_Role_As_CandidateMutation>(Update_User_Set_Role_As_CandidateDocument, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'update_user_set_role_as_candidate');
    },
    update_user_nylas_code(variables?: Update_User_Nylas_CodeMutationVariables, requestHeaders?: Dom.RequestInit["headers"]): Promise<Update_User_Nylas_CodeMutation> {
      return withWrapper((wrappedRequestHeaders) => client.request<Update_User_Nylas_CodeMutation>(Update_User_Nylas_CodeDocument, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'update_user_nylas_code');
    },
    update_user_calendar_id(variables?: Update_User_Calendar_IdMutationVariables, requestHeaders?: Dom.RequestInit["headers"]): Promise<Update_User_Calendar_IdMutation> {
      return withWrapper((wrappedRequestHeaders) => client.request<Update_User_Calendar_IdMutation>(Update_User_Calendar_IdDocument, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'update_user_calendar_id');
    },
    unique_user_by_client(variables: Unique_User_By_ClientQueryVariables, requestHeaders?: Dom.RequestInit["headers"]): Promise<Unique_User_By_ClientQuery> {
      return withWrapper((wrappedRequestHeaders) => client.request<Unique_User_By_ClientQuery>(Unique_User_By_ClientDocument, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'unique_user_by_client');
    },
    unique_user_by_email(variables?: Unique_User_By_EmailQueryVariables, requestHeaders?: Dom.RequestInit["headers"]): Promise<Unique_User_By_EmailQuery> {
      return withWrapper((wrappedRequestHeaders) => client.request<Unique_User_By_EmailQuery>(Unique_User_By_EmailDocument, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'unique_user_by_email');
    },
    insert_test_user(variables: Insert_Test_UserMutationVariables, requestHeaders?: Dom.RequestInit["headers"]): Promise<Insert_Test_UserMutation> {
      return withWrapper((wrappedRequestHeaders) => client.request<Insert_Test_UserMutation>(Insert_Test_UserDocument, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'insert_test_user');
    },
    delete_test_user(variables: Delete_Test_UserMutationVariables, requestHeaders?: Dom.RequestInit["headers"]): Promise<Delete_Test_UserMutation> {
      return withWrapper((wrappedRequestHeaders) => client.request<Delete_Test_UserMutation>(Delete_Test_UserDocument, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'delete_test_user');
    },
    delete_client_user(variables?: Delete_Client_UserMutationVariables, requestHeaders?: Dom.RequestInit["headers"]): Promise<Delete_Client_UserMutation> {
      return withWrapper((wrappedRequestHeaders) => client.request<Delete_Client_UserMutation>(Delete_Client_UserDocument, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'delete_client_user');
    },
    delete_candidate_user(variables?: Delete_Candidate_UserMutationVariables, requestHeaders?: Dom.RequestInit["headers"]): Promise<Delete_Candidate_UserMutation> {
      return withWrapper((wrappedRequestHeaders) => client.request<Delete_Candidate_UserMutation>(Delete_Candidate_UserDocument, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'delete_candidate_user');
    }
  };
}
export type Sdk = ReturnType<typeof getSdk>;
export function getSdkWithHooks(client: GraphQLClient, withWrapper: SdkFunctionWrapper = defaultWrapper) {
  const sdk = getSdk(client, withWrapper);
  const genKey = <V extends Record<string, unknown> = Record<string, unknown>>(name: string, object: V = {} as V): SWRKeyInterface => [name, ...Object.keys(object).sort().map(key => object[key])];
  return {
    ...sdk,
    useCandidateActivity(variables?: Candidate_ActivityQueryVariables, config?: SWRConfigInterface<Candidate_ActivityQuery, ClientError>) {
      return useSWR<Candidate_ActivityQuery, ClientError>(genKey<Candidate_ActivityQueryVariables>('CandidateActivity', variables), () => sdk.candidate_activity(variables), config);
    },
    useClientActivity(variables: Client_ActivityQueryVariables, config?: SWRConfigInterface<Client_ActivityQuery, ClientError>) {
      return useSWR<Client_ActivityQuery, ClientError>(genKey<Client_ActivityQueryVariables>('ClientActivity', variables), () => sdk.client_activity(variables), config);
    },
    useTrackResult(variables: TrackResultQueryVariables, config?: SWRConfigInterface<TrackResultQuery, ClientError>) {
      return useSWR<TrackResultQuery, ClientError>(genKey<TrackResultQueryVariables>('TrackResult', variables), () => sdk.trackResult(variables), config);
    },
    useUniqueEvent(variables: Unique_EventQueryVariables, config?: SWRConfigInterface<Unique_EventQuery, ClientError>) {
      return useSWR<Unique_EventQuery, ClientError>(genKey<Unique_EventQueryVariables>('UniqueEvent', variables), () => sdk.unique_event(variables), config);
    },
    useAllEventsByClient(variables?: All_Events_By_ClientQueryVariables, config?: SWRConfigInterface<All_Events_By_ClientQuery, ClientError>) {
      return useSWR<All_Events_By_ClientQuery, ClientError>(genKey<All_Events_By_ClientQueryVariables>('AllEventsByClient', variables), () => sdk.all_events_by_client(variables), config);
    },
    useAllEvents(variables?: All_EventsQueryVariables, config?: SWRConfigInterface<All_EventsQuery, ClientError>) {
      return useSWR<All_EventsQuery, ClientError>(genKey<All_EventsQueryVariables>('AllEvents', variables), () => sdk.all_events(variables), config);
    },
    useSearchEvents(variables?: Search_EventsQueryVariables, config?: SWRConfigInterface<Search_EventsQuery, ClientError>) {
      return useSWR<Search_EventsQuery, ClientError>(genKey<Search_EventsQueryVariables>('SearchEvents', variables), () => sdk.search_events(variables), config);
    },
    useSearchAllEvents(variables?: Search_All_EventsQueryVariables, config?: SWRConfigInterface<Search_All_EventsQuery, ClientError>) {
      return useSWR<Search_All_EventsQuery, ClientError>(genKey<Search_All_EventsQueryVariables>('SearchAllEvents', variables), () => sdk.search_all_events(variables), config);
    },
    useDateRangeEvents(variables: Date_Range_EventsQueryVariables, config?: SWRConfigInterface<Date_Range_EventsQuery, ClientError>) {
      return useSWR<Date_Range_EventsQuery, ClientError>(genKey<Date_Range_EventsQueryVariables>('DateRangeEvents', variables), () => sdk.date_range_events(variables), config);
    },
    useAggregateEvents(variables?: Aggregate_EventsQueryVariables, config?: SWRConfigInterface<Aggregate_EventsQuery, ClientError>) {
      return useSWR<Aggregate_EventsQuery, ClientError>(genKey<Aggregate_EventsQueryVariables>('AggregateEvents', variables), () => sdk.aggregate_events(variables), config);
    },
    useEnterpriseViews(variables?: Enterprise_ViewsQueryVariables, config?: SWRConfigInterface<Enterprise_ViewsQuery, ClientError>) {
      return useSWR<Enterprise_ViewsQuery, ClientError>(genKey<Enterprise_ViewsQueryVariables>('EnterpriseViews', variables), () => sdk.enterprise_views(variables), config);
    },
    useEnterpriseEvents(variables?: Enterprise_EventsQueryVariables, config?: SWRConfigInterface<Enterprise_EventsQuery, ClientError>) {
      return useSWR<Enterprise_EventsQuery, ClientError>(genKey<Enterprise_EventsQueryVariables>('EnterpriseEvents', variables), () => sdk.enterprise_events(variables), config);
    },
    useUniqueEventByAuth0Uid(variables?: Unique_Event_By_Auth0_UidQueryVariables, config?: SWRConfigInterface<Unique_Event_By_Auth0_UidQuery, ClientError>) {
      return useSWR<Unique_Event_By_Auth0_UidQuery, ClientError>(genKey<Unique_Event_By_Auth0_UidQueryVariables>('UniqueEventByAuth0Uid', variables), () => sdk.unique_event_by_auth0_uid(variables), config);
    },
    useAllCandidates(variables?: All_CandidatesQueryVariables, config?: SWRConfigInterface<All_CandidatesQuery, ClientError>) {
      return useSWR<All_CandidatesQuery, ClientError>(genKey<All_CandidatesQueryVariables>('AllCandidates', variables), () => sdk.all_candidates(variables), config);
    },
    useSearchCandidatesByObject(variables?: Search_Candidates_By_ObjectQueryVariables, config?: SWRConfigInterface<Search_Candidates_By_ObjectQuery, ClientError>) {
      return useSWR<Search_Candidates_By_ObjectQuery, ClientError>(genKey<Search_Candidates_By_ObjectQueryVariables>('SearchCandidatesByObject', variables), () => sdk.search_candidates_by_object(variables), config);
    },
    useSearchCandidates(variables: Search_CandidatesQueryVariables, config?: SWRConfigInterface<Search_CandidatesQuery, ClientError>) {
      return useSWR<Search_CandidatesQuery, ClientError>(genKey<Search_CandidatesQueryVariables>('SearchCandidates', variables), () => sdk.search_candidates(variables), config);
    },
    useAggregateCandidates(variables?: Aggregate_CandidatesQueryVariables, config?: SWRConfigInterface<Aggregate_CandidatesQuery, ClientError>) {
      return useSWR<Aggregate_CandidatesQuery, ClientError>(genKey<Aggregate_CandidatesQueryVariables>('AggregateCandidates', variables), () => sdk.aggregate_candidates(variables), config);
    },
    useSearchAllCandidates(variables?: Search_All_CandidatesQueryVariables, config?: SWRConfigInterface<Search_All_CandidatesQuery, ClientError>) {
      return useSWR<Search_All_CandidatesQuery, ClientError>(genKey<Search_All_CandidatesQueryVariables>('SearchAllCandidates', variables), () => sdk.search_all_candidates(variables), config);
    },
    useUniqueCandidateAdmin(variables: Unique_Candidate_AdminQueryVariables, config?: SWRConfigInterface<Unique_Candidate_AdminQuery, ClientError>) {
      return useSWR<Unique_Candidate_AdminQuery, ClientError>(genKey<Unique_Candidate_AdminQueryVariables>('UniqueCandidateAdmin', variables), () => sdk.unique_candidate_admin(variables), config);
    },
    useUniqueCandidate(variables: Unique_CandidateQueryVariables, config?: SWRConfigInterface<Unique_CandidateQuery, ClientError>) {
      return useSWR<Unique_CandidateQuery, ClientError>(genKey<Unique_CandidateQueryVariables>('UniqueCandidate', variables), () => sdk.unique_candidate(variables), config);
    },
    useUniqueCandidatePreview(variables: Unique_Candidate_PreviewQueryVariables, config?: SWRConfigInterface<Unique_Candidate_PreviewQuery, ClientError>) {
      return useSWR<Unique_Candidate_PreviewQuery, ClientError>(genKey<Unique_Candidate_PreviewQueryVariables>('UniqueCandidatePreview', variables), () => sdk.unique_candidate_preview(variables), config);
    },
    useCandidateStats(variables: Candidate_StatsQueryVariables, config?: SWRConfigInterface<Candidate_StatsQuery, ClientError>) {
      return useSWR<Candidate_StatsQuery, ClientError>(genKey<Candidate_StatsQueryVariables>('CandidateStats', variables), () => sdk.candidate_stats(variables), config);
    },
    useUniqueCandidateEmail(variables?: Unique_Candidate_EmailQueryVariables, config?: SWRConfigInterface<Unique_Candidate_EmailQuery, ClientError>) {
      return useSWR<Unique_Candidate_EmailQuery, ClientError>(genKey<Unique_Candidate_EmailQueryVariables>('UniqueCandidateEmail', variables), () => sdk.unique_candidate_email(variables), config);
    },
    useUniqueCandidateByOwnerId(variables?: Unique_Candidate_By_Owner_IdQueryVariables, config?: SWRConfigInterface<Unique_Candidate_By_Owner_IdQuery, ClientError>) {
      return useSWR<Unique_Candidate_By_Owner_IdQuery, ClientError>(genKey<Unique_Candidate_By_Owner_IdQueryVariables>('UniqueCandidateByOwnerId', variables), () => sdk.unique_candidate_by_owner_id(variables), config);
    },
    useAllCandidatesByStatus(variables?: All_Candidates_By_StatusQueryVariables, config?: SWRConfigInterface<All_Candidates_By_StatusQuery, ClientError>) {
      return useSWR<All_Candidates_By_StatusQuery, ClientError>(genKey<All_Candidates_By_StatusQueryVariables>('AllCandidatesByStatus', variables), () => sdk.all_candidates_by_status(variables), config);
    },
    useAllClients(variables?: All_ClientsQueryVariables, config?: SWRConfigInterface<All_ClientsQuery, ClientError>) {
      return useSWR<All_ClientsQuery, ClientError>(genKey<All_ClientsQueryVariables>('AllClients', variables), () => sdk.all_clients(variables), config);
    },
    useSearchClients(variables?: Search_ClientsQueryVariables, config?: SWRConfigInterface<Search_ClientsQuery, ClientError>) {
      return useSWR<Search_ClientsQuery, ClientError>(genKey<Search_ClientsQueryVariables>('SearchClients', variables), () => sdk.search_clients(variables), config);
    },
    useSearchAllClients(variables?: Search_All_ClientsQueryVariables, config?: SWRConfigInterface<Search_All_ClientsQuery, ClientError>) {
      return useSWR<Search_All_ClientsQuery, ClientError>(genKey<Search_All_ClientsQueryVariables>('SearchAllClients', variables), () => sdk.search_all_clients(variables), config);
    },
    useUniqueClientByOwnerId(variables: Unique_Client_By_OwnerIdQueryVariables, config?: SWRConfigInterface<Unique_Client_By_OwnerIdQuery, ClientError>) {
      return useSWR<Unique_Client_By_OwnerIdQuery, ClientError>(genKey<Unique_Client_By_OwnerIdQueryVariables>('UniqueClientByOwnerId', variables), () => sdk.unique_client_by_ownerId(variables), config);
    },
    useUniqueClient(variables: Unique_ClientQueryVariables, config?: SWRConfigInterface<Unique_ClientQuery, ClientError>) {
      return useSWR<Unique_ClientQuery, ClientError>(genKey<Unique_ClientQueryVariables>('UniqueClient', variables), () => sdk.unique_client(variables), config);
    },
    useAllClientJobs(variables?: All_Client_JobsQueryVariables, config?: SWRConfigInterface<All_Client_JobsQuery, ClientError>) {
      return useSWR<All_Client_JobsQuery, ClientError>(genKey<All_Client_JobsQueryVariables>('AllClientJobs', variables), () => sdk.all_client_jobs(variables), config);
    },
    useClientSelect(variables?: Client_SelectQueryVariables, config?: SWRConfigInterface<Client_SelectQuery, ClientError>) {
      return useSWR<Client_SelectQuery, ClientError>(genKey<Client_SelectQueryVariables>('ClientSelect', variables), () => sdk.client_select(variables), config);
    },
    useUniqueClientByEmail(variables?: Unique_Client_By_EmailQueryVariables, config?: SWRConfigInterface<Unique_Client_By_EmailQuery, ClientError>) {
      return useSWR<Unique_Client_By_EmailQuery, ClientError>(genKey<Unique_Client_By_EmailQueryVariables>('UniqueClientByEmail', variables), () => sdk.unique_client_by_email(variables), config);
    },
    useUniqueContact(variables: Unique_ContactQueryVariables, config?: SWRConfigInterface<Unique_ContactQuery, ClientError>) {
      return useSWR<Unique_ContactQuery, ClientError>(genKey<Unique_ContactQueryVariables>('UniqueContact', variables), () => sdk.unique_contact(variables), config);
    },
    useAllEnterprises(variables?: All_EnterprisesQueryVariables, config?: SWRConfigInterface<All_EnterprisesQuery, ClientError>) {
      return useSWR<All_EnterprisesQuery, ClientError>(genKey<All_EnterprisesQueryVariables>('AllEnterprises', variables), () => sdk.all_enterprises(variables), config);
    },
    useAllPublicEnterprises(variables?: All_Public_EnterprisesQueryVariables, config?: SWRConfigInterface<All_Public_EnterprisesQuery, ClientError>) {
      return useSWR<All_Public_EnterprisesQuery, ClientError>(genKey<All_Public_EnterprisesQueryVariables>('AllPublicEnterprises', variables), () => sdk.all_public_enterprises(variables), config);
    },
    useUniqueEnterpriseByShortname(variables: Unique_Enterprise_By_ShortnameQueryVariables, config?: SWRConfigInterface<Unique_Enterprise_By_ShortnameQuery, ClientError>) {
      return useSWR<Unique_Enterprise_By_ShortnameQuery, ClientError>(genKey<Unique_Enterprise_By_ShortnameQueryVariables>('UniqueEnterpriseByShortname', variables), () => sdk.unique_enterprise_by_shortname(variables), config);
    },
    useUniqueEnterprise(variables?: Unique_EnterpriseQueryVariables, config?: SWRConfigInterface<Unique_EnterpriseQuery, ClientError>) {
      return useSWR<Unique_EnterpriseQuery, ClientError>(genKey<Unique_EnterpriseQueryVariables>('UniqueEnterprise', variables), () => sdk.unique_enterprise(variables), config);
    },
    useUniqueEnterpriseClientsByOwnerId(variables?: Unique_Enterprise_Clients_By_Owner_IdQueryVariables, config?: SWRConfigInterface<Unique_Enterprise_Clients_By_Owner_IdQuery, ClientError>) {
      return useSWR<Unique_Enterprise_Clients_By_Owner_IdQuery, ClientError>(genKey<Unique_Enterprise_Clients_By_Owner_IdQueryVariables>('UniqueEnterpriseClientsByOwnerId', variables), () => sdk.unique_enterprise_clients_by_owner_id(variables), config);
    },
    useAllExclusiveByCandidateId(variables?: All_Exclusive_By_Candidate_IdQueryVariables, config?: SWRConfigInterface<All_Exclusive_By_Candidate_IdQuery, ClientError>) {
      return useSWR<All_Exclusive_By_Candidate_IdQuery, ClientError>(genKey<All_Exclusive_By_Candidate_IdQueryVariables>('AllExclusiveByCandidateId', variables), () => sdk.all_exclusive_by_candidate_id(variables), config);
    },
    useAllExclusiveByEnterpriseId(variables?: All_Exclusive_By_Enterprise_IdQueryVariables, config?: SWRConfigInterface<All_Exclusive_By_Enterprise_IdQuery, ClientError>) {
      return useSWR<All_Exclusive_By_Enterprise_IdQuery, ClientError>(genKey<All_Exclusive_By_Enterprise_IdQueryVariables>('AllExclusiveByEnterpriseId', variables), () => sdk.all_exclusive_by_enterprise_id(variables), config);
    },
    useFeaturePopupsByRole(variables?: Feature_Popups_By_RoleQueryVariables, config?: SWRConfigInterface<Feature_Popups_By_RoleQuery, ClientError>) {
      return useSWR<Feature_Popups_By_RoleQuery, ClientError>(genKey<Feature_Popups_By_RoleQueryVariables>('FeaturePopupsByRole', variables), () => sdk.feature_popups_by_role(variables), config);
    },
    useFollowsByCandidateId(variables?: Follows_By_Candidate_IdQueryVariables, config?: SWRConfigInterface<Follows_By_Candidate_IdQuery, ClientError>) {
      return useSWR<Follows_By_Candidate_IdQuery, ClientError>(genKey<Follows_By_Candidate_IdQueryVariables>('FollowsByCandidateId', variables), () => sdk.follows_by_candidate_id(variables), config);
    },
    useFollowsByOwnerId(variables?: Follows_By_Owner_IdQueryVariables, config?: SWRConfigInterface<Follows_By_Owner_IdQuery, ClientError>) {
      return useSWR<Follows_By_Owner_IdQuery, ClientError>(genKey<Follows_By_Owner_IdQueryVariables>('FollowsByOwnerId', variables), () => sdk.follows_by_owner_id(variables), config);
    },
    useAllCandidateFollows(variables?: All_Candidate_FollowsQueryVariables, config?: SWRConfigInterface<All_Candidate_FollowsQuery, ClientError>) {
      return useSWR<All_Candidate_FollowsQuery, ClientError>(genKey<All_Candidate_FollowsQueryVariables>('AllCandidateFollows', variables), () => sdk.all_candidate_follows(variables), config);
    },
    useAllFollowsByEnterpriseId(variables?: All_Follows_By_Enterprise_IdQueryVariables, config?: SWRConfigInterface<All_Follows_By_Enterprise_IdQuery, ClientError>) {
      return useSWR<All_Follows_By_Enterprise_IdQuery, ClientError>(genKey<All_Follows_By_Enterprise_IdQueryVariables>('AllFollowsByEnterpriseId', variables), () => sdk.all_follows_by_enterprise_id(variables), config);
    },
    useAllSignUpsByEcShortname(variables?: All_Sign_Ups_By_Ec_ShortnameQueryVariables, config?: SWRConfigInterface<All_Sign_Ups_By_Ec_ShortnameQuery, ClientError>) {
      return useSWR<All_Sign_Ups_By_Ec_ShortnameQuery, ClientError>(genKey<All_Sign_Ups_By_Ec_ShortnameQueryVariables>('AllSignUpsByEcShortname', variables), () => sdk.all_sign_ups_by_ec_shortname(variables), config);
    },
    useUniqueCandidateDefaultValues(variables: Unique_Candidate_Default_ValuesQueryVariables, config?: SWRConfigInterface<Unique_Candidate_Default_ValuesQuery, ClientError>) {
      return useSWR<Unique_Candidate_Default_ValuesQuery, ClientError>(genKey<Unique_Candidate_Default_ValuesQueryVariables>('UniqueCandidateDefaultValues', variables), () => sdk.unique_candidate_default_values(variables), config);
    },
    useFormOptions(variables?: Form_OptionsQueryVariables, config?: SWRConfigInterface<Form_OptionsQuery, ClientError>) {
      return useSWR<Form_OptionsQuery, ClientError>(genKey<Form_OptionsQueryVariables>('FormOptions', variables), () => sdk.form_options(variables), config);
    },
    useUniqueInterview(variables: Unique_InterviewQueryVariables, config?: SWRConfigInterface<Unique_InterviewQuery, ClientError>) {
      return useSWR<Unique_InterviewQuery, ClientError>(genKey<Unique_InterviewQueryVariables>('UniqueInterview', variables), () => sdk.unique_interview(variables), config);
    },
    useAllInterviews(variables?: All_InterviewsQueryVariables, config?: SWRConfigInterface<All_InterviewsQuery, ClientError>) {
      return useSWR<All_InterviewsQuery, ClientError>(genKey<All_InterviewsQueryVariables>('AllInterviews', variables), () => sdk.all_interviews(variables), config);
    },
    useAllAcceptedScheduledInterviews(variables?: All_Accepted_Scheduled_InterviewsQueryVariables, config?: SWRConfigInterface<All_Accepted_Scheduled_InterviewsQuery, ClientError>) {
      return useSWR<All_Accepted_Scheduled_InterviewsQuery, ClientError>(genKey<All_Accepted_Scheduled_InterviewsQueryVariables>('AllAcceptedScheduledInterviews', variables), () => sdk.all_accepted_scheduled_interviews(variables), config);
    },
    useAllPendingUnacceptedInterviews(variables?: All_Pending_Unaccepted_InterviewsQueryVariables, config?: SWRConfigInterface<All_Pending_Unaccepted_InterviewsQuery, ClientError>) {
      return useSWR<All_Pending_Unaccepted_InterviewsQuery, ClientError>(genKey<All_Pending_Unaccepted_InterviewsQueryVariables>('AllPendingUnacceptedInterviews', variables), () => sdk.all_pending_unaccepted_interviews(variables), config);
    },
    useUniqueCandidateInterviews(variables?: Unique_Candidate_InterviewsQueryVariables, config?: SWRConfigInterface<Unique_Candidate_InterviewsQuery, ClientError>) {
      return useSWR<Unique_Candidate_InterviewsQuery, ClientError>(genKey<Unique_Candidate_InterviewsQueryVariables>('UniqueCandidateInterviews', variables), () => sdk.unique_candidate_interviews(variables), config);
    },
    useInterviewSearch(variables?: Interview_SearchQueryVariables, config?: SWRConfigInterface<Interview_SearchQuery, ClientError>) {
      return useSWR<Interview_SearchQuery, ClientError>(genKey<Interview_SearchQueryVariables>('InterviewSearch', variables), () => sdk.interview_search(variables), config);
    },
    useInterviewSearchOptions(variables?: Interview_Search_OptionsQueryVariables, config?: SWRConfigInterface<Interview_Search_OptionsQuery, ClientError>) {
      return useSWR<Interview_Search_OptionsQuery, ClientError>(genKey<Interview_Search_OptionsQueryVariables>('InterviewSearchOptions', variables), () => sdk.interview_search_options(variables), config);
    },
    useUniqueTenantJobs(variables: Unique_Tenant_JobsQueryVariables, config?: SWRConfigInterface<Unique_Tenant_JobsQuery, ClientError>) {
      return useSWR<Unique_Tenant_JobsQuery, ClientError>(genKey<Unique_Tenant_JobsQueryVariables>('UniqueTenantJobs', variables), () => sdk.unique_tenant_jobs(variables), config);
    },
    useAllTenantJobs(variables?: All_Tenant_JobsQueryVariables, config?: SWRConfigInterface<All_Tenant_JobsQuery, ClientError>) {
      return useSWR<All_Tenant_JobsQuery, ClientError>(genKey<All_Tenant_JobsQueryVariables>('AllTenantJobs', variables), () => sdk.all_tenant_jobs(variables), config);
    },
    useSearchJobs(variables?: Search_JobsQueryVariables, config?: SWRConfigInterface<Search_JobsQuery, ClientError>) {
      return useSWR<Search_JobsQuery, ClientError>(genKey<Search_JobsQueryVariables>('SearchJobs', variables), () => sdk.search_jobs(variables), config);
    },
    useAllJobs(variables?: All_JobsQueryVariables, config?: SWRConfigInterface<All_JobsQuery, ClientError>) {
      return useSWR<All_JobsQuery, ClientError>(genKey<All_JobsQueryVariables>('AllJobs', variables), () => sdk.all_jobs(variables), config);
    },
    useRecentJobs(variables?: Recent_JobsQueryVariables, config?: SWRConfigInterface<Recent_JobsQuery, ClientError>) {
      return useSWR<Recent_JobsQuery, ClientError>(genKey<Recent_JobsQueryVariables>('RecentJobs', variables), () => sdk.recent_jobs(variables), config);
    },
    useUniqueJob(variables: Unique_JobQueryVariables, config?: SWRConfigInterface<Unique_JobQuery, ClientError>) {
      return useSWR<Unique_JobQuery, ClientError>(genKey<Unique_JobQueryVariables>('UniqueJob', variables), () => sdk.unique_job(variables), config);
    },
    useUniqueJobPublic(variables: Unique_Job_PublicQueryVariables, config?: SWRConfigInterface<Unique_Job_PublicQuery, ClientError>) {
      return useSWR<Unique_Job_PublicQuery, ClientError>(genKey<Unique_Job_PublicQueryVariables>('UniqueJobPublic', variables), () => sdk.unique_job_public(variables), config);
    },
    useJobShortlist(variables?: Job_ShortlistQueryVariables, config?: SWRConfigInterface<Job_ShortlistQuery, ClientError>) {
      return useSWR<Job_ShortlistQuery, ClientError>(genKey<Job_ShortlistQueryVariables>('JobShortlist', variables), () => sdk.job_shortlist(variables), config);
    },
    useUniqueClientJobsByClient(variables?: Unique_Client_Jobs_By_ClientQueryVariables, config?: SWRConfigInterface<Unique_Client_Jobs_By_ClientQuery, ClientError>) {
      return useSWR<Unique_Client_Jobs_By_ClientQuery, ClientError>(genKey<Unique_Client_Jobs_By_ClientQueryVariables>('UniqueClientJobsByClient', variables), () => sdk.unique_client_jobs_by_client(variables), config);
    },
    useUniqueJobCandidatesByPk(variables: Unique_Job_Candidates_By_PkQueryVariables, config?: SWRConfigInterface<Unique_Job_Candidates_By_PkQuery, ClientError>) {
      return useSWR<Unique_Job_Candidates_By_PkQuery, ClientError>(genKey<Unique_Job_Candidates_By_PkQueryVariables>('UniqueJobCandidatesByPk', variables), () => sdk.unique_job_candidates_by_pk(variables), config);
    },
    useJobCandidatesAggregate(variables: Job_Candidates_AggregateQueryVariables, config?: SWRConfigInterface<Job_Candidates_AggregateQuery, ClientError>) {
      return useSWR<Job_Candidates_AggregateQuery, ClientError>(genKey<Job_Candidates_AggregateQueryVariables>('JobCandidatesAggregate', variables), () => sdk.job_candidates_aggregate(variables), config);
    },
    useAllCourses(variables?: All_CoursesQueryVariables, config?: SWRConfigInterface<All_CoursesQuery, ClientError>) {
      return useSWR<All_CoursesQuery, ClientError>(genKey<All_CoursesQueryVariables>('AllCourses', variables), () => sdk.all_courses(variables), config);
    },
    useUniqueCourse(variables: Unique_CourseQueryVariables, config?: SWRConfigInterface<Unique_CourseQuery, ClientError>) {
      return useSWR<Unique_CourseQuery, ClientError>(genKey<Unique_CourseQueryVariables>('UniqueCourse', variables), () => sdk.unique_course(variables), config);
    },
    useUniqueCourseFormId(variables?: Unique_Course_Form_IdQueryVariables, config?: SWRConfigInterface<Unique_Course_Form_IdQuery, ClientError>) {
      return useSWR<Unique_Course_Form_IdQuery, ClientError>(genKey<Unique_Course_Form_IdQueryVariables>('UniqueCourseFormId', variables), () => sdk.unique_course_form_id(variables), config);
    },
    useAllCompletedCoursesByCandidateId(variables?: All_Completed_Courses_By_Candidate_IdQueryVariables, config?: SWRConfigInterface<All_Completed_Courses_By_Candidate_IdQuery, ClientError>) {
      return useSWR<All_Completed_Courses_By_Candidate_IdQuery, ClientError>(genKey<All_Completed_Courses_By_Candidate_IdQueryVariables>('AllCompletedCoursesByCandidateId', variables), () => sdk.all_completed_courses_by_candidate_id(variables), config);
    },
    useNylasGetEventById(variables?: Nylas_Get_Event_By_IdQueryVariables, config?: SWRConfigInterface<Nylas_Get_Event_By_IdQuery, ClientError>) {
      return useSWR<Nylas_Get_Event_By_IdQuery, ClientError>(genKey<Nylas_Get_Event_By_IdQueryVariables>('NylasGetEventById', variables), () => sdk.nylas_get_event_by_id(variables), config);
    },
    usePlacementsByCandidateId(variables?: Placements_By_Candidate_IdQueryVariables, config?: SWRConfigInterface<Placements_By_Candidate_IdQuery, ClientError>) {
      return useSWR<Placements_By_Candidate_IdQuery, ClientError>(genKey<Placements_By_Candidate_IdQueryVariables>('PlacementsByCandidateId', variables), () => sdk.placements_by_candidate_id(variables), config);
    },
    useUniquePlacement(variables?: Unique_PlacementQueryVariables, config?: SWRConfigInterface<Unique_PlacementQuery, ClientError>) {
      return useSWR<Unique_PlacementQuery, ClientError>(genKey<Unique_PlacementQueryVariables>('UniquePlacement', variables), () => sdk.unique_placement(variables), config);
    },
    useAllPlacements(variables?: All_PlacementsQueryVariables, config?: SWRConfigInterface<All_PlacementsQuery, ClientError>) {
      return useSWR<All_PlacementsQuery, ClientError>(genKey<All_PlacementsQueryVariables>('AllPlacements', variables), () => sdk.all_placements(variables), config);
    },
    useSearchPlacements(variables?: Search_PlacementsQueryVariables, config?: SWRConfigInterface<Search_PlacementsQuery, ClientError>) {
      return useSWR<Search_PlacementsQuery, ClientError>(genKey<Search_PlacementsQueryVariables>('SearchPlacements', variables), () => sdk.search_placements(variables), config);
    },
    useSearchAllPlacements(variables?: Search_All_PlacementsQueryVariables, config?: SWRConfigInterface<Search_All_PlacementsQuery, ClientError>) {
      return useSWR<Search_All_PlacementsQuery, ClientError>(genKey<Search_All_PlacementsQueryVariables>('SearchAllPlacements', variables), () => sdk.search_all_placements(variables), config);
    },
    useAllIndustries(variables?: All_IndustriesQueryVariables, config?: SWRConfigInterface<All_IndustriesQuery, ClientError>) {
      return useSWR<All_IndustriesQuery, ClientError>(genKey<All_IndustriesQueryVariables>('AllIndustries', variables), () => sdk.all_industries(variables), config);
    },
    useAllSkills(variables?: All_SkillsQueryVariables, config?: SWRConfigInterface<All_SkillsQuery, ClientError>) {
      return useSWR<All_SkillsQuery, ClientError>(genKey<All_SkillsQueryVariables>('AllSkills', variables), () => sdk.all_skills(variables), config);
    },
    useAllDegrees(variables?: All_DegreesQueryVariables, config?: SWRConfigInterface<All_DegreesQuery, ClientError>) {
      return useSWR<All_DegreesQuery, ClientError>(genKey<All_DegreesQueryVariables>('AllDegrees', variables), () => sdk.all_degrees(variables), config);
    },
    useAllMajors(variables?: All_MajorsQueryVariables, config?: SWRConfigInterface<All_MajorsQuery, ClientError>) {
      return useSWR<All_MajorsQuery, ClientError>(genKey<All_MajorsQueryVariables>('AllMajors', variables), () => sdk.all_majors(variables), config);
    },
    useAllUniversities(variables?: All_UniversitiesQueryVariables, config?: SWRConfigInterface<All_UniversitiesQuery, ClientError>) {
      return useSWR<All_UniversitiesQuery, ClientError>(genKey<All_UniversitiesQueryVariables>('AllUniversities', variables), () => sdk.all_universities(variables), config);
    },
    useGetJobById(variables?: Get_Job_By_IdQueryVariables, config?: SWRConfigInterface<Get_Job_By_IdQuery, ClientError>) {
      return useSWR<Get_Job_By_IdQuery, ClientError>(genKey<Get_Job_By_IdQueryVariables>('GetJobById', variables), () => sdk.get_job_by_id(variables), config);
    },
    useAdminReport(variables?: Admin_ReportQueryVariables, config?: SWRConfigInterface<Admin_ReportQuery, ClientError>) {
      return useSWR<Admin_ReportQuery, ClientError>(genKey<Admin_ReportQueryVariables>('AdminReport', variables), () => sdk.admin_report(variables), config);
    },
    useAllTheThingsUser(variables?: All_The_Things_UserQueryVariables, config?: SWRConfigInterface<All_The_Things_UserQuery, ClientError>) {
      return useSWR<All_The_Things_UserQuery, ClientError>(genKey<All_The_Things_UserQueryVariables>('AllTheThingsUser', variables), () => sdk.all_the_things_user(variables), config);
    },
    useAllReportTargets(variables?: All_Report_TargetsQueryVariables, config?: SWRConfigInterface<All_Report_TargetsQuery, ClientError>) {
      return useSWR<All_Report_TargetsQuery, ClientError>(genKey<All_Report_TargetsQueryVariables>('AllReportTargets', variables), () => sdk.all_report_targets(variables), config);
    },
    useAllActiveUsers(variables?: All_Active_UsersQueryVariables, config?: SWRConfigInterface<All_Active_UsersQuery, ClientError>) {
      return useSWR<All_Active_UsersQuery, ClientError>(genKey<All_Active_UsersQueryVariables>('AllActiveUsers', variables), () => sdk.all_active_users(variables), config);
    },
    useAllEventsReportingByCreatedAt(variables?: All_Events_Reporting_By_Created_AtQueryVariables, config?: SWRConfigInterface<All_Events_Reporting_By_Created_AtQuery, ClientError>) {
      return useSWR<All_Events_Reporting_By_Created_AtQuery, ClientError>(genKey<All_Events_Reporting_By_Created_AtQueryVariables>('AllEventsReportingByCreatedAt', variables), () => sdk.all_events_reporting_by_created_at(variables), config);
    },
    useAllEventsReportingByEventName(variables?: All_Events_Reporting_By_Event_NameQueryVariables, config?: SWRConfigInterface<All_Events_Reporting_By_Event_NameQuery, ClientError>) {
      return useSWR<All_Events_Reporting_By_Event_NameQuery, ClientError>(genKey<All_Events_Reporting_By_Event_NameQueryVariables>('AllEventsReportingByEventName', variables), () => sdk.all_events_reporting_by_event_name(variables), config);
    },
    useSearchFacetValues(variables?: Search_Facet_ValuesQueryVariables, config?: SWRConfigInterface<Search_Facet_ValuesQuery, ClientError>) {
      return useSWR<Search_Facet_ValuesQuery, ClientError>(genKey<Search_Facet_ValuesQueryVariables>('SearchFacetValues', variables), () => sdk.search_facet_values(variables), config);
    },
    useSearchLocations(variables?: Search_LocationsQueryVariables, config?: SWRConfigInterface<Search_LocationsQuery, ClientError>) {
      return useSWR<Search_LocationsQuery, ClientError>(genKey<Search_LocationsQueryVariables>('SearchLocations', variables), () => sdk.search_locations(variables), config);
    },
    useSearchClientsByBusinessName(variables?: Search_Clients_By_Business_NameQueryVariables, config?: SWRConfigInterface<Search_Clients_By_Business_NameQuery, ClientError>) {
      return useSWR<Search_Clients_By_Business_NameQuery, ClientError>(genKey<Search_Clients_By_Business_NameQueryVariables>('SearchClientsByBusinessName', variables), () => sdk.search_clients_by_business_name(variables), config);
    },
    useSearchJobsPreview(variables?: Search_Jobs_PreviewQueryVariables, config?: SWRConfigInterface<Search_Jobs_PreviewQuery, ClientError>) {
      return useSWR<Search_Jobs_PreviewQuery, ClientError>(genKey<Search_Jobs_PreviewQueryVariables>('SearchJobsPreview', variables), () => sdk.search_jobs_preview(variables), config);
    },
    useUniqueShortUrl(variables: Unique_Short_UrlQueryVariables, config?: SWRConfigInterface<Unique_Short_UrlQuery, ClientError>) {
      return useSWR<Unique_Short_UrlQuery, ClientError>(genKey<Unique_Short_UrlQueryVariables>('UniqueShortUrl', variables), () => sdk.unique_short_url(variables), config);
    },
    useUniqueShortUrlByShortId(variables: Unique_Short_Url_By_Short_IdQueryVariables, config?: SWRConfigInterface<Unique_Short_Url_By_Short_IdQuery, ClientError>) {
      return useSWR<Unique_Short_Url_By_Short_IdQuery, ClientError>(genKey<Unique_Short_Url_By_Short_IdQueryVariables>('UniqueShortUrlByShortId', variables), () => sdk.unique_short_url_by_short_id(variables), config);
    },
    useShortlistsByCandidateId(variables: Shortlists_By_Candidate_IdQueryVariables, config?: SWRConfigInterface<Shortlists_By_Candidate_IdQuery, ClientError>) {
      return useSWR<Shortlists_By_Candidate_IdQuery, ClientError>(genKey<Shortlists_By_Candidate_IdQueryVariables>('ShortlistsByCandidateId', variables), () => sdk.shortlists_by_candidate_id(variables), config);
    },
    useUniqueShortlist(variables: Unique_ShortlistQueryVariables, config?: SWRConfigInterface<Unique_ShortlistQuery, ClientError>) {
      return useSWR<Unique_ShortlistQuery, ClientError>(genKey<Unique_ShortlistQueryVariables>('UniqueShortlist', variables), () => sdk.unique_shortlist(variables), config);
    },
    useAllTenants(variables?: All_TenantsQueryVariables, config?: SWRConfigInterface<All_TenantsQuery, ClientError>) {
      return useSWR<All_TenantsQuery, ClientError>(genKey<All_TenantsQueryVariables>('AllTenants', variables), () => sdk.all_tenants(variables), config);
    },
    useUniqueTenantByShortname(variables: Unique_Tenant_By_ShortnameQueryVariables, config?: SWRConfigInterface<Unique_Tenant_By_ShortnameQuery, ClientError>) {
      return useSWR<Unique_Tenant_By_ShortnameQuery, ClientError>(genKey<Unique_Tenant_By_ShortnameQueryVariables>('UniqueTenantByShortname', variables), () => sdk.unique_tenant_by_shortname(variables), config);
    },
    useUniqueTenant(variables: Unique_TenantQueryVariables, config?: SWRConfigInterface<Unique_TenantQuery, ClientError>) {
      return useSWR<Unique_TenantQuery, ClientError>(genKey<Unique_TenantQueryVariables>('UniqueTenant', variables), () => sdk.unique_tenant(variables), config);
    },
    useUniqueUser(variables: Unique_UserQueryVariables, config?: SWRConfigInterface<Unique_UserQuery, ClientError>) {
      return useSWR<Unique_UserQuery, ClientError>(genKey<Unique_UserQueryVariables>('UniqueUser', variables), () => sdk.unique_user(variables), config);
    },
    useUserByAuth0Uid(variables: User_By_Auth0_UidQueryVariables, config?: SWRConfigInterface<User_By_Auth0_UidQuery, ClientError>) {
      return useSWR<User_By_Auth0_UidQuery, ClientError>(genKey<User_By_Auth0_UidQueryVariables>('UserByAuth0Uid', variables), () => sdk.user_by_auth0_uid(variables), config);
    },
    useUniqueUserByClient(variables: Unique_User_By_ClientQueryVariables, config?: SWRConfigInterface<Unique_User_By_ClientQuery, ClientError>) {
      return useSWR<Unique_User_By_ClientQuery, ClientError>(genKey<Unique_User_By_ClientQueryVariables>('UniqueUserByClient', variables), () => sdk.unique_user_by_client(variables), config);
    },
    useUniqueUserByEmail(variables?: Unique_User_By_EmailQueryVariables, config?: SWRConfigInterface<Unique_User_By_EmailQuery, ClientError>) {
      return useSWR<Unique_User_By_EmailQuery, ClientError>(genKey<Unique_User_By_EmailQueryVariables>('UniqueUserByEmail', variables), () => sdk.unique_user_by_email(variables), config);
    }
  };
}
export type SdkWithHooks = ReturnType<typeof getSdkWithHooks>;