import React from 'react';
import { Box, useStyleConfig } from '@chakra-ui/react';
import { ComponentProps } from 'react';

interface Props extends ComponentProps<typeof Box> {
  variant?: string;
}

export const StriverBox: React.FC<Props> = (props) => {
  const { children, ...rest } = props;

  const styles = useStyleConfig('Card');

  return (
    <Box __css={styles} {...rest}>
      {children}
    </Box>
  );
};
