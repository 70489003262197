import { useUser } from '@auth0/nextjs-auth0';
import { useRouter } from 'next/router';
import { useEffect, useState } from 'react';
import { v4 as uuidv4 } from 'uuid';
import { Identify, Page, Track } from '../manual-fetch-sdk';
import {
  SegmentSDKWrapperIdentify,
  SegmentSDKWrapperPage,
  SegmentSDKWrapperTrack,
} from '../types';
import { useUtms } from './use-utms';

// Client side - Hooks
export function useSegment() {
  const { name, source, medium, hasUtmParams } = useUtms();
  const router = useRouter();
  const path = router.pathname;

  let anonymousId =
    typeof window === 'undefined'
      ? ''
      : document.cookie
          .split('; ')
          .find((row) => row.startsWith('anonymousId='))
          ?.split('=')[1] || '';
  const hasAnonymousId = anonymousId ? true : false;

  const { user, error } = useUser();
  const userId = user?.sub as string;

  const [isLoading, setIsLoading] = useState<boolean>(true);

  // All users must have an anonymous ID at a minimum to make Page and Track calls via Segment
  useEffect(() => {
    if (!hasAnonymousId) {
      router.query.anony
        ? (document.cookie = `anonymousId=${router.query.anony}`)
        : (document.cookie = `anonymousId=${uuidv4()}`);
    }
    if (hasAnonymousId && router.isReady) {
      setIsLoading(false);
    }
  }, [hasAnonymousId, router.isReady, router.query.anony]);

  return {
    user,
    isLoading,
    error,
    userId,
    anonymousId,
    hasAnonymousId,
    path,
    hasUtmParams,
    name,
    source,
    medium,
    // SDK wrappers
    identify: ({ traits }: SegmentSDKWrapperIdentify) =>
      Identify({ anonymousId, userId, traits }),
    page: ({ context, path }: SegmentSDKWrapperPage) =>
      Page({ anonymousId, userId, context, path }),
    track: ({ context, event, properties }: SegmentSDKWrapperTrack) =>
      // IMPORTANT: this kinda behaves like a last touch attribution system (due to the ephemeral nature of cookies in the browser) - first touch persistence is handled in Track API route
      // IMPORTANT: in production we would get this data from the users table
      {
        const savedAttribution = window.localStorage.getItem('attribution');
        Track({
          anonymousId,
          userId,
          context: {
            campaign: savedAttribution
              ? JSON.parse(savedAttribution)
              : {
                  name,
                  source,
                  medium,
                },
            ...context,
          },
          event,
          properties,
        });
      },
  };
}
